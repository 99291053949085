<template>
	<div>
		<div v-if="only_title">
            <span v-if="modal_detail_id=='swv_gw_ubg'">
				Bodenwert
			</span>
            <span v-else-if="modal_detail_id=='swv_gbsw_259_4'">
				Gebäudesachwert
			</span>
            <span v-else-if="modal_detail_id=='swv_wz_260'">
				Wertzahl
			</span>
            <span v-else-if="modal_detail_id=='swv_gw_swv'">
				Grundsteuerwert
			</span>
		</div>
		<div v-else>
            <span v-if="modal_detail_id=='swv_gw_ubg'">
              <p></p>
              <table class="table table-striped">
				<colgroup>
					<col style="width:  6%; text-align: center;">
					<col style="width: 70%; text-align: left;">
					<col style="width: 24%; text-align: right;">
				</colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th colspan="2">Bodenwert</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">Bodenrichtwert</td>
                    <td style="text-align: right;">{{zs_bl["brw"] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">&times;</td>
                    <td style="text-align: left;">Grundstücksfläche</td>
                    <td style="text-align: right;">{{zs_bl["gf"] | pretty_print("pretty_number", "m²")}}</td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;">Bodenwert</td>
                    <td style="text-align: right;">{{zs_bl["gw_ubg"] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                </tbody>
              </table>
            </span>
            <span v-else-if="modal_detail_id=='swv_gbsw_259_4'">
              <div v-for="(gg, key) in zs_bl['gebaeude']" :key="key">
                <p style="text-align: left;"><strong>Gebäude {{ key+1 }}:</strong></p>
                <p>Gebäudesachwert für "{{anlage_swv[gg["ga_42"]]["art"]}}" mit dem Baujahr {{gg["bj_nwg_fiktiv"] || gg["bj_nwg"]}} {{gg["bj_nwg_fiktiv"] ? "(fiktives Baujahr)" : ""}} und einer Gesamtnutzungsdauer von {{gg["gnd_nwg_k"]}} Jahren.</p>
                <table class="table table-striped">
                  <colgroup>
                    <col style="width:  6%; text-align: center;">
                    <col style="width: 52%; text-align: left;">
                    <col style="width: 14%; text-align: right;">
                    <col style="width: 14%; text-align: right;">
                    <col style="width: 14%; text-align: right;">
                  </colgroup>
                  <thead>
                    <tr>
                      <th></th>
                      <th colspan="4">Gebäudesachwert</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="text-align: center;"></td>
                      <td style="text-align: left;">Normalherstellungskosten des Gebäudes</td>
                      <td style="text-align: right;">{{gg["nhk_259_1"] | pretty_print("pretty_number_round2", "€")}}</td>
                      <td style="text-align: right;"></td>
                      <td style="text-align: right;"></td>
                    </tr>
                    <tr>
                      <td style="text-align: center;">&times;</td>
                      <td style="text-align: left;">Baupreisindex</td>
                      <td style="text-align: right;">{{gg["nhk_259_3"]*100 | pretty_print("pretty_number", "%")}}</td>
                      <td style="text-align: right;"></td>
                      <td style="text-align: right;"></td>
                    </tr>
                    <tr>
                      <td style="text-align: center;">&times;</td>
                      <td style="text-align: left;">Brutto-Grundfläche des Gebäudes</td>
                      <td style="text-align: right;">{{gg["bgf"] | pretty_print("pretty_number", "m²")}}</td>
                      <td style="text-align: right;"></td>
                      <td style="text-align: right;"></td>
                    </tr>
                    <tr>
                      <td style="text-align: center;">=</td>
                      <td style="text-align: left;">Gebäudenormalherstellungswert</td>
                      <td style="text-align: right;"></td>
                      <td style="text-align: right;">{{gg["gbnhw_259_2"] | pretty_print("pretty_number_round2", "€")}}</td>
                      <td style="text-align: right;"></td>
                    </tr>
                    <tr>
                      <td style="text-align: center;">-</td>
                      <td style="text-align: left;">Alterswertminderung</td>
                      <td style="text-align: right;"></td>
                      <td style="text-align: right;">{{gg["awm_nwg"]*100 | pretty_print("pretty_number_round2", "%")}}</td>
                      <td style="text-align: right;"></td>
                    </tr>
                    <tr>
                      <td style="text-align: center;">=</td>
                      <td style="text-align: left;">Gebäudesachwert</td>
                      <td style="text-align: right;"></td>
                      <td style="text-align: right;"></td>
                      <td style="text-align: right;">{{gg["gbsw_259_4"] | pretty_print("pretty_number_round2", "€")}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </span>
            <span v-else-if="modal_detail_id=='swv_wz_260'">
              <p>Bei einem vorläufigen Sachwert von {{ zs_bl["vsw_258_3"] | pretty_print("pretty_number_round2", "€") }} und einem Bodenrichtwert von {{ zs_bl["brw"] | pretty_print("pretty_number_round2", "€") }} beträgt die Wertzahl gemäß Anlage&nbsp;43 BewG {{ zs_bl["wz_260"] | pretty_print("pretty_number") }}.</p>
              <table class="table table-striped">
				<colgroup>
					<col style="width: 15%; text-align: center;">
					<col style="width: 25%; text-align: right;">
					<col style="width: 20%; text-align: right;">
					<col style="width: 20%; text-align: right;">
					<col style="width: 20%; text-align: right;">
				</colgroup>
                <thead>
                  <tr>
                    <th></th>
					<th></th>
                    <th colspan="3">Bodenrichtwert oder in EUR/m² umgerechneter Bodenwert nach §&nbsp;247 Absatz&nbsp;3</th>
                  </tr>
                  <tr>
                    <th colspan="2">Vorläufiger Sachwert</th>
					<th>bis 100&nbsp;EUR/m²</th>
					<th>bis 300&nbsp;EUR/m²</th>
					<th>über 300&nbsp;EUR/m²</th>
                  </tr>
                </thead>
                <tbody>
					<tr v-for="(row, ind) in anlage_43" :key="ind">
						<td style="text-align: left;">
							<span v-if="ind==0">bis</span>
							<span v-else-if="ind==anlage_43.length-1">über</span>
						</td>
						<td style="text-align: right;">
							{{ anlage_43_sachwerte[ind] | pretty_print("pretty_number_round2", "€") }}
						</td>
						<td style="text-align: right;">
							<span v-if="ind==zs_bl['table_sw'] && zs_bl['table_brw']==0">
								<span class="label label-success" style="font-size: 14px">{{ row[0] | pretty_print("pretty_number") }}</span>
							</span>
							<span v-else>
								{{ row[0] | pretty_print("pretty_number") }}
							</span>
						</td>
						<td style="text-align: right;">
							<span v-if="ind==zs_bl['table_sw'] && zs_bl['table_brw']==1">
								<span class="label label-success" style="font-size: 14px">{{ row[1] | pretty_print("pretty_number") }}</span>
							</span>
							<span v-else>
								{{ row[1] | pretty_print("pretty_number") }}
							</span>
						</td>
						<td style="text-align: right;">
							<span v-if="ind==zs_bl['table_sw'] && zs_bl['table_brw']==2">
								<span class="label label-success" style="font-size: 14px">{{ row[2] | pretty_print("pretty_number") }}</span>
							</span>
							<span v-else>
								{{ row[2] | pretty_print("pretty_number") }}
							</span>
						</td>
					</tr>
				</tbody>
              </table>
            </span>
            <span v-else-if="modal_detail_id=='swv_gw_swv'">
              <p>Ermittlung des Grundsteuerwerts</p>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th width="6%" style="text-align: center;"></th>
                    <th width="50%">Sachwert</th>
                    <th width="22%" style="text-align: right;"></th>
                    <th width="22%" style="text-align: right;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;" colspan="2">Grundsteuerwert im Sachwertverfahren</td>
                    <td style="text-align: right;">{{zs_bl["gw_swv"] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <p>Prüfung des Mindestwerts</p>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th width="6%" style="text-align: center;"></th>
                    <th style="text-align: left;" width="50%">Mindestwert</th>
                    <th width="22%" style="text-align: right;"></th>
                    <th width="22%" style="text-align: right;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">Bodenwert</td>
                    <td style="text-align: right;">{{zs_bl["gw_ubg"] | pretty_print("pretty_number_round2", "€")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">davon</td>
                    <td style="text-align: right;">{{75.00 | pretty_print("pretty_number_round2", "%")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;">Mindestwert</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{zs_bl["mwv_sw_k"] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <table class="table">
                <tr>
                  <td style="text-align: left;" width="70%">
                    Maßgeblich ist der höhere Wert
                  </td>
                  <td width="30%" style="text-align: right;">
                    {{zs_bl["gw_mwv_vabr"] | pretty_print("pretty_number_round2", "€")}}
                  </td>
                </tr>
              </table>
              <table class="table">
                <tr>
                  <td style="text-align: left;" width="70%">
                    Grundsteuerwert, abgerundet auf volle 100&nbsp;€
                  </td>
                  <td width="30%" style="text-align: right;">
                    {{zs_bl["gw_mwv"] | pretty_print("pretty_number_round2", "€")}}
                  </td>
                </tr>
              </table>
			</span>
            <span v-else>
              <p>Es ist ein unerwarteter Fehler aufgetreten, bitte starten sie die Berechnung erneut.</p>
            </span>
		</div>
	</div>
</template>

<script>
import {Anlage43} from "@steuerrechner/grundsteuer-rechner/lib/data/swv_anlage_43"
import {AnlagenSWV} from "@steuerrechner/grundsteuer-rechner/lib/data/anlagen-sachwertverfahren";

export default {
	name: "RechnerErgebnisBW",
	props: {
		modal_detail_id: String,
		zs_bl: null,
		only_title: Boolean,
	},
	data: function() {
		return {
      anlage_swv: AnlagenSWV,
			anlage_43: Anlage43,
			anlage_43_sachwerte: [
				500000,
				750000,
				1000000,
				1500000,
				2000000,
				3000000,
				3000000
			]
		}
	},
};
</script>

<style scoped lang="scss">
.is_selected {
	font-weight: bold;
	background-color: red;
}
</style>
