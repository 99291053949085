<template>
  <div
    class="form-horizontal rechner-form"
    id="'in_group_' + name"
    :class="{'has-error': has_error, 'callout': has_error, 'callout-red': has_error, 'validation_error': has_error}"
  >
    <div class="form-group">
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <label :for="name" class="pull-left control-label rechner-form-label">
          {{ label }}
          <a
            v-if="info_text"
            class="rechner-form-info-button"
            @click="on_info_clicked"
          >
            <span class="glyphicon glyphicon-info-sign"></span>
          </a>
        </label>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <div class="input-group">
          <div class="btn-group btn-group-justified">
            <div class="btn-group" v-for="option in options" v-bind:key="option.value">
              <button
                type="button"
                class="btn"
                :class="option_class(option)"
                @click="selected = option.value"
                :disabled="disabled"
              >{{ option.text }}</button>
            </div>
          </div>
        </div>
        <div v-if="has_error && form_error_message">
          <p>{{ form_error_message }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get';
import RechnerInputWithInfoText from "../RechnerInputWithInfoText";
import { FORM_UPDATE } from "../../store/mutations";
import { RechnerInputWithError } from '../RechnerInputWithError';

export default {
  name: "RechnerInputSelectButtons",
  mixins: [
    RechnerInputWithError,
    RechnerInputWithInfoText
    ],
  props: {
    // Name and ID
    name: String,

    // Bezeichnung
    label: String,

    // Mögliche Optionen zum Auswählen
    options: Array,

    // (De-)Aktivieren des Inputs
    disabled: Boolean,
  },
  methods: {
    option_class: function(option) {
      if (option.check_gt) {
        if (this.selected >= option.value) {
          return ["btn-primary"];
        }
      }
      else {
        if (this.selected === option.value) {
          return ["btn-primary"];
        }
      }

      return ["btn-default"];
    }
  },
  computed: {
    // Eingabefeld-Wert
    selected: {
      get() {
        return _get(this.$store.state.form_raw, this.name);
      },
      set(value) {
        this.$store.dispatch({
          type: FORM_UPDATE,
          name: this.name,
          validation_func: function(val) {
            if (val === null) {
              return false;
            }
            return val;
          },
          error_message: this.error_message || "Bitte eine Auswahl treffen",
          value
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
</style>
