<template>
  <div id="grundsteuerrechner_app">
    <Grundsteuerrechner
      :va="2"
      :bwst="2022"
      :show_feedback_form_link="true"
      wid="rechner"
    />
    <div v-if="RECHNER_DEBUG" id="grundsteuerrechner_app_version" class="row version_row">
      <div class="col-xs-12">
        <p class="text-muted small">{{ APP_VERSION }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Grundsteuerrechner from './components/Grundsteuerrechner.vue'

export default {
  name: 'App',
  components: {
    Grundsteuerrechner
  },
  data: function() {
    return {
      RECHNER_DEBUG: process.env.NODE_ENV !== 'production',
    }
  },
  mounted: function() {
    console.log(this.APP_VERSION);
  },
  computed: {
    APP_VERSION: {
      get: function() {
        return "Grundsteurrechner v" + this.$store.getters.app_version + ' (Rechner v' + this.$store.getters.app_rechner_version + ', Shared v' + this.$store.getters.app_shared_version + ', Widgets v' + this.$store.getters.app_widgets_version + ')';
      }
    }
  }
}
</script>

<style scoped lang="scss">
#grundsteuerrechner_app ::v-deep {
  @import './styles';

  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin: 0 auto;
}

#grundsteuerrechner_app_version .version_row {
  margin-top: 60px;
}
</style>
