// Adds info text functionality to Rechner inputs
var RechnerInputWithInfoText = {
    props: {
        // Info Text und Überschrift
        info_title: String,
        info_text: String
    },

    methods: {
        on_info_clicked() {
            this.$emit('toggleInfoText', this.info_title, this.info_text);
        },
    },
}

module.exports = RechnerInputWithInfoText