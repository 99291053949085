<template>
	<div>
		<div v-if="only_title">
            <span v-if="modal_detail_id=='bw_grundsteuerwert'">
				Grundsteuerwert
			</span>
            <span v-else-if="modal_detail_id=='bw_steuermesszahl'">
				Steuermesszahl
			</span>
		</div>
		<div v-else>
            <span v-if="modal_detail_id=='bw_grundsteuerwert'">
              <p></p>
              <table class="table table-striped">
				<colgroup>
					<col style="width:  6%; text-align: center;">
					<col style="width: 70%; text-align: left;">
					<col style="width: 24%; text-align: right;">
				</colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th colspan="2">Grundsteuerwert</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">Grundstücksfläche</td>
                    <td style="text-align: right;">{{zs_bl["gf"] | pretty_print("pretty_number", "m²")}}</td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">&times;</td>
                    <td style="text-align: left;">Bodenrichtwert</td>
                    <td style="text-align: right;">{{zs_bl["brw"] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;">Grundsteuerwert, abgerundet auf volle 100&nbsp;€</td>
                    <td style="text-align: right;">{{zs_bl["gw_bw"] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                </tbody>
              </table>
            </span>
            <span v-else-if="modal_detail_id=='bw_steuermesszahl'">
              <p></p>
              <table class="table table-striped">
				<colgroup>
					<col style="width:  6%; text-align: center;">
					<col style="width: 70%; text-align: left;">
					<col style="width: 24%; text-align: right;">
				</colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th colspan="2">Steuermesszahl</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">Steuermesszahl</td>
                    <td style="text-align: right;">{{zs_bl["smz_bw_40_2"] | pretty_print("pretty_number_round2_promille", "‰")}}</td>
                  </tr>
                  <tr v-if='zs_bl["erm_n_wz"] != 0'>
                    <td style="text-align: center;">-</td>
                    <td style="text-align: left;">Ermäßigung für überwiegende Wohnnutzung</td>
                    <td style="text-align: right;">{{zs_bl["erm_n_wz"] | pretty_print("pretty_number_round2_promille", "‰")}}</td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;">Steuermesszahl</td>
                    <td style="text-align: right;">{{zs_bl["smz_bw"] | pretty_print("pretty_number_round2_promille", "‰")}}</td>
                  </tr>
                </tbody>
              </table>
            </span>
            <span v-else>
              <p>Es ist ein unerwarteter Fehler aufgetreten, bitte starten sie die Berechnung erneut.</p>
            </span>
		</div>
	</div>
</template>

<script>
export default {
	name: "RechnerErgebnisBW",
	props: {
		modal_detail_id: String,
		zs_bl: null,
		only_title: Boolean,
	},
};
</script>

<style scoped lang="scss">
</style>
