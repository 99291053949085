<template>
  <div
    class="form-horizontal rechner-form"
    id="'in_group_' + name"
    :class="{'has-error': has_error , 'callout': has_error, 'callout-red': has_error, 'validation_error': has_error}"
  >
    <div class="form-group">
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <label :for="name" class="pull-left control-label rechner-form-label">
          {{ label }}
          <a v-if="info_text" class="rechner-form-info-button" @click="on_info_clicked"><span class="glyphicon glyphicon-info-sign"></span></a>
        </label>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <div class="input-group">
          <input
            type="text"
			inputmode="numeric"
            :id="name"
            :name="name"
            :placeholder="placeholder"
            v-model.trim="form_value"
            class="form-control text-right"
          />
          <div class="input-group-btn">
            <button type="button" class="btn btn-default" tabindex="-1">{{ this.get_unit_name }}</button>
          </div>
          <div v-if="external_link_button" class="input-group-btn">
            <a :href="this.external_link_button" target="_blank" class="btn btn-default">Suchen</a>
          </div>
        </div>
        <div v-if="has_error && form_error_message">
          <p>{{ form_error_message }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get';
import { try_get_number_de } from "@steuerrechner/steuerrechner-shared";
import {RechnerInputWithError} from '../RechnerInputWithError';
import RechnerInputWithInfoText from '../RechnerInputWithInfoText';
import {FORM_UPDATE_EURO} from '../../store/mutations';

// Eingabefeld für Euro Beträge
export default {
  name: "RechnerInputEuroCent",
  mixins: [
    RechnerInputWithError,
    RechnerInputWithInfoText
  ],
  props: {
    // Name und ID des inputs
    name: String,

    // Bezeichnung für den Nutzer
    label: String,

    // Platzhalter Text
    placeholder: String,

    // Initialier Wert
    initial_value: String,

    // URL des extra Button rechts
    external_link_button: String || undefined,

    // Optional, falls nicht €
    unit_name: String || undefined
  },
  computed: {
    // Eingabefeld-Wert
    form_value: {
      get() {
        return _get(this.$store.state.form_raw, this.name);
      },
      set(value) {
        this.$store.dispatch({
          type: FORM_UPDATE_EURO,
          name: this.name,
          validation_func: try_get_number_de,
          error_message: this.error_message || "Bitte einen Betrag angeben",
          value
        });
      }
    },

    get_unit_name: function() {
      if (this.unit_name) {
        return this.unit_name;
      }

      return "€"
    }
  },
  mounted: function() {
    if (this.initial_value) {
      this.form_value = this.initial_value;
    }
  }
};
</script>

<style scoped lang="scss">
/* fix border if multiple buttons */
.input-group-btn:not(:first-child):not(:last-child) .btn {
  border-radius: 0 !important;
}
</style>
