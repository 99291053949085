<template>
  <div class="gebart_container" id="gebart">
    
    <div
      id="'in_group_' + name"
      class="form-horizontal rechner-form"
      :class="{'has-error': search_error , 'callout': search_error, 'callout-red': search_error, 'validation_error': search_error}"
    >
      <div class="form-group">
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <label :for="name" class="pull-left control-label rechner-form-label">
            {{ label }}
            <a v-if="info_text" class="rechner-form-info-button" @click="on_info_clicked"><span class="glyphicon glyphicon-info-sign"></span></a>
          </label>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <div v-if="show_search">
            <div class="input-group">
              <input
                type="text"
                :id="name"
                :name="name"
                :placeholder="placeholder"
                :value="search_query"
                @input="e => search_query = ('' + e.target.value).trim()"
                class="form-control"
              />
              <div class="input-group-btn">
                <button type="button" @click="do_show_all()" class="btn btn-default">Anzeigen</button>
              </div>
			</div>
            <div v-if="is_searching">
              <span class="glyphicon glyphicon-refresh spinning"></span>
            </div>
            <div v-if="!is_searching && search_results.length > 0" class="btn-group btn-group-justified search_results_container">
              <span v-for="result in search_results" :key="'span_' + result.item.id">
                <button
                  :key="result.item.id"
                  @click="on_result_selected(result.item)"
                  type="button"
                  class="btn btn-default einfache_suche_result_btn"
                >{{ result.item.art}}</button>
                <br />
              </span>
            </div>
            <p v-else-if="!is_searching && search_results.length == 0 &&search_query.length > 0" class="small text-muted">
              Keine Gebäudeart passend zu {{ search_query }} gefunden
            </p>
            <p v-else-if="!is_searching" class="small text-muted">
              <span >
                Gebäudarten durchsuchen oder alle anzeigen
              </span>
            </p>
          </div>
          <div v-else>
            <div class="input-group">
              <input type="text" class="form-control" disabled="disabled" :value="selected_gebart" />
              <div class="input-group-btn">
                <button type="button" @click="show_search=true" class="btn btn-default">Suchen</button>
              </div>
            </div>
          </div>
          <div v-if="search_error">
            <p>{{ search_error }}</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Fuse from "fuse.js";
import { try_get_number } from "@steuerrechner/steuerrechner-shared";

import { AnlagenSWV } from "@steuerrechner/grundsteuer-rechner/lib/data/anlagen-sachwertverfahren";
const AnlagenSWVSearchData = Object.keys(AnlagenSWV).map(k => { return { id: k, ...AnlagenSWV[k] } });
const AnlagenSWVSearchAll = AnlagenSWVSearchData.map(e => { return { item: e } });

import RechnerInputWithInfoText from '../RechnerInputWithInfoText';
import { RechnerInputWithError } from '../RechnerInputWithError';
import {FORM_RAW_SET} from '../../store/mutations';

var _debounce = require("lodash/debounce");


export default {
  name: "RechnerInputGebArt",
  mixins: [
    RechnerInputWithError,
    RechnerInputWithInfoText
  ],
  props: {
    // TODO Name and ID?
    name: String,

    // Input Platzhalter
    placeholder: String,

    // Textlabel
    label: String,
  },
  data: function() {
    return {
      // Aktuell läuft eine Suche...
      is_searching: false,

      // Suchfeld anzeigen?
      show_search: true,

      // Such Query
      search_query: '',

      // Suchergebnisse
      search_results: [],

      // Suchfehler
      search_error_msg: null,

      // Suchinstanzen, pro Bundesland
      fuses: {},

      // Debounced search, allows smoother user input
      debounced_search: null,

      // Manuelles anpassen aktiv
      user_edit: false,

      // Name der ausgewählten Gebäudeart
      selected_gebart: null,
    }
  },
  mounted: function() {
    // Suchoptionen
    const options = {
      // Suchen in folgenden Attributen
      keys: ["art"],
      isCaseSensitive: false,
      minMatchCharLength: 3
      //threshold: 0.75,
      //distance: 50,
    };

    // Suchen in Anlagen
	this.fuses[0] = new Fuse(AnlagenSWVSearchData, options);
    this.debounced_search = _debounce(this.fuse_search, 500);
  },
  computed: {
	search_error: function() {
		if (this.has_error) {
			return this.form_error_message;
		}
		return this.search_error_msg;
	}
  },
  methods: {
    // Info Buttonclick nach oben weiterleiten
    on_toggle_info_text: function(info_title, info_text) {
      this.$emit('toggleInfoText', info_title, info_text);
    },

	do_show_all: function() {
		// Show all existing
		this.search_results = AnlagenSWVSearchAll;
	},

    // Suche durchführen
    fuse_search: async function() {
      this.is_searching = true;

      // Suchen
      if (this.search_query.length >= 1 && ("0" in this.fuses)) {
        this.search_results = this.fuses[0].search(this.search_query);
      }
      else {
        this.search_results = [];
      }

      // Suche fertig
      this.is_searching = false;
    },

    // Es wurde auf ein Ergebnis geklickt
    on_result_selected: function(result) {
      // Suchfeld ausblenden
      this.show_search = false;

      // Fehler bei fehlender Daten?
      let error_occurred = false;
      if (Object.keys(result).length > 0 && "id" in result && "art" in result) {
        // Kein Fehler
        this.search_error_msg = null;
        this.selected_gebart = result.art;

		// Convert to number, this should not fail...
		const ga_42_number = try_get_number(result.id);
		if (ga_42_number === false) {
			error_occurred = false;
		}
		else {
			this.$store.dispatch({
				type: FORM_RAW_SET,
				name: this.name,
				value: ga_42_number
			});
		}
      }
      else {
			error_occurred = true;
      }
      
      if (error_occurred) {
        // Fehler aufgetreten
        this.search_error_msg = "Bei dieser Gebäudeart ist ein Fehler aufgetreten, bitte versuchen Sie sie erneut auszuwählen.";
        this.selected_gebart = null;

        this.$store.dispatch({
          type: FORM_RAW_SET,
          name: this.name,
          value: null
        });
      }
    }
  },
  watch: {
    // Suchtext überwachen und suchen bei neuer Eingabe
    search_query: function() {
      this.is_searching = true;
      this.debounced_search();
    },
  }
};
</script>

<style scoped lang="scss">
.einfache_suche_result_btn {
  border-radius: 0;
  white-space: normal !important;
  word-wrap: break-word;
  word-break: normal;
  width: 100%;
}

.search_results_container {
  overflow: auto;
  display: inline-block;
  max-height: 250px;
}

.gemeinde_container {
  margin-bottom: 15px;
}

.spinning {
  margin-top: 8px;
}
</style>
