<template>
  <div
    class="form-horizontal rechner-form"
    id="'in_group_' + name"
    :class="{'has-error': has_error, 'callout': has_error, 'callout-red': has_error, 'validation_error': has_error}"
  >
    <div class="form-group">
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <label :for="name" class="pull-left control-label rechner-form-label">
          {{ label }}
          <a
            v-if="info_text"
            class="rechner-form-info-button"
            @click="on_info_clicked"
          >
            <span class="glyphicon glyphicon-info-sign"></span>
          </a>
        </label>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <div :class="extra_button ? 'input-group' : ''">
          <select
            type="text"
            :id="name"
            :name="name"
            v-model="selected"
            class="form-control"
            :disabled="disabled"
          >
            <option
              v-for="option in options"
              v-bind:value="option.value"
              v-bind:key="option.value"
            >{{ option.text }}</option>
          </select>
          <div v-if="extra_button" class="input-group-btn">
            <a class="btn btn-default" @click="on_extra_clicked">{{ extra_button }}</a>
          </div>
        </div>
        <div
          v-if="extra_info"
          class="small text-muted"
        >
        <a
            @click="on_info_clicked"
            style="cursor: pointer;"
          >
            <span class="glyphicon glyphicon-info-sign"></span>
            &nbsp;
            <span v-html="extra_info"></span>
        </a>
        </div>
        <div v-if="has_error && form_error_message">
          <p>{{ form_error_message }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get';
import RechnerInputWithInfoText from "../RechnerInputWithInfoText";
import { FORM_UPDATE } from "../../store/mutations";
import { RechnerInputWithError } from '../RechnerInputWithError';

export default {
  name: "RechnerInputSelect",
  mixins: [
    RechnerInputWithError,
    RechnerInputWithInfoText
  ],
  props: {
    // Name and ID
    name: String,

    // Bezeichnung
    label: String,

    // Mögliche Optionen zum Auswählen
    options: Array,

    // (De-)Aktivieren des Inputs
    disabled: Boolean,

    // Extra Info unterhalb des Eingabefeldes
    extra_info: String,

    // Initialier Wert
    initial_value: Number,

    // Extra Button anzeigen
    extra_button: String
  },
  methods: {
    on_extra_clicked: function() {
      this.$emit('extra_button_click');
    }
  },
  computed: {
    // Eingabefeld-Wert
    selected: {
      get() {
        return _get(this.$store.state.form_raw, this.name);
      },
      set(value) {
        this.$store.dispatch({
          type: FORM_UPDATE,
          name: this.name,
          validation_func: function(val) {
            if (val === null) {
              return false;
            }
            return val;
          },
          error_message: this.error_message || "Bitte eine Auswahl treffen",
          value
        });
      }
    }
  },
  mounted: function() {
    if (this.initial_value) {
      this.selected = this.initial_value;
    }
  }
};
</script>

<style scoped lang="scss">
</style>
