import _get from 'lodash/get';

// Standardfehlermeldung, sollte passend überschrieben werden
const DEFAULT_ERROR_MESSAGE = "Bitte einen gültigen Wert angeben";

// Adds error functionality to Rechner inputs
export var RechnerInputWithError = {
    props: {
        // Überschreiben der Standardfehlermeldung möglich
        error_message: String
    },

    computed: {
        has_error: function () {
            return this.error !== null;
        },

        form_error_message: function () {
            if (this.error) {
                if ("message" in this.error) {
                    return this.error.message;
                }
                return DEFAULT_ERROR_MESSAGE;
            }

            return null;
        },

        // Fehler
        error: {
            get() {
                return _get(this.$store.state.form_error, this.name);
            }
        },
    }
};
