<template>
  <div>
    <div v-if="current_wid">
      <!-- Vuejs-basiertes Widget -->
      <grundsteuer-widget v-pre></grundsteuer-widget>
      <!-- Ende Vuejs-basiertes Widget -->

      <!-- ID-basiertes Widget -->
      <div id="gsde_rechner_widget_div"></div>
      <!-- Ende ID-basiertes Widget -->

      <!-- Fallback für Grundstücksart Widget -->
      <grundsteuer-grundstuecksart v-pre></grundsteuer-grundstuecksart>
      <!-- Ende Fallback für Grundstücksart Widget -->
      
      <!-- Fallback für jQuery-basiertes Widget -->
      <!--<div id="gsde_rechner_widget_div_jquery">
        <div class="col-lg-12 mx-0 px-0 form_widget" id="form_widget"></div>
      </div>-->
      <!-- Ende Fallback für jQuery-basiertes Widget -->
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "WidgetContainer",
  props: {
    // Widget ID
    "wid": String,

    // Bereitgestellte Rechner-Parameter
    "bl": Number
  },
  data: function() {
    return {
      current_wid: null
    }
  },
  mounted: function() {
    // URL params
    let params = this.$store.state.URL_PARAMS;

    // Update widget ID, if present
    if (params.has("wid")) {
      const new_wid = params.get("wid");
      this.current_wid = new_wid;
    }
    else {
      this.current_wid = this.wid;
    }

    if (this.current_wid) {
      // Inject the script to load the widget
      // Try to load a single file per id
      const url = process.env.VUE_APP_WIDGET_URL + "/wid/" + this.current_wid + ".js";
      let that = this;
      axios
        .head(url)
        .then(function () {
          // Load script
          var script = document.createElement("script");
          script.type = "text/javascript";
          script.src = url;
          document.head.appendChild(script);
        })
        .catch(function () {
          // Fallback to internal
          that.current_wid = null;
        });
    }
  }
};
</script>

<style scoped lang="scss">
#gsde_rechner_widget_div {
  margin-left: 16px !important;
  margin-right: 16px !important;
}
</style>
