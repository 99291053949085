<template>
	<div>
		<div v-if="only_title">
            <span v-if="modal_detail_id=='rohertrag'">
              Rohertrag
            </span>
            <span v-else-if="modal_detail_id=='bewirtschaftungskosten'">
              Bewirtschaftungskosten und Reinertrag
            </span>
            <span v-else-if="modal_detail_id=='vervielfältiger'">
              Vervielfältiger
            </span>
            <span v-else-if="modal_detail_id=='kapitalisierter_reinertrag'">
              Kapitalisierter Reinertrag
            </span>
            <span v-else-if="modal_detail_id=='abgezinster_bodenwert'">
              Abgezinster Bodenwert
            </span>
            <span v-else-if="modal_detail_id=='grundsteuerwert'">
              Grundsteuerwert
            </span>
		</div>
		<div v-else-if="zs_bm_ok">
            <span v-if="modal_detail_id=='rohertrag'">
              <p>Rohertrag gemäß Anlage 39 zum BewG für das {{BBG_GA_NAMES_DETAILS[bbg_ga]}} mit dem Baujahr {{zs_bm["3"]["bj_wg_fiktiv"] || zs_bm["2a"]["bj_wg"]}} {{zs_bm["3"]["bj_wg_fiktiv"] ? "(fiktives Baujahr)" : ""}} im Bundesland {{BL_MAP[es["bl"]]}}.</p>
              <span v-for="(w, wkey) in zs_bm_2['wohnungen']" :key="wkey">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th width="6%" style="text-align: center;"></th>
                      <th width="50%" style="text-align: left;">Wohnung {{wkey+1}}</th>
                      <th width="22%" style="text-align: right;"></th>
                      <th width="22%" style="text-align: right;"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="text-align: center;"></td>
                      <td style="text-align: left;">Wohnfläche</td>
                      <td style="text-align: right;">{{w["wf"]}} m²</td>
                      <td style="text-align: right;"></td>
                    </tr>
                    <tr>
                      <td style="text-align: center;">&times;</td>
                      <td style="text-align: left;">
                        angepasste monatliche Nettokaltmiete
						<span v-if="w['nettokaltmiete_mns_korrektur']">({{w["nettokaltmiete"] | pretty_print("pretty_number_round2", "€/m²")}} {{w["nettokaltmiete_mns_korrektur"]}})</span>
                      </td>
                      <td style="text-align: right;">{{w["nettokaltmiete_mns"] | pretty_print("pretty_number_round2", "€/m²")}}</td>
                      <td style="text-align: right;"></td>
                    </tr>
                    <tr>
                      <td style="text-align: center;">=</td>
                      <td style="text-align: left;">Monatliche Nettokaltmiete</td>
                      <td style="text-align: right;"></td>
                      <td style="text-align: right;">{{w["nettokaltmiete_monat"] | pretty_print("pretty_number_round2", "€")}}</td>
                    </tr>
                    <tr>
                      <td style="text-align: center;">&times;</td>
                      <td style="text-align: left;">12 Monate</td>
                      <td style="text-align: right;"></td>
                      <td style="text-align: right;"></td>
                    </tr>
                    <tr>
                      <td style="text-align: center;">=</td>
                      <td style="text-align: left;">Jährlicher Rohertrag</td>
                      <td style="text-align: right;"></td>
                      <td style="text-align: right;">{{w["rohertrag_jahr"] | pretty_print("pretty_number_round2", "€")}}</td>
                    </tr>
                  </tbody>
                </table>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th width="6%" style="text-align: center;"></th>
                      <th width="50%">Garagenstellplätze</th>
                      <th width="22%" style="text-align: right;"></th>
                      <th width="22%" style="text-align: right;"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="text-align: center;"></td>
                      <td style="text-align: left;">Anzahl Garagen</td>
                      <td style="text-align: right;">{{w["garage"]}}</td>
                      <td style="text-align: right;"></td>
                    </tr>
                    <tr>
                      <td style="text-align: center;">&times;</td>
                      <td style="text-align: left;">
                        angepasste monatliche Nettokaltmiete je Garage
						<span v-if="w['nettokaltmiete_garage_mns_korrektur']">({{w["nettokaltmiete_garage"] | pretty_print("pretty_number_round2", "€")}} {{w["nettokaltmiete_garage_mns_korrektur"]}})</span>
                      </td>
                      <td style="text-align: right;">{{w["nettokaltmiete_garage_angepasst"] | pretty_print("pretty_number_round2", "€")}}</td>
                      <td style="text-align: right;"></td>
                    </tr>
                    <tr>
                      <td style="text-align: center;">=</td>
                      <td style="text-align: left;">Monatliche Nettokaltmiete</td>
                      <td style="text-align: right;"></td>
                      <td style="text-align: right;">{{w["nettokaltmiete_monat_garage"] | pretty_print("pretty_number_round2", "€")}}</td>
                    </tr>
                    <tr>
                      <td style="text-align: center;">&times;</td>
                      <td style="text-align: left;">12 Monate</td>
                      <td style="text-align: right;"></td>
                      <td style="text-align: right;"></td>
                    </tr>
                    <tr>
                      <td style="text-align: center;">=</td>
                      <td style="text-align: left;">Jährlicher Rohertrag</td>
                      <td style="text-align: right;"></td>
                      <td style="text-align: right;">{{w["rohertrag_jahr_garage"] | pretty_print("pretty_number_round2", "€")}}</td>
                    </tr>
                  </tbody>
                </table>
              </span>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th width="6%" style="text-align: center;"></th>
                    <th width="50%">Ergebnis</th>
                    <th width="22%" style="text-align: right;"></th>
                    <th width="22%" style="text-align: right;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">Jährlicher Rohertrag der {{zs_bm_2['wohnungen'].length > 1 ? ""+zs_bm_2['wohnungen'].length+" Wohnungen" : "Wohnung"}}</td>
                    <td style="text-align: right;">{{zs_bm_2['rohertrag_jahr'] | pretty_print("pretty_number_round2", "€")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">+</td>
                    <td style="text-align: left;">Jährlicher Rohertrag der {{zs_bm_2['garagen'] > 1 ? ""+zs_bm_2['garagen']+" Garagen" : "Garage"}}</td>
                    <td style="text-align: right;">{{zs_bm_2['rohertrag_jahr_garage'] | pretty_print("pretty_number_round2", "€")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;">Rohertrag des Grundstücks</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{zs_bm_2['rohertrag_gs'] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                </tbody>
              </table>
            </span>
            <span v-else-if="modal_detail_id=='bewirtschaftungskosten'">
              <p>Bewirtschaftungskosten für das {{BBG_GA_NAMES_DETAILS[bbg_ga]}} mit einer Restnutzungsdauer von {{zs_bm["2a"]["rnd"]}}&nbsp;Jahren.</p>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th width="6%" style="text-align: center;"></th>
                    <th width="50%">Bewirtschaftungskosten</th>
                    <th width="22%" style="text-align: right;"></th>
                    <th width="22%" style="text-align: right;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">Rohertrag des Grundstücks</td>
                    <td style="text-align: right;">{{zs_bm["4"]["re_254"] | pretty_print("pretty_number_round2", "€")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">-</td>
                    <td style="text-align: left;">Bewirtschaftungskosten</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">{{(zs_bm["4"]["bwk_255"] / zs_bm["4"]["re_254"]) | pretty_print("pretty_number_ceil2", "%")}} vom Rohertrag</td>
                    <td style="text-align: right;">{{zs_bm["4"]["bwk_255"] | pretty_print("pretty_number_round2", "€")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;">Reinertrag des Grundstücks</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{zs_bm["4"]['reg_253'] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                </tbody>
              </table>
            </span>
            <span v-else-if="modal_detail_id=='vervielfältiger'">
              <p>Vervielfältiger gemäß Anlage 37 zum BewG für das {{BBG_GA_NAMES_DETAILS[bbg_ga]}}.</p>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th width="6%" style="text-align: center;"></th>
                    <th width="50%">Vervielfältiger</th>
                    <th width="22%" style="text-align: right;"></th>
                    <th width="22%" style="text-align: right;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">Restnutzungsdauer</td>
                    <td style="text-align: right;">{{zs_bm["5"]["rnd"]}}&nbsp;Jahre</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">Liegenschaftszinssatz</td>
                    <td style="text-align: right;">{{zs_bm["5"]['lzs_256']*100 | pretty_print("pretty_number_round2", "%")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;">Vervielfältiger</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{zs_bm["5"]["vv_253"] | pretty_print("pretty_number_round2")}}</td>
                  </tr>
                </tbody>
              </table>
            </span>
            <span v-else-if="modal_detail_id=='kapitalisierter_reinertrag'">
              <p>Kapitalisierter Reinertrag für das {{BBG_GA_NAMES_DETAILS[bbg_ga]}}.</p>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th width="6%" style="text-align: center;"></th>
                    <th width="50%">Kapitalisierter Reinertrag</th>
                    <th width="14%" style="text-align: right;"></th>
                    <th width="14%" style="text-align: right;"></th>
                    <th width="14%" style="text-align: right;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">Reinertrag des Grundstücks</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{zs_bm["5"]["reg_253"] | pretty_print("pretty_number_round2", "€")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">&times;</td>
                    <td style="text-align: left;">Vervielfältiger</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{zs_bm["5"]["vv_253"] | pretty_print("pretty_number_round2")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">für den Liegenschaftszins von</td>
                    <td style="text-align: right;">{{zs_bm["5"]['lzs_256']*100 | pretty_print("pretty_number_round2", "%")}}</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">und die Restnutzungsdauer von</td>
                    <td style="text-align: right;">{{zs_bm["5"]["rnd"]}} Jahren</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;">Kapitalisierter Reinertrag</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{zs_bm["5"]["kr_253"] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                </tbody>
              </table>
            </span>
            <span v-else-if="modal_detail_id=='abgezinster_bodenwert'">
              <p>Abgezinster Bodenwert für das {{BBG_GA_NAMES_DETAILS[bbg_ga]}} mit einer Restnutzungsdauer von {{zs_bm["2a"]["rnd"]}}&nbsp;Jahren und einem Liegenschaftszins von {{zs_bm["5"]['lzs_256']*100 | pretty_print("pretty_number_round2", "%")}}. Der Abzinsungsfaktor ergibt sich aus Anlage&nbsp;41 zum BewG und der Umrechnungskoeffizient, sofern anwendbar, aus Anlage 36 zum BewG.</p>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th width="6%" style="text-align: center;"></th>
                    <th width="50%">Abgezinster Bodenwert</th>
                    <th width="14%" style="text-align: right;"></th>
                    <th width="14%" style="text-align: right;"></th>
                    <th width="14%" style="text-align: right;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">Grundstücksfläche</td>
                    <td style="text-align: right;">{{zs_bm["6"]["gf"]}} m²</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">&times;</td>
                    <td style="text-align: left;">Bodenrichtwert</td>
                    <td style="text-align: right;">{{zs_bm["6"]["brw"] | pretty_print("pretty_number_round2", "€/m²")}}</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr v-if="bbg_ga===1 || bbg_ga===2">
                    <td style="text-align: center;">&times;</td>
                    <td style="text-align: left;">Umrechnungskoeffizient</td>
                    <td style="text-align: right;">{{zs_bm["6"]["uk"] | pretty_print("pretty_number_round2")}}</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;">Bodenwert vor Abzinsung</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{zs_bm["6"]["bw_uk"] | pretty_print("pretty_number_round2", "€")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">&times;</td>
                    <td style="text-align: left;">Abzinsungsfaktor</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{zs_bm["6"]["af_257"] | pretty_print("pretty_number_round4")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;">Abgezinster Bodenwert</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{zs_bm["6"]["ab_257"] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                </tbody>
              </table>
            </span>
            <span v-else-if="modal_detail_id=='grundsteuerwert' && bbg_ga===0">
              <p>Grundsteuerwert des unbebauten Grundstücks.</p>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th width="6%" style="text-align: center;"></th>
                    <th width="50%">Grundsteuerwert</th>
                    <th width="22%" style="text-align: right;"></th>
                    <th width="22%" style="text-align: right;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">Grundstücksfläche</td>
                    <td style="text-align: right;">{{zs_bm["6"]["gf"]}} m²</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">&times;</td>
                    <td style="text-align: left;">Bodenrichtwert</td>
                    <td style="text-align: right;">{{zs_bm["6"]["brw"] | pretty_print("pretty_number_round2", "€/m²")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;">Grundsteuerwert</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{zs_bm["8"]["gw_ubg"] | pretty_print("pretty_number_round2")}}</td>
                  </tr>
                </tbody>
              </table>
            </span>
            <span v-else-if="modal_detail_id=='grundsteuerwert' && bbg_ga!==0">
              <p>Ermittlung des Grundsteuerwerts</p>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th width="6%" style="text-align: center;"></th>
                    <th width="50%">Ertragswert</th>
                    <th width="22%" style="text-align: right;"></th>
                    <th width="22%" style="text-align: right;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">Kapitalisierter Reinertrag</td>
                    <td style="text-align: right;">{{zs_bm["7"]["kr_253"] | pretty_print("pretty_number_round2", "€")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">+</td>
                    <td style="text-align: left;">abgezinster Bodenwert</td>
                    <td style="text-align: right;">{{zs_bm["7"]["ab_257"] | pretty_print("pretty_number_round2", "€")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;">Grundsteuerwert im Ertragswertverfahren</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{zs_bm["7"]["gw_ewv"] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <p>Prüfung des Mindestwerts</p>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th width="6%" style="text-align: center;"></th>
                    <th width="50%">Mindestwert</th>
                    <th width="22%" style="text-align: right;"></th>
                    <th width="22%" style="text-align: right;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">Bodenwert vor Abzinsung</td>
                    <td style="text-align: right;">{{zs_bm["6"]["bw_uk"] | pretty_print("pretty_number_round2", "€")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">davon</td>
                    <td style="text-align: right;">{{zs_bm["8"]["mwv_prozent"]*100 | pretty_print("pretty_number_round2", "%")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;">Mindestwert</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{zs_bm["8"]["mwv_k"] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <table class="table">
                <tr>
                  <td width="70%" style="text-align: left;">
                    Maßgeblich ist der höhere Wert
                  </td>
                  <td width="30%" style="text-align: right;">
                    {{zs_bm["8"]["gw_mwv_vabr"] | pretty_print("pretty_number_round2", "€")}}
                  </td>
                </tr>
              </table>
              <table class="table">
                <tr>
                  <td width="70%" style="text-align: left;">
                    Grundsteuerwert, abgerundet auf volle 100&nbsp;€
                  </td>
                  <td width="30%" style="text-align: right;">
                    {{zs_bm["9"]["gw_mwv"] | pretty_print("pretty_number_round2", "€")}}
                  </td>
                </tr>
              </table>
            </span>
            <span v-else>
              <p>Es ist ein unerwarteter Fehler aufgetreten, bitte starten sie die Berechnung erneut.</p>
            </span>
		</div>
	</div>
</template>

<script>
import { pretty_number, runden2 } from '@steuerrechner/steuerrechner-shared';

export default {
	name: "RechnerErgebnisBM",
	props: {
		modal_detail_id: String,
		zs_bm: null,
		bbg_ga: null,
		BBG_GA_NAMES_DETAILS: null,
		bl: null,
		BL_MAP: null,
		es: null,
		only_title: Boolean,
	},
	computed: {
		zs_bm_ok: function() {
			return Object.keys(this.zs_bm).length > 0;
		},

		zs_bm_2: function() {
			let ret = {
				"rohertrag_jahr": 0,
				"rohertrag_jahr_garage": 0,
				"rohertrag_gs": 0,
				"wohnungen": [],
				"garagen": 0,
			}

			for (const w of this.zs_bm['2b']['whonungen']) {
				let nettokaltmiete_monat = w["wf"]*w["nettokaltmiete_mns"];
				let rohertrag_jahr = nettokaltmiete_monat*12;
				
				let nettokaltmiete_garage_angepasst = w["nettokaltmiete_garage"]*this.zs_bm["2b"]["mns"];
				let nettokaltmiete_monat_garage = w["garage"] * nettokaltmiete_garage_angepasst;
				let rohertrag_jahr_garage = nettokaltmiete_monat_garage*12;

				let nkm_korrektur = null;
				if (w["nettokaltmiete_mns_korrektur"] < 0) {
					nkm_korrektur = "- " + pretty_number(runden2(w["nettokaltmiete_mns_korrektur"]*-1)) + " €/m² Abschlag";
				}
				else if (w["nettokaltmiete_mns_korrektur"] > 0) {
					nkm_korrektur = "+ " + pretty_number(runden2(w["nettokaltmiete_mns_korrektur"])) + " €/m² Zuschlag";
				}
				
				let nkm_garage_korrektur = null;
				if (w["nettokaltmiete_garage_mns_korrektur"] < 0) {
					nkm_garage_korrektur = "- " + pretty_number(runden2(w["nettokaltmiete_garage_mns_korrektur"]*-1)) + " € Abschlag";
				}
				else if (w["nettokaltmiete_garage_mns_korrektur"] > 0) {
					nkm_garage_korrektur = "+ " + pretty_number(runden2(w["nettokaltmiete_garage_mns_korrektur"])) + " € Zuschlag";
				}

				ret["rohertrag_jahr"] += rohertrag_jahr;
				ret["rohertrag_jahr_garage"] += rohertrag_jahr_garage;
				ret["garagen"] += w["garage"];
				ret["wohnungen"].push({
					"wf": w["wf"],
					"nettokaltmiete_angepasst_p": w["nettokaltmiete_mns"]/w["nettokaltmiete"],
					"nettokaltmiete_mns": w["nettokaltmiete_mns"],
					"nettokaltmiete": w["nettokaltmiete"],
					"nettokaltmiete_mns_korrektur": nkm_korrektur,
					"nettokaltmiete_monat": nettokaltmiete_monat,
					"rohertrag_jahr": rohertrag_jahr,
					"garage": w["garage"],
					"nettokaltmiete_garage_angepasst_p": w["nettokaltmiete_mns"]/w["nettokaltmiete"],
					"nettokaltmiete_garage_angepasst": nettokaltmiete_garage_angepasst,
					"nettokaltmiete_monat_garage": nettokaltmiete_monat_garage,
					"rohertrag_jahr_garage": rohertrag_jahr_garage,
					"nettokaltmiete_garage": w["nettokaltmiete_garage"],
					"nettokaltmiete_garage_mns_korrektur": nkm_garage_korrektur,
				})
			}

			ret["rohertrag_gs"] = ret["rohertrag_jahr"] + ret["rohertrag_jahr_garage"];

			return ret;
		},
	}
};
</script>

<style scoped lang="scss">
.is_selected {
	font-weight: bold;
	background-color: red;
}
</style>
