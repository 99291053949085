import Vue from 'vue'
import App from './App.vue'
import store from './store';
import { filter_pretty_print } from './filters';

Vue.config.productionTip = false

Vue.filter('pretty_print', filter_pretty_print)

new Vue({
  render: h => h(App),
  store
}).$mount('#grundsteuerrechner_app')
