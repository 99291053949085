export const FORM_RAW_SET = 'form_raw_set';
export const FORM_VALUE_SET = 'form_value_set';
export const FORM_ERROR_SET = 'form_error_set';
export const FORM_ERROR_RESET = 'form_error_reset';
export const FORM_UPDATE = 'form_update';
export const FORM_UPDATE_EURO = 'form_update_euro';
export const FORM_ADD_WOHNUNG = 'form_add_wohnung';
export const FORM_REMOVE_WOHNUNG = 'form_remove_wohnung';
export const FORM_SET_WOHNUNG_LAST_KEY = 'form_set_wohnung_last_key';
export const FULL_RESET = 'full_reset';
