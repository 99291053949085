import Vue from 'vue';
import Vuex from 'vuex';
import {FULL_RESET, FORM_RAW_SET, FORM_REMOVE_WOHNUNG, FORM_ERROR_SET, FORM_ERROR_RESET, FORM_ADD_WOHNUNG, FORM_UPDATE, FORM_VALUE_SET, FORM_UPDATE_EURO} from './mutations';
import _set from 'lodash/set';

Vue.use(Vuex)

function init_wohnung(key) {
  return {
    _key: key,
    baujahr: null,
    gb_nb: true,
    wf: null,
    wf_nf: null,
    garage: 0,
    bgf: null,
    ga_42: null,
  }
}

function init_wohnung_error() {
  return {
    baujahr: null,
    gb_nb: null,
    wf: null,
    wf_nf: null,
    garage: null,
    bgf: null,
    ga_42: null,
  }
}

function init_fields() {
  return {
    gf: null,
    gf_miteigentum_anteilig: null,
    gf_miteigentum: null,
    gf_miteigentum_z: null,
    gf_miteigentum_n: null,
    brw: null,
    dbrw: null,
    bbg_ga: 1,

    // Bereits mit einer Wohnung starten
    wohnungen: [
      init_wohnung(0)
    ],

    foerderung: 0,
    baudenkmal: 0,
    n_wz: 1,
    mns: 1,
    bl: null,
    hs: null,
    gf_bebauung: 0,
    wl: 1,
    ags: null,
    abbruch: false,
    abbruch_jahr: null,
    kernsanierung: false,
    kernsanierung_jahr: null,
  }
}

function init_error_fields() {
  return {
    gf: null,
    gf_miteigentum_anteilig: null,
    gf_miteigentum: null,
    gf_miteigentum_z: null,
    gf_miteigentum_n: null,
    brw: null,
    dbrw: null,
    bbg_ga: null,
    wohnungen: [
      init_wohnung_error()
    ],
    foerderung: null,
    baudenkmal: null,
    n_wz: null,
    mns: null,
    bl: null,
    hs: null,
    gf_bebauung: null,
    wl: null,
    abbruch: null,
    abbruch_jahr: null,
    kernsanierung: null,
    kernsanierung_jahr: null,
  }
}

function parse_url_params() {
  // Parse URL params
  let params = new URLSearchParams(window.location.search);
  return params;
}

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    state: {
      // Versionsinfo
      VUE_APP_RECHNER_WEB_VERSION: process.env.VUE_APP_RECHNER_WEB_VERSION,
      VUE_APP_RECHNER_WEB_GIT_HASH: process.env.VUE_APP_RECHNER_WEB_GIT_HASH,
      VUE_APP_RECHNER_VERSION: process.env.VUE_APP_RECHNER_VERSION,
      VUE_APP_RECHNER_SHARED_VERSION: process.env.VUE_APP_RECHNER_SHARED_VERSION,
      VUE_APP_RECHNER_WIDGETS_VERSION: process.env.VUE_APP_RECHNER_WIDGETS_VERSION,

      // Einfacher Cordova-Check
      IS_CORDOVA_APP: (!!window.cordova || !!window.Cordova),

      // URL Parameters
      URL_PARAMS: parse_url_params(),
      URL_DOMAIN: window.location.hostname,

      // Gebäude ID merken um das korrekte Element aus dem Array zu Löschen
      // Immer fortlaufend, wird nie zurückgesetzt
      wohnungen_last_key: 0,

      // Direkte Nutzer-Eingabe im Eingabefeld
      form_raw: init_fields(),

      // Gesäuberter, geprüfter Wert
      form_value: init_fields(),

      // Fehler im Eingabefeld
      form_error: init_error_fields(),
    },
    mutations: {
      // Reset des ganzen Stores
      [FULL_RESET] (state) {
        state.wohnungen_last_key = 0

        const f1 = init_fields();
        Object.keys(f1).forEach(k => {
          if (k == "bl") {
            return
          }
          state.form_raw[k] = f1[k]
        })

        const f2 = init_fields();
        Object.keys(f2).forEach(k => {
          if (k == "bl") {
            return
          }
          state.form_value[k] = f2[k]
        })

        const f3 = init_error_fields();
        Object.keys(f3).forEach(k => {
          if (k == "bl") {
            return
          }
          state.form_error[k] = f3[k]
        })
      },

      // Direktes Setzten des eingetippten Wertes
      [FORM_RAW_SET] (state, payload) {
        _set(state.form_raw, payload.name, payload.value);
      },

      // Setzen des geprüften Wertes
      [FORM_VALUE_SET] (state, payload) {
        _set(state.form_value, payload.name, payload.value);
      },

      // Setzen des Fehlers
      [FORM_ERROR_SET] (state, payload) {
        _set(state.form_error, payload.name, payload.value);
      },

      // Zurücksetzen der Fehler
      [FORM_ERROR_RESET] (state) {
        state.form_error = init_error_fields();
      },

      // Neues Formular für weitere Wohnung anfügen
      [FORM_ADD_WOHNUNG] (state) {
        // Hochzählen, um einduetigen Key über gesamte Laufzeit zu erhalten
        state.wohnungen_last_key += 1;
        
        // Neue Wohnung hinzufügen
        state.form_raw.wohnungen = [
          ...state.form_raw.wohnungen,
          init_wohnung(state.wohnungen_last_key)
        ];
        state.form_value.wohnungen = [
          ...state.form_value.wohnungen,
          init_wohnung(state.wohnungen_last_key)
        ];
        state.form_error.wohnungen = [
          ...state.form_error.wohnungen,
          init_wohnung_error()
        ];
      },

      // ... und entfernen
      [FORM_REMOVE_WOHNUNG] (state, payload) {
        const index = payload.index;

        // Kopie erstellen, in der die Wohnung am Index fehlt
        state.form_raw.wohnungen = state.form_raw.wohnungen.filter((w, ind)  => ind !== index);
        state.form_value.wohnungen = state.form_value.wohnungen.filter((w, ind)  => ind !== index);
        state.form_error.wohnungen = state.form_error.wohnungen.filter((w, ind)  => ind !== index);
      },
    },
    actions: {
      // Aktualisieren nach Eingabe im Formularfeld
      [FORM_UPDATE] (context, payload) {
        // Setzen des unveränderten Wertes
        context.commit(FORM_RAW_SET, payload);

        // Dazu den Wert auf Fehler prüfen
        let value = payload.value;
        let error = null;

        // Validierung des Inputs durchführen
        if ("validation_func" in payload) {
          let validated = payload.validation_func(value);
          if (validated === false) {
            // Fehler aufgetreten
            value = null;
            error = {
              message: payload.error_message || "Eingabewert konnte nicht validiert werden!"
            }
          }
          else {
            // Kein Fehler
            value = validated;
          }
        }

        // Wert speichern
        context.commit({
          type: FORM_VALUE_SET,
          name: payload.name,
          value
        });

        // Fehler speichern
        context.commit({
          type: FORM_ERROR_SET,
          name: payload.name,
          value: error
        });
      },

      // Setzten des geprüften Wertes als "Euro"
      [FORM_UPDATE_EURO] (context, payload) {
        // Setzen des unveränderten Wertes
        context.commit(FORM_RAW_SET, payload);

        // Dazu den Wert auf Fehler prüfen
        let value = payload.value;
        let error = null;

        // Validierung des Inputs durchführen
        if ("validation_func" in payload) {
          let validated = payload.validation_func(value);
          if (validated === false) {
            // Fehler aufgetreten
            value = null;
            error = {
              message: payload.error_message || "Eingabewert konnte nicht validiert werden!"
            }
          }
          else {
            // Kein Fehler
            value = validated;
          }
        }

        // Als Euro und Cent speichern
        let euros = value;
        let cents = euros*100;

        // Wert speichern
        context.commit({
          type: FORM_VALUE_SET,
          name: payload.name,
          value: {
            cent: cents,
            euro: euros
          }
        });

        // Fehler speichern
        context.commit({
          type: FORM_ERROR_SET,
          name: payload.name,
          value: error
        });
      },

      // Direktes Setzten des "eingegebenen" Wertes
      [FORM_RAW_SET] (context, payload) {
        context.commit(FORM_RAW_SET, payload);

        // Validierung überspringen und Wert direkt setzen
        context.commit({
          type: FORM_VALUE_SET,
          name: payload.name,
          value: payload.value
        });

        // Fehler zurücksetzen
        context.commit({
          type: FORM_ERROR_SET,
          name: payload.name,
          value: null
        });
      },

      // Formular für eine weitere Wohnung anfügen
      [FORM_ADD_WOHNUNG] (context, payload) {
        context.commit(FORM_ADD_WOHNUNG, payload);
      },

      // ... und entfernen
      [FORM_REMOVE_WOHNUNG] (context, payload) {
        context.commit(FORM_REMOVE_WOHNUNG, payload);
      },

      [FORM_ERROR_SET] (context, payload) {
        context.commit(FORM_ERROR_SET, payload);
      },

      [FORM_ERROR_RESET] (context) {
        context.commit(FORM_ERROR_RESET);
      },

      [FULL_RESET] (context) {
        context.commit(FULL_RESET);
      }
    },
    getters: {
      euro: (state) => (name) => {
        if (name in state.form_value
          && state.form_value[name] !== null
          && "euro" in state.form_value[name]) {
          return state.form_value[name].euro;
        }
        return null;
      },
      cent: (state) => (name) => {
        if (name in state.form_value
          && state.form_value[name] !== null
          && "cent" in state.form_value[name]) {
          return state.form_value[name].cent;
        }
        return null;
      },

      eingangsparameter: (state, getters) => {
        const bl = state.form_value.bl;

        // GA auf 1 fest bei BW, sonst Wert aus Formular nehmen
        const bbg_ga = bl === 8 ? 1 : state.form_value.bbg_ga;

        // Gebäude nur bei BLand != BW mitschicken
        let gebaeude = [];

        if (bl !== 8) {
          let bj = 0;
          if (state.form_value.wohnungen.length > 0) {
            let first_g = state.form_value.wohnungen[0];
            bj = first_g.baujahr;
          }

          // Remove errors in others
          /*for (let i = 0, l = state.form_value.wohnungen.length; i < l; i++) {
            if (i > 0) {
              state.form_error.wohnungen[i] = init_wohnung_error();
            }
          }*/

          // remove all vue observers by copying array and objects
          gebaeude = [...state.form_value.wohnungen].map(w => {
                          return {
                              baujahr: (!w.baujahr) ? bj : w.baujahr, // TODO nochmal prüfen!
                              gb_nb: w.gb_nb ? 1 : 0,
                              // set to 0 if empty
                              wf: w.wf === null ? 0 : w.wf,
                              // set to 0 if empty
                              wf_nf: w.wf_nf === null ? 0 : w.wf_nf,
                              garage: w.garage,
                              bgf: w.bgf,
                              ga_42: w.ga_42,
                          }
                      });
        }

        // Bei BY,NDS: e.N-WZ = 1, wenn WF_BY > 0, sonst 0
        let n_wz_by = 0;
        if (bl === 9 || bl === 3 || bl === 2) {
          const by_wf = gebaeude[0].wf;
          if (by_wf > 0) {
            n_wz_by = 1;
          }
        }

        // Update N-WZ wenn Bayern,NDS,HH
        let n_wz = (bbg_ga !== 0 && state.form_value.n_wz !== 0) ? 1 : 0;
        if (bl === 9 || bl === 3 || bl === 2) {
          n_wz = n_wz_by;
        }

        // Grundstücksfläche
        let gf = state.form_value.gf === null ? 0 : state.form_value.gf;
        if (bbg_ga === 4) {
          // Miteigentum
          // TODO Fehlerhandling!
          if (state.form_value.gf_miteigentum_anteilig === null) {
            gf = 0;
          }
          else {
            gf = Math.floor(state.form_value.gf_miteigentum_anteilig);
          }
        }
        else {
          gf = Math.floor(gf);
        }

        const params = {
          gf: gf,
          brw: getters.euro('brw'),
          dbrw: getters.euro('dbrw'),
          bbg_ga: bbg_ga,
          gebaeude: gebaeude, 
          foerderung: (bl === 8 && bbg_ga !== 0 && state.form_value.foerderung !== 0) ? 1 : 0,
          baudenkmal: (bl === 8 && bbg_ga !== 0 && state.form_value.baudenkmal !== 0) ? 1 : 0,
          n_wz: n_wz,
          mns: state.form_value.mns,
          bl: state.form_value.bl,
          hs: state.form_value.hs,
          f_luf: 0,   // TODO später
          gf_bebauung: state.form_value.gf_bebauung !== 0 ? 1 : 0,
          wl: state.form_value.wl !== 0 ? 1 : 0,
          abbruch: state.form_value.abbruch === true ? 1 : 0,
          abbruch_jahr: state.form_value.abbruch === true ? state.form_value.abbruch_jahr : null,
          kernsanierung: state.form_value.kernsanierung === true ? 1 : 0,
          kernsanierung_jahr: state.form_value.kernsanierung === true ? state.form_value.kernsanierung_jahr : null,
        }
        return params;
      },

      app_version: (state) => {
        return state.VUE_APP_RECHNER_WEB_VERSION + '.' + state.VUE_APP_RECHNER_WEB_GIT_HASH;
      },

      app_rechner_version: (state) => {
        return state.VUE_APP_RECHNER_VERSION;
      },

      app_shared_version: (state) => {
        return state.VUE_APP_RECHNER_SHARED_VERSION;
      },

      app_widgets_version: (state) => {
        return state.VUE_APP_RECHNER_WIDGETS_VERSION;
      },

      feedback_data: (state, getters) => {
        return {
          rechner: "grundsteuer.de",
          version: getters.app_version,
          "cordova_app": state.IS_CORDOVA_APP,
          werte: {
            ...getters.eingangsparameter
          }
        };
      }
    }
  })
