<template>
  <div>
    <RechnerInputGebArt
      v-if="is_swv"
      :name="name+'.ga_42'"
      placeholder="Gebäudeart suchen"
      label="Gebäudeart"
      info_title="Gebäudeart gemäß Anlage 42 BewG"
      info_text="Nach der Gebäudeart und dem Baujahr richten sich die Normalherstellungskosten. Ist eine bestimmte Gebäudeart hier nicht aufgeführt, ist eine vergleichbare Gebäudeart anzugeben. Ebenso ist Teileigentum in Abhängigkeit von der baulichen Gestaltung der passenden Gebäudeart zuzuordnen."
      @toggleInfoText="on_toggle_info_text"
    />

    <RechnerInputNumber
      v-if="(is_swv || is_first) && show_baujahr"
      :name="name+'.baujahr'"
      placeholder="Baujahr"
      suffix="Jahr"
      label="Baujahr"
      info_title="Baujahr"
      info_text="Das Jahr der Bezugsfertigkeit (Baujahr) wird u.a. für die Ermittlung der Restnutzungsdauer bzw. Alterswertminderung des Gebäudes benötigt.<br><br>Weitere Details finden Sie in dem Artikel zum <a href='https://grundsteuer.de/steuererklaerung/anlage-grundstueck/baujahr?mtm=rechner' target='_blank'>Baujahr und der Kernsanierung in der Grundsteuererklärung</a>."
      @toggleInfoText="on_toggle_info_text"
      :initial_value="initial_baujahr"
      :extra_button="show_baujahr_widget ? 'Ermitteln' : null"
      @extra_button_click="on_baujahr_clicked"
      info_line="Bei Kernsanierung auf <em>Ermitteln</em> klicken"
    />
    
    <RechnerInputCheckbox
      v-if="false"
      :name="name+'.gb_nb'"
      label="Nutzbarkeit des Gabäudes"
    />

    <RechnerInputNumber
		v-if="show_wf"
      :name="name+'.wf'"
      placeholder="Wohnfläche"
      label="Wohnfläche"
      suffix="m²"
      info_title="Wohnfläche"
      info_text="Die Wohnfläche wird zur Ermittlung der neuen Grundsteuer benötigt und bestimmt sich grundsätzlich nach der WoFlV. Die Angaben sind meistens aus dem Kaufvertrag, den Bauunterlagen oder einer eigenen Wohnflächenberechnung ersichtlich.<br><br>Weitere Details finden Sie in dem Artikel zur <a href='https://grundsteuer.de/steuererklaerung/anlage-grundstueck/wohnflaechenberechnung?mtm=rechner' target='_blank'>Wohnflächenberechnung für die Grundsteuererklärung</a>."
      @toggleInfoText="on_toggle_info_text"
      :initial_value="initial_wf"
      :allow_empty="true"
      :modal_html="wf_link"
      modal_html_title="Wohnfläche ermitteln"
      modal_button_text="Ermitteln"
    />

    <RechnerInputNumber
      v-if="show_wf_nf"
      :name="name+'.wf_nf'"
      placeholder="Nutzfläche"
      label="Nutzfläche"
      suffix="m²"
      info_title="Nutzfläche"
      info_text="Da Nutzflächen teilweise anders beurteilt werden als Wohnflächen, ist diese separat anzugeben.<br><br>Weitere Details finden Sie in dem Artikel zur <a href='https://grundsteuer.de/steuererklaerung/anlage-grundstueck/wohnflaeche-nutzflaeche?mtm=rechner' target='_blank'>Wohn- und Nutzfläche in der Grundsteuererklärung</a>."
      @toggleInfoText="on_toggle_info_text"	
      :allow_empty="true"  
    />

    <RechnerInputSelectButtons
		v-if="show_garage"
      :name="name+'.garage'"
      label="Anzahl Garagenstellplätze"
      :options='[
        { value: 0, text: "0"},
        { value: 1, text: "1"},
        { value: 2, text: "2"},
        { value: 3, text: "3"},
      ]'
      info_title="Anzahl Garagenstellplätze"
      info_text="Je Garagenstellplatz (Einzelgarage oder Tiefgarage) erhöht sich der Rohertrag des Gebäudes pauschal um 28 bis 49 Euro monatlich, abhängig von der Mietniveaustufe der Gemeinde.<br><br>Weitere Details finden Sie in dem Artikel zu <a href='https://grundsteuer.de/steuererklaerung/anlage-grundstueck/garagen-parkplaetze?mtm=rechner' target='_blank'>Garagen in der Grundsteuererklärung</a>."
      @toggleInfoText="on_toggle_info_text"
    />

    <RechnerInputNumber
      v-if="is_swv"
      :name="name+'.bgf'"
      placeholder="Brutto-Grundfläche"
      label="Brutto-Grundfläche"
      suffix="m²"
      info_title="Brutto-Grundfläche"
			info_text="Die Brutto-Grundfläche ist die Summe der bezogen auf die jeweilige Gebäudeart marktüblich nutzbaren Grundflächen aller Grundrissebenen eines Bauwerks. <br><br>In Anlehnung an die DIN 277-1:2005-02 sind bei den Grundflächen nur solche zugrunde zu legen, die überdeckt und allseitig oder nicht allseitig in voller Höhe umschlossen sind. Balkone und nicht überdeckte Grundflächen bleiben unberücksichtigt. Für die Ermittlung der Brutto-Grundfläche sind die äußeren Maße der Bauteile einschließlich Bekleidung, z. B. Putz und Außenschalen mehrschaliger Wandkonstruktionen, in Höhe der Bodenbelagsoberkanten anzusetzen.<br><br>Nicht zur Brutto-Grundfläche gehören z. B. Flächen von Spitzböden und Kriechkellern, Flächen, die ausschließlich der Wartung, Inspektion und Instandsetzung von Baukonstruktionen und technischen Anlagen dienen, sowie Flächen unter konstruktiven Hohlräumen, z.B. über abgehängten Decken."
      @toggleInfoText="on_toggle_info_text"
      :allow_empty="false"
    />
  </div>
</template>

<script>
import RechnerInputNumber from './RechnerInputNumber';
import RechnerInputCheckbox from './RechnerInputCheckbox';
import RechnerInputSelectButtons from './RechnerInputSelectButtons';
import RechnerInputGebArt from './RechnerInputGebArt';

export default {
  name: "RechnerInputWohnung",
  components: {
    RechnerInputNumber,
    RechnerInputCheckbox,
    RechnerInputSelectButtons,
    RechnerInputGebArt
  },
  props: {
    name: String,
    label: String,
    _key: Number,

    initial_baujahr: String,
    initial_wf: String,
    
    show_baujahr: Boolean,
    show_wf: Boolean,
    show_wf_nf: Boolean,
    show_garage: Boolean,
	show_baujahr_widget: Boolean,

    is_first: Boolean,
    is_swv: Boolean,
  },
  computed: {
    domain: function() {
      return this.$store.state.URL_DOMAIN;
    },

    wf_link: function() {
		return '<img style="margin-top: -16px !important;" src="https://grundsteuer.de/sites/default/files/2022-06/Wohnflaeche-berechnen-Grundsteuer.png" />'
    }
  },
  methods: {
    // Info Buttonclick nach oben weiterleiten
    on_toggle_info_text: function(info_title, info_text) {
      this.$emit('toggleInfoText', info_title, info_text);
    },

    on_baujahr_clicked: function() {
      this.$emit('on_baujahr_clicked');
    }
  }
};
</script>

<style scoped lang="scss">
</style>
