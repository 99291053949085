<template>
  <div
    class="form-horizontal rechner-form"
    id="'in_group_' + name"
  >
    <div class="form-group">
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <label :for="name" class="pull-left control-label rechner-form-label">
          {{ label }}
          <a v-if="info_text" class="rechner-form-info-button" @click="on_info_clicked"><span class="glyphicon glyphicon-info-sign"></span></a>
        </label>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <input
          type="checkbox"
          :id="name"
          :name="name"
          v-model="checked"
          class="pull-right"
        >
      </div>
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get';
import RechnerInputWithInfoText from '../RechnerInputWithInfoText';
import {FORM_UPDATE} from '../../store/mutations';

export default {
  name: "RechnerInputCheckbox",
  mixins: [
    RechnerInputWithInfoText
  ],
  props: {
    name: String,
    label: String,

    // Initialier Wert
    initial_value: Boolean
  },
  computed: {
    checked: {
      get() {
        return _get(this.$store.state.form_raw, this.name);
      },
      set(value) {
        this.$store.dispatch({
          type: FORM_UPDATE,
          name: this.name,
          value
        });
      }
    }
  },
  mounted: function() {
    if (this.initial_value) {
      this.checked = this.initial_value;
    }
  }
};
</script>

<style scoped lang="scss">
</style>
