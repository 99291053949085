<template>
<div
  :class="{
    'modal-open': modal_info_is_showing || modal_bbg_ga_is_showing || modal_baujahr_is_showing || modal_miteigentum_is_showing
  }"
>
  <div v-if="modal_info_is_showing || modal_bbg_ga_is_showing || modal_baujahr_is_showing || modal_miteigentum_is_showing" class="modal-backdrop fade in"></div>

  <!-- Grundstücksart modal -->
  <div
      class="modal fade"
      :class="{'in': modal_bbg_ga_is_showing}"
      :style="{'display': modal_bbg_ga_is_showing ? 'block' : 'none'}"
      id="modal_bbg_ga"
      tabindex="-1"
      role="dialog" aria-labelledby="modal_bbg_ga_title"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header" style="border-bottom: 0 !important; padding-bottom: 32px;">
          <button type="button" class="close" @click.prevent="modal_bbg_ga_is_showing=false" aria-label="Schließen"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="modal_bbg_ga_title">Grundstücksart bestimmen</h4>
        </div>
        <div class="modal-body" style="padding: 0; margin-top: -20px;">
          <Grundstuecksart
            :key="grundstuecksart_count"
            :integration_mode="true"
            @on_grundstuecksart_ergebnis="on_grundstuecksart_ergebnis"
            :initial_bl="this.bl"
          />
        </div>
        <div v-if="grundstuecksart_ergebnis" class="modal-footer" style="border-top: 0 !important;">
          <div v-if="!grundstuecksart_show_use_button" style="text-align: center; margin-bottom: 8px;">
            <p>
              Diese Grundstücksart wird vom Rechner leider nicht unterstützt.
            </p>
          </div>
          <div >
            <button type="button" class="btn btn-default" @click.prevent="modal_bbg_ga_is_showing=false">Schließen</button>
            <button v-if="grundstuecksart_show_use_button" type="button" class="btn btn-primary" @click.prevent="on_grundstuecksart_ergebnis_use">In Rechner übernehmen</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Baujahr modal -->
  <div
      class="modal fade"
      :class="{'in': modal_baujahr_is_showing}"
      :style="{'display': modal_baujahr_is_showing ? 'block' : 'none'}"
      id="modal_baujahr"
      tabindex="-1"
      role="dialog" aria-labelledby="modal_baujahr_title"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header" style="border-bottom: 0 !important; padding-bottom: 32px;">
          <button type="button" class="close" @click.prevent="modal_baujahr_is_showing=false" aria-label="Schließen"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="modal_baujahr_title">Baujahr, Kernsanierung und Abbruchverpflichtung</h4>
        </div>
        <div class="modal-body" style="padding: 0; margin-top: -20px;">
          <Baujahr
            :key="baujahr_count"
            :integration_mode="true"
            @on_baujahr_ergebnis="on_baujahr_ergebnis"
            :initial_baujahr="this.baujahr"
            :bbg_ga="bbg_ga"
            :ga_42="ga_42"
          />
        </div>
        <div v-if="baujahr_ergebnis" class="modal-footer" style="border-top: 0 !important;">
          <div >
            <button type="button" class="btn btn-default" @click.prevent="modal_baujahr_is_showing=false">Schließen</button>
            <button type="button" class="btn btn-primary" @click.prevent="on_baujahr_ergebnis_use">In Rechner übernehmen</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Miteigentum modal -->
  <div
      class="modal fade"
      :class="{'in': modal_miteigentum_is_showing}"
      :style="{'display': modal_miteigentum_is_showing ? 'block' : 'none'}"
      id="modal_miteigentum"
      tabindex="-1"
      role="dialog" aria-labelledby="modal_miteigentum_title"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header" style="border-bottom: 0 !important; padding-bottom: 32px;">
          <button type="button" class="close" @click.prevent="modal_miteigentum_is_showing=false" aria-label="Schließen"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="modal_miteigentum_title">Miteigentumsanteil</h4>
        </div>
        <div class="modal-body" style="padding: 0; margin: 0 15px 0 15px;">
          <RechnerInputNumber
            v-if="bbg_ga === 4"
            name="gf_miteigentum"
            placeholder="Grundstücksfläche"
            label="Grundstücksfläche"
            suffix="m²"
            error_message="Bitte einen Wert für die Grundstücksfläche in m² eingeben."
            info_title="Grundstücksfläche"
            info_text="Bei Wohnungseigentum ist an dieser Stelle die gesamte Grundstücksfläche anzugeben. Im nächsten Schritt kann der Miteigentumsanteil angegeben werden, sodass nur die anteilige Grundstücksfläche bei der Ermittlung des Bodenwerts berücksichtigt wird."
            @toggleInfoText="on_toggle_info_text"
            :initial_value="initial_gf_miteigentum"
            :allow_empty="true"
            info_line="Gesamte Grundstücksfläche angeben"
          />

          <RechnerInputTwoNumbers
            v-if="bbg_ga === 4"
            name="gf_miteigentum_zn"
            label="Miteigentumsanteil"
            name1="gf_miteigentum_z"
            placeholder1="Zähler"
            :initial_value1="initial_gf_miteigentum_z"
            :allow_empty1="true"
            :disallow_zero1="true"
            name2="gf_miteigentum_n"
            placeholder2="Nenner"
            initial_value2="1000"
            :allow_empty2="false"
            :disallow_zero2="true"
            error_message="Bitte einen gültigen Wert für den Miteigentumsanteil eingeben."
            info_title="Miteigentumsanteil"
            info_text="Die Angabe des Miteigentumsanteils ist erforderlich, damit nur die anteilige Grundstücksfläche bei der Berechnung berücksichtigt wird. Der Miteigentumsanteil ergibt sich entweder aus dem Grundbuchauszug oder der Teilungserklärung."
            @toggleInfoText="on_toggle_info_text"
          />
        </div>
        <div class="modal-footer" style="border-top: 0 !important;">
          <div >
            <button type="button" class="btn btn-default" @click.prevent="modal_miteigentum_is_showing=false">Schließen</button>
            <button type="button" class="btn btn-primary" @click.prevent="on_miteigentum_ergebnis_use">In Rechner übernehmen</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Info text modal -->
  <div
      class="modal fade"
      :class="{'in': modal_info_is_showing}"
      :style="{'display': modal_info_is_showing ? 'block' : 'none'}"
      id="modal_info"
      tabindex="-1"
      role="dialog" aria-labelledby="modal_info_title"
  >
    <div
      class="modal-dialog"
      :class="{'modal-md': modal_detail_id}"
      role="document"
    >
      <div class="modal-content">
        <div v-if="modal_info_title || modal_detail_id" class="modal-header">
          <button type="button" class="close" @click.prevent="modal_info_is_showing=false" aria-label="Schließen"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="modal_info_title">
            <span v-if="modal_detail_id && modal_detail_id.startsWith('swv_')">
				<RechnerErgebnisSWV
					:modal_detail_id="modal_detail_id"
					:only_title="true"
					:zs_bl="zs_bl"
					:as="as"
				/>
			</span>
            <span v-else-if="modal_detail_id && modal_detail_id.startsWith('bw_')">
				<RechnerErgebnisBW
					:modal_detail_id="modal_detail_id"
					:only_title="true"
					:zs_bl="zs_bl"
					:as="as"
				/>
			</span>
            <span v-else-if="modal_detail_id && modal_detail_id.startsWith('by_')">
				<RechnerErgebnisBY
					:modal_detail_id="modal_detail_id"
					:only_title="true"
					:zs_bl="zs_bl"
					:as="as"
				/>
			</span>
            <span v-else-if="modal_detail_id && modal_detail_id.startsWith('hh_')">
				<RechnerErgebnisHH
					:modal_detail_id="modal_detail_id"
					:only_title="true"
					:zs_bl="zs_bl"
					:as="as"
				/>
			</span>
            <span v-else-if="modal_detail_id && modal_detail_id.startsWith('he_')">
				<RechnerErgebnisHE
					:modal_detail_id="modal_detail_id"
					:only_title="true"
					:zs_bl="zs_bl"
					:as="as"
				/>
			</span>
            <span v-else-if="modal_detail_id && modal_detail_id.startsWith('nds_')">
				<RechnerErgebnisNDS
					:modal_detail_id="modal_detail_id"
					:only_title="true"
					:zs_bl="zs_bl"
					:as="as"
				/>
			</span>
            <span v-else-if="modal_detail_id">
				<RechnerErgebnisBM
					:modal_detail_id="modal_detail_id"
					:only_title="true"
					:zs_bm="zs_bm"
					:as="as"
				/>
			</span>
            <span v-else>
              {{ modal_info_title }}
            </span>
          </h4>
        </div>
        <div class="modal-body">
          <p id="modal_info_text">
            <span v-if="modal_detail_id && modal_detail_id.startsWith('swv_')">
				<RechnerErgebnisSWV
					:modal_detail_id="modal_detail_id"
					:zs_bl="zs_bl"
					:as="as"
				/>
			</span>
            <span v-else-if="modal_detail_id && modal_detail_id.startsWith('bw_')">
				<RechnerErgebnisBW
					:modal_detail_id="modal_detail_id"
					:zs_bl="zs_bl"
					:as="as"
				/>
			</span>
            <span v-else-if="modal_detail_id && modal_detail_id.startsWith('by_')">
				<RechnerErgebnisBY
					:modal_detail_id="modal_detail_id"
					:zs_bl="zs_bl"
					:as="as"
				/>
			</span>
            <span v-else-if="modal_detail_id && modal_detail_id.startsWith('hh_')">
				<RechnerErgebnisHH
					:modal_detail_id="modal_detail_id"
					:zs_bl="zs_bl"
					:as="as"
				/>
			</span>
            <span v-else-if="modal_detail_id && modal_detail_id.startsWith('he_')">
				<RechnerErgebnisHE
					:modal_detail_id="modal_detail_id"
					:zs_bl="zs_bl"
					:as="as"
				/>
			</span>
            <span v-else-if="modal_detail_id && modal_detail_id.startsWith('nds_')">
				<RechnerErgebnisNDS
					:modal_detail_id="modal_detail_id"
					:zs_bl="zs_bl"
					:as="as"
				/>
			</span>
            <span v-else-if="modal_detail_id">
				<RechnerErgebnisBM
					:modal_detail_id="modal_detail_id"
					:zs_bm="zs_bm"
					:as="as"
					:bbg_ga="bbg_ga"
					:BBG_GA_NAMES_DETAILS="BBG_GA_NAMES_DETAILS"
					:bl="bl"
					:BL_MAP="BL_MAP"
					:es="es"
				/>
			</span>
            <span v-else>
              <p v-html="modal_info_text"></p>
            </span>
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click.prevent="modal_info_is_showing=false">OK</button>
        </div>
      </div>
    </div>
  </div>

  <div class="row" id="rechner_top_div" ref="rechner_top">
    <div v-if="show_feedback_form">
      <FeedbackForm v-if="show_feedback_form" :ags="ags" />
      <button class="btn btn-link btn-xs" @click="close_feedback_form">Schließen</button>
    </div>

    <div v-if="!show_feedback_form">

    <!-- Infotext Beginn -->
    <div v-if="!is_embedded" id="block-startseiteinfotext" class="container service-box has-bg contextual-region block block-block-content block-block-contenta014ae50-e360-413d-b6f4-52a35133df1c clearfix" style="padding: 20px 40px 20px !important;">
      <div class="block-inner">
        <div class="field field--name-body field--type-text-with-summary field--label-hidden field--item">
          <p>
            <a class="rechner-form-info-button" style="float: right;" @click='on_toggle_info_text("Funktionsbeschreibung und Haftungsausschluss", infobox_text_html)'>
              <span class="glyphicon glyphicon-info-sign" style="font-size: 18px;"></span>
            </a>
            Mit dem Grundsteuer Rechner berechnen Sie bequem die neue Grundsteuer. Der Rechner deckt für Grundvermögen die häufigsten Fälle ab. Über die Infobuttons erhalten Sie zusätzliche Erläuterungen, u.a. zu den benötigten Eingaben. Eine Hilfestellung zur Bescheidprüfung im Bundesmodell finden Sie im Artikel zum <a href="/bescheide/grundsteuerwertbescheid" target="_blank">Bescheid über den Grundsteuerwert</a>.
          </p>
        </div>
      </div>
    </div>
    <!-- Infotext Ende -->
    <div
      id="container_ergebnis_klein" class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-6 col-md-offset-0 visible-xs sr_ergebnis_container">
      <div v-if="results_calculated >= 1" class="panel panel-default sr-panel" :class="{'panel-danger': has_errors || exception}">
				<div class="panel-heading" id="panel-header-ergebnis-klein">
					<h3 class="panel-title">
						<table width="100%">
							<tbody>
                <tr v-for="(r, key) in result_mobile" :key="key">
                  <td v-html="r.name"></td>
                  <td
                    v-if="r.html_line && (current_widget_show_direct || results_calculated >= 1) && current_tid && html_lines && Object.keys(html_lines).length > 0 && current_tid in html_lines"  
                  >
                    <div
                      style="margin-left: 16px; margin-right: 16px;"
                      v-html="html_lines[current_tid]"
                    >
                    </div>
                  </td>
                  <td class="text-right">{{ r.value | pretty_print(r.pretty, r.suffix) }}</td>
                </tr>
							</tbody>
						</table>
						<button type="button" @click.prevent="show_mobile_result=!show_mobile_result" class="btn btn-link">{{ show_mobile_result ? "Weniger" : "Mehr" }} anzeigen</button>
					</h3>
				</div>

        <div :class="{ 'collapse': !show_mobile_result }" id="panel-body-ergebnis-klein">
          <table class="sr-result-table table table-condensed table-hover">
            <tbody>
              <tr v-for="(r, key) in result_filtered" :key="key">
                <td :class="{ 'important': r.important }">
					<span v-html="r.name"></span>
                  <span v-if="r.name_value">
                    ({{ r.name_value | pretty_print(r.name_pretty, r.name_suffix) }})
                  </span>
                  <a v-if="r.info_text && r.info_title" class="rechner-form-info-button" style="float: none;" @click='on_toggle_info_text(r.info_title, r.info_text)'>
                    <span class="glyphicon glyphicon-info-sign" style="font-size: 16px;"></span>
                  </a>
                  <span
                    v-if="r.html_line && (current_widget_show_direct || results_calculated >= 1) && current_tid && html_lines && Object.keys(html_lines).length > 0 && current_tid in html_lines"
                    class="not_important"
                    v-html="html_lines[current_tid]"
                  >
                  </span>
                </td>
                <td class="text-right" :class="{ 'important': r.important }">
                  {{ r.value | pretty_print(r.pretty, r.suffix) }}
                  <a v-if="r.ergebnis_detail && zs_ok" class="rechner-form-info-button" style="float: none;" @click='on_toggle_detail_text(r.ergebnis_detail)'>
                    <span class="glyphicon glyphicon-info-sign" style="font-size: 16px;"></span>
                  </a>
                </td>
              </tr>
              <tr v-if="show_feedback_form_link">
                <td></td>
                <td class="text-right small text-muted">
                  <button class="btn btn-link btn-xs" @click="toggle_feedback_form">Fehler in der Berechnung?</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div
      id="container_ergebnis_normal" class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-6 col-md-offset-0 hidden-xs sr_ergebnis_container">
      <div class="panel panel-default sr-panel" id="sr_left_resultbox" :class="{'panel-danger': has_errors || exception}">
				<div class="panel-heading" id="panel-header-ergebnis-normal">
					<h3 class="panel-title text-left">Ergebnis</h3>
				</div>

				<table class="sr-result-table table table-hover">
          <tbody>
            <tr v-for="(r, key) in result_filtered" :key="key">
              <td :class="{ 'important': r.important }">
                  <span v-html="r.name"></span>
                <span v-if="r.name_value">
                  ({{ r.name_value | pretty_print(r.name_pretty, r.name_suffix) }})
                </span>
                <a v-if="r.info_text && r.info_title" class="rechner-form-info-button" style="float: none;" @click='on_toggle_info_text(r.info_title, r.info_text)'><span class="glyphicon glyphicon-info-sign" style="font-size: 16px;"></span></a>
                <span
                  v-if="r.html_line && (current_widget_show_direct || results_calculated >= 1) && current_tid && html_lines && Object.keys(html_lines).length > 0 && current_tid in html_lines"
                  class="not_important"
                  v-html="html_lines[current_tid]"
                >
                </span>
              </td>
              <td class="text-right" :class="{ 'important': r.important }">
                {{ r.value | pretty_print(r.pretty, r.suffix) }}
                <a v-if="r.ergebnis_detail && zs_ok" class="rechner-form-info-button" style="float: none;" @click='on_toggle_detail_text(r.ergebnis_detail)'>
                  <span class="glyphicon glyphicon-info-sign" style="font-size: 16px;"></span>
                </a>
              </td>
            </tr>
            <tr v-if="show_feedback_form_link">
              <td></td>
              <td class="text-right small text-muted">
                <button class="btn btn-link btn-xs" @click="toggle_feedback_form">Fehler in der Berechnung?</button>
              </td>
            </tr>
          </tbody>
				</table>
      </div>

      <!--<div v-if="!is_mobile && (current_widget_show_direct || results_calculated >= 1)">-->
      <div v-if="!is_mobile">
        <WidgetContainer
          :wid="wid"
          :bl="bl"
        />
      </div>
    </div>

    <div class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-6 col-md-offset-0 sr_input_container">
      <div class="panel panel-default sr-panel" id="sr_right_inputbox" :class="{'panel-danger': has_errors || exception}">
				<div class="panel-heading">
					<h3 class="panel-title text-left">Ihre Eingabe</h3>
				</div>

				<div class="panel-body">
          <RechnerInputGemeinde
            name="gemeinde"
            placeholder="Gemeinde suchen"
            label="Gemeinde"
            info_title="Gemeinde"
            info_text="Nach Auswahl der Gemeinde wird der Hebesatz und die Mietniveaustufe festgelegt. Sollten diese Angaben im Einzelfall nicht aktuell sein, können diese manuell angepasst werden."
            @toggleInfoText="on_toggle_info_text"
            :initial_bl="initial_bl"
            :initial_hs="initial_hs"
            :initial_ags="initial_ags"
          />
         
          <RechnerInputSelect
            v-if="bl !== 8 && bl !== 9 && bl !== 3 && bl !== 6 && bl !== 2"
            name="bbg_ga"
            label="Grundstücksart"
            :options='[
              { value: 0, text: "Unbebautes Grundstück"},
              { value: 1, text: "Einfamilienhaus"},
              { value: 2, text: "Zweifamilienhaus"},
              { value: 3, text: "Mietwohngrundstück"},
              { value: 4, text: "Wohnungseigentum"},
              { value: 5, text: "Teileigentum"},
              { value: 6, text: "Geschäftsgrundstücke"},
              { value: 7, text: "Gemischt genutzte Grundstücke"},
              { value: 8, text: "Sonstige bebaute Grundstücke"},
            ]'
            info_title="Grundstücksart"
            info_text="Die Berechnung der Grundsteuer unterscheidet sich je nach Grundstücksart.<br>Wohngrundstücke wie Ein- und Zweifamilienhäuser, Mietwohngrundstücke und Wohnungseigentum werden im Ertragswertverfahren bewertet. Alle anderen Grundstücke (Nichtwohngrundstücke) wie Geschäftsgrundstücke, gemischt genutzte Grundstücke, sonstige bebaute Grundstücke und Teileigentum werden im Sachwertverfahren bewertet. Mit einem Klick auf Ermitteln neben dem Auswahlfeld kann die passende Grundstücksart bestimmt werden.<br><br>Weitere Details finden Sie in dem Artikel zur <a href='https://grundsteuer.de/steuererklaerung/anlage-grundstueck/grundstuecksart?mtm=rechner' target='_blank'>Grundstücksart</a>."
            @toggleInfoText="on_toggle_info_text"
            :initial_value="initial_bbg_ga"
            extra_button="Ermitteln"
            @extra_button_click="on_bbg_ga_clicked"
          />
          
          <RechnerInputEuroCent
            v-if="bl !== 9 && bl !== 2"
            name="brw"
            placeholder="Bodenrichtwert"
            label="Bodenrichtwert"
            error_message="Bitte einen Betrag für den Bodenrichtwert angeben."
            info_title="Bodenrichtwert"
            info_text="Zur Ermittlung des Bodenwerts ist der Bodenrichtwert zum Bewertungsstichtag anzugeben. Die Bodenrichtwerte werden von den Gutachterausschüssen veröffentlicht und sind für die meisten Bundesländer kostenlos abrufbar. Per Klick auf Ermitteln gelangen Sie direkt auf die jeweilige Website zum Abruf der Bodenrichtwerte.<br><br>Weitere Details finden Sie in dem Artikel zum <a href='https://grundsteuer.de/steuererklaerung/anlage-grundstueck/bodenrichtwert?mtm=rechner' target='_blank'>Bodenrichtwert in der Grundsteuererklärung</a>."
            @toggleInfoText="on_toggle_info_text"
            :initial_value="initial_brw"
            :external_link_button='brw_link_for_bl'
            :unit_name='"€/m²"'
          />

          <RechnerInputEuroCent
            v-if="bl === 3 || bl === 6"
            name="dbrw"
            placeholder="⌀ Bodenrichtwert in Euro"
            label="Durchschnittlicher Bodenrichtwert"
            error_message="Bitte einen Betrag für den ⌀ Bodenrichtwert angeben."
            info_title="Durchschnittlicher Bodenrichtwert"
            info_text="Darunter versteht sich der durchschnittliche Bodenrichtwert in der Gemeinde. Dieser ergibt sich als Median aller dieser in der Gemeinde liegenden Bodenrichtwerte."
            @toggleInfoText="on_toggle_info_text"
            :initial_value="initial_dbrw"
            :external_link_button='dbrw_link_for_bl'
            :unit_name='"€/m²"'
          />
          
          <RechnerInputNumber
            v-if="bbg_ga !== 4"
            name="gf"
            placeholder="Grundstücksfläche"
            label="Grundstücksfläche"
            suffix="m²"
            error_message="Bitte einen Wert für die Grundstücksfläche in m² eingeben."
            info_title="Grundstücksfläche"
            info_text="Durch die Mulitplikation der Grundstücksfläche mit dem Bodenrichtwert wird der Bodenwert ermittelt. Bei Wohnungseigentum ist nur die anteilige Grundstücksfläche anzugeben, die sich anhand der Miteigentumsanteile ermittelt.<br><br>Weitere Details finden Sie in dem Artikel zur <a href='https://grundsteuer.de/steuererklaerung/anlage-grundstueck/grundstuecksflaeche?mtm=rechner' target='_blank'>Grundstücksfläche in der Grundsteuererklärung</a>."
            @toggleInfoText="on_toggle_info_text"
            :initial_value="initial_gf"
            :allow_empty="true"
          />

          <RechnerInputSelectButtons
            v-if="bl === 2"
            name="wl"
            placeholder="Wohnlage"
            label="Wohnlage"
            :initial_value="initial_wl"
            :options='[
              { value: 1, text: "Normal"},
              { value: 0, text: "Gut"}
            ]'
            info_title="Wohnlage"
            info_text="Für normale Wohnlagen reduziert sich die Messzahl für den Äquivalenzbetrag der Wohnfläche um 25 %. In guten Wohnlagen erfolgt keine Reduzierung. Die Stadt Hamburg erlässt ein Verzeichnis, aus dem sich die Wohnlage ergibt. Fehlt die Angabe einer Wohnlage, wird eine normale Wohnlage vermutet."
            @toggleInfoText="on_toggle_info_text"
          />

          <RechnerInputSelectButtons
            v-if="(bl === 9 || bl === 3) && gf >= 10000"
            name="gf_bebauung"
            label="Gering bebaut oder befestigt"
            :initial_value="initial_gf_bebauung"
            :options='[
              { value: 1, text: "Ja"},
              { value: 0, text: "Nein"}
            ]'
            info_title="Gering bebaut oder befestigt"
            info_text="Ist die Grundstücksfläche zu mindestens 90 % weder bebaut noch befestigt, handelt es sich um ein gering bebautes oder befestigtes Grundstück."
            @toggleInfoText="on_toggle_info_text"
          />

          <RechnerInputNumber
            v-if="bbg_ga === 4"
            name="gf_miteigentum_anteilig"
            placeholder="anteilige Grundstücksfläche"
            label="Anteilige Grundstücksfläche"
            suffix="m²"
            error_message="Bitte einen Wert für die anteilige Grundstücksfläche in m² eingeben."
            info_title="Anteilige Grundstücksfläche"
            info_text="Durch die Mulitplikation der Grundstücksfläche mit dem Bodenrichtwert wird der Bodenwert ermittelt. Bei Wohnungseigentum ist nur die anteilige Grundstücksfläche anzugeben, die sich anhand der Miteigentumsanteile ermittelt."
            @toggleInfoText="on_toggle_info_text"
            :initial_value="initial_gf_miteigentum_anteilig"
            :allow_empty="true"
            modal_layer="gf_miteigentum_anteilig"
            modal_button_text="Ermitteln"
            @toggleLayer="on_toggle_layer_miteigentum"
          />

          <RechnerInputSelectButtons
            v-if="bl === 8"
            name="n_wz"
            label="Überwiegende Nutzung zu Wohnzwecken"
            :initial_value="initial_n_wz"
            :options='[
              { value: 1, text: "Ja"},
              { value: 0, text: "Nein"}
            ]'
            info_title="Nutzung zu Wohnzwecken"
            info_text="Eine überwiegende Nutzung zu Wohnzwecken liegt vor, wenn die Wohnnutzung an der gesamten Wohn- und Nutzfläche mehr als 50% ausmacht."
            @toggleInfoText="on_toggle_info_text"
          />

          <RechnerInputSelectButtons
            v-if="false"
            name="foerderung"
            label="Geförderter Wohnraum"
            :initial_value="initial_foerderung"
            :options='[
              { value: 1, text: "Ja"},
              { value: 0, text: "Nein"}
            ]'
            info_title="Geförderter Wohnraum"
            info_text="Geförderter Wohnraum ist begünstigt, wenn eine Förderzusage für sozialen Wohnungsbau oder kommunale Wohnraumförderung erteilt wurde."
            @toggleInfoText="on_toggle_info_text"
          />
          
          <RechnerInputSelectButtons
            v-if="false"
            name="baudenkmal"
            label="Denkmalgeschützt"
            :initial_value="initial_baudenkmal"
            :options='[
              { value: 1, text: "Ja"},
              { value: 0, text: "Nein"}
            ]'
            info_title="Denkmalschutz"
            info_text="Begünstigt werden Denkmalgeschützte Gebäude."
            @toggleInfoText="on_toggle_info_text"
          />
          
          <div
            class="row form-group"
            id="in_group_wohnungen"
            v-if="wohnungen_max > 0"
          >
            <div class="col-xs-12">
              <div
                v-for="(g, index) in gebaeude"
                :key="g._key"
                :class="{'callout wohnungs_box': index > 0}"
              >
                <label v-if="wohnungen_max > 1" style="margin-bottom: 8px;">
                  <em>Wohnung {{index+1}}</em>
                </label>
                <RechnerInputWohnung
                  :_key="g._key"
                  :name="'wohnungen['+index+']'"
                  label="Wohnung"
                  @toggleInfoText="on_toggle_info_text"
                  @on_baujahr_clicked="on_baujahr_clicked"
                  :initial_baujahr="initial_baujahr"
                  :initial_wf="initial_wf"
                  :show_baujahr="bl !== 8 && bl !== 9 && bl !== 3 && bl !== 6 && bl !== 2"
                  :show_wf="bl !== 8 && !is_swv"
                  :show_wf_nf="bl === 9 || bl === 3 || bl === 6 || bl === 2"
                  :show_garage="(bl !== 8 && bl !== 9 && bl !== 3 && bl !== 6 && bl !== 2) && !is_swv"
                  :is_first="index === 0"
                  :show_baujahr_widget="true"
                  :is_swv="is_swv"
                />
                <div v-if="wohnungen_max > 1 && index > 0 && wohnungen_can_remove">
                  <br />
                  <div class="btn-group">
                    <button
                      @click="remove_gebaeude(index)"
                      type="button"
                      class="btn btn-danger"
                    >
                      Wohnung {{ index+1 }} entfernen
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="wohnungen_can_add" class="btn-group">
                <button
                  @click="add_gebaeude"
                  type="button"
                  class="btn btn-default"
                  :disabled="!wohnungen_can_add"
                >
                  Wohnung hinzufügen ({{ gebaeude.length }}/{{ wohnungen_max }})
                </button>
              </div>
            </div>
          </div>          

          <br />
          <div class="clearfix" :class="{'has-error': exception, 'callout': exception, 'callout-red': exception}">
            <div class="row">
              <div class="col-xs-12 col-sm-offset-3 col-sm-6 col-md-offset-2 col-md-8 col-lg-offset-3 col-lg-6">
                <div class="form-group">
                  <div class="btn-group btn-group-justified">
                    <div class="btn-group">
                      <button type="button" id="btn_berechnen" class="btn btn-primary btn-lg" @click="on_berechnen_btn">Berechnen</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-xs-12" v-if="exception && exception_message">
                <p>{{ exception_message }}</p>
                <p>Bitte laden sie die Seite neu und versuchen sie es erneut. Kontaktieren sie uns, falls dieser Fehler weiterhin auftritt.</p>
              </div>
            </div>
					</div>
				</div>
      </div>
    </div>
    
    <div v-if="is_mobile">
      <WidgetContainer
        :wid="wid"
        :bl="bl"
      />
    </div>

    </div>
  </div>

</div>
</template>

<script>
import {runden_volleEuro} from "@steuerrechner/steuerrechner-shared"
import Grundstuecksart from '@steuerrechner/grundsteuer-widgets/src/grundstuecksart/Grundstuecksart.vue';
import Baujahr from '@steuerrechner/grundsteuer-widgets/src/baujahr/Baujahr.vue';
import axios from 'axios';
import { grundsteuerrechner } from "@steuerrechner/grundsteuer-rechner";
import RechnerInputGemeinde from "./RechnerInputs/RechnerInputGemeinde";
import RechnerInputEuroCent from "./RechnerInputs/RechnerInputEuroCent";
import RechnerInputNumber from "./RechnerInputs/RechnerInputNumber";
import RechnerInputTwoNumbers from "./RechnerInputs/RechnerInputTwoNumbers";
import RechnerInputSelect from "./RechnerInputs/RechnerInputSelect";
import RechnerInputSelectButtons from "./RechnerInputs/RechnerInputSelectButtons";
import RechnerInputWohnung from "./RechnerInputs/RechnerInputWohnung";
import FeedbackForm from "./FeedbackForm";
import { FORM_RAW_SET, FORM_ADD_WOHNUNG, FORM_REMOVE_WOHNUNG, FORM_ERROR_SET, FORM_ERROR_RESET } from '../store/mutations';
import WidgetContainer from './WidgetContainer.vue';
import RechnerErgebnisBW from "./RechnerErgebnisse/RechnerErgebnisBW";
import RechnerErgebnisBY from "./RechnerErgebnisse/RechnerErgebnisBY";
import RechnerErgebnisHH from "./RechnerErgebnisse/RechnerErgebnisHH";
import RechnerErgebnisHE from "./RechnerErgebnisse/RechnerErgebnisHE";
import RechnerErgebnisNDS from "./RechnerErgebnisse/RechnerErgebnisNDS";
import RechnerErgebnisSWV from "./RechnerErgebnisse/RechnerErgebnisSWV";
import RechnerErgebnisBM from "./RechnerErgebnisse/RechnerErgebnisBM";

// Grundsteuerrechner Formular
export default {
  name: "Grundsteuerrechner",
  components: {
    FeedbackForm,
    RechnerInputGemeinde,
    RechnerInputEuroCent,
    RechnerInputNumber,
    RechnerInputTwoNumbers,
    RechnerInputSelect,
    RechnerInputSelectButtons,
    RechnerInputWohnung,
    Grundstuecksart,
    Baujahr,
    WidgetContainer,
	RechnerErgebnisBW,
	RechnerErgebnisBY,
	RechnerErgebnisHH,
	RechnerErgebnisHE,
	RechnerErgebnisNDS,
	RechnerErgebnisSWV,
	RechnerErgebnisBM,
},
  // TODO Von "außen" übergebene Parameter
  props: {
    is_embedded: Boolean,
    va: Number,
    bwst: Number,
    initial_bl: Number,
    initial_ags: String,
    initial_hs: Number,
    initial_brw: String,
    initial_dbrw: String,
    initial_gf: String,
    initial_gf_miteigentum_anteilig: String,
    initial_gf_miteigentum: String,
    initial_gf_miteigentum_z: String,
    initial_gf_miteigentum_n: String,
    initial_bbg_ga: Number,
    initial_foerderung: Number,
    initial_baudenkmal: Number,
    initial_n_wz: Number,
    initial_baujahr: String,
    initial_wf: String,
    initial_gf_bebauung: String,
    initial_wl: String,
    show_feedback_form_link: Boolean,
    start_with_feedback: Boolean,
    allow_scroll_to_top: Boolean,
    
    // Widget ID, überschreibt "internes Feld" und wird vom URL-Parameter überschrieben
    wid: String,
    
    // Widget direkt anzeigen
    widget_show_direct: Boolean,

    // ID des "Text" Widgets
    tid: Boolean
  },
  data: function() {
    // Initialize "is mobile" media query
    const is_mobile_mq = window.matchMedia('(max-width: 767px)');

    return {
      // MQs
      is_mobile: is_mobile_mq.matches,
      is_mobile_mq: is_mobile_mq,

      // Besonderer Fehler
      exception: null,
      exception_message: null,

      // Rechner Ergebnis
      rechner_result: {
        a: {},
        e: {},
		z: {},
      },

      // Ergebnis Panel Optionen
      show_mobile_result: false,
      results_calculated: 0,
      results_tried: 0,
      button_clicked: false,

      // Aktueller Info-Text
      modal_info_title: '',
      modal_info_text: '',
      modal_info_is_showing: false,
      modal_detail_id: null,

      // Infobox Text
      infobox_text_html: '<p style="text-align: left;">Beschreibung: Anhand weniger Angaben ermittelt der Grundsteuer Rechner den Grundsteuerwert zum 1.1.2022 bzw. den Grundsteuermessbetrag zum 1.1.2025. Die ab 2025 zu zahlende neue Grundsteuer wird anhand der bei der letzten Aktualisierung veröffentlichten Hebesätze berechnet. Es ist davon auszugehen, dass die meisten Gemeinden die Hebesätze für Zeiträume ab 2025 noch anpassen werden.<br /><br />Der Rechner ist für Wohngrundstücke in allen Bundesländern und Gemeinden konzipiert. Bei der Berechnung werden die häufigsten Fälle berücksichtigt, ohne dass ein Anspruch auf Vollständigkeit besteht. Sonderfälle wie z.B. mehrere Bodenrichtwertzonen oder Ermäßigungen der Steuermesszahl für Baudenkmäler werden nicht berücksichtigt.<br /><br />Bundesmodell</p><ul style="text-align: left;"><li>Berlin</li><li>Brandenburg</li><li>Bremen</li><li>Mecklenburg-Vorpommern</li><li>Nordrhein-Westfalen</li><li>Rheinland-Pfalz</li><li>Saarland (abweichende Steuermesszahl)</li><li>Sachsen-Anhalt</li><li>Sachsen (abweichende Steuermesszahl)</li><li>Schleswig-Holstein</li><li>Thüringen</li></ul><p style="text-align: left;">Ländermodelle</p><ul style="text-align: left;"><li>Baden-Württemberg (modifiziertes Bodenwertmodell)</li><li>Bayern (Flächenmodell)</li><li>Hamburg (Wohnlagenmodell)</li><li>Hessen (Flächen-Faktor-Modell)</li><li>Niedersachsen (Flächen-Lage-Modell)</li></ul><p style="text-align: left;">Bitte beachten Sie auch den folgenden Haftungsausschluss: Für die Richtigkeit, Aktualität und Vollständigkeit der Inhalte und Berechnungen wird keine Gewähr übernommen. Die Berechnungen ersetzen in keinem Fall eine individuelle Beratung.</p>',

      // Feedback Formular
      show_feedback_form: false,

      // Grundstücksart Widget
      modal_bbg_ga_is_showing: false,
      grundstuecksart_count: 0,
      grundstuecksart_ergebnis: null,

      // Baujahr Widget
      modal_baujahr_is_showing: false,
      baujahr_count: 0,
      baujahr_ergebnis: null,
      baujahr_ergebnis_changed_baujahr: false,

      // Miteigentum Layer
      modal_miteigentum_is_showing: false,

      // Vom Rechner aktuell unterstützte Grundstücksarten
      SUPPORTED_BBG_GA: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      BUNDESMODELLE: [
        11,     // Berlin
        12,     // Brandenburg
        4,      // Bremen
        13,     // Mecklenburg-Vorpommern
        5,      // Nordrhein-Westfalen
        7,      // Rheinland-Pfalz
        15,     // Sachsen-Anhalt
        1,      // Schleswig-Holstein
        16      // Thüringen
      ],

      BBG_GA_NAMES_DETAILS: {
        "0": "Unbebaute Grundstück",
        "1": "Einfamilienhaus",
        "2": "Zweifamilienhaus",
        "3": "Mietwohngrundstück",
        "4": "Wohnungseigentum",
      },

      BL_MAP: {
        8: "Baden-Württemberg",
        9: "Bayern",
        11: "Berlin",
        12: "Brandenburg",
        4: "Bremen",
        2: "Hamburg",
        6: "Hessen",
        13: "Mecklenburg-Vorpommern",
        3: "Niedersachsen",
        5: "Nordrhein-Westfalen",
        7: "Rheinland-Pfalz",
        10: "Saarland",
        14: "Sachsen",
        15: "Sachsen-Anhalt",
        1: "Schleswig-Holstein",
        16: "Thüringen"
      },

      // Ergebnis-BL-Mapping
      BL_Z_MAP: {
        8: "bw",
        9: "by",
        2: "hh",
        6: "he",
        3: "nds",
      },

      // BORIS URLs
      BRW_URLS: {
        8: "https://www.gutachterausschuesse-bw.de/borisbw/?lang=de",
        11: "https://fbinter.stadt-berlin.de/boris/",
        12: "https://www.boris-brandenburg.de/boris-bb/",
        4: "https://immobilienmarkt.niedersachsen.de/bodenrichtwerte",
        6: "https://www.geoportal.hessen.de/map?WMC=39",
        13: "https://www.geodaten-mv.de/grundsteuerdaten/",
        3: "https://grundsteuer-viewer.niedersachsen.de/b",
        5: "https://grundsteuer-geodaten.nrw.de/",
        7: "http://www.boris.rlp.de/",
        10: "https://geoportal.saarland.de/mapbender/frames/index.php?lang=de&gui_id=Steuer-BORIS&WMC=5416",
        14: "https://geoviewer.sachsen.de/mapviewer/resources/apps/boris/index.html",
        15: "http://www.grundsteuerdaten.sachsen-anhalt.de/",
        1: "https://danord.gdi-sh.de/viewer/resources/apps/bodenrichtwertefuergrundsteuerzweckesh/index.html?lang=de#/",
        16: "https://thueringenviewer.thueringen.de/thviewer/grundsteuer.html"
      },
      DBRW_URLS: {
        3: "https://grundsteuer-viewer.niedersachsen.de/b"
      },

      // Widget direkt anzeigen
      current_widget_show_direct: false,

      // Dynamische Textzeile im Ergebnis
      current_tid: null,
      html_lines: null
    };
  },
  mounted: async function() {
    // Listen to "is mobile" media query
    this.is_mobile_mq.addListener((mq) => this.is_mobile = mq.matches);

    // URL params
    let params = this.$store.state.URL_PARAMS;

    // Update widget show time, if present
    if (params.has("widget_show_direct")) {
      const new_widget_show_direct = params.get("widget_show_direct");
      this.current_widget_show_direct = new_widget_show_direct;
    }
    else {
      this.current_widget_show_direct = this.widget_show_direct;
    }

    if (params.has("tid")) {
      const new_tid = params.get("tid");
      this.current_tid = new_tid;
    }
    else if(this.tid) {
      this.current_tid = this.tid;
    }
    else {
      this.current_tid = 0;
    }

    if (this.current_tid) {
      // Load Ergebnis Widgets
      try {
        const result = await axios.get(
          process.env.VUE_APP_WIDGET_URL + "/ergebnis_textzeilen.json",
          {
            responseType: 'json'
          }
        );
        this.html_lines = result.data;
      }
      catch (err) {
        // ignore
      }
    }

    // Start with feedback form open?
    if (this.start_with_feedback) {
      this.show_feedback_form = true;
    }
  },
  methods: {
    on_bbg_ga_clicked: function() {
      this.grundstuecksart_ergebnis = null;
      this.grundstuecksart_count += 1;
      this.modal_bbg_ga_is_showing = true;
    },

    on_baujahr_clicked: function() {
      this.baujahr_ergebnis = null;
      this.baujahr_count += 1;
      this.modal_baujahr_is_showing = true;
    },

    on_grundstuecksart_ergebnis: function(values) {
      this.grundstuecksart_ergebnis = values;
    },

    on_grundstuecksart_ergebnis_use: function() {
      // Set values in form
      if (this.grundstuecksart_ergebnis && "bbg_ga" in this.grundstuecksart_ergebnis) {
        // Nur, wenn unterstützt
        const bbg_ga_supported = this.SUPPORTED_BBG_GA.includes(this.grundstuecksart_ergebnis["bbg_ga"]);
        if (bbg_ga_supported) {
          this.$store.dispatch({
            type: FORM_RAW_SET,
            name: "bbg_ga",
            value: this.grundstuecksart_ergebnis["bbg_ga"]
          });
        }
      }
      
      // BL immer setzen
      if (this.grundstuecksart_ergebnis && "bl" in this.grundstuecksart_ergebnis) {
        this.$store.dispatch({
          type: FORM_RAW_SET,
          name: "bl",
          value: this.grundstuecksart_ergebnis["bl"]
        });
      }

      // Close widget
      this.modal_bbg_ga_is_showing = false;
    },

    on_baujahr_ergebnis: function(values) {
      this.baujahr_ergebnis = values;
    },

    on_baujahr_ergebnis_use: function() {
      // Baujahr wird ausgefüllt im ersten Gebäude
      if (this.baujahr !== this.baujahr_ergebnis["baujahr"]) {
        // Baujahr wurde im Widget angepasst oder repariert
        this.baujahr_ergebnis_changed_baujahr = true;
        this.$store.dispatch({
          type: FORM_RAW_SET,
          name: "wohnungen[0].baujahr",
          value: this.baujahr_ergebnis["baujahr"]
        });
      }
      else {
        this.baujahr_ergebnis_changed_baujahr = false;
      }

      this.$store.dispatch({
        type: FORM_RAW_SET,
        name: "abbruch",
        value: this.baujahr_ergebnis["abbruch"]
      });
      this.$store.dispatch({
        type: FORM_RAW_SET,
        name: "abbruch_jahr",
        value: this.baujahr_ergebnis["abbruch_jahr"]
      });

      this.$store.dispatch({
        type: FORM_RAW_SET,
        name: "kernsanierung",
        value: this.baujahr_ergebnis["kernsanierung"]
      });
      this.$store.dispatch({
        type: FORM_RAW_SET,
        name: "kernsanierung_jahr",
        value: this.baujahr_ergebnis["kernsanierung_jahr"]
      });

      // Close widget
      this.modal_baujahr_is_showing = false;
    },

    on_miteigentum_ergebnis_use: function() {
      let gf = 0;

      if (this.gf_miteigentum !== null) {
        // N always has to be valid and cant be 0
        let gf_n = this.gf_miteigentum_n === null ? 1000 : this.gf_miteigentum_n;

        // If Z is not set, use N to get factor of 1
        let gf_z = this.gf_miteigentum_z === null ? gf_n : this.gf_miteigentum_z;
        
        // Kaufmännisch runden volle Euro
        gf = runden_volleEuro(this.gf_miteigentum * (gf_z / gf_n));
      }

      this.$store.dispatch({
        type: FORM_RAW_SET,
        name: "gf_miteigentum_anteilig",
        value: gf
      });

      this.modal_miteigentum_is_showing = false;
    },

    reset_wohnungen: function(limit_to_bbg_ga) {
      // reset wohnungen, e.g. on Bundesland- oder Grundstücksartänderung
      
      // hard reset to this new bbg_ga
      if (limit_to_bbg_ga !== undefined) {
        this.$store.dispatch({
            type: FORM_RAW_SET,
            name: "bbg_ga",
            value: limit_to_bbg_ga
          });
      }

      // Wohnungen anpassen, wenn sich die Auswahl ändert können zu viele im Formular aktiv sein
      while(this.gebaeude.length > this.wohnungen_max) {
        // Alle "extra" entfernen
        const last_index = this.gebaeude.length-1;
        this.remove_gebaeude(last_index);
      }

      // Wohnung anlegen: Weitere hinzufügen, falls noch nicht "voll"
      let max_w = 1;
      if (this.bbg_ga == 2) {
        max_w = 2;
      }
      else if (this.bbg_ga == 3) {
        max_w = 3;
      }
      max_w = Math.min(max_w-this.gebaeude.length, this.wohnungen_max);
      if (max_w > 0) {
        for (let i = 0; i < max_w; i+=1) {
          this.add_gebaeude();
        }
      }
    },

    toggle_feedback_form: function() {
      this.show_feedback_form = !this.show_feedback_form;
      if (this.show_feedback_form) {
        this.scroll_to_top();
      }
    },

    close_feedback_form: function() {
      this.show_feedback_form = false;
      this.scroll_to_top();
    },

    on_toggle_info_text: function(title, text) {
      this.modal_detail_id = null;
      this.modal_info_title = title;
      this.modal_info_text = text;
      this.modal_info_is_showing = true;
    },

    on_toggle_detail_text: function(detail_id) {
      this.modal_info_title = null;
      this.modal_info_text = null;
      this.modal_detail_id = detail_id;
      this.modal_info_is_showing = true;
    },

    on_toggle_layer_miteigentum: function() {
      this.modal_miteigentum_is_showing = true;
    },

    // Scroll to element
    scroll_to_top: function() {
      // Dont scroll if embedded and not allowed
      if (this.is_embedded && !this.allow_scroll_to_top) {
        return;
      }

      let element = this.$refs.rechner_top;
      // Not available on first render, perfect
      if (element) {
        let top = element.offsetTop;
        // Etwas weniger, Header überdeckt sonst
        if (this.is_embedded) {
          top -= 10;
        }
        else {
          top -= 100;
        }
        window.scrollTo(0, top);
      }
    },

    // Berechnen Button geklickt, nach Berechnung noch hochscrollen
    on_berechnen_btn: function() {
      this.button_clicked = true;
      this.berechnen(true);
    },

	ergebnisdetails: function(bl, key) {
		if (bl in this.rechner_result.z) {
			if (key in this.rechner_result.z[bl]) {
				return this.rechner_result.z[bl][key];
				}
		}
		return null;
	},

    // Grundsteuer berechnen
    berechnen: function(scroll_to_top) {
      // Wait for first click from user before automatically updating
      if (!this.button_clicked) {
        return;
      }

      // Tried to calculate
      this.results_tried += 1;

      // Falls es bereits Formularfehler gibt gar nicht erst Rechnen
      if (this.has_errors) {
        this.rechner_result = {
          a: {},
          e: {},
          z: {}
        };
        return;
      }

      // eingangsparameter zusammenstellen
      const eingangsparameter = {
        va: this.va,
        bwst: this.bwst,
        ...this.$store.getters.eingangsparameter
      };
      console.log("eingangsparameter", eingangsparameter)

      // Rechnen und Ergebnisse bzw Fehler speichern
      const rechner = grundsteuerrechner(eingangsparameter);

      console.log("rechner output", rechner);

      if (rechner.status) {
        this.results_calculated += 1;
        this.rechner_result = {
          a: rechner.a,
          e: eingangsparameter,
          z: rechner.z
        };
        this.exception = false;
        this.exception_message = null;

        // Send sample, if this is the first calculation and it is successfull
        if (this.results_tried === 1) {
          axios({
            'method': 'POST',
            'url': process.env.VUE_APP_BEISPIELE_API_URL + "/beispiele",
            'data': {
              "ags": this.ags,
              ...eingangsparameter,
              ...rechner.a
            },
            'headers': {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          });
        }
        
        if (scroll_to_top === true) {
          this.scroll_to_top()
        }
      } else {
        this.rechner_result = {
          a: {},
          e: {},
          z: {}
        };

        if ("exception" in rechner) {
          // Unerwartete Exception
          this.exception = true;
          if ("message" in rechner.exception) {
            this.exception_message = rechner.exception.message;
            if ("details" in rechner.exception) {
              this.exception_message += " " + rechner.exception.details.toString();
            }
          }
          else {
            this.exception_message = null;
          }
        }
        else {
          // "Erwarteter" Fehler
          this.exception = false;
          this.exception_message = null;

          // Fehler im Store updaten
          for (let err in rechner.errors) {
            if (err === "gebaeude") {
              let wind = 0;
              for (let wohnung in rechner.errors["gebaeude"]) {
                for (let werr in rechner.errors["gebaeude"][wohnung]) {
                  if (rechner.errors["gebaeude"][wohnung][werr] !== null) {
                    this.$store.dispatch({
                      type: FORM_ERROR_SET,
                      name: "wohnungen[" + wind + "]." + werr,
                      value: rechner.errors["gebaeude"][wohnung][werr]
                    });
                  }
                }
                wind += 1;
              }
            }
            else {
              if (rechner.errors[err] !== null) {
                this.$store.dispatch({
                  type: FORM_ERROR_SET,
                  name: err,
                  value: rechner.errors[err]
                });
              }
            }
          }
        }
      }
    },

    add_gebaeude: function() {
      this.$store.dispatch(FORM_ADD_WOHNUNG);
    },

    remove_gebaeude: function(index) {
      this.$store.dispatch({
        type: FORM_REMOVE_WOHNUNG,
        index  
      });

      // Need at least 1
      if (this.gebaeude.length < 1 && this.wohnungen_max > 0) {
        this.errors.gebaeude_list = {
          "message": "Bitte mindestens eine Wohnung angeben."
        }
      }
    }
  },
  watch: {
    bbg_ga: function() {
      this.reset_wohnungen();
    },

    bl: function(newBl, oldBl) {
      // Wenn sich das Bundesland ändert zu einem welches nicht das Bundesmodell verwendet,
      // werden alle Fehler im Formular zurückgesetzt,
      // sowie die Anzahl Wohnungen angepasst

      if (newBl === 8 && oldBl !== 8 || newBl !== 8 && oldBl === 8) {
        this.reset_wohnungen();
        this.$store.dispatch({type: FORM_ERROR_RESET});
      }
      if (
            (newBl === 9 && oldBl !== 9 || newBl !== 9 && oldBl === 9)
          ||
            (newBl === 3 && oldBl !== 3 || newBl !== 3 && oldBl === 3)
          ||
            (newBl === 6 && oldBl !== 6 || newBl !== 6 && oldBl === 6)
          ||
            (newBl === 2 && oldBl !== 2 || newBl !== 2 && oldBl === 2)
        ) {
        this.reset_wohnungen(1);
        this.$store.dispatch({type: FORM_ERROR_RESET});
      }
    },

    baujahr: function() {
      if (this.baujahr_ergebnis_changed_baujahr) {
        // Baujahr wurde durch Widget geändert, damit wurde diese Änderung deshalb getriggert und die Felder sollten nicht gelöscht werden!
        this.baujahr_ergebnis_changed_baujahr = false;
        return
      }

      this.$store.dispatch({
        type: FORM_RAW_SET,
        name: "abbruch",
        value: false
      });
      this.$store.dispatch({
        type: FORM_RAW_SET,
        name: "abbruch_jahr",
        value: null
      });

      this.$store.dispatch({
        type: FORM_RAW_SET,
        name: "kernsanierung",
        value: false
      });
      this.$store.dispatch({
        type: FORM_RAW_SET,
        name: "kernsanierung_jahr",
        value: null
      });
    }
  },
  computed: {
    is_bundesmodell: function() {
		// Bei fehlendem BL erstmal erlauben, da BM Standard ist
		if (!this.bl) {
			return true;
		}
		return this.BUNDESMODELLE.includes(this.bl);
	},

	is_swv: function() {
		return (this.is_bundesmodell || this.bl === 10 || this.bl === 14) && this.bbg_ga >= 5;
	},

    brw_link_for_bl: function() {
      if (this.bl in this.BRW_URLS) {
        return this.BRW_URLS[this.bl];
      }

      // Standard ist BORIS-D
      return "https://www.bodenrichtwerte-boris.de/borisde/?lang=de";
    },

    dbrw_link_for_bl: function() {
      if (this.bl in this.DBRW_URLS) {
        return this.DBRW_URLS[this.bl];
      }

      // Kein Standard
      return null;
    },
    
    grundstuecksart_show_use_button: function() {
      // Wenn kein Ergebnis vorlieg zur Sicherheit abbrechen
      if (!this.grundstuecksart_ergebnis) {
        return false;
      }
      if (!("bl" in this.grundstuecksart_ergebnis) || !("bbg_ga" in this.grundstuecksart_ergebnis)) {
        return false;
      }

      // Wenn ein Bundesmodell ist, dann prüfen ob der Rechner dies unterstützt
      const bl_bundesmodell = this.BUNDESMODELLE.includes(this.grundstuecksart_ergebnis["bl"]);
      if (bl_bundesmodell) {
        // Kann der Rechner diese Grundstücksart?
        const bbg_ga_supported = this.SUPPORTED_BBG_GA.includes(this.grundstuecksart_ergebnis["bbg_ga"]);
        return bbg_ga_supported;
      }

      // Kein Bundesmodell, das ist immer ok da dies dann nur das BL ändert
      return true;
    },

	bbg: {
		get() {
			if (this.bbg_ga) {
				switch (this.bbg_ga) {
				case 1:
				case 2:
				case 3:
				case 4:
					return 1;
				default:
					return 0;
				}
			}
			return 0;
		}
	},
	
    bl: {
      get() {
        return this.$store.state.form_value["bl"];
      }
    },

    gf: {
      get() {
        return this.$store.state.form_value["gf"];
      }
    },
	
    bbg_ga: {
      get() {
        return this.$store.state.form_value["bbg_ga"];
      },
    },

    ga_42: {
      get() {
        // Immer Wert aus "Wohnung 1" verwenden
        return this.$store.state.form_value["wohnungen[0].ga_42"];
      },
    },

    gebaeude: {
      get() {
        return this.$store.state.form_value["wohnungen"];
      }
    },

    baujahr: {
      get() {
        if (this.gebaeude !== undefined && this.gebaeude.length > 0) {
          return this.gebaeude[0].baujahr;
        }
        return undefined;
      }
    },

    ags: {
      get() {
        return this.$store.state.form_value["ags"];
      }
    },

    gf_miteigentum_n: {
      get() {
        return this.$store.state.form_value["gf_miteigentum_n"];
      }
    },

    gf_miteigentum_z: {
      get() {
        return this.$store.state.form_value["gf_miteigentum_z"];
      }
    },

    gf_miteigentum: {
      get() {
        return this.$store.state.form_value["gf_miteigentum"];
      }
    },

    errors: {
      get() {
        return this.$store.state.form_error;
      }
    },

    wohnungen_label: function() {
      if (this.wohnungen_max > 1) {
        return "Wohnungen (" + this.gebaeude.length + "/" + this.wohnungen_max + ")";
      }

      return "Wohnung";
    },

    // Festlegen der maximalen Anzahl an Wohnungen die möglich sind
    wohnungen_max: function() {
      // Keine bei BW
      if (this.bl === 8) {
        return 0;
      }

      if (this.bl === 9 || this.bl === 3 || this.bl === 2) {
        return 1;
      }

      if (this.bbg_ga) {
        switch (this.bbg_ga) {
          case 2: return 2;
          case 3: return 6;
          case 5: return 2;
          case 6: return 2;
          case 7: return 2;
          case 8: return 2;
          default: return 1;
        }
      }
      return 0;
    },

    wohnungen_can_add: function() {
      return this.gebaeude.length < this.wohnungen_max;
    },

    wohnungen_can_remove: function() {
      // Nicht entfernbar bei ZFH
      return this.bbg_ga !== 2;
    },

    // Prüfen ob es einen Fehler im Formular gibt
    has_errors: function() {
     // Normale Fehler prüfen...
     for (let err in this.errors) {
       if (err === "wohnungen") {
         // In "Wohnungen" alle Objekte durchgehen...
         for (let wohnung in this.errors["wohnungen"]) {
           // ... und in jedem Objekt alle Properties auf "null" checken
           for (let werr in this.errors["wohnungen"][wohnung]) {
             if (this.errors["wohnungen"][wohnung][werr] !== null) {
              // TODO ignore this error for now
              if (!(wohnung > 0 && werr === "baujahr")) {
                return true;
              }
             }
           }
         }
       }
       else {
         // Alle Properties außer "wohnungen" direkt auf "null" checken
         if (this.errors[err] !== null) {
           return true;
         }
       }
     }

     // Kein Fehler
     return false;
    },

    result_filtered: function() {
      let filtered = {};
      for (let key in this.result) {
        if ("show" in this.result[key] && this.result[key]["show"] === true) {
          filtered[key] = this.result[key];
        }
      }
      return filtered;
    },

    result_mobile: function() {
      let filtered_for_mobile = {};
      for (let key in this.result_filtered) {
        if ("mobile" in this.result_filtered[key] && this.result_filtered[key]["mobile"] === true) {
          filtered_for_mobile[key] = this.result_filtered[key];
        }
      }
      return filtered_for_mobile;
    },

    as: function() {
      return this.rechner_result.a || {};
    },

    es: function() {
      return this.rechner_result.e || {};
    },
    
    es_ok: function() {
      return Object.keys(this.es).length > 0;
    },

    zs_bl: function() {
      let k = null;
      if (this.is_swv) {
        k = "swv";
      }
      else {
        k = this.BL_Z_MAP[this.es["bl"]];
      }
      if (k) {
        return this.rechner_result.z[k] || {};
      }
      return {};
    },

    zs_bm: function() {
      return this.rechner_result.z["bm"] || {};
    },

    zs_ok: function() {
		// Entweder BM oder andere ok
      return Object.keys(this.zs_bm).length > 0 || Object.keys(this.zs_bl).length > 0;
    },

    // Ergebnis passend für das Formular konvertieren
    result: function() {
      // Berechnung durchführen
      this.berechnen();

      // Ergebnis zusammenbauen, zB passende Filter und Mobile-View Parameter angeben
      return {
        re_254: {
          name: "&nbsp;&nbsp;&nbsp;Rohertrag",
          mobile: false,
          show: !this.is_swv && this.rechner_result.e.bbg_ga !== 0 && this.rechner_result.e.bl !== 8 && this.rechner_result.e.bl !== 9 && this.rechner_result.e.bl !== 3 && this.rechner_result.e.bl !== 6 && this.rechner_result.e.bl !== 2,
          value: this.rechner_result.a.re_254,
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "rohertrag"
        },
        bwk_255: {
          name: "- Bewirtschaftungskosten",
          mobile: false,
          show: !this.is_swv && this.rechner_result.e.bbg_ga !== 0 && this.rechner_result.e.bl !== 8 && this.rechner_result.e.bl !== 9 && this.rechner_result.e.bl !== 3 && this.rechner_result.e.bl !== 6 && this.rechner_result.e.bl !== 2,
          value: this.rechner_result.a.bwk_255,
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "bewirtschaftungskosten"
        },
        reg_253: {
          name: "= Reinertrag",
          mobile: false,
          show: !this.is_swv && this.rechner_result.e.bbg_ga !== 0 && this.rechner_result.e.bl !== 8 && this.rechner_result.e.bl !== 9 && this.rechner_result.e.bl !== 3 && this.rechner_result.e.bl !== 6 && this.rechner_result.e.bl !== 2,
          value: this.rechner_result.a.reg_253,
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "bewirtschaftungskosten"
        },
        vv_253: {
          name: "× Vervielfältiger",
          mobile: false,
          show: !this.is_swv && this.rechner_result.e.bbg_ga !== 0 && this.rechner_result.e.bl !== 8 && this.rechner_result.e.bl !== 9 && this.rechner_result.e.bl !== 3 && this.rechner_result.e.bl !== 6 && this.rechner_result.e.bl !== 2,
          value: this.rechner_result.a.vv_253,
          pretty: "pretty_number_round2",
          ergebnis_detail: "vervielfältiger"
        },
        kr_254: {
          name: "= Kapitalisierter Reinertrag",
          mobile: false,
          show: !this.is_swv && this.rechner_result.e.bbg_ga !== 0 && this.rechner_result.e.bl !== 8 && this.rechner_result.e.bl !== 9 && this.rechner_result.e.bl !== 3 && this.rechner_result.e.bl !== 6 && this.rechner_result.e.bl !== 2,
          value: this.rechner_result.a.kr_253,
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "kapitalisierter_reinertrag"
        },
        ab_257: {
          name: "+ Abgezinster Bodenwert",
          mobile: false,
          show: !this.is_swv && this.rechner_result.e.bbg_ga !== 0 && this.rechner_result.e.bl !== 8 && this.rechner_result.e.bl !== 9 && this.rechner_result.e.bl !== 3 && this.rechner_result.e.bl !== 6 && this.rechner_result.e.bl !== 2,
          value: this.rechner_result.a.ab_257,
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "abgezinster_bodenwert"
        },
        gw_mwv: {
          name: "= Grundsteuerwert",
          mobile: false,
          show: !this.is_swv && this.rechner_result.e.bl !== 9 && this.rechner_result.e.bl !== 3 && this.rechner_result.e.bl !== 6 && this.rechner_result.e.bl !== 2 && this.rechner_result.e.bl !== 8,
          value: this.rechner_result.a.gw_mwv,
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "grundsteuerwert"
        },
        gw_bw: {
          name: "&nbsp&nbsp;&nbsp;Grundsteuerwert",
          mobile: false,
          show: !this.is_swv && this.rechner_result.e.bl === 8,
          //value: this.rechner_result.a.gw_bw,
          value: this.rechner_result.a.gw_mwv,
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "bw_grundsteuerwert"
        },
        swv_gw_ubg: {
          name: "&nbsp;&nbsp;&nbsp;Bodenwert",
          mobile: false,
          show: this.is_swv,
          value: this.ergebnisdetails("swv", "gw_ubg"),
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "swv_gw_ubg"
        },
        swv_gbsw_259_4: {
          name: "+ Gebäudesachwert",
          mobile: false,
          show: this.is_swv,
          value: this.ergebnisdetails("swv", "gbsw_259_4"),
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "swv_gbsw_259_4"
        },
        swv_vsw_258_3: {
          name: "= Vorläufiger Sachwert des Grundstücks",
          mobile: false,
          show: this.is_swv,
          value: this.ergebnisdetails("swv", "vsw_258_3"),
          pretty: "pretty_number_round2",
          suffix: "€",
        },
        swv_wz_260: {
          name: "× Wertzahl",
          mobile: false,
          show: this.is_swv,
          value: this.ergebnisdetails("swv", "wz_260"),
          pretty: "pretty_number_round2",
          ergebnis_detail: "swv_wz_260"
        },
        swv_gw_swv: {
          name: "= Grundsteuerwert",
          mobile: false,
          show: this.is_swv,
          value: this.ergebnisdetails("swv", "gw_mwv"),
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "swv_gw_swv"
        },
        smz_15: {
          name: "× Steuermesszahl",
          mobile: false,
          show: this.rechner_result.e.bl !== 9 && this.rechner_result.e.bl !== 3 && this.rechner_result.e.bl !== 6 && this.rechner_result.e.bl !== 2 && this.rechner_result.e.bl !== 8,
          value: this.rechner_result.a.smz_15,
          pretty: "pretty_number_round2_promille",
          suffix: "‰"
        },
        smz_15_bw: {
          name: "× Steuermesszahl",
          mobile: false,
          show: !this.is_swv && this.rechner_result.e.bl === 8,
          value: this.rechner_result.a.smz_15,
          pretty: "pretty_number_round2_promille",
          suffix: "‰",
          ergebnis_detail: "bw_steuermesszahl"
        },
        smb: {
          name: "= Grundsteuermessbetrag",
          mobile: false,
          show: this.rechner_result.e.bl !== 9 && this.rechner_result.e.bl !== 3 && this.rechner_result.e.bl !== 6 && this.rechner_result.e.bl !== 2,
          value: this.rechner_result.a.smb,
          pretty: "pretty_number_round2",
          suffix: "€"
        },
        abwf_by: {
          name: "&nbsp;&nbsp;&nbsp;Äquivalenzbetrag Wohnfläche",
          mobile: false,
          show: !this.is_swv && this.rechner_result.e.bl === 9,
          value: this.ergebnisdetails("by", "ABWF_BY"),
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "by_abwf",
          name_value: this.ergebnisdetails("by", "SMZ_BY_WF")*100,
          name_pretty: "pretty_number_round2",
          name_suffix: "%"
        },
        abnf_by: {
          name: "+ Äquivalenzbetrag Nutzfläche",
          mobile: false,
          show: !this.is_swv && this.rechner_result.e.bl === 9,
          value: this.ergebnisdetails("by", "ABNF_BY"),
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "by_abnf",
          name_value: this.ergebnisdetails("by", "SMZ_BY_NF")*100,
          name_pretty: "pretty_number_round2",
          name_suffix: "%"
        },
        abgub_by: {
          name: "+ Äquivalenzbetrag Grund und Boden",
          mobile: false,
          show: !this.is_swv && this.rechner_result.e.bl === 9,
          value: this.ergebnisdetails("by", "ABGuB_BY"),
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "by_abgub",
          name_value: this.ergebnisdetails("by", "SMZ_BY_A")*100,
          name_pretty: "pretty_number_round2",
          name_suffix: "%"
        },
        grst_mb_by: {
          name: "= Grundsteuermessbetrag",
          mobile: false,
          show: !this.is_swv && this.rechner_result.e.bl === 9,
          value: this.rechner_result.a.grst_mb,
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "by_grundsteuermessbetrag"
        },
        abwf_hh: {
          name: "&nbsp;&nbsp;&nbsp;Äquivalenzbetrag Wohnfläche",
          mobile: false,
          show: !this.is_swv && this.rechner_result.e.bl === 2,
          value: this.ergebnisdetails("hh", "ABWF_HH"),
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "hh_abwf",
          name_value: this.ergebnisdetails("hh", "SMZ_HH_WF")*100,
          name_pretty: "pretty_number_round2",
          name_suffix: "%"
        },
        abnf_hh: {
          name: "+ Äquivalenzbetrag Nutzfläche",
          mobile: false,
          show: !this.is_swv && this.rechner_result.e.bl === 2,
          value: this.ergebnisdetails("hh", "ABNF_HH"),
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "hh_abnf",
          name_value: this.ergebnisdetails("hh", "SMZ_HH_NF")*100,
          name_pretty: "pretty_number_round2",
          name_suffix: "%"
        },
        abgub_hh: {
          name: "+ Äquivalenzbetrag Grund und Boden",
          mobile: false,
          show: !this.is_swv && this.rechner_result.e.bl === 2,
          value: this.ergebnisdetails("hh", "ABGuB_HH"),
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "hh_abgub",
          name_value: this.ergebnisdetails("hh", "SMZ_HH_A")*100,
          name_pretty: "pretty_number_round2",
          name_suffix: "%"
        },
        grst_mb_hh: {
          name: "= Grundsteuermessbetrag",
          mobile: false,
          show: !this.is_swv && this.rechner_result.e.bl === 2,
          value: this.rechner_result.a.grst_mb,
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "hh_grundsteuermessbetrag"
        },
        abwf_he: {
          name: "&nbsp;&nbsp;&nbsp;Flächenbetrag Wohnfläche",
          mobile: false,
          show: !this.is_swv && this.rechner_result.e.bl === 6,
          value: this.ergebnisdetails("he", "ABWF_HE"),
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "he_abwf",
          name_value: this.ergebnisdetails("he", "SMZ_HE_WF")*100,
          name_pretty: "pretty_number_round2",
          name_suffix: "%"
        },
        abnf_he: {
          name: "+ Flächenbetrag Nutzfläche",
          mobile: false,
          show: !this.is_swv && this.rechner_result.e.bl === 6,
          value: this.ergebnisdetails("he", "ABNF_HE"),
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "he_abnf",
          name_value: this.ergebnisdetails("he", "SMZ_HE_NF")*100,
          name_pretty: "pretty_number_round2",
          name_suffix: "%"
        },
        abgub_he: {
          name: "+ Flächenbetrag Grund und Boden",
          mobile: false,
          show: !this.is_swv && this.rechner_result.e.bl === 6,
          value: this.ergebnisdetails("he", "ABGuB_HE"),
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "he_abgub",
          name_value: this.ergebnisdetails("he", "SMZ_HE_A")*100,
          name_pretty: "pretty_number_round2",
          name_suffix: "%"
        },
        grst_mb_he: {
          name: "= Grundsteuermessbetrag",
          mobile: false,
          show: !this.is_swv && this.rechner_result.e.bl === 6,
          value: this.rechner_result.a.grst_mb,
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "he_grundsteuermessbetrag"
        },
        abwf_nds: {
          name: "&nbsp;&nbsp;&nbsp;Äquivalenzbetrag Wohnfläche",
          mobile: false,
          show: !this.is_swv && this.rechner_result.e.bl === 3,
          value: this.ergebnisdetails("nds", "ABWF_NDS"),
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "nds_abwf",
          name_value: this.ergebnisdetails("nds", "SMZ_NDS_WF")*100,
          name_pretty: "pretty_number_round2",
          name_suffix: "%"
        },
        abnf_nds: {
          name: "+ Äquivalenzbetrag Nutzfläche",
          mobile: false,
          show: !this.is_swv && this.rechner_result.e.bl === 3,
          value: this.ergebnisdetails("nds", "ABNF_NDS"),
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "nds_abnf",
          name_value: this.ergebnisdetails("nds", "SMZ_NDS_NF")*100,
          name_pretty: "pretty_number_round2",
          name_suffix: "%"
        },
        abgub_nds: {
          name: "+ Äquivalenzbetrag Grund und Boden",
          mobile: false,
          show: !this.is_swv && this.rechner_result.e.bl === 3,
          value: this.ergebnisdetails("nds", "ABGuB_NDS"),
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "nds_abgub",
          name_value: this.ergebnisdetails("nds", "SMZ_NDS_A")*100,
          name_pretty: "pretty_number_round2",
          name_suffix: "%"
        },
        grst_mb_nds: {
          name: "= Grundsteuermessbetrag",
          mobile: false,
          show: !this.is_swv && this.rechner_result.e.bl === 3,
          value: this.rechner_result.a.grst_mb,
          pretty: "pretty_number_round2",
          suffix: "€",
          ergebnis_detail: "nds_grundsteuermessbetrag"
        },
        hs: {
          name: "× Hebesatz",
          mobile: false,
          show: true,
          value: this.rechner_result.a.hs,
          pretty: "pretty_number_round2",
          suffix: "%",
          //info_title: "Hebesatz",
          //info_text: "Die Hebesätze werden vor Inkrafttreten der Grundsteuerreform voraussichtlich angepasst, um die beabsichtigte Aufkommensneutralität herzustellen. Mit einer Anpassung der ab 1.1.2025 geltenden Hebesätze ist erst im Laufe des Jahres 2024 zu rechnen. Dadurch kann die vorläufig berechnete Grundsteuer von der tatsächlichen Festsetzung abweichen."
        },
        grst: {
          name: "Grundsteuer",
          mobile: true,
          show: true,
          value: this.rechner_result.a.grst,
          important: true,
          pretty: "pretty_number_round2",
          suffix: "€",
          info_title: "Grundsteuer",
          info_text: "Bei der angegebenen Grundsteuer handelt es sich um einen Jahresbetrag, der ab Inkrafttreten der Grundsteuerreform zum 1.1.2025 gilt. Die tatsächliche Grundsteuer wird voraussichtlich abweichen, da davon auszugehen ist, dass die meisten Gemeinden den Hebesatz im Laufe des Jahres 2024 anpassen werden.",
          html_line: true
        },
      };
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// Ergebnis Tabellen Anpassungen
#container_ergebnis_klein {
  margin-bottom: 30px !important;
}
#container_ergebnis_normal {
  margin-bottom: 60px;
}
#panel-body-ergebnis-klein .table {
  margin-bottom: 0;
}
#container_ergebnis_normal .table {
  margin-bottom: 0;
}
table {
  border: 0;
}
tr {
  text-align: left;
  border: 0;
}
td {
  border: 0 !important;
}
td.important {
  font-weight: bold;
  border-top: 2px solid #ddd !important;
}
td .not_important {
  font-weight: normal;
  margin-left: 24px;
}

// Wohnungs "Box" etwas verkleinern
.wohnungs_box {
  margin: 10px 0;
  padding: 10px;
}

#feedback_container {
  margin-bottom: 32px;
}
</style>
