<template>
	<div>
		<div v-if="only_title">
            <span v-if="modal_detail_id=='he_grundsteuermessbetrag'">
				Grundsteuermessbetrag
			</span>
            <span v-else-if="modal_detail_id=='he_abwf'">
				Flächenbetrag für die Wohnfläche
			</span>
            <span v-else-if="modal_detail_id=='he_abnf'">
				Flächenbetrag für die Nutzfläche
			</span>
            <span v-else-if="modal_detail_id=='he_abgub'">
				Flächenbetrag für den Grund und Boden
			</span>
		</div>
		<div v-else>
            <span v-if="modal_detail_id=='he_grundsteuermessbetrag'">
              <table class="table table-striped">
				<colgroup>
					<col style="width:  6%; text-align: center;">
					<col style="width: 50%; text-align: left;">
					<col style="width: 22%; text-align: right;">
					<col style="width: 22%; text-align: right;">
				</colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th colspan="3">Grundsteuermessbetrag für die Wohnfläche</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">Flächenbetrag Wohnfläche</td>
                    <td style="text-align: right;">{{zs_bl["ABWF_HE"] | pretty_print("pretty_number_round2", "€")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">&times;</td>
                    <td style="text-align: left;">Steuermesszahl Wohnfläche</td>
                    <td style="text-align: right;">{{zs_bl["SMZ_HE_WF"]*100 | pretty_print("pretty_number_round2", "%")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;">Grundsteuermessbetrag Wohnfläche</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{zs_bl["SMB_WF"] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-striped">
				<colgroup>
					<col style="width:  6%; text-align: center;">
					<col style="width: 50%; text-align: left;">
					<col style="width: 22%; text-align: right;">
					<col style="width: 22%; text-align: right;">
				</colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th colspan="3">Grundsteuermessbetrag für die Nutzfläche</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">Flächenbetrag Nutzfläche</td>
                    <td style="text-align: right;">{{zs_bl["ABNF_HE"] | pretty_print("pretty_number_round2", "€")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">&times;</td>
                    <td style="text-align: left;">Steuermesszahl Nutzfläche</td>
                    <td style="text-align: right;">{{zs_bl["SMZ_HE_NF"]*100 | pretty_print("pretty_number_round2", "%")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;">Grundsteuermessbetrag Nutzfläche</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{zs_bl["SMB_NF"] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-striped">
				<colgroup>
					<col style="width:  6%; text-align: center;">
					<col style="width: 50%; text-align: left;">
					<col style="width: 22%; text-align: right;">
					<col style="width: 22%; text-align: right;">
				</colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th colspan="3">Grundsteuermessbetrag für den Grund und Boden</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">Flächenbetrag Grund und Boden</td>
                    <td style="text-align: right;">{{zs_bl["ABGuB_HE"] | pretty_print("pretty_number_round2", "€")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">&times;</td>
                    <td style="text-align: left;">Steuermesszahl Grund und Boden</td>
                    <td style="text-align: right;">{{zs_bl["SMZ_HE_A"]*100 | pretty_print("pretty_number_round2", "%")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;">Grundsteuermessbetrag Grund und Boden</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{zs_bl["SMB_GuB"] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th width="6%" style="text-align: center;"></th>
                    <th width="50%" style="text-align: left;"></th>
                    <th width="22%" style="text-align: right;"></th>
                    <th width="22%" style="text-align: right;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td colspan="2" style="text-align: left;">Summe Flächenbeträge</td>
                    <td style="text-align: right;">{{zs_bl["SMB_SUM"] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">&times;</td>
                    <td colspan="2" style="text-align: left;">Faktor ({{zs_bl["BRW_HE"] | pretty_print("pretty_number_round2")}} / {{zs_bl["DBRW_HE"]}})<sup>0,3</sup></td>
                    <td style="text-align: right;">{{zs_bl["FAKTOR_HE"] | pretty_print("pretty_number_round2")}}</td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td colspan="2" style="text-align: left;">Grundsteuermessbetrag für das Grundstück</td>
                    <td style="text-align: right;">{{as["grst_mb"] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                </tbody>
              </table>
            </span>
            <span v-else-if="modal_detail_id=='he_abwf'">
              <table class="table table-striped">
				<colgroup>
					<col style="width:  6%; text-align: center;">
					<col style="width: 50%; text-align: left;">
					<col style="width: 22%; text-align: right;">
					<col style="width: 22%; text-align: right;">
				</colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th colspan="3">Flächenbetrag für die Wohnfläche</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">Wohnfläche</td>
                    <td style="text-align: right;">{{zs_bl["WF_HE"] | pretty_print("pretty_number", "m²")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">&times;</td>
                    <td style="text-align: left;">Äquivalenzzahl</td>
                    <td style="text-align: right;">{{zs_bl["AZGebF_HE"] | pretty_print("pretty_number_round2", "€")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;">Flächenbetrag Wohnfläche</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{zs_bl["ABWF_HE"] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                </tbody>
              </table>
            </span>
            <span v-else-if="modal_detail_id=='he_abnf'">
              <table class="table table-striped">
				<colgroup>
					<col style="width:  6%; text-align: center;">
					<col style="width: 50%; text-align: left;">
					<col style="width: 22%; text-align: right;">
					<col style="width: 22%; text-align: right;">
				</colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th colspan="3">Flächenbetrag für die Nutzfläche</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">Nutzfläche</td>
                    <td style="text-align: right;">{{zs_bl["NF_HE"] | pretty_print("pretty_number", "m²")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">&times;</td>
                    <td style="text-align: left;">Äquivalenzzahl</td>
                    <td style="text-align: right;">{{zs_bl["AZGebF_HE"] | pretty_print("pretty_number_round2", "€")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;">Flächenbetrag Nutzfläche</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{zs_bl["ABNF_HE"] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                </tbody>
              </table>
            </span>
            <span v-else-if="modal_detail_id=='he_abgub'">
              <table class="table table-striped">
				<colgroup>
					<col style="width:  6%; text-align: center;">
					<col style="width: 50%; text-align: left;">
					<col style="width: 22%; text-align: right;">
					<col style="width: 22%; text-align: right;">
				</colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th colspan="3">Flächenbetrag für den Grund und Boden</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">Fläche des Grund und Bodens</td>
                    <td style="text-align: right;">{{zs_bl["GF_HE"] | pretty_print("pretty_number", "m²")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">&times;</td>
                    <td style="text-align: left;">Äquivalenzzahl</td>
                    <td style="text-align: right;">{{zs_bl["AZGuB_HE"] | pretty_print("pretty_number_round2", "€")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;">Flächenbetrag Grund und Boden</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{zs_bl["ABGuB_HE"] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                </tbody>
              </table>
            </span>
            <span v-else>
              <p>Es ist ein unerwarteter Fehler aufgetreten, bitte starten sie die Berechnung erneut.</p>
            </span>
		</div>
	</div>
</template>

<script>
export default {
	name: "RechnerErgebnisHE",
	props: {
		modal_detail_id: String,
		zs_bl: null,
		as: null,
		only_title: Boolean,
	},
};
</script>

<style scoped lang="scss">
</style>
