<template>
  <div class="gemeinde_container" id="name">
    
    <RechnerInputSelect
      name="bl"
      label="Bundesland"
      error_message="Bitte ein Bundesland wählen"
      :options='[
        { value: 8, text: "Baden-Württemberg" },
        { value: 9, text: "Bayern" },
        { value: 11, text: "Berlin" },
        { value: 12, text: "Brandenburg" },
        { value: 4, text: "Bremen" },
        { value: 2, text: "Hamburg" },
        { value: 6, text: "Hessen" },
        { value: 13, text: "Mecklenburg-Vorpommern" },
        { value: 3, text: "Niedersachsen" },
        { value: 5, text: "Nordrhein-Westfalen" },
        { value: 7, text: "Rheinland-Pfalz" },
        { value: 10, text: "Saarland" },
        { value: 14, text: "Sachsen" },
        { value: 15, text: "Sachsen-Anhalt" },
        { value: 1, text: "Schleswig-Holstein" },
        { value: 16, text: "Thüringen" }
      ]'
      info_title="Bundesland"
      info_text="Je nach Bundesland weicht die Berechnung der Grundsteuer ab, da einzelne Bundesländer die Öffnungsklausel in Anspruch nehmen. Weiterhin ist die Angabe des Bundeslandes beim Bundesmodell u.a. für die Ermittlung der pauschalen Nettokaltmiete erforderlich.<br><br>Weitere Details finden Sie in dem Artikel zum <a href='https://grundsteuer.de/bundesland?mtm=rechner' target='_blank'>Bundesmodell und den Ländermodellen</a>."
      @toggleInfoText="on_toggle_info_text"
      :extra_info="bl_extra_info"
      :initial_value="initial_bl"
    />

    <div
      id="'in_group_' + name"
      class="form-horizontal rechner-form"
      :class="{'has-error': search_error , 'callout': search_error, 'callout-red': search_error, 'validation_error': search_error}"
    >
      <div class="form-group">
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <label :for="name" class="pull-left control-label rechner-form-label">
            {{ label }}
            <a v-if="info_text" class="rechner-form-info-button" @click="on_info_clicked"><span class="glyphicon glyphicon-info-sign"></span></a>
          </label>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <div v-if="show_search">
            <input
              type="text"
              :id="name"
              :name="name"
              :placeholder="bl ? placeholder : 'Zuerst Bundesland wählen'"
              :value="search_query"
              @input="e => search_query = ('' + e.target.value).trim()"
              class="form-control"
              :disabled="!bl"
            />
            <div v-if="is_searching">
              <span class="glyphicon glyphicon-refresh spinning"></span>
            </div>
            <div v-if="!is_searching && search_results.length > 0" class="btn-group btn-group-justified search_results_container">
              <span v-for="result in search_results" :key="'span_' + result.item.a">
                <button
                  :key="result.item.a"
                  @click="on_result_selected(result.item)"
                  type="button"
                  class="btn btn-default einfache_suche_result_btn"
                >{{ result.item.dn}}</button>
                <br />
              </span>
            </div>
            <p v-else-if="!is_searching && search_results.length == 0 &&search_query.length > 0" class="small text-muted">
              Keine Gemeinde passend zu {{ search_query }} gefunden
            </p>
            <p v-else-if="!is_searching" class="small text-muted">
              <span v-if="bl === 9 || bl === 3 || bl === 8 || bl === 6 || bl === 2">
                Über die Gemeindesuche den Hebesatz ermitteln
              </span>
              <span v-else>
                Über die Gemeindesuche den Hebesatz und die Mietniveaustufe ermitteln
              </span>
            </p>
          </div>
          <div v-else>
            <div class="input-group">
              <input type="text" class="form-control" disabled="disabled" :value="selected_gemeinde" />
              <div class="input-group-btn">
                <button type="button" @click="show_search=true" class="btn btn-default">Suchen</button>
              </div>
            </div>
          </div>
          <div v-if="search_error">
            <p>{{ search_error }}</p>
          </div>
        </div>
      </div>
    </div>

    <RechnerInputNumber
      name="hs"
      placeholder="Hebesatz"
      label="Hebesatz"
      suffix="%"
      error_message="Bitte einen Wert für den Hebesatz in % eingeben."
      info_title="Hebesatz"
      info_text="Die Angabe des Hebesatzes ist für die Ermittlung der Grundsteuer erforderlich. Der Hebesatz unterscheidet sich je nach Gemeinde und ist auf der Gemeinde-Website veröffentlicht. Es ist davon auszugehen, dass die meisten Gemeinden den Hebesatz ab 2025 noch anpassen werden.<br><br>Weitere Details finden Sie in dem Artikel zum <a href='https://grundsteuer.de/berechnung/hebesatz?mtm=rechner' target='_blank'>Grundsteuer Hebesatz</a>."
      @toggleInfoText="on_toggle_info_text"
    />

    <RechnerInputSelectButtons
      v-if="bl !== 8 && bl !== 9 && bl !== 3 && bl !== 6 && bl !== 2"
      name="mns"
      label="Mietniveaustufe"
      :options='[
        { value: 1, text: "1"},
        { value: 2, text: "2"},
        { value: 3, text: "3"},
        { value: 4, text: "4"},
        { value: 5, text: "5"},
        { value: 6, text: "6"},
        { value: 7, text: "7+", check_gt: true },
      ]'
      error_message="Bitte eine Mietniveaustufe auswählen"
      info_title="Mietniveaustufe"
      info_text="Zur Berücksichtigung von Mietniveauunterschieden zwischen Gemeinden eines Landes wird die pauschale Nettokaltmiete durch Zu- oder Abschläge angepasst.<br><br>Weitere Details finden Sie in dem Artikel zur <a href='https://grundsteuer.de/bescheide/grundsteuerwertbescheid/mietniveaustufe?mtm=rechner' target='_blank'>Mietniveaustufe</a>."
      @toggleInfoText="on_toggle_info_text"
    />

  </div>
</template>

<script>
import Fuse from "fuse.js";
import { Gemeinden } from "../../data/grundsteurrechner_gemeinden.generated";
import { GemeindenDBRW } from "../../data/grundsteuerrechner_dbrw.generated";
import RechnerInputSelect from "./RechnerInputSelect";
import RechnerInputSelectButtons from "./RechnerInputSelectButtons";
import RechnerInputNumber from "./RechnerInputNumber";
import RechnerInputWithInfoText from '../RechnerInputWithInfoText';
import {FULL_RESET, FORM_RAW_SET, FORM_UPDATE_EURO} from '../../store/mutations';

var _debounce = require("lodash/debounce");
var _find = require("lodash/find");


export default {
  name: "RechnerInputGemeinde",
  components: {
    RechnerInputSelect,
    RechnerInputSelectButtons,
    RechnerInputNumber
  },
  mixins: [
    RechnerInputWithInfoText
  ],
  props: {
    // TODO Name and ID?
    name: String,

    // Input Platzhalter
    placeholder: String,

    // Textlabel
    label: String,

    // Initialie Werte
    initial_bl: Number,
    initial_hs: Number,
    initial_ags: String
  },
  data: function() {
    return {
      // Aktuell läuft eine Suche...
      is_searching: false,

      // Suchfeld anzeigen?
      show_search: true,

      // Such Query
      search_query: '',

      // Suchergebnisse
      search_results: [],

      // Suchfehler
      search_error: null,

      // Suchinstanzen, pro Bundesland
      fuses: {},

      // Debounced search, allows smoother user input
      debounced_search: null,

      // Manuelles anpassen aktiv
      user_edit: false,

      // Name der ausgewählten Gemeinde
      selected_gemeinde: null,

      // Erste BL-Änderung aus Initialien Werten ignorieren
      ignored_first_bl_change: this.initial_bl === undefined,

      BL_MAP: {
        8: "Baden-Württemberg",
        9: "Bayern",
        11: "Berlin",
        12: "Brandenburg",
        4: "Bremen",
        2: "Hamburg",
        6: "Hessen",
        13: "Mecklenburg-Vorpommern",
        3: "Niedersachsen",
        5: "Nordrhein-Westfalen",
        7: "Rheinland-Pfalz",
        10: "Saarland",
        14: "Sachsen",
        15: "Sachsen-Anhalt",
        1: "Schleswig-Holstein",
        16: "Thüringen"
      }
    }
  },
  mounted: function() {
    // Suchoptionen
    const options = {
      // Suchen in folgenden Attributen
      keys: ["n"],
      isCaseSensitive: false,
      minMatchCharLength: 3
      //threshold: 0.75,
      //distance: 50,
    };

    // Suchen in Gemeindedaten
    for (let bl in Gemeinden) {
      this.fuses[bl] = new Fuse(Gemeinden[bl], options);
    }
    this.debounced_search = _debounce(this.fuse_search, 500);

    // Übergebene Werte
    if (this.initial_bl && this.initial_ags) {
        // Suche nach Gemeinde über AGS
        if (this.initial_bl in Gemeinden) {
          const gemeinde = _find(Gemeinden[this.initial_bl], ['a', this.initial_ags]);
          if (gemeinde) {
            this.on_result_selected(gemeinde, this.initial_hs);
          }
        }
      
    }
  },
  computed: {
    bl: {
      get() {
        return this.$store.state.form_value["bl"];
      }
    },

    bl_extra_info: function() {
      // (3)
      if (this.bl === 14 || this.bl === 10 || this.bl === 8 || this.bl === 9 || this.bl === 3 || this.bl === 6 || this.bl === 2) {
        return 'Berechnung nach dem Landesmodell ' + this.BL_MAP[this.bl];
      }
      // (1)
      else if (this.bl !== null) {
        return "Berechnung im Bundesmodell";
      }

      return null;
    }
  },
  methods: {
    // Info Buttonclick nach oben weiterleiten
    on_toggle_info_text: function(info_title, info_text) {
      this.$emit('toggleInfoText', info_title, info_text);
    },

    // Suche durchführen
    fuse_search: async function() {
      this.is_searching = true;

      // Suchen mit Bundesland für genaue Suche
      if (this.search_query.length >= 1 && this.bl && (this.bl in this.fuses)) {
        this.search_results = this.fuses[this.bl].search(this.search_query);
      }
      else {
        this.search_results = [];
      }

      // Suche fertig
      this.is_searching = false;
    },

    // Es wurde auf ein Ergebnis geklickt
    on_result_selected: function(result, overwrite_hs) {
      // Suchfeld ausblenden
      this.show_search = false;

      // Fehler bei fehlender Gemeinde
      if (Object.keys(result).length > 0 && "hs" in result && "mns" in result && "dn" in result) {
        // Kein Fehler
        this.search_error = null;
        this.selected_gemeinde = result.dn;

        this.$store.dispatch({
          type: FORM_RAW_SET,
          name: "ags",
          value: result.a
        });

        // HS kann durch initialien Wert direkt überschrieben werden
        let hs = result.hs;
        if (overwrite_hs) {
          hs = overwrite_hs;
        }
        this.$store.dispatch({
          type: FORM_RAW_SET,
          name: "hs",
          value: hs
        });

        this.$store.dispatch({
          type: FORM_RAW_SET,
          name: "mns",
          value: result.mns
        });

        // DBRW available?
        if (result.a in GemeindenDBRW) {
          this.$store.dispatch({
            type: FORM_UPDATE_EURO,
            name: "dbrw",
            value: GemeindenDBRW[result.a]["dbrw"]
          });
        }
      }
      else {
        // Fehler aufgetreten
        this.search_error = "Bei dieser Gemeinde ist ein Fehler aufgetreten, bitte versuchen Sie sie erneut auszuwählen oder geben Sie den Hebesatz und die Mietniveaustufe von Hand ein.";
        this.selected_gemeinde = null;

        this.$store.dispatch({
          type: FORM_RAW_SET,
          name: "ags",
          value: null
        });

        this.$store.dispatch({
          type: FORM_RAW_SET,
          name: "hs",
          value: null
        });

        this.$store.dispatch({
          type: FORM_RAW_SET,
          name: "mns",
          value: 1
        });

        this.$store.dispatch({
          type: FORM_RAW_SET,
          name: "dbrw",
          value: null
        });
      }
    }
  },
  watch: {
    // Suchtext überwachen und suchen bei neuer Eingabe
    search_query: function() {
      this.is_searching = true;
      this.debounced_search();
    },

    // Bundesland hat sich geändert, Suche zurücksetzen
    bl: function(newBl, oldBl) {
      // ignore first bl change, happens because of initial values
      if (!this.ignored_first_bl_change) {
        this.ignored_first_bl_change = true;
        return;
      }

      // Reset der Daten bei Änderung des Rechnermodells
      if (  (newBl === 8 && oldBl !== 8 || newBl !== 8 && oldBl === 8)
          ||
            (newBl === 9 && oldBl !== 9 || newBl !== 9 && oldBl === 9)
          ||
            (newBl === 3 && oldBl !== 3 || newBl !== 3 && oldBl === 3)
          ||
            (newBl === 6 && oldBl !== 6 || newBl !== 6 && oldBl === 6)
          ||
            (newBl === 2 && oldBl !== 2 || newBl !== 2 && oldBl === 2)
        ) {
        this.$store.dispatch({type: FULL_RESET})
      }

      this.search_query = '';
      this.search_results = [];

      // Direkt auswählen, wenn nur eine Gemeinde in BL
      if (Gemeinden[this.bl].length === 1) {
        const gemeinde = Gemeinden[this.bl][0];
        this.on_result_selected(gemeinde);
      }
      else {
        this.$store.dispatch({
          type: FORM_RAW_SET,
          name: "ags",
          value: null
        });

        this.$store.dispatch({
          type: FORM_RAW_SET,
          name: "hs",
          value: null
        });

        this.$store.dispatch({
          type: FORM_RAW_SET,
          name: "mns",
          value: 1
        });

        this.$store.dispatch({
          type: FORM_UPDATE_EURO,
          name: "dbrw",
          value: null
        });

        this.show_search = true;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.einfache_suche_result_btn {
  border-radius: 0;
  white-space: normal !important;
  word-wrap: break-word;
  word-break: normal;
  width: 100%;
}

.search_results_container {
  overflow: auto;
  display: inline-block;
  max-height: 250px;
}

.gemeinde_container {
  margin-bottom: 15px;
}

.spinning {
  margin-top: 8px;
}
</style>
