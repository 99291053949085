import { pretty_number, pretty_number_cent, runden1, runden2, runden5, runden4 } from "@steuerrechner/steuerrechner-shared";

// "Schöne" Ausgabe der Werte im Formular
function filter_pretty_print(value, filter, suffix) {
    // Keine/ungültige Werte direkt ignorieren
    if (value === null || value === undefined) {
        return '-';
    }

    // Using non breaking space, no html encoding due to vue print
    const suffix_ = suffix ? ("\xa0" + suffix) : "";

    // Filter wählen
    if (filter == "pretty_number") {
        return pretty_number(value) + suffix_;
    } else if (filter == "pretty_number_round2") {
        return pretty_number(runden2(value)) + suffix_;
    } else if (filter == "pretty_number_round2_promille") {
        return pretty_number(runden2(value*1000)) + suffix_;
    } else if (filter == "pretty_number_round5") {
        return pretty_number(runden5(value)) + suffix_;
    } else if (filter == "pretty_number_cent") {
        return pretty_number_cent(value) + suffix_;
    } else if (filter == "pretty_number_ceil") {
        let r = runden1(value)*100;
        r = r.toString().split('.')[0];
        r += suffix_;
        return r;
    } else if (filter == "pretty_number_ceil2") {
        let r = runden2(value)
        r *= 100;
        r = Math.round(r);
        r = r.toString().split('.')[0];
        r += suffix_;
        return r;
    } else if (filter == "pretty_number_round4") {
        return pretty_number(runden4(value)) + suffix_;
    }

    // Kein passender Filter gefunden, Wert einfach direkt ausgeben
    return value + suffix_;
}

export {
    filter_pretty_print
}
