<template>
  <div
    class="form-horizontal rechner-form"
    id="'in_group_' + name"
    :class="{'has-error': has_error, 'callout': has_error, 'callout-red': has_error, 'validation_error': has_error}"
  >
    <div class="form-group">
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <label :for="name1" class="pull-left control-label rechner-form-label">
          {{ label }}
          <a v-if="info_text" class="rechner-form-info-button" @click="on_info_clicked"><span class="glyphicon glyphicon-info-sign"></span></a>
        </label>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <div class="input-group">
            <input
              type="text"
              inputmode="numeric"
              :id="name1"
              :name="name1"
              :placeholder="placeholder1"
              v-model.trim="form_value1"
              class="form-control text-right"
              :disabled="disabled"
            />
            <span class="input-group-addon">/</span>
            <input
              type="text"
              inputmode="numeric"
              :id="name2"
              :name="name2"
              :placeholder="placeholder2"
              v-model.trim="form_value2"
              class="form-control text-right"
              :disabled="disabled"
            />
        </div>
        <div v-if="has_error && form_error_message">
          <p>{{ form_error_message }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get';
import { try_get_number_de, is_number_min } from '@steuerrechner/steuerrechner-shared';
import { RechnerInputWithError } from '../RechnerInputWithError';
import RechnerInputWithInfoText from '../RechnerInputWithInfoText';
import {FORM_UPDATE, FORM_RAW_SET} from '../../store/mutations';

export default {
  name: "RechnerInputNumber",
  mixins: [
    RechnerInputWithError,
    RechnerInputWithInfoText
  ],
  props: {
    // Name und ID des inputs
    name: String,
    name1: String,
    name2: String,

    // Platzhalter Text
    placeholder1: String,
    placeholder2: String,

    // Bezeichnung für den Nutzer
    label: String,
    
    // Disable input fields
    disabled: Boolean,

    // Initialier Wert
    initial_value1: String,
    initial_value2: String,

    // Allow to set this to null by not entering anything
    allow_empty1: Boolean,
    allow_empty2: Boolean,
    disallow_zero1: Boolean,
    disallow_zero2: Boolean,
  },
  computed: {
    // Overwrite Error to include both fields
    error: {
        get() {
            let err1 = _get(this.$store.state.form_error, this.name1);
            if (err1) {
              return err1;
            }

            let err2 = _get(this.$store.state.form_error, this.name2);
            if (err2) {
              return err2;
            }

            return null;
        }
    },

    // Eingabefeld-Wert
    form_value1: {
      get() {
        return _get(this.$store.state.form_raw, this.name1);
      },
      set(value) {
        // set empty to null, if allowed and value is null or just empty string
        if (this.allow_empty1 === true && (value === null || value === "")) {
          this.$store.dispatch({
            type: FORM_RAW_SET,
            name: this.name1,
            value: null
          });
        }
        else {
          this.$store.dispatch({
            type: FORM_UPDATE,
            name: this.name1,
            validation_func: (val) => {
              let ret = try_get_number_de(val);
              if (ret !== false) {
                let ok = is_number_min(this.disallow_zero1 ? 1 : 0, ret);
                if (ok) {
                  return ret;
                }
              }
              return false;
            },
            error_message: this.error_message || "Bitte einen Wert angeben",
            value
          });
        }
      }
    },
    form_value2: {
      get() {
        return _get(this.$store.state.form_raw, this.name2);
      },
      set(value) {
        // set empty to null, if allowed and value is null or just empty string
        if (this.allow_empty2 === true && (value === null || value === "")) {
          this.$store.dispatch({
            type: FORM_RAW_SET,
            name: this.name2,
            value: null
          });
        }
        else {
          this.$store.dispatch({
            type: FORM_UPDATE,
            name: this.name2,
            validation_func: (val) => {
              let ret = try_get_number_de(val);
              if (ret !== false) {
                let ok = is_number_min(this.disallow_zero2 ? 1 : 0, ret);
                if (ok) {
                  return ret;
                }
              }
              return false;
            },
            error_message: this.error_message || "Bitte einen Wert angeben",
            value
          });
        }
      }
    }
  },
  mounted: function() {
    if (this.initial_value1) {
      this.form_value1 = this.initial_value1;
    }
    if (this.initial_value2) {
      this.form_value2 = this.initial_value2;
    }
  }
};
</script>

<style scoped lang="scss">
  .no-round button {
    border-radius: 0;
  }
</style>
