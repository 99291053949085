<template>
	<div>
		<div v-if="only_title">
            <span v-if="modal_detail_id=='hh_grundsteuermessbetrag'">
				Grundsteuermessbetrag
			</span>
            <span v-else-if="modal_detail_id=='hh_abwf'">
				Äquivalenzbetrag für die Wohnfläche
			</span>
            <span v-else-if="modal_detail_id=='hh_abnf'">
				Äquivalenzbetrag für die Nutzfläche
			</span>
            <span v-else-if="modal_detail_id=='hh_abgub'">
				Äquivalenzbetrag für den Grund und Boden
			</span>
		</div>
		<div v-else>
            <span v-if="modal_detail_id=='hh_grundsteuermessbetrag'">
              <table class="table table-striped">
				<colgroup>
					<col style="width:  6%; text-align: center;">
					<col style="width: 50%; text-align: left;">
					<col style="width: 22%; text-align: right;">
					<col style="width: 22%; text-align: right;">
				</colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th colspan="3">Grundsteuermessbetrag für die Wohnfläche</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">Äquivalenzbetrag Wohnfläche</td>
                    <td style="text-align: right;">{{zs_bl["ABWF_HH"] | pretty_print("pretty_number_round2", "€")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">&times;</td>
                    <td style="text-align: left;">Steuermesszahl Wohnfläche</td>
                    <td style="text-align: right;">{{zs_bl["SMZ_HH_WF_A"]*100 | pretty_print("pretty_number_round2", "%")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">&times;</td>
                    <td style="text-align: left;">Ermäßigung (nur) normale Wohnlage</td>
                    <td style="text-align: right;">{{zs_bl["SMZ_HH_WL"]*100 | pretty_print("pretty_number_round2", "%")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;">Grundsteuermessbetrag Wohnfläche</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{zs_bl["SMB_WF"] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-striped">
				<colgroup>
					<col style="width:  6%; text-align: center;">
					<col style="width: 50%; text-align: left;">
					<col style="width: 22%; text-align: right;">
					<col style="width: 22%; text-align: right;">
				</colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th colspan="3">Grundsteuermessbetrag für die Nutzfläche</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">Äquivalenzbetrag Nutzfläche</td>
                    <td style="text-align: right;">{{zs_bl["ABNF_HH"] | pretty_print("pretty_number_round2", "€")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">&times;</td>
                    <td style="text-align: left;">Steuermesszahl Nutzfläche</td>
                    <td style="text-align: right;">{{zs_bl["SMZ_HH_NF"]*100 | pretty_print("pretty_number_round2", "%")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;">Grundsteuermessbetrag Nutzfläche</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{zs_bl["SMB_NF"] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-striped">
				<colgroup>
					<col style="width:  6%; text-align: center;">
					<col style="width: 50%; text-align: left;">
					<col style="width: 22%; text-align: right;">
					<col style="width: 22%; text-align: right;">
				</colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th colspan="3">Grundsteuermessbetrag für den Grund und Boden</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">Äquivalenzbetrag Grund und Boden</td>
                    <td style="text-align: right;">{{zs_bl["ABGuB_HH"] | pretty_print("pretty_number_round2", "€")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">&times;</td>
                    <td style="text-align: left;">Steuermesszahl Grund und Boden</td>
                    <td style="text-align: right;">{{zs_bl["SMZ_HH_A"]*100 | pretty_print("pretty_number_round2", "%")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;">Grundsteuermessbetrag Grund und Boden</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{zs_bl["SMB_GuB"] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th width="6%" style="text-align: center;"></th>
                    <th width="50%" style="text-align: left;"></th>
                    <th width="22%" style="text-align: right;"></th>
                    <th width="22%" style="text-align: right;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td colspan="2" style="text-align: left;">Grundsteuermessbetrag für das Grundstück</td>
                    <td style="text-align: right;">{{as["grst_mb"] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                </tbody>
              </table>
            </span>
            <span v-else-if="modal_detail_id=='hh_abwf'">
              <table class="table table-striped">
				<colgroup>
					<col style="width:  6%; text-align: center;">
					<col style="width: 50%; text-align: left;">
					<col style="width: 22%; text-align: right;">
					<col style="width: 22%; text-align: right;">
				</colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th colspan="3">Äquivalenzbetrag für die Wohnfläche</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">Wohnfläche</td>
                    <td style="text-align: right;">{{zs_bl["WF_HH"] | pretty_print("pretty_number", "m²")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">&times;</td>
                    <td style="text-align: left;">Äquivalenzzahl</td>
                    <td style="text-align: right;">{{zs_bl["AZGebF_HH"] | pretty_print("pretty_number_round2", "€")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;">Äquivalenzbetrag Wohnfläche</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{zs_bl["ABWF_HH"] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                </tbody>
              </table>
            </span>
            <span v-else-if="modal_detail_id=='hh_abnf'">
              <table class="table table-striped">
				<colgroup>
					<col style="width:  6%; text-align: center;">
					<col style="width: 50%; text-align: left;">
					<col style="width: 22%; text-align: right;">
					<col style="width: 22%; text-align: right;">
				</colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th colspan="3">Äquivalenzbetrag für die Nutzfläche</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">Nutzfläche</td>
                    <td style="text-align: right;">{{zs_bl["NF_HH"] | pretty_print("pretty_number", "m²")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">&times;</td>
                    <td style="text-align: left;">Äquivalenzzahl</td>
                    <td style="text-align: right;">{{zs_bl["AZGebF_HH"] | pretty_print("pretty_number_round2", "€")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;">Äquivalenzbetrag Nutzfläche</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{zs_bl["ABNF_HH"] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                </tbody>
              </table>
            </span>
            <span v-else-if="modal_detail_id=='hh_abgub'">
              <table class="table table-striped">
				<colgroup>
					<col style="width:  6%; text-align: center;">
					<col style="width: 50%; text-align: left;">
					<col style="width: 22%; text-align: right;">
					<col style="width: 22%; text-align: right;">
				</colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th colspan="3">Äquivalenzbetrag für den Grund und Boden</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="text-align: center;"></td>
                    <td style="text-align: left;">Fläche des Grund und Bodens</td>
                    <td style="text-align: right;">{{zs_bl["GF_HH"] | pretty_print("pretty_number", "m²")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">&times;</td>
                    <td style="text-align: left;">Äquivalenzzahl</td>
                    <td style="text-align: right;">{{zs_bl["AZGuB_HH"] | pretty_print("pretty_number_round2", "€")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr v-if='zs_bl["ERM_ART_3"] !== 0'>
                    <td style="text-align: center;">./.</td>
                    <td style="text-align: left;">Ermäßigung (§&nbsp;3 Abs.&nbsp;1 S.&nbsp;2 HmbGrStG)</td>
                    <td style="text-align: right;">{{zs_bl["ERM_ART_3"] | pretty_print("pretty_number_round2", "€")}}</td>
                    <td style="text-align: right;"></td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">=</td>
                    <td style="text-align: left;">Äquivalenzbetrag Grund und Boden<br/>(abgerundet auf eine Nachkommastelle)</td>
                    <td style="text-align: right;"></td>
                    <td style="text-align: right;">{{zs_bl["ABGuB_HH"] | pretty_print("pretty_number_round2", "€")}}</td>
                  </tr>
                </tbody>
              </table>
            </span>
            <span v-else>
              <p>Es ist ein unerwarteter Fehler aufgetreten, bitte starten sie die Berechnung erneut.</p>
            </span>
		</div>
	</div>
</template>

<script>
export default {
	name: "RechnerErgebnisHH",
	props: {
		modal_detail_id: String,
		zs_bl: null,
		as: null,
		only_title: Boolean,
	},
};
</script>

<style scoped lang="scss">
</style>
