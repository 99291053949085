<template>
  <div class="row">
    <div class="col-sm-6 col-sm-offset-3">
      <div class="feedback_box_container">
        <h3>Feedback</h3>
        <div v-if="ags && !show_form && update_link">
          <a class="btn btn-primary" :href="update_link" target="_blank" style="margin: 0 4px 8px 4px;">Hebesatz aktualisieren</a>
          <button type="button" class="btn btn-primary" @click="show_form=true" style="margin: 0 4px 8px 4px;">Feedback zur Berechnung</button>
        </div>
        <div v-else>
          <div
            v-if="send_status!==true"
            class="form-group"
            :class="{'has-error': feedback_error}"
          >
            <label for="feedback_text">
              <span class="glyphicon glyphicon-pencil"></span>
              Fehler in der Berechnung, Fragen oder Lob? Schreiben Sie uns!
            </label>
            <textarea class="form-control" id="feedback_text" placeholder="Feedback hier eingeben..." rows="5" v-model="feedback_text" :disabled="is_sending"></textarea>
            <p v-if="feedback_error" class="text-danger">Bitte Feedback eingeben.</p>
          </div>
          <div
            v-if="send_status!==true"
            class="form-group"
          >
            <label for="feedback_email">
              <span class="glyphicon glyphicon-envelope"></span>
              Geben Sie Ihre E-Mail Adresse an und wir antworten
            </label>
            <input type="email" class="form-control" id="feedback_email" placeholder="meine@email.de" v-model="feedback_email" :disabled="is_sending">
          </div>
          <div>
            <h3 v-if="send_status===true" class="text-center text-success"><span class="glyphicon glyphicon-ok"></span> Vielen Dank für Ihr Feedback!</h3>
            <p v-if="send_status===false" class="text-center text-danger"><span class="glyphicon glyphicon-exclamation-sign"></span> Fehler beim Senden des Feedbacks aufgetreten. Bitte versuchen Sie es erneut.</p>
          </div>
          <div v-if="send_status!==true" id="feedback_submit_div">
            <button type="button" class="btn btn-primary" @click="on_send" :disabled="is_sending">Feedback senden</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';

export default {
  name: "FeedbackForm",
  props: {
    name: String,
    ags: String,
  },
  data: function() {
    return {
      show_form: false,

      feedback_email: '',
      feedback_text: '',

      feedback_error: false,

      is_sending: false,
      send_status: null
    }
  },
  computed: {
    update_link: function() {
      if (this.ags && process.env.VUE_APP_HEBESATZ_UPDATES_URL) {
        return process.env.VUE_APP_HEBESATZ_UPDATES_URL + "/" + this.ags;
      }
      return null;
    }
  },
  methods: {
    on_send: function() {
      if (!this.feedback_text || this.feedback_text == "") {
        this.feedback_error = true;
      }
      else {
        this.feedback_error = false;
      }

      if (!this.feedback_error) {
        this.is_sending = true;
        this.send_status = null;

        let that = this;
        axios({
              'method': 'POST',
              'url': "https://www.steuerrechner.com/app_server/feedback.php",
              'data':
                  qs.stringify({
                    feedback_text: this.feedback_text,
                    feedback_email: this.feedback_email,
                    feedback_info: JSON.stringify({
                      ...this.$store.getters.feedback_data
                    }, null, 2)
                  }),
              'headers':
                  {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Accept': '*/*'
                  }
            }
        )
            .then(function () {
              that.send_status = true;
              that.is_sending = false;
            })
            .catch(function () {
              that.send_status = false;
              that.is_sending = false;
            });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.feedback_box_container {
  margin: 8px;
}
</style>
