export const Gemeinden = {
  "1": [
    {
      "a": "01001000",
      "j": 2025,
      "n": "flensburg",
      "dn": "Flensburg",
      "bl": 1,
      "hs": 953.0,
      "mns": 3
    },
    {
      "a": "01002000",
      "j": 2024,
      "n": "kiel",
      "dn": "Kiel",
      "bl": 1,
      "hs": 500.0,
      "mns": 5
    },
    {
      "a": "01003000",
      "j": 2024,
      "n": "lübeck",
      "dn": "Lübeck",
      "bl": 1,
      "hs": 500.0,
      "mns": 4
    },
    {
      "a": "01004000",
      "j": 2024,
      "n": "neumünster",
      "dn": "Neumünster",
      "bl": 1,
      "hs": 480.0,
      "mns": 3
    },
    {
      "a": "01051001",
      "j": 2024,
      "n": "albersdorf",
      "dn": "Albersdorf",
      "bl": 1,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "01051002",
      "j": 2024,
      "n": "arkebek",
      "dn": "Arkebek",
      "bl": 1,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "01051003",
      "j": 2024,
      "n": "averlak",
      "dn": "Averlak",
      "bl": 1,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "01051004",
      "j": 2024,
      "n": "bargenstedt",
      "dn": "Bargenstedt",
      "bl": 1,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "01051005",
      "j": 2024,
      "n": "barkenholm",
      "dn": "Barkenholm",
      "bl": 1,
      "hs": 290.0,
      "mns": 1
    },
    {
      "a": "01051006",
      "j": 2024,
      "n": "barlt",
      "dn": "Barlt",
      "bl": 1,
      "hs": 200.0,
      "mns": 1
    },
    {
      "a": "01051008",
      "j": 2024,
      "n": "bergewöhrden",
      "dn": "Bergewöhrden",
      "bl": 1,
      "hs": 260.0,
      "mns": 1
    },
    {
      "a": "01051010",
      "j": 2024,
      "n": "brickeln",
      "dn": "Brickeln",
      "bl": 1,
      "hs": 295.0,
      "mns": 1
    },
    {
      "a": "01051011",
      "j": 2024,
      "n": "brunsbüttel",
      "dn": "Brunsbüttel",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01051012",
      "j": 2024,
      "n": "buchholz",
      "dn": "Buchholz",
      "bl": 1,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "01051013",
      "j": 2024,
      "n": "büsum",
      "dn": "Büsum",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01051014",
      "j": 2024,
      "n": "büsumer deichhausen",
      "dn": "Büsumer Deichhausen",
      "bl": 1,
      "hs": 250.0,
      "mns": 1
    },
    {
      "a": "01051015",
      "j": 2024,
      "n": "bunsoh",
      "dn": "Bunsoh",
      "bl": 1,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "01051016",
      "j": 2024,
      "n": "burg",
      "dn": "Burg",
      "bl": 1,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "01051017",
      "j": 2024,
      "n": "busenwurth",
      "dn": "Busenwurth",
      "bl": 1,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "01051019",
      "j": 2024,
      "n": "dellstedt",
      "dn": "Dellstedt",
      "bl": 1,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "01051020",
      "j": 2024,
      "n": "delve",
      "dn": "Delve",
      "bl": 1,
      "hs": 260.0,
      "mns": 1
    },
    {
      "a": "01051021",
      "j": 2024,
      "n": "diekhusen-fahrstedt",
      "dn": "Diekhusen-Fahrstedt",
      "bl": 1,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "01051022",
      "j": 2024,
      "n": "dingen",
      "dn": "Dingen",
      "bl": 1,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "01051023",
      "j": 2024,
      "n": "dörpling",
      "dn": "Dörpling",
      "bl": 1,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "01051024",
      "j": 2024,
      "n": "eddelak",
      "dn": "Eddelak",
      "bl": 1,
      "hs": 295.0,
      "mns": 1
    },
    {
      "a": "01051026",
      "j": 2024,
      "n": "eggstedt",
      "dn": "Eggstedt",
      "bl": 1,
      "hs": 295.0,
      "mns": 1
    },
    {
      "a": "01051027",
      "j": 2024,
      "n": "elpersbüttel",
      "dn": "Elpersbüttel",
      "bl": 1,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "01051028",
      "j": 2024,
      "n": "epenwöhrden",
      "dn": "Epenwöhrden",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01051030",
      "j": 2024,
      "n": "fedderingen",
      "dn": "Fedderingen",
      "bl": 1,
      "hs": 260.0,
      "mns": 1
    },
    {
      "a": "01051032",
      "j": 2024,
      "n": "frestedt",
      "dn": "Frestedt",
      "bl": 1,
      "hs": 295.0,
      "mns": 1
    },
    {
      "a": "01051033",
      "j": 2023,
      "n": "friedrichsgabekoog",
      "dn": "Friedrichsgabekoog",
      "bl": 1,
      "hs": 0.0,
      "mns": 1
    },
    {
      "a": "01051034",
      "j": 2024,
      "n": "friedrichskoog",
      "dn": "Friedrichskoog",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01051035",
      "j": 2024,
      "n": "gaushorn",
      "dn": "Gaushorn",
      "bl": 1,
      "hs": 260.0,
      "mns": 1
    },
    {
      "a": "01051036",
      "j": 2024,
      "n": "glüsing",
      "dn": "Glüsing",
      "bl": 1,
      "hs": 250.0,
      "mns": 1
    },
    {
      "a": "01051037",
      "j": 2024,
      "n": "großenrade",
      "dn": "Großenrade",
      "bl": 1,
      "hs": 295.0,
      "mns": 1
    },
    {
      "a": "01051038",
      "j": 2024,
      "n": "groven",
      "dn": "Groven",
      "bl": 1,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "01051039",
      "j": 2024,
      "n": "gudendorf",
      "dn": "Gudendorf",
      "bl": 1,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "01051043",
      "j": 2023,
      "n": "hedwigenkoog",
      "dn": "Hedwigenkoog",
      "bl": 1,
      "hs": 0.0,
      "mns": 1
    },
    {
      "a": "01051044",
      "j": 2024,
      "n": "heide",
      "dn": "Heide",
      "bl": 1,
      "hs": 430.0,
      "mns": 3
    },
    {
      "a": "01051045",
      "j": 2024,
      "n": "hellschen-heringsand-unterschaar",
      "dn": "Hellschen-Heringsand-Unterschaar",
      "bl": 1,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "01051046",
      "j": 2024,
      "n": "helse",
      "dn": "Helse",
      "bl": 1,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "01051047",
      "j": 2024,
      "n": "hemme",
      "dn": "Hemme",
      "bl": 1,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "01051048",
      "j": 2024,
      "n": "hemmingstedt",
      "dn": "Hemmingstedt",
      "bl": 1,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "01051049",
      "j": 2024,
      "n": "hennstedt",
      "dn": "Hennstedt",
      "bl": 1,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "01051050",
      "j": 2023,
      "n": "hillgroven",
      "dn": "Hillgroven",
      "bl": 1,
      "hs": 0.0,
      "mns": 1
    },
    {
      "a": "01051051",
      "j": 2024,
      "n": "hochdonn",
      "dn": "Hochdonn",
      "bl": 1,
      "hs": 311.0,
      "mns": 1
    },
    {
      "a": "01051052",
      "j": 2024,
      "n": "hövede",
      "dn": "Hövede",
      "bl": 1,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "01051053",
      "j": 2024,
      "n": "hollingstedt",
      "dn": "Hollingstedt",
      "bl": 1,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "01051054",
      "j": 2024,
      "n": "immenstedt",
      "dn": "Immenstedt",
      "bl": 1,
      "hs": 260.0,
      "mns": 1
    },
    {
      "a": "01051057",
      "j": 2024,
      "n": "kaiser-wilhelm-koog",
      "dn": "Kaiser-Wilhelm-Koog",
      "bl": 1,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "01051058",
      "j": 2024,
      "n": "karolinenkoog",
      "dn": "Karolinenkoog",
      "bl": 1,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "01051060",
      "j": 2024,
      "n": "kleve",
      "dn": "Kleve",
      "bl": 1,
      "hs": 260.0,
      "mns": 1
    },
    {
      "a": "01051061",
      "j": 2024,
      "n": "krempel",
      "dn": "Krempel",
      "bl": 1,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "01051062",
      "j": 2024,
      "n": "kronprinzenkoog",
      "dn": "Kronprinzenkoog",
      "bl": 1,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "01051063",
      "j": 2024,
      "n": "krumstedt",
      "dn": "Krumstedt",
      "bl": 1,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "01051064",
      "j": 2024,
      "n": "kuden",
      "dn": "Kuden",
      "bl": 1,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "01051065",
      "j": 2024,
      "n": "lehe",
      "dn": "Lehe",
      "bl": 1,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "01051067",
      "j": 2024,
      "n": "lieth",
      "dn": "Lieth",
      "bl": 1,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "01051068",
      "j": 2024,
      "n": "linden",
      "dn": "Linden",
      "bl": 1,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "01051069",
      "j": 2024,
      "n": "lohe-rickelshof",
      "dn": "Lohe-Rickelshof",
      "bl": 1,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "01051071",
      "j": 2024,
      "n": "lunden",
      "dn": "Lunden",
      "bl": 1,
      "hs": 356.0,
      "mns": 1
    },
    {
      "a": "01051072",
      "j": 2024,
      "n": "marne",
      "dn": "Marne",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01051073",
      "j": 2024,
      "n": "marnerdeich",
      "dn": "Marnerdeich",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01051074",
      "j": 2024,
      "n": "meldorf",
      "dn": "Meldorf",
      "bl": 1,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "01051075",
      "j": 2024,
      "n": "neuenkirchen",
      "dn": "Neuenkirchen",
      "bl": 1,
      "hs": 295.0,
      "mns": 1
    },
    {
      "a": "01051076",
      "j": 2024,
      "n": "neufeld",
      "dn": "Neufeld",
      "bl": 1,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "01051077",
      "j": 2024,
      "n": "neufelderkoog",
      "dn": "Neufelderkoog",
      "bl": 1,
      "hs": 290.0,
      "mns": 1
    },
    {
      "a": "01051078",
      "j": 2024,
      "n": "nindorf",
      "dn": "Nindorf",
      "bl": 1,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "01051079",
      "j": 2024,
      "n": "norddeich",
      "dn": "Norddeich",
      "bl": 1,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "01051080",
      "j": 2024,
      "n": "norderheistedt",
      "dn": "Norderheistedt",
      "bl": 1,
      "hs": 260.0,
      "mns": 1
    },
    {
      "a": "01051081",
      "j": 2024,
      "n": "norderwöhrden",
      "dn": "Norderwöhrden",
      "bl": 1,
      "hs": 0.0,
      "mns": 1
    },
    {
      "a": "01051082",
      "j": 2024,
      "n": "nordhastedt",
      "dn": "Nordhastedt",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01051083",
      "j": 2024,
      "n": "odderade",
      "dn": "Odderade",
      "bl": 1,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "01051084",
      "j": 2024,
      "n": "oesterdeichstrich",
      "dn": "Oesterdeichstrich",
      "bl": 1,
      "hs": 368.0,
      "mns": 1
    },
    {
      "a": "01051085",
      "j": 2024,
      "n": "offenbüttel",
      "dn": "Offenbüttel",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01051086",
      "j": 2024,
      "n": "osterrade",
      "dn": "Osterrade",
      "bl": 1,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "01051087",
      "j": 2024,
      "n": "ostrohe",
      "dn": "Ostrohe",
      "bl": 1,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "01051088",
      "j": 2024,
      "n": "pahlen",
      "dn": "Pahlen",
      "bl": 1,
      "hs": 315.0,
      "mns": 1
    },
    {
      "a": "01051089",
      "j": 2024,
      "n": "quickborn",
      "dn": "Quickborn",
      "bl": 1,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "01051090",
      "j": 2024,
      "n": "ramhusen",
      "dn": "Ramhusen",
      "bl": 1,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "01051092",
      "j": 2024,
      "n": "rehm-flehde-bargen",
      "dn": "Rehm-Flehde-Bargen",
      "bl": 1,
      "hs": 290.0,
      "mns": 1
    },
    {
      "a": "01051093",
      "j": 2024,
      "n": "reinsbüttel",
      "dn": "Reinsbüttel",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01051096",
      "j": 2024,
      "n": "sankt annen",
      "dn": "Sankt Annen",
      "bl": 1,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "01051097",
      "j": 2024,
      "n": "sankt michaelisdonn",
      "dn": "Sankt Michaelisdonn",
      "bl": 1,
      "hs": 295.0,
      "mns": 1
    },
    {
      "a": "01051098",
      "j": 2024,
      "n": "sarzbüttel",
      "dn": "Sarzbüttel",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01051099",
      "j": 2024,
      "n": "schafstedt",
      "dn": "Schafstedt",
      "bl": 1,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "01051100",
      "j": 2024,
      "n": "schalkholz",
      "dn": "Schalkholz",
      "bl": 1,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "01051102",
      "j": 2024,
      "n": "schlichting",
      "dn": "Schlichting",
      "bl": 1,
      "hs": 290.0,
      "mns": 1
    },
    {
      "a": "01051103",
      "j": 2024,
      "n": "schmedeswurth",
      "dn": "Schmedeswurth",
      "bl": 1,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "01051104",
      "j": 2024,
      "n": "schrum",
      "dn": "Schrum",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01051105",
      "j": 2024,
      "n": "schülp",
      "dn": "Schülp",
      "bl": 1,
      "hs": 230.0,
      "mns": 1
    },
    {
      "a": "01051107",
      "j": 2024,
      "n": "stelle-wittenwurth",
      "dn": "Stelle-Wittenwurth",
      "bl": 1,
      "hs": 225.0,
      "mns": 1
    },
    {
      "a": "01051108",
      "j": 2024,
      "n": "strübbel",
      "dn": "Strübbel",
      "bl": 1,
      "hs": 0.0,
      "mns": 1
    },
    {
      "a": "01051109",
      "j": 2024,
      "n": "süderdeich",
      "dn": "Süderdeich",
      "bl": 1,
      "hs": 290.0,
      "mns": 1
    },
    {
      "a": "01051110",
      "j": 2024,
      "n": "süderhastedt",
      "dn": "Süderhastedt",
      "bl": 1,
      "hs": 295.0,
      "mns": 1
    },
    {
      "a": "01051113",
      "j": 2024,
      "n": "wöhrden",
      "dn": "Wöhrden",
      "bl": 1,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "01051114",
      "j": 2024,
      "n": "tellingstedt",
      "dn": "Tellingstedt",
      "bl": 1,
      "hs": 295.0,
      "mns": 1
    },
    {
      "a": "01051117",
      "j": 2024,
      "n": "tielenhemme",
      "dn": "Tielenhemme",
      "bl": 1,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "01051118",
      "j": 2024,
      "n": "trennewurth",
      "dn": "Trennewurth",
      "bl": 1,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "01051119",
      "j": 2024,
      "n": "volsemenhusen",
      "dn": "Volsemenhusen",
      "bl": 1,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "01051120",
      "j": 2024,
      "n": "wallen",
      "dn": "Wallen",
      "bl": 1,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "01051121",
      "j": 2024,
      "n": "warwerort",
      "dn": "Warwerort",
      "bl": 1,
      "hs": 200.0,
      "mns": 1
    },
    {
      "a": "01051122",
      "j": 2024,
      "n": "weddingstedt",
      "dn": "Weddingstedt",
      "bl": 1,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "01051125",
      "j": 2024,
      "n": "welmbüttel",
      "dn": "Welmbüttel",
      "bl": 1,
      "hs": 260.0,
      "mns": 1
    },
    {
      "a": "01051126",
      "j": 2024,
      "n": "wennbüttel",
      "dn": "Wennbüttel",
      "bl": 1,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "01051127",
      "j": 2024,
      "n": "wesselburen",
      "dn": "Wesselburen",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01051128",
      "j": 2024,
      "n": "wesselburener deichhausen",
      "dn": "Wesselburener Deichhausen",
      "bl": 1,
      "hs": 0.0,
      "mns": 1
    },
    {
      "a": "01051129",
      "j": 2024,
      "n": "wesselburenerkoog",
      "dn": "Wesselburenerkoog",
      "bl": 1,
      "hs": 220.0,
      "mns": 1
    },
    {
      "a": "01051130",
      "j": 2024,
      "n": "wesseln",
      "dn": "Wesseln",
      "bl": 1,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "01051131",
      "j": 2024,
      "n": "westerborstel",
      "dn": "Westerborstel",
      "bl": 1,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "01051132",
      "j": 2024,
      "n": "westerdeichstrich",
      "dn": "Westerdeichstrich",
      "bl": 1,
      "hs": 260.0,
      "mns": 1
    },
    {
      "a": "01051133",
      "j": 2024,
      "n": "wiemerstedt",
      "dn": "Wiemerstedt",
      "bl": 1,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "01051134",
      "j": 2024,
      "n": "windbergen",
      "dn": "Windbergen",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01051135",
      "j": 2024,
      "n": "wolmersdorf",
      "dn": "Wolmersdorf",
      "bl": 1,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "01051136",
      "j": 2024,
      "n": "wrohm",
      "dn": "Wrohm",
      "bl": 1,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "01051137",
      "j": 2024,
      "n": "nordermeldorf",
      "dn": "Nordermeldorf",
      "bl": 1,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "01051138",
      "j": 2024,
      "n": "tensbüttel-röst",
      "dn": "Tensbüttel-Röst",
      "bl": 1,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "01051139",
      "j": 2024,
      "n": "süderdorf",
      "dn": "Süderdorf",
      "bl": 1,
      "hs": 270.0,
      "mns": 1
    },
    {
      "a": "01051140",
      "j": 2024,
      "n": "oesterwurth",
      "dn": "Oesterwurth",
      "bl": 1,
      "hs": 0.0,
      "mns": 1
    },
    {
      "a": "01051141",
      "j": 2024,
      "n": "süderheistedt",
      "dn": "Süderheistedt",
      "bl": 1,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "01053001",
      "j": 2024,
      "n": "albsfelde",
      "dn": "Albsfelde",
      "bl": 1,
      "hs": 290.0,
      "mns": 3
    },
    {
      "a": "01053002",
      "j": 2024,
      "n": "alt mölln",
      "dn": "Alt Mölln",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01053003",
      "j": 2024,
      "n": "aumühle",
      "dn": "Aumühle",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01053004",
      "j": 2024,
      "n": "bäk",
      "dn": "Bäk",
      "bl": 1,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "01053005",
      "j": 2024,
      "n": "bälau",
      "dn": "Bälau",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01053006",
      "j": 2024,
      "n": "basedow",
      "dn": "Basedow",
      "bl": 1,
      "hs": 240.0,
      "mns": 3
    },
    {
      "a": "01053007",
      "j": 2024,
      "n": "basthorst",
      "dn": "Basthorst",
      "bl": 1,
      "hs": 240.0,
      "mns": 3
    },
    {
      "a": "01053008",
      "j": 2024,
      "n": "behlendorf",
      "dn": "Behlendorf",
      "bl": 1,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "01053009",
      "j": 2024,
      "n": "berkenthin",
      "dn": "Berkenthin",
      "bl": 1,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "01053010",
      "j": 2024,
      "n": "besenthal",
      "dn": "Besenthal",
      "bl": 1,
      "hs": 290.0,
      "mns": 3
    },
    {
      "a": "01053011",
      "j": 2024,
      "n": "bliestorf",
      "dn": "Bliestorf",
      "bl": 1,
      "hs": 335.0,
      "mns": 3
    },
    {
      "a": "01053012",
      "j": 2024,
      "n": "börnsen",
      "dn": "Börnsen",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01053013",
      "j": 2024,
      "n": "borstorf",
      "dn": "Borstorf",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01053014",
      "j": 2024,
      "n": "breitenfelde",
      "dn": "Breitenfelde",
      "bl": 1,
      "hs": 290.0,
      "mns": 3
    },
    {
      "a": "01053015",
      "j": 2024,
      "n": "bröthen",
      "dn": "Bröthen",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01053016",
      "j": 2024,
      "n": "brunsmark",
      "dn": "Brunsmark",
      "bl": 1,
      "hs": 290.0,
      "mns": 3
    },
    {
      "a": "01053017",
      "j": 2024,
      "n": "brunstorf",
      "dn": "Brunstorf",
      "bl": 1,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "01053018",
      "j": 2024,
      "n": "buchholz",
      "dn": "Buchholz",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01053019",
      "j": 2024,
      "n": "buchhorst",
      "dn": "Buchhorst",
      "bl": 1,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "01053020",
      "j": 2024,
      "n": "büchen",
      "dn": "Büchen",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01053021",
      "j": 2024,
      "n": "dahmker",
      "dn": "Dahmker",
      "bl": 1,
      "hs": 280.0,
      "mns": 3
    },
    {
      "a": "01053022",
      "j": 2024,
      "n": "dalldorf",
      "dn": "Dalldorf",
      "bl": 1,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "01053023",
      "j": 2024,
      "n": "dassendorf",
      "dn": "Dassendorf",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01053024",
      "j": 2024,
      "n": "düchelsdorf",
      "dn": "Düchelsdorf",
      "bl": 1,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "01053025",
      "j": 2024,
      "n": "duvensee",
      "dn": "Duvensee",
      "bl": 1,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "01053026",
      "j": 2024,
      "n": "einhaus",
      "dn": "Einhaus",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01053027",
      "j": 2024,
      "n": "elmenhorst",
      "dn": "Elmenhorst",
      "bl": 1,
      "hs": 245.0,
      "mns": 3
    },
    {
      "a": "01053028",
      "j": 2024,
      "n": "escheburg",
      "dn": "Escheburg",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01053029",
      "j": 2024,
      "n": "fitzen",
      "dn": "Fitzen",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01053030",
      "j": 2024,
      "n": "fredeburg",
      "dn": "Fredeburg",
      "bl": 1,
      "hs": 290.0,
      "mns": 3
    },
    {
      "a": "01053031",
      "j": 2024,
      "n": "fuhlenhagen",
      "dn": "Fuhlenhagen",
      "bl": 1,
      "hs": 275.0,
      "mns": 3
    },
    {
      "a": "01053032",
      "j": 2024,
      "n": "geesthacht",
      "dn": "Geesthacht",
      "bl": 1,
      "hs": 425.0,
      "mns": 5
    },
    {
      "a": "01053033",
      "j": 2024,
      "n": "giesensdorf",
      "dn": "Giesensdorf",
      "bl": 1,
      "hs": 290.0,
      "mns": 3
    },
    {
      "a": "01053034",
      "j": 2024,
      "n": "göldenitz",
      "dn": "Göldenitz",
      "bl": 1,
      "hs": 363.0,
      "mns": 3
    },
    {
      "a": "01053035",
      "j": 2024,
      "n": "göttin",
      "dn": "Göttin",
      "bl": 1,
      "hs": 240.0,
      "mns": 3
    },
    {
      "a": "01053036",
      "j": 2024,
      "n": "grabau",
      "dn": "Grabau",
      "bl": 1,
      "hs": 290.0,
      "mns": 3
    },
    {
      "a": "01053037",
      "j": 2024,
      "n": "grambek",
      "dn": "Grambek",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01053038",
      "j": 2024,
      "n": "grinau",
      "dn": "Grinau",
      "bl": 1,
      "hs": 280.0,
      "mns": 3
    },
    {
      "a": "01053039",
      "j": 2024,
      "n": "groß boden",
      "dn": "Groß Boden",
      "bl": 1,
      "hs": 285.0,
      "mns": 3
    },
    {
      "a": "01053040",
      "j": 2024,
      "n": "groß disnack",
      "dn": "Groß Disnack",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01053041",
      "j": 2024,
      "n": "groß grönau",
      "dn": "Groß Grönau",
      "bl": 1,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "01053042",
      "j": 2024,
      "n": "groß pampau",
      "dn": "Groß Pampau",
      "bl": 1,
      "hs": 290.0,
      "mns": 3
    },
    {
      "a": "01053043",
      "j": 2024,
      "n": "groß sarau",
      "dn": "Groß Sarau",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01053044",
      "j": 2024,
      "n": "groß schenkenberg",
      "dn": "Groß Schenkenberg",
      "bl": 1,
      "hs": 280.0,
      "mns": 3
    },
    {
      "a": "01053045",
      "j": 2024,
      "n": "grove",
      "dn": "Grove",
      "bl": 1,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "01053046",
      "j": 2024,
      "n": "gudow",
      "dn": "Gudow",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01053047",
      "j": 2024,
      "n": "gülzow",
      "dn": "Gülzow",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01053048",
      "j": 2024,
      "n": "güster",
      "dn": "Güster",
      "bl": 1,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "01053049",
      "j": 2024,
      "n": "hamfelde (lauenburg)",
      "dn": "Hamfelde (Lauenburg)",
      "bl": 1,
      "hs": 200.0,
      "mns": 3
    },
    {
      "a": "01053050",
      "j": 2024,
      "n": "hamwarde",
      "dn": "Hamwarde",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01053051",
      "j": 2024,
      "n": "harmsdorf",
      "dn": "Harmsdorf",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01053052",
      "j": 2024,
      "n": "havekost",
      "dn": "Havekost",
      "bl": 1,
      "hs": 250.0,
      "mns": 3
    },
    {
      "a": "01053053",
      "j": 2024,
      "n": "hohenhorn",
      "dn": "Hohenhorn",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01053054",
      "j": 2024,
      "n": "hollenbek",
      "dn": "Hollenbek",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01053056",
      "j": 2024,
      "n": "hornbek",
      "dn": "Hornbek",
      "bl": 1,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "01053057",
      "j": 2024,
      "n": "horst",
      "dn": "Horst",
      "bl": 1,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "01053058",
      "j": 2024,
      "n": "juliusburg",
      "dn": "Juliusburg",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01053059",
      "j": 2024,
      "n": "kankelau",
      "dn": "Kankelau",
      "bl": 1,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "01053060",
      "j": 2024,
      "n": "kasseburg",
      "dn": "Kasseburg",
      "bl": 1,
      "hs": 260.0,
      "mns": 3
    },
    {
      "a": "01053061",
      "j": 2024,
      "n": "kastorf",
      "dn": "Kastorf",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01053062",
      "j": 2024,
      "n": "kittlitz",
      "dn": "Kittlitz",
      "bl": 1,
      "hs": 385.0,
      "mns": 3
    },
    {
      "a": "01053064",
      "j": 2024,
      "n": "klein pampau",
      "dn": "Klein Pampau",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01053066",
      "j": 2024,
      "n": "klein zecher",
      "dn": "Klein Zecher",
      "bl": 1,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "01053067",
      "j": 2024,
      "n": "klempau",
      "dn": "Klempau",
      "bl": 1,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "01053068",
      "j": 2024,
      "n": "klinkrade",
      "dn": "Klinkrade",
      "bl": 1,
      "hs": 311.0,
      "mns": 3
    },
    {
      "a": "01053069",
      "j": 2024,
      "n": "koberg",
      "dn": "Koberg",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01053070",
      "j": 2024,
      "n": "köthel (lauenburg)",
      "dn": "Köthel (Lauenburg)",
      "bl": 1,
      "hs": 280.0,
      "mns": 3
    },
    {
      "a": "01053071",
      "j": 2024,
      "n": "kollow",
      "dn": "Kollow",
      "bl": 1,
      "hs": 290.0,
      "mns": 3
    },
    {
      "a": "01053072",
      "j": 2024,
      "n": "kröppelshagen-fahrendorf",
      "dn": "Kröppelshagen-Fahrendorf",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01053073",
      "j": 2024,
      "n": "krüzen",
      "dn": "Krüzen",
      "bl": 1,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "01053074",
      "j": 2024,
      "n": "krukow",
      "dn": "Krukow",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01053075",
      "j": 2024,
      "n": "krummesse",
      "dn": "Krummesse",
      "bl": 1,
      "hs": 363.0,
      "mns": 3
    },
    {
      "a": "01053076",
      "j": 2024,
      "n": "kuddewörde",
      "dn": "Kuddewörde",
      "bl": 1,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "01053077",
      "j": 2024,
      "n": "kühsen",
      "dn": "Kühsen",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01053078",
      "j": 2024,
      "n": "kulpin",
      "dn": "Kulpin",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01053079",
      "j": 2024,
      "n": "labenz",
      "dn": "Labenz",
      "bl": 1,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "01053080",
      "j": 2024,
      "n": "langenlehsten",
      "dn": "Langenlehsten",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01053081",
      "j": 2024,
      "n": "lankau",
      "dn": "Lankau",
      "bl": 1,
      "hs": 290.0,
      "mns": 3
    },
    {
      "a": "01053082",
      "j": 2024,
      "n": "lanze",
      "dn": "Lanze",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01053083",
      "j": 2024,
      "n": "lauenburg/elbe",
      "dn": "Lauenburg/Elbe",
      "bl": 1,
      "hs": 470.0,
      "mns": 4
    },
    {
      "a": "01053084",
      "j": 2024,
      "n": "lehmrade",
      "dn": "Lehmrade",
      "bl": 1,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "01053085",
      "j": 2024,
      "n": "linau",
      "dn": "Linau",
      "bl": 1,
      "hs": 280.0,
      "mns": 3
    },
    {
      "a": "01053086",
      "j": 2024,
      "n": "lüchow",
      "dn": "Lüchow",
      "bl": 1,
      "hs": 295.0,
      "mns": 3
    },
    {
      "a": "01053087",
      "j": 2024,
      "n": "lütau",
      "dn": "Lütau",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01053088",
      "j": 2024,
      "n": "mechow",
      "dn": "Mechow",
      "bl": 1,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "01053089",
      "j": 2024,
      "n": "möhnsen",
      "dn": "Möhnsen",
      "bl": 1,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "01053090",
      "j": 2024,
      "n": "mölln",
      "dn": "Mölln",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01053091",
      "j": 2024,
      "n": "mühlenrade",
      "dn": "Mühlenrade",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01053092",
      "j": 2024,
      "n": "müssen",
      "dn": "Müssen",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01053093",
      "j": 2024,
      "n": "mustin",
      "dn": "Mustin",
      "bl": 1,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "01053094",
      "j": 2024,
      "n": "niendorf bei berkenthin",
      "dn": "Niendorf bei Berkenthin",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01053095",
      "j": 2024,
      "n": "niendorf a. d. st.",
      "dn": "Niendorf a. d. St.",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01053096",
      "j": 2024,
      "n": "nusse",
      "dn": "Nusse",
      "bl": 1,
      "hs": 311.0,
      "mns": 3
    },
    {
      "a": "01053097",
      "j": 2024,
      "n": "panten",
      "dn": "Panten",
      "bl": 1,
      "hs": 260.0,
      "mns": 3
    },
    {
      "a": "01053098",
      "j": 2024,
      "n": "pogeez",
      "dn": "Pogeez",
      "bl": 1,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "01053099",
      "j": 2024,
      "n": "poggensee",
      "dn": "Poggensee",
      "bl": 1,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "01053100",
      "j": 2024,
      "n": "ratzeburg",
      "dn": "Ratzeburg",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01053101",
      "j": 2024,
      "n": "ritzerau",
      "dn": "Ritzerau",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01053102",
      "j": 2024,
      "n": "römnitz",
      "dn": "Römnitz",
      "bl": 1,
      "hs": 290.0,
      "mns": 3
    },
    {
      "a": "01053103",
      "j": 2024,
      "n": "rondeshagen",
      "dn": "Rondeshagen",
      "bl": 1,
      "hs": 365.0,
      "mns": 3
    },
    {
      "a": "01053104",
      "j": 2024,
      "n": "roseburg",
      "dn": "Roseburg",
      "bl": 1,
      "hs": 260.0,
      "mns": 3
    },
    {
      "a": "01053106",
      "j": 2024,
      "n": "sahms",
      "dn": "Sahms",
      "bl": 1,
      "hs": 290.0,
      "mns": 3
    },
    {
      "a": "01053107",
      "j": 2024,
      "n": "salem",
      "dn": "Salem",
      "bl": 1,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "01053108",
      "j": 2024,
      "n": "sandesneben",
      "dn": "Sandesneben",
      "bl": 1,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "01053109",
      "j": 2024,
      "n": "schiphorst",
      "dn": "Schiphorst",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01053110",
      "j": 2024,
      "n": "schmilau",
      "dn": "Schmilau",
      "bl": 1,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "01053111",
      "j": 2024,
      "n": "schnakenbek",
      "dn": "Schnakenbek",
      "bl": 1,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "01053112",
      "j": 2024,
      "n": "schönberg",
      "dn": "Schönberg",
      "bl": 1,
      "hs": 280.0,
      "mns": 3
    },
    {
      "a": "01053113",
      "j": 2024,
      "n": "schretstaken",
      "dn": "Schretstaken",
      "bl": 1,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "01053114",
      "j": 2024,
      "n": "schürensöhlen",
      "dn": "Schürensöhlen",
      "bl": 1,
      "hs": 225.0,
      "mns": 3
    },
    {
      "a": "01053115",
      "j": 2024,
      "n": "schulendorf",
      "dn": "Schulendorf",
      "bl": 1,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "01053116",
      "j": 2024,
      "n": "schwarzenbek",
      "dn": "Schwarzenbek",
      "bl": 1,
      "hs": 440.0,
      "mns": 4
    },
    {
      "a": "01053117",
      "j": 2024,
      "n": "seedorf",
      "dn": "Seedorf",
      "bl": 1,
      "hs": 290.0,
      "mns": 3
    },
    {
      "a": "01053118",
      "j": 2024,
      "n": "siebenbäumen",
      "dn": "Siebenbäumen",
      "bl": 1,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "01053119",
      "j": 2024,
      "n": "siebeneichen",
      "dn": "Siebeneichen",
      "bl": 1,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "01053120",
      "j": 2024,
      "n": "sierksrade",
      "dn": "Sierksrade",
      "bl": 1,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "01053121",
      "j": 2024,
      "n": "sirksfelde",
      "dn": "Sirksfelde",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01053122",
      "j": 2024,
      "n": "steinhorst",
      "dn": "Steinhorst",
      "bl": 1,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "01053123",
      "j": 2024,
      "n": "sterley",
      "dn": "Sterley",
      "bl": 1,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "01053124",
      "j": 2024,
      "n": "stubben",
      "dn": "Stubben",
      "bl": 1,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "01053125",
      "j": 2024,
      "n": "talkau",
      "dn": "Talkau",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01053126",
      "j": 2024,
      "n": "tramm",
      "dn": "Tramm",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01053127",
      "j": 2024,
      "n": "walksfelde",
      "dn": "Walksfelde",
      "bl": 1,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "01053128",
      "j": 2024,
      "n": "wangelau",
      "dn": "Wangelau",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01053129",
      "j": 2024,
      "n": "wentorf bei hamburg",
      "dn": "Wentorf bei Hamburg",
      "bl": 1,
      "hs": 395.0,
      "mns": 5
    },
    {
      "a": "01053130",
      "j": 2024,
      "n": "wentorf",
      "dn": "Wentorf",
      "bl": 1,
      "hs": 290.0,
      "mns": 3
    },
    {
      "a": "01053131",
      "j": 2024,
      "n": "wiershop",
      "dn": "Wiershop",
      "bl": 1,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "01053132",
      "j": 2024,
      "n": "witzeeze",
      "dn": "Witzeeze",
      "bl": 1,
      "hs": 297.0,
      "mns": 3
    },
    {
      "a": "01053133",
      "j": 2024,
      "n": "wohltorf",
      "dn": "Wohltorf",
      "bl": 1,
      "hs": 450.0,
      "mns": 3
    },
    {
      "a": "01053134",
      "j": 2024,
      "n": "woltersdorf",
      "dn": "Woltersdorf",
      "bl": 1,
      "hs": 290.0,
      "mns": 3
    },
    {
      "a": "01053135",
      "j": 2024,
      "n": "worth",
      "dn": "Worth",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01053136",
      "j": 2024,
      "n": "ziethen",
      "dn": "Ziethen",
      "bl": 1,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "01054001",
      "j": 2024,
      "n": "achtrup",
      "dn": "Achtrup",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054002",
      "j": 2024,
      "n": "ahrenshöft",
      "dn": "Ahrenshöft",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01054003",
      "j": 2024,
      "n": "ahrenviöl",
      "dn": "Ahrenviöl",
      "bl": 1,
      "hs": 290.0,
      "mns": 2
    },
    {
      "a": "01054004",
      "j": 2024,
      "n": "ahrenviölfeld",
      "dn": "Ahrenviölfeld",
      "bl": 1,
      "hs": 290.0,
      "mns": 2
    },
    {
      "a": "01054005",
      "j": 2024,
      "n": "alkersum",
      "dn": "Alkersum",
      "bl": 1,
      "hs": 330.0,
      "mns": 5
    },
    {
      "a": "01054006",
      "j": 2024,
      "n": "almdorf",
      "dn": "Almdorf",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054007",
      "j": 2024,
      "n": "arlewatt",
      "dn": "Arlewatt",
      "bl": 1,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "01054009",
      "j": 2024,
      "n": "aventoft",
      "dn": "Aventoft",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054010",
      "j": 2024,
      "n": "bargum",
      "dn": "Bargum",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054011",
      "j": 2024,
      "n": "behrendorf",
      "dn": "Behrendorf",
      "bl": 1,
      "hs": 290.0,
      "mns": 2
    },
    {
      "a": "01054012",
      "j": 2024,
      "n": "bohmstedt",
      "dn": "Bohmstedt",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01054013",
      "j": 2024,
      "n": "bondelum",
      "dn": "Bondelum",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01054014",
      "j": 2024,
      "n": "bordelum",
      "dn": "Bordelum",
      "bl": 1,
      "hs": 373.0,
      "mns": 2
    },
    {
      "a": "01054015",
      "j": 2024,
      "n": "borgsum",
      "dn": "Borgsum",
      "bl": 1,
      "hs": 330.0,
      "mns": 5
    },
    {
      "a": "01054016",
      "j": 2024,
      "n": "bosbüll",
      "dn": "Bosbüll",
      "bl": 1,
      "hs": 100.0,
      "mns": 2
    },
    {
      "a": "01054017",
      "j": 2024,
      "n": "braderup",
      "dn": "Braderup",
      "bl": 1,
      "hs": 130.0,
      "mns": 2
    },
    {
      "a": "01054018",
      "j": 2024,
      "n": "bramstedtlund",
      "dn": "Bramstedtlund",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054019",
      "j": 2024,
      "n": "bredstedt",
      "dn": "Bredstedt",
      "bl": 1,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "01054020",
      "j": 2024,
      "n": "breklum",
      "dn": "Breklum",
      "bl": 1,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "01054022",
      "j": 2024,
      "n": "dagebüll",
      "dn": "Dagebüll",
      "bl": 1,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "01054023",
      "j": 2024,
      "n": "drage",
      "dn": "Drage",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01054024",
      "j": 2024,
      "n": "drelsdorf",
      "dn": "Drelsdorf",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054025",
      "j": 2024,
      "n": "dunsum",
      "dn": "Dunsum",
      "bl": 1,
      "hs": 300.0,
      "mns": 5
    },
    {
      "a": "01054026",
      "j": 2024,
      "n": "elisabeth-sophien-koog",
      "dn": "Elisabeth-Sophien-Koog",
      "bl": 1,
      "hs": 100.0,
      "mns": 2
    },
    {
      "a": "01054027",
      "j": 2024,
      "n": "ellhöft",
      "dn": "Ellhöft",
      "bl": 1,
      "hs": 140.0,
      "mns": 2
    },
    {
      "a": "01054032",
      "j": 2024,
      "n": "fresendelf",
      "dn": "Fresendelf",
      "bl": 1,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "01054033",
      "j": 2024,
      "n": "friedrichstadt",
      "dn": "Friedrichstadt",
      "bl": 1,
      "hs": 435.0,
      "mns": 2
    },
    {
      "a": "01054034",
      "j": 2024,
      "n": "friedrich-wilhelm-lübke-koog",
      "dn": "Friedrich-Wilhelm-Lübke-Koog",
      "bl": 1,
      "hs": 130.0,
      "mns": 2
    },
    {
      "a": "01054035",
      "j": 2024,
      "n": "kirchspiel garding",
      "dn": "Kirchspiel Garding",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054036",
      "j": 2024,
      "n": "garding",
      "dn": "Garding",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054037",
      "j": 2024,
      "n": "goldebek",
      "dn": "Goldebek",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054038",
      "j": 2024,
      "n": "goldelund",
      "dn": "Goldelund",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054039",
      "j": 2024,
      "n": "gröde",
      "dn": "Gröde",
      "bl": 1,
      "hs": 486.0,
      "mns": 5
    },
    {
      "a": "01054040",
      "j": 2024,
      "n": "grothusenkoog",
      "dn": "Grothusenkoog",
      "bl": 1,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "01054041",
      "j": 2024,
      "n": "haselund",
      "dn": "Haselund",
      "bl": 1,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "01054042",
      "j": 2024,
      "n": "hattstedt",
      "dn": "Hattstedt",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054043",
      "j": 2024,
      "n": "hattstedtermarsch",
      "dn": "Hattstedtermarsch",
      "bl": 1,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "01054045",
      "j": 2024,
      "n": "högel",
      "dn": "Högel",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054046",
      "j": 2024,
      "n": "hörnum (sylt)",
      "dn": "Hörnum (Sylt)",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01054048",
      "j": 2024,
      "n": "holm",
      "dn": "Holm",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054050",
      "j": 2024,
      "n": "hallig hooge",
      "dn": "Hallig Hooge",
      "bl": 1,
      "hs": 487.0,
      "mns": 5
    },
    {
      "a": "01054052",
      "j": 2024,
      "n": "horstedt",
      "dn": "Horstedt",
      "bl": 1,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "01054054",
      "j": 2024,
      "n": "hude",
      "dn": "Hude",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054055",
      "j": 2024,
      "n": "humptrup",
      "dn": "Humptrup",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054056",
      "j": 2025,
      "n": "husum",
      "dn": "Husum",
      "bl": 1,
      "hs": 543.0,
      "mns": 3
    },
    {
      "a": "01054057",
      "j": 2024,
      "n": "immenstedt",
      "dn": "Immenstedt",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054059",
      "j": 2024,
      "n": "joldelund",
      "dn": "Joldelund",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054061",
      "j": 2024,
      "n": "kampen",
      "dn": "Kampen",
      "bl": 1,
      "hs": 325.0,
      "mns": 2
    },
    {
      "a": "01054062",
      "j": 2024,
      "n": "karlum",
      "dn": "Karlum",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054063",
      "j": 2024,
      "n": "katharinenheerd",
      "dn": "Katharinenheerd",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054065",
      "j": 2024,
      "n": "klanxbüll",
      "dn": "Klanxbüll",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01054068",
      "j": 2024,
      "n": "klixbüll",
      "dn": "Klixbüll",
      "bl": 1,
      "hs": 368.0,
      "mns": 2
    },
    {
      "a": "01054070",
      "j": 2024,
      "n": "koldenbüttel",
      "dn": "Koldenbüttel",
      "bl": 1,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "01054071",
      "j": 2024,
      "n": "kolkerheide",
      "dn": "Kolkerheide",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01054072",
      "j": 2024,
      "n": "kotzenbüll",
      "dn": "Kotzenbüll",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054073",
      "j": 2024,
      "n": "ladelund",
      "dn": "Ladelund",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054074",
      "j": 2024,
      "n": "langeneß",
      "dn": "Langeneß",
      "bl": 1,
      "hs": 488.0,
      "mns": 5
    },
    {
      "a": "01054075",
      "j": 2024,
      "n": "langenhorn",
      "dn": "Langenhorn",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01054076",
      "j": 2024,
      "n": "leck",
      "dn": "Leck",
      "bl": 1,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "01054077",
      "j": 2024,
      "n": "lexgaard",
      "dn": "Lexgaard",
      "bl": 1,
      "hs": 180.0,
      "mns": 2
    },
    {
      "a": "01054078",
      "j": 2024,
      "n": "list auf sylt",
      "dn": "List auf Sylt",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01054079",
      "j": 2024,
      "n": "löwenstedt",
      "dn": "Löwenstedt",
      "bl": 1,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "01054080",
      "j": 2024,
      "n": "lütjenholm",
      "dn": "Lütjenholm",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01054083",
      "j": 2024,
      "n": "midlum",
      "dn": "Midlum",
      "bl": 1,
      "hs": 330.0,
      "mns": 5
    },
    {
      "a": "01054084",
      "j": 2024,
      "n": "mildstedt",
      "dn": "Mildstedt",
      "bl": 1,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "01054085",
      "j": 2024,
      "n": "nebel",
      "dn": "Nebel",
      "bl": 1,
      "hs": 340.0,
      "mns": 5
    },
    {
      "a": "01054086",
      "j": 2024,
      "n": "neukirchen",
      "dn": "Neukirchen",
      "bl": 1,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "01054087",
      "j": 2024,
      "n": "nieblum",
      "dn": "Nieblum",
      "bl": 1,
      "hs": 320.0,
      "mns": 5
    },
    {
      "a": "01054088",
      "j": 2024,
      "n": "niebüll",
      "dn": "Niebüll",
      "bl": 1,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "01054089",
      "j": 2024,
      "n": "norddorf auf amrum",
      "dn": "Norddorf auf Amrum",
      "bl": 1,
      "hs": 320.0,
      "mns": 5
    },
    {
      "a": "01054090",
      "j": 2023,
      "n": "norderfriedrichskoog",
      "dn": "Norderfriedrichskoog",
      "bl": 1,
      "hs": 0.0,
      "mns": 2
    },
    {
      "a": "01054091",
      "j": 2024,
      "n": "nordstrand",
      "dn": "Nordstrand",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054092",
      "j": 2024,
      "n": "norstedt",
      "dn": "Norstedt",
      "bl": 1,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "01054093",
      "j": 2024,
      "n": "ockholm",
      "dn": "Ockholm",
      "bl": 1,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "01054094",
      "j": 2024,
      "n": "oevenum",
      "dn": "Oevenum",
      "bl": 1,
      "hs": 425.0,
      "mns": 5
    },
    {
      "a": "01054095",
      "j": 2024,
      "n": "oldenswort",
      "dn": "Oldenswort",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054096",
      "j": 2024,
      "n": "oldersbek",
      "dn": "Oldersbek",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054097",
      "j": 2024,
      "n": "olderup",
      "dn": "Olderup",
      "bl": 1,
      "hs": 290.0,
      "mns": 2
    },
    {
      "a": "01054098",
      "j": 2024,
      "n": "oldsum",
      "dn": "Oldsum",
      "bl": 1,
      "hs": 330.0,
      "mns": 5
    },
    {
      "a": "01054099",
      "j": 2024,
      "n": "ostenfeld (husum)",
      "dn": "Ostenfeld (Husum)",
      "bl": 1,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "01054100",
      "j": 2024,
      "n": "osterhever",
      "dn": "Osterhever",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054101",
      "j": 2024,
      "n": "oster-ohrstedt",
      "dn": "Oster-Ohrstedt",
      "bl": 1,
      "hs": 290.0,
      "mns": 2
    },
    {
      "a": "01054103",
      "j": 2024,
      "n": "pellworm",
      "dn": "Pellworm",
      "bl": 1,
      "hs": 485.0,
      "mns": 5
    },
    {
      "a": "01054104",
      "j": 2024,
      "n": "poppenbüll",
      "dn": "Poppenbüll",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054105",
      "j": 2024,
      "n": "ramstedt",
      "dn": "Ramstedt",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054106",
      "j": 2024,
      "n": "rantrum",
      "dn": "Rantrum",
      "bl": 1,
      "hs": 455.0,
      "mns": 2
    },
    {
      "a": "01054108",
      "j": 2024,
      "n": "reußenköge",
      "dn": "Reußenköge",
      "bl": 1,
      "hs": 280.0,
      "mns": 2
    },
    {
      "a": "01054109",
      "j": 2024,
      "n": "risum-lindholm",
      "dn": "Risum-Lindholm",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01054110",
      "j": 2024,
      "n": "rodenäs",
      "dn": "Rodenäs",
      "bl": 1,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "01054113",
      "j": 2024,
      "n": "sankt peter-ording",
      "dn": "Sankt Peter-Ording",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01054116",
      "j": 2024,
      "n": "schwabstedt",
      "dn": "Schwabstedt",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054118",
      "j": 2024,
      "n": "schwesing",
      "dn": "Schwesing",
      "bl": 1,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "01054119",
      "j": 2024,
      "n": "seeth",
      "dn": "Seeth",
      "bl": 1,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "01054120",
      "j": 2024,
      "n": "simonsberg",
      "dn": "Simonsberg",
      "bl": 1,
      "hs": 290.0,
      "mns": 2
    },
    {
      "a": "01054121",
      "j": 2024,
      "n": "sönnebüll",
      "dn": "Sönnebüll",
      "bl": 1,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "01054123",
      "j": 2024,
      "n": "sollwitt",
      "dn": "Sollwitt",
      "bl": 1,
      "hs": 290.0,
      "mns": 2
    },
    {
      "a": "01054124",
      "j": 2024,
      "n": "sprakebüll",
      "dn": "Sprakebüll",
      "bl": 1,
      "hs": 250.0,
      "mns": 2
    },
    {
      "a": "01054125",
      "j": 2024,
      "n": "stadum",
      "dn": "Stadum",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054126",
      "j": 2024,
      "n": "stedesand",
      "dn": "Stedesand",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054128",
      "j": 2024,
      "n": "struckum",
      "dn": "Struckum",
      "bl": 1,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "01054129",
      "j": 2024,
      "n": "süderende",
      "dn": "Süderende",
      "bl": 1,
      "hs": 300.0,
      "mns": 5
    },
    {
      "a": "01054130",
      "j": 2024,
      "n": "süderhöft",
      "dn": "Süderhöft",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054131",
      "j": 2024,
      "n": "süderlügum",
      "dn": "Süderlügum",
      "bl": 1,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "01054132",
      "j": 2023,
      "n": "südermarsch",
      "dn": "Südermarsch",
      "bl": 1,
      "hs": 0.0,
      "mns": 2
    },
    {
      "a": "01054134",
      "j": 2024,
      "n": "tating",
      "dn": "Tating",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054135",
      "j": 2024,
      "n": "tetenbüll",
      "dn": "Tetenbüll",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054136",
      "j": 2024,
      "n": "tinningstedt",
      "dn": "Tinningstedt",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054138",
      "j": 2024,
      "n": "tönning",
      "dn": "Tönning",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054140",
      "j": 2024,
      "n": "tümlauer-koog",
      "dn": "Tümlauer-Koog",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054141",
      "j": 2024,
      "n": "uelvesbüll",
      "dn": "Uelvesbüll",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01054142",
      "j": 2024,
      "n": "uphusum",
      "dn": "Uphusum",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054143",
      "j": 2024,
      "n": "utersum",
      "dn": "Utersum",
      "bl": 1,
      "hs": 390.0,
      "mns": 5
    },
    {
      "a": "01054144",
      "j": 2024,
      "n": "viöl",
      "dn": "Viöl",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01054145",
      "j": 2024,
      "n": "vollerwiek",
      "dn": "Vollerwiek",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054146",
      "j": 2024,
      "n": "vollstedt",
      "dn": "Vollstedt",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01054148",
      "j": 2024,
      "n": "welt",
      "dn": "Welt",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054149",
      "j": 2024,
      "n": "wenningstedt-braderup",
      "dn": "Wenningstedt-Braderup",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01054150",
      "j": 2024,
      "n": "westerhever",
      "dn": "Westerhever",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01054152",
      "j": 2024,
      "n": "wester-ohrstedt",
      "dn": "Wester-Ohrstedt",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01054154",
      "j": 2024,
      "n": "westre",
      "dn": "Westre",
      "bl": 1,
      "hs": 200.0,
      "mns": 2
    },
    {
      "a": "01054156",
      "j": 2024,
      "n": "winnert",
      "dn": "Winnert",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01054157",
      "j": 2024,
      "n": "wisch",
      "dn": "Wisch",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054158",
      "j": 2024,
      "n": "witsum",
      "dn": "Witsum",
      "bl": 1,
      "hs": 390.0,
      "mns": 5
    },
    {
      "a": "01054159",
      "j": 2024,
      "n": "wittbek",
      "dn": "Wittbek",
      "bl": 1,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "01054160",
      "j": 2024,
      "n": "wittdün auf amrum",
      "dn": "Wittdün auf Amrum",
      "bl": 1,
      "hs": 425.0,
      "mns": 5
    },
    {
      "a": "01054161",
      "j": 2024,
      "n": "witzwort",
      "dn": "Witzwort",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054162",
      "j": 2024,
      "n": "wobbenbüll",
      "dn": "Wobbenbüll",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054163",
      "j": 2024,
      "n": "wrixum",
      "dn": "Wrixum",
      "bl": 1,
      "hs": 425.0,
      "mns": 5
    },
    {
      "a": "01054164",
      "j": 2024,
      "n": "wyk auf föhr",
      "dn": "Wyk auf Föhr",
      "bl": 1,
      "hs": 425.0,
      "mns": 5
    },
    {
      "a": "01054165",
      "j": 2024,
      "n": "galmsbüll",
      "dn": "Galmsbüll",
      "bl": 1,
      "hs": 120.0,
      "mns": 2
    },
    {
      "a": "01054166",
      "j": 2024,
      "n": "emmelsbüll-horsbüll",
      "dn": "Emmelsbüll-Horsbüll",
      "bl": 1,
      "hs": 250.0,
      "mns": 2
    },
    {
      "a": "01054167",
      "j": 2024,
      "n": "enge-sande",
      "dn": "Enge-Sande",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01054168",
      "j": 2024,
      "n": "gemeinde sylt",
      "dn": "Gemeinde Sylt",
      "bl": 1,
      "hs": 330.0,
      "mns": 6
    },
    {
      "a": "01055001",
      "j": 2024,
      "n": "ahrensbök",
      "dn": "Ahrensbök",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01055002",
      "j": 2024,
      "n": "altenkrempe",
      "dn": "Altenkrempe",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01055004",
      "j": 2024,
      "n": "bad schwartau",
      "dn": "Bad Schwartau",
      "bl": 1,
      "hs": 425.0,
      "mns": 5
    },
    {
      "a": "01055006",
      "j": 2024,
      "n": "beschendorf",
      "dn": "Beschendorf",
      "bl": 1,
      "hs": 345.0,
      "mns": 3
    },
    {
      "a": "01055007",
      "j": 2024,
      "n": "bosau",
      "dn": "Bosau",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01055010",
      "j": 2024,
      "n": "dahme",
      "dn": "Dahme",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01055011",
      "j": 2024,
      "n": "damlos",
      "dn": "Damlos",
      "bl": 1,
      "hs": 325.0,
      "mns": 3
    },
    {
      "a": "01055012",
      "j": 2024,
      "n": "eutin",
      "dn": "Eutin",
      "bl": 1,
      "hs": 435.0,
      "mns": 4
    },
    {
      "a": "01055014",
      "j": 2024,
      "n": "göhl",
      "dn": "Göhl",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01055015",
      "j": 2024,
      "n": "gremersdorf",
      "dn": "Gremersdorf",
      "bl": 1,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "01055016",
      "j": 2024,
      "n": "grömitz",
      "dn": "Grömitz",
      "bl": 1,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "01055017",
      "j": 2024,
      "n": "großenbrode",
      "dn": "Großenbrode",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01055018",
      "j": 2024,
      "n": "grube",
      "dn": "Grube",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01055020",
      "j": 2024,
      "n": "harmsdorf",
      "dn": "Harmsdorf",
      "bl": 1,
      "hs": 345.0,
      "mns": 3
    },
    {
      "a": "01055021",
      "j": 2024,
      "n": "heiligenhafen",
      "dn": "Heiligenhafen",
      "bl": 1,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "01055022",
      "j": 2024,
      "n": "heringsdorf",
      "dn": "Heringsdorf",
      "bl": 1,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "01055023",
      "j": 2024,
      "n": "kabelhorst",
      "dn": "Kabelhorst",
      "bl": 1,
      "hs": 345.0,
      "mns": 3
    },
    {
      "a": "01055024",
      "j": 2024,
      "n": "kasseedorf",
      "dn": "Kasseedorf",
      "bl": 1,
      "hs": 440.0,
      "mns": 3
    },
    {
      "a": "01055025",
      "j": 2024,
      "n": "kellenhusen (ostsee)",
      "dn": "Kellenhusen (Ostsee)",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01055027",
      "j": 2024,
      "n": "lensahn",
      "dn": "Lensahn",
      "bl": 1,
      "hs": 325.0,
      "mns": 3
    },
    {
      "a": "01055028",
      "j": 2024,
      "n": "malente",
      "dn": "Malente",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01055029",
      "j": 2024,
      "n": "manhagen",
      "dn": "Manhagen",
      "bl": 1,
      "hs": 325.0,
      "mns": 3
    },
    {
      "a": "01055031",
      "j": 2024,
      "n": "neukirchen",
      "dn": "Neukirchen",
      "bl": 1,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "01055032",
      "j": 2024,
      "n": "neustadt in holstein",
      "dn": "Neustadt in Holstein",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01055033",
      "j": 2024,
      "n": "oldenburg in holstein",
      "dn": "Oldenburg in Holstein",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01055035",
      "j": 2024,
      "n": "ratekau",
      "dn": "Ratekau",
      "bl": 1,
      "hs": 360.0,
      "mns": 4
    },
    {
      "a": "01055036",
      "j": 2024,
      "n": "riepsdorf",
      "dn": "Riepsdorf",
      "bl": 1,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "01055037",
      "j": 2024,
      "n": "schashagen",
      "dn": "Schashagen",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01055038",
      "j": 2024,
      "n": "schönwalde am bungsberg",
      "dn": "Schönwalde am Bungsberg",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01055039",
      "j": 2024,
      "n": "sierksdorf",
      "dn": "Sierksdorf",
      "bl": 1,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "01055040",
      "j": 2024,
      "n": "stockelsdorf",
      "dn": "Stockelsdorf",
      "bl": 1,
      "hs": 330.0,
      "mns": 4
    },
    {
      "a": "01055041",
      "j": 2024,
      "n": "süsel",
      "dn": "Süsel",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01055042",
      "j": 2024,
      "n": "timmendorfer strand",
      "dn": "Timmendorfer Strand",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01055043",
      "j": 2024,
      "n": "wangels",
      "dn": "Wangels",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01055044",
      "j": 2024,
      "n": "scharbeutz",
      "dn": "Scharbeutz",
      "bl": 1,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "01055046",
      "j": 2024,
      "n": "fehmarn",
      "dn": "Fehmarn",
      "bl": 1,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "01056001",
      "j": 2024,
      "n": "appen",
      "dn": "Appen",
      "bl": 1,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "01056002",
      "j": 2024,
      "n": "barmstedt",
      "dn": "Barmstedt",
      "bl": 1,
      "hs": 455.0,
      "mns": 4
    },
    {
      "a": "01056003",
      "j": 2024,
      "n": "bevern",
      "dn": "Bevern",
      "bl": 1,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "01056004",
      "j": 2024,
      "n": "bilsen",
      "dn": "Bilsen",
      "bl": 1,
      "hs": 270.0,
      "mns": 3
    },
    {
      "a": "01056005",
      "j": 2025,
      "n": "bönningstedt",
      "dn": "Bönningstedt",
      "bl": 1,
      "hs": 485.0,
      "mns": 3
    },
    {
      "a": "01056006",
      "j": 2024,
      "n": "bokel",
      "dn": "Bokel",
      "bl": 1,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "01056008",
      "j": 2024,
      "n": "bokholt-hanredder",
      "dn": "Bokholt-Hanredder",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01056009",
      "j": 2024,
      "n": "borstel-hohenraden",
      "dn": "Borstel-Hohenraden",
      "bl": 1,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "01056010",
      "j": 2024,
      "n": "brande-hörnerkirchen",
      "dn": "Brande-Hörnerkirchen",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01056011",
      "j": 2024,
      "n": "bullenkuhlen",
      "dn": "Bullenkuhlen",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01056013",
      "j": 2025,
      "n": "ellerbek",
      "dn": "Ellerbek",
      "bl": 1,
      "hs": 378.0,
      "mns": 3
    },
    {
      "a": "01056014",
      "j": 2024,
      "n": "ellerhoop",
      "dn": "Ellerhoop",
      "bl": 1,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "01056015",
      "j": 2024,
      "n": "elmshorn",
      "dn": "Elmshorn",
      "bl": 1,
      "hs": 425.0,
      "mns": 4
    },
    {
      "a": "01056016",
      "j": 2024,
      "n": "groß nordende",
      "dn": "Groß Nordende",
      "bl": 1,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "01056017",
      "j": 2024,
      "n": "groß offenseth-aspern",
      "dn": "Groß Offenseth-Aspern",
      "bl": 1,
      "hs": 290.0,
      "mns": 3
    },
    {
      "a": "01056018",
      "j": 2024,
      "n": "halstenbek",
      "dn": "Halstenbek",
      "bl": 1,
      "hs": 425.0,
      "mns": 6
    },
    {
      "a": "01056019",
      "j": 2024,
      "n": "haselau",
      "dn": "Haselau",
      "bl": 1,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "01056020",
      "j": 2024,
      "n": "haseldorf",
      "dn": "Haseldorf",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01056021",
      "j": 2024,
      "n": "hasloh",
      "dn": "Hasloh",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01056022",
      "j": 2024,
      "n": "heede",
      "dn": "Heede",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01056023",
      "j": 2024,
      "n": "heidgraben",
      "dn": "Heidgraben",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01056024",
      "j": 2024,
      "n": "heist",
      "dn": "Heist",
      "bl": 1,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "01056025",
      "j": 2024,
      "n": "helgoland",
      "dn": "Helgoland",
      "bl": 1,
      "hs": 380.0,
      "mns": 5
    },
    {
      "a": "01056026",
      "j": 2024,
      "n": "hemdingen",
      "dn": "Hemdingen",
      "bl": 1,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "01056027",
      "j": 2024,
      "n": "hetlingen",
      "dn": "Hetlingen",
      "bl": 1,
      "hs": 450.0,
      "mns": 3
    },
    {
      "a": "01056028",
      "j": 2024,
      "n": "holm",
      "dn": "Holm",
      "bl": 1,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "01056029",
      "j": 2024,
      "n": "klein nordende",
      "dn": "Klein Nordende",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01056030",
      "j": 2024,
      "n": "klein offenseth-sparrieshoop",
      "dn": "Klein Offenseth-Sparrieshoop",
      "bl": 1,
      "hs": 367.0,
      "mns": 3
    },
    {
      "a": "01056031",
      "j": 2024,
      "n": "kölln-reisiek",
      "dn": "Kölln-Reisiek",
      "bl": 1,
      "hs": 402.0,
      "mns": 3
    },
    {
      "a": "01056032",
      "j": 2024,
      "n": "kummerfeld",
      "dn": "Kummerfeld",
      "bl": 1,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "01056033",
      "j": 2024,
      "n": "seester",
      "dn": "Seester",
      "bl": 1,
      "hs": 368.0,
      "mns": 3
    },
    {
      "a": "01056034",
      "j": 2024,
      "n": "langeln",
      "dn": "Langeln",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01056035",
      "j": 2024,
      "n": "lutzhorn",
      "dn": "Lutzhorn",
      "bl": 1,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "01056036",
      "j": 2024,
      "n": "moorrege",
      "dn": "Moorrege",
      "bl": 1,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "01056037",
      "j": 2024,
      "n": "neuendeich",
      "dn": "Neuendeich",
      "bl": 1,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "01056038",
      "j": 2024,
      "n": "osterhorn",
      "dn": "Osterhorn",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01056039",
      "j": 2024,
      "n": "pinneberg",
      "dn": "Pinneberg",
      "bl": 1,
      "hs": 450.0,
      "mns": 5
    },
    {
      "a": "01056040",
      "j": 2024,
      "n": "prisdorf",
      "dn": "Prisdorf",
      "bl": 1,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "01056041",
      "j": 2024,
      "n": "quickborn",
      "dn": "Quickborn",
      "bl": 1,
      "hs": 425.0,
      "mns": 5
    },
    {
      "a": "01056042",
      "j": 2024,
      "n": "raa-besenbek",
      "dn": "Raa-Besenbek",
      "bl": 1,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "01056043",
      "j": 2024,
      "n": "rellingen",
      "dn": "Rellingen",
      "bl": 1,
      "hs": 310.0,
      "mns": 5
    },
    {
      "a": "01056044",
      "j": 2024,
      "n": "schenefeld",
      "dn": "Schenefeld",
      "bl": 1,
      "hs": 380.0,
      "mns": 7
    },
    {
      "a": "01056045",
      "j": 2024,
      "n": "seestermühe",
      "dn": "Seestermühe",
      "bl": 1,
      "hs": 367.0,
      "mns": 3
    },
    {
      "a": "01056046",
      "j": 2024,
      "n": "seeth-ekholt",
      "dn": "Seeth-Ekholt",
      "bl": 1,
      "hs": 367.0,
      "mns": 3
    },
    {
      "a": "01056047",
      "j": 2024,
      "n": "tangstedt",
      "dn": "Tangstedt",
      "bl": 1,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "01056048",
      "j": 2024,
      "n": "tornesch",
      "dn": "Tornesch",
      "bl": 1,
      "hs": 425.0,
      "mns": 5
    },
    {
      "a": "01056049",
      "j": 2024,
      "n": "uetersen",
      "dn": "Uetersen",
      "bl": 1,
      "hs": 425.0,
      "mns": 4
    },
    {
      "a": "01056050",
      "j": 2024,
      "n": "wedel",
      "dn": "Wedel",
      "bl": 1,
      "hs": 540.0,
      "mns": 6
    },
    {
      "a": "01056051",
      "j": 2024,
      "n": "westerhorn",
      "dn": "Westerhorn",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01057001",
      "j": 2024,
      "n": "ascheberg",
      "dn": "Ascheberg",
      "bl": 1,
      "hs": 280.0,
      "mns": 3
    },
    {
      "a": "01057002",
      "j": 2024,
      "n": "barmissen",
      "dn": "Barmissen",
      "bl": 1,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "01057003",
      "j": 2024,
      "n": "barsbek",
      "dn": "Barsbek",
      "bl": 1,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "01057004",
      "j": 2024,
      "n": "behrensdorf (ostsee)",
      "dn": "Behrensdorf (Ostsee)",
      "bl": 1,
      "hs": 280.0,
      "mns": 3
    },
    {
      "a": "01057005",
      "j": 2024,
      "n": "belau",
      "dn": "Belau",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01057006",
      "j": 2024,
      "n": "bendfeld",
      "dn": "Bendfeld",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057007",
      "j": 2024,
      "n": "blekendorf",
      "dn": "Blekendorf",
      "bl": 1,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "01057008",
      "j": 2024,
      "n": "bönebüttel",
      "dn": "Bönebüttel",
      "bl": 1,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "01057009",
      "j": 2024,
      "n": "bösdorf",
      "dn": "Bösdorf",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01057010",
      "j": 2024,
      "n": "boksee",
      "dn": "Boksee",
      "bl": 1,
      "hs": 368.0,
      "mns": 3
    },
    {
      "a": "01057011",
      "j": 2024,
      "n": "bothkamp",
      "dn": "Bothkamp",
      "bl": 1,
      "hs": 368.0,
      "mns": 3
    },
    {
      "a": "01057012",
      "j": 2024,
      "n": "brodersdorf",
      "dn": "Brodersdorf",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057013",
      "j": 2024,
      "n": "dannau",
      "dn": "Dannau",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01057015",
      "j": 2024,
      "n": "dersau",
      "dn": "Dersau",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057016",
      "j": 2024,
      "n": "dobersdorf",
      "dn": "Dobersdorf",
      "bl": 1,
      "hs": 331.0,
      "mns": 3
    },
    {
      "a": "01057017",
      "j": 2024,
      "n": "dörnick",
      "dn": "Dörnick",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01057018",
      "j": 2024,
      "n": "fahren",
      "dn": "Fahren",
      "bl": 1,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "01057020",
      "j": 2024,
      "n": "fiefbergen",
      "dn": "Fiefbergen",
      "bl": 1,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "01057021",
      "j": 2024,
      "n": "giekau",
      "dn": "Giekau",
      "bl": 1,
      "hs": 290.0,
      "mns": 3
    },
    {
      "a": "01057022",
      "j": 2024,
      "n": "grebin",
      "dn": "Grebin",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057023",
      "j": 2024,
      "n": "großbarkau",
      "dn": "Großbarkau",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057024",
      "j": 2024,
      "n": "großharrie",
      "dn": "Großharrie",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01057025",
      "j": 2024,
      "n": "heikendorf",
      "dn": "Heikendorf",
      "bl": 1,
      "hs": 480.0,
      "mns": 3
    },
    {
      "a": "01057026",
      "j": 2024,
      "n": "helmstorf",
      "dn": "Helmstorf",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01057027",
      "j": 2024,
      "n": "högsdorf",
      "dn": "Högsdorf",
      "bl": 1,
      "hs": 260.0,
      "mns": 3
    },
    {
      "a": "01057028",
      "j": 2024,
      "n": "höhndorf",
      "dn": "Höhndorf",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057029",
      "j": 2024,
      "n": "hohenfelde",
      "dn": "Hohenfelde",
      "bl": 1,
      "hs": 240.0,
      "mns": 3
    },
    {
      "a": "01057030",
      "j": 2024,
      "n": "hohwacht (ostsee)",
      "dn": "Hohwacht (Ostsee)",
      "bl": 1,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "01057031",
      "j": 2024,
      "n": "honigsee",
      "dn": "Honigsee",
      "bl": 1,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "01057032",
      "j": 2024,
      "n": "kalübbe",
      "dn": "Kalübbe",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057033",
      "j": 2024,
      "n": "kirchbarkau",
      "dn": "Kirchbarkau",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01057034",
      "j": 2024,
      "n": "kirchnüchel",
      "dn": "Kirchnüchel",
      "bl": 1,
      "hs": 295.0,
      "mns": 3
    },
    {
      "a": "01057035",
      "j": 2024,
      "n": "klamp",
      "dn": "Klamp",
      "bl": 1,
      "hs": 270.0,
      "mns": 3
    },
    {
      "a": "01057037",
      "j": 2024,
      "n": "klein barkau",
      "dn": "Klein Barkau",
      "bl": 1,
      "hs": 368.0,
      "mns": 3
    },
    {
      "a": "01057038",
      "j": 2024,
      "n": "kletkamp",
      "dn": "Kletkamp",
      "bl": 1,
      "hs": 290.0,
      "mns": 3
    },
    {
      "a": "01057039",
      "j": 2024,
      "n": "köhn",
      "dn": "Köhn",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057040",
      "j": 2024,
      "n": "krokau",
      "dn": "Krokau",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01057041",
      "j": 2024,
      "n": "krummbek",
      "dn": "Krummbek",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057042",
      "j": 2024,
      "n": "kühren",
      "dn": "Kühren",
      "bl": 1,
      "hs": 368.0,
      "mns": 3
    },
    {
      "a": "01057043",
      "j": 2024,
      "n": "laboe",
      "dn": "Laboe",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057044",
      "j": 2024,
      "n": "lammershagen",
      "dn": "Lammershagen",
      "bl": 1,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "01057045",
      "j": 2024,
      "n": "lebrade",
      "dn": "Lebrade",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057046",
      "j": 2024,
      "n": "lehmkuhlen",
      "dn": "Lehmkuhlen",
      "bl": 1,
      "hs": 368.0,
      "mns": 3
    },
    {
      "a": "01057047",
      "j": 2024,
      "n": "löptin",
      "dn": "Löptin",
      "bl": 1,
      "hs": 325.0,
      "mns": 3
    },
    {
      "a": "01057048",
      "j": 2024,
      "n": "lütjenburg",
      "dn": "Lütjenburg",
      "bl": 1,
      "hs": 270.0,
      "mns": 3
    },
    {
      "a": "01057049",
      "j": 2024,
      "n": "lutterbek",
      "dn": "Lutterbek",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057050",
      "j": 2024,
      "n": "martensrade",
      "dn": "Martensrade",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057051",
      "j": 2024,
      "n": "mönkeberg",
      "dn": "Mönkeberg",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057052",
      "j": 2024,
      "n": "mucheln",
      "dn": "Mucheln",
      "bl": 1,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "01057053",
      "j": 2024,
      "n": "nehmten",
      "dn": "Nehmten",
      "bl": 1,
      "hs": 280.0,
      "mns": 3
    },
    {
      "a": "01057054",
      "j": 2024,
      "n": "nettelsee",
      "dn": "Nettelsee",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057055",
      "j": 2024,
      "n": "panker",
      "dn": "Panker",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01057056",
      "j": 2024,
      "n": "passade",
      "dn": "Passade",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057057",
      "j": 2024,
      "n": "plön",
      "dn": "Plön",
      "bl": 1,
      "hs": 475.0,
      "mns": 3
    },
    {
      "a": "01057058",
      "j": 2024,
      "n": "pohnsdorf",
      "dn": "Pohnsdorf",
      "bl": 1,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "01057059",
      "j": 2024,
      "n": "postfeld",
      "dn": "Postfeld",
      "bl": 1,
      "hs": 368.0,
      "mns": 3
    },
    {
      "a": "01057060",
      "j": 2024,
      "n": "prasdorf",
      "dn": "Prasdorf",
      "bl": 1,
      "hs": 480.0,
      "mns": 3
    },
    {
      "a": "01057062",
      "j": 2024,
      "n": "preetz",
      "dn": "Preetz",
      "bl": 1,
      "hs": 450.0,
      "mns": 3
    },
    {
      "a": "01057063",
      "j": 2024,
      "n": "probsteierhagen",
      "dn": "Probsteierhagen",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057065",
      "j": 2024,
      "n": "rantzau",
      "dn": "Rantzau",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057066",
      "j": 2024,
      "n": "rastorf",
      "dn": "Rastorf",
      "bl": 1,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "01057067",
      "j": 2024,
      "n": "rathjensdorf",
      "dn": "Rathjensdorf",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057068",
      "j": 2024,
      "n": "rendswühren",
      "dn": "Rendswühren",
      "bl": 1,
      "hs": 260.0,
      "mns": 3
    },
    {
      "a": "01057069",
      "j": 2024,
      "n": "ruhwinkel",
      "dn": "Ruhwinkel",
      "bl": 1,
      "hs": 332.0,
      "mns": 3
    },
    {
      "a": "01057070",
      "j": 2024,
      "n": "schellhorn",
      "dn": "Schellhorn",
      "bl": 1,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "01057071",
      "j": 2024,
      "n": "schillsdorf",
      "dn": "Schillsdorf",
      "bl": 1,
      "hs": 335.0,
      "mns": 3
    },
    {
      "a": "01057072",
      "j": 2024,
      "n": "schlesen",
      "dn": "Schlesen",
      "bl": 1,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "01057073",
      "j": 2024,
      "n": "schönberg",
      "dn": "Schönberg",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057074",
      "j": 2024,
      "n": "schönkirchen",
      "dn": "Schönkirchen",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057076",
      "j": 2024,
      "n": "schwartbuck",
      "dn": "Schwartbuck",
      "bl": 1,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "01057077",
      "j": 2024,
      "n": "selent",
      "dn": "Selent",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057078",
      "j": 2024,
      "n": "stakendorf",
      "dn": "Stakendorf",
      "bl": 1,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "01057079",
      "j": 2024,
      "n": "stein",
      "dn": "Stein",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057080",
      "j": 2024,
      "n": "stolpe",
      "dn": "Stolpe",
      "bl": 1,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "01057081",
      "j": 2024,
      "n": "stoltenberg",
      "dn": "Stoltenberg",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057082",
      "j": 2024,
      "n": "tröndel",
      "dn": "Tröndel",
      "bl": 1,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "01057083",
      "j": 2024,
      "n": "tasdorf",
      "dn": "Tasdorf",
      "bl": 1,
      "hs": 260.0,
      "mns": 3
    },
    {
      "a": "01057084",
      "j": 2024,
      "n": "wahlstorf",
      "dn": "Wahlstorf",
      "bl": 1,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "01057085",
      "j": 2025,
      "n": "wankendorf",
      "dn": "Wankendorf",
      "bl": 1,
      "hs": 450.0,
      "mns": 3
    },
    {
      "a": "01057086",
      "j": 2024,
      "n": "warnau",
      "dn": "Warnau",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057087",
      "j": 2024,
      "n": "wendtorf",
      "dn": "Wendtorf",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057088",
      "j": 2024,
      "n": "wisch",
      "dn": "Wisch",
      "bl": 1,
      "hs": 240.0,
      "mns": 3
    },
    {
      "a": "01057089",
      "j": 2024,
      "n": "wittmoldt",
      "dn": "Wittmoldt",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01057090",
      "j": 2024,
      "n": "fargau-pratjau",
      "dn": "Fargau-Pratjau",
      "bl": 1,
      "hs": 339.0,
      "mns": 3
    },
    {
      "a": "01057091",
      "j": 2024,
      "n": "schwentinental",
      "dn": "Schwentinental",
      "bl": 1,
      "hs": 425.0,
      "mns": 4
    },
    {
      "a": "01058001",
      "j": 2024,
      "n": "achterwehr",
      "dn": "Achterwehr",
      "bl": 1,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "01058003",
      "j": 2024,
      "n": "alt duvenstedt",
      "dn": "Alt Duvenstedt",
      "bl": 1,
      "hs": 280.0,
      "mns": 2
    },
    {
      "a": "01058004",
      "j": 2024,
      "n": "altenhof",
      "dn": "Altenhof",
      "bl": 1,
      "hs": 270.0,
      "mns": 2
    },
    {
      "a": "01058005",
      "j": 2024,
      "n": "altenholz",
      "dn": "Altenholz",
      "bl": 1,
      "hs": 550.0,
      "mns": 2
    },
    {
      "a": "01058007",
      "j": 2024,
      "n": "arpsdorf",
      "dn": "Arpsdorf",
      "bl": 1,
      "hs": 290.0,
      "mns": 2
    },
    {
      "a": "01058008",
      "j": 2024,
      "n": "ascheffel",
      "dn": "Ascheffel",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01058009",
      "j": 2024,
      "n": "aukrug",
      "dn": "Aukrug",
      "bl": 1,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "01058010",
      "j": 2024,
      "n": "bargstall",
      "dn": "Bargstall",
      "bl": 1,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "01058011",
      "j": 2024,
      "n": "bargstedt",
      "dn": "Bargstedt",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01058012",
      "j": 2024,
      "n": "barkelsby",
      "dn": "Barkelsby",
      "bl": 1,
      "hs": 270.0,
      "mns": 2
    },
    {
      "a": "01058013",
      "j": 2024,
      "n": "beldorf",
      "dn": "Beldorf",
      "bl": 1,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "01058014",
      "j": 2024,
      "n": "bendorf",
      "dn": "Bendorf",
      "bl": 1,
      "hs": 240.0,
      "mns": 2
    },
    {
      "a": "01058015",
      "j": 2024,
      "n": "beringstedt",
      "dn": "Beringstedt",
      "bl": 1,
      "hs": 329.0,
      "mns": 2
    },
    {
      "a": "01058016",
      "j": 2024,
      "n": "bissee",
      "dn": "Bissee",
      "bl": 1,
      "hs": 339.0,
      "mns": 2
    },
    {
      "a": "01058018",
      "j": 2024,
      "n": "blumenthal",
      "dn": "Blumenthal",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01058019",
      "j": 2024,
      "n": "böhnhusen",
      "dn": "Böhnhusen",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01058021",
      "j": 2024,
      "n": "bokel",
      "dn": "Bokel",
      "bl": 1,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "01058022",
      "j": 2024,
      "n": "bordesholm",
      "dn": "Bordesholm",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01058023",
      "j": 2024,
      "n": "borgdorf-seedorf",
      "dn": "Borgdorf-Seedorf",
      "bl": 1,
      "hs": 332.0,
      "mns": 2
    },
    {
      "a": "01058024",
      "j": 2024,
      "n": "borgstedt",
      "dn": "Borgstedt",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01058025",
      "j": 2024,
      "n": "bornholt",
      "dn": "Bornholt",
      "bl": 1,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "01058026",
      "j": 2024,
      "n": "bovenau",
      "dn": "Bovenau",
      "bl": 1,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "01058027",
      "j": 2024,
      "n": "brammer",
      "dn": "Brammer",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01058028",
      "j": 2024,
      "n": "bredenbek",
      "dn": "Bredenbek",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01058029",
      "j": 2024,
      "n": "breiholz",
      "dn": "Breiholz",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01058030",
      "j": 2024,
      "n": "brekendorf",
      "dn": "Brekendorf",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01058031",
      "j": 2024,
      "n": "brinjahe",
      "dn": "Brinjahe",
      "bl": 1,
      "hs": 260.0,
      "mns": 2
    },
    {
      "a": "01058032",
      "j": 2024,
      "n": "brodersby",
      "dn": "Brodersby",
      "bl": 1,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "01058033",
      "j": 2024,
      "n": "brügge",
      "dn": "Brügge",
      "bl": 1,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "01058034",
      "j": 2024,
      "n": "büdelsdorf",
      "dn": "Büdelsdorf",
      "bl": 1,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "01058035",
      "j": 2024,
      "n": "bünsdorf",
      "dn": "Bünsdorf",
      "bl": 1,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "01058036",
      "j": 2024,
      "n": "christiansholm",
      "dn": "Christiansholm",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01058037",
      "j": 2024,
      "n": "dänischenhagen",
      "dn": "Dänischenhagen",
      "bl": 1,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "01058038",
      "j": 2024,
      "n": "dätgen",
      "dn": "Dätgen",
      "bl": 1,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "01058039",
      "j": 2024,
      "n": "damendorf",
      "dn": "Damendorf",
      "bl": 1,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "01058040",
      "j": 2024,
      "n": "damp",
      "dn": "Damp",
      "bl": 1,
      "hs": 260.0,
      "mns": 2
    },
    {
      "a": "01058042",
      "j": 2024,
      "n": "dörphof",
      "dn": "Dörphof",
      "bl": 1,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "01058043",
      "j": 2024,
      "n": "eckernförde",
      "dn": "Eckernförde",
      "bl": 1,
      "hs": 390.0,
      "mns": 4
    },
    {
      "a": "01058044",
      "j": 2024,
      "n": "ehndorf",
      "dn": "Ehndorf",
      "bl": 1,
      "hs": 260.0,
      "mns": 2
    },
    {
      "a": "01058045",
      "j": 2024,
      "n": "eisendorf",
      "dn": "Eisendorf",
      "bl": 1,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "01058046",
      "j": 2024,
      "n": "ellerdorf",
      "dn": "Ellerdorf",
      "bl": 1,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "01058047",
      "j": 2024,
      "n": "elsdorf-westermühlen",
      "dn": "Elsdorf-Westermühlen",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01058048",
      "j": 2024,
      "n": "embühren",
      "dn": "Embühren",
      "bl": 1,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "01058049",
      "j": 2024,
      "n": "emkendorf",
      "dn": "Emkendorf",
      "bl": 1,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "01058050",
      "j": 2024,
      "n": "felde",
      "dn": "Felde",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01058051",
      "j": 2024,
      "n": "felm",
      "dn": "Felm",
      "bl": 1,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "01058052",
      "j": 2024,
      "n": "fleckeby",
      "dn": "Fleckeby",
      "bl": 1,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "01058053",
      "j": 2024,
      "n": "flintbek",
      "dn": "Flintbek",
      "bl": 1,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "01058054",
      "j": 2024,
      "n": "fockbek",
      "dn": "Fockbek",
      "bl": 1,
      "hs": 290.0,
      "mns": 2
    },
    {
      "a": "01058055",
      "j": 2024,
      "n": "friedrichsgraben",
      "dn": "Friedrichsgraben",
      "bl": 1,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "01058056",
      "j": 2024,
      "n": "friedrichsholm",
      "dn": "Friedrichsholm",
      "bl": 1,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "01058057",
      "j": 2024,
      "n": "gammelby",
      "dn": "Gammelby",
      "bl": 1,
      "hs": 280.0,
      "mns": 2
    },
    {
      "a": "01058058",
      "j": 2024,
      "n": "gettorf",
      "dn": "Gettorf",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01058059",
      "j": 2024,
      "n": "gnutz",
      "dn": "Gnutz",
      "bl": 1,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "01058061",
      "j": 2024,
      "n": "gokels",
      "dn": "Gokels",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01058062",
      "j": 2024,
      "n": "grauel",
      "dn": "Grauel",
      "bl": 1,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "01058063",
      "j": 2024,
      "n": "grevenkrug",
      "dn": "Grevenkrug",
      "bl": 1,
      "hs": 332.0,
      "mns": 2
    },
    {
      "a": "01058064",
      "j": 2024,
      "n": "groß buchwald",
      "dn": "Groß Buchwald",
      "bl": 1,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "01058065",
      "j": 2024,
      "n": "groß vollstedt",
      "dn": "Groß Vollstedt",
      "bl": 1,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "01058066",
      "j": 2024,
      "n": "groß wittensee",
      "dn": "Groß Wittensee",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01058067",
      "j": 2024,
      "n": "güby",
      "dn": "Güby",
      "bl": 1,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "01058068",
      "j": 2024,
      "n": "haale",
      "dn": "Haale",
      "bl": 1,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "01058069",
      "j": 2024,
      "n": "haby",
      "dn": "Haby",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01058070",
      "j": 2024,
      "n": "hamdorf",
      "dn": "Hamdorf",
      "bl": 1,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "01058071",
      "j": 2024,
      "n": "hamweddel",
      "dn": "Hamweddel",
      "bl": 1,
      "hs": 260.0,
      "mns": 2
    },
    {
      "a": "01058072",
      "j": 2024,
      "n": "hanerau-hademarschen",
      "dn": "Hanerau-Hademarschen",
      "bl": 1,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "01058073",
      "j": 2024,
      "n": "haßmoor",
      "dn": "Haßmoor",
      "bl": 1,
      "hs": 367.0,
      "mns": 2
    },
    {
      "a": "01058074",
      "j": 2024,
      "n": "heinkenborstel",
      "dn": "Heinkenborstel",
      "bl": 1,
      "hs": 332.0,
      "mns": 2
    },
    {
      "a": "01058075",
      "j": 2024,
      "n": "hörsten",
      "dn": "Hörsten",
      "bl": 1,
      "hs": 250.0,
      "mns": 2
    },
    {
      "a": "01058076",
      "j": 2024,
      "n": "hoffeld",
      "dn": "Hoffeld",
      "bl": 1,
      "hs": 335.0,
      "mns": 2
    },
    {
      "a": "01058077",
      "j": 2024,
      "n": "hohenwestedt",
      "dn": "Hohenwestedt",
      "bl": 1,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "01058078",
      "j": 2024,
      "n": "hohn",
      "dn": "Hohn",
      "bl": 1,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "01058080",
      "j": 2024,
      "n": "holtsee",
      "dn": "Holtsee",
      "bl": 1,
      "hs": 368.0,
      "mns": 2
    },
    {
      "a": "01058081",
      "j": 2024,
      "n": "holzbunge",
      "dn": "Holzbunge",
      "bl": 1,
      "hs": 339.0,
      "mns": 2
    },
    {
      "a": "01058082",
      "j": 2024,
      "n": "holzdorf",
      "dn": "Holzdorf",
      "bl": 1,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "01058083",
      "j": 2024,
      "n": "hütten",
      "dn": "Hütten",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01058084",
      "j": 2024,
      "n": "hummelfeld",
      "dn": "Hummelfeld",
      "bl": 1,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "01058085",
      "j": 2024,
      "n": "jahrsdorf",
      "dn": "Jahrsdorf",
      "bl": 1,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "01058086",
      "j": 2024,
      "n": "jevenstedt",
      "dn": "Jevenstedt",
      "bl": 1,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "01058087",
      "j": 2024,
      "n": "karby",
      "dn": "Karby",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01058088",
      "j": 2024,
      "n": "klein wittensee",
      "dn": "Klein Wittensee",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01058089",
      "j": 2024,
      "n": "königshügel",
      "dn": "Königshügel",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01058090",
      "j": 2024,
      "n": "kosel",
      "dn": "Kosel",
      "bl": 1,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "01058091",
      "j": 2024,
      "n": "krogaspe",
      "dn": "Krogaspe",
      "bl": 1,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "01058092",
      "j": 2024,
      "n": "kronshagen",
      "dn": "Kronshagen",
      "bl": 1,
      "hs": 425.0,
      "mns": 4
    },
    {
      "a": "01058093",
      "j": 2024,
      "n": "krummwisch",
      "dn": "Krummwisch",
      "bl": 1,
      "hs": 368.0,
      "mns": 2
    },
    {
      "a": "01058094",
      "j": 2024,
      "n": "langwedel",
      "dn": "Langwedel",
      "bl": 1,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "01058096",
      "j": 2024,
      "n": "lindau",
      "dn": "Lindau",
      "bl": 1,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "01058097",
      "j": 2024,
      "n": "lohe-föhrden",
      "dn": "Lohe-Föhrden",
      "bl": 1,
      "hs": 325.0,
      "mns": 2
    },
    {
      "a": "01058098",
      "j": 2024,
      "n": "loop",
      "dn": "Loop",
      "bl": 1,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "01058099",
      "j": 2024,
      "n": "loose",
      "dn": "Loose",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01058100",
      "j": 2024,
      "n": "lütjenwestedt",
      "dn": "Lütjenwestedt",
      "bl": 1,
      "hs": 260.0,
      "mns": 2
    },
    {
      "a": "01058101",
      "j": 2024,
      "n": "luhnstedt",
      "dn": "Luhnstedt",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01058102",
      "j": 2024,
      "n": "goosefeld",
      "dn": "Goosefeld",
      "bl": 1,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "01058103",
      "j": 2024,
      "n": "meezen",
      "dn": "Meezen",
      "bl": 1,
      "hs": 325.0,
      "mns": 2
    },
    {
      "a": "01058104",
      "j": 2024,
      "n": "melsdorf",
      "dn": "Melsdorf",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01058105",
      "j": 2024,
      "n": "mielkendorf",
      "dn": "Mielkendorf",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01058106",
      "j": 2024,
      "n": "mörel",
      "dn": "Mörel",
      "bl": 1,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "01058107",
      "j": 2024,
      "n": "molfsee",
      "dn": "Molfsee",
      "bl": 1,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "01058108",
      "j": 2024,
      "n": "mühbrook",
      "dn": "Mühbrook",
      "bl": 1,
      "hs": 332.0,
      "mns": 2
    },
    {
      "a": "01058109",
      "j": 2024,
      "n": "negenharrie",
      "dn": "Negenharrie",
      "bl": 1,
      "hs": 339.0,
      "mns": 2
    },
    {
      "a": "01058110",
      "j": 2024,
      "n": "neudorf-bornstein",
      "dn": "Neudorf-Bornstein",
      "bl": 1,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "01058111",
      "j": 2024,
      "n": "neu duvenstedt",
      "dn": "Neu Duvenstedt",
      "bl": 1,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "01058112",
      "j": 2024,
      "n": "neuwittenbek",
      "dn": "Neuwittenbek",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01058113",
      "j": 2024,
      "n": "nienborstel",
      "dn": "Nienborstel",
      "bl": 1,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "01058115",
      "j": 2024,
      "n": "nindorf",
      "dn": "Nindorf",
      "bl": 1,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "01058116",
      "j": 2024,
      "n": "noer",
      "dn": "Noer",
      "bl": 1,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "01058117",
      "j": 2024,
      "n": "nortorf",
      "dn": "Nortorf",
      "bl": 1,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "01058118",
      "j": 2024,
      "n": "nübbel",
      "dn": "Nübbel",
      "bl": 1,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "01058119",
      "j": 2024,
      "n": "oldenbüttel",
      "dn": "Oldenbüttel",
      "bl": 1,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "01058120",
      "j": 2024,
      "n": "oldenhütten",
      "dn": "Oldenhütten",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01058121",
      "j": 2024,
      "n": "osdorf",
      "dn": "Osdorf",
      "bl": 1,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "01058122",
      "j": 2024,
      "n": "ostenfeld (rendsburg)",
      "dn": "Ostenfeld (Rendsburg)",
      "bl": 1,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "01058123",
      "j": 2024,
      "n": "osterby",
      "dn": "Osterby",
      "bl": 1,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "01058124",
      "j": 2024,
      "n": "osterrönfeld",
      "dn": "Osterrönfeld",
      "bl": 1,
      "hs": 335.0,
      "mns": 2
    },
    {
      "a": "01058125",
      "j": 2024,
      "n": "osterstedt",
      "dn": "Osterstedt",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01058126",
      "j": 2024,
      "n": "ottendorf",
      "dn": "Ottendorf",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01058127",
      "j": 2024,
      "n": "owschlag",
      "dn": "Owschlag",
      "bl": 1,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "01058128",
      "j": 2024,
      "n": "padenstedt",
      "dn": "Padenstedt",
      "bl": 1,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "01058129",
      "j": 2024,
      "n": "prinzenmoor",
      "dn": "Prinzenmoor",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01058130",
      "j": 2024,
      "n": "quarnbek",
      "dn": "Quarnbek",
      "bl": 1,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "01058131",
      "j": 2024,
      "n": "rade b. hohenwestedt",
      "dn": "Rade b. Hohenwestedt",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01058132",
      "j": 2024,
      "n": "rade b. rendsburg",
      "dn": "Rade b. Rendsburg",
      "bl": 1,
      "hs": 260.0,
      "mns": 2
    },
    {
      "a": "01058133",
      "j": 2024,
      "n": "reesdorf",
      "dn": "Reesdorf",
      "bl": 1,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "01058134",
      "j": 2024,
      "n": "remmels",
      "dn": "Remmels",
      "bl": 1,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "01058135",
      "j": 2024,
      "n": "rendsburg",
      "dn": "Rendsburg",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01058136",
      "j": 2024,
      "n": "rickert",
      "dn": "Rickert",
      "bl": 1,
      "hs": 250.0,
      "mns": 2
    },
    {
      "a": "01058137",
      "j": 2024,
      "n": "rieseby",
      "dn": "Rieseby",
      "bl": 1,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "01058138",
      "j": 2024,
      "n": "rodenbek",
      "dn": "Rodenbek",
      "bl": 1,
      "hs": 395.0,
      "mns": 2
    },
    {
      "a": "01058139",
      "j": 2024,
      "n": "rumohr",
      "dn": "Rumohr",
      "bl": 1,
      "hs": 373.0,
      "mns": 2
    },
    {
      "a": "01058140",
      "j": 2024,
      "n": "schacht-audorf",
      "dn": "Schacht-Audorf",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01058141",
      "j": 2024,
      "n": "schierensee",
      "dn": "Schierensee",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01058142",
      "j": 2024,
      "n": "schinkel",
      "dn": "Schinkel",
      "bl": 1,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "01058143",
      "j": 2024,
      "n": "schmalstede",
      "dn": "Schmalstede",
      "bl": 1,
      "hs": 339.0,
      "mns": 2
    },
    {
      "a": "01058144",
      "j": 2024,
      "n": "schönbek",
      "dn": "Schönbek",
      "bl": 1,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "01058145",
      "j": 2024,
      "n": "schönhorst",
      "dn": "Schönhorst",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01058146",
      "j": 2024,
      "n": "schülldorf",
      "dn": "Schülldorf",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01058147",
      "j": 2024,
      "n": "schülp b. nortorf",
      "dn": "Schülp b. Nortorf",
      "bl": 1,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "01058148",
      "j": 2024,
      "n": "schülp b. rendsburg",
      "dn": "Schülp b. Rendsburg",
      "bl": 1,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "01058150",
      "j": 2024,
      "n": "schwedeneck",
      "dn": "Schwedeneck",
      "bl": 1,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "01058151",
      "j": 2024,
      "n": "seefeld",
      "dn": "Seefeld",
      "bl": 1,
      "hs": 260.0,
      "mns": 2
    },
    {
      "a": "01058152",
      "j": 2024,
      "n": "sehestedt",
      "dn": "Sehestedt",
      "bl": 1,
      "hs": 368.0,
      "mns": 2
    },
    {
      "a": "01058153",
      "j": 2024,
      "n": "sören",
      "dn": "Sören",
      "bl": 1,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "01058154",
      "j": 2024,
      "n": "sophienhamm",
      "dn": "Sophienhamm",
      "bl": 1,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "01058155",
      "j": 2024,
      "n": "stafstedt",
      "dn": "Stafstedt",
      "bl": 1,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "01058156",
      "j": 2024,
      "n": "steenfeld",
      "dn": "Steenfeld",
      "bl": 1,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "01058157",
      "j": 2024,
      "n": "strande",
      "dn": "Strande",
      "bl": 1,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "01058158",
      "j": 2024,
      "n": "tackesdorf",
      "dn": "Tackesdorf",
      "bl": 1,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "01058159",
      "j": 2024,
      "n": "tappendorf",
      "dn": "Tappendorf",
      "bl": 1,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "01058160",
      "j": 2024,
      "n": "techelsdorf",
      "dn": "Techelsdorf",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01058161",
      "j": 2024,
      "n": "thaden",
      "dn": "Thaden",
      "bl": 1,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "01058162",
      "j": 2024,
      "n": "thumby",
      "dn": "Thumby",
      "bl": 1,
      "hs": 200.0,
      "mns": 2
    },
    {
      "a": "01058163",
      "j": 2024,
      "n": "timmaspe",
      "dn": "Timmaspe",
      "bl": 1,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "01058164",
      "j": 2024,
      "n": "todenbüttel",
      "dn": "Todenbüttel",
      "bl": 1,
      "hs": 325.0,
      "mns": 2
    },
    {
      "a": "01058165",
      "j": 2024,
      "n": "tüttendorf",
      "dn": "Tüttendorf",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01058166",
      "j": 2024,
      "n": "waabs",
      "dn": "Waabs",
      "bl": 1,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "01058167",
      "j": 2024,
      "n": "wapelfeld",
      "dn": "Wapelfeld",
      "bl": 1,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "01058168",
      "j": 2024,
      "n": "warder",
      "dn": "Warder",
      "bl": 1,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "01058169",
      "j": 2024,
      "n": "wasbek",
      "dn": "Wasbek",
      "bl": 1,
      "hs": 260.0,
      "mns": 2
    },
    {
      "a": "01058170",
      "j": 2024,
      "n": "wattenbek",
      "dn": "Wattenbek",
      "bl": 1,
      "hs": 385.0,
      "mns": 2
    },
    {
      "a": "01058171",
      "j": 2024,
      "n": "westensee",
      "dn": "Westensee",
      "bl": 1,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "01058172",
      "j": 2024,
      "n": "westerrönfeld",
      "dn": "Westerrönfeld",
      "bl": 1,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "01058173",
      "j": 2024,
      "n": "windeby",
      "dn": "Windeby",
      "bl": 1,
      "hs": 280.0,
      "mns": 2
    },
    {
      "a": "01058174",
      "j": 2024,
      "n": "winnemark",
      "dn": "Winnemark",
      "bl": 1,
      "hs": 280.0,
      "mns": 2
    },
    {
      "a": "01058175",
      "j": 2024,
      "n": "ahlefeld-bistensee",
      "dn": "Ahlefeld-Bistensee",
      "bl": 1,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "01059001",
      "j": 2024,
      "n": "alt bennebek",
      "dn": "Alt Bennebek",
      "bl": 1,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "01059002",
      "j": 2024,
      "n": "arnis",
      "dn": "Arnis",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059005",
      "j": 2024,
      "n": "bergenhusen",
      "dn": "Bergenhusen",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059006",
      "j": 2024,
      "n": "böel",
      "dn": "Böel",
      "bl": 1,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "01059008",
      "j": 2025,
      "n": "böklund",
      "dn": "Böklund",
      "bl": 1,
      "hs": 569.0,
      "mns": 1
    },
    {
      "a": "01059009",
      "j": 2024,
      "n": "börm",
      "dn": "Börm",
      "bl": 1,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "01059010",
      "j": 2024,
      "n": "bollingstedt",
      "dn": "Bollingstedt",
      "bl": 1,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "01059012",
      "j": 2024,
      "n": "borgwedel",
      "dn": "Borgwedel",
      "bl": 1,
      "hs": 368.0,
      "mns": 1
    },
    {
      "a": "01059018",
      "j": 2024,
      "n": "busdorf",
      "dn": "Busdorf",
      "bl": 1,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "01059019",
      "j": 2024,
      "n": "dannewerk",
      "dn": "Dannewerk",
      "bl": 1,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "01059020",
      "j": 2024,
      "n": "dörpstedt",
      "dn": "Dörpstedt",
      "bl": 1,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "01059023",
      "j": 2024,
      "n": "ellingstedt",
      "dn": "Ellingstedt",
      "bl": 1,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "01059024",
      "j": 2024,
      "n": "erfde",
      "dn": "Erfde",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059026",
      "j": 2024,
      "n": "fahrdorf",
      "dn": "Fahrdorf",
      "bl": 1,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "01059032",
      "j": 2024,
      "n": "geltorf",
      "dn": "Geltorf",
      "bl": 1,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "01059034",
      "j": 2024,
      "n": "grödersby",
      "dn": "Grödersby",
      "bl": 1,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "01059035",
      "j": 2025,
      "n": "groß rheide",
      "dn": "Groß Rheide",
      "bl": 1,
      "hs": 600.0,
      "mns": 1
    },
    {
      "a": "01059037",
      "j": 2024,
      "n": "havetoft",
      "dn": "Havetoft",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059039",
      "j": 2024,
      "n": "hollingstedt",
      "dn": "Hollingstedt",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059041",
      "j": 2024,
      "n": "hüsby",
      "dn": "Hüsby",
      "bl": 1,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "01059042",
      "j": 2024,
      "n": "idstedt",
      "dn": "Idstedt",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059043",
      "j": 2024,
      "n": "jagel",
      "dn": "Jagel",
      "bl": 1,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "01059044",
      "j": 2024,
      "n": "jübek",
      "dn": "Jübek",
      "bl": 1,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "01059045",
      "j": 2024,
      "n": "kappeln",
      "dn": "Kappeln",
      "bl": 1,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "01059049",
      "j": 2024,
      "n": "klappholz",
      "dn": "Klappholz",
      "bl": 1,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "01059050",
      "j": 2024,
      "n": "klein bennebek",
      "dn": "Klein Bennebek",
      "bl": 1,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "01059051",
      "j": 2024,
      "n": "klein rheide",
      "dn": "Klein Rheide",
      "bl": 1,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "01059053",
      "j": 2024,
      "n": "kropp",
      "dn": "Kropp",
      "bl": 1,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "01059055",
      "j": 2024,
      "n": "loit",
      "dn": "Loit",
      "bl": 1,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "01059056",
      "j": 2024,
      "n": "lottorf",
      "dn": "Lottorf",
      "bl": 1,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "01059057",
      "j": 2024,
      "n": "lürschau",
      "dn": "Lürschau",
      "bl": 1,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "01059058",
      "j": 2024,
      "n": "meggerdorf",
      "dn": "Meggerdorf",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059060",
      "j": 2024,
      "n": "mohrkirch",
      "dn": "Mohrkirch",
      "bl": 1,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "01059062",
      "j": 2024,
      "n": "neuberend",
      "dn": "Neuberend",
      "bl": 1,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "01059063",
      "j": 2024,
      "n": "norderbrarup",
      "dn": "Norderbrarup",
      "bl": 1,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "01059065",
      "j": 2024,
      "n": "nottfeld",
      "dn": "Nottfeld",
      "bl": 1,
      "hs": 240.0,
      "mns": 1
    },
    {
      "a": "01059067",
      "j": 2024,
      "n": "oersberg",
      "dn": "Oersberg",
      "bl": 1,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "01059068",
      "j": 2024,
      "n": "rabenkirchen-faulück",
      "dn": "Rabenkirchen-Faulück",
      "bl": 1,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "01059070",
      "j": 2024,
      "n": "rügge",
      "dn": "Rügge",
      "bl": 1,
      "hs": 295.0,
      "mns": 1
    },
    {
      "a": "01059072",
      "j": 2024,
      "n": "saustrup",
      "dn": "Saustrup",
      "bl": 1,
      "hs": 311.0,
      "mns": 1
    },
    {
      "a": "01059073",
      "j": 2024,
      "n": "schaalby",
      "dn": "Schaalby",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059074",
      "j": 2024,
      "n": "scheggerott",
      "dn": "Scheggerott",
      "bl": 1,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "01059075",
      "j": 2024,
      "n": "schleswig",
      "dn": "Schleswig",
      "bl": 1,
      "hs": 450.0,
      "mns": 3
    },
    {
      "a": "01059076",
      "j": 2024,
      "n": "schnarup-thumby",
      "dn": "Schnarup-Thumby",
      "bl": 1,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "01059077",
      "j": 2024,
      "n": "schuby",
      "dn": "Schuby",
      "bl": 1,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "01059078",
      "j": 2024,
      "n": "selk",
      "dn": "Selk",
      "bl": 1,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "01059079",
      "j": 2024,
      "n": "silberstedt",
      "dn": "Silberstedt",
      "bl": 1,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "01059080",
      "j": 2024,
      "n": "steinfeld",
      "dn": "Steinfeld",
      "bl": 1,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "01059081",
      "j": 2024,
      "n": "stolk",
      "dn": "Stolk",
      "bl": 1,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "01059082",
      "j": 2024,
      "n": "struxdorf",
      "dn": "Struxdorf",
      "bl": 1,
      "hs": 335.0,
      "mns": 1
    },
    {
      "a": "01059083",
      "j": 2024,
      "n": "süderbrarup",
      "dn": "Süderbrarup",
      "bl": 1,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "01059084",
      "j": 2024,
      "n": "süderfahrenstedt",
      "dn": "Süderfahrenstedt",
      "bl": 1,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "01059086",
      "j": 2024,
      "n": "taarstedt",
      "dn": "Taarstedt",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059087",
      "j": 2024,
      "n": "tetenhusen",
      "dn": "Tetenhusen",
      "bl": 1,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "01059088",
      "j": 2024,
      "n": "tielen",
      "dn": "Tielen",
      "bl": 1,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "01059090",
      "j": 2024,
      "n": "tolk",
      "dn": "Tolk",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059092",
      "j": 2024,
      "n": "treia",
      "dn": "Treia",
      "bl": 1,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "01059093",
      "j": 2024,
      "n": "uelsby",
      "dn": "Uelsby",
      "bl": 1,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "01059094",
      "j": 2024,
      "n": "ulsnis",
      "dn": "Ulsnis",
      "bl": 1,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "01059095",
      "j": 2024,
      "n": "wagersrott",
      "dn": "Wagersrott",
      "bl": 1,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "01059096",
      "j": 2024,
      "n": "wohlde",
      "dn": "Wohlde",
      "bl": 1,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "01059097",
      "j": 2024,
      "n": "twedt",
      "dn": "Twedt",
      "bl": 1,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "01059098",
      "j": 2024,
      "n": "nübel",
      "dn": "Nübel",
      "bl": 1,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "01059101",
      "j": 2022,
      "n": "tastrup",
      "dn": "Tastrup",
      "bl": 1,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "01059102",
      "j": 2024,
      "n": "ahneby",
      "dn": "Ahneby",
      "bl": 1,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "01059103",
      "j": 2024,
      "n": "ausacker",
      "dn": "Ausacker",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059105",
      "j": 2024,
      "n": "böxlund",
      "dn": "Böxlund",
      "bl": 1,
      "hs": 220.0,
      "mns": 1
    },
    {
      "a": "01059106",
      "j": 2024,
      "n": "dollerup",
      "dn": "Dollerup",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059107",
      "j": 2024,
      "n": "eggebek",
      "dn": "Eggebek",
      "bl": 1,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "01059109",
      "j": 2024,
      "n": "esgrus",
      "dn": "Esgrus",
      "bl": 1,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "01059112",
      "j": 2024,
      "n": "gelting",
      "dn": "Gelting",
      "bl": 1,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "01059113",
      "j": 2024,
      "n": "glücksburg",
      "dn": "Glücksburg",
      "bl": 1,
      "hs": 700.0,
      "mns": 1
    },
    {
      "a": "01059115",
      "j": 2024,
      "n": "großenwiehe",
      "dn": "Großenwiehe",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059116",
      "j": 2024,
      "n": "großsolt",
      "dn": "Großsolt",
      "bl": 1,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "01059118",
      "j": 2024,
      "n": "grundhof",
      "dn": "Grundhof",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059120",
      "j": 2024,
      "n": "harrislee",
      "dn": "Harrislee",
      "bl": 1,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "01059121",
      "j": 2024,
      "n": "hasselberg",
      "dn": "Hasselberg",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059123",
      "j": 2024,
      "n": "hörup",
      "dn": "Hörup",
      "bl": 1,
      "hs": 0.0,
      "mns": 1
    },
    {
      "a": "01059124",
      "j": 2024,
      "n": "holt",
      "dn": "Holt",
      "bl": 1,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "01059126",
      "j": 2024,
      "n": "hürup",
      "dn": "Hürup",
      "bl": 1,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "01059127",
      "j": 2024,
      "n": "husby",
      "dn": "Husby",
      "bl": 1,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "01059128",
      "j": 2024,
      "n": "janneby",
      "dn": "Janneby",
      "bl": 1,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "01059129",
      "j": 2024,
      "n": "jardelund",
      "dn": "Jardelund",
      "bl": 1,
      "hs": 0.0,
      "mns": 1
    },
    {
      "a": "01059131",
      "j": 2024,
      "n": "jerrishoe",
      "dn": "Jerrishoe",
      "bl": 1,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "01059132",
      "j": 2024,
      "n": "jörl",
      "dn": "Jörl",
      "bl": 1,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "01059136",
      "j": 2024,
      "n": "kronsgaard",
      "dn": "Kronsgaard",
      "bl": 1,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "01059137",
      "j": 2024,
      "n": "langballig",
      "dn": "Langballig",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059138",
      "j": 2024,
      "n": "langstedt",
      "dn": "Langstedt",
      "bl": 1,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "01059141",
      "j": 2022,
      "n": "maasbüll",
      "dn": "Maasbüll",
      "bl": 1,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "01059142",
      "j": 2024,
      "n": "maasholm",
      "dn": "Maasholm",
      "bl": 1,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "01059143",
      "j": 2024,
      "n": "medelby",
      "dn": "Medelby",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059144",
      "j": 2024,
      "n": "meyn",
      "dn": "Meyn",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059145",
      "j": 2024,
      "n": "munkbrarup",
      "dn": "Munkbrarup",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059147",
      "j": 2024,
      "n": "nieby",
      "dn": "Nieby",
      "bl": 1,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "01059148",
      "j": 2024,
      "n": "niesgrau",
      "dn": "Niesgrau",
      "bl": 1,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "01059149",
      "j": 2024,
      "n": "nordhackstedt",
      "dn": "Nordhackstedt",
      "bl": 1,
      "hs": 150.0,
      "mns": 1
    },
    {
      "a": "01059151",
      "j": 2024,
      "n": "osterby",
      "dn": "Osterby",
      "bl": 1,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "01059152",
      "j": 2024,
      "n": "pommerby",
      "dn": "Pommerby",
      "bl": 1,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "01059154",
      "j": 2024,
      "n": "rabel",
      "dn": "Rabel",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059155",
      "j": 2024,
      "n": "rabenholz",
      "dn": "Rabenholz",
      "bl": 1,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "01059157",
      "j": 2024,
      "n": "ringsberg",
      "dn": "Ringsberg",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059158",
      "j": 2024,
      "n": "schafflund",
      "dn": "Schafflund",
      "bl": 1,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "01059159",
      "j": 2025,
      "n": "sieverstedt",
      "dn": "Sieverstedt",
      "bl": 1,
      "hs": 580.0,
      "mns": 1
    },
    {
      "a": "01059161",
      "j": 2024,
      "n": "sörup",
      "dn": "Sörup",
      "bl": 1,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "01059162",
      "j": 2024,
      "n": "sollerup",
      "dn": "Sollerup",
      "bl": 1,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "01059163",
      "j": 2024,
      "n": "stangheck",
      "dn": "Stangheck",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059164",
      "j": 2024,
      "n": "steinberg",
      "dn": "Steinberg",
      "bl": 1,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "01059167",
      "j": 2024,
      "n": "sterup",
      "dn": "Sterup",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059168",
      "j": 2024,
      "n": "stoltebüll",
      "dn": "Stoltebüll",
      "bl": 1,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "01059169",
      "j": 2024,
      "n": "süderhackstedt",
      "dn": "Süderhackstedt",
      "bl": 1,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "01059171",
      "j": 2025,
      "n": "tarp",
      "dn": "Tarp",
      "bl": 1,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "01059173",
      "j": 2024,
      "n": "wallsbüll",
      "dn": "Wallsbüll",
      "bl": 1,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "01059174",
      "j": 2024,
      "n": "wanderup",
      "dn": "Wanderup",
      "bl": 1,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "01059176",
      "j": 2024,
      "n": "wees",
      "dn": "Wees",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059177",
      "j": 2023,
      "n": "weesby",
      "dn": "Weesby",
      "bl": 1,
      "hs": 0.0,
      "mns": 1
    },
    {
      "a": "01059178",
      "j": 2024,
      "n": "westerholz",
      "dn": "Westerholz",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059179",
      "j": 2024,
      "n": "lindewitt",
      "dn": "Lindewitt",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059182",
      "j": 2024,
      "n": "freienwill",
      "dn": "Freienwill",
      "bl": 1,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "01059183",
      "j": 2024,
      "n": "handewitt",
      "dn": "Handewitt",
      "bl": 1,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "01059184",
      "j": 2024,
      "n": "oeversee",
      "dn": "Oeversee",
      "bl": 1,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "01059185",
      "j": 2024,
      "n": "mittelangeln",
      "dn": "Mittelangeln",
      "bl": 1,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "01059186",
      "j": 2024,
      "n": "steinbergkirche",
      "dn": "Steinbergkirche",
      "bl": 1,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "01059187",
      "j": 2024,
      "n": "boren",
      "dn": "Boren",
      "bl": 1,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "01059188",
      "j": 2024,
      "n": "stapel",
      "dn": "Stapel",
      "bl": 1,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "01059189",
      "j": 2024,
      "n": "brodersby-goltoft",
      "dn": "Brodersby-Goltoft",
      "bl": 1,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "01060002",
      "j": 2024,
      "n": "alveslohe",
      "dn": "Alveslohe",
      "bl": 1,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "01060003",
      "j": 2024,
      "n": "armstedt",
      "dn": "Armstedt",
      "bl": 1,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "01060004",
      "j": 2024,
      "n": "bad bramstedt",
      "dn": "Bad Bramstedt",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01060005",
      "j": 2024,
      "n": "bad segeberg",
      "dn": "Bad Segeberg",
      "bl": 1,
      "hs": 425.0,
      "mns": 4
    },
    {
      "a": "01060006",
      "j": 2024,
      "n": "bahrenhof",
      "dn": "Bahrenhof",
      "bl": 1,
      "hs": 260.0,
      "mns": 3
    },
    {
      "a": "01060007",
      "j": 2024,
      "n": "bark",
      "dn": "Bark",
      "bl": 1,
      "hs": 260.0,
      "mns": 3
    },
    {
      "a": "01060008",
      "j": 2024,
      "n": "bebensee",
      "dn": "Bebensee",
      "bl": 1,
      "hs": 275.0,
      "mns": 3
    },
    {
      "a": "01060009",
      "j": 2024,
      "n": "bimöhlen",
      "dn": "Bimöhlen",
      "bl": 1,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "01060010",
      "j": 2024,
      "n": "blunk",
      "dn": "Blunk",
      "bl": 1,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "01060011",
      "j": 2024,
      "n": "boostedt",
      "dn": "Boostedt",
      "bl": 1,
      "hs": 335.0,
      "mns": 3
    },
    {
      "a": "01060012",
      "j": 2024,
      "n": "bornhöved",
      "dn": "Bornhöved",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01060013",
      "j": 2024,
      "n": "borstel",
      "dn": "Borstel",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01060015",
      "j": 2024,
      "n": "bühnsdorf",
      "dn": "Bühnsdorf",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01060016",
      "j": 2024,
      "n": "daldorf",
      "dn": "Daldorf",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01060017",
      "j": 2024,
      "n": "damsdorf",
      "dn": "Damsdorf",
      "bl": 1,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "01060018",
      "j": 2024,
      "n": "dreggers",
      "dn": "Dreggers",
      "bl": 1,
      "hs": 280.0,
      "mns": 3
    },
    {
      "a": "01060019",
      "j": 2024,
      "n": "ellerau",
      "dn": "Ellerau",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01060020",
      "j": 2024,
      "n": "fahrenkrug",
      "dn": "Fahrenkrug",
      "bl": 1,
      "hs": 280.0,
      "mns": 3
    },
    {
      "a": "01060021",
      "j": 2024,
      "n": "föhrden-barl",
      "dn": "Föhrden-Barl",
      "bl": 1,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "01060022",
      "j": 2024,
      "n": "fredesdorf",
      "dn": "Fredesdorf",
      "bl": 1,
      "hs": 290.0,
      "mns": 3
    },
    {
      "a": "01060023",
      "j": 2024,
      "n": "fuhlendorf",
      "dn": "Fuhlendorf",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01060024",
      "j": 2024,
      "n": "geschendorf",
      "dn": "Geschendorf",
      "bl": 1,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "01060025",
      "j": 2024,
      "n": "glasau",
      "dn": "Glasau",
      "bl": 1,
      "hs": 280.0,
      "mns": 3
    },
    {
      "a": "01060026",
      "j": 2024,
      "n": "gönnebek",
      "dn": "Gönnebek",
      "bl": 1,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "01060027",
      "j": 2024,
      "n": "großenaspe",
      "dn": "Großenaspe",
      "bl": 1,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "01060028",
      "j": 2024,
      "n": "groß kummerfeld",
      "dn": "Groß Kummerfeld",
      "bl": 1,
      "hs": 339.0,
      "mns": 3
    },
    {
      "a": "01060029",
      "j": 2024,
      "n": "groß niendorf",
      "dn": "Groß Niendorf",
      "bl": 1,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "01060030",
      "j": 2024,
      "n": "groß rönnau",
      "dn": "Groß Rönnau",
      "bl": 1,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "01060031",
      "j": 2024,
      "n": "hagen",
      "dn": "Hagen",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01060033",
      "j": 2024,
      "n": "hardebek",
      "dn": "Hardebek",
      "bl": 1,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "01060034",
      "j": 2024,
      "n": "hartenholm",
      "dn": "Hartenholm",
      "bl": 1,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "01060035",
      "j": 2024,
      "n": "hasenkrug",
      "dn": "Hasenkrug",
      "bl": 1,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "01060036",
      "j": 2024,
      "n": "hasenmoor",
      "dn": "Hasenmoor",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01060037",
      "j": 2024,
      "n": "heidmoor",
      "dn": "Heidmoor",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01060038",
      "j": 2024,
      "n": "heidmühlen",
      "dn": "Heidmühlen",
      "bl": 1,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "01060039",
      "j": 2024,
      "n": "henstedt-ulzburg",
      "dn": "Henstedt-Ulzburg",
      "bl": 1,
      "hs": 311.0,
      "mns": 5
    },
    {
      "a": "01060040",
      "j": 2024,
      "n": "hitzhusen",
      "dn": "Hitzhusen",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01060041",
      "j": 2024,
      "n": "högersdorf",
      "dn": "Högersdorf",
      "bl": 1,
      "hs": 325.0,
      "mns": 3
    },
    {
      "a": "01060042",
      "j": 2024,
      "n": "hüttblek",
      "dn": "Hüttblek",
      "bl": 1,
      "hs": 280.0,
      "mns": 3
    },
    {
      "a": "01060043",
      "j": 2024,
      "n": "itzstedt",
      "dn": "Itzstedt",
      "bl": 1,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "01060044",
      "j": 2024,
      "n": "kaltenkirchen",
      "dn": "Kaltenkirchen",
      "bl": 1,
      "hs": 315.0,
      "mns": 4
    },
    {
      "a": "01060045",
      "j": 2024,
      "n": "kattendorf",
      "dn": "Kattendorf",
      "bl": 1,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "01060046",
      "j": 2024,
      "n": "kayhude",
      "dn": "Kayhude",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01060047",
      "j": 2024,
      "n": "kisdorf",
      "dn": "Kisdorf",
      "bl": 1,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "01060048",
      "j": 2024,
      "n": "klein gladebrügge",
      "dn": "Klein Gladebrügge",
      "bl": 1,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "01060049",
      "j": 2024,
      "n": "klein rönnau",
      "dn": "Klein Rönnau",
      "bl": 1,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "01060050",
      "j": 2024,
      "n": "krems ii",
      "dn": "Krems II",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01060051",
      "j": 2024,
      "n": "kükels",
      "dn": "Kükels",
      "bl": 1,
      "hs": 280.0,
      "mns": 3
    },
    {
      "a": "01060052",
      "j": 2024,
      "n": "latendorf",
      "dn": "Latendorf",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01060053",
      "j": 2024,
      "n": "leezen",
      "dn": "Leezen",
      "bl": 1,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "01060054",
      "j": 2024,
      "n": "lentföhrden",
      "dn": "Lentföhrden",
      "bl": 1,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "01060056",
      "j": 2024,
      "n": "mönkloh",
      "dn": "Mönkloh",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01060057",
      "j": 2024,
      "n": "mözen",
      "dn": "Mözen",
      "bl": 1,
      "hs": 280.0,
      "mns": 3
    },
    {
      "a": "01060058",
      "j": 2024,
      "n": "nahe",
      "dn": "Nahe",
      "bl": 1,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "01060059",
      "j": 2024,
      "n": "negernbötel",
      "dn": "Negernbötel",
      "bl": 1,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "01060060",
      "j": 2024,
      "n": "nehms",
      "dn": "Nehms",
      "bl": 1,
      "hs": 280.0,
      "mns": 3
    },
    {
      "a": "01060061",
      "j": 2024,
      "n": "neuengörs",
      "dn": "Neuengörs",
      "bl": 1,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "01060062",
      "j": 2024,
      "n": "neversdorf",
      "dn": "Neversdorf",
      "bl": 1,
      "hs": 250.0,
      "mns": 3
    },
    {
      "a": "01060063",
      "j": 2024,
      "n": "norderstedt",
      "dn": "Norderstedt",
      "bl": 1,
      "hs": 410.0,
      "mns": 6
    },
    {
      "a": "01060064",
      "j": 2024,
      "n": "nützen",
      "dn": "Nützen",
      "bl": 1,
      "hs": 200.0,
      "mns": 3
    },
    {
      "a": "01060065",
      "j": 2024,
      "n": "oering",
      "dn": "Oering",
      "bl": 1,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "01060066",
      "j": 2024,
      "n": "oersdorf",
      "dn": "Oersdorf",
      "bl": 1,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "01060067",
      "j": 2024,
      "n": "pronstorf",
      "dn": "Pronstorf",
      "bl": 1,
      "hs": 290.0,
      "mns": 3
    },
    {
      "a": "01060068",
      "j": 2024,
      "n": "rickling",
      "dn": "Rickling",
      "bl": 1,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "01060069",
      "j": 2024,
      "n": "rohlstorf",
      "dn": "Rohlstorf",
      "bl": 1,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "01060070",
      "j": 2024,
      "n": "schackendorf",
      "dn": "Schackendorf",
      "bl": 1,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "01060071",
      "j": 2024,
      "n": "schieren",
      "dn": "Schieren",
      "bl": 1,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "01060072",
      "j": 2024,
      "n": "schmalensee",
      "dn": "Schmalensee",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01060073",
      "j": 2024,
      "n": "schmalfeld",
      "dn": "Schmalfeld",
      "bl": 1,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "01060074",
      "j": 2024,
      "n": "schwissel",
      "dn": "Schwissel",
      "bl": 1,
      "hs": 270.0,
      "mns": 3
    },
    {
      "a": "01060075",
      "j": 2024,
      "n": "seedorf",
      "dn": "Seedorf",
      "bl": 1,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "01060076",
      "j": 2025,
      "n": "seth",
      "dn": "Seth",
      "bl": 1,
      "hs": 415.0,
      "mns": 3
    },
    {
      "a": "01060077",
      "j": 2024,
      "n": "sievershütten",
      "dn": "Sievershütten",
      "bl": 1,
      "hs": 335.0,
      "mns": 3
    },
    {
      "a": "01060079",
      "j": 2024,
      "n": "stipsdorf",
      "dn": "Stipsdorf",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01060080",
      "j": 2024,
      "n": "stocksee",
      "dn": "Stocksee",
      "bl": 1,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "01060081",
      "j": 2024,
      "n": "strukdorf",
      "dn": "Strukdorf",
      "bl": 1,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "01060082",
      "j": 2024,
      "n": "struvenhütten",
      "dn": "Struvenhütten",
      "bl": 1,
      "hs": 339.0,
      "mns": 3
    },
    {
      "a": "01060084",
      "j": 2024,
      "n": "stuvenborn",
      "dn": "Stuvenborn",
      "bl": 1,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "01060085",
      "j": 2024,
      "n": "sülfeld",
      "dn": "Sülfeld",
      "bl": 1,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "01060086",
      "j": 2024,
      "n": "tarbek",
      "dn": "Tarbek",
      "bl": 1,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "01060087",
      "j": 2024,
      "n": "tensfeld",
      "dn": "Tensfeld",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01060088",
      "j": 2024,
      "n": "todesfelde",
      "dn": "Todesfelde",
      "bl": 1,
      "hs": 270.0,
      "mns": 3
    },
    {
      "a": "01060089",
      "j": 2024,
      "n": "trappenkamp",
      "dn": "Trappenkamp",
      "bl": 1,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "01060090",
      "j": 2024,
      "n": "travenhorst",
      "dn": "Travenhorst",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01060091",
      "j": 2024,
      "n": "traventhal",
      "dn": "Traventhal",
      "bl": 1,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "01060092",
      "j": 2024,
      "n": "wahlstedt",
      "dn": "Wahlstedt",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01060093",
      "j": 2024,
      "n": "wakendorf i",
      "dn": "Wakendorf I",
      "bl": 1,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "01060094",
      "j": 2024,
      "n": "wakendorf ii",
      "dn": "Wakendorf II",
      "bl": 1,
      "hs": 315.0,
      "mns": 3
    },
    {
      "a": "01060095",
      "j": 2024,
      "n": "weddelbrook",
      "dn": "Weddelbrook",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01060096",
      "j": 2024,
      "n": "weede",
      "dn": "Weede",
      "bl": 1,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "01060097",
      "j": 2024,
      "n": "wensin",
      "dn": "Wensin",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01060098",
      "j": 2024,
      "n": "westerrade",
      "dn": "Westerrade",
      "bl": 1,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "01060099",
      "j": 2024,
      "n": "wiemersdorf",
      "dn": "Wiemersdorf",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01060100",
      "j": 2024,
      "n": "winsen",
      "dn": "Winsen",
      "bl": 1,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "01060101",
      "j": 2024,
      "n": "wittenborn",
      "dn": "Wittenborn",
      "bl": 1,
      "hs": 375.0,
      "mns": 3
    },
    {
      "a": "01061001",
      "j": 2024,
      "n": "aasbüttel",
      "dn": "Aasbüttel",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01061002",
      "j": 2024,
      "n": "aebtissinwisch",
      "dn": "Aebtissinwisch",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01061003",
      "j": 2024,
      "n": "agethorst",
      "dn": "Agethorst",
      "bl": 1,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "01061004",
      "j": 2024,
      "n": "altenmoor",
      "dn": "Altenmoor",
      "bl": 1,
      "hs": 367.0,
      "mns": 2
    },
    {
      "a": "01061005",
      "j": 2024,
      "n": "auufer",
      "dn": "Auufer",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01061006",
      "j": 2024,
      "n": "bahrenfleth",
      "dn": "Bahrenfleth",
      "bl": 1,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "01061007",
      "j": 2024,
      "n": "beidenfleth",
      "dn": "Beidenfleth",
      "bl": 1,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "01061008",
      "j": 2024,
      "n": "bekdorf",
      "dn": "Bekdorf",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01061010",
      "j": 2024,
      "n": "bekmünde",
      "dn": "Bekmünde",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01061011",
      "j": 2024,
      "n": "besdorf",
      "dn": "Besdorf",
      "bl": 1,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "01061012",
      "j": 2024,
      "n": "blomesche wildnis",
      "dn": "Blomesche Wildnis",
      "bl": 1,
      "hs": 270.0,
      "mns": 2
    },
    {
      "a": "01061013",
      "j": 2024,
      "n": "bokelrehm",
      "dn": "Bokelrehm",
      "bl": 1,
      "hs": 150.0,
      "mns": 2
    },
    {
      "a": "01061014",
      "j": 2024,
      "n": "bokhorst",
      "dn": "Bokhorst",
      "bl": 1,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "01061015",
      "j": 2024,
      "n": "borsfleth",
      "dn": "Borsfleth",
      "bl": 1,
      "hs": 331.0,
      "mns": 2
    },
    {
      "a": "01061016",
      "j": 2024,
      "n": "breitenberg",
      "dn": "Breitenberg",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01061017",
      "j": 2024,
      "n": "breitenburg",
      "dn": "Breitenburg",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01061018",
      "j": 2024,
      "n": "brokdorf",
      "dn": "Brokdorf",
      "bl": 1,
      "hs": 270.0,
      "mns": 2
    },
    {
      "a": "01061019",
      "j": 2024,
      "n": "brokstedt",
      "dn": "Brokstedt",
      "bl": 1,
      "hs": 280.0,
      "mns": 2
    },
    {
      "a": "01061020",
      "j": 2024,
      "n": "büttel",
      "dn": "Büttel",
      "bl": 1,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "01061021",
      "j": 2024,
      "n": "christinenthal",
      "dn": "Christinenthal",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01061022",
      "j": 2024,
      "n": "dägeling",
      "dn": "Dägeling",
      "bl": 1,
      "hs": 280.0,
      "mns": 2
    },
    {
      "a": "01061023",
      "j": 2024,
      "n": "dammfleth",
      "dn": "Dammfleth",
      "bl": 1,
      "hs": 100.0,
      "mns": 2
    },
    {
      "a": "01061024",
      "j": 2024,
      "n": "drage",
      "dn": "Drage",
      "bl": 1,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "01061025",
      "j": 2024,
      "n": "ecklak",
      "dn": "Ecklak",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01061026",
      "j": 2024,
      "n": "elskop",
      "dn": "Elskop",
      "bl": 1,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "01061027",
      "j": 2024,
      "n": "engelbrechtsche wildnis",
      "dn": "Engelbrechtsche Wildnis",
      "bl": 1,
      "hs": 260.0,
      "mns": 2
    },
    {
      "a": "01061028",
      "j": 2024,
      "n": "fitzbek",
      "dn": "Fitzbek",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01061029",
      "j": 2024,
      "n": "glückstadt",
      "dn": "Glückstadt",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01061030",
      "j": 2024,
      "n": "grevenkop",
      "dn": "Grevenkop",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01061031",
      "j": 2024,
      "n": "gribbohm",
      "dn": "Gribbohm",
      "bl": 1,
      "hs": 290.0,
      "mns": 2
    },
    {
      "a": "01061033",
      "j": 2024,
      "n": "hadenfeld",
      "dn": "Hadenfeld",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01061034",
      "j": 2024,
      "n": "heiligenstedten",
      "dn": "Heiligenstedten",
      "bl": 1,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "01061035",
      "j": 2024,
      "n": "heiligenstedtenerkamp",
      "dn": "Heiligenstedtenerkamp",
      "bl": 1,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "01061036",
      "j": 2024,
      "n": "hennstedt",
      "dn": "Hennstedt",
      "bl": 1,
      "hs": 280.0,
      "mns": 2
    },
    {
      "a": "01061037",
      "j": 2024,
      "n": "herzhorn",
      "dn": "Herzhorn",
      "bl": 1,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "01061038",
      "j": 2024,
      "n": "hingstheide",
      "dn": "Hingstheide",
      "bl": 1,
      "hs": 280.0,
      "mns": 2
    },
    {
      "a": "01061039",
      "j": 2024,
      "n": "hodorf",
      "dn": "Hodorf",
      "bl": 1,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "01061040",
      "j": 2024,
      "n": "hohenaspe",
      "dn": "Hohenaspe",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01061041",
      "j": 2024,
      "n": "hohenfelde",
      "dn": "Hohenfelde",
      "bl": 1,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "01061042",
      "j": 2024,
      "n": "hohenlockstedt",
      "dn": "Hohenlockstedt",
      "bl": 1,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "01061043",
      "j": 2024,
      "n": "holstenniendorf",
      "dn": "Holstenniendorf",
      "bl": 1,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "01061044",
      "j": 2024,
      "n": "horst",
      "dn": "Horst",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01061045",
      "j": 2024,
      "n": "huje",
      "dn": "Huje",
      "bl": 1,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "01061046",
      "j": 2024,
      "n": "itzehoe",
      "dn": "Itzehoe",
      "bl": 1,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "01061047",
      "j": 2024,
      "n": "kaaks",
      "dn": "Kaaks",
      "bl": 1,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "01061048",
      "j": 2024,
      "n": "kaisborstel",
      "dn": "Kaisborstel",
      "bl": 1,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "01061049",
      "j": 2024,
      "n": "kellinghusen",
      "dn": "Kellinghusen",
      "bl": 1,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "01061050",
      "j": 2024,
      "n": "kiebitzreihe",
      "dn": "Kiebitzreihe",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01061052",
      "j": 2024,
      "n": "kleve",
      "dn": "Kleve",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01061053",
      "j": 2024,
      "n": "kollmoor",
      "dn": "Kollmoor",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01061054",
      "j": 2024,
      "n": "krempdorf",
      "dn": "Krempdorf",
      "bl": 1,
      "hs": 230.0,
      "mns": 2
    },
    {
      "a": "01061055",
      "j": 2024,
      "n": "krempe",
      "dn": "Krempe",
      "bl": 1,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "01061056",
      "j": 2024,
      "n": "kremperheide",
      "dn": "Kremperheide",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01061057",
      "j": 2024,
      "n": "krempermoor",
      "dn": "Krempermoor",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01061058",
      "j": 2024,
      "n": "kronsmoor",
      "dn": "Kronsmoor",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01061059",
      "j": 2024,
      "n": "krummendiek",
      "dn": "Krummendiek",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01061060",
      "j": 2024,
      "n": "kudensee",
      "dn": "Kudensee",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01061061",
      "j": 2024,
      "n": "lägerdorf",
      "dn": "Lägerdorf",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01061062",
      "j": 2024,
      "n": "landrecht",
      "dn": "Landrecht",
      "bl": 1,
      "hs": 210.0,
      "mns": 2
    },
    {
      "a": "01061063",
      "j": 2024,
      "n": "landscheide",
      "dn": "Landscheide",
      "bl": 1,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "01061064",
      "j": 2024,
      "n": "lockstedt",
      "dn": "Lockstedt",
      "bl": 1,
      "hs": 250.0,
      "mns": 2
    },
    {
      "a": "01061065",
      "j": 2024,
      "n": "lohbarbek",
      "dn": "Lohbarbek",
      "bl": 1,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "01061066",
      "j": 2024,
      "n": "looft",
      "dn": "Looft",
      "bl": 1,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "01061067",
      "j": 2024,
      "n": "mehlbek",
      "dn": "Mehlbek",
      "bl": 1,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "01061068",
      "j": 2024,
      "n": "moordiek",
      "dn": "Moordiek",
      "bl": 1,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "01061070",
      "j": 2024,
      "n": "moorhusen",
      "dn": "Moorhusen",
      "bl": 1,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "01061071",
      "j": 2024,
      "n": "mühlenbarbek",
      "dn": "Mühlenbarbek",
      "bl": 1,
      "hs": 285.0,
      "mns": 2
    },
    {
      "a": "01061072",
      "j": 2024,
      "n": "münsterdorf",
      "dn": "Münsterdorf",
      "bl": 1,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "01061073",
      "j": 2024,
      "n": "neuenbrook",
      "dn": "Neuenbrook",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01061074",
      "j": 2024,
      "n": "neuendorf b. elmshorn",
      "dn": "Neuendorf b. Elmshorn",
      "bl": 1,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "01061076",
      "j": 2024,
      "n": "nienbüttel",
      "dn": "Nienbüttel",
      "bl": 1,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "01061077",
      "j": 2024,
      "n": "nortorf",
      "dn": "Nortorf",
      "bl": 1,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "01061078",
      "j": 2024,
      "n": "nutteln",
      "dn": "Nutteln",
      "bl": 1,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "01061079",
      "j": 2024,
      "n": "oelixdorf",
      "dn": "Oelixdorf",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01061080",
      "j": 2024,
      "n": "oeschebüttel",
      "dn": "Oeschebüttel",
      "bl": 1,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "01061081",
      "j": 2024,
      "n": "oldenborstel",
      "dn": "Oldenborstel",
      "bl": 1,
      "hs": 331.0,
      "mns": 2
    },
    {
      "a": "01061082",
      "j": 2024,
      "n": "oldendorf",
      "dn": "Oldendorf",
      "bl": 1,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "01061083",
      "j": 2024,
      "n": "ottenbüttel",
      "dn": "Ottenbüttel",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01061084",
      "j": 2024,
      "n": "peissen",
      "dn": "Peissen",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01061085",
      "j": 2024,
      "n": "pöschendorf",
      "dn": "Pöschendorf",
      "bl": 1,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "01061086",
      "j": 2024,
      "n": "poyenberg",
      "dn": "Poyenberg",
      "bl": 1,
      "hs": 270.0,
      "mns": 2
    },
    {
      "a": "01061087",
      "j": 2024,
      "n": "puls",
      "dn": "Puls",
      "bl": 1,
      "hs": 325.0,
      "mns": 2
    },
    {
      "a": "01061088",
      "j": 2024,
      "n": "quarnstedt",
      "dn": "Quarnstedt",
      "bl": 1,
      "hs": 270.0,
      "mns": 2
    },
    {
      "a": "01061089",
      "j": 2024,
      "n": "rade",
      "dn": "Rade",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01061091",
      "j": 2024,
      "n": "reher",
      "dn": "Reher",
      "bl": 1,
      "hs": 335.0,
      "mns": 2
    },
    {
      "a": "01061092",
      "j": 2024,
      "n": "rethwisch",
      "dn": "Rethwisch",
      "bl": 1,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "01061093",
      "j": 2024,
      "n": "rosdorf",
      "dn": "Rosdorf",
      "bl": 1,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "01061095",
      "j": 2024,
      "n": "sankt margarethen",
      "dn": "Sankt Margarethen",
      "bl": 1,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "01061096",
      "j": 2024,
      "n": "sarlhusen",
      "dn": "Sarlhusen",
      "bl": 1,
      "hs": 280.0,
      "mns": 2
    },
    {
      "a": "01061097",
      "j": 2024,
      "n": "schenefeld",
      "dn": "Schenefeld",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01061098",
      "j": 2024,
      "n": "schlotfeld",
      "dn": "Schlotfeld",
      "bl": 1,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "01061100",
      "j": 2024,
      "n": "silzen",
      "dn": "Silzen",
      "bl": 1,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "01061101",
      "j": 2024,
      "n": "sommerland",
      "dn": "Sommerland",
      "bl": 1,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "01061102",
      "j": 2024,
      "n": "stördorf",
      "dn": "Stördorf",
      "bl": 1,
      "hs": 290.0,
      "mns": 2
    },
    {
      "a": "01061103",
      "j": 2024,
      "n": "störkathen",
      "dn": "Störkathen",
      "bl": 1,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "01061104",
      "j": 2024,
      "n": "süderau",
      "dn": "Süderau",
      "bl": 1,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "01061105",
      "j": 2024,
      "n": "vaale",
      "dn": "Vaale",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01061106",
      "j": 2024,
      "n": "vaalermoor",
      "dn": "Vaalermoor",
      "bl": 1,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "01061107",
      "j": 2024,
      "n": "wacken",
      "dn": "Wacken",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01061108",
      "j": 2024,
      "n": "warringholz",
      "dn": "Warringholz",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01061109",
      "j": 2024,
      "n": "westermoor",
      "dn": "Westermoor",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01061110",
      "j": 2024,
      "n": "wewelsfleth",
      "dn": "Wewelsfleth",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01061111",
      "j": 2024,
      "n": "wiedenborstel",
      "dn": "Wiedenborstel",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01061112",
      "j": 2024,
      "n": "willenscharen",
      "dn": "Willenscharen",
      "bl": 1,
      "hs": 270.0,
      "mns": 2
    },
    {
      "a": "01061113",
      "j": 2024,
      "n": "wilster",
      "dn": "Wilster",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01061114",
      "j": 2024,
      "n": "winseldorf",
      "dn": "Winseldorf",
      "bl": 1,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "01061115",
      "j": 2024,
      "n": "wittenbergen",
      "dn": "Wittenbergen",
      "bl": 1,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "01061116",
      "j": 2024,
      "n": "wrist",
      "dn": "Wrist",
      "bl": 1,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "01061117",
      "j": 2024,
      "n": "wulfsmoor",
      "dn": "Wulfsmoor",
      "bl": 1,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "01061118",
      "j": 2024,
      "n": "kollmar",
      "dn": "Kollmar",
      "bl": 1,
      "hs": 280.0,
      "mns": 2
    },
    {
      "a": "01061119",
      "j": 2024,
      "n": "neuendorf-sachsenbande",
      "dn": "Neuendorf-Sachsenbande",
      "bl": 1,
      "hs": 290.0,
      "mns": 2
    },
    {
      "a": "01062001",
      "j": 2024,
      "n": "ahrensburg",
      "dn": "Ahrensburg",
      "bl": 1,
      "hs": 350.0,
      "mns": 7
    },
    {
      "a": "01062003",
      "j": 2024,
      "n": "badendorf",
      "dn": "Badendorf",
      "bl": 1,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "01062004",
      "j": 2024,
      "n": "bad oldesloe",
      "dn": "Bad Oldesloe",
      "bl": 1,
      "hs": 425.0,
      "mns": 4
    },
    {
      "a": "01062005",
      "j": 2024,
      "n": "bargfeld-stegen",
      "dn": "Bargfeld-Stegen",
      "bl": 1,
      "hs": 425.0,
      "mns": 4
    },
    {
      "a": "01062006",
      "j": 2024,
      "n": "bargteheide",
      "dn": "Bargteheide",
      "bl": 1,
      "hs": 370.0,
      "mns": 5
    },
    {
      "a": "01062008",
      "j": 2024,
      "n": "barnitz",
      "dn": "Barnitz",
      "bl": 1,
      "hs": 319.0,
      "mns": 4
    },
    {
      "a": "01062009",
      "j": 2024,
      "n": "barsbüttel",
      "dn": "Barsbüttel",
      "bl": 1,
      "hs": 400.0,
      "mns": 7
    },
    {
      "a": "01062011",
      "j": 2024,
      "n": "braak",
      "dn": "Braak",
      "bl": 1,
      "hs": 245.0,
      "mns": 4
    },
    {
      "a": "01062014",
      "j": 2024,
      "n": "delingsdorf",
      "dn": "Delingsdorf",
      "bl": 1,
      "hs": 425.0,
      "mns": 4
    },
    {
      "a": "01062016",
      "j": 2024,
      "n": "elmenhorst",
      "dn": "Elmenhorst",
      "bl": 1,
      "hs": 390.0,
      "mns": 4
    },
    {
      "a": "01062018",
      "j": 2024,
      "n": "glinde",
      "dn": "Glinde",
      "bl": 1,
      "hs": 400.0,
      "mns": 5
    },
    {
      "a": "01062019",
      "j": 2024,
      "n": "grabau",
      "dn": "Grabau",
      "bl": 1,
      "hs": 370.0,
      "mns": 4
    },
    {
      "a": "01062020",
      "j": 2024,
      "n": "grande",
      "dn": "Grande",
      "bl": 1,
      "hs": 310.0,
      "mns": 4
    },
    {
      "a": "01062021",
      "j": 2024,
      "n": "grönwohld",
      "dn": "Grönwohld",
      "bl": 1,
      "hs": 420.0,
      "mns": 4
    },
    {
      "a": "01062022",
      "j": 2024,
      "n": "großensee",
      "dn": "Großensee",
      "bl": 1,
      "hs": 380.0,
      "mns": 4
    },
    {
      "a": "01062023",
      "j": 2024,
      "n": "großhansdorf",
      "dn": "Großhansdorf",
      "bl": 1,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "01062025",
      "j": 2024,
      "n": "hamberge",
      "dn": "Hamberge",
      "bl": 1,
      "hs": 370.0,
      "mns": 4
    },
    {
      "a": "01062026",
      "j": 2024,
      "n": "hamfelde (stormarn)",
      "dn": "Hamfelde (Stormarn)",
      "bl": 1,
      "hs": 300.0,
      "mns": 4
    },
    {
      "a": "01062027",
      "j": 2024,
      "n": "hammoor",
      "dn": "Hammoor",
      "bl": 1,
      "hs": 425.0,
      "mns": 4
    },
    {
      "a": "01062031",
      "j": 2024,
      "n": "heidekamp",
      "dn": "Heidekamp",
      "bl": 1,
      "hs": 368.0,
      "mns": 4
    },
    {
      "a": "01062032",
      "j": 2024,
      "n": "heilshoop",
      "dn": "Heilshoop",
      "bl": 1,
      "hs": 300.0,
      "mns": 4
    },
    {
      "a": "01062033",
      "j": 2024,
      "n": "hohenfelde",
      "dn": "Hohenfelde",
      "bl": 1,
      "hs": 230.0,
      "mns": 4
    },
    {
      "a": "01062035",
      "j": 2024,
      "n": "hoisdorf",
      "dn": "Hoisdorf",
      "bl": 1,
      "hs": 395.0,
      "mns": 4
    },
    {
      "a": "01062036",
      "j": 2024,
      "n": "jersbek",
      "dn": "Jersbek",
      "bl": 1,
      "hs": 390.0,
      "mns": 4
    },
    {
      "a": "01062039",
      "j": 2024,
      "n": "klein wesenberg",
      "dn": "Klein Wesenberg",
      "bl": 1,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "01062040",
      "j": 2024,
      "n": "köthel (stormarn)",
      "dn": "Köthel (Stormarn)",
      "bl": 1,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "01062045",
      "j": 2024,
      "n": "lütjensee",
      "dn": "Lütjensee",
      "bl": 1,
      "hs": 390.0,
      "mns": 4
    },
    {
      "a": "01062046",
      "j": 2024,
      "n": "meddewade",
      "dn": "Meddewade",
      "bl": 1,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "01062048",
      "j": 2024,
      "n": "mönkhagen",
      "dn": "Mönkhagen",
      "bl": 1,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "01062050",
      "j": 2024,
      "n": "neritz",
      "dn": "Neritz",
      "bl": 1,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "01062051",
      "j": 2024,
      "n": "nienwohld",
      "dn": "Nienwohld",
      "bl": 1,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "01062053",
      "j": 2024,
      "n": "oststeinbek",
      "dn": "Oststeinbek",
      "bl": 1,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "01062056",
      "j": 2024,
      "n": "pölitz",
      "dn": "Pölitz",
      "bl": 1,
      "hs": 410.0,
      "mns": 4
    },
    {
      "a": "01062058",
      "j": 2024,
      "n": "rausdorf",
      "dn": "Rausdorf",
      "bl": 1,
      "hs": 380.0,
      "mns": 4
    },
    {
      "a": "01062059",
      "j": 2024,
      "n": "rehhorst",
      "dn": "Rehhorst",
      "bl": 1,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "01062060",
      "j": 2025,
      "n": "reinbek",
      "dn": "Reinbek",
      "bl": 1,
      "hs": 433.0,
      "mns": 5
    },
    {
      "a": "01062061",
      "j": 2024,
      "n": "reinfeld",
      "dn": "Reinfeld",
      "bl": 1,
      "hs": 425.0,
      "mns": 4
    },
    {
      "a": "01062062",
      "j": 2024,
      "n": "rethwisch",
      "dn": "Rethwisch",
      "bl": 1,
      "hs": 330.0,
      "mns": 4
    },
    {
      "a": "01062065",
      "j": 2024,
      "n": "rümpel",
      "dn": "Rümpel",
      "bl": 1,
      "hs": 390.0,
      "mns": 4
    },
    {
      "a": "01062069",
      "j": 2024,
      "n": "siek",
      "dn": "Siek",
      "bl": 1,
      "hs": 310.0,
      "mns": 4
    },
    {
      "a": "01062071",
      "j": 2024,
      "n": "stapelfeld",
      "dn": "Stapelfeld",
      "bl": 1,
      "hs": 260.0,
      "mns": 4
    },
    {
      "a": "01062076",
      "j": 2024,
      "n": "tangstedt",
      "dn": "Tangstedt",
      "bl": 1,
      "hs": 390.0,
      "mns": 4
    },
    {
      "a": "01062078",
      "j": 2024,
      "n": "todendorf",
      "dn": "Todendorf",
      "bl": 1,
      "hs": 425.0,
      "mns": 4
    },
    {
      "a": "01062081",
      "j": 2024,
      "n": "tremsbüttel",
      "dn": "Tremsbüttel",
      "bl": 1,
      "hs": 450.0,
      "mns": 4
    },
    {
      "a": "01062082",
      "j": 2024,
      "n": "trittau",
      "dn": "Trittau",
      "bl": 1,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "01062083",
      "j": 2024,
      "n": "westerau",
      "dn": "Westerau",
      "bl": 1,
      "hs": 370.0,
      "mns": 4
    },
    {
      "a": "01062086",
      "j": 2024,
      "n": "witzhave",
      "dn": "Witzhave",
      "bl": 1,
      "hs": 280.0,
      "mns": 4
    },
    {
      "a": "01062087",
      "j": 2024,
      "n": "zarpen",
      "dn": "Zarpen",
      "bl": 1,
      "hs": 370.0,
      "mns": 4
    },
    {
      "a": "01062088",
      "j": 2024,
      "n": "brunsbek",
      "dn": "Brunsbek",
      "bl": 1,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "01062089",
      "j": 2024,
      "n": "lasbek",
      "dn": "Lasbek",
      "bl": 1,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "01062090",
      "j": 2024,
      "n": "ammersbek",
      "dn": "Ammersbek",
      "bl": 1,
      "hs": 450.0,
      "mns": 4
    },
    {
      "a": "01062091",
      "j": 2024,
      "n": "steinburg",
      "dn": "Steinburg",
      "bl": 1,
      "hs": 390.0,
      "mns": 4
    },
    {
      "a": "01062092",
      "j": 2024,
      "n": "travenbrück",
      "dn": "Travenbrück",
      "bl": 1,
      "hs": 390.0,
      "mns": 4
    },
    {
      "a": "01062093",
      "j": 2024,
      "n": "feldhorst",
      "dn": "Feldhorst",
      "bl": 1,
      "hs": 315.0,
      "mns": 4
    },
    {
      "a": "01062094",
      "j": 2024,
      "n": "wesenberg",
      "dn": "Wesenberg",
      "bl": 1,
      "hs": 370.0,
      "mns": 4
    }
  ],
  "2": [
    {
      "a": "02000000",
      "j": 2025,
      "n": "hamburg",
      "dn": "Hamburg",
      "bl": 2,
      "hs": 975.0,
      "mns": 6
    }
  ],
  "3": [
    {
      "a": "03101000",
      "j": 2023,
      "n": "braunschweig",
      "dn": "Braunschweig",
      "bl": 3,
      "hs": 500.0,
      "mns": 4
    },
    {
      "a": "03102000",
      "j": 2023,
      "n": "salzgitter",
      "dn": "Salzgitter",
      "bl": 3,
      "hs": 540.0,
      "mns": 2
    },
    {
      "a": "03103000",
      "j": 2023,
      "n": "wolfsburg",
      "dn": "Wolfsburg",
      "bl": 3,
      "hs": 495.0,
      "mns": 4
    },
    {
      "a": "03151001",
      "j": 2023,
      "n": "adenbüttel",
      "dn": "Adenbüttel",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03151002",
      "j": 2023,
      "n": "barwedel",
      "dn": "Barwedel",
      "bl": 3,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "03151003",
      "j": 2023,
      "n": "bergfeld",
      "dn": "Bergfeld",
      "bl": 3,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "03151004",
      "j": 2023,
      "n": "bokensdorf",
      "dn": "Bokensdorf",
      "bl": 3,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "03151005",
      "j": 2023,
      "n": "brome",
      "dn": "Brome",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03151006",
      "j": 2023,
      "n": "calberlah",
      "dn": "Calberlah",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03151007",
      "j": 2023,
      "n": "dedelstorf",
      "dn": "Dedelstorf",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03151008",
      "j": 2023,
      "n": "ehra-lessien",
      "dn": "Ehra-Lessien",
      "bl": 3,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "03151009",
      "j": 2023,
      "n": "gifhorn",
      "dn": "Gifhorn",
      "bl": 3,
      "hs": 430.0,
      "mns": 3
    },
    {
      "a": "03151010",
      "j": 2023,
      "n": "groß oesingen",
      "dn": "Groß Oesingen",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03151011",
      "j": 2023,
      "n": "hankensbüttel",
      "dn": "Hankensbüttel",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03151012",
      "j": 2023,
      "n": "hillerse",
      "dn": "Hillerse",
      "bl": 3,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "03151013",
      "j": 2023,
      "n": "isenbüttel",
      "dn": "Isenbüttel",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03151014",
      "j": 2023,
      "n": "jembke",
      "dn": "Jembke",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03151015",
      "j": 2023,
      "n": "leiferde",
      "dn": "Leiferde",
      "bl": 3,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "03151016",
      "j": 2023,
      "n": "meine",
      "dn": "Meine",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03151017",
      "j": 2023,
      "n": "meinersen",
      "dn": "Meinersen",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03151018",
      "j": 2023,
      "n": "müden (aller)",
      "dn": "Müden (Aller)",
      "bl": 3,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "03151019",
      "j": 2023,
      "n": "obernholz",
      "dn": "Obernholz",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03151020",
      "j": 2023,
      "n": "osloß",
      "dn": "Osloß",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03151021",
      "j": 2023,
      "n": "parsau",
      "dn": "Parsau",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03151022",
      "j": 2023,
      "n": "ribbesbüttel",
      "dn": "Ribbesbüttel",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03151023",
      "j": 2023,
      "n": "rötgesbüttel",
      "dn": "Rötgesbüttel",
      "bl": 3,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "03151024",
      "j": 2023,
      "n": "rühen",
      "dn": "Rühen",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03151025",
      "j": 2023,
      "n": "sassenburg",
      "dn": "Sassenburg",
      "bl": 3,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "03151026",
      "j": 2023,
      "n": "schönewörde",
      "dn": "Schönewörde",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03151027",
      "j": 2023,
      "n": "schwülper",
      "dn": "Schwülper",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03151028",
      "j": 2023,
      "n": "sprakensehl",
      "dn": "Sprakensehl",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03151029",
      "j": 2023,
      "n": "steinhorst",
      "dn": "Steinhorst",
      "bl": 3,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "03151030",
      "j": 2023,
      "n": "tappenbeck",
      "dn": "Tappenbeck",
      "bl": 3,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "03151031",
      "j": 2023,
      "n": "tiddische",
      "dn": "Tiddische",
      "bl": 3,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "03151032",
      "j": 2023,
      "n": "tülau",
      "dn": "Tülau",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03151033",
      "j": 2023,
      "n": "ummern",
      "dn": "Ummern",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03151034",
      "j": 2023,
      "n": "vordorf",
      "dn": "Vordorf",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03151035",
      "j": 2023,
      "n": "wagenhoff",
      "dn": "Wagenhoff",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03151036",
      "j": 2023,
      "n": "wahrenholz",
      "dn": "Wahrenholz",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03151037",
      "j": 2023,
      "n": "wasbüttel",
      "dn": "Wasbüttel",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03151038",
      "j": 2023,
      "n": "wesendorf",
      "dn": "Wesendorf",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03151039",
      "j": 2023,
      "n": "weyhausen",
      "dn": "Weyhausen",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03151040",
      "j": 2023,
      "n": "wittingen",
      "dn": "Wittingen",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03151041",
      "j": 2023,
      "n": "didderse",
      "dn": "Didderse",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03153002",
      "j": 2023,
      "n": "bad harzburg",
      "dn": "Bad Harzburg",
      "bl": 3,
      "hs": 500.0,
      "mns": 2
    },
    {
      "a": "03153008",
      "j": 2023,
      "n": "liebenburg",
      "dn": "Liebenburg",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03153012",
      "j": 2023,
      "n": "seesen",
      "dn": "Seesen",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03153016",
      "j": 2023,
      "n": "braunlage",
      "dn": "Braunlage",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03153017",
      "j": 2023,
      "n": "goslar",
      "dn": "Goslar",
      "bl": 3,
      "hs": 460.0,
      "mns": 2
    },
    {
      "a": "03153018",
      "j": 2025,
      "n": "clausthal-zellerfeld",
      "dn": "Clausthal-Zellerfeld",
      "bl": 3,
      "hs": 437.0,
      "mns": 1
    },
    {
      "a": "03153019",
      "j": 2023,
      "n": "langelsheim",
      "dn": "Langelsheim",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03154001",
      "j": 2023,
      "n": "bahrdorf",
      "dn": "Bahrdorf",
      "bl": 3,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "03154002",
      "j": 2023,
      "n": "beierstedt",
      "dn": "Beierstedt",
      "bl": 3,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "03154004",
      "j": 2023,
      "n": "danndorf",
      "dn": "Danndorf",
      "bl": 3,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "03154005",
      "j": 2023,
      "n": "frellstedt",
      "dn": "Frellstedt",
      "bl": 3,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "03154006",
      "j": 2023,
      "n": "gevensleben",
      "dn": "Gevensleben",
      "bl": 3,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "03154007",
      "j": 2023,
      "n": "grafhorst",
      "dn": "Grafhorst",
      "bl": 3,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "03154008",
      "j": 2023,
      "n": "grasleben",
      "dn": "Grasleben",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03154009",
      "j": 2023,
      "n": "groß twülpstedt",
      "dn": "Groß Twülpstedt",
      "bl": 3,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "03154012",
      "j": 2023,
      "n": "jerxheim",
      "dn": "Jerxheim",
      "bl": 3,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "03154013",
      "j": 2023,
      "n": "königslutter am elm",
      "dn": "Königslutter am Elm",
      "bl": 3,
      "hs": 540.0,
      "mns": 2
    },
    {
      "a": "03154014",
      "j": 2023,
      "n": "lehre",
      "dn": "Lehre",
      "bl": 3,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "03154015",
      "j": 2023,
      "n": "mariental",
      "dn": "Mariental",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03154016",
      "j": 2023,
      "n": "querenhorst",
      "dn": "Querenhorst",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03154017",
      "j": 2023,
      "n": "räbke",
      "dn": "Räbke",
      "bl": 3,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "03154018",
      "j": 2023,
      "n": "rennau",
      "dn": "Rennau",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03154019",
      "j": 2023,
      "n": "schöningen",
      "dn": "Schöningen",
      "bl": 3,
      "hs": 526.0,
      "mns": 1
    },
    {
      "a": "03154021",
      "j": 2023,
      "n": "süpplingen",
      "dn": "Süpplingen",
      "bl": 3,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "03154022",
      "j": 2023,
      "n": "süpplingenburg",
      "dn": "Süpplingenburg",
      "bl": 3,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "03154024",
      "j": 2025,
      "n": "velpke",
      "dn": "Velpke",
      "bl": 3,
      "hs": 315.0,
      "mns": 1
    },
    {
      "a": "03154025",
      "j": 2023,
      "n": "warberg",
      "dn": "Warberg",
      "bl": 3,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "03154026",
      "j": 2023,
      "n": "wolsdorf",
      "dn": "Wolsdorf",
      "bl": 3,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "03154027",
      "j": 2023,
      "n": "söllingen",
      "dn": "Söllingen",
      "bl": 3,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "03154028",
      "j": 2023,
      "n": "helmstedt",
      "dn": "Helmstedt",
      "bl": 3,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "03155001",
      "j": 2023,
      "n": "bad gandersheim",
      "dn": "Bad Gandersheim",
      "bl": 3,
      "hs": 515.0,
      "mns": 1
    },
    {
      "a": "03155002",
      "j": 2023,
      "n": "bodenfelde",
      "dn": "Bodenfelde",
      "bl": 3,
      "hs": 545.0,
      "mns": 1
    },
    {
      "a": "03155003",
      "j": 2023,
      "n": "dassel",
      "dn": "Dassel",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03155005",
      "j": 2023,
      "n": "hardegsen",
      "dn": "Hardegsen",
      "bl": 3,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "03155006",
      "j": 2023,
      "n": "kalefeld",
      "dn": "Kalefeld",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03155007",
      "j": 2023,
      "n": "katlenburg-lindau",
      "dn": "Katlenburg-Lindau",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03155009",
      "j": 2023,
      "n": "moringen",
      "dn": "Moringen",
      "bl": 3,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "03155010",
      "j": 2023,
      "n": "nörten-hardenberg",
      "dn": "Nörten-Hardenberg",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03155011",
      "j": 2023,
      "n": "northeim",
      "dn": "Northeim",
      "bl": 3,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "03155012",
      "j": 2023,
      "n": "uslar",
      "dn": "Uslar",
      "bl": 3,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "03155013",
      "j": 2023,
      "n": "einbeck",
      "dn": "Einbeck",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03157001",
      "j": 2023,
      "n": "edemissen",
      "dn": "Edemissen",
      "bl": 3,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "03157002",
      "j": 2023,
      "n": "hohenhameln",
      "dn": "Hohenhameln",
      "bl": 3,
      "hs": 570.0,
      "mns": 1
    },
    {
      "a": "03157005",
      "j": 2023,
      "n": "lengede",
      "dn": "Lengede",
      "bl": 3,
      "hs": 490.0,
      "mns": 2
    },
    {
      "a": "03157006",
      "j": 2023,
      "n": "peine",
      "dn": "Peine",
      "bl": 3,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "03157007",
      "j": 2025,
      "n": "vechelde",
      "dn": "Vechelde",
      "bl": 3,
      "hs": 430.0,
      "mns": 3
    },
    {
      "a": "03157008",
      "j": 2023,
      "n": "wendeburg",
      "dn": "Wendeburg",
      "bl": 3,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "03157009",
      "j": 2023,
      "n": "ilsede",
      "dn": "Ilsede",
      "bl": 3,
      "hs": 528.0,
      "mns": 1
    },
    {
      "a": "03158002",
      "j": 2023,
      "n": "baddeckenstedt",
      "dn": "Baddeckenstedt",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03158004",
      "j": 2023,
      "n": "burgdorf",
      "dn": "Burgdorf",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03158005",
      "j": 2023,
      "n": "cramme",
      "dn": "Cramme",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03158006",
      "j": 2023,
      "n": "cremlingen",
      "dn": "Cremlingen",
      "bl": 3,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "03158007",
      "j": 2023,
      "n": "dahlum",
      "dn": "Dahlum",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03158008",
      "j": 2023,
      "n": "denkte",
      "dn": "Denkte",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03158009",
      "j": 2023,
      "n": "dettum",
      "dn": "Dettum",
      "bl": 3,
      "hs": 600.0,
      "mns": 1
    },
    {
      "a": "03158010",
      "j": 2023,
      "n": "dorstadt",
      "dn": "Dorstadt",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03158011",
      "j": 2023,
      "n": "elbe",
      "dn": "Elbe",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03158012",
      "j": 2023,
      "n": "erkerode",
      "dn": "Erkerode",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03158013",
      "j": 2023,
      "n": "evessen",
      "dn": "Evessen",
      "bl": 3,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "03158014",
      "j": 2023,
      "n": "flöthe",
      "dn": "Flöthe",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03158016",
      "j": 2023,
      "n": "haverlah",
      "dn": "Haverlah",
      "bl": 3,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "03158017",
      "j": 2023,
      "n": "hedeper",
      "dn": "Hedeper",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03158018",
      "j": 2023,
      "n": "heere",
      "dn": "Heere",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03158019",
      "j": 2023,
      "n": "heiningen",
      "dn": "Heiningen",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03158021",
      "j": 2023,
      "n": "kissenbrück",
      "dn": "Kissenbrück",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03158022",
      "j": 2023,
      "n": "kneitlingen",
      "dn": "Kneitlingen",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03158023",
      "j": 2023,
      "n": "ohrum",
      "dn": "Ohrum",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03158025",
      "j": 2023,
      "n": "roklum",
      "dn": "Roklum",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03158027",
      "j": 2023,
      "n": "schöppenstedt",
      "dn": "Schöppenstedt",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03158028",
      "j": 2023,
      "n": "sehlde",
      "dn": "Sehlde",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03158030",
      "j": 2023,
      "n": "sickte",
      "dn": "Sickte",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03158031",
      "j": 2023,
      "n": "uehrde",
      "dn": "Uehrde",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03158032",
      "j": 2023,
      "n": "vahlberg",
      "dn": "Vahlberg",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03158033",
      "j": 2023,
      "n": "veltheim (ohe)",
      "dn": "Veltheim (Ohe)",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03158035",
      "j": 2023,
      "n": "winnigstedt",
      "dn": "Winnigstedt",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03158036",
      "j": 2023,
      "n": "wittmar",
      "dn": "Wittmar",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03158037",
      "j": 2023,
      "n": "wolfenbüttel",
      "dn": "Wolfenbüttel",
      "bl": 3,
      "hs": 480.0,
      "mns": 3
    },
    {
      "a": "03158038",
      "j": 2025,
      "n": "börßum",
      "dn": "Börßum",
      "bl": 3,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "03158039",
      "j": 2023,
      "n": "schladen-werla",
      "dn": "Schladen-Werla",
      "bl": 3,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "03158040",
      "j": 2023,
      "n": "remlingen-semmenstedt",
      "dn": "Remlingen-Semmenstedt",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03159001",
      "j": 2023,
      "n": "adelebsen",
      "dn": "Adelebsen",
      "bl": 3,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "03159002",
      "j": 2023,
      "n": "bad grund (harz)",
      "dn": "Bad Grund (Harz)",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03159003",
      "j": 2023,
      "n": "bad lauterberg im harz",
      "dn": "Bad Lauterberg im Harz",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03159004",
      "j": 2023,
      "n": "bad sachsa",
      "dn": "Bad Sachsa",
      "bl": 3,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "03159005",
      "j": 2023,
      "n": "bilshausen",
      "dn": "Bilshausen",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03159006",
      "j": 2023,
      "n": "bodensee",
      "dn": "Bodensee",
      "bl": 3,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "03159007",
      "j": 2023,
      "n": "bovenden",
      "dn": "Bovenden",
      "bl": 3,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "03159008",
      "j": 2023,
      "n": "bühren",
      "dn": "Bühren",
      "bl": 3,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "03159009",
      "j": 2023,
      "n": "dransfeld",
      "dn": "Dransfeld",
      "bl": 3,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "03159010",
      "j": 2023,
      "n": "duderstadt",
      "dn": "Duderstadt",
      "bl": 3,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "03159011",
      "j": 2023,
      "n": "ebergötzen",
      "dn": "Ebergötzen",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03159012",
      "j": 2023,
      "n": "elbingerode",
      "dn": "Elbingerode",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03159013",
      "j": 2023,
      "n": "friedland",
      "dn": "Friedland",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03159014",
      "j": 2023,
      "n": "gieboldehausen",
      "dn": "Gieboldehausen",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03159015",
      "j": 2025,
      "n": "gleichen",
      "dn": "Gleichen",
      "bl": 3,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "03159016",
      "j": 2023,
      "n": "göttingen",
      "dn": "Göttingen",
      "bl": 3,
      "hs": 600.0,
      "mns": 4
    },
    {
      "a": "03159017",
      "j": 2024,
      "n": "hann. münden",
      "dn": "Hann. Münden",
      "bl": 3,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "03159018",
      "j": 2023,
      "n": "hattorf am harz",
      "dn": "Hattorf am Harz",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03159019",
      "j": 2023,
      "n": "herzberg am harz",
      "dn": "Herzberg am Harz",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03159020",
      "j": 2023,
      "n": "hörden am harz",
      "dn": "Hörden am Harz",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03159021",
      "j": 2023,
      "n": "jühnde",
      "dn": "Jühnde",
      "bl": 3,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "03159022",
      "j": 2023,
      "n": "krebeck",
      "dn": "Krebeck",
      "bl": 3,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "03159023",
      "j": 2023,
      "n": "landolfshausen",
      "dn": "Landolfshausen",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03159024",
      "j": 2023,
      "n": "niemetal",
      "dn": "Niemetal",
      "bl": 3,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "03159025",
      "j": 2023,
      "n": "obernfeld",
      "dn": "Obernfeld",
      "bl": 3,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "03159026",
      "j": 2023,
      "n": "osterode am harz",
      "dn": "Osterode am Harz",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03159027",
      "j": 2023,
      "n": "rhumspringe",
      "dn": "Rhumspringe",
      "bl": 3,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "03159028",
      "j": 2023,
      "n": "rollshausen",
      "dn": "Rollshausen",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03159029",
      "j": 2023,
      "n": "rosdorf",
      "dn": "Rosdorf",
      "bl": 3,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "03159030",
      "j": 2023,
      "n": "rüdershausen",
      "dn": "Rüdershausen",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03159031",
      "j": 2023,
      "n": "scheden",
      "dn": "Scheden",
      "bl": 3,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "03159032",
      "j": 2023,
      "n": "seeburg",
      "dn": "Seeburg",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03159033",
      "j": 2023,
      "n": "seulingen",
      "dn": "Seulingen",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03159034",
      "j": 2024,
      "n": "staufenberg",
      "dn": "Staufenberg",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03159035",
      "j": 2023,
      "n": "waake",
      "dn": "Waake",
      "bl": 3,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "03159036",
      "j": 2023,
      "n": "walkenried",
      "dn": "Walkenried",
      "bl": 3,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "03159037",
      "j": 2023,
      "n": "wollbrandshausen",
      "dn": "Wollbrandshausen",
      "bl": 3,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "03159038",
      "j": 2023,
      "n": "wollershausen",
      "dn": "Wollershausen",
      "bl": 3,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "03159039",
      "j": 2023,
      "n": "wulften am harz",
      "dn": "Wulften am Harz",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03241001",
      "j": 2024,
      "n": "hannover",
      "dn": "Hannover",
      "bl": 3,
      "hs": 700.0,
      "mns": 5
    },
    {
      "a": "03241002",
      "j": 2023,
      "n": "barsinghausen",
      "dn": "Barsinghausen",
      "bl": 3,
      "hs": 620.0,
      "mns": 2
    },
    {
      "a": "03241003",
      "j": 2023,
      "n": "burgdorf",
      "dn": "Burgdorf",
      "bl": 3,
      "hs": 540.0,
      "mns": 3
    },
    {
      "a": "03241004",
      "j": 2023,
      "n": "burgwedel",
      "dn": "Burgwedel",
      "bl": 3,
      "hs": 505.0,
      "mns": 5
    },
    {
      "a": "03241005",
      "j": 2023,
      "n": "garbsen",
      "dn": "Garbsen",
      "bl": 3,
      "hs": 510.0,
      "mns": 4
    },
    {
      "a": "03241006",
      "j": 2023,
      "n": "gehrden",
      "dn": "Gehrden",
      "bl": 3,
      "hs": 550.0,
      "mns": 3
    },
    {
      "a": "03241007",
      "j": 2023,
      "n": "hemmingen",
      "dn": "Hemmingen",
      "bl": 3,
      "hs": 595.0,
      "mns": 4
    },
    {
      "a": "03241008",
      "j": 2023,
      "n": "isernhagen",
      "dn": "Isernhagen",
      "bl": 3,
      "hs": 510.0,
      "mns": 4
    },
    {
      "a": "03241009",
      "j": 2023,
      "n": "laatzen",
      "dn": "Laatzen",
      "bl": 3,
      "hs": 610.0,
      "mns": 4
    },
    {
      "a": "03241010",
      "j": 2023,
      "n": "langenhagen",
      "dn": "Langenhagen",
      "bl": 3,
      "hs": 480.0,
      "mns": 4
    },
    {
      "a": "03241011",
      "j": 2023,
      "n": "lehrte",
      "dn": "Lehrte",
      "bl": 3,
      "hs": 500.0,
      "mns": 3
    },
    {
      "a": "03241012",
      "j": 2024,
      "n": "neustadt am rübenberge",
      "dn": "Neustadt am Rübenberge",
      "bl": 3,
      "hs": 540.0,
      "mns": 2
    },
    {
      "a": "03241013",
      "j": 2023,
      "n": "pattensen",
      "dn": "Pattensen",
      "bl": 3,
      "hs": 500.0,
      "mns": 3
    },
    {
      "a": "03241014",
      "j": 2023,
      "n": "ronnenberg",
      "dn": "Ronnenberg",
      "bl": 3,
      "hs": 550.0,
      "mns": 4
    },
    {
      "a": "03241015",
      "j": 2023,
      "n": "seelze",
      "dn": "Seelze",
      "bl": 3,
      "hs": 600.0,
      "mns": 4
    },
    {
      "a": "03241016",
      "j": 2023,
      "n": "sehnde",
      "dn": "Sehnde",
      "bl": 3,
      "hs": 560.0,
      "mns": 3
    },
    {
      "a": "03241017",
      "j": 2023,
      "n": "springe",
      "dn": "Springe",
      "bl": 3,
      "hs": 500.0,
      "mns": 2
    },
    {
      "a": "03241018",
      "j": 2023,
      "n": "uetze",
      "dn": "Uetze",
      "bl": 3,
      "hs": 515.0,
      "mns": 2
    },
    {
      "a": "03241019",
      "j": 2025,
      "n": "wedemark",
      "dn": "Wedemark",
      "bl": 3,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "03241020",
      "j": 2023,
      "n": "wennigsen (deister)",
      "dn": "Wennigsen (Deister)",
      "bl": 3,
      "hs": 560.0,
      "mns": 2
    },
    {
      "a": "03241021",
      "j": 2023,
      "n": "wunstorf",
      "dn": "Wunstorf",
      "bl": 3,
      "hs": 595.0,
      "mns": 2
    },
    {
      "a": "03251001",
      "j": 2023,
      "n": "affinghausen",
      "dn": "Affinghausen",
      "bl": 3,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "03251002",
      "j": 2023,
      "n": "asendorf",
      "dn": "Asendorf",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03251003",
      "j": 2023,
      "n": "bahrenborstel",
      "dn": "Bahrenborstel",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03251004",
      "j": 2023,
      "n": "barenburg",
      "dn": "Barenburg",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03251005",
      "j": 2023,
      "n": "barnstorf",
      "dn": "Barnstorf",
      "bl": 3,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "03251006",
      "j": 2023,
      "n": "barver",
      "dn": "Barver",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03251007",
      "j": 2023,
      "n": "bassum",
      "dn": "Bassum",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03251008",
      "j": 2023,
      "n": "borstel",
      "dn": "Borstel",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03251009",
      "j": 2023,
      "n": "brockum",
      "dn": "Brockum",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03251011",
      "j": 2023,
      "n": "dickel",
      "dn": "Dickel",
      "bl": 3,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "03251012",
      "j": 2023,
      "n": "diepholz",
      "dn": "Diepholz",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03251013",
      "j": 2023,
      "n": "drebber",
      "dn": "Drebber",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03251014",
      "j": 2023,
      "n": "drentwede",
      "dn": "Drentwede",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03251015",
      "j": 2023,
      "n": "ehrenburg",
      "dn": "Ehrenburg",
      "bl": 3,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "03251017",
      "j": 2023,
      "n": "eydelstedt",
      "dn": "Eydelstedt",
      "bl": 3,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "03251018",
      "j": 2023,
      "n": "freistatt",
      "dn": "Freistatt",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03251019",
      "j": 2023,
      "n": "hemsloh",
      "dn": "Hemsloh",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03251020",
      "j": 2023,
      "n": "hüde",
      "dn": "Hüde",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03251021",
      "j": 2023,
      "n": "kirchdorf",
      "dn": "Kirchdorf",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03251022",
      "j": 2023,
      "n": "lembruch",
      "dn": "Lembruch",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03251023",
      "j": 2023,
      "n": "lemförde",
      "dn": "Lemförde",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03251024",
      "j": 2023,
      "n": "maasen",
      "dn": "Maasen",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03251025",
      "j": 2023,
      "n": "marl",
      "dn": "Marl",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03251026",
      "j": 2023,
      "n": "martfeld",
      "dn": "Martfeld",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03251027",
      "j": 2023,
      "n": "mellinghausen",
      "dn": "Mellinghausen",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03251028",
      "j": 2023,
      "n": "neuenkirchen",
      "dn": "Neuenkirchen",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03251029",
      "j": 2023,
      "n": "quernheim",
      "dn": "Quernheim",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03251030",
      "j": 2023,
      "n": "rehden",
      "dn": "Rehden",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03251031",
      "j": 2023,
      "n": "scholen",
      "dn": "Scholen",
      "bl": 3,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "03251032",
      "j": 2023,
      "n": "schwaförden",
      "dn": "Schwaförden",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03251033",
      "j": 2023,
      "n": "schwarme",
      "dn": "Schwarme",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03251034",
      "j": 2023,
      "n": "siedenburg",
      "dn": "Siedenburg",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03251035",
      "j": 2023,
      "n": "staffhorst",
      "dn": "Staffhorst",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03251036",
      "j": 2023,
      "n": "stemshorn",
      "dn": "Stemshorn",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03251037",
      "j": 2023,
      "n": "stuhr",
      "dn": "Stuhr",
      "bl": 3,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "03251038",
      "j": 2023,
      "n": "sudwalde",
      "dn": "Sudwalde",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03251040",
      "j": 2023,
      "n": "sulingen",
      "dn": "Sulingen",
      "bl": 3,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "03251041",
      "j": 2023,
      "n": "syke",
      "dn": "Syke",
      "bl": 3,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "03251042",
      "j": 2023,
      "n": "twistringen",
      "dn": "Twistringen",
      "bl": 3,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "03251043",
      "j": 2023,
      "n": "varrel",
      "dn": "Varrel",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03251044",
      "j": 2023,
      "n": "wagenfeld",
      "dn": "Wagenfeld",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03251045",
      "j": 2023,
      "n": "wehrbleck",
      "dn": "Wehrbleck",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03251046",
      "j": 2023,
      "n": "wetschen",
      "dn": "Wetschen",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03251047",
      "j": 2023,
      "n": "weyhe",
      "dn": "Weyhe",
      "bl": 3,
      "hs": 500.0,
      "mns": 3
    },
    {
      "a": "03251049",
      "j": 2023,
      "n": "bruchhausen-vilsen",
      "dn": "Bruchhausen-Vilsen",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03252001",
      "j": 2023,
      "n": "aerzen",
      "dn": "Aerzen",
      "bl": 3,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "03252002",
      "j": 2023,
      "n": "bad münder am deister",
      "dn": "Bad Münder am Deister",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03252003",
      "j": 2023,
      "n": "bad pyrmont",
      "dn": "Bad Pyrmont",
      "bl": 3,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "03252004",
      "j": 2023,
      "n": "coppenbrügge",
      "dn": "Coppenbrügge",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03252005",
      "j": 2023,
      "n": "emmerthal",
      "dn": "Emmerthal",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03252006",
      "j": 2023,
      "n": "hameln",
      "dn": "Hameln",
      "bl": 3,
      "hs": 600.0,
      "mns": 2
    },
    {
      "a": "03252007",
      "j": 2025,
      "n": "hessisch oldendorf",
      "dn": "Hessisch Oldendorf",
      "bl": 3,
      "hs": 219.0,
      "mns": 1
    },
    {
      "a": "03252008",
      "j": 2023,
      "n": "salzhemmendorf",
      "dn": "Salzhemmendorf",
      "bl": 3,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "03254002",
      "j": 2023,
      "n": "alfeld (leine)",
      "dn": "Alfeld (Leine)",
      "bl": 3,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "03254003",
      "j": 2023,
      "n": "algermissen",
      "dn": "Algermissen",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03254005",
      "j": 2023,
      "n": "bad salzdetfurth",
      "dn": "Bad Salzdetfurth",
      "bl": 3,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "03254008",
      "j": 2023,
      "n": "bockenem",
      "dn": "Bockenem",
      "bl": 3,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "03254011",
      "j": 2023,
      "n": "diekholzen",
      "dn": "Diekholzen",
      "bl": 3,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "03254013",
      "j": 2023,
      "n": "eime",
      "dn": "Eime",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03254014",
      "j": 2023,
      "n": "elze",
      "dn": "Elze",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03254017",
      "j": 2023,
      "n": "giesen",
      "dn": "Giesen",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03254020",
      "j": 2023,
      "n": "harsum",
      "dn": "Harsum",
      "bl": 3,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "03254021",
      "j": 2024,
      "n": "hildesheim",
      "dn": "Hildesheim",
      "bl": 3,
      "hs": 650.0,
      "mns": 3
    },
    {
      "a": "03254022",
      "j": 2023,
      "n": "holle",
      "dn": "Holle",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03254026",
      "j": 2023,
      "n": "nordstemmen",
      "dn": "Nordstemmen",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03254028",
      "j": 2023,
      "n": "sarstedt",
      "dn": "Sarstedt",
      "bl": 3,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "03254029",
      "j": 2023,
      "n": "schellerten",
      "dn": "Schellerten",
      "bl": 3,
      "hs": 345.0,
      "mns": 1
    },
    {
      "a": "03254032",
      "j": 2023,
      "n": "söhlde",
      "dn": "Söhlde",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03254041",
      "j": 2023,
      "n": "duingen",
      "dn": "Duingen",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03254042",
      "j": 2023,
      "n": "freden (leine)",
      "dn": "Freden (Leine)",
      "bl": 3,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "03254043",
      "j": 2023,
      "n": "gronau (leine)",
      "dn": "Gronau (Leine)",
      "bl": 3,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "03254044",
      "j": 2023,
      "n": "lamspringe",
      "dn": "Lamspringe",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03254045",
      "j": 2023,
      "n": "sibbesse",
      "dn": "Sibbesse",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03255001",
      "j": 2023,
      "n": "arholzen",
      "dn": "Arholzen",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03255002",
      "j": 2023,
      "n": "bevern",
      "dn": "Bevern",
      "bl": 3,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "03255003",
      "j": 2023,
      "n": "bodenwerder",
      "dn": "Bodenwerder",
      "bl": 3,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "03255004",
      "j": 2023,
      "n": "boffzen",
      "dn": "Boffzen",
      "bl": 3,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "03255005",
      "j": 2023,
      "n": "brevörde",
      "dn": "Brevörde",
      "bl": 3,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "03255007",
      "j": 2023,
      "n": "deensen",
      "dn": "Deensen",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03255008",
      "j": 2023,
      "n": "delligsen",
      "dn": "Delligsen",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03255009",
      "j": 2023,
      "n": "derental",
      "dn": "Derental",
      "bl": 3,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "03255010",
      "j": 2023,
      "n": "dielmissen",
      "dn": "Dielmissen",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03255012",
      "j": 2023,
      "n": "eimen",
      "dn": "Eimen",
      "bl": 3,
      "hs": 345.0,
      "mns": 1
    },
    {
      "a": "03255013",
      "j": 2023,
      "n": "eschershausen",
      "dn": "Eschershausen",
      "bl": 3,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "03255014",
      "j": 2023,
      "n": "fürstenberg",
      "dn": "Fürstenberg",
      "bl": 3,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "03255015",
      "j": 2023,
      "n": "golmbach",
      "dn": "Golmbach",
      "bl": 3,
      "hs": 384.0,
      "mns": 1
    },
    {
      "a": "03255016",
      "j": 2023,
      "n": "halle",
      "dn": "Halle",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03255017",
      "j": 2023,
      "n": "hehlen",
      "dn": "Hehlen",
      "bl": 3,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "03255018",
      "j": 2023,
      "n": "heinade",
      "dn": "Heinade",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03255019",
      "j": 2023,
      "n": "heinsen",
      "dn": "Heinsen",
      "bl": 3,
      "hs": 351.0,
      "mns": 1
    },
    {
      "a": "03255020",
      "j": 2023,
      "n": "heyen",
      "dn": "Heyen",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03255021",
      "j": 2023,
      "n": "holenberg",
      "dn": "Holenberg",
      "bl": 3,
      "hs": 384.0,
      "mns": 1
    },
    {
      "a": "03255022",
      "j": 2023,
      "n": "holzen",
      "dn": "Holzen",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03255023",
      "j": 2023,
      "n": "holzminden",
      "dn": "Holzminden",
      "bl": 3,
      "hs": 348.0,
      "mns": 1
    },
    {
      "a": "03255025",
      "j": 2023,
      "n": "kirchbrak",
      "dn": "Kirchbrak",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03255026",
      "j": 2023,
      "n": "lauenförde",
      "dn": "Lauenförde",
      "bl": 3,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "03255027",
      "j": 2023,
      "n": "lenne",
      "dn": "Lenne",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03255028",
      "j": 2023,
      "n": "lüerdissen",
      "dn": "Lüerdissen",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03255030",
      "j": 2023,
      "n": "negenborn",
      "dn": "Negenborn",
      "bl": 3,
      "hs": 384.0,
      "mns": 1
    },
    {
      "a": "03255031",
      "j": 2023,
      "n": "ottenstein",
      "dn": "Ottenstein",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03255032",
      "j": 2023,
      "n": "pegestorf",
      "dn": "Pegestorf",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03255033",
      "j": 2023,
      "n": "polle",
      "dn": "Polle",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03255034",
      "j": 2023,
      "n": "stadtoldendorf",
      "dn": "Stadtoldendorf",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03255035",
      "j": 2023,
      "n": "vahlbruch",
      "dn": "Vahlbruch",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03255036",
      "j": 2023,
      "n": "wangelnstedt",
      "dn": "Wangelnstedt",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03256001",
      "j": 2023,
      "n": "balge",
      "dn": "Balge",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03256002",
      "j": 2023,
      "n": "binnen",
      "dn": "Binnen",
      "bl": 3,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "03256003",
      "j": 2023,
      "n": "bücken",
      "dn": "Bücken",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03256004",
      "j": 2023,
      "n": "diepenau",
      "dn": "Diepenau",
      "bl": 3,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "03256005",
      "j": 2023,
      "n": "drakenburg",
      "dn": "Drakenburg",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03256006",
      "j": 2023,
      "n": "estorf",
      "dn": "Estorf",
      "bl": 3,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "03256007",
      "j": 2023,
      "n": "eystrup",
      "dn": "Eystrup",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03256008",
      "j": 2023,
      "n": "gandesbergen",
      "dn": "Gandesbergen",
      "bl": 3,
      "hs": 335.0,
      "mns": 1
    },
    {
      "a": "03256009",
      "j": 2023,
      "n": "hämelhausen",
      "dn": "Hämelhausen",
      "bl": 3,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "03256010",
      "j": 2023,
      "n": "hassel",
      "dn": "Hassel",
      "bl": 3,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "03256011",
      "j": 2023,
      "n": "haßbergen",
      "dn": "Haßbergen",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03256012",
      "j": 2023,
      "n": "heemsen",
      "dn": "Heemsen",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03256013",
      "j": 2023,
      "n": "hilgermissen",
      "dn": "Hilgermissen",
      "bl": 3,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "03256014",
      "j": 2023,
      "n": "hoya",
      "dn": "Hoya",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03256015",
      "j": 2023,
      "n": "hoyerhagen",
      "dn": "Hoyerhagen",
      "bl": 3,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "03256016",
      "j": 2023,
      "n": "husum",
      "dn": "Husum",
      "bl": 3,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "03256017",
      "j": 2023,
      "n": "landesbergen",
      "dn": "Landesbergen",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03256018",
      "j": 2023,
      "n": "leese",
      "dn": "Leese",
      "bl": 3,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "03256019",
      "j": 2023,
      "n": "liebenau",
      "dn": "Liebenau",
      "bl": 3,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "03256020",
      "j": 2023,
      "n": "linsburg",
      "dn": "Linsburg",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03256021",
      "j": 2023,
      "n": "marklohe",
      "dn": "Marklohe",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03256022",
      "j": 2023,
      "n": "nienburg/weser",
      "dn": "Nienburg/Weser",
      "bl": 3,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "03256023",
      "j": 2023,
      "n": "pennigsehl",
      "dn": "Pennigsehl",
      "bl": 3,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "03256024",
      "j": 2023,
      "n": "raddestorf",
      "dn": "Raddestorf",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03256025",
      "j": 2023,
      "n": "rehburg-loccum",
      "dn": "Rehburg-Loccum",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03256026",
      "j": 2023,
      "n": "rodewald",
      "dn": "Rodewald",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03256027",
      "j": 2023,
      "n": "rohrsen",
      "dn": "Rohrsen",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03256028",
      "j": 2023,
      "n": "schweringen",
      "dn": "Schweringen",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03256029",
      "j": 2023,
      "n": "steimbke",
      "dn": "Steimbke",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03256030",
      "j": 2023,
      "n": "steyerberg",
      "dn": "Steyerberg",
      "bl": 3,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "03256031",
      "j": 2023,
      "n": "stöckse",
      "dn": "Stöckse",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03256032",
      "j": 2023,
      "n": "stolzenau",
      "dn": "Stolzenau",
      "bl": 3,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "03256033",
      "j": 2023,
      "n": "uchte",
      "dn": "Uchte",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03256034",
      "j": 2023,
      "n": "warmsen",
      "dn": "Warmsen",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03256035",
      "j": 2023,
      "n": "warpe",
      "dn": "Warpe",
      "bl": 3,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "03256036",
      "j": 2023,
      "n": "wietzen",
      "dn": "Wietzen",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03257001",
      "j": 2023,
      "n": "ahnsen",
      "dn": "Ahnsen",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03257002",
      "j": 2023,
      "n": "apelern",
      "dn": "Apelern",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03257003",
      "j": 2023,
      "n": "auetal",
      "dn": "Auetal",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03257004",
      "j": 2023,
      "n": "auhagen",
      "dn": "Auhagen",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03257005",
      "j": 2023,
      "n": "bad eilsen",
      "dn": "Bad Eilsen",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03257006",
      "j": 2023,
      "n": "bad nenndorf",
      "dn": "Bad Nenndorf",
      "bl": 3,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "03257007",
      "j": 2023,
      "n": "beckedorf",
      "dn": "Beckedorf",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03257008",
      "j": 2023,
      "n": "buchholz",
      "dn": "Buchholz",
      "bl": 3,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "03257009",
      "j": 2023,
      "n": "bückeburg",
      "dn": "Bückeburg",
      "bl": 3,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "03257010",
      "j": 2023,
      "n": "hagenburg",
      "dn": "Hagenburg",
      "bl": 3,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "03257011",
      "j": 2023,
      "n": "haste",
      "dn": "Haste",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03257012",
      "j": 2023,
      "n": "heeßen",
      "dn": "Heeßen",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03257013",
      "j": 2023,
      "n": "helpsen",
      "dn": "Helpsen",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03257014",
      "j": 2023,
      "n": "hespe",
      "dn": "Hespe",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03257015",
      "j": 2023,
      "n": "heuerßen",
      "dn": "Heuerßen",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03257016",
      "j": 2023,
      "n": "hohnhorst",
      "dn": "Hohnhorst",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03257017",
      "j": 2023,
      "n": "hülsede",
      "dn": "Hülsede",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03257018",
      "j": 2023,
      "n": "lauenau",
      "dn": "Lauenau",
      "bl": 3,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "03257019",
      "j": 2023,
      "n": "lauenhagen",
      "dn": "Lauenhagen",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03257020",
      "j": 2023,
      "n": "lindhorst",
      "dn": "Lindhorst",
      "bl": 3,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "03257021",
      "j": 2023,
      "n": "lüdersfeld",
      "dn": "Lüdersfeld",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03257022",
      "j": 2023,
      "n": "luhden",
      "dn": "Luhden",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03257023",
      "j": 2023,
      "n": "meerbeck",
      "dn": "Meerbeck",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03257024",
      "j": 2023,
      "n": "messenkamp",
      "dn": "Messenkamp",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03257025",
      "j": 2023,
      "n": "niedernwöhren",
      "dn": "Niedernwöhren",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03257026",
      "j": 2023,
      "n": "nienstädt",
      "dn": "Nienstädt",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03257027",
      "j": 2023,
      "n": "nordsehl",
      "dn": "Nordsehl",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03257028",
      "j": 2023,
      "n": "obernkirchen",
      "dn": "Obernkirchen",
      "bl": 3,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "03257029",
      "j": 2023,
      "n": "pohle",
      "dn": "Pohle",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03257030",
      "j": 2023,
      "n": "pollhagen",
      "dn": "Pollhagen",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03257031",
      "j": 2023,
      "n": "rinteln",
      "dn": "Rinteln",
      "bl": 3,
      "hs": 433.0,
      "mns": 1
    },
    {
      "a": "03257032",
      "j": 2023,
      "n": "rodenberg",
      "dn": "Rodenberg",
      "bl": 3,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "03257033",
      "j": 2023,
      "n": "sachsenhagen",
      "dn": "Sachsenhagen",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03257034",
      "j": 2023,
      "n": "seggebruch",
      "dn": "Seggebruch",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03257035",
      "j": 2023,
      "n": "stadthagen",
      "dn": "Stadthagen",
      "bl": 3,
      "hs": 460.0,
      "mns": 2
    },
    {
      "a": "03257036",
      "j": 2023,
      "n": "suthfeld",
      "dn": "Suthfeld",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03257037",
      "j": 2023,
      "n": "wiedensahl",
      "dn": "Wiedensahl",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03257038",
      "j": 2023,
      "n": "wölpinghausen",
      "dn": "Wölpinghausen",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03351001",
      "j": 2023,
      "n": "adelheidsdorf",
      "dn": "Adelheidsdorf",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03351002",
      "j": 2023,
      "n": "ahnsbeck",
      "dn": "Ahnsbeck",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03351003",
      "j": 2023,
      "n": "beedenbostel",
      "dn": "Beedenbostel",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03351004",
      "j": 2023,
      "n": "bergen",
      "dn": "Bergen",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03351005",
      "j": 2023,
      "n": "bröckel",
      "dn": "Bröckel",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03351006",
      "j": 2023,
      "n": "celle",
      "dn": "Celle",
      "bl": 3,
      "hs": 520.0,
      "mns": 3
    },
    {
      "a": "03351007",
      "j": 2023,
      "n": "eicklingen",
      "dn": "Eicklingen",
      "bl": 3,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "03351008",
      "j": 2023,
      "n": "eldingen",
      "dn": "Eldingen",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03351010",
      "j": 2023,
      "n": "faßberg",
      "dn": "Faßberg",
      "bl": 3,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "03351012",
      "j": 2023,
      "n": "hambühren",
      "dn": "Hambühren",
      "bl": 3,
      "hs": 480.0,
      "mns": 2
    },
    {
      "a": "03351015",
      "j": 2023,
      "n": "hohne",
      "dn": "Hohne",
      "bl": 3,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "03351016",
      "j": 2023,
      "n": "lachendorf",
      "dn": "Lachendorf",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03351017",
      "j": 2023,
      "n": "langlingen",
      "dn": "Langlingen",
      "bl": 3,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "03351018",
      "j": 2023,
      "n": "nienhagen",
      "dn": "Nienhagen",
      "bl": 3,
      "hs": 495.0,
      "mns": 1
    },
    {
      "a": "03351021",
      "j": 2023,
      "n": "wathlingen",
      "dn": "Wathlingen",
      "bl": 3,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "03351022",
      "j": 2023,
      "n": "wienhausen",
      "dn": "Wienhausen",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03351023",
      "j": 2023,
      "n": "wietze",
      "dn": "Wietze",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03351024",
      "j": 2023,
      "n": "winsen",
      "dn": "Winsen",
      "bl": 3,
      "hs": 600.0,
      "mns": 2
    },
    {
      "a": "03351025",
      "j": 2023,
      "n": "eschede",
      "dn": "Eschede",
      "bl": 3,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "03351026",
      "j": 2023,
      "n": "südheide",
      "dn": "Südheide",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03352002",
      "j": 2023,
      "n": "armstorf",
      "dn": "Armstorf",
      "bl": 3,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "03352004",
      "j": 2023,
      "n": "belum",
      "dn": "Belum",
      "bl": 3,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "03352008",
      "j": 2023,
      "n": "bülkau",
      "dn": "Bülkau",
      "bl": 3,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "03352011",
      "j": 2025,
      "n": "cuxhaven",
      "dn": "Cuxhaven",
      "bl": 3,
      "hs": 555.0,
      "mns": 2
    },
    {
      "a": "03352020",
      "j": 2023,
      "n": "hechthausen",
      "dn": "Hechthausen",
      "bl": 3,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "03352022",
      "j": 2023,
      "n": "hemmoor",
      "dn": "Hemmoor",
      "bl": 3,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "03352024",
      "j": 2023,
      "n": "hollnseth",
      "dn": "Hollnseth",
      "bl": 3,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "03352025",
      "j": 2023,
      "n": "ihlienworth",
      "dn": "Ihlienworth",
      "bl": 3,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "03352029",
      "j": 2023,
      "n": "lamstedt",
      "dn": "Lamstedt",
      "bl": 3,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "03352032",
      "j": 2023,
      "n": "loxstedt",
      "dn": "Loxstedt",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03352036",
      "j": 2023,
      "n": "mittelstenahe",
      "dn": "Mittelstenahe",
      "bl": 3,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "03352038",
      "j": 2023,
      "n": "neuenkirchen",
      "dn": "Neuenkirchen",
      "bl": 3,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "03352039",
      "j": 2023,
      "n": "neuhaus (oste)",
      "dn": "Neuhaus (Oste)",
      "bl": 3,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "03352041",
      "j": 2023,
      "n": "nordleda",
      "dn": "Nordleda",
      "bl": 3,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "03352042",
      "j": 2023,
      "n": "oberndorf",
      "dn": "Oberndorf",
      "bl": 3,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "03352043",
      "j": 2023,
      "n": "odisheim",
      "dn": "Odisheim",
      "bl": 3,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "03352044",
      "j": 2023,
      "n": "osten",
      "dn": "Osten",
      "bl": 3,
      "hs": 435.0,
      "mns": 1
    },
    {
      "a": "03352045",
      "j": 2023,
      "n": "osterbruch",
      "dn": "Osterbruch",
      "bl": 3,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "03352046",
      "j": 2023,
      "n": "otterndorf",
      "dn": "Otterndorf",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03352050",
      "j": 2023,
      "n": "schiffdorf",
      "dn": "Schiffdorf",
      "bl": 3,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "03352051",
      "j": 2023,
      "n": "steinau",
      "dn": "Steinau",
      "bl": 3,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "03352052",
      "j": 2023,
      "n": "stinstedt",
      "dn": "Stinstedt",
      "bl": 3,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "03352055",
      "j": 2023,
      "n": "wanna",
      "dn": "Wanna",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03352056",
      "j": 2023,
      "n": "wingst",
      "dn": "Wingst",
      "bl": 3,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "03352059",
      "j": 2023,
      "n": "beverstedt",
      "dn": "Beverstedt",
      "bl": 3,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "03352060",
      "j": 2023,
      "n": "hagen im bremischen",
      "dn": "Hagen im Bremischen",
      "bl": 3,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "03352061",
      "j": 2023,
      "n": "wurster nordseeküste",
      "dn": "Wurster Nordseeküste",
      "bl": 3,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "03352062",
      "j": 2023,
      "n": "geestland",
      "dn": "Geestland",
      "bl": 3,
      "hs": 490.0,
      "mns": 2
    },
    {
      "a": "03352063",
      "j": 2023,
      "n": "cadenberge",
      "dn": "Cadenberge",
      "bl": 3,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "03353001",
      "j": 2023,
      "n": "appel",
      "dn": "Appel",
      "bl": 3,
      "hs": 355.0,
      "mns": 3
    },
    {
      "a": "03353002",
      "j": 2023,
      "n": "asendorf",
      "dn": "Asendorf",
      "bl": 3,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "03353003",
      "j": 2023,
      "n": "bendestorf",
      "dn": "Bendestorf",
      "bl": 3,
      "hs": 440.0,
      "mns": 3
    },
    {
      "a": "03353004",
      "j": 2023,
      "n": "brackel",
      "dn": "Brackel",
      "bl": 3,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "03353005",
      "j": 2023,
      "n": "buchholz in der nordheide",
      "dn": "Buchholz in der Nordheide",
      "bl": 3,
      "hs": 400.0,
      "mns": 6
    },
    {
      "a": "03353006",
      "j": 2023,
      "n": "dohren",
      "dn": "Dohren",
      "bl": 3,
      "hs": 430.0,
      "mns": 3
    },
    {
      "a": "03353007",
      "j": 2023,
      "n": "drage",
      "dn": "Drage",
      "bl": 3,
      "hs": 410.0,
      "mns": 3
    },
    {
      "a": "03353008",
      "j": 2023,
      "n": "drestedt",
      "dn": "Drestedt",
      "bl": 3,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "03353009",
      "j": 2023,
      "n": "egestorf",
      "dn": "Egestorf",
      "bl": 3,
      "hs": 460.0,
      "mns": 3
    },
    {
      "a": "03353010",
      "j": 2023,
      "n": "eyendorf",
      "dn": "Eyendorf",
      "bl": 3,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "03353011",
      "j": 2023,
      "n": "garlstorf",
      "dn": "Garlstorf",
      "bl": 3,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "03353012",
      "j": 2023,
      "n": "garstedt",
      "dn": "Garstedt",
      "bl": 3,
      "hs": 450.0,
      "mns": 3
    },
    {
      "a": "03353013",
      "j": 2023,
      "n": "gödenstorf",
      "dn": "Gödenstorf",
      "bl": 3,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "03353014",
      "j": 2023,
      "n": "halvesbostel",
      "dn": "Halvesbostel",
      "bl": 3,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "03353015",
      "j": 2023,
      "n": "handeloh",
      "dn": "Handeloh",
      "bl": 3,
      "hs": 430.0,
      "mns": 3
    },
    {
      "a": "03353016",
      "j": 2023,
      "n": "hanstedt",
      "dn": "Hanstedt",
      "bl": 3,
      "hs": 420.0,
      "mns": 3
    },
    {
      "a": "03353017",
      "j": 2023,
      "n": "harmstorf",
      "dn": "Harmstorf",
      "bl": 3,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "03353018",
      "j": 2023,
      "n": "heidenau",
      "dn": "Heidenau",
      "bl": 3,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "03353019",
      "j": 2023,
      "n": "hollenstedt",
      "dn": "Hollenstedt",
      "bl": 3,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "03353020",
      "j": 2025,
      "n": "jesteburg",
      "dn": "Jesteburg",
      "bl": 3,
      "hs": 490.0,
      "mns": 3
    },
    {
      "a": "03353021",
      "j": 2023,
      "n": "kakenstorf",
      "dn": "Kakenstorf",
      "bl": 3,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "03353022",
      "j": 2023,
      "n": "königsmoor",
      "dn": "Königsmoor",
      "bl": 3,
      "hs": 460.0,
      "mns": 3
    },
    {
      "a": "03353023",
      "j": 2023,
      "n": "marschacht",
      "dn": "Marschacht",
      "bl": 3,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "03353024",
      "j": 2023,
      "n": "marxen",
      "dn": "Marxen",
      "bl": 3,
      "hs": 450.0,
      "mns": 3
    },
    {
      "a": "03353025",
      "j": 2023,
      "n": "moisburg",
      "dn": "Moisburg",
      "bl": 3,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "03353026",
      "j": 2023,
      "n": "neu wulmstorf",
      "dn": "Neu Wulmstorf",
      "bl": 3,
      "hs": 460.0,
      "mns": 5
    },
    {
      "a": "03353027",
      "j": 2023,
      "n": "otter",
      "dn": "Otter",
      "bl": 3,
      "hs": 430.0,
      "mns": 3
    },
    {
      "a": "03353028",
      "j": 2023,
      "n": "regesbostel",
      "dn": "Regesbostel",
      "bl": 3,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "03353029",
      "j": 2023,
      "n": "rosengarten",
      "dn": "Rosengarten",
      "bl": 3,
      "hs": 420.0,
      "mns": 4
    },
    {
      "a": "03353030",
      "j": 2023,
      "n": "salzhausen",
      "dn": "Salzhausen",
      "bl": 3,
      "hs": 450.0,
      "mns": 3
    },
    {
      "a": "03353031",
      "j": 2023,
      "n": "seevetal",
      "dn": "Seevetal",
      "bl": 3,
      "hs": 410.0,
      "mns": 5
    },
    {
      "a": "03353032",
      "j": 2023,
      "n": "stelle",
      "dn": "Stelle",
      "bl": 3,
      "hs": 400.0,
      "mns": 5
    },
    {
      "a": "03353033",
      "j": 2023,
      "n": "tespe",
      "dn": "Tespe",
      "bl": 3,
      "hs": 410.0,
      "mns": 3
    },
    {
      "a": "03353034",
      "j": 2023,
      "n": "toppenstedt",
      "dn": "Toppenstedt",
      "bl": 3,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "03353035",
      "j": 2023,
      "n": "tostedt",
      "dn": "Tostedt",
      "bl": 3,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "03353036",
      "j": 2023,
      "n": "undeloh",
      "dn": "Undeloh",
      "bl": 3,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "03353037",
      "j": 2023,
      "n": "vierhöfen",
      "dn": "Vierhöfen",
      "bl": 3,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "03353038",
      "j": 2023,
      "n": "welle",
      "dn": "Welle",
      "bl": 3,
      "hs": 450.0,
      "mns": 3
    },
    {
      "a": "03353039",
      "j": 2023,
      "n": "wenzendorf",
      "dn": "Wenzendorf",
      "bl": 3,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "03353040",
      "j": 2023,
      "n": "winsen (luhe)",
      "dn": "Winsen (Luhe)",
      "bl": 3,
      "hs": 380.0,
      "mns": 4
    },
    {
      "a": "03353041",
      "j": 2023,
      "n": "wistedt",
      "dn": "Wistedt",
      "bl": 3,
      "hs": 440.0,
      "mns": 3
    },
    {
      "a": "03353042",
      "j": 2023,
      "n": "wulfsen",
      "dn": "Wulfsen",
      "bl": 3,
      "hs": 450.0,
      "mns": 3
    },
    {
      "a": "03354001",
      "j": 2023,
      "n": "bergen an der dumme",
      "dn": "Bergen an der Dumme",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03354002",
      "j": 2023,
      "n": "clenze",
      "dn": "Clenze",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03354003",
      "j": 2023,
      "n": "damnatz",
      "dn": "Damnatz",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03354004",
      "j": 2023,
      "n": "dannenberg (elbe)",
      "dn": "Dannenberg (Elbe)",
      "bl": 3,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "03354005",
      "j": 2023,
      "n": "gartow",
      "dn": "Gartow",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03354006",
      "j": 2023,
      "n": "göhrde",
      "dn": "Göhrde",
      "bl": 3,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "03354007",
      "j": 2023,
      "n": "gorleben",
      "dn": "Gorleben",
      "bl": 3,
      "hs": 250.0,
      "mns": 1
    },
    {
      "a": "03354008",
      "j": 2023,
      "n": "gusborn",
      "dn": "Gusborn",
      "bl": 3,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "03354009",
      "j": 2023,
      "n": "hitzacker",
      "dn": "Hitzacker",
      "bl": 3,
      "hs": 600.0,
      "mns": 1
    },
    {
      "a": "03354010",
      "j": 2023,
      "n": "höhbeck",
      "dn": "Höhbeck",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03354011",
      "j": 2023,
      "n": "jameln",
      "dn": "Jameln",
      "bl": 3,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "03354012",
      "j": 2023,
      "n": "karwitz",
      "dn": "Karwitz",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03354013",
      "j": 2023,
      "n": "küsten",
      "dn": "Küsten",
      "bl": 3,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "03354014",
      "j": 2023,
      "n": "langendorf",
      "dn": "Langendorf",
      "bl": 3,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "03354015",
      "j": 2023,
      "n": "lemgow",
      "dn": "Lemgow",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03354016",
      "j": 2023,
      "n": "luckau (wendland)",
      "dn": "Luckau (Wendland)",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03354017",
      "j": 2023,
      "n": "lübbow",
      "dn": "Lübbow",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03354018",
      "j": 2023,
      "n": "lüchow",
      "dn": "Lüchow",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03354019",
      "j": 2023,
      "n": "neu darchau",
      "dn": "Neu Darchau",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03354020",
      "j": 2023,
      "n": "prezelle",
      "dn": "Prezelle",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03354021",
      "j": 2023,
      "n": "schnackenburg",
      "dn": "Schnackenburg",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03354022",
      "j": 2023,
      "n": "schnega",
      "dn": "Schnega",
      "bl": 3,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "03354023",
      "j": 2023,
      "n": "trebel",
      "dn": "Trebel",
      "bl": 3,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "03354024",
      "j": 2023,
      "n": "waddeweitz",
      "dn": "Waddeweitz",
      "bl": 3,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "03354025",
      "j": 2023,
      "n": "woltersdorf",
      "dn": "Woltersdorf",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03354026",
      "j": 2023,
      "n": "wustrow",
      "dn": "Wustrow",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03354027",
      "j": 2023,
      "n": "zernien",
      "dn": "Zernien",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03355001",
      "j": 2023,
      "n": "adendorf",
      "dn": "Adendorf",
      "bl": 3,
      "hs": 439.0,
      "mns": 4
    },
    {
      "a": "03355002",
      "j": 2023,
      "n": "amelinghausen",
      "dn": "Amelinghausen",
      "bl": 3,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "03355003",
      "j": 2023,
      "n": "artlenburg",
      "dn": "Artlenburg",
      "bl": 3,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "03355004",
      "j": 2023,
      "n": "bardowick",
      "dn": "Bardowick",
      "bl": 3,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "03355005",
      "j": 2023,
      "n": "barendorf",
      "dn": "Barendorf",
      "bl": 3,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "03355006",
      "j": 2023,
      "n": "barnstedt",
      "dn": "Barnstedt",
      "bl": 3,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "03355007",
      "j": 2023,
      "n": "barum",
      "dn": "Barum",
      "bl": 3,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "03355008",
      "j": 2023,
      "n": "betzendorf",
      "dn": "Betzendorf",
      "bl": 3,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "03355009",
      "j": 2023,
      "n": "bleckede",
      "dn": "Bleckede",
      "bl": 3,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "03355010",
      "j": 2023,
      "n": "boitze",
      "dn": "Boitze",
      "bl": 3,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "03355011",
      "j": 2023,
      "n": "brietlingen",
      "dn": "Brietlingen",
      "bl": 3,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "03355012",
      "j": 2023,
      "n": "dahlem",
      "dn": "Dahlem",
      "bl": 3,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "03355013",
      "j": 2023,
      "n": "dahlenburg",
      "dn": "Dahlenburg",
      "bl": 3,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "03355014",
      "j": 2023,
      "n": "deutsch evern",
      "dn": "Deutsch Evern",
      "bl": 3,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "03355015",
      "j": 2023,
      "n": "echem",
      "dn": "Echem",
      "bl": 3,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "03355016",
      "j": 2023,
      "n": "embsen",
      "dn": "Embsen",
      "bl": 3,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "03355017",
      "j": 2023,
      "n": "handorf",
      "dn": "Handorf",
      "bl": 3,
      "hs": 375.0,
      "mns": 2
    },
    {
      "a": "03355018",
      "j": 2023,
      "n": "hittbergen",
      "dn": "Hittbergen",
      "bl": 3,
      "hs": 415.0,
      "mns": 2
    },
    {
      "a": "03355019",
      "j": 2023,
      "n": "hohnstorf",
      "dn": "Hohnstorf",
      "bl": 3,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "03355020",
      "j": 2023,
      "n": "kirchgellersen",
      "dn": "Kirchgellersen",
      "bl": 3,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "03355021",
      "j": 2023,
      "n": "lüdersburg",
      "dn": "Lüdersburg",
      "bl": 3,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "03355022",
      "j": 2023,
      "n": "lüneburg",
      "dn": "Lüneburg",
      "bl": 3,
      "hs": 490.0,
      "mns": 5
    },
    {
      "a": "03355023",
      "j": 2023,
      "n": "mechtersen",
      "dn": "Mechtersen",
      "bl": 3,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "03355024",
      "j": 2023,
      "n": "melbeck",
      "dn": "Melbeck",
      "bl": 3,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "03355025",
      "j": 2023,
      "n": "nahrendorf",
      "dn": "Nahrendorf",
      "bl": 3,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "03355026",
      "j": 2023,
      "n": "neetze",
      "dn": "Neetze",
      "bl": 3,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "03355027",
      "j": 2023,
      "n": "oldendorf (luhe)",
      "dn": "Oldendorf (Luhe)",
      "bl": 3,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "03355028",
      "j": 2023,
      "n": "radbruch",
      "dn": "Radbruch",
      "bl": 3,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "03355029",
      "j": 2023,
      "n": "rehlingen",
      "dn": "Rehlingen",
      "bl": 3,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "03355030",
      "j": 2023,
      "n": "reinstorf",
      "dn": "Reinstorf",
      "bl": 3,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "03355031",
      "j": 2023,
      "n": "reppenstedt",
      "dn": "Reppenstedt",
      "bl": 3,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "03355032",
      "j": 2023,
      "n": "rullstorf",
      "dn": "Rullstorf",
      "bl": 3,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "03355033",
      "j": 2023,
      "n": "scharnebeck",
      "dn": "Scharnebeck",
      "bl": 3,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "03355034",
      "j": 2023,
      "n": "soderstorf",
      "dn": "Soderstorf",
      "bl": 3,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "03355035",
      "j": 2023,
      "n": "südergellersen",
      "dn": "Südergellersen",
      "bl": 3,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "03355036",
      "j": 2023,
      "n": "thomasburg",
      "dn": "Thomasburg",
      "bl": 3,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "03355037",
      "j": 2023,
      "n": "tosterglope",
      "dn": "Tosterglope",
      "bl": 3,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "03355038",
      "j": 2023,
      "n": "vastorf",
      "dn": "Vastorf",
      "bl": 3,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "03355039",
      "j": 2023,
      "n": "vögelsen",
      "dn": "Vögelsen",
      "bl": 3,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "03355040",
      "j": 2023,
      "n": "wendisch evern",
      "dn": "Wendisch Evern",
      "bl": 3,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "03355041",
      "j": 2023,
      "n": "westergellersen",
      "dn": "Westergellersen",
      "bl": 3,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "03355042",
      "j": 2023,
      "n": "wittorf",
      "dn": "Wittorf",
      "bl": 3,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "03355049",
      "j": 2023,
      "n": "amt neuhaus",
      "dn": "Amt Neuhaus",
      "bl": 3,
      "hs": 490.0,
      "mns": 2
    },
    {
      "a": "03356001",
      "j": 2023,
      "n": "axstedt",
      "dn": "Axstedt",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03356002",
      "j": 2023,
      "n": "grasberg",
      "dn": "Grasberg",
      "bl": 3,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "03356003",
      "j": 2023,
      "n": "hambergen",
      "dn": "Hambergen",
      "bl": 3,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "03356004",
      "j": 2023,
      "n": "holste",
      "dn": "Holste",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03356005",
      "j": 2024,
      "n": "lilienthal",
      "dn": "Lilienthal",
      "bl": 3,
      "hs": 590.0,
      "mns": 3
    },
    {
      "a": "03356006",
      "j": 2023,
      "n": "lübberstedt",
      "dn": "Lübberstedt",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03356007",
      "j": 2023,
      "n": "osterholz-scharmbeck",
      "dn": "Osterholz-Scharmbeck",
      "bl": 3,
      "hs": 550.0,
      "mns": 2
    },
    {
      "a": "03356008",
      "j": 2023,
      "n": "ritterhude",
      "dn": "Ritterhude",
      "bl": 3,
      "hs": 640.0,
      "mns": 2
    },
    {
      "a": "03356009",
      "j": 2023,
      "n": "schwanewede",
      "dn": "Schwanewede",
      "bl": 3,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "03356010",
      "j": 2023,
      "n": "vollersode",
      "dn": "Vollersode",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03356011",
      "j": 2023,
      "n": "worpswede",
      "dn": "Worpswede",
      "bl": 3,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "03357001",
      "j": 2023,
      "n": "ahausen",
      "dn": "Ahausen",
      "bl": 3,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "03357002",
      "j": 2023,
      "n": "alfstedt",
      "dn": "Alfstedt",
      "bl": 3,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "03357003",
      "j": 2023,
      "n": "anderlingen",
      "dn": "Anderlingen",
      "bl": 3,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "03357004",
      "j": 2023,
      "n": "basdahl",
      "dn": "Basdahl",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03357005",
      "j": 2023,
      "n": "bötersen",
      "dn": "Bötersen",
      "bl": 3,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "03357006",
      "j": 2023,
      "n": "bothel",
      "dn": "Bothel",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03357007",
      "j": 2023,
      "n": "breddorf",
      "dn": "Breddorf",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03357008",
      "j": 2023,
      "n": "bremervörde",
      "dn": "Bremervörde",
      "bl": 3,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "03357009",
      "j": 2023,
      "n": "brockel",
      "dn": "Brockel",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03357010",
      "j": 2023,
      "n": "bülstedt",
      "dn": "Bülstedt",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03357011",
      "j": 2023,
      "n": "deinstedt",
      "dn": "Deinstedt",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03357012",
      "j": 2023,
      "n": "ebersdorf",
      "dn": "Ebersdorf",
      "bl": 3,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "03357013",
      "j": 2023,
      "n": "elsdorf",
      "dn": "Elsdorf",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03357014",
      "j": 2023,
      "n": "farven",
      "dn": "Farven",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03357015",
      "j": 2023,
      "n": "fintel",
      "dn": "Fintel",
      "bl": 3,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "03357016",
      "j": 2023,
      "n": "gnarrenburg",
      "dn": "Gnarrenburg",
      "bl": 3,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "03357017",
      "j": 2023,
      "n": "groß meckelsen",
      "dn": "Groß Meckelsen",
      "bl": 3,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "03357018",
      "j": 2023,
      "n": "gyhum",
      "dn": "Gyhum",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03357019",
      "j": 2023,
      "n": "hamersen",
      "dn": "Hamersen",
      "bl": 3,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "03357020",
      "j": 2023,
      "n": "hassendorf",
      "dn": "Hassendorf",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03357021",
      "j": 2023,
      "n": "heeslingen",
      "dn": "Heeslingen",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03357022",
      "j": 2023,
      "n": "hellwege",
      "dn": "Hellwege",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03357023",
      "j": 2023,
      "n": "helvesiek",
      "dn": "Helvesiek",
      "bl": 3,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "03357024",
      "j": 2023,
      "n": "hemsbünde",
      "dn": "Hemsbünde",
      "bl": 3,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "03357025",
      "j": 2023,
      "n": "hemslingen",
      "dn": "Hemslingen",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03357026",
      "j": 2023,
      "n": "hepstedt",
      "dn": "Hepstedt",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03357027",
      "j": 2023,
      "n": "hipstedt",
      "dn": "Hipstedt",
      "bl": 3,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "03357028",
      "j": 2023,
      "n": "horstedt",
      "dn": "Horstedt",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03357029",
      "j": 2023,
      "n": "kalbe",
      "dn": "Kalbe",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03357030",
      "j": 2023,
      "n": "kirchtimke",
      "dn": "Kirchtimke",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03357031",
      "j": 2023,
      "n": "kirchwalsede",
      "dn": "Kirchwalsede",
      "bl": 3,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "03357032",
      "j": 2023,
      "n": "klein meckelsen",
      "dn": "Klein Meckelsen",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03357033",
      "j": 2023,
      "n": "lauenbrück",
      "dn": "Lauenbrück",
      "bl": 3,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "03357034",
      "j": 2023,
      "n": "lengenbostel",
      "dn": "Lengenbostel",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03357035",
      "j": 2023,
      "n": "oerel",
      "dn": "Oerel",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03357036",
      "j": 2023,
      "n": "ostereistedt",
      "dn": "Ostereistedt",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03357037",
      "j": 2023,
      "n": "reeßum",
      "dn": "Reeßum",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03357038",
      "j": 2023,
      "n": "rhade",
      "dn": "Rhade",
      "bl": 3,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "03357039",
      "j": 2023,
      "n": "rotenburg",
      "dn": "Rotenburg",
      "bl": 3,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "03357040",
      "j": 2023,
      "n": "sandbostel",
      "dn": "Sandbostel",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03357041",
      "j": 2023,
      "n": "scheeßel",
      "dn": "Scheeßel",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03357042",
      "j": 2023,
      "n": "seedorf",
      "dn": "Seedorf",
      "bl": 3,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "03357043",
      "j": 2023,
      "n": "selsingen",
      "dn": "Selsingen",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03357044",
      "j": 2023,
      "n": "sittensen",
      "dn": "Sittensen",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03357045",
      "j": 2024,
      "n": "sottrum",
      "dn": "Sottrum",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03357046",
      "j": 2023,
      "n": "stemmen",
      "dn": "Stemmen",
      "bl": 3,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "03357047",
      "j": 2023,
      "n": "tarmstedt",
      "dn": "Tarmstedt",
      "bl": 3,
      "hs": 435.0,
      "mns": 1
    },
    {
      "a": "03357048",
      "j": 2023,
      "n": "tiste",
      "dn": "Tiste",
      "bl": 3,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "03357049",
      "j": 2023,
      "n": "vahlde",
      "dn": "Vahlde",
      "bl": 3,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "03357050",
      "j": 2023,
      "n": "vierden",
      "dn": "Vierden",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03357051",
      "j": 2023,
      "n": "visselhövede",
      "dn": "Visselhövede",
      "bl": 3,
      "hs": 416.0,
      "mns": 1
    },
    {
      "a": "03357052",
      "j": 2023,
      "n": "vorwerk",
      "dn": "Vorwerk",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03357053",
      "j": 2023,
      "n": "westertimke",
      "dn": "Westertimke",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03357054",
      "j": 2023,
      "n": "westerwalsede",
      "dn": "Westerwalsede",
      "bl": 3,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "03357055",
      "j": 2023,
      "n": "wilstedt",
      "dn": "Wilstedt",
      "bl": 3,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "03357056",
      "j": 2023,
      "n": "wohnste",
      "dn": "Wohnste",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03357057",
      "j": 2023,
      "n": "zeven",
      "dn": "Zeven",
      "bl": 3,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "03358001",
      "j": 2023,
      "n": "ahlden (aller)",
      "dn": "Ahlden (Aller)",
      "bl": 3,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "03358002",
      "j": 2023,
      "n": "bispingen",
      "dn": "Bispingen",
      "bl": 3,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "03358003",
      "j": 2023,
      "n": "böhme",
      "dn": "Böhme",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03358005",
      "j": 2023,
      "n": "buchholz (aller)",
      "dn": "Buchholz (Aller)",
      "bl": 3,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "03358006",
      "j": 2023,
      "n": "eickeloh",
      "dn": "Eickeloh",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03358007",
      "j": 2023,
      "n": "essel",
      "dn": "Essel",
      "bl": 3,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "03358008",
      "j": 2023,
      "n": "bad fallingbostel",
      "dn": "Bad Fallingbostel",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03358009",
      "j": 2023,
      "n": "frankenfeld",
      "dn": "Frankenfeld",
      "bl": 3,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "03358010",
      "j": 2023,
      "n": "gilten",
      "dn": "Gilten",
      "bl": 3,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "03358011",
      "j": 2023,
      "n": "grethem",
      "dn": "Grethem",
      "bl": 3,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "03358012",
      "j": 2023,
      "n": "hademstorf",
      "dn": "Hademstorf",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03358013",
      "j": 2023,
      "n": "häuslingen",
      "dn": "Häuslingen",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03358014",
      "j": 2023,
      "n": "hodenhagen",
      "dn": "Hodenhagen",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03358015",
      "j": 2023,
      "n": "lindwedel",
      "dn": "Lindwedel",
      "bl": 3,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "03358016",
      "j": 2023,
      "n": "munster",
      "dn": "Munster",
      "bl": 3,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "03358017",
      "j": 2023,
      "n": "neuenkirchen",
      "dn": "Neuenkirchen",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03358018",
      "j": 2023,
      "n": "rethem (aller)",
      "dn": "Rethem (Aller)",
      "bl": 3,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "03358019",
      "j": 2023,
      "n": "schneverdingen",
      "dn": "Schneverdingen",
      "bl": 3,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "03358020",
      "j": 2023,
      "n": "schwarmstedt",
      "dn": "Schwarmstedt",
      "bl": 3,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "03358021",
      "j": 2023,
      "n": "soltau",
      "dn": "Soltau",
      "bl": 3,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "03358023",
      "j": 2023,
      "n": "wietzendorf",
      "dn": "Wietzendorf",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03358024",
      "j": 2023,
      "n": "walsrode",
      "dn": "Walsrode",
      "bl": 3,
      "hs": 375.0,
      "mns": 2
    },
    {
      "a": "03359001",
      "j": 2023,
      "n": "agathenburg",
      "dn": "Agathenburg",
      "bl": 3,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "03359002",
      "j": 2023,
      "n": "ahlerstedt",
      "dn": "Ahlerstedt",
      "bl": 3,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "03359003",
      "j": 2023,
      "n": "apensen",
      "dn": "Apensen",
      "bl": 3,
      "hs": 435.0,
      "mns": 2
    },
    {
      "a": "03359004",
      "j": 2023,
      "n": "balje",
      "dn": "Balje",
      "bl": 3,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "03359005",
      "j": 2023,
      "n": "bargstedt",
      "dn": "Bargstedt",
      "bl": 3,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "03359006",
      "j": 2023,
      "n": "beckdorf",
      "dn": "Beckdorf",
      "bl": 3,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "03359007",
      "j": 2023,
      "n": "bliedersdorf",
      "dn": "Bliedersdorf",
      "bl": 3,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "03359008",
      "j": 2023,
      "n": "brest",
      "dn": "Brest",
      "bl": 3,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "03359009",
      "j": 2023,
      "n": "burweg",
      "dn": "Burweg",
      "bl": 3,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "03359010",
      "j": 2023,
      "n": "buxtehude",
      "dn": "Buxtehude",
      "bl": 3,
      "hs": 490.0,
      "mns": 5
    },
    {
      "a": "03359011",
      "j": 2023,
      "n": "deinste",
      "dn": "Deinste",
      "bl": 3,
      "hs": 600.0,
      "mns": 2
    },
    {
      "a": "03359012",
      "j": 2023,
      "n": "dollern",
      "dn": "Dollern",
      "bl": 3,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "03359013",
      "j": 2023,
      "n": "drochtersen",
      "dn": "Drochtersen",
      "bl": 3,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "03359014",
      "j": 2023,
      "n": "düdenbüttel",
      "dn": "Düdenbüttel",
      "bl": 3,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "03359015",
      "j": 2023,
      "n": "engelschoff",
      "dn": "Engelschoff",
      "bl": 3,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "03359016",
      "j": 2023,
      "n": "estorf",
      "dn": "Estorf",
      "bl": 3,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "03359017",
      "j": 2023,
      "n": "fredenbeck",
      "dn": "Fredenbeck",
      "bl": 3,
      "hs": 480.0,
      "mns": 2
    },
    {
      "a": "03359018",
      "j": 2023,
      "n": "freiburg/elbe",
      "dn": "Freiburg/Elbe",
      "bl": 3,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "03359019",
      "j": 2023,
      "n": "großenwörden",
      "dn": "Großenwörden",
      "bl": 3,
      "hs": 460.0,
      "mns": 2
    },
    {
      "a": "03359020",
      "j": 2023,
      "n": "grünendeich",
      "dn": "Grünendeich",
      "bl": 3,
      "hs": 455.0,
      "mns": 2
    },
    {
      "a": "03359021",
      "j": 2023,
      "n": "guderhandviertel",
      "dn": "Guderhandviertel",
      "bl": 3,
      "hs": 455.0,
      "mns": 2
    },
    {
      "a": "03359022",
      "j": 2023,
      "n": "hammah",
      "dn": "Hammah",
      "bl": 3,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "03359023",
      "j": 2025,
      "n": "harsefeld",
      "dn": "Harsefeld",
      "bl": 3,
      "hs": 359.0,
      "mns": 3
    },
    {
      "a": "03359024",
      "j": 2024,
      "n": "heinbockel",
      "dn": "Heinbockel",
      "bl": 3,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "03359025",
      "j": 2024,
      "n": "himmelpforten",
      "dn": "Himmelpforten",
      "bl": 3,
      "hs": 490.0,
      "mns": 2
    },
    {
      "a": "03359026",
      "j": 2023,
      "n": "hollern-twielenfleth",
      "dn": "Hollern-Twielenfleth",
      "bl": 3,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "03359027",
      "j": 2025,
      "n": "horneburg",
      "dn": "Horneburg",
      "bl": 3,
      "hs": 530.0,
      "mns": 2
    },
    {
      "a": "03359028",
      "j": 2023,
      "n": "jork",
      "dn": "Jork",
      "bl": 3,
      "hs": 455.0,
      "mns": 4
    },
    {
      "a": "03359029",
      "j": 2023,
      "n": "kranenburg",
      "dn": "Kranenburg",
      "bl": 3,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "03359030",
      "j": 2023,
      "n": "krummendeich",
      "dn": "Krummendeich",
      "bl": 3,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "03359031",
      "j": 2023,
      "n": "kutenholz",
      "dn": "Kutenholz",
      "bl": 3,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "03359032",
      "j": 2023,
      "n": "mittelnkirchen",
      "dn": "Mittelnkirchen",
      "bl": 3,
      "hs": 495.0,
      "mns": 2
    },
    {
      "a": "03359033",
      "j": 2023,
      "n": "neuenkirchen",
      "dn": "Neuenkirchen",
      "bl": 3,
      "hs": 490.0,
      "mns": 2
    },
    {
      "a": "03359034",
      "j": 2023,
      "n": "nottensdorf",
      "dn": "Nottensdorf",
      "bl": 3,
      "hs": 500.0,
      "mns": 2
    },
    {
      "a": "03359035",
      "j": 2023,
      "n": "oederquart",
      "dn": "Oederquart",
      "bl": 3,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "03359036",
      "j": 2023,
      "n": "oldendorf",
      "dn": "Oldendorf",
      "bl": 3,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "03359037",
      "j": 2023,
      "n": "sauensiek",
      "dn": "Sauensiek",
      "bl": 3,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "03359038",
      "j": 2023,
      "n": "stade",
      "dn": "Stade",
      "bl": 3,
      "hs": 490.0,
      "mns": 4
    },
    {
      "a": "03359039",
      "j": 2023,
      "n": "steinkirchen",
      "dn": "Steinkirchen",
      "bl": 3,
      "hs": 495.0,
      "mns": 2
    },
    {
      "a": "03359040",
      "j": 2023,
      "n": "wischhafen",
      "dn": "Wischhafen",
      "bl": 3,
      "hs": 480.0,
      "mns": 2
    },
    {
      "a": "03360001",
      "j": 2023,
      "n": "altenmedingen",
      "dn": "Altenmedingen",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03360002",
      "j": 2023,
      "n": "bad bevensen",
      "dn": "Bad Bevensen",
      "bl": 3,
      "hs": 530.0,
      "mns": 1
    },
    {
      "a": "03360003",
      "j": 2023,
      "n": "barum",
      "dn": "Barum",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03360004",
      "j": 2023,
      "n": "bienenbüttel",
      "dn": "Bienenbüttel",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03360005",
      "j": 2023,
      "n": "bad bodenteich",
      "dn": "Bad Bodenteich",
      "bl": 3,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "03360006",
      "j": 2023,
      "n": "ebstorf",
      "dn": "Ebstorf",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03360007",
      "j": 2023,
      "n": "eimke",
      "dn": "Eimke",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03360008",
      "j": 2023,
      "n": "emmendorf",
      "dn": "Emmendorf",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03360009",
      "j": 2023,
      "n": "gerdau",
      "dn": "Gerdau",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03360010",
      "j": 2023,
      "n": "hanstedt",
      "dn": "Hanstedt",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03360011",
      "j": 2023,
      "n": "himbergen",
      "dn": "Himbergen",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03360012",
      "j": 2023,
      "n": "jelmstorf",
      "dn": "Jelmstorf",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03360013",
      "j": 2023,
      "n": "lüder",
      "dn": "Lüder",
      "bl": 3,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "03360014",
      "j": 2023,
      "n": "natendorf",
      "dn": "Natendorf",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03360015",
      "j": 2023,
      "n": "oetzen",
      "dn": "Oetzen",
      "bl": 3,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "03360016",
      "j": 2023,
      "n": "rätzlingen",
      "dn": "Rätzlingen",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03360017",
      "j": 2023,
      "n": "römstedt",
      "dn": "Römstedt",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03360018",
      "j": 2023,
      "n": "rosche",
      "dn": "Rosche",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03360019",
      "j": 2023,
      "n": "schwienau",
      "dn": "Schwienau",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03360020",
      "j": 2023,
      "n": "soltendieck",
      "dn": "Soltendieck",
      "bl": 3,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "03360022",
      "j": 2023,
      "n": "stoetze",
      "dn": "Stoetze",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03360023",
      "j": 2023,
      "n": "suderburg",
      "dn": "Suderburg",
      "bl": 3,
      "hs": 505.0,
      "mns": 1
    },
    {
      "a": "03360024",
      "j": 2023,
      "n": "suhlendorf",
      "dn": "Suhlendorf",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03360025",
      "j": 2023,
      "n": "uelzen",
      "dn": "Uelzen",
      "bl": 3,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "03360026",
      "j": 2023,
      "n": "weste",
      "dn": "Weste",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03360029",
      "j": 2023,
      "n": "wriedel",
      "dn": "Wriedel",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03360030",
      "j": 2023,
      "n": "wrestedt",
      "dn": "Wrestedt",
      "bl": 3,
      "hs": 560.0,
      "mns": 1
    },
    {
      "a": "03361001",
      "j": 2023,
      "n": "achim",
      "dn": "Achim",
      "bl": 3,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "03361002",
      "j": 2023,
      "n": "blender",
      "dn": "Blender",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03361003",
      "j": 2023,
      "n": "dörverden",
      "dn": "Dörverden",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03361004",
      "j": 2023,
      "n": "emtinghausen",
      "dn": "Emtinghausen",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03361005",
      "j": 2023,
      "n": "kirchlinteln",
      "dn": "Kirchlinteln",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03361006",
      "j": 2023,
      "n": "langwedel",
      "dn": "Langwedel",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03361008",
      "j": 2023,
      "n": "ottersberg",
      "dn": "Ottersberg",
      "bl": 3,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "03361009",
      "j": 2023,
      "n": "oyten",
      "dn": "Oyten",
      "bl": 3,
      "hs": 460.0,
      "mns": 2
    },
    {
      "a": "03361010",
      "j": 2023,
      "n": "riede",
      "dn": "Riede",
      "bl": 3,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "03361012",
      "j": 2023,
      "n": "verden (aller)",
      "dn": "Verden (Aller)",
      "bl": 3,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "03361013",
      "j": 2023,
      "n": "thedinghausen",
      "dn": "Thedinghausen",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03401000",
      "j": 2023,
      "n": "delmenhorst",
      "dn": "Delmenhorst",
      "bl": 3,
      "hs": 530.0,
      "mns": 3
    },
    {
      "a": "03402000",
      "j": 2023,
      "n": "emden",
      "dn": "Emden",
      "bl": 3,
      "hs": 480.0,
      "mns": 2
    },
    {
      "a": "03403000",
      "j": 2025,
      "n": "oldenburg",
      "dn": "Oldenburg",
      "bl": 3,
      "hs": 539.0,
      "mns": 4
    },
    {
      "a": "03404000",
      "j": 2023,
      "n": "osnabrück",
      "dn": "Osnabrück",
      "bl": 3,
      "hs": 460.0,
      "mns": 3
    },
    {
      "a": "03405000",
      "j": 2023,
      "n": "wilhelmshaven",
      "dn": "Wilhelmshaven",
      "bl": 3,
      "hs": 600.0,
      "mns": 2
    },
    {
      "a": "03451001",
      "j": 2023,
      "n": "apen",
      "dn": "Apen",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03451002",
      "j": 2023,
      "n": "bad zwischenahn",
      "dn": "Bad Zwischenahn",
      "bl": 3,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "03451004",
      "j": 2023,
      "n": "edewecht",
      "dn": "Edewecht",
      "bl": 3,
      "hs": 335.0,
      "mns": 2
    },
    {
      "a": "03451005",
      "j": 2023,
      "n": "rastede",
      "dn": "Rastede",
      "bl": 3,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "03451007",
      "j": 2023,
      "n": "westerstede",
      "dn": "Westerstede",
      "bl": 3,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "03451008",
      "j": 2023,
      "n": "wiefelstede",
      "dn": "Wiefelstede",
      "bl": 3,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "03452001",
      "j": 2023,
      "n": "aurich",
      "dn": "Aurich",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03452002",
      "j": 2023,
      "n": "baltrum",
      "dn": "Baltrum",
      "bl": 3,
      "hs": 600.0,
      "mns": 5
    },
    {
      "a": "03452003",
      "j": 2023,
      "n": "berumbur",
      "dn": "Berumbur",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03452006",
      "j": 2023,
      "n": "großefehn",
      "dn": "Großefehn",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03452007",
      "j": 2023,
      "n": "großheide",
      "dn": "Großheide",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03452008",
      "j": 2023,
      "n": "hage",
      "dn": "Hage",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03452009",
      "j": 2023,
      "n": "hagermarsch",
      "dn": "Hagermarsch",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03452010",
      "j": 2023,
      "n": "halbemond",
      "dn": "Halbemond",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03452011",
      "j": 2023,
      "n": "hinte",
      "dn": "Hinte",
      "bl": 3,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "03452012",
      "j": 2023,
      "n": "ihlow",
      "dn": "Ihlow",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03452013",
      "j": 2023,
      "n": "juist",
      "dn": "Juist",
      "bl": 3,
      "hs": 390.0,
      "mns": 5
    },
    {
      "a": "03452014",
      "j": 2023,
      "n": "krummhörn",
      "dn": "Krummhörn",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03452015",
      "j": 2023,
      "n": "leezdorf",
      "dn": "Leezdorf",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03452016",
      "j": 2023,
      "n": "lütetsburg",
      "dn": "Lütetsburg",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03452017",
      "j": 2023,
      "n": "marienhafe",
      "dn": "Marienhafe",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03452019",
      "j": 2023,
      "n": "norden",
      "dn": "Norden",
      "bl": 3,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "03452020",
      "j": 2024,
      "n": "norderney",
      "dn": "Norderney",
      "bl": 3,
      "hs": 380.0,
      "mns": 5
    },
    {
      "a": "03452021",
      "j": 2023,
      "n": "osteel",
      "dn": "Osteel",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03452022",
      "j": 2023,
      "n": "rechtsupweg",
      "dn": "Rechtsupweg",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03452023",
      "j": 2023,
      "n": "südbrookmerland",
      "dn": "Südbrookmerland",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03452024",
      "j": 2023,
      "n": "upgant-schott",
      "dn": "Upgant-Schott",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03452025",
      "j": 2023,
      "n": "wiesmoor",
      "dn": "Wiesmoor",
      "bl": 3,
      "hs": 383.0,
      "mns": 1
    },
    {
      "a": "03452026",
      "j": 2023,
      "n": "wirdum",
      "dn": "Wirdum",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03452027",
      "j": 2023,
      "n": "dornum",
      "dn": "Dornum",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03453001",
      "j": 2023,
      "n": "barßel",
      "dn": "Barßel",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03453002",
      "j": 2023,
      "n": "bösel",
      "dn": "Bösel",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03453003",
      "j": 2023,
      "n": "cappeln",
      "dn": "Cappeln",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03453004",
      "j": 2025,
      "n": "cloppenburg",
      "dn": "Cloppenburg",
      "bl": 3,
      "hs": 369.0,
      "mns": 2
    },
    {
      "a": "03453005",
      "j": 2023,
      "n": "emstek",
      "dn": "Emstek",
      "bl": 3,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "03453006",
      "j": 2023,
      "n": "essen (oldenburg)",
      "dn": "Essen (Oldenburg)",
      "bl": 3,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "03453007",
      "j": 2023,
      "n": "friesoythe",
      "dn": "Friesoythe",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03453008",
      "j": 2023,
      "n": "garrel",
      "dn": "Garrel",
      "bl": 3,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "03453009",
      "j": 2023,
      "n": "lastrup",
      "dn": "Lastrup",
      "bl": 3,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "03453010",
      "j": 2023,
      "n": "lindern",
      "dn": "Lindern",
      "bl": 3,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "03453011",
      "j": 2023,
      "n": "löningen",
      "dn": "Löningen",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03453012",
      "j": 2023,
      "n": "molbergen",
      "dn": "Molbergen",
      "bl": 3,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "03453013",
      "j": 2023,
      "n": "saterland",
      "dn": "Saterland",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03454001",
      "j": 2023,
      "n": "andervenne",
      "dn": "Andervenne",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03454002",
      "j": 2023,
      "n": "bawinkel",
      "dn": "Bawinkel",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03454003",
      "j": 2023,
      "n": "beesten",
      "dn": "Beesten",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03454004",
      "j": 2023,
      "n": "bockhorst",
      "dn": "Bockhorst",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03454005",
      "j": 2023,
      "n": "börger",
      "dn": "Börger",
      "bl": 3,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "03454006",
      "j": 2023,
      "n": "breddenberg",
      "dn": "Breddenberg",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03454007",
      "j": 2023,
      "n": "dersum",
      "dn": "Dersum",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03454008",
      "j": 2023,
      "n": "dörpen",
      "dn": "Dörpen",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03454009",
      "j": 2023,
      "n": "dohren",
      "dn": "Dohren",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03454010",
      "j": 2023,
      "n": "emsbüren",
      "dn": "Emsbüren",
      "bl": 3,
      "hs": 345.0,
      "mns": 1
    },
    {
      "a": "03454011",
      "j": 2023,
      "n": "esterwegen",
      "dn": "Esterwegen",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03454012",
      "j": 2023,
      "n": "freren",
      "dn": "Freren",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03454013",
      "j": 2023,
      "n": "fresenburg",
      "dn": "Fresenburg",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03454014",
      "j": 2023,
      "n": "geeste",
      "dn": "Geeste",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03454015",
      "j": 2023,
      "n": "gersten",
      "dn": "Gersten",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03454016",
      "j": 2023,
      "n": "groß berßen",
      "dn": "Groß Berßen",
      "bl": 3,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "03454017",
      "j": 2023,
      "n": "handrup",
      "dn": "Handrup",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03454018",
      "j": 2023,
      "n": "haren",
      "dn": "Haren",
      "bl": 3,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "03454019",
      "j": 2023,
      "n": "haselünne",
      "dn": "Haselünne",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03454020",
      "j": 2023,
      "n": "heede",
      "dn": "Heede",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03454021",
      "j": 2023,
      "n": "herzlake",
      "dn": "Herzlake",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03454022",
      "j": 2023,
      "n": "hilkenbrook",
      "dn": "Hilkenbrook",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03454023",
      "j": 2023,
      "n": "hüven",
      "dn": "Hüven",
      "bl": 3,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "03454024",
      "j": 2023,
      "n": "klein berßen",
      "dn": "Klein Berßen",
      "bl": 3,
      "hs": 373.0,
      "mns": 1
    },
    {
      "a": "03454025",
      "j": 2023,
      "n": "kluse",
      "dn": "Kluse",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03454026",
      "j": 2023,
      "n": "lähden",
      "dn": "Lähden",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03454027",
      "j": 2023,
      "n": "lahn",
      "dn": "Lahn",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03454028",
      "j": 2023,
      "n": "langen",
      "dn": "Langen",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03454029",
      "j": 2023,
      "n": "lathen",
      "dn": "Lathen",
      "bl": 3,
      "hs": 455.0,
      "mns": 1
    },
    {
      "a": "03454030",
      "j": 2023,
      "n": "lehe",
      "dn": "Lehe",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03454031",
      "j": 2023,
      "n": "lengerich",
      "dn": "Lengerich",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03454032",
      "j": 2023,
      "n": "lingen (ems)",
      "dn": "Lingen (Ems)",
      "bl": 3,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "03454033",
      "j": 2023,
      "n": "lorup",
      "dn": "Lorup",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03454034",
      "j": 2023,
      "n": "lünne",
      "dn": "Lünne",
      "bl": 3,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "03454035",
      "j": 2023,
      "n": "meppen",
      "dn": "Meppen",
      "bl": 3,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "03454036",
      "j": 2023,
      "n": "messingen",
      "dn": "Messingen",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03454037",
      "j": 2023,
      "n": "neubörger",
      "dn": "Neubörger",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03454038",
      "j": 2023,
      "n": "neulehe",
      "dn": "Neulehe",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03454039",
      "j": 2023,
      "n": "niederlangen",
      "dn": "Niederlangen",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03454040",
      "j": 2023,
      "n": "oberlangen",
      "dn": "Oberlangen",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03454041",
      "j": 2023,
      "n": "papenburg",
      "dn": "Papenburg",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03454042",
      "j": 2023,
      "n": "rastdorf",
      "dn": "Rastdorf",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03454043",
      "j": 2023,
      "n": "renkenberge",
      "dn": "Renkenberge",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03454044",
      "j": 2023,
      "n": "rhede",
      "dn": "Rhede",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03454045",
      "j": 2023,
      "n": "salzbergen",
      "dn": "Salzbergen",
      "bl": 3,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "03454046",
      "j": 2023,
      "n": "schapen",
      "dn": "Schapen",
      "bl": 3,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "03454047",
      "j": 2023,
      "n": "sögel",
      "dn": "Sögel",
      "bl": 3,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "03454048",
      "j": 2023,
      "n": "spahnharrenstätte",
      "dn": "Spahnharrenstätte",
      "bl": 3,
      "hs": 367.0,
      "mns": 1
    },
    {
      "a": "03454049",
      "j": 2023,
      "n": "spelle",
      "dn": "Spelle",
      "bl": 3,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "03454050",
      "j": 2023,
      "n": "stavern",
      "dn": "Stavern",
      "bl": 3,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "03454051",
      "j": 2023,
      "n": "surwold",
      "dn": "Surwold",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03454052",
      "j": 2023,
      "n": "sustrum",
      "dn": "Sustrum",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03454053",
      "j": 2023,
      "n": "thuine",
      "dn": "Thuine",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03454054",
      "j": 2023,
      "n": "twist",
      "dn": "Twist",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03454055",
      "j": 2023,
      "n": "vrees",
      "dn": "Vrees",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03454056",
      "j": 2023,
      "n": "walchum",
      "dn": "Walchum",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03454057",
      "j": 2023,
      "n": "werlte",
      "dn": "Werlte",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03454058",
      "j": 2023,
      "n": "werpeloh",
      "dn": "Werpeloh",
      "bl": 3,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "03454059",
      "j": 2023,
      "n": "wettrup",
      "dn": "Wettrup",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03454060",
      "j": 2023,
      "n": "wippingen",
      "dn": "Wippingen",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03455007",
      "j": 2023,
      "n": "jever",
      "dn": "Jever",
      "bl": 3,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "03455014",
      "j": 2023,
      "n": "sande",
      "dn": "Sande",
      "bl": 3,
      "hs": 650.0,
      "mns": 1
    },
    {
      "a": "03455015",
      "j": 2023,
      "n": "schortens",
      "dn": "Schortens",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03455020",
      "j": 2023,
      "n": "wangerland",
      "dn": "Wangerland",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03455021",
      "j": 2023,
      "n": "wangerooge",
      "dn": "Wangerooge",
      "bl": 3,
      "hs": 550.0,
      "mns": 5
    },
    {
      "a": "03455025",
      "j": 2023,
      "n": "bockhorn",
      "dn": "Bockhorn",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03455026",
      "j": 2023,
      "n": "varel",
      "dn": "Varel",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03455027",
      "j": 2023,
      "n": "zetel",
      "dn": "Zetel",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03456001",
      "j": 2023,
      "n": "bad bentheim",
      "dn": "Bad Bentheim",
      "bl": 3,
      "hs": 445.0,
      "mns": 2
    },
    {
      "a": "03456002",
      "j": 2023,
      "n": "emlichheim",
      "dn": "Emlichheim",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03456003",
      "j": 2023,
      "n": "engden",
      "dn": "Engden",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03456004",
      "j": 2023,
      "n": "esche",
      "dn": "Esche",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03456005",
      "j": 2023,
      "n": "georgsdorf",
      "dn": "Georgsdorf",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03456006",
      "j": 2023,
      "n": "getelo",
      "dn": "Getelo",
      "bl": 3,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "03456007",
      "j": 2023,
      "n": "gölenkamp",
      "dn": "Gölenkamp",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03456008",
      "j": 2023,
      "n": "halle",
      "dn": "Halle",
      "bl": 3,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "03456009",
      "j": 2023,
      "n": "hoogstede",
      "dn": "Hoogstede",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03456010",
      "j": 2023,
      "n": "isterberg",
      "dn": "Isterberg",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03456011",
      "j": 2023,
      "n": "itterbeck",
      "dn": "Itterbeck",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03456012",
      "j": 2023,
      "n": "laar",
      "dn": "Laar",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03456013",
      "j": 2023,
      "n": "lage",
      "dn": "Lage",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03456014",
      "j": 2023,
      "n": "neuenhaus",
      "dn": "Neuenhaus",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03456015",
      "j": 2023,
      "n": "nordhorn",
      "dn": "Nordhorn",
      "bl": 3,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "03456016",
      "j": 2023,
      "n": "ohne",
      "dn": "Ohne",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03456017",
      "j": 2023,
      "n": "osterwald",
      "dn": "Osterwald",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03456018",
      "j": 2023,
      "n": "quendorf",
      "dn": "Quendorf",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03456019",
      "j": 2023,
      "n": "ringe",
      "dn": "Ringe",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03456020",
      "j": 2023,
      "n": "samern",
      "dn": "Samern",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03456023",
      "j": 2023,
      "n": "uelsen",
      "dn": "Uelsen",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03456024",
      "j": 2023,
      "n": "wielen",
      "dn": "Wielen",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03456025",
      "j": 2023,
      "n": "wietmarschen",
      "dn": "Wietmarschen",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03456026",
      "j": 2023,
      "n": "wilsum",
      "dn": "Wilsum",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03456027",
      "j": 2023,
      "n": "schüttorf",
      "dn": "Schüttorf",
      "bl": 3,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "03457002",
      "j": 2023,
      "n": "borkum",
      "dn": "Borkum",
      "bl": 3,
      "hs": 460.0,
      "mns": 5
    },
    {
      "a": "03457003",
      "j": 2023,
      "n": "brinkum",
      "dn": "Brinkum",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03457006",
      "j": 2023,
      "n": "detern",
      "dn": "Detern",
      "bl": 3,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "03457008",
      "j": 2023,
      "n": "filsum",
      "dn": "Filsum",
      "bl": 3,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "03457009",
      "j": 2023,
      "n": "firrel",
      "dn": "Firrel",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03457010",
      "j": 2023,
      "n": "hesel",
      "dn": "Hesel",
      "bl": 3,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "03457011",
      "j": 2023,
      "n": "holtland",
      "dn": "Holtland",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03457012",
      "j": 2023,
      "n": "jemgum",
      "dn": "Jemgum",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03457013",
      "j": 2023,
      "n": "leer",
      "dn": "Leer",
      "bl": 3,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "03457014",
      "j": 2023,
      "n": "moormerland",
      "dn": "Moormerland",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03457015",
      "j": 2023,
      "n": "neukamperfehn",
      "dn": "Neukamperfehn",
      "bl": 3,
      "hs": 560.0,
      "mns": 1
    },
    {
      "a": "03457016",
      "j": 2023,
      "n": "nortmoor",
      "dn": "Nortmoor",
      "bl": 3,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "03457017",
      "j": 2023,
      "n": "ostrhauderfehn",
      "dn": "Ostrhauderfehn",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03457018",
      "j": 2023,
      "n": "rhauderfehn",
      "dn": "Rhauderfehn",
      "bl": 3,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "03457019",
      "j": 2023,
      "n": "schwerinsdorf",
      "dn": "Schwerinsdorf",
      "bl": 3,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "03457020",
      "j": 2023,
      "n": "uplengen",
      "dn": "Uplengen",
      "bl": 3,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "03457021",
      "j": 2023,
      "n": "weener",
      "dn": "Weener",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03457022",
      "j": 2023,
      "n": "westoverledingen",
      "dn": "Westoverledingen",
      "bl": 3,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "03457024",
      "j": 2023,
      "n": "bunde",
      "dn": "Bunde",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03458001",
      "j": 2023,
      "n": "beckeln",
      "dn": "Beckeln",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03458002",
      "j": 2023,
      "n": "colnrade",
      "dn": "Colnrade",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03458003",
      "j": 2023,
      "n": "dötlingen",
      "dn": "Dötlingen",
      "bl": 3,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "03458004",
      "j": 2023,
      "n": "dünsen",
      "dn": "Dünsen",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03458005",
      "j": 2023,
      "n": "ganderkesee",
      "dn": "Ganderkesee",
      "bl": 3,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "03458006",
      "j": 2023,
      "n": "groß ippener",
      "dn": "Groß Ippener",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03458007",
      "j": 2023,
      "n": "großenkneten",
      "dn": "Großenkneten",
      "bl": 3,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "03458008",
      "j": 2023,
      "n": "harpstedt",
      "dn": "Harpstedt",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03458009",
      "j": 2023,
      "n": "hatten",
      "dn": "Hatten",
      "bl": 3,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "03458010",
      "j": 2023,
      "n": "hude",
      "dn": "Hude",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03458011",
      "j": 2023,
      "n": "kirchseelte",
      "dn": "Kirchseelte",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03458012",
      "j": 2023,
      "n": "prinzhöfte",
      "dn": "Prinzhöfte",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03458013",
      "j": 2023,
      "n": "wardenburg",
      "dn": "Wardenburg",
      "bl": 3,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "03458014",
      "j": 2023,
      "n": "wildeshausen",
      "dn": "Wildeshausen",
      "bl": 3,
      "hs": 355.0,
      "mns": 2
    },
    {
      "a": "03458015",
      "j": 2023,
      "n": "winkelsett",
      "dn": "Winkelsett",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03459001",
      "j": 2023,
      "n": "alfhausen",
      "dn": "Alfhausen",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03459002",
      "j": 2023,
      "n": "ankum",
      "dn": "Ankum",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03459003",
      "j": 2023,
      "n": "bad essen",
      "dn": "Bad Essen",
      "bl": 3,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "03459004",
      "j": 2023,
      "n": "bad iburg",
      "dn": "Bad Iburg",
      "bl": 3,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "03459005",
      "j": 2023,
      "n": "bad laer",
      "dn": "Bad Laer",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03459006",
      "j": 2023,
      "n": "bad rothenfelde",
      "dn": "Bad Rothenfelde",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03459007",
      "j": 2023,
      "n": "badbergen",
      "dn": "Badbergen",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03459008",
      "j": 2023,
      "n": "belm",
      "dn": "Belm",
      "bl": 3,
      "hs": 379.0,
      "mns": 1
    },
    {
      "a": "03459009",
      "j": 2023,
      "n": "berge",
      "dn": "Berge",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03459010",
      "j": 2023,
      "n": "bersenbrück",
      "dn": "Bersenbrück",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03459011",
      "j": 2023,
      "n": "bippen",
      "dn": "Bippen",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03459012",
      "j": 2023,
      "n": "bissendorf",
      "dn": "Bissendorf",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03459013",
      "j": 2023,
      "n": "bohmte",
      "dn": "Bohmte",
      "bl": 3,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "03459014",
      "j": 2023,
      "n": "bramsche",
      "dn": "Bramsche",
      "bl": 3,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "03459015",
      "j": 2023,
      "n": "dissen am teutoburger wald",
      "dn": "Dissen am Teutoburger Wald",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03459016",
      "j": 2023,
      "n": "eggermühlen",
      "dn": "Eggermühlen",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03459017",
      "j": 2023,
      "n": "fürstenau",
      "dn": "Fürstenau",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03459018",
      "j": 2023,
      "n": "gehrde",
      "dn": "Gehrde",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03459019",
      "j": 2023,
      "n": "georgsmarienhütte",
      "dn": "Georgsmarienhütte",
      "bl": 3,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "03459020",
      "j": 2023,
      "n": "hagen am teutoburger wald",
      "dn": "Hagen am Teutoburger Wald",
      "bl": 3,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "03459021",
      "j": 2023,
      "n": "hasbergen",
      "dn": "Hasbergen",
      "bl": 3,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "03459022",
      "j": 2023,
      "n": "hilter am teutoburger wald",
      "dn": "Hilter am Teutoburger Wald",
      "bl": 3,
      "hs": 345.0,
      "mns": 1
    },
    {
      "a": "03459023",
      "j": 2023,
      "n": "kettenkamp",
      "dn": "Kettenkamp",
      "bl": 3,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "03459024",
      "j": 2023,
      "n": "melle",
      "dn": "Melle",
      "bl": 3,
      "hs": 345.0,
      "mns": 1
    },
    {
      "a": "03459025",
      "j": 2023,
      "n": "menslage",
      "dn": "Menslage",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03459026",
      "j": 2023,
      "n": "merzen",
      "dn": "Merzen",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03459027",
      "j": 2023,
      "n": "neuenkirchen",
      "dn": "Neuenkirchen",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03459028",
      "j": 2023,
      "n": "nortrup",
      "dn": "Nortrup",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03459029",
      "j": 2023,
      "n": "ostercappeln",
      "dn": "Ostercappeln",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03459030",
      "j": 2023,
      "n": "quakenbrück",
      "dn": "Quakenbrück",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03459031",
      "j": 2023,
      "n": "rieste",
      "dn": "Rieste",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03459032",
      "j": 2023,
      "n": "voltlage",
      "dn": "Voltlage",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03459033",
      "j": 2023,
      "n": "wallenhorst",
      "dn": "Wallenhorst",
      "bl": 3,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "03459034",
      "j": 2023,
      "n": "glandorf",
      "dn": "Glandorf",
      "bl": 3,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "03460001",
      "j": 2023,
      "n": "bakum",
      "dn": "Bakum",
      "bl": 3,
      "hs": 290.0,
      "mns": 1
    },
    {
      "a": "03460002",
      "j": 2023,
      "n": "damme",
      "dn": "Damme",
      "bl": 3,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "03460003",
      "j": 2023,
      "n": "dinklage",
      "dn": "Dinklage",
      "bl": 3,
      "hs": 426.0,
      "mns": 1
    },
    {
      "a": "03460004",
      "j": 2023,
      "n": "goldenstedt",
      "dn": "Goldenstedt",
      "bl": 3,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "03460005",
      "j": 2023,
      "n": "holdorf",
      "dn": "Holdorf",
      "bl": 3,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "03460006",
      "j": 2025,
      "n": "lohne",
      "dn": "Lohne",
      "bl": 3,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "03460007",
      "j": 2023,
      "n": "neuenkirchen-vörden",
      "dn": "Neuenkirchen-Vörden",
      "bl": 3,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "03460008",
      "j": 2023,
      "n": "steinfeld",
      "dn": "Steinfeld",
      "bl": 3,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "03460009",
      "j": 2023,
      "n": "vechta",
      "dn": "Vechta",
      "bl": 3,
      "hs": 280.0,
      "mns": 2
    },
    {
      "a": "03460010",
      "j": 2023,
      "n": "visbek",
      "dn": "Visbek",
      "bl": 3,
      "hs": 295.0,
      "mns": 1
    },
    {
      "a": "03461001",
      "j": 2023,
      "n": "berne",
      "dn": "Berne",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03461002",
      "j": 2023,
      "n": "brake",
      "dn": "Brake",
      "bl": 3,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "03461003",
      "j": 2023,
      "n": "butjadingen",
      "dn": "Butjadingen",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03461004",
      "j": 2023,
      "n": "elsfleth",
      "dn": "Elsfleth",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03461005",
      "j": 2023,
      "n": "jade",
      "dn": "Jade",
      "bl": 3,
      "hs": 495.0,
      "mns": 1
    },
    {
      "a": "03461006",
      "j": 2023,
      "n": "lemwerder",
      "dn": "Lemwerder",
      "bl": 3,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "03461007",
      "j": 2023,
      "n": "nordenham",
      "dn": "Nordenham",
      "bl": 3,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "03461008",
      "j": 2023,
      "n": "ovelgönne",
      "dn": "Ovelgönne",
      "bl": 3,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "03461009",
      "j": 2023,
      "n": "stadland",
      "dn": "Stadland",
      "bl": 3,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "03462001",
      "j": 2023,
      "n": "blomberg",
      "dn": "Blomberg",
      "bl": 3,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "03462002",
      "j": 2023,
      "n": "dunum",
      "dn": "Dunum",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03462003",
      "j": 2023,
      "n": "esens",
      "dn": "Esens",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03462004",
      "j": 2023,
      "n": "eversmeer",
      "dn": "Eversmeer",
      "bl": 3,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "03462005",
      "j": 2025,
      "n": "friedeburg",
      "dn": "Friedeburg",
      "bl": 3,
      "hs": 213.0,
      "mns": 1
    },
    {
      "a": "03462006",
      "j": 2023,
      "n": "holtgast",
      "dn": "Holtgast",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03462007",
      "j": 2023,
      "n": "langeoog",
      "dn": "Langeoog",
      "bl": 3,
      "hs": 440.0,
      "mns": 5
    },
    {
      "a": "03462008",
      "j": 2023,
      "n": "moorweg",
      "dn": "Moorweg",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03462009",
      "j": 2023,
      "n": "nenndorf",
      "dn": "Nenndorf",
      "bl": 3,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "03462010",
      "j": 2023,
      "n": "neuharlingersiel",
      "dn": "Neuharlingersiel",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03462011",
      "j": 2023,
      "n": "neuschoo",
      "dn": "Neuschoo",
      "bl": 3,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "03462012",
      "j": 2023,
      "n": "ochtersum",
      "dn": "Ochtersum",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "03462013",
      "j": 2023,
      "n": "schweindorf",
      "dn": "Schweindorf",
      "bl": 3,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "03462014",
      "j": 2023,
      "n": "spiekeroog",
      "dn": "Spiekeroog",
      "bl": 3,
      "hs": 600.0,
      "mns": 5
    },
    {
      "a": "03462015",
      "j": 2023,
      "n": "stedesdorf",
      "dn": "Stedesdorf",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03462016",
      "j": 2023,
      "n": "utarp",
      "dn": "Utarp",
      "bl": 3,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "03462017",
      "j": 2023,
      "n": "werdum",
      "dn": "Werdum",
      "bl": 3,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "03462018",
      "j": 2023,
      "n": "westerholt",
      "dn": "Westerholt",
      "bl": 3,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "03462019",
      "j": 2023,
      "n": "wittmund",
      "dn": "Wittmund",
      "bl": 3,
      "hs": 380.0,
      "mns": 1
    }
  ],
  "4": [
    {
      "a": "04011000",
      "j": 2025,
      "n": "bremen",
      "dn": "Bremen",
      "bl": 4,
      "hs": 755.0,
      "mns": 4
    },
    {
      "a": "04012000",
      "j": 2025,
      "n": "bremerhaven",
      "dn": "Bremerhaven",
      "bl": 4,
      "hs": 896.0,
      "mns": 2
    }
  ],
  "5": [
    {
      "a": "05111000",
      "j": 2024,
      "n": "düsseldorf",
      "dn": "Düsseldorf",
      "bl": 5,
      "hs": 440.0,
      "mns": 6
    },
    {
      "a": "05112000",
      "j": 2025,
      "n": "duisburg",
      "dn": "Duisburg",
      "bl": 5,
      "hs": 886.0,
      "mns": 3
    },
    {
      "a": "05113000",
      "j": 2025,
      "n": "essen",
      "dn": "Essen",
      "bl": 5,
      "hs": 655.0,
      "mns": 4
    },
    {
      "a": "05114000",
      "j": 2024,
      "n": "krefeld",
      "dn": "Krefeld",
      "bl": 5,
      "hs": 533.0,
      "mns": 4
    },
    {
      "a": "05116000",
      "j": 2025,
      "n": "mönchengladbach",
      "dn": "Mönchengladbach",
      "bl": 5,
      "hs": 792.0,
      "mns": 3
    },
    {
      "a": "05117000",
      "j": 2024,
      "n": "mülheim an der ruhr",
      "dn": "Mülheim an der Ruhr",
      "bl": 5,
      "hs": 890.0,
      "mns": 4
    },
    {
      "a": "05119000",
      "j": 2024,
      "n": "oberhausen",
      "dn": "Oberhausen",
      "bl": 5,
      "hs": 670.0,
      "mns": 3
    },
    {
      "a": "05120000",
      "j": 2024,
      "n": "remscheid",
      "dn": "Remscheid",
      "bl": 5,
      "hs": 770.0,
      "mns": 3
    },
    {
      "a": "05122000",
      "j": 2025,
      "n": "solingen",
      "dn": "Solingen",
      "bl": 5,
      "hs": 805.0,
      "mns": 4
    },
    {
      "a": "05124000",
      "j": 2024,
      "n": "wuppertal",
      "dn": "Wuppertal",
      "bl": 5,
      "hs": 620.0,
      "mns": 3
    },
    {
      "a": "05154004",
      "j": 2024,
      "n": "bedburg-hau",
      "dn": "Bedburg-Hau",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05154008",
      "j": 2024,
      "n": "emmerich am rhein",
      "dn": "Emmerich am Rhein",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05154012",
      "j": 2024,
      "n": "geldern",
      "dn": "Geldern",
      "bl": 5,
      "hs": 518.0,
      "mns": 3
    },
    {
      "a": "05154016",
      "j": 2024,
      "n": "goch",
      "dn": "Goch",
      "bl": 5,
      "hs": 550.0,
      "mns": 2
    },
    {
      "a": "05154020",
      "j": 2024,
      "n": "issum",
      "dn": "Issum",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05154024",
      "j": 2024,
      "n": "kalkar",
      "dn": "Kalkar",
      "bl": 5,
      "hs": 550.0,
      "mns": 2
    },
    {
      "a": "05154028",
      "j": 2025,
      "n": "kerken",
      "dn": "Kerken",
      "bl": 5,
      "hs": 555.0,
      "mns": 2
    },
    {
      "a": "05154032",
      "j": 2025,
      "n": "kevelaer",
      "dn": "Kevelaer",
      "bl": 5,
      "hs": 810.0,
      "mns": 2
    },
    {
      "a": "05154036",
      "j": 2024,
      "n": "kleve",
      "dn": "Kleve",
      "bl": 5,
      "hs": 501.0,
      "mns": 3
    },
    {
      "a": "05154040",
      "j": 2024,
      "n": "kranenburg",
      "dn": "Kranenburg",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05154044",
      "j": 2024,
      "n": "rees",
      "dn": "Rees",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05154048",
      "j": 2024,
      "n": "rheurdt",
      "dn": "Rheurdt",
      "bl": 5,
      "hs": 593.0,
      "mns": 2
    },
    {
      "a": "05154052",
      "j": 2024,
      "n": "straelen",
      "dn": "Straelen",
      "bl": 5,
      "hs": 429.0,
      "mns": 2
    },
    {
      "a": "05154056",
      "j": 2024,
      "n": "uedem",
      "dn": "Uedem",
      "bl": 5,
      "hs": 493.0,
      "mns": 2
    },
    {
      "a": "05154060",
      "j": 2024,
      "n": "wachtendonk",
      "dn": "Wachtendonk",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05154064",
      "j": 2024,
      "n": "weeze",
      "dn": "Weeze",
      "bl": 5,
      "hs": 460.0,
      "mns": 2
    },
    {
      "a": "05158004",
      "j": 2024,
      "n": "erkrath",
      "dn": "Erkrath",
      "bl": 5,
      "hs": 652.0,
      "mns": 4
    },
    {
      "a": "05158008",
      "j": 2024,
      "n": "haan",
      "dn": "Haan",
      "bl": 5,
      "hs": 510.0,
      "mns": 4
    },
    {
      "a": "05158012",
      "j": 2024,
      "n": "heiligenhaus",
      "dn": "Heiligenhaus",
      "bl": 5,
      "hs": 680.0,
      "mns": 4
    },
    {
      "a": "05158016",
      "j": 2024,
      "n": "hilden",
      "dn": "Hilden",
      "bl": 5,
      "hs": 580.0,
      "mns": 5
    },
    {
      "a": "05158020",
      "j": 2024,
      "n": "langenfeld",
      "dn": "Langenfeld",
      "bl": 5,
      "hs": 360.0,
      "mns": 4
    },
    {
      "a": "05158024",
      "j": 2024,
      "n": "mettmann",
      "dn": "Mettmann",
      "bl": 5,
      "hs": 745.0,
      "mns": 4
    },
    {
      "a": "05158026",
      "j": 2025,
      "n": "monheim am rhein",
      "dn": "Monheim am Rhein",
      "bl": 5,
      "hs": 1000.0,
      "mns": 5
    },
    {
      "a": "05158028",
      "j": 2024,
      "n": "ratingen",
      "dn": "Ratingen",
      "bl": 5,
      "hs": 440.0,
      "mns": 5
    },
    {
      "a": "05158032",
      "j": 2024,
      "n": "velbert",
      "dn": "Velbert",
      "bl": 5,
      "hs": 650.0,
      "mns": 3
    },
    {
      "a": "05158036",
      "j": 2024,
      "n": "wülfrath",
      "dn": "Wülfrath",
      "bl": 5,
      "hs": 720.0,
      "mns": 3
    },
    {
      "a": "05162004",
      "j": 2024,
      "n": "dormagen",
      "dn": "Dormagen",
      "bl": 5,
      "hs": 595.0,
      "mns": 4
    },
    {
      "a": "05162008",
      "j": 2024,
      "n": "grevenbroich",
      "dn": "Grevenbroich",
      "bl": 5,
      "hs": 625.0,
      "mns": 4
    },
    {
      "a": "05162012",
      "j": 2025,
      "n": "jüchen",
      "dn": "Jüchen",
      "bl": 5,
      "hs": 695.0,
      "mns": 3
    },
    {
      "a": "05162016",
      "j": 2024,
      "n": "kaarst",
      "dn": "Kaarst",
      "bl": 5,
      "hs": 504.0,
      "mns": 5
    },
    {
      "a": "05162020",
      "j": 2024,
      "n": "korschenbroich",
      "dn": "Korschenbroich",
      "bl": 5,
      "hs": 690.0,
      "mns": 3
    },
    {
      "a": "05162022",
      "j": 2024,
      "n": "meerbusch",
      "dn": "Meerbusch",
      "bl": 5,
      "hs": 480.0,
      "mns": 5
    },
    {
      "a": "05162024",
      "j": 2024,
      "n": "neuss",
      "dn": "Neuss",
      "bl": 5,
      "hs": 495.0,
      "mns": 4
    },
    {
      "a": "05162028",
      "j": 2024,
      "n": "rommerskirchen",
      "dn": "Rommerskirchen",
      "bl": 5,
      "hs": 493.0,
      "mns": 3
    },
    {
      "a": "05166004",
      "j": 2024,
      "n": "brüggen",
      "dn": "Brüggen",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05166008",
      "j": 2024,
      "n": "grefrath",
      "dn": "Grefrath",
      "bl": 5,
      "hs": 525.0,
      "mns": 3
    },
    {
      "a": "05166012",
      "j": 2024,
      "n": "kempen",
      "dn": "Kempen",
      "bl": 5,
      "hs": 501.0,
      "mns": 3
    },
    {
      "a": "05166016",
      "j": 2024,
      "n": "nettetal",
      "dn": "Nettetal",
      "bl": 5,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "05166020",
      "j": 2024,
      "n": "niederkrüchten",
      "dn": "Niederkrüchten",
      "bl": 5,
      "hs": 493.0,
      "mns": 2
    },
    {
      "a": "05166024",
      "j": 2024,
      "n": "schwalmtal",
      "dn": "Schwalmtal",
      "bl": 5,
      "hs": 480.0,
      "mns": 2
    },
    {
      "a": "05166028",
      "j": 2024,
      "n": "tönisvorst",
      "dn": "Tönisvorst",
      "bl": 5,
      "hs": 520.0,
      "mns": 3
    },
    {
      "a": "05166032",
      "j": 2024,
      "n": "viersen",
      "dn": "Viersen",
      "bl": 5,
      "hs": 480.0,
      "mns": 3
    },
    {
      "a": "05166036",
      "j": 2024,
      "n": "willich",
      "dn": "Willich",
      "bl": 5,
      "hs": 545.0,
      "mns": 4
    },
    {
      "a": "05170004",
      "j": 2024,
      "n": "alpen",
      "dn": "Alpen",
      "bl": 5,
      "hs": 493.0,
      "mns": 2
    },
    {
      "a": "05170008",
      "j": 2024,
      "n": "dinslaken",
      "dn": "Dinslaken",
      "bl": 5,
      "hs": 648.0,
      "mns": 3
    },
    {
      "a": "05170012",
      "j": 2024,
      "n": "hamminkeln",
      "dn": "Hamminkeln",
      "bl": 5,
      "hs": 650.0,
      "mns": 2
    },
    {
      "a": "05170016",
      "j": 2024,
      "n": "hünxe",
      "dn": "Hünxe",
      "bl": 5,
      "hs": 600.0,
      "mns": 3
    },
    {
      "a": "05170020",
      "j": 2024,
      "n": "kamp-lintfort",
      "dn": "Kamp-Lintfort",
      "bl": 5,
      "hs": 765.0,
      "mns": 3
    },
    {
      "a": "05170024",
      "j": 2024,
      "n": "moers",
      "dn": "Moers",
      "bl": 5,
      "hs": 813.0,
      "mns": 3
    },
    {
      "a": "05170028",
      "j": 2024,
      "n": "neukirchen-vluyn",
      "dn": "Neukirchen-Vluyn",
      "bl": 5,
      "hs": 656.0,
      "mns": 3
    },
    {
      "a": "05170032",
      "j": 2024,
      "n": "rheinberg",
      "dn": "Rheinberg",
      "bl": 5,
      "hs": 590.0,
      "mns": 3
    },
    {
      "a": "05170036",
      "j": 2024,
      "n": "schermbeck",
      "dn": "Schermbeck",
      "bl": 5,
      "hs": 750.0,
      "mns": 3
    },
    {
      "a": "05170040",
      "j": 2024,
      "n": "sonsbeck",
      "dn": "Sonsbeck",
      "bl": 5,
      "hs": 413.0,
      "mns": 2
    },
    {
      "a": "05170044",
      "j": 2024,
      "n": "voerde",
      "dn": "Voerde",
      "bl": 5,
      "hs": 690.0,
      "mns": 3
    },
    {
      "a": "05170048",
      "j": 2024,
      "n": "wesel",
      "dn": "Wesel",
      "bl": 5,
      "hs": 690.0,
      "mns": 3
    },
    {
      "a": "05170052",
      "j": 2024,
      "n": "xanten",
      "dn": "Xanten",
      "bl": 5,
      "hs": 965.0,
      "mns": 3
    },
    {
      "a": "05314000",
      "j": 2025,
      "n": "bonn",
      "dn": "Bonn",
      "bl": 5,
      "hs": 657.0,
      "mns": 5
    },
    {
      "a": "05315000",
      "j": 2025,
      "n": "köln",
      "dn": "Köln",
      "bl": 5,
      "hs": 515.0,
      "mns": 6
    },
    {
      "a": "05316000",
      "j": 2024,
      "n": "leverkusen",
      "dn": "Leverkusen",
      "bl": 5,
      "hs": 750.0,
      "mns": 4
    },
    {
      "a": "05334002",
      "j": 2024,
      "n": "aachen",
      "dn": "Aachen",
      "bl": 5,
      "hs": 525.0,
      "mns": 4
    },
    {
      "a": "05334004",
      "j": 2024,
      "n": "alsdorf",
      "dn": "Alsdorf",
      "bl": 5,
      "hs": 895.0,
      "mns": 2
    },
    {
      "a": "05334008",
      "j": 2024,
      "n": "baesweiler",
      "dn": "Baesweiler",
      "bl": 5,
      "hs": 555.0,
      "mns": 2
    },
    {
      "a": "05334012",
      "j": 2025,
      "n": "eschweiler",
      "dn": "Eschweiler",
      "bl": 5,
      "hs": 895.0,
      "mns": 3
    },
    {
      "a": "05334016",
      "j": 2024,
      "n": "herzogenrath",
      "dn": "Herzogenrath",
      "bl": 5,
      "hs": 650.0,
      "mns": 3
    },
    {
      "a": "05334020",
      "j": 2024,
      "n": "monschau",
      "dn": "Monschau",
      "bl": 5,
      "hs": 820.0,
      "mns": 1
    },
    {
      "a": "05334024",
      "j": 2024,
      "n": "roetgen",
      "dn": "Roetgen",
      "bl": 5,
      "hs": 660.0,
      "mns": 2
    },
    {
      "a": "05334028",
      "j": 2024,
      "n": "simmerath",
      "dn": "Simmerath",
      "bl": 5,
      "hs": 490.0,
      "mns": 2
    },
    {
      "a": "05334032",
      "j": 2024,
      "n": "stolberg",
      "dn": "Stolberg",
      "bl": 5,
      "hs": 595.0,
      "mns": 3
    },
    {
      "a": "05334036",
      "j": 2024,
      "n": "würselen",
      "dn": "Würselen",
      "bl": 5,
      "hs": 850.0,
      "mns": 3
    },
    {
      "a": "05358004",
      "j": 2024,
      "n": "aldenhoven",
      "dn": "Aldenhoven",
      "bl": 5,
      "hs": 820.0,
      "mns": 3
    },
    {
      "a": "05358008",
      "j": 2024,
      "n": "düren",
      "dn": "Düren",
      "bl": 5,
      "hs": 590.0,
      "mns": 3
    },
    {
      "a": "05358012",
      "j": 2024,
      "n": "heimbach",
      "dn": "Heimbach",
      "bl": 5,
      "hs": 950.0,
      "mns": 2
    },
    {
      "a": "05358016",
      "j": 2024,
      "n": "hürtgenwald",
      "dn": "Hürtgenwald",
      "bl": 5,
      "hs": 950.0,
      "mns": 2
    },
    {
      "a": "05358020",
      "j": 2024,
      "n": "inden",
      "dn": "Inden",
      "bl": 5,
      "hs": 930.0,
      "mns": 2
    },
    {
      "a": "05358024",
      "j": 2024,
      "n": "jülich",
      "dn": "Jülich",
      "bl": 5,
      "hs": 780.0,
      "mns": 3
    },
    {
      "a": "05358028",
      "j": 2024,
      "n": "kreuzau",
      "dn": "Kreuzau",
      "bl": 5,
      "hs": 714.0,
      "mns": 2
    },
    {
      "a": "05358032",
      "j": 2024,
      "n": "langerwehe",
      "dn": "Langerwehe",
      "bl": 5,
      "hs": 940.0,
      "mns": 2
    },
    {
      "a": "05358036",
      "j": 2024,
      "n": "linnich",
      "dn": "Linnich",
      "bl": 5,
      "hs": 600.0,
      "mns": 2
    },
    {
      "a": "05358040",
      "j": 2024,
      "n": "merzenich",
      "dn": "Merzenich",
      "bl": 5,
      "hs": 749.0,
      "mns": 2
    },
    {
      "a": "05358044",
      "j": 2024,
      "n": "nideggen",
      "dn": "Nideggen",
      "bl": 5,
      "hs": 950.0,
      "mns": 2
    },
    {
      "a": "05358048",
      "j": 2025,
      "n": "niederzier",
      "dn": "Niederzier",
      "bl": 5,
      "hs": 730.0,
      "mns": 2
    },
    {
      "a": "05358052",
      "j": 2024,
      "n": "nörvenich",
      "dn": "Nörvenich",
      "bl": 5,
      "hs": 910.0,
      "mns": 3
    },
    {
      "a": "05358056",
      "j": 2024,
      "n": "titz",
      "dn": "Titz",
      "bl": 5,
      "hs": 840.0,
      "mns": 2
    },
    {
      "a": "05358060",
      "j": 2024,
      "n": "vettweiß",
      "dn": "Vettweiß",
      "bl": 5,
      "hs": 741.0,
      "mns": 2
    },
    {
      "a": "05362004",
      "j": 2024,
      "n": "bedburg",
      "dn": "Bedburg",
      "bl": 5,
      "hs": 850.0,
      "mns": 3
    },
    {
      "a": "05362008",
      "j": 2024,
      "n": "bergheim",
      "dn": "Bergheim",
      "bl": 5,
      "hs": 760.0,
      "mns": 3
    },
    {
      "a": "05362012",
      "j": 2024,
      "n": "brühl",
      "dn": "Brühl",
      "bl": 5,
      "hs": 800.0,
      "mns": 5
    },
    {
      "a": "05362016",
      "j": 2024,
      "n": "elsdorf",
      "dn": "Elsdorf",
      "bl": 5,
      "hs": 913.0,
      "mns": 3
    },
    {
      "a": "05362020",
      "j": 2025,
      "n": "erftstadt",
      "dn": "Erftstadt",
      "bl": 5,
      "hs": 635.0,
      "mns": 4
    },
    {
      "a": "05362024",
      "j": 2024,
      "n": "frechen",
      "dn": "Frechen",
      "bl": 5,
      "hs": 520.0,
      "mns": 5
    },
    {
      "a": "05362028",
      "j": 2024,
      "n": "hürth",
      "dn": "Hürth",
      "bl": 5,
      "hs": 480.0,
      "mns": 5
    },
    {
      "a": "05362032",
      "j": 2024,
      "n": "kerpen",
      "dn": "Kerpen",
      "bl": 5,
      "hs": 739.0,
      "mns": 4
    },
    {
      "a": "05362036",
      "j": 2025,
      "n": "pulheim",
      "dn": "Pulheim",
      "bl": 5,
      "hs": 467.0,
      "mns": 5
    },
    {
      "a": "05362040",
      "j": 2024,
      "n": "wesseling",
      "dn": "Wesseling",
      "bl": 5,
      "hs": 595.0,
      "mns": 4
    },
    {
      "a": "05366004",
      "j": 2024,
      "n": "bad münstereifel",
      "dn": "Bad Münstereifel",
      "bl": 5,
      "hs": 695.0,
      "mns": 2
    },
    {
      "a": "05366008",
      "j": 2024,
      "n": "blankenheim",
      "dn": "Blankenheim",
      "bl": 5,
      "hs": 650.0,
      "mns": 1
    },
    {
      "a": "05366012",
      "j": 2024,
      "n": "dahlem",
      "dn": "Dahlem",
      "bl": 5,
      "hs": 565.0,
      "mns": 1
    },
    {
      "a": "05366016",
      "j": 2024,
      "n": "euskirchen",
      "dn": "Euskirchen",
      "bl": 5,
      "hs": 496.0,
      "mns": 3
    },
    {
      "a": "05366020",
      "j": 2024,
      "n": "hellenthal",
      "dn": "Hellenthal",
      "bl": 5,
      "hs": 595.0,
      "mns": 1
    },
    {
      "a": "05366024",
      "j": 2024,
      "n": "kall",
      "dn": "Kall",
      "bl": 5,
      "hs": 555.0,
      "mns": 2
    },
    {
      "a": "05366028",
      "j": 2024,
      "n": "mechernich",
      "dn": "Mechernich",
      "bl": 5,
      "hs": 595.0,
      "mns": 2
    },
    {
      "a": "05366032",
      "j": 2024,
      "n": "nettersheim",
      "dn": "Nettersheim",
      "bl": 5,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "05366036",
      "j": 2024,
      "n": "schleiden",
      "dn": "Schleiden",
      "bl": 5,
      "hs": 735.0,
      "mns": 1
    },
    {
      "a": "05366040",
      "j": 2024,
      "n": "weilerswist",
      "dn": "Weilerswist",
      "bl": 5,
      "hs": 610.0,
      "mns": 3
    },
    {
      "a": "05366044",
      "j": 2024,
      "n": "zülpich",
      "dn": "Zülpich",
      "bl": 5,
      "hs": 690.0,
      "mns": 2
    },
    {
      "a": "05370004",
      "j": 2024,
      "n": "erkelenz",
      "dn": "Erkelenz",
      "bl": 5,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "05370008",
      "j": 2024,
      "n": "gangelt",
      "dn": "Gangelt",
      "bl": 5,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "05370012",
      "j": 2024,
      "n": "geilenkirchen",
      "dn": "Geilenkirchen",
      "bl": 5,
      "hs": 600.0,
      "mns": 2
    },
    {
      "a": "05370016",
      "j": 2024,
      "n": "heinsberg",
      "dn": "Heinsberg",
      "bl": 5,
      "hs": 500.0,
      "mns": 2
    },
    {
      "a": "05370020",
      "j": 2024,
      "n": "hückelhoven",
      "dn": "Hückelhoven",
      "bl": 5,
      "hs": 493.0,
      "mns": 2
    },
    {
      "a": "05370024",
      "j": 2024,
      "n": "selfkant",
      "dn": "Selfkant",
      "bl": 5,
      "hs": 660.0,
      "mns": 2
    },
    {
      "a": "05370028",
      "j": 2024,
      "n": "übach-palenberg",
      "dn": "Übach-Palenberg",
      "bl": 5,
      "hs": 680.0,
      "mns": 2
    },
    {
      "a": "05370032",
      "j": 2024,
      "n": "waldfeucht",
      "dn": "Waldfeucht",
      "bl": 5,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "05370036",
      "j": 2024,
      "n": "wassenberg",
      "dn": "Wassenberg",
      "bl": 5,
      "hs": 490.0,
      "mns": 2
    },
    {
      "a": "05370040",
      "j": 2024,
      "n": "wegberg",
      "dn": "Wegberg",
      "bl": 5,
      "hs": 550.0,
      "mns": 3
    },
    {
      "a": "05374004",
      "j": 2024,
      "n": "bergneustadt",
      "dn": "Bergneustadt",
      "bl": 5,
      "hs": 959.0,
      "mns": 2
    },
    {
      "a": "05374008",
      "j": 2024,
      "n": "engelskirchen",
      "dn": "Engelskirchen",
      "bl": 5,
      "hs": 650.0,
      "mns": 3
    },
    {
      "a": "05374012",
      "j": 2024,
      "n": "gummersbach",
      "dn": "Gummersbach",
      "bl": 5,
      "hs": 675.0,
      "mns": 2
    },
    {
      "a": "05374016",
      "j": 2024,
      "n": "hückeswagen",
      "dn": "Hückeswagen",
      "bl": 5,
      "hs": 795.0,
      "mns": 3
    },
    {
      "a": "05374020",
      "j": 2024,
      "n": "lindlar",
      "dn": "Lindlar",
      "bl": 5,
      "hs": 903.0,
      "mns": 3
    },
    {
      "a": "05374024",
      "j": 2025,
      "n": "marienheide",
      "dn": "Marienheide",
      "bl": 5,
      "hs": 835.0,
      "mns": 2
    },
    {
      "a": "05374028",
      "j": 2024,
      "n": "morsbach",
      "dn": "Morsbach",
      "bl": 5,
      "hs": 605.0,
      "mns": 1
    },
    {
      "a": "05374032",
      "j": 2024,
      "n": "nümbrecht",
      "dn": "Nümbrecht",
      "bl": 5,
      "hs": 675.0,
      "mns": 2
    },
    {
      "a": "05374036",
      "j": 2024,
      "n": "radevormwald",
      "dn": "Radevormwald",
      "bl": 5,
      "hs": 490.0,
      "mns": 3
    },
    {
      "a": "05374040",
      "j": 2024,
      "n": "reichshof",
      "dn": "Reichshof",
      "bl": 5,
      "hs": 570.0,
      "mns": 1
    },
    {
      "a": "05374044",
      "j": 2024,
      "n": "waldbröl",
      "dn": "Waldbröl",
      "bl": 5,
      "hs": 755.0,
      "mns": 2
    },
    {
      "a": "05374048",
      "j": 2024,
      "n": "wiehl",
      "dn": "Wiehl",
      "bl": 5,
      "hs": 508.0,
      "mns": 2
    },
    {
      "a": "05374052",
      "j": 2024,
      "n": "wipperfürth",
      "dn": "Wipperfürth",
      "bl": 5,
      "hs": 860.0,
      "mns": 2
    },
    {
      "a": "05378004",
      "j": 2024,
      "n": "bergisch gladbach",
      "dn": "Bergisch Gladbach",
      "bl": 5,
      "hs": 731.0,
      "mns": 5
    },
    {
      "a": "05378008",
      "j": 2024,
      "n": "burscheid",
      "dn": "Burscheid",
      "bl": 5,
      "hs": 501.0,
      "mns": 4
    },
    {
      "a": "05378012",
      "j": 2024,
      "n": "kürten",
      "dn": "Kürten",
      "bl": 5,
      "hs": 675.0,
      "mns": 3
    },
    {
      "a": "05378016",
      "j": 2024,
      "n": "leichlingen",
      "dn": "Leichlingen",
      "bl": 5,
      "hs": 550.0,
      "mns": 4
    },
    {
      "a": "05378020",
      "j": 2024,
      "n": "odenthal",
      "dn": "Odenthal",
      "bl": 5,
      "hs": 790.0,
      "mns": 4
    },
    {
      "a": "05378024",
      "j": 2024,
      "n": "overath",
      "dn": "Overath",
      "bl": 5,
      "hs": 850.0,
      "mns": 4
    },
    {
      "a": "05378028",
      "j": 2024,
      "n": "rösrath",
      "dn": "Rösrath",
      "bl": 5,
      "hs": 690.0,
      "mns": 5
    },
    {
      "a": "05378032",
      "j": 2024,
      "n": "wermelskirchen",
      "dn": "Wermelskirchen",
      "bl": 5,
      "hs": 670.0,
      "mns": 3
    },
    {
      "a": "05382004",
      "j": 2024,
      "n": "alfter",
      "dn": "Alfter",
      "bl": 5,
      "hs": 995.0,
      "mns": 4
    },
    {
      "a": "05382008",
      "j": 2024,
      "n": "bad honnef",
      "dn": "Bad Honnef",
      "bl": 5,
      "hs": 815.0,
      "mns": 4
    },
    {
      "a": "05382012",
      "j": 2024,
      "n": "bornheim",
      "dn": "Bornheim",
      "bl": 5,
      "hs": 750.0,
      "mns": 4
    },
    {
      "a": "05382016",
      "j": 2024,
      "n": "eitorf",
      "dn": "Eitorf",
      "bl": 5,
      "hs": 760.0,
      "mns": 2
    },
    {
      "a": "05382020",
      "j": 2024,
      "n": "hennef",
      "dn": "Hennef",
      "bl": 5,
      "hs": 785.0,
      "mns": 4
    },
    {
      "a": "05382024",
      "j": 2024,
      "n": "königswinter",
      "dn": "Königswinter",
      "bl": 5,
      "hs": 690.0,
      "mns": 4
    },
    {
      "a": "05382028",
      "j": 2024,
      "n": "lohmar",
      "dn": "Lohmar",
      "bl": 5,
      "hs": 790.0,
      "mns": 4
    },
    {
      "a": "05382032",
      "j": 2024,
      "n": "meckenheim",
      "dn": "Meckenheim",
      "bl": 5,
      "hs": 895.0,
      "mns": 3
    },
    {
      "a": "05382036",
      "j": 2024,
      "n": "much",
      "dn": "Much",
      "bl": 5,
      "hs": 750.0,
      "mns": 2
    },
    {
      "a": "05382040",
      "j": 2024,
      "n": "neunkirchen-seelscheid",
      "dn": "Neunkirchen-Seelscheid",
      "bl": 5,
      "hs": 793.0,
      "mns": 3
    },
    {
      "a": "05382044",
      "j": 2024,
      "n": "niederkassel",
      "dn": "Niederkassel",
      "bl": 5,
      "hs": 1100.0,
      "mns": 4
    },
    {
      "a": "05382048",
      "j": 2024,
      "n": "rheinbach",
      "dn": "Rheinbach",
      "bl": 5,
      "hs": 753.0,
      "mns": 4
    },
    {
      "a": "05382052",
      "j": 2024,
      "n": "ruppichteroth",
      "dn": "Ruppichteroth",
      "bl": 5,
      "hs": 745.0,
      "mns": 2
    },
    {
      "a": "05382056",
      "j": 2024,
      "n": "sankt augustin",
      "dn": "Sankt Augustin",
      "bl": 5,
      "hs": 750.0,
      "mns": 4
    },
    {
      "a": "05382060",
      "j": 2024,
      "n": "siegburg",
      "dn": "Siegburg",
      "bl": 5,
      "hs": 790.0,
      "mns": 5
    },
    {
      "a": "05382064",
      "j": 2024,
      "n": "swisttal",
      "dn": "Swisttal",
      "bl": 5,
      "hs": 760.0,
      "mns": 3
    },
    {
      "a": "05382068",
      "j": 2024,
      "n": "troisdorf",
      "dn": "Troisdorf",
      "bl": 5,
      "hs": 555.0,
      "mns": 4
    },
    {
      "a": "05382072",
      "j": 2024,
      "n": "wachtberg",
      "dn": "Wachtberg",
      "bl": 5,
      "hs": 695.0,
      "mns": 4
    },
    {
      "a": "05382076",
      "j": 2024,
      "n": "windeck",
      "dn": "Windeck",
      "bl": 5,
      "hs": 750.0,
      "mns": 2
    },
    {
      "a": "05512000",
      "j": 2025,
      "n": "bottrop",
      "dn": "Bottrop",
      "bl": 5,
      "hs": 619.0,
      "mns": 3
    },
    {
      "a": "05513000",
      "j": 2024,
      "n": "gelsenkirchen",
      "dn": "Gelsenkirchen",
      "bl": 5,
      "hs": 675.0,
      "mns": 2
    },
    {
      "a": "05515000",
      "j": 2025,
      "n": "münster",
      "dn": "Münster",
      "bl": 5,
      "hs": 410.0,
      "mns": 5
    },
    {
      "a": "05554004",
      "j": 2024,
      "n": "ahaus",
      "dn": "Ahaus",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05554008",
      "j": 2024,
      "n": "bocholt",
      "dn": "Bocholt",
      "bl": 5,
      "hs": 630.0,
      "mns": 3
    },
    {
      "a": "05554012",
      "j": 2024,
      "n": "borken",
      "dn": "Borken",
      "bl": 5,
      "hs": 530.0,
      "mns": 2
    },
    {
      "a": "05554016",
      "j": 2024,
      "n": "gescher",
      "dn": "Gescher",
      "bl": 5,
      "hs": 550.0,
      "mns": 2
    },
    {
      "a": "05554020",
      "j": 2024,
      "n": "gronau",
      "dn": "Gronau",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05554024",
      "j": 2024,
      "n": "heek",
      "dn": "Heek",
      "bl": 5,
      "hs": 501.0,
      "mns": 1
    },
    {
      "a": "05554028",
      "j": 2024,
      "n": "heiden",
      "dn": "Heiden",
      "bl": 5,
      "hs": 469.0,
      "mns": 1
    },
    {
      "a": "05554032",
      "j": 2025,
      "n": "isselburg",
      "dn": "Isselburg",
      "bl": 5,
      "hs": 630.0,
      "mns": 2
    },
    {
      "a": "05554036",
      "j": 2024,
      "n": "legden",
      "dn": "Legden",
      "bl": 5,
      "hs": 458.0,
      "mns": 1
    },
    {
      "a": "05554040",
      "j": 2024,
      "n": "raesfeld",
      "dn": "Raesfeld",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05554044",
      "j": 2024,
      "n": "reken",
      "dn": "Reken",
      "bl": 5,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "05554048",
      "j": 2024,
      "n": "rhede",
      "dn": "Rhede",
      "bl": 5,
      "hs": 625.0,
      "mns": 2
    },
    {
      "a": "05554052",
      "j": 2024,
      "n": "schöppingen",
      "dn": "Schöppingen",
      "bl": 5,
      "hs": 413.0,
      "mns": 1
    },
    {
      "a": "05554056",
      "j": 2024,
      "n": "stadtlohn",
      "dn": "Stadtlohn",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05554060",
      "j": 2024,
      "n": "südlohn",
      "dn": "Südlohn",
      "bl": 5,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "05554064",
      "j": 2024,
      "n": "velen",
      "dn": "Velen",
      "bl": 5,
      "hs": 529.0,
      "mns": 1
    },
    {
      "a": "05554068",
      "j": 2024,
      "n": "vreden",
      "dn": "Vreden",
      "bl": 5,
      "hs": 501.0,
      "mns": 1
    },
    {
      "a": "05558004",
      "j": 2024,
      "n": "ascheberg",
      "dn": "Ascheberg",
      "bl": 5,
      "hs": 481.0,
      "mns": 2
    },
    {
      "a": "05558008",
      "j": 2024,
      "n": "billerbeck",
      "dn": "Billerbeck",
      "bl": 5,
      "hs": 447.0,
      "mns": 1
    },
    {
      "a": "05558012",
      "j": 2024,
      "n": "coesfeld",
      "dn": "Coesfeld",
      "bl": 5,
      "hs": 550.0,
      "mns": 2
    },
    {
      "a": "05558016",
      "j": 2024,
      "n": "dülmen",
      "dn": "Dülmen",
      "bl": 5,
      "hs": 550.0,
      "mns": 2
    },
    {
      "a": "05558020",
      "j": 2024,
      "n": "havixbeck",
      "dn": "Havixbeck",
      "bl": 5,
      "hs": 581.0,
      "mns": 3
    },
    {
      "a": "05558024",
      "j": 2024,
      "n": "lüdinghausen",
      "dn": "Lüdinghausen",
      "bl": 5,
      "hs": 460.0,
      "mns": 2
    },
    {
      "a": "05558028",
      "j": 2024,
      "n": "nordkirchen",
      "dn": "Nordkirchen",
      "bl": 5,
      "hs": 540.0,
      "mns": 2
    },
    {
      "a": "05558032",
      "j": 2024,
      "n": "nottuln",
      "dn": "Nottuln",
      "bl": 5,
      "hs": 690.0,
      "mns": 2
    },
    {
      "a": "05558036",
      "j": 2024,
      "n": "olfen",
      "dn": "Olfen",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05558040",
      "j": 2024,
      "n": "rosendahl",
      "dn": "Rosendahl",
      "bl": 5,
      "hs": 495.0,
      "mns": 1
    },
    {
      "a": "05558044",
      "j": 2024,
      "n": "senden",
      "dn": "Senden",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05562004",
      "j": 2024,
      "n": "castrop-rauxel",
      "dn": "Castrop-Rauxel",
      "bl": 5,
      "hs": 825.0,
      "mns": 3
    },
    {
      "a": "05562008",
      "j": 2024,
      "n": "datteln",
      "dn": "Datteln",
      "bl": 5,
      "hs": 825.0,
      "mns": 2
    },
    {
      "a": "05562012",
      "j": 2024,
      "n": "dorsten",
      "dn": "Dorsten",
      "bl": 5,
      "hs": 870.0,
      "mns": 3
    },
    {
      "a": "05562014",
      "j": 2024,
      "n": "gladbeck",
      "dn": "Gladbeck",
      "bl": 5,
      "hs": 950.0,
      "mns": 2
    },
    {
      "a": "05562016",
      "j": 2024,
      "n": "haltern am see",
      "dn": "Haltern am See",
      "bl": 5,
      "hs": 825.0,
      "mns": 3
    },
    {
      "a": "05562020",
      "j": 2024,
      "n": "herten",
      "dn": "Herten",
      "bl": 5,
      "hs": 920.0,
      "mns": 3
    },
    {
      "a": "05562024",
      "j": 2024,
      "n": "marl",
      "dn": "Marl",
      "bl": 5,
      "hs": 790.0,
      "mns": 3
    },
    {
      "a": "05562028",
      "j": 2024,
      "n": "oer-erkenschwick",
      "dn": "Oer-Erkenschwick",
      "bl": 5,
      "hs": 825.0,
      "mns": 3
    },
    {
      "a": "05562032",
      "j": 2024,
      "n": "recklinghausen",
      "dn": "Recklinghausen",
      "bl": 5,
      "hs": 695.0,
      "mns": 3
    },
    {
      "a": "05562036",
      "j": 2024,
      "n": "waltrop",
      "dn": "Waltrop",
      "bl": 5,
      "hs": 700.0,
      "mns": 3
    },
    {
      "a": "05566004",
      "j": 2024,
      "n": "altenberge",
      "dn": "Altenberge",
      "bl": 5,
      "hs": 493.0,
      "mns": 2
    },
    {
      "a": "05566008",
      "j": 2024,
      "n": "emsdetten",
      "dn": "Emsdetten",
      "bl": 5,
      "hs": 575.0,
      "mns": 2
    },
    {
      "a": "05566012",
      "j": 2024,
      "n": "greven",
      "dn": "Greven",
      "bl": 5,
      "hs": 580.0,
      "mns": 3
    },
    {
      "a": "05566016",
      "j": 2024,
      "n": "hörstel",
      "dn": "Hörstel",
      "bl": 5,
      "hs": 493.0,
      "mns": 1
    },
    {
      "a": "05566020",
      "j": 2024,
      "n": "hopsten",
      "dn": "Hopsten",
      "bl": 5,
      "hs": 435.0,
      "mns": 1
    },
    {
      "a": "05566024",
      "j": 2024,
      "n": "horstmar",
      "dn": "Horstmar",
      "bl": 5,
      "hs": 590.0,
      "mns": 1
    },
    {
      "a": "05566028",
      "j": 2024,
      "n": "ibbenbüren",
      "dn": "Ibbenbüren",
      "bl": 5,
      "hs": 642.0,
      "mns": 2
    },
    {
      "a": "05566032",
      "j": 2024,
      "n": "ladbergen",
      "dn": "Ladbergen",
      "bl": 5,
      "hs": 501.0,
      "mns": 1
    },
    {
      "a": "05566036",
      "j": 2024,
      "n": "laer",
      "dn": "Laer",
      "bl": 5,
      "hs": 790.0,
      "mns": 1
    },
    {
      "a": "05566040",
      "j": 2024,
      "n": "lengerich",
      "dn": "Lengerich",
      "bl": 5,
      "hs": 528.0,
      "mns": 1
    },
    {
      "a": "05566044",
      "j": 2024,
      "n": "lienen",
      "dn": "Lienen",
      "bl": 5,
      "hs": 583.0,
      "mns": 1
    },
    {
      "a": "05566048",
      "j": 2024,
      "n": "lotte",
      "dn": "Lotte",
      "bl": 5,
      "hs": 535.0,
      "mns": 2
    },
    {
      "a": "05566052",
      "j": 2024,
      "n": "metelen",
      "dn": "Metelen",
      "bl": 5,
      "hs": 495.0,
      "mns": 1
    },
    {
      "a": "05566056",
      "j": 2024,
      "n": "mettingen",
      "dn": "Mettingen",
      "bl": 5,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "05566060",
      "j": 2024,
      "n": "neuenkirchen",
      "dn": "Neuenkirchen",
      "bl": 5,
      "hs": 455.0,
      "mns": 2
    },
    {
      "a": "05566064",
      "j": 2024,
      "n": "nordwalde",
      "dn": "Nordwalde",
      "bl": 5,
      "hs": 501.0,
      "mns": 1
    },
    {
      "a": "05566068",
      "j": 2024,
      "n": "ochtrup",
      "dn": "Ochtrup",
      "bl": 5,
      "hs": 498.0,
      "mns": 1
    },
    {
      "a": "05566072",
      "j": 2024,
      "n": "recke",
      "dn": "Recke",
      "bl": 5,
      "hs": 563.0,
      "mns": 1
    },
    {
      "a": "05566076",
      "j": 2024,
      "n": "rheine",
      "dn": "Rheine",
      "bl": 5,
      "hs": 600.0,
      "mns": 2
    },
    {
      "a": "05566080",
      "j": 2024,
      "n": "saerbeck",
      "dn": "Saerbeck",
      "bl": 5,
      "hs": 620.0,
      "mns": 1
    },
    {
      "a": "05566084",
      "j": 2024,
      "n": "steinfurt",
      "dn": "Steinfurt",
      "bl": 5,
      "hs": 642.0,
      "mns": 2
    },
    {
      "a": "05566088",
      "j": 2024,
      "n": "tecklenburg",
      "dn": "Tecklenburg",
      "bl": 5,
      "hs": 595.0,
      "mns": 1
    },
    {
      "a": "05566092",
      "j": 2024,
      "n": "westerkappeln",
      "dn": "Westerkappeln",
      "bl": 5,
      "hs": 630.0,
      "mns": 1
    },
    {
      "a": "05566096",
      "j": 2024,
      "n": "wettringen",
      "dn": "Wettringen",
      "bl": 5,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "05570004",
      "j": 2024,
      "n": "ahlen",
      "dn": "Ahlen",
      "bl": 5,
      "hs": 546.0,
      "mns": 2
    },
    {
      "a": "05570008",
      "j": 2024,
      "n": "beckum",
      "dn": "Beckum",
      "bl": 5,
      "hs": 519.0,
      "mns": 2
    },
    {
      "a": "05570012",
      "j": 2024,
      "n": "beelen",
      "dn": "Beelen",
      "bl": 5,
      "hs": 476.0,
      "mns": 1
    },
    {
      "a": "05570016",
      "j": 2024,
      "n": "drensteinfurt",
      "dn": "Drensteinfurt",
      "bl": 5,
      "hs": 525.0,
      "mns": 2
    },
    {
      "a": "05570020",
      "j": 2024,
      "n": "ennigerloh",
      "dn": "Ennigerloh",
      "bl": 5,
      "hs": 529.0,
      "mns": 1
    },
    {
      "a": "05570024",
      "j": 2024,
      "n": "everswinkel",
      "dn": "Everswinkel",
      "bl": 5,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "05570028",
      "j": 2024,
      "n": "oelde",
      "dn": "Oelde",
      "bl": 5,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "05570032",
      "j": 2024,
      "n": "ostbevern",
      "dn": "Ostbevern",
      "bl": 5,
      "hs": 493.0,
      "mns": 2
    },
    {
      "a": "05570036",
      "j": 2024,
      "n": "sassenberg",
      "dn": "Sassenberg",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05570040",
      "j": 2024,
      "n": "sendenhorst",
      "dn": "Sendenhorst",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05570044",
      "j": 2024,
      "n": "telgte",
      "dn": "Telgte",
      "bl": 5,
      "hs": 530.0,
      "mns": 3
    },
    {
      "a": "05570048",
      "j": 2024,
      "n": "wadersloh",
      "dn": "Wadersloh",
      "bl": 5,
      "hs": 501.0,
      "mns": 1
    },
    {
      "a": "05570052",
      "j": 2024,
      "n": "warendorf",
      "dn": "Warendorf",
      "bl": 5,
      "hs": 535.0,
      "mns": 2
    },
    {
      "a": "05711000",
      "j": 2024,
      "n": "bielefeld",
      "dn": "Bielefeld",
      "bl": 5,
      "hs": 660.0,
      "mns": 3
    },
    {
      "a": "05754004",
      "j": 2024,
      "n": "borgholzhausen",
      "dn": "Borgholzhausen",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05754008",
      "j": 2024,
      "n": "gütersloh",
      "dn": "Gütersloh",
      "bl": 5,
      "hs": 606.0,
      "mns": 3
    },
    {
      "a": "05754012",
      "j": 2024,
      "n": "halle (westf.)",
      "dn": "Halle (Westf.)",
      "bl": 5,
      "hs": 493.0,
      "mns": 2
    },
    {
      "a": "05754016",
      "j": 2024,
      "n": "harsewinkel",
      "dn": "Harsewinkel",
      "bl": 5,
      "hs": 345.0,
      "mns": 2
    },
    {
      "a": "05754020",
      "j": 2024,
      "n": "herzebrock-clarholz",
      "dn": "Herzebrock-Clarholz",
      "bl": 5,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "05754024",
      "j": 2024,
      "n": "langenberg",
      "dn": "Langenberg",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05754028",
      "j": 2024,
      "n": "rheda-wiedenbrück",
      "dn": "Rheda-Wiedenbrück",
      "bl": 5,
      "hs": 501.0,
      "mns": 3
    },
    {
      "a": "05754032",
      "j": 2024,
      "n": "rietberg",
      "dn": "Rietberg",
      "bl": 5,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "05754036",
      "j": 2024,
      "n": "schloß holte-stukenbrock",
      "dn": "Schloß Holte-Stukenbrock",
      "bl": 5,
      "hs": 280.0,
      "mns": 2
    },
    {
      "a": "05754040",
      "j": 2024,
      "n": "steinhagen",
      "dn": "Steinhagen",
      "bl": 5,
      "hs": 480.0,
      "mns": 2
    },
    {
      "a": "05754044",
      "j": 2024,
      "n": "verl",
      "dn": "Verl",
      "bl": 5,
      "hs": 170.0,
      "mns": 2
    },
    {
      "a": "05754048",
      "j": 2024,
      "n": "versmold",
      "dn": "Versmold",
      "bl": 5,
      "hs": 501.0,
      "mns": 1
    },
    {
      "a": "05754052",
      "j": 2024,
      "n": "werther (westf.)",
      "dn": "Werther (Westf.)",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05758004",
      "j": 2024,
      "n": "bünde",
      "dn": "Bünde",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05758008",
      "j": 2024,
      "n": "enger",
      "dn": "Enger",
      "bl": 5,
      "hs": 780.0,
      "mns": 2
    },
    {
      "a": "05758012",
      "j": 2024,
      "n": "herford",
      "dn": "Herford",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05758016",
      "j": 2024,
      "n": "hiddenhausen",
      "dn": "Hiddenhausen",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05758020",
      "j": 2024,
      "n": "kirchlengern",
      "dn": "Kirchlengern",
      "bl": 5,
      "hs": 501.0,
      "mns": 1
    },
    {
      "a": "05758024",
      "j": 2024,
      "n": "löhne",
      "dn": "Löhne",
      "bl": 5,
      "hs": 575.0,
      "mns": 2
    },
    {
      "a": "05758028",
      "j": 2024,
      "n": "rödinghausen",
      "dn": "Rödinghausen",
      "bl": 5,
      "hs": 501.0,
      "mns": 1
    },
    {
      "a": "05758032",
      "j": 2024,
      "n": "spenge",
      "dn": "Spenge",
      "bl": 5,
      "hs": 620.0,
      "mns": 1
    },
    {
      "a": "05758036",
      "j": 2024,
      "n": "vlotho",
      "dn": "Vlotho",
      "bl": 5,
      "hs": 501.0,
      "mns": 1
    },
    {
      "a": "05762004",
      "j": 2024,
      "n": "bad driburg",
      "dn": "Bad Driburg",
      "bl": 5,
      "hs": 501.0,
      "mns": 1
    },
    {
      "a": "05762008",
      "j": 2025,
      "n": "beverungen",
      "dn": "Beverungen",
      "bl": 5,
      "hs": 759.0,
      "mns": 1
    },
    {
      "a": "05762012",
      "j": 2024,
      "n": "borgentreich",
      "dn": "Borgentreich",
      "bl": 5,
      "hs": 501.0,
      "mns": 1
    },
    {
      "a": "05762016",
      "j": 2024,
      "n": "brakel",
      "dn": "Brakel",
      "bl": 5,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "05762020",
      "j": 2024,
      "n": "höxter",
      "dn": "Höxter",
      "bl": 5,
      "hs": 650.0,
      "mns": 1
    },
    {
      "a": "05762024",
      "j": 2024,
      "n": "marienmünster",
      "dn": "Marienmünster",
      "bl": 5,
      "hs": 493.0,
      "mns": 1
    },
    {
      "a": "05762028",
      "j": 2025,
      "n": "nieheim",
      "dn": "Nieheim",
      "bl": 5,
      "hs": 639.0,
      "mns": 1
    },
    {
      "a": "05762032",
      "j": 2024,
      "n": "steinheim",
      "dn": "Steinheim",
      "bl": 5,
      "hs": 501.0,
      "mns": 1
    },
    {
      "a": "05762036",
      "j": 2024,
      "n": "warburg",
      "dn": "Warburg",
      "bl": 5,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "05762040",
      "j": 2024,
      "n": "willebadessen",
      "dn": "Willebadessen",
      "bl": 5,
      "hs": 501.0,
      "mns": 1
    },
    {
      "a": "05766004",
      "j": 2024,
      "n": "augustdorf",
      "dn": "Augustdorf",
      "bl": 5,
      "hs": 494.0,
      "mns": 1
    },
    {
      "a": "05766008",
      "j": 2024,
      "n": "bad salzuflen",
      "dn": "Bad Salzuflen",
      "bl": 5,
      "hs": 620.0,
      "mns": 2
    },
    {
      "a": "05766012",
      "j": 2024,
      "n": "barntrup",
      "dn": "Barntrup",
      "bl": 5,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "05766016",
      "j": 2024,
      "n": "blomberg",
      "dn": "Blomberg",
      "bl": 5,
      "hs": 620.0,
      "mns": 1
    },
    {
      "a": "05766020",
      "j": 2024,
      "n": "detmold",
      "dn": "Detmold",
      "bl": 5,
      "hs": 540.0,
      "mns": 2
    },
    {
      "a": "05766024",
      "j": 2024,
      "n": "dörentrup",
      "dn": "Dörentrup",
      "bl": 5,
      "hs": 544.0,
      "mns": 1
    },
    {
      "a": "05766028",
      "j": 2024,
      "n": "extertal",
      "dn": "Extertal",
      "bl": 5,
      "hs": 535.0,
      "mns": 1
    },
    {
      "a": "05766032",
      "j": 2024,
      "n": "horn-bad meinberg",
      "dn": "Horn-Bad Meinberg",
      "bl": 5,
      "hs": 580.0,
      "mns": 1
    },
    {
      "a": "05766036",
      "j": 2024,
      "n": "kalletal",
      "dn": "Kalletal",
      "bl": 5,
      "hs": 547.0,
      "mns": 1
    },
    {
      "a": "05766040",
      "j": 2024,
      "n": "lage",
      "dn": "Lage",
      "bl": 5,
      "hs": 529.0,
      "mns": 2
    },
    {
      "a": "05766044",
      "j": 2024,
      "n": "lemgo",
      "dn": "Lemgo",
      "bl": 5,
      "hs": 525.0,
      "mns": 2
    },
    {
      "a": "05766048",
      "j": 2024,
      "n": "leopoldshöhe",
      "dn": "Leopoldshöhe",
      "bl": 5,
      "hs": 560.0,
      "mns": 2
    },
    {
      "a": "05766052",
      "j": 2024,
      "n": "lügde",
      "dn": "Lügde",
      "bl": 5,
      "hs": 557.0,
      "mns": 1
    },
    {
      "a": "05766056",
      "j": 2024,
      "n": "oerlinghausen",
      "dn": "Oerlinghausen",
      "bl": 5,
      "hs": 545.0,
      "mns": 2
    },
    {
      "a": "05766060",
      "j": 2024,
      "n": "schieder-schwalenberg",
      "dn": "Schieder-Schwalenberg",
      "bl": 5,
      "hs": 580.0,
      "mns": 1
    },
    {
      "a": "05766064",
      "j": 2024,
      "n": "schlangen",
      "dn": "Schlangen",
      "bl": 5,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "05770004",
      "j": 2024,
      "n": "bad oeynhausen",
      "dn": "Bad Oeynhausen",
      "bl": 5,
      "hs": 552.0,
      "mns": 2
    },
    {
      "a": "05770008",
      "j": 2024,
      "n": "espelkamp",
      "dn": "Espelkamp",
      "bl": 5,
      "hs": 579.0,
      "mns": 2
    },
    {
      "a": "05770012",
      "j": 2024,
      "n": "hille",
      "dn": "Hille",
      "bl": 5,
      "hs": 580.0,
      "mns": 1
    },
    {
      "a": "05770016",
      "j": 2024,
      "n": "hüllhorst",
      "dn": "Hüllhorst",
      "bl": 5,
      "hs": 493.0,
      "mns": 1
    },
    {
      "a": "05770020",
      "j": 2024,
      "n": "lübbecke",
      "dn": "Lübbecke",
      "bl": 5,
      "hs": 429.0,
      "mns": 2
    },
    {
      "a": "05770024",
      "j": 2024,
      "n": "minden",
      "dn": "Minden",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05770028",
      "j": 2024,
      "n": "petershagen",
      "dn": "Petershagen",
      "bl": 5,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "05770032",
      "j": 2024,
      "n": "porta westfalica",
      "dn": "Porta Westfalica",
      "bl": 5,
      "hs": 530.0,
      "mns": 1
    },
    {
      "a": "05770036",
      "j": 2024,
      "n": "preußisch oldendorf",
      "dn": "Preußisch Oldendorf",
      "bl": 5,
      "hs": 708.0,
      "mns": 1
    },
    {
      "a": "05770040",
      "j": 2024,
      "n": "rahden",
      "dn": "Rahden",
      "bl": 5,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "05770044",
      "j": 2024,
      "n": "stemwede",
      "dn": "Stemwede",
      "bl": 5,
      "hs": 501.0,
      "mns": 1
    },
    {
      "a": "05774004",
      "j": 2024,
      "n": "altenbeken",
      "dn": "Altenbeken",
      "bl": 5,
      "hs": 501.0,
      "mns": 1
    },
    {
      "a": "05774008",
      "j": 2024,
      "n": "bad lippspringe",
      "dn": "Bad Lippspringe",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05774012",
      "j": 2024,
      "n": "borchen",
      "dn": "Borchen",
      "bl": 5,
      "hs": 501.0,
      "mns": 1
    },
    {
      "a": "05774016",
      "j": 2024,
      "n": "büren",
      "dn": "Büren",
      "bl": 5,
      "hs": 514.0,
      "mns": 1
    },
    {
      "a": "05774020",
      "j": 2024,
      "n": "delbrück",
      "dn": "Delbrück",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05774024",
      "j": 2024,
      "n": "hövelhof",
      "dn": "Hövelhof",
      "bl": 5,
      "hs": 479.0,
      "mns": 1
    },
    {
      "a": "05774028",
      "j": 2024,
      "n": "lichtenau",
      "dn": "Lichtenau",
      "bl": 5,
      "hs": 501.0,
      "mns": 1
    },
    {
      "a": "05774032",
      "j": 2024,
      "n": "paderborn",
      "dn": "Paderborn",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05774036",
      "j": 2024,
      "n": "salzkotten",
      "dn": "Salzkotten",
      "bl": 5,
      "hs": 501.0,
      "mns": 1
    },
    {
      "a": "05774040",
      "j": 2024,
      "n": "bad wünnenberg",
      "dn": "Bad Wünnenberg",
      "bl": 5,
      "hs": 429.0,
      "mns": 1
    },
    {
      "a": "05911000",
      "j": 2025,
      "n": "bochum",
      "dn": "Bochum",
      "bl": 5,
      "hs": 715.0,
      "mns": 3
    },
    {
      "a": "05913000",
      "j": 2025,
      "n": "dortmund",
      "dn": "Dortmund",
      "bl": 5,
      "hs": 625.0,
      "mns": 3
    },
    {
      "a": "05914000",
      "j": 2024,
      "n": "hagen",
      "dn": "Hagen",
      "bl": 5,
      "hs": 750.0,
      "mns": 3
    },
    {
      "a": "05915000",
      "j": 2024,
      "n": "hamm",
      "dn": "Hamm",
      "bl": 5,
      "hs": 600.0,
      "mns": 2
    },
    {
      "a": "05916000",
      "j": 2024,
      "n": "herne",
      "dn": "Herne",
      "bl": 5,
      "hs": 830.0,
      "mns": 2
    },
    {
      "a": "05954004",
      "j": 2024,
      "n": "breckerfeld",
      "dn": "Breckerfeld",
      "bl": 5,
      "hs": 560.0,
      "mns": 3
    },
    {
      "a": "05954008",
      "j": 2024,
      "n": "ennepetal",
      "dn": "Ennepetal",
      "bl": 5,
      "hs": 790.0,
      "mns": 3
    },
    {
      "a": "05954012",
      "j": 2024,
      "n": "gevelsberg",
      "dn": "Gevelsberg",
      "bl": 5,
      "hs": 695.0,
      "mns": 3
    },
    {
      "a": "05954016",
      "j": 2024,
      "n": "hattingen",
      "dn": "Hattingen",
      "bl": 5,
      "hs": 875.0,
      "mns": 3
    },
    {
      "a": "05954020",
      "j": 2024,
      "n": "herdecke",
      "dn": "Herdecke",
      "bl": 5,
      "hs": 745.0,
      "mns": 3
    },
    {
      "a": "05954024",
      "j": 2024,
      "n": "schwelm",
      "dn": "Schwelm",
      "bl": 5,
      "hs": 742.0,
      "mns": 3
    },
    {
      "a": "05954028",
      "j": 2024,
      "n": "sprockhövel",
      "dn": "Sprockhövel",
      "bl": 5,
      "hs": 730.0,
      "mns": 3
    },
    {
      "a": "05954032",
      "j": 2024,
      "n": "wetter",
      "dn": "Wetter",
      "bl": 5,
      "hs": 755.0,
      "mns": 3
    },
    {
      "a": "05954036",
      "j": 2024,
      "n": "witten",
      "dn": "Witten",
      "bl": 5,
      "hs": 910.0,
      "mns": 3
    },
    {
      "a": "05958004",
      "j": 2024,
      "n": "arnsberg",
      "dn": "Arnsberg",
      "bl": 5,
      "hs": 573.0,
      "mns": 2
    },
    {
      "a": "05958008",
      "j": 2024,
      "n": "bestwig",
      "dn": "Bestwig",
      "bl": 5,
      "hs": 488.0,
      "mns": 1
    },
    {
      "a": "05958012",
      "j": 2024,
      "n": "brilon",
      "dn": "Brilon",
      "bl": 5,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "05958016",
      "j": 2024,
      "n": "eslohe",
      "dn": "Eslohe",
      "bl": 5,
      "hs": 449.0,
      "mns": 1
    },
    {
      "a": "05958020",
      "j": 2024,
      "n": "hallenberg",
      "dn": "Hallenberg",
      "bl": 5,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "05958024",
      "j": 2024,
      "n": "marsberg",
      "dn": "Marsberg",
      "bl": 5,
      "hs": 484.0,
      "mns": 1
    },
    {
      "a": "05958028",
      "j": 2024,
      "n": "medebach",
      "dn": "Medebach",
      "bl": 5,
      "hs": 505.0,
      "mns": 1
    },
    {
      "a": "05958032",
      "j": 2024,
      "n": "meschede",
      "dn": "Meschede",
      "bl": 5,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "05958036",
      "j": 2024,
      "n": "olsberg",
      "dn": "Olsberg",
      "bl": 5,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "05958040",
      "j": 2024,
      "n": "schmallenberg",
      "dn": "Schmallenberg",
      "bl": 5,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "05958044",
      "j": 2024,
      "n": "sundern (sauerland)",
      "dn": "Sundern (Sauerland)",
      "bl": 5,
      "hs": 576.0,
      "mns": 1
    },
    {
      "a": "05958048",
      "j": 2024,
      "n": "winterberg",
      "dn": "Winterberg",
      "bl": 5,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "05962004",
      "j": 2024,
      "n": "altena",
      "dn": "Altena",
      "bl": 5,
      "hs": 910.0,
      "mns": 1
    },
    {
      "a": "05962008",
      "j": 2024,
      "n": "balve",
      "dn": "Balve",
      "bl": 5,
      "hs": 675.0,
      "mns": 1
    },
    {
      "a": "05962012",
      "j": 2024,
      "n": "halver",
      "dn": "Halver",
      "bl": 5,
      "hs": 550.0,
      "mns": 3
    },
    {
      "a": "05962016",
      "j": 2024,
      "n": "hemer",
      "dn": "Hemer",
      "bl": 5,
      "hs": 680.0,
      "mns": 2
    },
    {
      "a": "05962020",
      "j": 2024,
      "n": "herscheid",
      "dn": "Herscheid",
      "bl": 5,
      "hs": 680.0,
      "mns": 2
    },
    {
      "a": "05962024",
      "j": 2024,
      "n": "iserlohn",
      "dn": "Iserlohn",
      "bl": 5,
      "hs": 589.0,
      "mns": 3
    },
    {
      "a": "05962028",
      "j": 2024,
      "n": "kierspe",
      "dn": "Kierspe",
      "bl": 5,
      "hs": 537.0,
      "mns": 2
    },
    {
      "a": "05962032",
      "j": 2024,
      "n": "lüdenscheid",
      "dn": "Lüdenscheid",
      "bl": 5,
      "hs": 766.0,
      "mns": 3
    },
    {
      "a": "05962036",
      "j": 2024,
      "n": "meinerzhagen",
      "dn": "Meinerzhagen",
      "bl": 5,
      "hs": 575.0,
      "mns": 2
    },
    {
      "a": "05962040",
      "j": 2024,
      "n": "menden (sauerland)",
      "dn": "Menden (Sauerland)",
      "bl": 5,
      "hs": 595.0,
      "mns": 2
    },
    {
      "a": "05962044",
      "j": 2024,
      "n": "nachrodt-wiblingwerde",
      "dn": "Nachrodt-Wiblingwerde",
      "bl": 5,
      "hs": 720.0,
      "mns": 2
    },
    {
      "a": "05962048",
      "j": 2024,
      "n": "neuenrade",
      "dn": "Neuenrade",
      "bl": 5,
      "hs": 600.0,
      "mns": 2
    },
    {
      "a": "05962052",
      "j": 2024,
      "n": "plettenberg",
      "dn": "Plettenberg",
      "bl": 5,
      "hs": 590.0,
      "mns": 2
    },
    {
      "a": "05962056",
      "j": 2024,
      "n": "schalksmühle",
      "dn": "Schalksmühle",
      "bl": 5,
      "hs": 455.0,
      "mns": 2
    },
    {
      "a": "05962060",
      "j": 2024,
      "n": "werdohl",
      "dn": "Werdohl",
      "bl": 5,
      "hs": 668.0,
      "mns": 2
    },
    {
      "a": "05966004",
      "j": 2024,
      "n": "attendorn",
      "dn": "Attendorn",
      "bl": 5,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "05966008",
      "j": 2024,
      "n": "drolshagen",
      "dn": "Drolshagen",
      "bl": 5,
      "hs": 629.0,
      "mns": 2
    },
    {
      "a": "05966012",
      "j": 2024,
      "n": "finnentrop",
      "dn": "Finnentrop",
      "bl": 5,
      "hs": 514.0,
      "mns": 1
    },
    {
      "a": "05966016",
      "j": 2024,
      "n": "kirchhundem",
      "dn": "Kirchhundem",
      "bl": 5,
      "hs": 560.0,
      "mns": 1
    },
    {
      "a": "05966020",
      "j": 2024,
      "n": "lennestadt",
      "dn": "Lennestadt",
      "bl": 5,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "05966024",
      "j": 2024,
      "n": "olpe",
      "dn": "Olpe",
      "bl": 5,
      "hs": 493.0,
      "mns": 3
    },
    {
      "a": "05966028",
      "j": 2024,
      "n": "wenden",
      "dn": "Wenden",
      "bl": 5,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "05970004",
      "j": 2024,
      "n": "bad berleburg",
      "dn": "Bad Berleburg",
      "bl": 5,
      "hs": 495.0,
      "mns": 1
    },
    {
      "a": "05970008",
      "j": 2024,
      "n": "burbach",
      "dn": "Burbach",
      "bl": 5,
      "hs": 443.0,
      "mns": 2
    },
    {
      "a": "05970012",
      "j": 2024,
      "n": "erndtebrück",
      "dn": "Erndtebrück",
      "bl": 5,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "05970016",
      "j": 2024,
      "n": "freudenberg",
      "dn": "Freudenberg",
      "bl": 5,
      "hs": 650.0,
      "mns": 2
    },
    {
      "a": "05970020",
      "j": 2024,
      "n": "hilchenbach",
      "dn": "Hilchenbach",
      "bl": 5,
      "hs": 640.0,
      "mns": 2
    },
    {
      "a": "05970024",
      "j": 2024,
      "n": "kreuztal",
      "dn": "Kreuztal",
      "bl": 5,
      "hs": 790.0,
      "mns": 2
    },
    {
      "a": "05970028",
      "j": 2024,
      "n": "bad laasphe",
      "dn": "Bad Laasphe",
      "bl": 5,
      "hs": 650.0,
      "mns": 1
    },
    {
      "a": "05970032",
      "j": 2024,
      "n": "netphen",
      "dn": "Netphen",
      "bl": 5,
      "hs": 670.0,
      "mns": 2
    },
    {
      "a": "05970036",
      "j": 2024,
      "n": "neunkirchen",
      "dn": "Neunkirchen",
      "bl": 5,
      "hs": 590.0,
      "mns": 2
    },
    {
      "a": "05970040",
      "j": 2024,
      "n": "siegen",
      "dn": "Siegen",
      "bl": 5,
      "hs": 684.0,
      "mns": 3
    },
    {
      "a": "05970044",
      "j": 2024,
      "n": "wilnsdorf",
      "dn": "Wilnsdorf",
      "bl": 5,
      "hs": 695.0,
      "mns": 1
    },
    {
      "a": "05974004",
      "j": 2024,
      "n": "anröchte",
      "dn": "Anröchte",
      "bl": 5,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "05974008",
      "j": 2024,
      "n": "bad sassendorf",
      "dn": "Bad Sassendorf",
      "bl": 5,
      "hs": 495.0,
      "mns": 2
    },
    {
      "a": "05974012",
      "j": 2024,
      "n": "ense",
      "dn": "Ense",
      "bl": 5,
      "hs": 479.0,
      "mns": 1
    },
    {
      "a": "05974016",
      "j": 2024,
      "n": "erwitte",
      "dn": "Erwitte",
      "bl": 5,
      "hs": 519.0,
      "mns": 1
    },
    {
      "a": "05974020",
      "j": 2024,
      "n": "geseke",
      "dn": "Geseke",
      "bl": 5,
      "hs": 590.0,
      "mns": 2
    },
    {
      "a": "05974024",
      "j": 2024,
      "n": "lippetal",
      "dn": "Lippetal",
      "bl": 5,
      "hs": 501.0,
      "mns": 1
    },
    {
      "a": "05974028",
      "j": 2024,
      "n": "lippstadt",
      "dn": "Lippstadt",
      "bl": 5,
      "hs": 460.0,
      "mns": 2
    },
    {
      "a": "05974032",
      "j": 2024,
      "n": "möhnesee",
      "dn": "Möhnesee",
      "bl": 5,
      "hs": 591.0,
      "mns": 1
    },
    {
      "a": "05974036",
      "j": 2025,
      "n": "rüthen",
      "dn": "Rüthen",
      "bl": 5,
      "hs": 900.0,
      "mns": 1
    },
    {
      "a": "05974040",
      "j": 2024,
      "n": "soest",
      "dn": "Soest",
      "bl": 5,
      "hs": 607.0,
      "mns": 2
    },
    {
      "a": "05974044",
      "j": 2024,
      "n": "warstein",
      "dn": "Warstein",
      "bl": 5,
      "hs": 730.0,
      "mns": 1
    },
    {
      "a": "05974048",
      "j": 2024,
      "n": "welver",
      "dn": "Welver",
      "bl": 5,
      "hs": 799.0,
      "mns": 2
    },
    {
      "a": "05974052",
      "j": 2024,
      "n": "werl",
      "dn": "Werl",
      "bl": 5,
      "hs": 800.0,
      "mns": 2
    },
    {
      "a": "05974056",
      "j": 2024,
      "n": "wickede",
      "dn": "Wickede",
      "bl": 5,
      "hs": 690.0,
      "mns": 2
    },
    {
      "a": "05978004",
      "j": 2024,
      "n": "bergkamen",
      "dn": "Bergkamen",
      "bl": 5,
      "hs": 670.0,
      "mns": 3
    },
    {
      "a": "05978008",
      "j": 2024,
      "n": "bönen",
      "dn": "Bönen",
      "bl": 5,
      "hs": 940.0,
      "mns": 2
    },
    {
      "a": "05978012",
      "j": 2024,
      "n": "fröndenberg/ruhr",
      "dn": "Fröndenberg/Ruhr",
      "bl": 5,
      "hs": 895.0,
      "mns": 2
    },
    {
      "a": "05978016",
      "j": 2024,
      "n": "holzwickede",
      "dn": "Holzwickede",
      "bl": 5,
      "hs": 560.0,
      "mns": 3
    },
    {
      "a": "05978020",
      "j": 2024,
      "n": "kamen",
      "dn": "Kamen",
      "bl": 5,
      "hs": 940.0,
      "mns": 3
    },
    {
      "a": "05978024",
      "j": 2024,
      "n": "lünen",
      "dn": "Lünen",
      "bl": 5,
      "hs": 760.0,
      "mns": 3
    },
    {
      "a": "05978028",
      "j": 2024,
      "n": "schwerte",
      "dn": "Schwerte",
      "bl": 5,
      "hs": 880.0,
      "mns": 3
    },
    {
      "a": "05978032",
      "j": 2024,
      "n": "selm",
      "dn": "Selm",
      "bl": 5,
      "hs": 825.0,
      "mns": 3
    },
    {
      "a": "05978036",
      "j": 2024,
      "n": "unna",
      "dn": "Unna",
      "bl": 5,
      "hs": 843.0,
      "mns": 3
    },
    {
      "a": "05978040",
      "j": 2024,
      "n": "werne",
      "dn": "Werne",
      "bl": 5,
      "hs": 665.0,
      "mns": 2
    }
  ],
  "6": [
    {
      "a": "06411000",
      "j": 2024,
      "n": "darmstadt",
      "dn": "Darmstadt",
      "bl": 6,
      "hs": 875.0,
      "mns": 6
    },
    {
      "a": "06412000",
      "j": 2024,
      "n": "frankfurt am main",
      "dn": "Frankfurt am Main",
      "bl": 6,
      "hs": 500.0,
      "mns": 6
    },
    {
      "a": "06413000",
      "j": 2024,
      "n": "offenbach am main",
      "dn": "Offenbach am Main",
      "bl": 6,
      "hs": 895.0,
      "mns": 6
    },
    {
      "a": "06414000",
      "j": 2024,
      "n": "wiesbaden",
      "dn": "Wiesbaden",
      "bl": 6,
      "hs": 492.0,
      "mns": 6
    },
    {
      "a": "06431001",
      "j": 2024,
      "n": "abtsteinach",
      "dn": "Abtsteinach",
      "bl": 6,
      "hs": 600.0,
      "mns": 2
    },
    {
      "a": "06431002",
      "j": 2024,
      "n": "bensheim",
      "dn": "Bensheim",
      "bl": 6,
      "hs": 620.0,
      "mns": 3
    },
    {
      "a": "06431003",
      "j": 2024,
      "n": "biblis",
      "dn": "Biblis",
      "bl": 6,
      "hs": 575.0,
      "mns": 2
    },
    {
      "a": "06431004",
      "j": 2024,
      "n": "birkenau",
      "dn": "Birkenau",
      "bl": 6,
      "hs": 600.0,
      "mns": 2
    },
    {
      "a": "06431005",
      "j": 2024,
      "n": "bürstadt",
      "dn": "Bürstadt",
      "bl": 6,
      "hs": 570.0,
      "mns": 2
    },
    {
      "a": "06431006",
      "j": 2025,
      "n": "einhausen",
      "dn": "Einhausen",
      "bl": 6,
      "hs": 595.0,
      "mns": 2
    },
    {
      "a": "06431007",
      "j": 2024,
      "n": "fürth",
      "dn": "Fürth",
      "bl": 6,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "06431008",
      "j": 2024,
      "n": "gorxheimertal",
      "dn": "Gorxheimertal",
      "bl": 6,
      "hs": 500.0,
      "mns": 2
    },
    {
      "a": "06431009",
      "j": 2024,
      "n": "grasellenbach",
      "dn": "Grasellenbach",
      "bl": 6,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "06431010",
      "j": 2024,
      "n": "groß-rohrheim",
      "dn": "Groß-Rohrheim",
      "bl": 6,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "06431011",
      "j": 2024,
      "n": "heppenheim",
      "dn": "Heppenheim",
      "bl": 6,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "06431012",
      "j": 2024,
      "n": "hirschhorn (neckar)",
      "dn": "Hirschhorn (Neckar)",
      "bl": 6,
      "hs": 700.0,
      "mns": 2
    },
    {
      "a": "06431013",
      "j": 2024,
      "n": "lampertheim",
      "dn": "Lampertheim",
      "bl": 6,
      "hs": 580.0,
      "mns": 2
    },
    {
      "a": "06431014",
      "j": 2024,
      "n": "lautertal",
      "dn": "Lautertal",
      "bl": 6,
      "hs": 850.0,
      "mns": 2
    },
    {
      "a": "06431015",
      "j": 2024,
      "n": "lindenfels",
      "dn": "Lindenfels",
      "bl": 6,
      "hs": 870.0,
      "mns": 2
    },
    {
      "a": "06431016",
      "j": 2024,
      "n": "lorsch",
      "dn": "Lorsch",
      "bl": 6,
      "hs": 695.0,
      "mns": 4
    },
    {
      "a": "06431017",
      "j": 2024,
      "n": "mörlenbach",
      "dn": "Mörlenbach",
      "bl": 6,
      "hs": 695.0,
      "mns": 2
    },
    {
      "a": "06431018",
      "j": 2024,
      "n": "neckarsteinach",
      "dn": "Neckarsteinach",
      "bl": 6,
      "hs": 850.0,
      "mns": 2
    },
    {
      "a": "06431019",
      "j": 2024,
      "n": "rimbach",
      "dn": "Rimbach",
      "bl": 6,
      "hs": 550.0,
      "mns": 2
    },
    {
      "a": "06431020",
      "j": 2024,
      "n": "viernheim",
      "dn": "Viernheim",
      "bl": 6,
      "hs": 620.0,
      "mns": 3
    },
    {
      "a": "06431021",
      "j": 2024,
      "n": "wald-michelbach",
      "dn": "Wald-Michelbach",
      "bl": 6,
      "hs": 365.0,
      "mns": 2
    },
    {
      "a": "06431022",
      "j": 2024,
      "n": "zwingenberg",
      "dn": "Zwingenberg",
      "bl": 6,
      "hs": 580.0,
      "mns": 2
    },
    {
      "a": "06432001",
      "j": 2024,
      "n": "alsbach-hähnlein",
      "dn": "Alsbach-Hähnlein",
      "bl": 6,
      "hs": 445.0,
      "mns": 4
    },
    {
      "a": "06432002",
      "j": 2024,
      "n": "babenhausen",
      "dn": "Babenhausen",
      "bl": 6,
      "hs": 995.0,
      "mns": 3
    },
    {
      "a": "06432003",
      "j": 2024,
      "n": "bickenbach",
      "dn": "Bickenbach",
      "bl": 6,
      "hs": 757.0,
      "mns": 4
    },
    {
      "a": "06432004",
      "j": 2025,
      "n": "dieburg",
      "dn": "Dieburg",
      "bl": 6,
      "hs": 419.0,
      "mns": 4
    },
    {
      "a": "06432005",
      "j": 2024,
      "n": "eppertshausen",
      "dn": "Eppertshausen",
      "bl": 6,
      "hs": 435.0,
      "mns": 4
    },
    {
      "a": "06432006",
      "j": 2024,
      "n": "erzhausen",
      "dn": "Erzhausen",
      "bl": 6,
      "hs": 630.0,
      "mns": 4
    },
    {
      "a": "06432007",
      "j": 2024,
      "n": "fischbachtal",
      "dn": "Fischbachtal",
      "bl": 6,
      "hs": 720.0,
      "mns": 4
    },
    {
      "a": "06432008",
      "j": 2024,
      "n": "griesheim",
      "dn": "Griesheim",
      "bl": 6,
      "hs": 650.0,
      "mns": 5
    },
    {
      "a": "06432009",
      "j": 2024,
      "n": "groß-bieberau",
      "dn": "Groß-Bieberau",
      "bl": 6,
      "hs": 640.0,
      "mns": 4
    },
    {
      "a": "06432010",
      "j": 2025,
      "n": "groß-umstadt",
      "dn": "Groß-Umstadt",
      "bl": 6,
      "hs": 695.0,
      "mns": 4
    },
    {
      "a": "06432011",
      "j": 2024,
      "n": "groß-zimmern",
      "dn": "Groß-Zimmern",
      "bl": 6,
      "hs": 500.0,
      "mns": 4
    },
    {
      "a": "06432012",
      "j": 2024,
      "n": "messel",
      "dn": "Messel",
      "bl": 6,
      "hs": 895.0,
      "mns": 4
    },
    {
      "a": "06432013",
      "j": 2024,
      "n": "modautal",
      "dn": "Modautal",
      "bl": 6,
      "hs": 500.0,
      "mns": 4
    },
    {
      "a": "06432014",
      "j": 2024,
      "n": "mühltal",
      "dn": "Mühltal",
      "bl": 6,
      "hs": 850.0,
      "mns": 5
    },
    {
      "a": "06432015",
      "j": 2024,
      "n": "münster (hessen)",
      "dn": "Münster (Hessen)",
      "bl": 6,
      "hs": 528.0,
      "mns": 4
    },
    {
      "a": "06432016",
      "j": 2024,
      "n": "ober-ramstadt",
      "dn": "Ober-Ramstadt",
      "bl": 6,
      "hs": 850.0,
      "mns": 4
    },
    {
      "a": "06432017",
      "j": 2024,
      "n": "otzberg",
      "dn": "Otzberg",
      "bl": 6,
      "hs": 890.0,
      "mns": 4
    },
    {
      "a": "06432018",
      "j": 2024,
      "n": "pfungstadt",
      "dn": "Pfungstadt",
      "bl": 6,
      "hs": 490.0,
      "mns": 4
    },
    {
      "a": "06432019",
      "j": 2024,
      "n": "reinheim",
      "dn": "Reinheim",
      "bl": 6,
      "hs": 525.0,
      "mns": 3
    },
    {
      "a": "06432020",
      "j": 2024,
      "n": "roßdorf",
      "dn": "Roßdorf",
      "bl": 6,
      "hs": 500.0,
      "mns": 4
    },
    {
      "a": "06432021",
      "j": 2024,
      "n": "schaafheim",
      "dn": "Schaafheim",
      "bl": 6,
      "hs": 500.0,
      "mns": 4
    },
    {
      "a": "06432022",
      "j": 2024,
      "n": "seeheim-jugenheim",
      "dn": "Seeheim-Jugenheim",
      "bl": 6,
      "hs": 850.0,
      "mns": 5
    },
    {
      "a": "06432023",
      "j": 2025,
      "n": "weiterstadt",
      "dn": "Weiterstadt",
      "bl": 6,
      "hs": 835.0,
      "mns": 5
    },
    {
      "a": "06433001",
      "j": 2024,
      "n": "biebesheim am rhein",
      "dn": "Biebesheim am Rhein",
      "bl": 6,
      "hs": 600.0,
      "mns": 3
    },
    {
      "a": "06433002",
      "j": 2025,
      "n": "bischofsheim",
      "dn": "Bischofsheim",
      "bl": 6,
      "hs": 755.0,
      "mns": 4
    },
    {
      "a": "06433003",
      "j": 2024,
      "n": "büttelborn",
      "dn": "Büttelborn",
      "bl": 6,
      "hs": 660.0,
      "mns": 4
    },
    {
      "a": "06433004",
      "j": 2024,
      "n": "gernsheim",
      "dn": "Gernsheim",
      "bl": 6,
      "hs": 410.0,
      "mns": 3
    },
    {
      "a": "06433005",
      "j": 2024,
      "n": "ginsheim-gustavsburg",
      "dn": "Ginsheim-Gustavsburg",
      "bl": 6,
      "hs": 825.0,
      "mns": 4
    },
    {
      "a": "06433006",
      "j": 2024,
      "n": "groß-gerau",
      "dn": "Groß-Gerau",
      "bl": 6,
      "hs": 620.0,
      "mns": 5
    },
    {
      "a": "06433007",
      "j": 2024,
      "n": "kelsterbach",
      "dn": "Kelsterbach",
      "bl": 6,
      "hs": 690.0,
      "mns": 4
    },
    {
      "a": "06433008",
      "j": 2024,
      "n": "mörfelden-walldorf",
      "dn": "Mörfelden-Walldorf",
      "bl": 6,
      "hs": 790.0,
      "mns": 5
    },
    {
      "a": "06433009",
      "j": 2024,
      "n": "nauheim",
      "dn": "Nauheim",
      "bl": 6,
      "hs": 1325.0,
      "mns": 3
    },
    {
      "a": "06433010",
      "j": 2024,
      "n": "raunheim",
      "dn": "Raunheim",
      "bl": 6,
      "hs": 590.0,
      "mns": 6
    },
    {
      "a": "06433011",
      "j": 2024,
      "n": "riedstadt",
      "dn": "Riedstadt",
      "bl": 6,
      "hs": 985.0,
      "mns": 4
    },
    {
      "a": "06433012",
      "j": 2024,
      "n": "rüsselsheim am main",
      "dn": "Rüsselsheim am Main",
      "bl": 6,
      "hs": 800.0,
      "mns": 5
    },
    {
      "a": "06433013",
      "j": 2024,
      "n": "stockstadt am rhein",
      "dn": "Stockstadt am Rhein",
      "bl": 6,
      "hs": 700.0,
      "mns": 3
    },
    {
      "a": "06433014",
      "j": 2024,
      "n": "trebur",
      "dn": "Trebur",
      "bl": 6,
      "hs": 711.0,
      "mns": 4
    },
    {
      "a": "06434001",
      "j": 2025,
      "n": "bad homburg vor der höhe",
      "dn": "Bad Homburg vor der Höhe",
      "bl": 6,
      "hs": 900.0,
      "mns": 6
    },
    {
      "a": "06434002",
      "j": 2024,
      "n": "friedrichsdorf",
      "dn": "Friedrichsdorf",
      "bl": 6,
      "hs": 595.0,
      "mns": 5
    },
    {
      "a": "06434003",
      "j": 2024,
      "n": "glashütten",
      "dn": "Glashütten",
      "bl": 6,
      "hs": 725.0,
      "mns": 4
    },
    {
      "a": "06434004",
      "j": 2024,
      "n": "grävenwiesbach",
      "dn": "Grävenwiesbach",
      "bl": 6,
      "hs": 690.0,
      "mns": 4
    },
    {
      "a": "06434005",
      "j": 2024,
      "n": "königstein im taunus",
      "dn": "Königstein im Taunus",
      "bl": 6,
      "hs": 540.0,
      "mns": 5
    },
    {
      "a": "06434006",
      "j": 2024,
      "n": "kronberg im taunus",
      "dn": "Kronberg im Taunus",
      "bl": 6,
      "hs": 470.0,
      "mns": 5
    },
    {
      "a": "06434007",
      "j": 2024,
      "n": "neu-anspach",
      "dn": "Neu-Anspach",
      "bl": 6,
      "hs": 758.0,
      "mns": 4
    },
    {
      "a": "06434008",
      "j": 2024,
      "n": "oberursel (taunus)",
      "dn": "Oberursel (Taunus)",
      "bl": 6,
      "hs": 947.0,
      "mns": 6
    },
    {
      "a": "06434009",
      "j": 2024,
      "n": "schmitten im taunus",
      "dn": "Schmitten im Taunus",
      "bl": 6,
      "hs": 660.0,
      "mns": 4
    },
    {
      "a": "06434010",
      "j": 2024,
      "n": "steinbach (taunus)",
      "dn": "Steinbach (Taunus)",
      "bl": 6,
      "hs": 900.0,
      "mns": 5
    },
    {
      "a": "06434011",
      "j": 2024,
      "n": "usingen",
      "dn": "Usingen",
      "bl": 6,
      "hs": 605.0,
      "mns": 4
    },
    {
      "a": "06434012",
      "j": 2024,
      "n": "wehrheim",
      "dn": "Wehrheim",
      "bl": 6,
      "hs": 479.0,
      "mns": 4
    },
    {
      "a": "06434013",
      "j": 2024,
      "n": "weilrod",
      "dn": "Weilrod",
      "bl": 6,
      "hs": 450.0,
      "mns": 4
    },
    {
      "a": "06435001",
      "j": 2024,
      "n": "bad orb",
      "dn": "Bad Orb",
      "bl": 6,
      "hs": 500.0,
      "mns": 2
    },
    {
      "a": "06435002",
      "j": 2024,
      "n": "bad soden-salmünster",
      "dn": "Bad Soden-Salmünster",
      "bl": 6,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "06435003",
      "j": 2024,
      "n": "biebergemünd",
      "dn": "Biebergemünd",
      "bl": 6,
      "hs": 220.0,
      "mns": 2
    },
    {
      "a": "06435004",
      "j": 2024,
      "n": "birstein",
      "dn": "Birstein",
      "bl": 6,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "06435005",
      "j": 2024,
      "n": "brachttal",
      "dn": "Brachttal",
      "bl": 6,
      "hs": 540.0,
      "mns": 2
    },
    {
      "a": "06435006",
      "j": 2024,
      "n": "bruchköbel",
      "dn": "Bruchköbel",
      "bl": 6,
      "hs": 590.0,
      "mns": 3
    },
    {
      "a": "06435007",
      "j": 2024,
      "n": "erlensee",
      "dn": "Erlensee",
      "bl": 6,
      "hs": 675.0,
      "mns": 4
    },
    {
      "a": "06435008",
      "j": 2024,
      "n": "flörsbachtal",
      "dn": "Flörsbachtal",
      "bl": 6,
      "hs": 359.0,
      "mns": 2
    },
    {
      "a": "06435009",
      "j": 2024,
      "n": "freigericht",
      "dn": "Freigericht",
      "bl": 6,
      "hs": 495.0,
      "mns": 2
    },
    {
      "a": "06435010",
      "j": 2024,
      "n": "gelnhausen",
      "dn": "Gelnhausen",
      "bl": 6,
      "hs": 550.0,
      "mns": 3
    },
    {
      "a": "06435011",
      "j": 2024,
      "n": "großkrotzenburg",
      "dn": "Großkrotzenburg",
      "bl": 6,
      "hs": 690.0,
      "mns": 2
    },
    {
      "a": "06435012",
      "j": 2024,
      "n": "gründau",
      "dn": "Gründau",
      "bl": 6,
      "hs": 200.0,
      "mns": 2
    },
    {
      "a": "06435013",
      "j": 2024,
      "n": "hammersbach",
      "dn": "Hammersbach",
      "bl": 6,
      "hs": 680.0,
      "mns": 2
    },
    {
      "a": "06435014",
      "j": 2024,
      "n": "hanau",
      "dn": "Hanau",
      "bl": 6,
      "hs": 595.0,
      "mns": 4
    },
    {
      "a": "06435015",
      "j": 2024,
      "n": "hasselroth",
      "dn": "Hasselroth",
      "bl": 6,
      "hs": 495.0,
      "mns": 2
    },
    {
      "a": "06435016",
      "j": 2024,
      "n": "jossgrund",
      "dn": "Jossgrund",
      "bl": 6,
      "hs": 365.0,
      "mns": 2
    },
    {
      "a": "06435017",
      "j": 2024,
      "n": "langenselbold",
      "dn": "Langenselbold",
      "bl": 6,
      "hs": 660.0,
      "mns": 3
    },
    {
      "a": "06435018",
      "j": 2024,
      "n": "linsengericht",
      "dn": "Linsengericht",
      "bl": 6,
      "hs": 495.0,
      "mns": 2
    },
    {
      "a": "06435019",
      "j": 2024,
      "n": "maintal",
      "dn": "Maintal",
      "bl": 6,
      "hs": 580.0,
      "mns": 5
    },
    {
      "a": "06435020",
      "j": 2024,
      "n": "neuberg",
      "dn": "Neuberg",
      "bl": 6,
      "hs": 850.0,
      "mns": 2
    },
    {
      "a": "06435021",
      "j": 2024,
      "n": "nidderau",
      "dn": "Nidderau",
      "bl": 6,
      "hs": 690.0,
      "mns": 3
    },
    {
      "a": "06435022",
      "j": 2024,
      "n": "niederdorfelden",
      "dn": "Niederdorfelden",
      "bl": 6,
      "hs": 550.0,
      "mns": 2
    },
    {
      "a": "06435023",
      "j": 2024,
      "n": "rodenbach",
      "dn": "Rodenbach",
      "bl": 6,
      "hs": 690.0,
      "mns": 4
    },
    {
      "a": "06435024",
      "j": 2024,
      "n": "ronneburg",
      "dn": "Ronneburg",
      "bl": 6,
      "hs": 700.0,
      "mns": 2
    },
    {
      "a": "06435025",
      "j": 2024,
      "n": "schlüchtern",
      "dn": "Schlüchtern",
      "bl": 6,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "06435026",
      "j": 2024,
      "n": "schöneck",
      "dn": "Schöneck",
      "bl": 6,
      "hs": 780.0,
      "mns": 4
    },
    {
      "a": "06435027",
      "j": 2024,
      "n": "sinntal",
      "dn": "Sinntal",
      "bl": 6,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "06435028",
      "j": 2024,
      "n": "steinau an der straße",
      "dn": "Steinau an der Straße",
      "bl": 6,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "06435029",
      "j": 2024,
      "n": "wächtersbach",
      "dn": "Wächtersbach",
      "bl": 6,
      "hs": 490.0,
      "mns": 2
    },
    {
      "a": "06436001",
      "j": 2024,
      "n": "bad soden am taunus",
      "dn": "Bad Soden am Taunus",
      "bl": 6,
      "hs": 632.0,
      "mns": 7
    },
    {
      "a": "06436002",
      "j": 2024,
      "n": "eppstein",
      "dn": "Eppstein",
      "bl": 6,
      "hs": 680.0,
      "mns": 5
    },
    {
      "a": "06436003",
      "j": 2024,
      "n": "eschborn",
      "dn": "Eschborn",
      "bl": 6,
      "hs": 140.0,
      "mns": 6
    },
    {
      "a": "06436004",
      "j": 2024,
      "n": "flörsheim am main",
      "dn": "Flörsheim am Main",
      "bl": 6,
      "hs": 550.0,
      "mns": 5
    },
    {
      "a": "06436005",
      "j": 2024,
      "n": "hattersheim am main",
      "dn": "Hattersheim am Main",
      "bl": 6,
      "hs": 590.0,
      "mns": 6
    },
    {
      "a": "06436006",
      "j": 2024,
      "n": "hochheim am main",
      "dn": "Hochheim am Main",
      "bl": 6,
      "hs": 540.0,
      "mns": 5
    },
    {
      "a": "06436007",
      "j": 2025,
      "n": "hofheim am taunus",
      "dn": "Hofheim am Taunus",
      "bl": 6,
      "hs": 658.0,
      "mns": 5
    },
    {
      "a": "06436008",
      "j": 2024,
      "n": "kelkheim",
      "dn": "Kelkheim",
      "bl": 6,
      "hs": 520.0,
      "mns": 6
    },
    {
      "a": "06436009",
      "j": 2024,
      "n": "kriftel",
      "dn": "Kriftel",
      "bl": 6,
      "hs": 550.0,
      "mns": 6
    },
    {
      "a": "06436010",
      "j": 2024,
      "n": "liederbach am taunus",
      "dn": "Liederbach am Taunus",
      "bl": 6,
      "hs": 550.0,
      "mns": 6
    },
    {
      "a": "06436011",
      "j": 2024,
      "n": "schwalbach am taunus",
      "dn": "Schwalbach am Taunus",
      "bl": 6,
      "hs": 400.0,
      "mns": 5
    },
    {
      "a": "06436012",
      "j": 2024,
      "n": "sulzbach",
      "dn": "Sulzbach",
      "bl": 6,
      "hs": 365.0,
      "mns": 6
    },
    {
      "a": "06437001",
      "j": 2024,
      "n": "bad könig",
      "dn": "Bad König",
      "bl": 6,
      "hs": 730.0,
      "mns": 2
    },
    {
      "a": "06437003",
      "j": 2024,
      "n": "brensbach",
      "dn": "Brensbach",
      "bl": 6,
      "hs": 640.0,
      "mns": 2
    },
    {
      "a": "06437004",
      "j": 2024,
      "n": "breuberg",
      "dn": "Breuberg",
      "bl": 6,
      "hs": 850.0,
      "mns": 2
    },
    {
      "a": "06437005",
      "j": 2024,
      "n": "brombachtal",
      "dn": "Brombachtal",
      "bl": 6,
      "hs": 1050.0,
      "mns": 2
    },
    {
      "a": "06437006",
      "j": 2024,
      "n": "erbach",
      "dn": "Erbach",
      "bl": 6,
      "hs": 530.0,
      "mns": 3
    },
    {
      "a": "06437007",
      "j": 2024,
      "n": "fränkisch-crumbach",
      "dn": "Fränkisch-Crumbach",
      "bl": 6,
      "hs": 700.0,
      "mns": 2
    },
    {
      "a": "06437009",
      "j": 2024,
      "n": "höchst im odenwald",
      "dn": "Höchst im Odenwald",
      "bl": 6,
      "hs": 520.0,
      "mns": 2
    },
    {
      "a": "06437010",
      "j": 2024,
      "n": "lützelbach",
      "dn": "Lützelbach",
      "bl": 6,
      "hs": 850.0,
      "mns": 2
    },
    {
      "a": "06437011",
      "j": 2024,
      "n": "michelstadt",
      "dn": "Michelstadt",
      "bl": 6,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "06437012",
      "j": 2024,
      "n": "mossautal",
      "dn": "Mossautal",
      "bl": 6,
      "hs": 500.0,
      "mns": 2
    },
    {
      "a": "06437013",
      "j": 2024,
      "n": "reichelsheim",
      "dn": "Reichelsheim",
      "bl": 6,
      "hs": 670.0,
      "mns": 2
    },
    {
      "a": "06437016",
      "j": 2024,
      "n": "oberzent",
      "dn": "Oberzent",
      "bl": 6,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "06438001",
      "j": 2024,
      "n": "dietzenbach",
      "dn": "Dietzenbach",
      "bl": 6,
      "hs": 800.0,
      "mns": 6
    },
    {
      "a": "06438002",
      "j": 2024,
      "n": "dreieich",
      "dn": "Dreieich",
      "bl": 6,
      "hs": 500.0,
      "mns": 5
    },
    {
      "a": "06438003",
      "j": 2024,
      "n": "egelsbach",
      "dn": "Egelsbach",
      "bl": 6,
      "hs": 815.0,
      "mns": 6
    },
    {
      "a": "06438004",
      "j": 2024,
      "n": "hainburg",
      "dn": "Hainburg",
      "bl": 6,
      "hs": 496.0,
      "mns": 3
    },
    {
      "a": "06438005",
      "j": 2024,
      "n": "heusenstamm",
      "dn": "Heusenstamm",
      "bl": 6,
      "hs": 950.0,
      "mns": 5
    },
    {
      "a": "06438006",
      "j": 2024,
      "n": "langen",
      "dn": "Langen",
      "bl": 6,
      "hs": 850.0,
      "mns": 6
    },
    {
      "a": "06438007",
      "j": 2024,
      "n": "mainhausen",
      "dn": "Mainhausen",
      "bl": 6,
      "hs": 494.0,
      "mns": 4
    },
    {
      "a": "06438008",
      "j": 2024,
      "n": "mühlheim am main",
      "dn": "Mühlheim am Main",
      "bl": 6,
      "hs": 800.0,
      "mns": 5
    },
    {
      "a": "06438009",
      "j": 2024,
      "n": "neu-isenburg",
      "dn": "Neu-Isenburg",
      "bl": 6,
      "hs": 350.0,
      "mns": 6
    },
    {
      "a": "06438010",
      "j": 2024,
      "n": "obertshausen",
      "dn": "Obertshausen",
      "bl": 6,
      "hs": 600.0,
      "mns": 4
    },
    {
      "a": "06438011",
      "j": 2024,
      "n": "rodgau",
      "dn": "Rodgau",
      "bl": 6,
      "hs": 700.0,
      "mns": 5
    },
    {
      "a": "06438012",
      "j": 2024,
      "n": "rödermark",
      "dn": "Rödermark",
      "bl": 6,
      "hs": 715.0,
      "mns": 4
    },
    {
      "a": "06438013",
      "j": 2025,
      "n": "seligenstadt",
      "dn": "Seligenstadt",
      "bl": 6,
      "hs": 850.0,
      "mns": 4
    },
    {
      "a": "06439001",
      "j": 2024,
      "n": "aarbergen",
      "dn": "Aarbergen",
      "bl": 6,
      "hs": 715.0,
      "mns": 2
    },
    {
      "a": "06439002",
      "j": 2024,
      "n": "bad schwalbach",
      "dn": "Bad Schwalbach",
      "bl": 6,
      "hs": 845.0,
      "mns": 3
    },
    {
      "a": "06439003",
      "j": 2024,
      "n": "eltville am rhein",
      "dn": "Eltville am Rhein",
      "bl": 6,
      "hs": 620.0,
      "mns": 5
    },
    {
      "a": "06439004",
      "j": 2024,
      "n": "geisenheim",
      "dn": "Geisenheim",
      "bl": 6,
      "hs": 480.0,
      "mns": 4
    },
    {
      "a": "06439005",
      "j": 2024,
      "n": "heidenrod",
      "dn": "Heidenrod",
      "bl": 6,
      "hs": 365.0,
      "mns": 2
    },
    {
      "a": "06439006",
      "j": 2024,
      "n": "hohenstein",
      "dn": "Hohenstein",
      "bl": 6,
      "hs": 735.0,
      "mns": 2
    },
    {
      "a": "06439007",
      "j": 2024,
      "n": "hünstetten",
      "dn": "Hünstetten",
      "bl": 6,
      "hs": 580.0,
      "mns": 1
    },
    {
      "a": "06439008",
      "j": 2024,
      "n": "idstein",
      "dn": "Idstein",
      "bl": 6,
      "hs": 450.0,
      "mns": 3
    },
    {
      "a": "06439009",
      "j": 2024,
      "n": "kiedrich",
      "dn": "Kiedrich",
      "bl": 6,
      "hs": 650.0,
      "mns": 2
    },
    {
      "a": "06439010",
      "j": 2024,
      "n": "lorch",
      "dn": "Lorch",
      "bl": 6,
      "hs": 1050.0,
      "mns": 2
    },
    {
      "a": "06439011",
      "j": 2024,
      "n": "niedernhausen",
      "dn": "Niedernhausen",
      "bl": 6,
      "hs": 560.0,
      "mns": 4
    },
    {
      "a": "06439012",
      "j": 2024,
      "n": "oestrich-winkel",
      "dn": "Oestrich-Winkel",
      "bl": 6,
      "hs": 850.0,
      "mns": 4
    },
    {
      "a": "06439013",
      "j": 2024,
      "n": "rüdesheim am rhein",
      "dn": "Rüdesheim am Rhein",
      "bl": 6,
      "hs": 790.0,
      "mns": 2
    },
    {
      "a": "06439014",
      "j": 2024,
      "n": "schlangenbad",
      "dn": "Schlangenbad",
      "bl": 6,
      "hs": 592.0,
      "mns": 2
    },
    {
      "a": "06439015",
      "j": 2024,
      "n": "taunusstein",
      "dn": "Taunusstein",
      "bl": 6,
      "hs": 600.0,
      "mns": 4
    },
    {
      "a": "06439016",
      "j": 2024,
      "n": "waldems",
      "dn": "Waldems",
      "bl": 6,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "06439017",
      "j": 2024,
      "n": "walluf",
      "dn": "Walluf",
      "bl": 6,
      "hs": 365.0,
      "mns": 2
    },
    {
      "a": "06440001",
      "j": 2024,
      "n": "altenstadt",
      "dn": "Altenstadt",
      "bl": 6,
      "hs": 395.0,
      "mns": 3
    },
    {
      "a": "06440002",
      "j": 2024,
      "n": "bad nauheim",
      "dn": "Bad Nauheim",
      "bl": 6,
      "hs": 560.0,
      "mns": 4
    },
    {
      "a": "06440003",
      "j": 2024,
      "n": "bad vilbel",
      "dn": "Bad Vilbel",
      "bl": 6,
      "hs": 450.0,
      "mns": 5
    },
    {
      "a": "06440004",
      "j": 2024,
      "n": "büdingen",
      "dn": "Büdingen",
      "bl": 6,
      "hs": 431.0,
      "mns": 1
    },
    {
      "a": "06440005",
      "j": 2024,
      "n": "butzbach",
      "dn": "Butzbach",
      "bl": 6,
      "hs": 510.0,
      "mns": 2
    },
    {
      "a": "06440006",
      "j": 2024,
      "n": "echzell",
      "dn": "Echzell",
      "bl": 6,
      "hs": 530.0,
      "mns": 1
    },
    {
      "a": "06440007",
      "j": 2024,
      "n": "florstadt",
      "dn": "Florstadt",
      "bl": 6,
      "hs": 495.0,
      "mns": 1
    },
    {
      "a": "06440008",
      "j": 2024,
      "n": "friedberg",
      "dn": "Friedberg",
      "bl": 6,
      "hs": 590.0,
      "mns": 4
    },
    {
      "a": "06440009",
      "j": 2024,
      "n": "gedern",
      "dn": "Gedern",
      "bl": 6,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "06440010",
      "j": 2024,
      "n": "glauburg",
      "dn": "Glauburg",
      "bl": 6,
      "hs": 600.0,
      "mns": 1
    },
    {
      "a": "06440011",
      "j": 2024,
      "n": "hirzenhain",
      "dn": "Hirzenhain",
      "bl": 6,
      "hs": 710.0,
      "mns": 1
    },
    {
      "a": "06440012",
      "j": 2024,
      "n": "karben",
      "dn": "Karben",
      "bl": 6,
      "hs": 440.0,
      "mns": 4
    },
    {
      "a": "06440013",
      "j": 2024,
      "n": "kefenrod",
      "dn": "Kefenrod",
      "bl": 6,
      "hs": 850.0,
      "mns": 1
    },
    {
      "a": "06440014",
      "j": 2024,
      "n": "limeshain",
      "dn": "Limeshain",
      "bl": 6,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "06440015",
      "j": 2024,
      "n": "münzenberg",
      "dn": "Münzenberg",
      "bl": 6,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "06440016",
      "j": 2024,
      "n": "nidda",
      "dn": "Nidda",
      "bl": 6,
      "hs": 570.0,
      "mns": 1
    },
    {
      "a": "06440017",
      "j": 2024,
      "n": "niddatal",
      "dn": "Niddatal",
      "bl": 6,
      "hs": 542.0,
      "mns": 1
    },
    {
      "a": "06440018",
      "j": 2024,
      "n": "ober-mörlen",
      "dn": "Ober-Mörlen",
      "bl": 6,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "06440019",
      "j": 2024,
      "n": "ortenberg",
      "dn": "Ortenberg",
      "bl": 6,
      "hs": 750.0,
      "mns": 1
    },
    {
      "a": "06440020",
      "j": 2024,
      "n": "ranstadt",
      "dn": "Ranstadt",
      "bl": 6,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "06440021",
      "j": 2024,
      "n": "reichelsheim",
      "dn": "Reichelsheim",
      "bl": 6,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "06440022",
      "j": 2024,
      "n": "rockenberg",
      "dn": "Rockenberg",
      "bl": 6,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "06440023",
      "j": 2024,
      "n": "rosbach vor der höhe",
      "dn": "Rosbach vor der Höhe",
      "bl": 6,
      "hs": 453.0,
      "mns": 3
    },
    {
      "a": "06440024",
      "j": 2024,
      "n": "wölfersheim",
      "dn": "Wölfersheim",
      "bl": 6,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "06440025",
      "j": 2024,
      "n": "wöllstadt",
      "dn": "Wöllstadt",
      "bl": 6,
      "hs": 495.0,
      "mns": 1
    },
    {
      "a": "06531001",
      "j": 2024,
      "n": "allendorf (lumda)",
      "dn": "Allendorf (Lumda)",
      "bl": 6,
      "hs": 690.0,
      "mns": 2
    },
    {
      "a": "06531002",
      "j": 2024,
      "n": "biebertal",
      "dn": "Biebertal",
      "bl": 6,
      "hs": 700.0,
      "mns": 2
    },
    {
      "a": "06531003",
      "j": 2024,
      "n": "buseck",
      "dn": "Buseck",
      "bl": 6,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "06531004",
      "j": 2024,
      "n": "fernwald",
      "dn": "Fernwald",
      "bl": 6,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "06531005",
      "j": 2024,
      "n": "gießen",
      "dn": "Gießen",
      "bl": 6,
      "hs": 600.0,
      "mns": 4
    },
    {
      "a": "06531006",
      "j": 2024,
      "n": "grünberg",
      "dn": "Grünberg",
      "bl": 6,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "06531007",
      "j": 2024,
      "n": "heuchelheim",
      "dn": "Heuchelheim",
      "bl": 6,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "06531008",
      "j": 2024,
      "n": "hungen",
      "dn": "Hungen",
      "bl": 6,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "06531009",
      "j": 2024,
      "n": "langgöns",
      "dn": "Langgöns",
      "bl": 6,
      "hs": 365.0,
      "mns": 2
    },
    {
      "a": "06531010",
      "j": 2025,
      "n": "laubach",
      "dn": "Laubach",
      "bl": 6,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "06531011",
      "j": 2024,
      "n": "lich",
      "dn": "Lich",
      "bl": 6,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "06531012",
      "j": 2024,
      "n": "linden",
      "dn": "Linden",
      "bl": 6,
      "hs": 365.0,
      "mns": 3
    },
    {
      "a": "06531013",
      "j": 2024,
      "n": "lollar",
      "dn": "Lollar",
      "bl": 6,
      "hs": 490.0,
      "mns": 2
    },
    {
      "a": "06531014",
      "j": 2024,
      "n": "pohlheim",
      "dn": "Pohlheim",
      "bl": 6,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "06531015",
      "j": 2024,
      "n": "rabenau",
      "dn": "Rabenau",
      "bl": 6,
      "hs": 690.0,
      "mns": 2
    },
    {
      "a": "06531016",
      "j": 2024,
      "n": "reiskirchen",
      "dn": "Reiskirchen",
      "bl": 6,
      "hs": 545.0,
      "mns": 2
    },
    {
      "a": "06531017",
      "j": 2024,
      "n": "staufenberg",
      "dn": "Staufenberg",
      "bl": 6,
      "hs": 500.0,
      "mns": 2
    },
    {
      "a": "06531018",
      "j": 2024,
      "n": "wettenberg",
      "dn": "Wettenberg",
      "bl": 6,
      "hs": 420.0,
      "mns": 3
    },
    {
      "a": "06532001",
      "j": 2024,
      "n": "aßlar",
      "dn": "Aßlar",
      "bl": 6,
      "hs": 600.0,
      "mns": 2
    },
    {
      "a": "06532002",
      "j": 2024,
      "n": "bischoffen",
      "dn": "Bischoffen",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06532003",
      "j": 2024,
      "n": "braunfels",
      "dn": "Braunfels",
      "bl": 6,
      "hs": 550.0,
      "mns": 2
    },
    {
      "a": "06532004",
      "j": 2024,
      "n": "breitscheid",
      "dn": "Breitscheid",
      "bl": 6,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "06532005",
      "j": 2024,
      "n": "dietzhölztal",
      "dn": "Dietzhölztal",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06532006",
      "j": 2024,
      "n": "dillenburg",
      "dn": "Dillenburg",
      "bl": 6,
      "hs": 460.0,
      "mns": 2
    },
    {
      "a": "06532007",
      "j": 2024,
      "n": "driedorf",
      "dn": "Driedorf",
      "bl": 6,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "06532008",
      "j": 2024,
      "n": "ehringshausen",
      "dn": "Ehringshausen",
      "bl": 6,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "06532009",
      "j": 2024,
      "n": "eschenburg",
      "dn": "Eschenburg",
      "bl": 6,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "06532010",
      "j": 2024,
      "n": "greifenstein",
      "dn": "Greifenstein",
      "bl": 6,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "06532011",
      "j": 2024,
      "n": "haiger",
      "dn": "Haiger",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06532012",
      "j": 2024,
      "n": "herborn",
      "dn": "Herborn",
      "bl": 6,
      "hs": 413.0,
      "mns": 2
    },
    {
      "a": "06532013",
      "j": 2024,
      "n": "hohenahr",
      "dn": "Hohenahr",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06532014",
      "j": 2024,
      "n": "hüttenberg",
      "dn": "Hüttenberg",
      "bl": 6,
      "hs": 660.0,
      "mns": 2
    },
    {
      "a": "06532015",
      "j": 2024,
      "n": "lahnau",
      "dn": "Lahnau",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06532016",
      "j": 2024,
      "n": "leun",
      "dn": "Leun",
      "bl": 6,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "06532017",
      "j": 2024,
      "n": "mittenaar",
      "dn": "Mittenaar",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06532018",
      "j": 2024,
      "n": "schöffengrund",
      "dn": "Schöffengrund",
      "bl": 6,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "06532019",
      "j": 2024,
      "n": "siegbach",
      "dn": "Siegbach",
      "bl": 6,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "06532020",
      "j": 2024,
      "n": "sinn",
      "dn": "Sinn",
      "bl": 6,
      "hs": 610.0,
      "mns": 1
    },
    {
      "a": "06532021",
      "j": 2024,
      "n": "solms",
      "dn": "Solms",
      "bl": 6,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "06532022",
      "j": 2024,
      "n": "waldsolms",
      "dn": "Waldsolms",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06532023",
      "j": 2025,
      "n": "wetzlar",
      "dn": "Wetzlar",
      "bl": 6,
      "hs": 760.0,
      "mns": 3
    },
    {
      "a": "06533001",
      "j": 2024,
      "n": "beselich",
      "dn": "Beselich",
      "bl": 6,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "06533002",
      "j": 2024,
      "n": "brechen",
      "dn": "Brechen",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06533003",
      "j": 2024,
      "n": "bad camberg",
      "dn": "Bad Camberg",
      "bl": 6,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "06533004",
      "j": 2024,
      "n": "dornburg",
      "dn": "Dornburg",
      "bl": 6,
      "hs": 351.0,
      "mns": 1
    },
    {
      "a": "06533005",
      "j": 2024,
      "n": "elbtal",
      "dn": "Elbtal",
      "bl": 6,
      "hs": 230.0,
      "mns": 1
    },
    {
      "a": "06533006",
      "j": 2024,
      "n": "elz",
      "dn": "Elz",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06533007",
      "j": 2024,
      "n": "hadamar",
      "dn": "Hadamar",
      "bl": 6,
      "hs": 460.0,
      "mns": 2
    },
    {
      "a": "06533008",
      "j": 2024,
      "n": "hünfelden",
      "dn": "Hünfelden",
      "bl": 6,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "06533009",
      "j": 2024,
      "n": "limburg an der lahn",
      "dn": "Limburg an der Lahn",
      "bl": 6,
      "hs": 365.0,
      "mns": 2
    },
    {
      "a": "06533010",
      "j": 2024,
      "n": "löhnberg",
      "dn": "Löhnberg",
      "bl": 6,
      "hs": 394.0,
      "mns": 1
    },
    {
      "a": "06533011",
      "j": 2024,
      "n": "mengerskirchen",
      "dn": "Mengerskirchen",
      "bl": 6,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "06533012",
      "j": 2024,
      "n": "merenberg",
      "dn": "Merenberg",
      "bl": 6,
      "hs": 570.0,
      "mns": 1
    },
    {
      "a": "06533013",
      "j": 2024,
      "n": "runkel",
      "dn": "Runkel",
      "bl": 6,
      "hs": 590.0,
      "mns": 1
    },
    {
      "a": "06533014",
      "j": 2024,
      "n": "selters (taunus)",
      "dn": "Selters (Taunus)",
      "bl": 6,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "06533015",
      "j": 2024,
      "n": "villmar",
      "dn": "Villmar",
      "bl": 6,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "06533016",
      "j": 2024,
      "n": "waldbrunn (westerwald)",
      "dn": "Waldbrunn (Westerwald)",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06533017",
      "j": 2024,
      "n": "weilburg",
      "dn": "Weilburg",
      "bl": 6,
      "hs": 535.0,
      "mns": 1
    },
    {
      "a": "06533018",
      "j": 2024,
      "n": "weilmünster",
      "dn": "Weilmünster",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06533019",
      "j": 2024,
      "n": "weinbach",
      "dn": "Weinbach",
      "bl": 6,
      "hs": 560.0,
      "mns": 1
    },
    {
      "a": "06534001",
      "j": 2024,
      "n": "amöneburg",
      "dn": "Amöneburg",
      "bl": 6,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "06534002",
      "j": 2024,
      "n": "angelburg",
      "dn": "Angelburg",
      "bl": 6,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "06534003",
      "j": 2024,
      "n": "bad endbach (gemeinde)",
      "dn": "Bad Endbach (Gemeinde)",
      "bl": 6,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "06534004",
      "j": 2024,
      "n": "biedenkopf",
      "dn": "Biedenkopf",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06534005",
      "j": 2024,
      "n": "breidenbach",
      "dn": "Breidenbach",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06534006",
      "j": 2024,
      "n": "cölbe",
      "dn": "Cölbe",
      "bl": 6,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "06534007",
      "j": 2024,
      "n": "dautphetal",
      "dn": "Dautphetal",
      "bl": 6,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "06534008",
      "j": 2024,
      "n": "ebsdorfergrund",
      "dn": "Ebsdorfergrund",
      "bl": 6,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "06534009",
      "j": 2024,
      "n": "fronhausen",
      "dn": "Fronhausen",
      "bl": 6,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "06534010",
      "j": 2024,
      "n": "gladenbach",
      "dn": "Gladenbach",
      "bl": 6,
      "hs": 475.0,
      "mns": 2
    },
    {
      "a": "06534011",
      "j": 2024,
      "n": "kirchhain",
      "dn": "Kirchhain",
      "bl": 6,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "06534012",
      "j": 2024,
      "n": "lahntal",
      "dn": "Lahntal",
      "bl": 6,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "06534013",
      "j": 2024,
      "n": "lohra",
      "dn": "Lohra",
      "bl": 6,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "06534014",
      "j": 2024,
      "n": "marburg",
      "dn": "Marburg",
      "bl": 6,
      "hs": 390.0,
      "mns": 4
    },
    {
      "a": "06534015",
      "j": 2024,
      "n": "münchhausen",
      "dn": "Münchhausen",
      "bl": 6,
      "hs": 630.0,
      "mns": 1
    },
    {
      "a": "06534016",
      "j": 2024,
      "n": "neustadt (hessen)",
      "dn": "Neustadt (Hessen)",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06534017",
      "j": 2024,
      "n": "rauschenberg",
      "dn": "Rauschenberg",
      "bl": 6,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "06534018",
      "j": 2024,
      "n": "stadtallendorf",
      "dn": "Stadtallendorf",
      "bl": 6,
      "hs": 365.0,
      "mns": 2
    },
    {
      "a": "06534019",
      "j": 2024,
      "n": "steffenberg",
      "dn": "Steffenberg",
      "bl": 6,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "06534020",
      "j": 2024,
      "n": "weimar (lahn)",
      "dn": "Weimar (Lahn)",
      "bl": 6,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "06534021",
      "j": 2024,
      "n": "wetter (hessen)",
      "dn": "Wetter (Hessen)",
      "bl": 6,
      "hs": 630.0,
      "mns": 1
    },
    {
      "a": "06534022",
      "j": 2024,
      "n": "wohratal",
      "dn": "Wohratal",
      "bl": 6,
      "hs": 580.0,
      "mns": 1
    },
    {
      "a": "06535001",
      "j": 2024,
      "n": "alsfeld",
      "dn": "Alsfeld",
      "bl": 6,
      "hs": 485.0,
      "mns": 1
    },
    {
      "a": "06535002",
      "j": 2024,
      "n": "antrifttal",
      "dn": "Antrifttal",
      "bl": 6,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "06535003",
      "j": 2024,
      "n": "feldatal",
      "dn": "Feldatal",
      "bl": 6,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "06535004",
      "j": 2024,
      "n": "freiensteinau",
      "dn": "Freiensteinau",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06535005",
      "j": 2024,
      "n": "gemünden (felda)",
      "dn": "Gemünden (Felda)",
      "bl": 6,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "06535006",
      "j": 2024,
      "n": "grebenau",
      "dn": "Grebenau",
      "bl": 6,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "06535007",
      "j": 2024,
      "n": "grebenhain",
      "dn": "Grebenhain",
      "bl": 6,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "06535008",
      "j": 2024,
      "n": "herbstein",
      "dn": "Herbstein",
      "bl": 6,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "06535009",
      "j": 2024,
      "n": "homberg (ohm)",
      "dn": "Homberg (Ohm)",
      "bl": 6,
      "hs": 580.0,
      "mns": 1
    },
    {
      "a": "06535010",
      "j": 2024,
      "n": "kirtorf",
      "dn": "Kirtorf",
      "bl": 6,
      "hs": 795.0,
      "mns": 1
    },
    {
      "a": "06535011",
      "j": 2024,
      "n": "lauterbach",
      "dn": "Lauterbach",
      "bl": 6,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "06535012",
      "j": 2024,
      "n": "lautertal",
      "dn": "Lautertal",
      "bl": 6,
      "hs": 457.0,
      "mns": 1
    },
    {
      "a": "06535013",
      "j": 2024,
      "n": "mücke",
      "dn": "Mücke",
      "bl": 6,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "06535014",
      "j": 2024,
      "n": "romrod",
      "dn": "Romrod",
      "bl": 6,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "06535015",
      "j": 2024,
      "n": "schlitz",
      "dn": "Schlitz",
      "bl": 6,
      "hs": 625.0,
      "mns": 1
    },
    {
      "a": "06535016",
      "j": 2024,
      "n": "schotten",
      "dn": "Schotten",
      "bl": 6,
      "hs": 687.0,
      "mns": 1
    },
    {
      "a": "06535017",
      "j": 2024,
      "n": "schwalmtal",
      "dn": "Schwalmtal",
      "bl": 6,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "06535018",
      "j": 2024,
      "n": "ulrichstein",
      "dn": "Ulrichstein",
      "bl": 6,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "06535019",
      "j": 2024,
      "n": "wartenberg",
      "dn": "Wartenberg",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06611000",
      "j": 2024,
      "n": "kassel",
      "dn": "Kassel",
      "bl": 6,
      "hs": 490.0,
      "mns": 3
    },
    {
      "a": "06631001",
      "j": 2024,
      "n": "bad salzschlirf",
      "dn": "Bad Salzschlirf",
      "bl": 6,
      "hs": 695.0,
      "mns": 1
    },
    {
      "a": "06631002",
      "j": 2024,
      "n": "burghaun",
      "dn": "Burghaun",
      "bl": 6,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "06631003",
      "j": 2024,
      "n": "dipperz",
      "dn": "Dipperz",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06631004",
      "j": 2024,
      "n": "ebersburg",
      "dn": "Ebersburg",
      "bl": 6,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "06631005",
      "j": 2024,
      "n": "ehrenberg (rhön)",
      "dn": "Ehrenberg (Rhön)",
      "bl": 6,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "06631006",
      "j": 2024,
      "n": "eichenzell",
      "dn": "Eichenzell",
      "bl": 6,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "06631007",
      "j": 2024,
      "n": "eiterfeld",
      "dn": "Eiterfeld",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06631008",
      "j": 2024,
      "n": "flieden",
      "dn": "Flieden",
      "bl": 6,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "06631009",
      "j": 2024,
      "n": "fulda",
      "dn": "Fulda",
      "bl": 6,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "06631010",
      "j": 2024,
      "n": "gersfeld (rhön)",
      "dn": "Gersfeld (Rhön)",
      "bl": 6,
      "hs": 600.0,
      "mns": 1
    },
    {
      "a": "06631011",
      "j": 2024,
      "n": "großenlüder",
      "dn": "Großenlüder",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06631012",
      "j": 2024,
      "n": "hilders",
      "dn": "Hilders",
      "bl": 6,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "06631013",
      "j": 2024,
      "n": "hofbieber",
      "dn": "Hofbieber",
      "bl": 6,
      "hs": 495.0,
      "mns": 1
    },
    {
      "a": "06631014",
      "j": 2024,
      "n": "hosenfeld",
      "dn": "Hosenfeld",
      "bl": 6,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "06631015",
      "j": 2024,
      "n": "hünfeld",
      "dn": "Hünfeld",
      "bl": 6,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "06631016",
      "j": 2024,
      "n": "kalbach",
      "dn": "Kalbach",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06631017",
      "j": 2024,
      "n": "künzell",
      "dn": "Künzell",
      "bl": 6,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "06631018",
      "j": 2024,
      "n": "neuhof",
      "dn": "Neuhof",
      "bl": 6,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "06631019",
      "j": 2024,
      "n": "nüsttal",
      "dn": "Nüsttal",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06631020",
      "j": 2024,
      "n": "petersberg",
      "dn": "Petersberg",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06631021",
      "j": 2024,
      "n": "poppenhausen (wasserkuppe)",
      "dn": "Poppenhausen (Wasserkuppe)",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06631022",
      "j": 2024,
      "n": "rasdorf",
      "dn": "Rasdorf",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06631023",
      "j": 2024,
      "n": "tann (rhön)",
      "dn": "Tann (Rhön)",
      "bl": 6,
      "hs": 600.0,
      "mns": 1
    },
    {
      "a": "06632001",
      "j": 2024,
      "n": "alheim",
      "dn": "Alheim",
      "bl": 6,
      "hs": 600.0,
      "mns": 1
    },
    {
      "a": "06632002",
      "j": 2024,
      "n": "bad hersfeld",
      "dn": "Bad Hersfeld",
      "bl": 6,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "06632003",
      "j": 2024,
      "n": "bebra",
      "dn": "Bebra",
      "bl": 6,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "06632004",
      "j": 2024,
      "n": "breitenbach am herzberg",
      "dn": "Breitenbach am Herzberg",
      "bl": 6,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "06632005",
      "j": 2024,
      "n": "cornberg",
      "dn": "Cornberg",
      "bl": 6,
      "hs": 700.0,
      "mns": 1
    },
    {
      "a": "06632006",
      "j": 2024,
      "n": "friedewald",
      "dn": "Friedewald",
      "bl": 6,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "06632007",
      "j": 2024,
      "n": "hauneck",
      "dn": "Hauneck",
      "bl": 6,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "06632008",
      "j": 2024,
      "n": "haunetal",
      "dn": "Haunetal",
      "bl": 6,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "06632009",
      "j": 2024,
      "n": "heringen",
      "dn": "Heringen",
      "bl": 6,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "06632010",
      "j": 2024,
      "n": "hohenroda",
      "dn": "Hohenroda",
      "bl": 6,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "06632011",
      "j": 2024,
      "n": "kirchheim",
      "dn": "Kirchheim",
      "bl": 6,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "06632012",
      "j": 2024,
      "n": "ludwigsau",
      "dn": "Ludwigsau",
      "bl": 6,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "06632013",
      "j": 2024,
      "n": "nentershausen",
      "dn": "Nentershausen",
      "bl": 6,
      "hs": 650.0,
      "mns": 1
    },
    {
      "a": "06632014",
      "j": 2024,
      "n": "neuenstein",
      "dn": "Neuenstein",
      "bl": 6,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "06632015",
      "j": 2024,
      "n": "niederaula",
      "dn": "Niederaula",
      "bl": 6,
      "hs": 600.0,
      "mns": 1
    },
    {
      "a": "06632016",
      "j": 2024,
      "n": "philippsthal (werra)",
      "dn": "Philippsthal (Werra)",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06632017",
      "j": 2024,
      "n": "ronshausen",
      "dn": "Ronshausen",
      "bl": 6,
      "hs": 600.0,
      "mns": 1
    },
    {
      "a": "06632018",
      "j": 2024,
      "n": "rotenburg an der fulda",
      "dn": "Rotenburg an der Fulda",
      "bl": 6,
      "hs": 675.0,
      "mns": 1
    },
    {
      "a": "06632019",
      "j": 2024,
      "n": "schenklengsfeld",
      "dn": "Schenklengsfeld",
      "bl": 6,
      "hs": 575.0,
      "mns": 1
    },
    {
      "a": "06632020",
      "j": 2024,
      "n": "wildeck",
      "dn": "Wildeck",
      "bl": 6,
      "hs": 725.0,
      "mns": 1
    },
    {
      "a": "06633001",
      "j": 2024,
      "n": "ahnatal",
      "dn": "Ahnatal",
      "bl": 6,
      "hs": 795.0,
      "mns": 1
    },
    {
      "a": "06633002",
      "j": 2024,
      "n": "bad karlshafen",
      "dn": "Bad Karlshafen",
      "bl": 6,
      "hs": 951.0,
      "mns": 1
    },
    {
      "a": "06633003",
      "j": 2024,
      "n": "baunatal",
      "dn": "Baunatal",
      "bl": 6,
      "hs": 590.0,
      "mns": 2
    },
    {
      "a": "06633004",
      "j": 2024,
      "n": "breuna",
      "dn": "Breuna",
      "bl": 6,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "06633005",
      "j": 2024,
      "n": "calden",
      "dn": "Calden",
      "bl": 6,
      "hs": 680.0,
      "mns": 1
    },
    {
      "a": "06633006",
      "j": 2024,
      "n": "bad emstal",
      "dn": "Bad Emstal",
      "bl": 6,
      "hs": 950.0,
      "mns": 1
    },
    {
      "a": "06633007",
      "j": 2024,
      "n": "espenau",
      "dn": "Espenau",
      "bl": 6,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "06633008",
      "j": 2024,
      "n": "fuldabrück",
      "dn": "Fuldabrück",
      "bl": 6,
      "hs": 650.0,
      "mns": 1
    },
    {
      "a": "06633009",
      "j": 2024,
      "n": "fuldatal",
      "dn": "Fuldatal",
      "bl": 6,
      "hs": 690.0,
      "mns": 2
    },
    {
      "a": "06633010",
      "j": 2024,
      "n": "grebenstein",
      "dn": "Grebenstein",
      "bl": 6,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "06633011",
      "j": 2024,
      "n": "habichtswald",
      "dn": "Habichtswald",
      "bl": 6,
      "hs": 650.0,
      "mns": 1
    },
    {
      "a": "06633012",
      "j": 2024,
      "n": "helsa",
      "dn": "Helsa",
      "bl": 6,
      "hs": 700.0,
      "mns": 1
    },
    {
      "a": "06633013",
      "j": 2024,
      "n": "hofgeismar",
      "dn": "Hofgeismar",
      "bl": 6,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "06633014",
      "j": 2024,
      "n": "immenhausen",
      "dn": "Immenhausen",
      "bl": 6,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "06633015",
      "j": 2024,
      "n": "kaufungen",
      "dn": "Kaufungen",
      "bl": 6,
      "hs": 610.0,
      "mns": 2
    },
    {
      "a": "06633016",
      "j": 2024,
      "n": "liebenau",
      "dn": "Liebenau",
      "bl": 6,
      "hs": 690.0,
      "mns": 1
    },
    {
      "a": "06633017",
      "j": 2024,
      "n": "lohfelden",
      "dn": "Lohfelden",
      "bl": 6,
      "hs": 620.0,
      "mns": 2
    },
    {
      "a": "06633018",
      "j": 2024,
      "n": "naumburg",
      "dn": "Naumburg",
      "bl": 6,
      "hs": 675.0,
      "mns": 1
    },
    {
      "a": "06633019",
      "j": 2024,
      "n": "nieste",
      "dn": "Nieste",
      "bl": 6,
      "hs": 750.0,
      "mns": 1
    },
    {
      "a": "06633020",
      "j": 2024,
      "n": "niestetal",
      "dn": "Niestetal",
      "bl": 6,
      "hs": 590.0,
      "mns": 2
    },
    {
      "a": "06633022",
      "j": 2024,
      "n": "reinhardshagen",
      "dn": "Reinhardshagen",
      "bl": 6,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "06633023",
      "j": 2024,
      "n": "schauenburg",
      "dn": "Schauenburg",
      "bl": 6,
      "hs": 560.0,
      "mns": 1
    },
    {
      "a": "06633024",
      "j": 2024,
      "n": "söhrewald",
      "dn": "Söhrewald",
      "bl": 6,
      "hs": 750.0,
      "mns": 1
    },
    {
      "a": "06633025",
      "j": 2024,
      "n": "trendelburg",
      "dn": "Trendelburg",
      "bl": 6,
      "hs": 748.0,
      "mns": 1
    },
    {
      "a": "06633026",
      "j": 2024,
      "n": "vellmar",
      "dn": "Vellmar",
      "bl": 6,
      "hs": 735.0,
      "mns": 2
    },
    {
      "a": "06633028",
      "j": 2024,
      "n": "wolfhagen",
      "dn": "Wolfhagen",
      "bl": 6,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "06633029",
      "j": 2024,
      "n": "zierenberg",
      "dn": "Zierenberg",
      "bl": 6,
      "hs": 695.0,
      "mns": 1
    },
    {
      "a": "06633030",
      "j": 2024,
      "n": "wesertal",
      "dn": "Wesertal",
      "bl": 6,
      "hs": 590.0,
      "mns": 1
    },
    {
      "a": "06634001",
      "j": 2024,
      "n": "borken (hessen)",
      "dn": "Borken (Hessen)",
      "bl": 6,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "06634002",
      "j": 2024,
      "n": "edermünde",
      "dn": "Edermünde",
      "bl": 6,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "06634003",
      "j": 2024,
      "n": "felsberg",
      "dn": "Felsberg",
      "bl": 6,
      "hs": 660.0,
      "mns": 1
    },
    {
      "a": "06634004",
      "j": 2024,
      "n": "frielendorf",
      "dn": "Frielendorf",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06634005",
      "j": 2024,
      "n": "fritzlar",
      "dn": "Fritzlar",
      "bl": 6,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "06634006",
      "j": 2024,
      "n": "gilserberg",
      "dn": "Gilserberg",
      "bl": 6,
      "hs": 394.0,
      "mns": 1
    },
    {
      "a": "06634007",
      "j": 2024,
      "n": "gudensberg",
      "dn": "Gudensberg",
      "bl": 6,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "06634008",
      "j": 2024,
      "n": "guxhagen",
      "dn": "Guxhagen",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06634009",
      "j": 2024,
      "n": "homberg (efze)",
      "dn": "Homberg (Efze)",
      "bl": 6,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "06634010",
      "j": 2024,
      "n": "jesberg",
      "dn": "Jesberg",
      "bl": 6,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "06634011",
      "j": 2024,
      "n": "knüllwald",
      "dn": "Knüllwald",
      "bl": 6,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "06634012",
      "j": 2024,
      "n": "körle",
      "dn": "Körle",
      "bl": 6,
      "hs": 600.0,
      "mns": 1
    },
    {
      "a": "06634013",
      "j": 2024,
      "n": "malsfeld",
      "dn": "Malsfeld",
      "bl": 6,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "06634014",
      "j": 2024,
      "n": "melsungen",
      "dn": "Melsungen",
      "bl": 6,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "06634015",
      "j": 2024,
      "n": "morschen",
      "dn": "Morschen",
      "bl": 6,
      "hs": 690.0,
      "mns": 1
    },
    {
      "a": "06634016",
      "j": 2024,
      "n": "neuental",
      "dn": "Neuental",
      "bl": 6,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "06634017",
      "j": 2024,
      "n": "neukirchen",
      "dn": "Neukirchen",
      "bl": 6,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "06634018",
      "j": 2024,
      "n": "niedenstein",
      "dn": "Niedenstein",
      "bl": 6,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "06634019",
      "j": 2024,
      "n": "oberaula",
      "dn": "Oberaula",
      "bl": 6,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "06634020",
      "j": 2024,
      "n": "ottrau",
      "dn": "Ottrau",
      "bl": 6,
      "hs": 495.0,
      "mns": 1
    },
    {
      "a": "06634021",
      "j": 2024,
      "n": "schrecksbach",
      "dn": "Schrecksbach",
      "bl": 6,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "06634022",
      "j": 2024,
      "n": "schwalmstadt",
      "dn": "Schwalmstadt",
      "bl": 6,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "06634023",
      "j": 2024,
      "n": "schwarzenborn",
      "dn": "Schwarzenborn",
      "bl": 6,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "06634024",
      "j": 2024,
      "n": "spangenberg",
      "dn": "Spangenberg",
      "bl": 6,
      "hs": 625.0,
      "mns": 1
    },
    {
      "a": "06634025",
      "j": 2024,
      "n": "wabern",
      "dn": "Wabern",
      "bl": 6,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "06634026",
      "j": 2024,
      "n": "willingshausen",
      "dn": "Willingshausen",
      "bl": 6,
      "hs": 580.0,
      "mns": 1
    },
    {
      "a": "06634027",
      "j": 2024,
      "n": "bad zwesten",
      "dn": "Bad Zwesten",
      "bl": 6,
      "hs": 580.0,
      "mns": 1
    },
    {
      "a": "06635001",
      "j": 2024,
      "n": "allendorf (eder)",
      "dn": "Allendorf (Eder)",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06635002",
      "j": 2024,
      "n": "bad arolsen",
      "dn": "Bad Arolsen",
      "bl": 6,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "06635003",
      "j": 2024,
      "n": "bad wildungen",
      "dn": "Bad Wildungen",
      "bl": 6,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "06635004",
      "j": 2024,
      "n": "battenberg (eder)",
      "dn": "Battenberg (Eder)",
      "bl": 6,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "06635005",
      "j": 2022,
      "n": "bromskirchen",
      "dn": "Bromskirchen",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06635006",
      "j": 2024,
      "n": "burgwald",
      "dn": "Burgwald",
      "bl": 6,
      "hs": 535.0,
      "mns": 1
    },
    {
      "a": "06635007",
      "j": 2024,
      "n": "diemelsee",
      "dn": "Diemelsee",
      "bl": 6,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "06635008",
      "j": 2025,
      "n": "diemelstadt",
      "dn": "Diemelstadt",
      "bl": 6,
      "hs": 192.0,
      "mns": 1
    },
    {
      "a": "06635009",
      "j": 2024,
      "n": "edertal",
      "dn": "Edertal",
      "bl": 6,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "06635010",
      "j": 2024,
      "n": "frankenau",
      "dn": "Frankenau",
      "bl": 6,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "06635011",
      "j": 2024,
      "n": "frankenberg (eder)",
      "dn": "Frankenberg (Eder)",
      "bl": 6,
      "hs": 396.0,
      "mns": 1
    },
    {
      "a": "06635012",
      "j": 2024,
      "n": "gemünden (wohra)",
      "dn": "Gemünden (Wohra)",
      "bl": 6,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "06635013",
      "j": 2024,
      "n": "haina (kloster)",
      "dn": "Haina (Kloster)",
      "bl": 6,
      "hs": 435.0,
      "mns": 1
    },
    {
      "a": "06635014",
      "j": 2024,
      "n": "hatzfeld",
      "dn": "Hatzfeld",
      "bl": 6,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "06635015",
      "j": 2024,
      "n": "korbach",
      "dn": "Korbach",
      "bl": 6,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "06635016",
      "j": 2024,
      "n": "lichtenfels",
      "dn": "Lichtenfels",
      "bl": 6,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "06635017",
      "j": 2024,
      "n": "rosenthal",
      "dn": "Rosenthal",
      "bl": 6,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "06635018",
      "j": 2024,
      "n": "twistetal",
      "dn": "Twistetal",
      "bl": 6,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "06635019",
      "j": 2024,
      "n": "vöhl",
      "dn": "Vöhl",
      "bl": 6,
      "hs": 540.0,
      "mns": 1
    },
    {
      "a": "06635020",
      "j": 2024,
      "n": "volkmarsen",
      "dn": "Volkmarsen",
      "bl": 6,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "06635021",
      "j": 2024,
      "n": "waldeck",
      "dn": "Waldeck",
      "bl": 6,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "06635022",
      "j": 2024,
      "n": "willingen (upland)",
      "dn": "Willingen (Upland)",
      "bl": 6,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "06636001",
      "j": 2024,
      "n": "bad sooden-allendorf",
      "dn": "Bad Sooden-Allendorf",
      "bl": 6,
      "hs": 800.0,
      "mns": 1
    },
    {
      "a": "06636002",
      "j": 2024,
      "n": "berkatal",
      "dn": "Berkatal",
      "bl": 6,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "06636003",
      "j": 2024,
      "n": "eschwege",
      "dn": "Eschwege",
      "bl": 6,
      "hs": 595.0,
      "mns": 1
    },
    {
      "a": "06636004",
      "j": 2024,
      "n": "großalmerode",
      "dn": "Großalmerode",
      "bl": 6,
      "hs": 560.0,
      "mns": 1
    },
    {
      "a": "06636005",
      "j": 2024,
      "n": "herleshausen",
      "dn": "Herleshausen",
      "bl": 6,
      "hs": 600.0,
      "mns": 1
    },
    {
      "a": "06636006",
      "j": 2025,
      "n": "hessisch lichtenau",
      "dn": "Hessisch Lichtenau",
      "bl": 6,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "06636007",
      "j": 2024,
      "n": "meinhard",
      "dn": "Meinhard",
      "bl": 6,
      "hs": 650.0,
      "mns": 1
    },
    {
      "a": "06636008",
      "j": 2024,
      "n": "meißner",
      "dn": "Meißner",
      "bl": 6,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "06636009",
      "j": 2024,
      "n": "neu-eichenberg",
      "dn": "Neu-Eichenberg",
      "bl": 6,
      "hs": 760.0,
      "mns": 1
    },
    {
      "a": "06636010",
      "j": 2024,
      "n": "ringgau",
      "dn": "Ringgau",
      "bl": 6,
      "hs": 960.0,
      "mns": 1
    },
    {
      "a": "06636011",
      "j": 2024,
      "n": "sontra",
      "dn": "Sontra",
      "bl": 6,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "06636012",
      "j": 2024,
      "n": "waldkappel",
      "dn": "Waldkappel",
      "bl": 6,
      "hs": 650.0,
      "mns": 1
    },
    {
      "a": "06636013",
      "j": 2024,
      "n": "wanfried",
      "dn": "Wanfried",
      "bl": 6,
      "hs": 730.0,
      "mns": 1
    },
    {
      "a": "06636014",
      "j": 2024,
      "n": "wehretal",
      "dn": "Wehretal",
      "bl": 6,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "06636015",
      "j": 2024,
      "n": "weißenborn",
      "dn": "Weißenborn",
      "bl": 6,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "06636016",
      "j": 2024,
      "n": "witzenhausen",
      "dn": "Witzenhausen",
      "bl": 6,
      "hs": 655.0,
      "mns": 1
    }
  ],
  "7": [
    {
      "a": "07111000",
      "j": 2023,
      "n": "koblenz",
      "dn": "Koblenz",
      "bl": 7,
      "hs": 420.0,
      "mns": 3
    },
    {
      "a": "07131001",
      "j": 2023,
      "n": "adenau",
      "dn": "Adenau",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131002",
      "j": 2023,
      "n": "ahrbrück",
      "dn": "Ahrbrück",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131003",
      "j": 2023,
      "n": "altenahr",
      "dn": "Altenahr",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131004",
      "j": 2023,
      "n": "antweiler",
      "dn": "Antweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131005",
      "j": 2023,
      "n": "aremberg",
      "dn": "Aremberg",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07131006",
      "j": 2023,
      "n": "bad breisig",
      "dn": "Bad Breisig",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131007",
      "j": 2023,
      "n": "bad neuenahr-ahrweiler",
      "dn": "Bad Neuenahr-Ahrweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07131008",
      "j": 2023,
      "n": "barweiler",
      "dn": "Barweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131009",
      "j": 2023,
      "n": "bauler",
      "dn": "Bauler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131011",
      "j": 2023,
      "n": "berg",
      "dn": "Berg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131014",
      "j": 2023,
      "n": "brohl-lützing",
      "dn": "Brohl-Lützing",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131015",
      "j": 2023,
      "n": "dankerath",
      "dn": "Dankerath",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131016",
      "j": 2023,
      "n": "dedenbach",
      "dn": "Dedenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131017",
      "j": 2023,
      "n": "dernau",
      "dn": "Dernau",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131018",
      "j": 2023,
      "n": "dorsel",
      "dn": "Dorsel",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131021",
      "j": 2023,
      "n": "eichenbach",
      "dn": "Eichenbach",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07131022",
      "j": 2023,
      "n": "fuchshofen",
      "dn": "Fuchshofen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131025",
      "j": 2023,
      "n": "gönnersdorf",
      "dn": "Gönnersdorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131026",
      "j": 2023,
      "n": "harscheid",
      "dn": "Harscheid",
      "bl": 7,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "07131027",
      "j": 2023,
      "n": "heckenbach",
      "dn": "Heckenbach",
      "bl": 7,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "07131028",
      "j": 2023,
      "n": "herschbroich",
      "dn": "Herschbroich",
      "bl": 7,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "07131029",
      "j": 2023,
      "n": "hönningen",
      "dn": "Hönningen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131030",
      "j": 2023,
      "n": "hoffeld",
      "dn": "Hoffeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131032",
      "j": 2023,
      "n": "honerath",
      "dn": "Honerath",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07131033",
      "j": 2023,
      "n": "hümmel",
      "dn": "Hümmel",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131034",
      "j": 2023,
      "n": "insul",
      "dn": "Insul",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131036",
      "j": 2023,
      "n": "kalenborn",
      "dn": "Kalenborn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131037",
      "j": 2023,
      "n": "kaltenborn",
      "dn": "Kaltenborn",
      "bl": 7,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "07131039",
      "j": 2023,
      "n": "kesseling",
      "dn": "Kesseling",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131040",
      "j": 2023,
      "n": "kirchsahr",
      "dn": "Kirchsahr",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131041",
      "j": 2023,
      "n": "königsfeld",
      "dn": "Königsfeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131042",
      "j": 2023,
      "n": "kottenborn",
      "dn": "Kottenborn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131044",
      "j": 2023,
      "n": "leimbach",
      "dn": "Leimbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131047",
      "j": 2023,
      "n": "lind",
      "dn": "Lind",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131049",
      "j": 2023,
      "n": "mayschoss",
      "dn": "Mayschoss",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131050",
      "j": 2023,
      "n": "meuspath",
      "dn": "Meuspath",
      "bl": 7,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "07131051",
      "j": 2023,
      "n": "müllenbach",
      "dn": "Müllenbach",
      "bl": 7,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "07131052",
      "j": 2023,
      "n": "müsch",
      "dn": "Müsch",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131054",
      "j": 2023,
      "n": "niederdürenbach",
      "dn": "Niederdürenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131055",
      "j": 2023,
      "n": "niederzissen",
      "dn": "Niederzissen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131058",
      "j": 2023,
      "n": "nürburg",
      "dn": "Nürburg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131059",
      "j": 2023,
      "n": "oberdürenbach",
      "dn": "Oberdürenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131060",
      "j": 2023,
      "n": "oberzissen",
      "dn": "Oberzissen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131062",
      "j": 2023,
      "n": "ohlenhard",
      "dn": "Ohlenhard",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131065",
      "j": 2023,
      "n": "pomster",
      "dn": "Pomster",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131066",
      "j": 2023,
      "n": "quiddelbach",
      "dn": "Quiddelbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131068",
      "j": 2023,
      "n": "rech",
      "dn": "Rech",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131069",
      "j": 2023,
      "n": "reifferscheid",
      "dn": "Reifferscheid",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07131070",
      "j": 2023,
      "n": "remagen",
      "dn": "Remagen",
      "bl": 7,
      "hs": 415.0,
      "mns": 3
    },
    {
      "a": "07131072",
      "j": 2023,
      "n": "rodder",
      "dn": "Rodder",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131073",
      "j": 2023,
      "n": "schalkenbach",
      "dn": "Schalkenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131074",
      "j": 2023,
      "n": "schuld",
      "dn": "Schuld",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131075",
      "j": 2023,
      "n": "senscheid",
      "dn": "Senscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131076",
      "j": 2023,
      "n": "sierscheid",
      "dn": "Sierscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131077",
      "j": 2023,
      "n": "sinzig",
      "dn": "Sinzig",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07131079",
      "j": 2023,
      "n": "trierscheid",
      "dn": "Trierscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131081",
      "j": 2023,
      "n": "waldorf",
      "dn": "Waldorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131082",
      "j": 2023,
      "n": "wershofen",
      "dn": "Wershofen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131083",
      "j": 2023,
      "n": "wiesemscheid",
      "dn": "Wiesemscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131084",
      "j": 2023,
      "n": "wimbach",
      "dn": "Wimbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131085",
      "j": 2023,
      "n": "winnerath",
      "dn": "Winnerath",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131086",
      "j": 2023,
      "n": "wirft",
      "dn": "Wirft",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131090",
      "j": 2023,
      "n": "grafschaft",
      "dn": "Grafschaft",
      "bl": 7,
      "hs": 365.0,
      "mns": 2
    },
    {
      "a": "07131201",
      "j": 2023,
      "n": "brenk",
      "dn": "Brenk",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131202",
      "j": 2023,
      "n": "burgbrohl",
      "dn": "Burgbrohl",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131204",
      "j": 2023,
      "n": "galenberg",
      "dn": "Galenberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131205",
      "j": 2023,
      "n": "glees",
      "dn": "Glees",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131206",
      "j": 2023,
      "n": "hohenleimbach",
      "dn": "Hohenleimbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131208",
      "j": 2023,
      "n": "spessart",
      "dn": "Spessart",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131209",
      "j": 2023,
      "n": "wassenach",
      "dn": "Wassenach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131210",
      "j": 2023,
      "n": "wehr",
      "dn": "Wehr",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131211",
      "j": 2023,
      "n": "weibern",
      "dn": "Weibern",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131501",
      "j": 2023,
      "n": "dümpelfeld",
      "dn": "Dümpelfeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07131502",
      "j": 2023,
      "n": "kempenich",
      "dn": "Kempenich",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132001",
      "j": 2023,
      "n": "almersbach",
      "dn": "Almersbach",
      "bl": 7,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "07132002",
      "j": 2023,
      "n": "alsdorf",
      "dn": "Alsdorf",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07132004",
      "j": 2023,
      "n": "bachenberg",
      "dn": "Bachenberg",
      "bl": 7,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "07132005",
      "j": 2023,
      "n": "berzhausen",
      "dn": "Berzhausen",
      "bl": 7,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "07132006",
      "j": 2023,
      "n": "betzdorf",
      "dn": "Betzdorf",
      "bl": 7,
      "hs": 585.0,
      "mns": 1
    },
    {
      "a": "07132007",
      "j": 2023,
      "n": "birkenbeul",
      "dn": "Birkenbeul",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07132008",
      "j": 2023,
      "n": "birken-honigsessen",
      "dn": "Birken-Honigsessen",
      "bl": 7,
      "hs": 530.0,
      "mns": 1
    },
    {
      "a": "07132009",
      "j": 2023,
      "n": "birnbach",
      "dn": "Birnbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132010",
      "j": 2023,
      "n": "bitzen",
      "dn": "Bitzen",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07132011",
      "j": 2023,
      "n": "mittelhof",
      "dn": "Mittelhof",
      "bl": 7,
      "hs": 540.0,
      "mns": 1
    },
    {
      "a": "07132012",
      "j": 2023,
      "n": "brachbach",
      "dn": "Brachbach",
      "bl": 7,
      "hs": 525.0,
      "mns": 1
    },
    {
      "a": "07132013",
      "j": 2023,
      "n": "breitscheidt",
      "dn": "Breitscheidt",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132014",
      "j": 2023,
      "n": "bruchertseifen",
      "dn": "Bruchertseifen",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07132015",
      "j": 2023,
      "n": "bürdenbach",
      "dn": "Bürdenbach",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07132016",
      "j": 2023,
      "n": "burglahr",
      "dn": "Burglahr",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07132017",
      "j": 2023,
      "n": "busenhausen",
      "dn": "Busenhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132018",
      "j": 2023,
      "n": "daaden",
      "dn": "Daaden",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132019",
      "j": 2023,
      "n": "derschen",
      "dn": "Derschen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132020",
      "j": 2023,
      "n": "dickendorf",
      "dn": "Dickendorf",
      "bl": 7,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "07132022",
      "j": 2023,
      "n": "eichelhardt",
      "dn": "Eichelhardt",
      "bl": 7,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "07132023",
      "j": 2023,
      "n": "eichen",
      "dn": "Eichen",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07132024",
      "j": 2023,
      "n": "elben (westerwald)",
      "dn": "Elben (Westerwald)",
      "bl": 7,
      "hs": 530.0,
      "mns": 1
    },
    {
      "a": "07132025",
      "j": 2024,
      "n": "elkenroth",
      "dn": "Elkenroth",
      "bl": 7,
      "hs": 530.0,
      "mns": 1
    },
    {
      "a": "07132026",
      "j": 2023,
      "n": "emmerzhausen",
      "dn": "Emmerzhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132027",
      "j": 2023,
      "n": "ersfeld",
      "dn": "Ersfeld",
      "bl": 7,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "07132028",
      "j": 2023,
      "n": "etzbach",
      "dn": "Etzbach",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07132029",
      "j": 2023,
      "n": "eulenberg",
      "dn": "Eulenberg",
      "bl": 7,
      "hs": 535.0,
      "mns": 1
    },
    {
      "a": "07132030",
      "j": 2023,
      "n": "fensdorf",
      "dn": "Fensdorf",
      "bl": 7,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "07132031",
      "j": 2023,
      "n": "fiersbach",
      "dn": "Fiersbach",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07132032",
      "j": 2023,
      "n": "flammersfeld",
      "dn": "Flammersfeld",
      "bl": 7,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "07132033",
      "j": 2023,
      "n": "fluterschen",
      "dn": "Fluterschen",
      "bl": 7,
      "hs": 485.0,
      "mns": 1
    },
    {
      "a": "07132034",
      "j": 2023,
      "n": "forst",
      "dn": "Forst",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07132035",
      "j": 2023,
      "n": "forstmehren",
      "dn": "Forstmehren",
      "bl": 7,
      "hs": 540.0,
      "mns": 1
    },
    {
      "a": "07132036",
      "j": 2023,
      "n": "friedewald",
      "dn": "Friedewald",
      "bl": 7,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "07132037",
      "j": 2023,
      "n": "friesenhagen",
      "dn": "Friesenhagen",
      "bl": 7,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "07132038",
      "j": 2023,
      "n": "fürthen",
      "dn": "Fürthen",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07132039",
      "j": 2023,
      "n": "gebhardshain",
      "dn": "Gebhardshain",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132040",
      "j": 2023,
      "n": "gieleroth",
      "dn": "Gieleroth",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07132041",
      "j": 2023,
      "n": "giershausen",
      "dn": "Giershausen",
      "bl": 7,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "07132042",
      "j": 2023,
      "n": "grünebach",
      "dn": "Grünebach",
      "bl": 7,
      "hs": 555.0,
      "mns": 1
    },
    {
      "a": "07132043",
      "j": 2023,
      "n": "güllesheim",
      "dn": "Güllesheim",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07132044",
      "j": 2023,
      "n": "hamm (sieg)",
      "dn": "Hamm (Sieg)",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07132045",
      "j": 2023,
      "n": "harbach",
      "dn": "Harbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132046",
      "j": 2023,
      "n": "hasselbach",
      "dn": "Hasselbach",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07132047",
      "j": 2023,
      "n": "helmenzen",
      "dn": "Helmenzen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132048",
      "j": 2023,
      "n": "helmeroth",
      "dn": "Helmeroth",
      "bl": 7,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "07132049",
      "j": 2023,
      "n": "hemmelzen",
      "dn": "Hemmelzen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132050",
      "j": 2023,
      "n": "herdorf",
      "dn": "Herdorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132051",
      "j": 2023,
      "n": "heupelzen",
      "dn": "Heupelzen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132052",
      "j": 2023,
      "n": "hilgenroth",
      "dn": "Hilgenroth",
      "bl": 7,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "07132053",
      "j": 2023,
      "n": "hirz-maulsbach",
      "dn": "Hirz-Maulsbach",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07132054",
      "j": 2023,
      "n": "hövels",
      "dn": "Hövels",
      "bl": 7,
      "hs": 540.0,
      "mns": 1
    },
    {
      "a": "07132055",
      "j": 2023,
      "n": "horhausen",
      "dn": "Horhausen",
      "bl": 7,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "07132056",
      "j": 2023,
      "n": "idelberg",
      "dn": "Idelberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132057",
      "j": 2023,
      "n": "ingelbach",
      "dn": "Ingelbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132058",
      "j": 2023,
      "n": "isert",
      "dn": "Isert",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07132059",
      "j": 2023,
      "n": "kausen",
      "dn": "Kausen",
      "bl": 7,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "07132060",
      "j": 2023,
      "n": "kescheid",
      "dn": "Kescheid",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07132061",
      "j": 2023,
      "n": "kettenhausen",
      "dn": "Kettenhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132062",
      "j": 2023,
      "n": "kircheib",
      "dn": "Kircheib",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132063",
      "j": 2023,
      "n": "kirchen",
      "dn": "Kirchen",
      "bl": 7,
      "hs": 570.0,
      "mns": 1
    },
    {
      "a": "07132064",
      "j": 2023,
      "n": "kraam",
      "dn": "Kraam",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132065",
      "j": 2023,
      "n": "krunkel",
      "dn": "Krunkel",
      "bl": 7,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "07132066",
      "j": 2023,
      "n": "malberg",
      "dn": "Malberg",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07132067",
      "j": 2023,
      "n": "mammelzen",
      "dn": "Mammelzen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132068",
      "j": 2023,
      "n": "mauden",
      "dn": "Mauden",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07132069",
      "j": 2023,
      "n": "mehren",
      "dn": "Mehren",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132070",
      "j": 2023,
      "n": "michelbach",
      "dn": "Michelbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132071",
      "j": 2023,
      "n": "molzhain",
      "dn": "Molzhain",
      "bl": 7,
      "hs": 540.0,
      "mns": 1
    },
    {
      "a": "07132072",
      "j": 2023,
      "n": "mudersbach",
      "dn": "Mudersbach",
      "bl": 7,
      "hs": 540.0,
      "mns": 1
    },
    {
      "a": "07132073",
      "j": 2023,
      "n": "nauroth",
      "dn": "Nauroth",
      "bl": 7,
      "hs": 530.0,
      "mns": 1
    },
    {
      "a": "07132075",
      "j": 2023,
      "n": "niederdreisbach",
      "dn": "Niederdreisbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132076",
      "j": 2023,
      "n": "niederfischbach",
      "dn": "Niederfischbach",
      "bl": 7,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "07132077",
      "j": 2023,
      "n": "niederirsen",
      "dn": "Niederirsen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132078",
      "j": 2023,
      "n": "niedersteinebach",
      "dn": "Niedersteinebach",
      "bl": 7,
      "hs": 650.0,
      "mns": 1
    },
    {
      "a": "07132079",
      "j": 2023,
      "n": "nisterberg",
      "dn": "Nisterberg",
      "bl": 7,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "07132080",
      "j": 2023,
      "n": "katzwinkel",
      "dn": "Katzwinkel",
      "bl": 7,
      "hs": 530.0,
      "mns": 1
    },
    {
      "a": "07132081",
      "j": 2023,
      "n": "obererbach",
      "dn": "Obererbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132082",
      "j": 2023,
      "n": "oberirsen",
      "dn": "Oberirsen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132083",
      "j": 2023,
      "n": "oberlahr",
      "dn": "Oberlahr",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07132085",
      "j": 2023,
      "n": "obersteinebach",
      "dn": "Obersteinebach",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07132086",
      "j": 2023,
      "n": "oberwambach",
      "dn": "Oberwambach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132087",
      "j": 2023,
      "n": "ölsen",
      "dn": "Ölsen",
      "bl": 7,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "07132088",
      "j": 2023,
      "n": "orfgen",
      "dn": "Orfgen",
      "bl": 7,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "07132089",
      "j": 2023,
      "n": "peterslahr",
      "dn": "Peterslahr",
      "bl": 7,
      "hs": 535.0,
      "mns": 1
    },
    {
      "a": "07132090",
      "j": 2023,
      "n": "pleckhausen",
      "dn": "Pleckhausen",
      "bl": 7,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "07132091",
      "j": 2023,
      "n": "pracht",
      "dn": "Pracht",
      "bl": 7,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "07132092",
      "j": 2023,
      "n": "racksen",
      "dn": "Racksen",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07132093",
      "j": 2023,
      "n": "reiferscheid",
      "dn": "Reiferscheid",
      "bl": 7,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "07132094",
      "j": 2023,
      "n": "rettersen",
      "dn": "Rettersen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132095",
      "j": 2023,
      "n": "rosenheim",
      "dn": "Rosenheim",
      "bl": 7,
      "hs": 530.0,
      "mns": 1
    },
    {
      "a": "07132096",
      "j": 2023,
      "n": "roth",
      "dn": "Roth",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07132097",
      "j": 2023,
      "n": "rott",
      "dn": "Rott",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07132098",
      "j": 2023,
      "n": "scheuerfeld",
      "dn": "Scheuerfeld",
      "bl": 7,
      "hs": 590.0,
      "mns": 1
    },
    {
      "a": "07132099",
      "j": 2023,
      "n": "schöneberg",
      "dn": "Schöneberg",
      "bl": 7,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "07132100",
      "j": 2023,
      "n": "schürdt",
      "dn": "Schürdt",
      "bl": 7,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "07132101",
      "j": 2023,
      "n": "schutzbach",
      "dn": "Schutzbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132102",
      "j": 2023,
      "n": "seelbach bei hamm",
      "dn": "Seelbach bei Hamm",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132103",
      "j": 2023,
      "n": "seelbach",
      "dn": "Seelbach",
      "bl": 7,
      "hs": 560.0,
      "mns": 1
    },
    {
      "a": "07132104",
      "j": 2023,
      "n": "seifen",
      "dn": "Seifen",
      "bl": 7,
      "hs": 560.0,
      "mns": 1
    },
    {
      "a": "07132105",
      "j": 2023,
      "n": "selbach",
      "dn": "Selbach",
      "bl": 7,
      "hs": 540.0,
      "mns": 1
    },
    {
      "a": "07132106",
      "j": 2023,
      "n": "sörth",
      "dn": "Sörth",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07132107",
      "j": 2023,
      "n": "steinebach/sieg",
      "dn": "Steinebach/Sieg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132108",
      "j": 2023,
      "n": "steineroth",
      "dn": "Steineroth",
      "bl": 7,
      "hs": 525.0,
      "mns": 1
    },
    {
      "a": "07132109",
      "j": 2023,
      "n": "stürzelbach",
      "dn": "Stürzelbach",
      "bl": 7,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "07132110",
      "j": 2023,
      "n": "volkerzen",
      "dn": "Volkerzen",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07132111",
      "j": 2023,
      "n": "wallmenroth",
      "dn": "Wallmenroth",
      "bl": 7,
      "hs": 590.0,
      "mns": 1
    },
    {
      "a": "07132112",
      "j": 2023,
      "n": "walterschen",
      "dn": "Walterschen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132113",
      "j": 2023,
      "n": "weitefeld",
      "dn": "Weitefeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132114",
      "j": 2023,
      "n": "werkhausen",
      "dn": "Werkhausen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07132115",
      "j": 2023,
      "n": "weyerbusch",
      "dn": "Weyerbusch",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07132116",
      "j": 2023,
      "n": "willroth",
      "dn": "Willroth",
      "bl": 7,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "07132117",
      "j": 2023,
      "n": "wissen",
      "dn": "Wissen",
      "bl": 7,
      "hs": 660.0,
      "mns": 1
    },
    {
      "a": "07132118",
      "j": 2023,
      "n": "wölmersen",
      "dn": "Wölmersen",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07132119",
      "j": 2023,
      "n": "ziegenhain",
      "dn": "Ziegenhain",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132201",
      "j": 2023,
      "n": "berod bei hachenburg",
      "dn": "Berod bei Hachenburg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07132501",
      "j": 2023,
      "n": "altenkirchen",
      "dn": "Altenkirchen",
      "bl": 7,
      "hs": 530.0,
      "mns": 1
    },
    {
      "a": "07132502",
      "j": 2023,
      "n": "neitersen",
      "dn": "Neitersen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07133001",
      "j": 2023,
      "n": "abtweiler",
      "dn": "Abtweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133002",
      "j": 2023,
      "n": "allenfeld",
      "dn": "Allenfeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133003",
      "j": 2023,
      "n": "altenbamberg",
      "dn": "Altenbamberg",
      "bl": 7,
      "hs": 555.0,
      "mns": 1
    },
    {
      "a": "07133004",
      "j": 2023,
      "n": "argenschwang",
      "dn": "Argenschwang",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133005",
      "j": 2023,
      "n": "auen",
      "dn": "Auen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133006",
      "j": 2023,
      "n": "bad kreuznach",
      "dn": "Bad Kreuznach",
      "bl": 7,
      "hs": 550.0,
      "mns": 3
    },
    {
      "a": "07133008",
      "j": 2023,
      "n": "bärenbach",
      "dn": "Bärenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133009",
      "j": 2023,
      "n": "bärweiler",
      "dn": "Bärweiler",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07133010",
      "j": 2023,
      "n": "becherbach bei kirn",
      "dn": "Becherbach bei Kirn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133011",
      "j": 2023,
      "n": "becherbach",
      "dn": "Becherbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133012",
      "j": 2023,
      "n": "biebelsheim",
      "dn": "Biebelsheim",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07133013",
      "j": 2023,
      "n": "bockenau",
      "dn": "Bockenau",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133014",
      "j": 2023,
      "n": "boos",
      "dn": "Boos",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07133015",
      "j": 2023,
      "n": "braunweiler",
      "dn": "Braunweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133016",
      "j": 2023,
      "n": "brauweiler",
      "dn": "Brauweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133017",
      "j": 2023,
      "n": "breitenheim",
      "dn": "Breitenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133018",
      "j": 2023,
      "n": "bretzenheim",
      "dn": "Bretzenheim",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07133019",
      "j": 2023,
      "n": "burgsponheim",
      "dn": "Burgsponheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133020",
      "j": 2023,
      "n": "callbach",
      "dn": "Callbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133021",
      "j": 2023,
      "n": "dalberg",
      "dn": "Dalberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133022",
      "j": 2023,
      "n": "daubach",
      "dn": "Daubach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133023",
      "j": 2023,
      "n": "daxweiler",
      "dn": "Daxweiler",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07133024",
      "j": 2023,
      "n": "desloch",
      "dn": "Desloch",
      "bl": 7,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "07133025",
      "j": 2023,
      "n": "dörrebach",
      "dn": "Dörrebach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133026",
      "j": 2023,
      "n": "dorsheim",
      "dn": "Dorsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133027",
      "j": 2023,
      "n": "duchroth",
      "dn": "Duchroth",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133028",
      "j": 2023,
      "n": "eckenroth",
      "dn": "Eckenroth",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07133030",
      "j": 2023,
      "n": "feilbingert",
      "dn": "Feilbingert",
      "bl": 7,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "07133031",
      "j": 2023,
      "n": "frei-laubersheim",
      "dn": "Frei-Laubersheim",
      "bl": 7,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "07133032",
      "j": 2023,
      "n": "fürfeld",
      "dn": "Fürfeld",
      "bl": 7,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "07133033",
      "j": 2023,
      "n": "gebroth",
      "dn": "Gebroth",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133035",
      "j": 2023,
      "n": "guldental",
      "dn": "Guldental",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07133036",
      "j": 2023,
      "n": "gutenberg",
      "dn": "Gutenberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133037",
      "j": 2023,
      "n": "hackenheim",
      "dn": "Hackenheim",
      "bl": 7,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "07133038",
      "j": 2023,
      "n": "hahnenbach",
      "dn": "Hahnenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133039",
      "j": 2023,
      "n": "hallgarten",
      "dn": "Hallgarten",
      "bl": 7,
      "hs": 540.0,
      "mns": 1
    },
    {
      "a": "07133040",
      "j": 2023,
      "n": "hargesheim",
      "dn": "Hargesheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133041",
      "j": 2023,
      "n": "heimweiler",
      "dn": "Heimweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133042",
      "j": 2023,
      "n": "heinzenberg",
      "dn": "Heinzenberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133043",
      "j": 2023,
      "n": "hennweiler",
      "dn": "Hennweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133044",
      "j": 2023,
      "n": "hergenfeld",
      "dn": "Hergenfeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133045",
      "j": 2023,
      "n": "hochstätten",
      "dn": "Hochstätten",
      "bl": 7,
      "hs": 570.0,
      "mns": 1
    },
    {
      "a": "07133046",
      "j": 2023,
      "n": "hochstetten-dhaun",
      "dn": "Hochstetten-Dhaun",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133047",
      "j": 2023,
      "n": "horbach",
      "dn": "Horbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133048",
      "j": 2023,
      "n": "hüffelsheim",
      "dn": "Hüffelsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133049",
      "j": 2023,
      "n": "hundsbach",
      "dn": "Hundsbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133050",
      "j": 2023,
      "n": "ippenschied",
      "dn": "Ippenschied",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133051",
      "j": 2023,
      "n": "jeckenbach",
      "dn": "Jeckenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133052",
      "j": 2023,
      "n": "kirn",
      "dn": "Kirn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133053",
      "j": 2023,
      "n": "kirschroth",
      "dn": "Kirschroth",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133054",
      "j": 2023,
      "n": "langenlonsheim",
      "dn": "Langenlonsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133055",
      "j": 2023,
      "n": "langenthal",
      "dn": "Langenthal",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133056",
      "j": 2023,
      "n": "laubenheim",
      "dn": "Laubenheim",
      "bl": 7,
      "hs": 560.0,
      "mns": 1
    },
    {
      "a": "07133057",
      "j": 2023,
      "n": "lauschied",
      "dn": "Lauschied",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133058",
      "j": 2023,
      "n": "lettweiler",
      "dn": "Lettweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133059",
      "j": 2023,
      "n": "limbach",
      "dn": "Limbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133060",
      "j": 2023,
      "n": "löllbach",
      "dn": "Löllbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133061",
      "j": 2023,
      "n": "mandel",
      "dn": "Mandel",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133062",
      "j": 2023,
      "n": "martinstein",
      "dn": "Martinstein",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133063",
      "j": 2023,
      "n": "meckenbach",
      "dn": "Meckenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133064",
      "j": 2023,
      "n": "meddersheim",
      "dn": "Meddersheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133065",
      "j": 2023,
      "n": "meisenheim",
      "dn": "Meisenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133066",
      "j": 2023,
      "n": "merxheim",
      "dn": "Merxheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133067",
      "j": 2023,
      "n": "monzingen",
      "dn": "Monzingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133068",
      "j": 2023,
      "n": "münchwald",
      "dn": "Münchwald",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133069",
      "j": 2023,
      "n": "neu-bamberg",
      "dn": "Neu-Bamberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133070",
      "j": 2023,
      "n": "niederhausen",
      "dn": "Niederhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133071",
      "j": 2023,
      "n": "norheim",
      "dn": "Norheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133072",
      "j": 2023,
      "n": "nußbaum",
      "dn": "Nußbaum",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133073",
      "j": 2023,
      "n": "oberhausen bei kirn",
      "dn": "Oberhausen bei Kirn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133074",
      "j": 2023,
      "n": "oberhausen an der nahe",
      "dn": "Oberhausen an der Nahe",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133075",
      "j": 2023,
      "n": "oberstreit",
      "dn": "Oberstreit",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133076",
      "j": 2023,
      "n": "odernheim am glan",
      "dn": "Odernheim am Glan",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133077",
      "j": 2023,
      "n": "otzweiler",
      "dn": "Otzweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133078",
      "j": 2023,
      "n": "pfaffen-schwabenheim",
      "dn": "Pfaffen-Schwabenheim",
      "bl": 7,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "07133080",
      "j": 2023,
      "n": "pleitersheim",
      "dn": "Pleitersheim",
      "bl": 7,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "07133081",
      "j": 2023,
      "n": "raumbach",
      "dn": "Raumbach",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07133082",
      "j": 2023,
      "n": "rehbach",
      "dn": "Rehbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133083",
      "j": 2023,
      "n": "rehborn",
      "dn": "Rehborn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133084",
      "j": 2023,
      "n": "reiffelbach",
      "dn": "Reiffelbach",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07133085",
      "j": 2023,
      "n": "roth",
      "dn": "Roth",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133086",
      "j": 2023,
      "n": "roxheim",
      "dn": "Roxheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133087",
      "j": 2023,
      "n": "rümmelsheim",
      "dn": "Rümmelsheim",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07133088",
      "j": 2023,
      "n": "sankt katharinen",
      "dn": "Sankt Katharinen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133089",
      "j": 2023,
      "n": "schloßböckelheim",
      "dn": "Schloßböckelheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133090",
      "j": 2023,
      "n": "schmittweiler",
      "dn": "Schmittweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133091",
      "j": 2023,
      "n": "schöneberg",
      "dn": "Schöneberg",
      "bl": 7,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "07133092",
      "j": 2023,
      "n": "schweinschied",
      "dn": "Schweinschied",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133093",
      "j": 2023,
      "n": "schweppenhausen",
      "dn": "Schweppenhausen",
      "bl": 7,
      "hs": 518.0,
      "mns": 1
    },
    {
      "a": "07133094",
      "j": 2023,
      "n": "seesbach",
      "dn": "Seesbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133095",
      "j": 2023,
      "n": "seibersbach",
      "dn": "Seibersbach",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07133096",
      "j": 2023,
      "n": "simmertal",
      "dn": "Simmertal",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133098",
      "j": 2023,
      "n": "sommerloch",
      "dn": "Sommerloch",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07133099",
      "j": 2023,
      "n": "spabrücken",
      "dn": "Spabrücken",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133100",
      "j": 2023,
      "n": "spall",
      "dn": "Spall",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133101",
      "j": 2023,
      "n": "sponheim",
      "dn": "Sponheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133102",
      "j": 2023,
      "n": "staudernheim",
      "dn": "Staudernheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133103",
      "j": 2023,
      "n": "stromberg",
      "dn": "Stromberg",
      "bl": 7,
      "hs": 505.0,
      "mns": 1
    },
    {
      "a": "07133104",
      "j": 2023,
      "n": "tiefenthal",
      "dn": "Tiefenthal",
      "bl": 7,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "07133105",
      "j": 2023,
      "n": "traisen",
      "dn": "Traisen",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07133106",
      "j": 2023,
      "n": "volxheim",
      "dn": "Volxheim",
      "bl": 7,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "07133107",
      "j": 2023,
      "n": "waldböckelheim",
      "dn": "Waldböckelheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133108",
      "j": 2023,
      "n": "waldlaubersheim",
      "dn": "Waldlaubersheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133109",
      "j": 2023,
      "n": "wallhausen",
      "dn": "Wallhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133110",
      "j": 2023,
      "n": "warmsroth",
      "dn": "Warmsroth",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07133111",
      "j": 2023,
      "n": "weiler bei monzingen",
      "dn": "Weiler bei Monzingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133112",
      "j": 2023,
      "n": "weinsheim",
      "dn": "Weinsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133113",
      "j": 2023,
      "n": "weitersborn",
      "dn": "Weitersborn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133114",
      "j": 2023,
      "n": "windesheim",
      "dn": "Windesheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133115",
      "j": 2023,
      "n": "winterbach",
      "dn": "Winterbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133116",
      "j": 2023,
      "n": "winterburg",
      "dn": "Winterburg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133117",
      "j": 2023,
      "n": "rüdesheim",
      "dn": "Rüdesheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133201",
      "j": 2023,
      "n": "bruschied",
      "dn": "Bruschied",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133202",
      "j": 2023,
      "n": "kellenbach",
      "dn": "Kellenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133203",
      "j": 2023,
      "n": "königsau",
      "dn": "Königsau",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133204",
      "j": 2023,
      "n": "schneppenbach",
      "dn": "Schneppenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07133205",
      "j": 2023,
      "n": "schwarzerden",
      "dn": "Schwarzerden",
      "bl": 7,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "07133501",
      "j": 2023,
      "n": "bad sobernheim",
      "dn": "Bad Sobernheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134001",
      "j": 2023,
      "n": "abentheuer",
      "dn": "Abentheuer",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134002",
      "j": 2023,
      "n": "achtelsbach",
      "dn": "Achtelsbach",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07134003",
      "j": 2023,
      "n": "allenbach",
      "dn": "Allenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134004",
      "j": 2023,
      "n": "asbach",
      "dn": "Asbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134005",
      "j": 2023,
      "n": "baumholder",
      "dn": "Baumholder",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07134006",
      "j": 2023,
      "n": "bergen",
      "dn": "Bergen",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07134007",
      "j": 2023,
      "n": "berglangenbach",
      "dn": "Berglangenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134008",
      "j": 2023,
      "n": "berschweiler bei baumholder",
      "dn": "Berschweiler bei Baumholder",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134009",
      "j": 2023,
      "n": "berschweiler bei kirn",
      "dn": "Berschweiler bei Kirn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134010",
      "j": 2023,
      "n": "birkenfeld",
      "dn": "Birkenfeld",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07134011",
      "j": 2023,
      "n": "börfink",
      "dn": "Börfink",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134012",
      "j": 2023,
      "n": "bollenbach",
      "dn": "Bollenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134013",
      "j": 2023,
      "n": "breitenthal",
      "dn": "Breitenthal",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134014",
      "j": 2023,
      "n": "bruchweiler",
      "dn": "Bruchweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134015",
      "j": 2023,
      "n": "brücken",
      "dn": "Brücken",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07134016",
      "j": 2023,
      "n": "buhlenberg",
      "dn": "Buhlenberg",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07134017",
      "j": 2023,
      "n": "bundenbach",
      "dn": "Bundenbach",
      "bl": 7,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "07134018",
      "j": 2023,
      "n": "dambach",
      "dn": "Dambach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134019",
      "j": 2023,
      "n": "dickesbach",
      "dn": "Dickesbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134020",
      "j": 2023,
      "n": "dienstweiler",
      "dn": "Dienstweiler",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07134021",
      "j": 2023,
      "n": "eckersweiler",
      "dn": "Eckersweiler",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07134022",
      "j": 2023,
      "n": "elchweiler",
      "dn": "Elchweiler",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07134023",
      "j": 2023,
      "n": "ellenberg",
      "dn": "Ellenberg",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07134024",
      "j": 2023,
      "n": "ellweiler",
      "dn": "Ellweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134025",
      "j": 2023,
      "n": "fischbach",
      "dn": "Fischbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134026",
      "j": 2023,
      "n": "fohren-linden",
      "dn": "Fohren-Linden",
      "bl": 7,
      "hs": 100.0,
      "mns": 1
    },
    {
      "a": "07134027",
      "j": 2023,
      "n": "frauenberg",
      "dn": "Frauenberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134028",
      "j": 2023,
      "n": "gerach",
      "dn": "Gerach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134029",
      "j": 2023,
      "n": "gimbweiler",
      "dn": "Gimbweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134030",
      "j": 2023,
      "n": "gösenroth",
      "dn": "Gösenroth",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07134031",
      "j": 2023,
      "n": "gollenberg",
      "dn": "Gollenberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134032",
      "j": 2023,
      "n": "griebelschied",
      "dn": "Griebelschied",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134033",
      "j": 2023,
      "n": "hahnweiler",
      "dn": "Hahnweiler",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07134034",
      "j": 2023,
      "n": "hattgenstein",
      "dn": "Hattgenstein",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07134035",
      "j": 2023,
      "n": "hausen",
      "dn": "Hausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134036",
      "j": 2023,
      "n": "heimbach",
      "dn": "Heimbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134037",
      "j": 2023,
      "n": "hellertshausen",
      "dn": "Hellertshausen",
      "bl": 7,
      "hs": 260.0,
      "mns": 1
    },
    {
      "a": "07134038",
      "j": 2023,
      "n": "herborn",
      "dn": "Herborn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134039",
      "j": 2023,
      "n": "herrstein",
      "dn": "Herrstein",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134040",
      "j": 2023,
      "n": "hettenrodt",
      "dn": "Hettenrodt",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134041",
      "j": 2023,
      "n": "hintertiefenbach",
      "dn": "Hintertiefenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134042",
      "j": 2023,
      "n": "hoppstädten-weiersbach",
      "dn": "Hoppstädten-Weiersbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134043",
      "j": 2023,
      "n": "horbruch",
      "dn": "Horbruch",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134044",
      "j": 2023,
      "n": "hottenbach",
      "dn": "Hottenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134045",
      "j": 2023,
      "n": "idar-oberstein",
      "dn": "Idar-Oberstein",
      "bl": 7,
      "hs": 290.0,
      "mns": 1
    },
    {
      "a": "07134046",
      "j": 2023,
      "n": "kempfeld",
      "dn": "Kempfeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134047",
      "j": 2023,
      "n": "kirschweiler",
      "dn": "Kirschweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134048",
      "j": 2023,
      "n": "kronweiler",
      "dn": "Kronweiler",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07134049",
      "j": 2023,
      "n": "krummenau",
      "dn": "Krummenau",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07134050",
      "j": 2023,
      "n": "leisel",
      "dn": "Leisel",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134051",
      "j": 2023,
      "n": "leitzweiler",
      "dn": "Leitzweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134052",
      "j": 2023,
      "n": "mackenrodt",
      "dn": "Mackenrodt",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134053",
      "j": 2023,
      "n": "meckenbach",
      "dn": "Meckenbach",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07134054",
      "j": 2023,
      "n": "mettweiler",
      "dn": "Mettweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134055",
      "j": 2023,
      "n": "mittelreidenbach",
      "dn": "Mittelreidenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134056",
      "j": 2023,
      "n": "mörschied",
      "dn": "Mörschied",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134057",
      "j": 2023,
      "n": "niederbrombach",
      "dn": "Niederbrombach",
      "bl": 7,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "07134058",
      "j": 2023,
      "n": "niederhambach",
      "dn": "Niederhambach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134059",
      "j": 2023,
      "n": "niederhosenbach",
      "dn": "Niederhosenbach",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07134060",
      "j": 2023,
      "n": "niederwörresbach",
      "dn": "Niederwörresbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134061",
      "j": 2023,
      "n": "nohen",
      "dn": "Nohen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134062",
      "j": 2023,
      "n": "oberbrombach",
      "dn": "Oberbrombach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134063",
      "j": 2023,
      "n": "oberhambach",
      "dn": "Oberhambach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134064",
      "j": 2023,
      "n": "oberhosenbach",
      "dn": "Oberhosenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134065",
      "j": 2023,
      "n": "oberkirn",
      "dn": "Oberkirn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134066",
      "j": 2023,
      "n": "oberreidenbach",
      "dn": "Oberreidenbach",
      "bl": 7,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "07134067",
      "j": 2023,
      "n": "oberwörresbach",
      "dn": "Oberwörresbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134068",
      "j": 2023,
      "n": "reichenbach",
      "dn": "Reichenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134069",
      "j": 2023,
      "n": "rhaunen",
      "dn": "Rhaunen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134070",
      "j": 2023,
      "n": "rimsberg",
      "dn": "Rimsberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134071",
      "j": 2023,
      "n": "rinzenberg",
      "dn": "Rinzenberg",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07134072",
      "j": 2023,
      "n": "rötsweiler-nockenthal",
      "dn": "Rötsweiler-Nockenthal",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07134073",
      "j": 2023,
      "n": "rohrbach",
      "dn": "Rohrbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134074",
      "j": 2023,
      "n": "rückweiler",
      "dn": "Rückweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134075",
      "j": 2023,
      "n": "ruschberg",
      "dn": "Ruschberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134076",
      "j": 2023,
      "n": "schauren",
      "dn": "Schauren",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134077",
      "j": 2023,
      "n": "schmidthachenbach",
      "dn": "Schmidthachenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134078",
      "j": 2023,
      "n": "schmißberg",
      "dn": "Schmißberg",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07134079",
      "j": 2023,
      "n": "schwerbach",
      "dn": "Schwerbach",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07134080",
      "j": 2023,
      "n": "schwollen",
      "dn": "Schwollen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07134081",
      "j": 2023,
      "n": "sensweiler",
      "dn": "Sensweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134082",
      "j": 2023,
      "n": "sien",
      "dn": "Sien",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134083",
      "j": 2023,
      "n": "sienhachenbach",
      "dn": "Sienhachenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134084",
      "j": 2023,
      "n": "siesbach",
      "dn": "Siesbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134085",
      "j": 2023,
      "n": "sonnenberg-winnenberg",
      "dn": "Sonnenberg-Winnenberg",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07134086",
      "j": 2023,
      "n": "sonnschied",
      "dn": "Sonnschied",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134087",
      "j": 2023,
      "n": "stipshausen",
      "dn": "Stipshausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134088",
      "j": 2023,
      "n": "sulzbach",
      "dn": "Sulzbach",
      "bl": 7,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "07134089",
      "j": 2023,
      "n": "veitsrodt",
      "dn": "Veitsrodt",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07134090",
      "j": 2023,
      "n": "vollmersbach",
      "dn": "Vollmersbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134091",
      "j": 2023,
      "n": "weiden",
      "dn": "Weiden",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134092",
      "j": 2023,
      "n": "weitersbach",
      "dn": "Weitersbach",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07134093",
      "j": 2023,
      "n": "wickenrodt",
      "dn": "Wickenrodt",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134094",
      "j": 2023,
      "n": "wilzenberg-hußweiler",
      "dn": "Wilzenberg-Hußweiler",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07134095",
      "j": 2023,
      "n": "wirschweiler",
      "dn": "Wirschweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07134502",
      "j": 2023,
      "n": "langweiler",
      "dn": "Langweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135001",
      "j": 2023,
      "n": "alf",
      "dn": "Alf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135002",
      "j": 2023,
      "n": "alflen",
      "dn": "Alflen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135003",
      "j": 2023,
      "n": "altlay",
      "dn": "Altlay",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135004",
      "j": 2023,
      "n": "altstrimmig",
      "dn": "Altstrimmig",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135005",
      "j": 2023,
      "n": "auderath",
      "dn": "Auderath",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135007",
      "j": 2023,
      "n": "beilstein",
      "dn": "Beilstein",
      "bl": 7,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "07135008",
      "j": 2023,
      "n": "beuren",
      "dn": "Beuren",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135009",
      "j": 2023,
      "n": "binningen",
      "dn": "Binningen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135010",
      "j": 2023,
      "n": "blankenrath",
      "dn": "Blankenrath",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135011",
      "j": 2023,
      "n": "brachtendorf",
      "dn": "Brachtendorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135012",
      "j": 2023,
      "n": "bremm",
      "dn": "Bremm",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135013",
      "j": 2023,
      "n": "briedel",
      "dn": "Briedel",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07135014",
      "j": 2023,
      "n": "brieden",
      "dn": "Brieden",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135015",
      "j": 2023,
      "n": "briedern",
      "dn": "Briedern",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135016",
      "j": 2023,
      "n": "brohl",
      "dn": "Brohl",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135017",
      "j": 2023,
      "n": "bruttig-fankel",
      "dn": "Bruttig-Fankel",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135018",
      "j": 2023,
      "n": "büchel",
      "dn": "Büchel",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135019",
      "j": 2023,
      "n": "bullay",
      "dn": "Bullay",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135020",
      "j": 2023,
      "n": "cochem",
      "dn": "Cochem",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135021",
      "j": 2023,
      "n": "dohr",
      "dn": "Dohr",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135022",
      "j": 2023,
      "n": "dünfus",
      "dn": "Dünfus",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135023",
      "j": 2023,
      "n": "düngenheim",
      "dn": "Düngenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135024",
      "j": 2023,
      "n": "ediger-eller",
      "dn": "Ediger-Eller",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135025",
      "j": 2023,
      "n": "ellenz-poltersdorf",
      "dn": "Ellenz-Poltersdorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135026",
      "j": 2023,
      "n": "eppenberg",
      "dn": "Eppenberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135027",
      "j": 2023,
      "n": "ernst",
      "dn": "Ernst",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135028",
      "j": 2023,
      "n": "eulgem",
      "dn": "Eulgem",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135029",
      "j": 2023,
      "n": "faid",
      "dn": "Faid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135030",
      "j": 2023,
      "n": "filz",
      "dn": "Filz",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135031",
      "j": 2023,
      "n": "forst",
      "dn": "Forst",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135032",
      "j": 2023,
      "n": "forst",
      "dn": "Forst",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135033",
      "j": 2023,
      "n": "gamlen",
      "dn": "Gamlen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135034",
      "j": 2023,
      "n": "gevenich",
      "dn": "Gevenich",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135035",
      "j": 2023,
      "n": "gillenbeuren",
      "dn": "Gillenbeuren",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135036",
      "j": 2023,
      "n": "greimersburg",
      "dn": "Greimersburg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135037",
      "j": 2023,
      "n": "grenderich",
      "dn": "Grenderich",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135038",
      "j": 2023,
      "n": "hambuch",
      "dn": "Hambuch",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135039",
      "j": 2023,
      "n": "haserich",
      "dn": "Haserich",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135040",
      "j": 2023,
      "n": "hauroth",
      "dn": "Hauroth",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135041",
      "j": 2023,
      "n": "hesweiler",
      "dn": "Hesweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135042",
      "j": 2023,
      "n": "illerich",
      "dn": "Illerich",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135043",
      "j": 2023,
      "n": "kaifenheim",
      "dn": "Kaifenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135044",
      "j": 2023,
      "n": "kail",
      "dn": "Kail",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135045",
      "j": 2023,
      "n": "kaisersesch",
      "dn": "Kaisersesch",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135046",
      "j": 2023,
      "n": "kalenborn",
      "dn": "Kalenborn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135048",
      "j": 2023,
      "n": "kliding",
      "dn": "Kliding",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135049",
      "j": 2023,
      "n": "klotten",
      "dn": "Klotten",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135051",
      "j": 2023,
      "n": "landkern",
      "dn": "Landkern",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135052",
      "j": 2023,
      "n": "laubach",
      "dn": "Laubach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135053",
      "j": 2023,
      "n": "lieg",
      "dn": "Lieg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135054",
      "j": 2023,
      "n": "liesenich",
      "dn": "Liesenich",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135056",
      "j": 2023,
      "n": "lütz",
      "dn": "Lütz",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135057",
      "j": 2023,
      "n": "lutzerath",
      "dn": "Lutzerath",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135058",
      "j": 2023,
      "n": "masburg",
      "dn": "Masburg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135060",
      "j": 2023,
      "n": "mesenich",
      "dn": "Mesenich",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135061",
      "j": 2023,
      "n": "mittelstrimmig",
      "dn": "Mittelstrimmig",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135062",
      "j": 2023,
      "n": "möntenich",
      "dn": "Möntenich",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135064",
      "j": 2023,
      "n": "moritzheim",
      "dn": "Moritzheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135065",
      "j": 2023,
      "n": "moselkern",
      "dn": "Moselkern",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135066",
      "j": 2023,
      "n": "müden (mosel)",
      "dn": "Müden (Mosel)",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135067",
      "j": 2023,
      "n": "müllenbach",
      "dn": "Müllenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135068",
      "j": 2023,
      "n": "neef",
      "dn": "Neef",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135069",
      "j": 2023,
      "n": "nehren",
      "dn": "Nehren",
      "bl": 7,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "07135070",
      "j": 2023,
      "n": "panzweiler",
      "dn": "Panzweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135071",
      "j": 2023,
      "n": "peterswald-löffelscheid",
      "dn": "Peterswald-Löffelscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135072",
      "j": 2023,
      "n": "pommern",
      "dn": "Pommern",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135073",
      "j": 2023,
      "n": "pünderich",
      "dn": "Pünderich",
      "bl": 7,
      "hs": 493.0,
      "mns": 1
    },
    {
      "a": "07135074",
      "j": 2023,
      "n": "reidenhausen",
      "dn": "Reidenhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135075",
      "j": 2023,
      "n": "roes",
      "dn": "Roes",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135076",
      "j": 2023,
      "n": "sankt aldegund",
      "dn": "Sankt Aldegund",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135077",
      "j": 2023,
      "n": "schauren",
      "dn": "Schauren",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135078",
      "j": 2023,
      "n": "schmitt",
      "dn": "Schmitt",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07135079",
      "j": 2023,
      "n": "senheim",
      "dn": "Senheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135080",
      "j": 2023,
      "n": "sosberg",
      "dn": "Sosberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135081",
      "j": 2023,
      "n": "tellig",
      "dn": "Tellig",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135082",
      "j": 2023,
      "n": "treis-karden",
      "dn": "Treis-Karden",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135083",
      "j": 2023,
      "n": "ulmen",
      "dn": "Ulmen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135084",
      "j": 2023,
      "n": "urmersbach",
      "dn": "Urmersbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135085",
      "j": 2023,
      "n": "urschmitt",
      "dn": "Urschmitt",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135086",
      "j": 2023,
      "n": "valwig",
      "dn": "Valwig",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135087",
      "j": 2023,
      "n": "wagenhausen",
      "dn": "Wagenhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135088",
      "j": 2023,
      "n": "walhausen",
      "dn": "Walhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135089",
      "j": 2023,
      "n": "weiler",
      "dn": "Weiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135090",
      "j": 2023,
      "n": "wirfus",
      "dn": "Wirfus",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135091",
      "j": 2023,
      "n": "wollmerath",
      "dn": "Wollmerath",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135092",
      "j": 2023,
      "n": "zell (mosel)",
      "dn": "Zell (Mosel)",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135093",
      "j": 2023,
      "n": "zettingen",
      "dn": "Zettingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135501",
      "j": 2023,
      "n": "bad bertrich",
      "dn": "Bad Bertrich",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07135502",
      "j": 2023,
      "n": "leienkaul",
      "dn": "Leienkaul",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137001",
      "j": 2023,
      "n": "acht (eifel)",
      "dn": "Acht (Eifel)",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137003",
      "j": 2023,
      "n": "andernach",
      "dn": "Andernach",
      "bl": 7,
      "hs": 550.0,
      "mns": 2
    },
    {
      "a": "07137004",
      "j": 2023,
      "n": "anschau",
      "dn": "Anschau",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137006",
      "j": 2023,
      "n": "arft",
      "dn": "Arft",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137007",
      "j": 2023,
      "n": "baar",
      "dn": "Baar",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137008",
      "j": 2023,
      "n": "bell",
      "dn": "Bell",
      "bl": 7,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "07137011",
      "j": 2023,
      "n": "bermel",
      "dn": "Bermel",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137014",
      "j": 2023,
      "n": "boos",
      "dn": "Boos",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137019",
      "j": 2023,
      "n": "ditscheid",
      "dn": "Ditscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137023",
      "j": 2023,
      "n": "einig",
      "dn": "Einig",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137025",
      "j": 2023,
      "n": "ettringen",
      "dn": "Ettringen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137027",
      "j": 2023,
      "n": "gappenach",
      "dn": "Gappenach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137029",
      "j": 2023,
      "n": "gering",
      "dn": "Gering",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137030",
      "j": 2023,
      "n": "gierschnach",
      "dn": "Gierschnach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137034",
      "j": 2023,
      "n": "hausten",
      "dn": "Hausten",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137035",
      "j": 2023,
      "n": "herresbach",
      "dn": "Herresbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137036",
      "j": 2023,
      "n": "hirten",
      "dn": "Hirten",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137041",
      "j": 2023,
      "n": "kalt",
      "dn": "Kalt",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137043",
      "j": 2023,
      "n": "kehrig",
      "dn": "Kehrig",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137048",
      "j": 2023,
      "n": "kerben",
      "dn": "Kerben",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137049",
      "j": 2023,
      "n": "kirchwald",
      "dn": "Kirchwald",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137053",
      "j": 2023,
      "n": "kollig",
      "dn": "Kollig",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137055",
      "j": 2023,
      "n": "kottenheim",
      "dn": "Kottenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137056",
      "j": 2023,
      "n": "kretz",
      "dn": "Kretz",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137057",
      "j": 2023,
      "n": "kruft",
      "dn": "Kruft",
      "bl": 7,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "07137060",
      "j": 2023,
      "n": "langenfeld",
      "dn": "Langenfeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137061",
      "j": 2023,
      "n": "langscheid",
      "dn": "Langscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137063",
      "j": 2023,
      "n": "lind",
      "dn": "Lind",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137065",
      "j": 2023,
      "n": "lonnig",
      "dn": "Lonnig",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137066",
      "j": 2023,
      "n": "luxem",
      "dn": "Luxem",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07137068",
      "j": 2023,
      "n": "mayen",
      "dn": "Mayen",
      "bl": 7,
      "hs": 535.0,
      "mns": 2
    },
    {
      "a": "07137069",
      "j": 2023,
      "n": "mendig",
      "dn": "Mendig",
      "bl": 7,
      "hs": 560.0,
      "mns": 1
    },
    {
      "a": "07137070",
      "j": 2023,
      "n": "mertloch",
      "dn": "Mertloch",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137074",
      "j": 2023,
      "n": "monreal",
      "dn": "Monreal",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137077",
      "j": 2023,
      "n": "münk",
      "dn": "Münk",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137079",
      "j": 2023,
      "n": "nachtsheim",
      "dn": "Nachtsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137080",
      "j": 2023,
      "n": "naunheim",
      "dn": "Naunheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137081",
      "j": 2023,
      "n": "nickenich",
      "dn": "Nickenich",
      "bl": 7,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "07137086",
      "j": 2024,
      "n": "ochtendung",
      "dn": "Ochtendung",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137087",
      "j": 2023,
      "n": "pillig",
      "dn": "Pillig",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137088",
      "j": 2023,
      "n": "plaidt",
      "dn": "Plaidt",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137089",
      "j": 2023,
      "n": "polch",
      "dn": "Polch",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137092",
      "j": 2023,
      "n": "reudelsterz",
      "dn": "Reudelsterz",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137093",
      "j": 2023,
      "n": "rieden",
      "dn": "Rieden",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137095",
      "j": 2023,
      "n": "rüber",
      "dn": "Rüber",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137096",
      "j": 2023,
      "n": "saffig",
      "dn": "Saffig",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137097",
      "j": 2023,
      "n": "sankt johann",
      "dn": "Sankt Johann",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137099",
      "j": 2023,
      "n": "siebenbach",
      "dn": "Siebenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137101",
      "j": 2023,
      "n": "thür",
      "dn": "Thür",
      "bl": 7,
      "hs": 560.0,
      "mns": 1
    },
    {
      "a": "07137102",
      "j": 2023,
      "n": "trimbs",
      "dn": "Trimbs",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137105",
      "j": 2025,
      "n": "virneburg",
      "dn": "Virneburg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137106",
      "j": 2023,
      "n": "volkesfeld",
      "dn": "Volkesfeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137110",
      "j": 2023,
      "n": "weiler",
      "dn": "Weiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137112",
      "j": 2023,
      "n": "welling",
      "dn": "Welling",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137113",
      "j": 2023,
      "n": "welschenbach",
      "dn": "Welschenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137114",
      "j": 2023,
      "n": "wierschem",
      "dn": "Wierschem",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137201",
      "j": 2023,
      "n": "alken",
      "dn": "Alken",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137202",
      "j": 2023,
      "n": "bassenheim",
      "dn": "Bassenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137203",
      "j": 2023,
      "n": "bendorf",
      "dn": "Bendorf",
      "bl": 7,
      "hs": 500.0,
      "mns": 2
    },
    {
      "a": "07137204",
      "j": 2023,
      "n": "brey",
      "dn": "Brey",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137205",
      "j": 2023,
      "n": "brodenbach",
      "dn": "Brodenbach",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07137206",
      "j": 2023,
      "n": "burgen",
      "dn": "Burgen",
      "bl": 7,
      "hs": 570.0,
      "mns": 1
    },
    {
      "a": "07137207",
      "j": 2023,
      "n": "dieblich",
      "dn": "Dieblich",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07137208",
      "j": 2023,
      "n": "hatzenport",
      "dn": "Hatzenport",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137209",
      "j": 2023,
      "n": "kaltenengers",
      "dn": "Kaltenengers",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137211",
      "j": 2023,
      "n": "kettig",
      "dn": "Kettig",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137212",
      "j": 2023,
      "n": "kobern-gondorf",
      "dn": "Kobern-Gondorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137214",
      "j": 2023,
      "n": "löf",
      "dn": "Löf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137215",
      "j": 2023,
      "n": "macken",
      "dn": "Macken",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137216",
      "j": 2023,
      "n": "mülheim-kärlich",
      "dn": "Mülheim-Kärlich",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137217",
      "j": 2023,
      "n": "niederfell",
      "dn": "Niederfell",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137218",
      "j": 2023,
      "n": "niederwerth",
      "dn": "Niederwerth",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07137219",
      "j": 2023,
      "n": "nörtershausen",
      "dn": "Nörtershausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137220",
      "j": 2023,
      "n": "oberfell",
      "dn": "Oberfell",
      "bl": 7,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "07137221",
      "j": 2023,
      "n": "rhens",
      "dn": "Rhens",
      "bl": 7,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "07137222",
      "j": 2023,
      "n": "sankt sebastian",
      "dn": "Sankt Sebastian",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137223",
      "j": 2023,
      "n": "spay",
      "dn": "Spay",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137224",
      "j": 2023,
      "n": "urbar",
      "dn": "Urbar",
      "bl": 7,
      "hs": 565.0,
      "mns": 1
    },
    {
      "a": "07137225",
      "j": 2023,
      "n": "urmitz",
      "dn": "Urmitz",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137226",
      "j": 2023,
      "n": "vallendar",
      "dn": "Vallendar",
      "bl": 7,
      "hs": 565.0,
      "mns": 1
    },
    {
      "a": "07137227",
      "j": 2023,
      "n": "waldesch",
      "dn": "Waldesch",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137228",
      "j": 2023,
      "n": "weißenthurm",
      "dn": "Weißenthurm",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137229",
      "j": 2023,
      "n": "weitersburg",
      "dn": "Weitersburg",
      "bl": 7,
      "hs": 565.0,
      "mns": 1
    },
    {
      "a": "07137230",
      "j": 2023,
      "n": "winningen",
      "dn": "Winningen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07137231",
      "j": 2023,
      "n": "wolken",
      "dn": "Wolken",
      "bl": 7,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "07137501",
      "j": 2023,
      "n": "münstermaifeld",
      "dn": "Münstermaifeld",
      "bl": 7,
      "hs": 485.0,
      "mns": 1
    },
    {
      "a": "07137504",
      "j": 2023,
      "n": "lehmen",
      "dn": "Lehmen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07138002",
      "j": 2023,
      "n": "anhausen",
      "dn": "Anhausen",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07138003",
      "j": 2023,
      "n": "asbach",
      "dn": "Asbach",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07138004",
      "j": 2023,
      "n": "bad hönningen",
      "dn": "Bad Hönningen",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07138005",
      "j": 2023,
      "n": "bonefeld",
      "dn": "Bonefeld",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07138006",
      "j": 2025,
      "n": "breitscheid",
      "dn": "Breitscheid",
      "bl": 7,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "07138007",
      "j": 2023,
      "n": "hausen (wied)",
      "dn": "Hausen (Wied)",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138008",
      "j": 2023,
      "n": "bruchhausen",
      "dn": "Bruchhausen",
      "bl": 7,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "07138009",
      "j": 2023,
      "n": "dattenberg",
      "dn": "Dattenberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138010",
      "j": 2023,
      "n": "datzeroth",
      "dn": "Datzeroth",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138011",
      "j": 2023,
      "n": "dernbach",
      "dn": "Dernbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138012",
      "j": 2023,
      "n": "dierdorf",
      "dn": "Dierdorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138013",
      "j": 2023,
      "n": "döttesfeld",
      "dn": "Döttesfeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138014",
      "j": 2023,
      "n": "dürrholz",
      "dn": "Dürrholz",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138015",
      "j": 2023,
      "n": "ehlscheid",
      "dn": "Ehlscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138019",
      "j": 2023,
      "n": "erpel",
      "dn": "Erpel",
      "bl": 7,
      "hs": 610.0,
      "mns": 1
    },
    {
      "a": "07138023",
      "j": 2023,
      "n": "großmaischeid",
      "dn": "Großmaischeid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138024",
      "j": 2023,
      "n": "hammerstein",
      "dn": "Hammerstein",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138025",
      "j": 2023,
      "n": "hanroth",
      "dn": "Hanroth",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138026",
      "j": 2023,
      "n": "hardert",
      "dn": "Hardert",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07138027",
      "j": 2023,
      "n": "harschbach",
      "dn": "Harschbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138030",
      "j": 2023,
      "n": "hümmerich",
      "dn": "Hümmerich",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07138031",
      "j": 2023,
      "n": "isenburg",
      "dn": "Isenburg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138034",
      "j": 2023,
      "n": "kleinmaischeid",
      "dn": "Kleinmaischeid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138036",
      "j": 2023,
      "n": "kurtscheid",
      "dn": "Kurtscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138037",
      "j": 2023,
      "n": "leubsdorf",
      "dn": "Leubsdorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138038",
      "j": 2023,
      "n": "leutesdorf",
      "dn": "Leutesdorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138040",
      "j": 2023,
      "n": "linkenbach",
      "dn": "Linkenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138041",
      "j": 2023,
      "n": "linz am rhein",
      "dn": "Linz am Rhein",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138042",
      "j": 2023,
      "n": "meinborn",
      "dn": "Meinborn",
      "bl": 7,
      "hs": 317.0,
      "mns": 1
    },
    {
      "a": "07138043",
      "j": 2023,
      "n": "melsbach",
      "dn": "Melsbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138044",
      "j": 2023,
      "n": "neustadt (wied)",
      "dn": "Neustadt (Wied)",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07138045",
      "j": 2023,
      "n": "neuwied",
      "dn": "Neuwied",
      "bl": 7,
      "hs": 610.0,
      "mns": 2
    },
    {
      "a": "07138047",
      "j": 2023,
      "n": "niederbreitbach",
      "dn": "Niederbreitbach",
      "bl": 7,
      "hs": 540.0,
      "mns": 1
    },
    {
      "a": "07138048",
      "j": 2023,
      "n": "niederhofen",
      "dn": "Niederhofen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138050",
      "j": 2023,
      "n": "niederwambach",
      "dn": "Niederwambach",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07138052",
      "j": 2023,
      "n": "oberdreis",
      "dn": "Oberdreis",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138053",
      "j": 2023,
      "n": "oberhonnefeld-gierend",
      "dn": "Oberhonnefeld-Gierend",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138054",
      "j": 2023,
      "n": "oberraden",
      "dn": "Oberraden",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138055",
      "j": 2023,
      "n": "ockenfels",
      "dn": "Ockenfels",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138057",
      "j": 2023,
      "n": "puderbach",
      "dn": "Puderbach",
      "bl": 7,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "07138058",
      "j": 2023,
      "n": "ratzert",
      "dn": "Ratzert",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138059",
      "j": 2023,
      "n": "raubach",
      "dn": "Raubach",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07138061",
      "j": 2023,
      "n": "rengsdorf",
      "dn": "Rengsdorf",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07138062",
      "j": 2023,
      "n": "rheinbreitbach",
      "dn": "Rheinbreitbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138063",
      "j": 2023,
      "n": "rheinbrohl",
      "dn": "Rheinbrohl",
      "bl": 7,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "07138064",
      "j": 2023,
      "n": "rodenbach bei puderbach",
      "dn": "Rodenbach bei Puderbach",
      "bl": 7,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "07138065",
      "j": 2023,
      "n": "roßbach",
      "dn": "Roßbach",
      "bl": 7,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "07138066",
      "j": 2023,
      "n": "rüscheid",
      "dn": "Rüscheid",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07138068",
      "j": 2023,
      "n": "sankt katharinen",
      "dn": "Sankt Katharinen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138069",
      "j": 2023,
      "n": "stebach",
      "dn": "Stebach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138070",
      "j": 2023,
      "n": "steimel",
      "dn": "Steimel",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138071",
      "j": 2023,
      "n": "straßenhaus",
      "dn": "Straßenhaus",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138072",
      "j": 2023,
      "n": "thalhausen",
      "dn": "Thalhausen",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07138073",
      "j": 2023,
      "n": "unkel",
      "dn": "Unkel",
      "bl": 7,
      "hs": 595.0,
      "mns": 1
    },
    {
      "a": "07138074",
      "j": 2023,
      "n": "urbach",
      "dn": "Urbach",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07138075",
      "j": 2023,
      "n": "vettelschoß",
      "dn": "Vettelschoß",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07138076",
      "j": 2023,
      "n": "waldbreitbach",
      "dn": "Waldbreitbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138077",
      "j": 2023,
      "n": "windhagen",
      "dn": "Windhagen",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07138078",
      "j": 2023,
      "n": "woldert",
      "dn": "Woldert",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07138080",
      "j": 2023,
      "n": "buchholz",
      "dn": "Buchholz",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07138201",
      "j": 2023,
      "n": "marienhausen",
      "dn": "Marienhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07138501",
      "j": 2023,
      "n": "kasbach-ohlenberg",
      "dn": "Kasbach-Ohlenberg",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07140001",
      "j": 2023,
      "n": "alterkülz",
      "dn": "Alterkülz",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140002",
      "j": 2023,
      "n": "altweidelbach",
      "dn": "Altweidelbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140003",
      "j": 2023,
      "n": "argenthal",
      "dn": "Argenthal",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140005",
      "j": 2023,
      "n": "badenhard",
      "dn": "Badenhard",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07140006",
      "j": 2023,
      "n": "bärenbach",
      "dn": "Bärenbach",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07140007",
      "j": 2023,
      "n": "belg",
      "dn": "Belg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140008",
      "j": 2023,
      "n": "belgweiler",
      "dn": "Belgweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140009",
      "j": 2023,
      "n": "bell",
      "dn": "Bell",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140010",
      "j": 2023,
      "n": "beltheim",
      "dn": "Beltheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140011",
      "j": 2023,
      "n": "benzweiler",
      "dn": "Benzweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140012",
      "j": 2023,
      "n": "bergenhausen",
      "dn": "Bergenhausen",
      "bl": 7,
      "hs": 0.0,
      "mns": 1
    },
    {
      "a": "07140014",
      "j": 2023,
      "n": "bickenbach",
      "dn": "Bickenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140015",
      "j": 2023,
      "n": "biebern",
      "dn": "Biebern",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140016",
      "j": 2023,
      "n": "birkheim",
      "dn": "Birkheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140018",
      "j": 2023,
      "n": "braunshorn",
      "dn": "Braunshorn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140020",
      "j": 2023,
      "n": "bubach",
      "dn": "Bubach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140021",
      "j": 2023,
      "n": "buch",
      "dn": "Buch",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140023",
      "j": 2023,
      "n": "budenbach",
      "dn": "Budenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140024",
      "j": 2023,
      "n": "büchenbeuren",
      "dn": "Büchenbeuren",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140025",
      "j": 2023,
      "n": "damscheid",
      "dn": "Damscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140027",
      "j": 2023,
      "n": "dichtelbach",
      "dn": "Dichtelbach",
      "bl": 7,
      "hs": 335.0,
      "mns": 1
    },
    {
      "a": "07140028",
      "j": 2023,
      "n": "dickenschied",
      "dn": "Dickenschied",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140029",
      "j": 2023,
      "n": "dill",
      "dn": "Dill",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07140030",
      "j": 2023,
      "n": "dillendorf",
      "dn": "Dillendorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140031",
      "j": 2023,
      "n": "dörth",
      "dn": "Dörth",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140035",
      "j": 2023,
      "n": "ellern",
      "dn": "Ellern",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140036",
      "j": 2023,
      "n": "emmelshausen",
      "dn": "Emmelshausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140037",
      "j": 2023,
      "n": "erbach",
      "dn": "Erbach",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07140039",
      "j": 2023,
      "n": "fronhofen",
      "dn": "Fronhofen",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07140040",
      "j": 2023,
      "n": "gehlweiler",
      "dn": "Gehlweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140041",
      "j": 2023,
      "n": "gemünden",
      "dn": "Gemünden",
      "bl": 7,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "07140042",
      "j": 2023,
      "n": "gödenroth",
      "dn": "Gödenroth",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140043",
      "j": 2023,
      "n": "gondershausen",
      "dn": "Gondershausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140044",
      "j": 2023,
      "n": "hahn",
      "dn": "Hahn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140045",
      "j": 2023,
      "n": "halsenbach",
      "dn": "Halsenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140046",
      "j": 2023,
      "n": "hasselbach",
      "dn": "Hasselbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140047",
      "j": 2023,
      "n": "hausbay",
      "dn": "Hausbay",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140048",
      "j": 2023,
      "n": "hecken",
      "dn": "Hecken",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140049",
      "j": 2023,
      "n": "heinzenbach",
      "dn": "Heinzenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140050",
      "j": 2023,
      "n": "henau",
      "dn": "Henau",
      "bl": 7,
      "hs": 335.0,
      "mns": 1
    },
    {
      "a": "07140053",
      "j": 2023,
      "n": "hirschfeld",
      "dn": "Hirschfeld",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07140055",
      "j": 2023,
      "n": "hollnich",
      "dn": "Hollnich",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140056",
      "j": 2023,
      "n": "holzbach",
      "dn": "Holzbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140058",
      "j": 2023,
      "n": "horn",
      "dn": "Horn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140060",
      "j": 2023,
      "n": "hungenroth",
      "dn": "Hungenroth",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140062",
      "j": 2023,
      "n": "kappel",
      "dn": "Kappel",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140063",
      "j": 2023,
      "n": "karbach",
      "dn": "Karbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140064",
      "j": 2023,
      "n": "kastellaun",
      "dn": "Kastellaun",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140065",
      "j": 2023,
      "n": "keidelheim",
      "dn": "Keidelheim",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07140067",
      "j": 2023,
      "n": "kirchberg",
      "dn": "Kirchberg",
      "bl": 7,
      "hs": 495.0,
      "mns": 1
    },
    {
      "a": "07140068",
      "j": 2023,
      "n": "kisselbach",
      "dn": "Kisselbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140070",
      "j": 2023,
      "n": "klosterkumbd",
      "dn": "Klosterkumbd",
      "bl": 7,
      "hs": 200.0,
      "mns": 1
    },
    {
      "a": "07140071",
      "j": 2023,
      "n": "kludenbach",
      "dn": "Kludenbach",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07140073",
      "j": 2023,
      "n": "korweiler",
      "dn": "Korweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140075",
      "j": 2023,
      "n": "kratzenburg",
      "dn": "Kratzenburg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140076",
      "j": 2023,
      "n": "külz",
      "dn": "Külz",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140077",
      "j": 2023,
      "n": "kümbdchen",
      "dn": "Kümbdchen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140079",
      "j": 2023,
      "n": "laubach",
      "dn": "Laubach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140080",
      "j": 2023,
      "n": "laudert",
      "dn": "Laudert",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140081",
      "j": 2023,
      "n": "laufersweiler",
      "dn": "Laufersweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140082",
      "j": 2023,
      "n": "lautzenhausen",
      "dn": "Lautzenhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140084",
      "j": 2023,
      "n": "leiningen",
      "dn": "Leiningen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140085",
      "j": 2023,
      "n": "liebshausen",
      "dn": "Liebshausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140086",
      "j": 2023,
      "n": "lindenschied",
      "dn": "Lindenschied",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140087",
      "j": 2023,
      "n": "lingerhahn",
      "dn": "Lingerhahn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140089",
      "j": 2023,
      "n": "maisborn",
      "dn": "Maisborn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140090",
      "j": 2023,
      "n": "maitzborn",
      "dn": "Maitzborn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140092",
      "j": 2023,
      "n": "mengerschied",
      "dn": "Mengerschied",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140093",
      "j": 2023,
      "n": "mermuth",
      "dn": "Mermuth",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140094",
      "j": 2023,
      "n": "metzenhausen",
      "dn": "Metzenhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140095",
      "j": 2023,
      "n": "michelbach",
      "dn": "Michelbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140096",
      "j": 2023,
      "n": "mörschbach",
      "dn": "Mörschbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140098",
      "j": 2023,
      "n": "mühlpfad",
      "dn": "Mühlpfad",
      "bl": 7,
      "hs": 455.0,
      "mns": 1
    },
    {
      "a": "07140099",
      "j": 2023,
      "n": "mutterschied",
      "dn": "Mutterschied",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140100",
      "j": 2023,
      "n": "nannhausen",
      "dn": "Nannhausen",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07140101",
      "j": 2023,
      "n": "neuerkirch",
      "dn": "Neuerkirch",
      "bl": 7,
      "hs": 200.0,
      "mns": 1
    },
    {
      "a": "07140102",
      "j": 2023,
      "n": "ney",
      "dn": "Ney",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140104",
      "j": 2023,
      "n": "niederburg",
      "dn": "Niederburg",
      "bl": 7,
      "hs": 495.0,
      "mns": 1
    },
    {
      "a": "07140105",
      "j": 2023,
      "n": "nieder kostenz",
      "dn": "Nieder Kostenz",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140106",
      "j": 2023,
      "n": "niederkumbd",
      "dn": "Niederkumbd",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07140107",
      "j": 2023,
      "n": "niedersohren",
      "dn": "Niedersohren",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140108",
      "j": 2023,
      "n": "niedert",
      "dn": "Niedert",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140109",
      "j": 2023,
      "n": "niederweiler",
      "dn": "Niederweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140110",
      "j": 2023,
      "n": "norath",
      "dn": "Norath",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140111",
      "j": 2023,
      "n": "ober kostenz",
      "dn": "Ober Kostenz",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07140112",
      "j": 2023,
      "n": "oberwesel",
      "dn": "Oberwesel",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140113",
      "j": 2023,
      "n": "ohlweiler",
      "dn": "Ohlweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140115",
      "j": 2023,
      "n": "oppertshausen",
      "dn": "Oppertshausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140116",
      "j": 2023,
      "n": "perscheid",
      "dn": "Perscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140117",
      "j": 2023,
      "n": "pfalzfeld",
      "dn": "Pfalzfeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140118",
      "j": 2023,
      "n": "pleizenhausen",
      "dn": "Pleizenhausen",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07140119",
      "j": 2023,
      "n": "ravengiersburg",
      "dn": "Ravengiersburg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140120",
      "j": 2023,
      "n": "raversbeuren",
      "dn": "Raversbeuren",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140121",
      "j": 2023,
      "n": "rayerschied",
      "dn": "Rayerschied",
      "bl": 7,
      "hs": 0.0,
      "mns": 1
    },
    {
      "a": "07140122",
      "j": 2023,
      "n": "reckershausen",
      "dn": "Reckershausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140123",
      "j": 2023,
      "n": "reich",
      "dn": "Reich",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140125",
      "j": 2023,
      "n": "rheinböllen",
      "dn": "Rheinböllen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140126",
      "j": 2023,
      "n": "riegenroth",
      "dn": "Riegenroth",
      "bl": 7,
      "hs": 0.0,
      "mns": 1
    },
    {
      "a": "07140127",
      "j": 2023,
      "n": "riesweiler",
      "dn": "Riesweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140128",
      "j": 2023,
      "n": "rödelhausen",
      "dn": "Rödelhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140129",
      "j": 2023,
      "n": "rödern",
      "dn": "Rödern",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140130",
      "j": 2023,
      "n": "rohrbach",
      "dn": "Rohrbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140131",
      "j": 2023,
      "n": "roth",
      "dn": "Roth",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140133",
      "j": 2023,
      "n": "sankt goar",
      "dn": "Sankt Goar",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140134",
      "j": 2023,
      "n": "sargenroth",
      "dn": "Sargenroth",
      "bl": 7,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "07140135",
      "j": 2023,
      "n": "schlierschied",
      "dn": "Schlierschied",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140138",
      "j": 2023,
      "n": "schnorbach",
      "dn": "Schnorbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140139",
      "j": 2023,
      "n": "schönborn",
      "dn": "Schönborn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140140",
      "j": 2023,
      "n": "schwall",
      "dn": "Schwall",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140141",
      "j": 2023,
      "n": "schwarzen",
      "dn": "Schwarzen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140144",
      "j": 2023,
      "n": "simmern/hunsrück",
      "dn": "Simmern/Hunsrück",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140145",
      "j": 2023,
      "n": "sohren",
      "dn": "Sohren",
      "bl": 7,
      "hs": 485.0,
      "mns": 1
    },
    {
      "a": "07140146",
      "j": 2023,
      "n": "sohrschied",
      "dn": "Sohrschied",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140147",
      "j": 2023,
      "n": "spesenroth",
      "dn": "Spesenroth",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140148",
      "j": 2023,
      "n": "steinbach",
      "dn": "Steinbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140149",
      "j": 2023,
      "n": "thörlingen",
      "dn": "Thörlingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140150",
      "j": 2023,
      "n": "tiefenbach",
      "dn": "Tiefenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140151",
      "j": 2023,
      "n": "todenroth",
      "dn": "Todenroth",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140153",
      "j": 2023,
      "n": "uhler",
      "dn": "Uhler",
      "bl": 7,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "07140154",
      "j": 2023,
      "n": "unzenberg",
      "dn": "Unzenberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140155",
      "j": 2023,
      "n": "urbar",
      "dn": "Urbar",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07140156",
      "j": 2023,
      "n": "utzenhain",
      "dn": "Utzenhain",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140158",
      "j": 2023,
      "n": "wahlbach",
      "dn": "Wahlbach",
      "bl": 7,
      "hs": 0.0,
      "mns": 1
    },
    {
      "a": "07140159",
      "j": 2023,
      "n": "wahlenau",
      "dn": "Wahlenau",
      "bl": 7,
      "hs": 485.0,
      "mns": 1
    },
    {
      "a": "07140161",
      "j": 2023,
      "n": "wiebelsheim",
      "dn": "Wiebelsheim",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07140163",
      "j": 2023,
      "n": "womrath",
      "dn": "Womrath",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140164",
      "j": 2023,
      "n": "woppenroth",
      "dn": "Woppenroth",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140165",
      "j": 2023,
      "n": "würrich",
      "dn": "Würrich",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140166",
      "j": 2023,
      "n": "wüschheim",
      "dn": "Wüschheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140201",
      "j": 2023,
      "n": "beulich",
      "dn": "Beulich",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140202",
      "j": 2023,
      "n": "dommershausen",
      "dn": "Dommershausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140204",
      "j": 2023,
      "n": "mastershausen",
      "dn": "Mastershausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140205",
      "j": 2023,
      "n": "morshausen",
      "dn": "Morshausen",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07140501",
      "j": 2023,
      "n": "boppard",
      "dn": "Boppard",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140502",
      "j": 2023,
      "n": "lahr",
      "dn": "Lahr",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140503",
      "j": 2023,
      "n": "mörsdorf",
      "dn": "Mörsdorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07140504",
      "j": 2025,
      "n": "zilshausen",
      "dn": "Zilshausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141001",
      "j": 2023,
      "n": "allendorf",
      "dn": "Allendorf",
      "bl": 7,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "07141002",
      "j": 2023,
      "n": "altendiez",
      "dn": "Altendiez",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141003",
      "j": 2023,
      "n": "attenhausen",
      "dn": "Attenhausen",
      "bl": 7,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "07141004",
      "j": 2023,
      "n": "auel",
      "dn": "Auel",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141005",
      "j": 2023,
      "n": "aull",
      "dn": "Aull",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141006",
      "j": 2023,
      "n": "bad ems",
      "dn": "Bad Ems",
      "bl": 7,
      "hs": 523.0,
      "mns": 1
    },
    {
      "a": "07141008",
      "j": 2023,
      "n": "becheln",
      "dn": "Becheln",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141009",
      "j": 2023,
      "n": "berg",
      "dn": "Berg",
      "bl": 7,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "07141010",
      "j": 2023,
      "n": "berghausen",
      "dn": "Berghausen",
      "bl": 7,
      "hs": 388.0,
      "mns": 1
    },
    {
      "a": "07141011",
      "j": 2023,
      "n": "berndroth",
      "dn": "Berndroth",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141012",
      "j": 2023,
      "n": "bettendorf",
      "dn": "Bettendorf",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07141013",
      "j": 2023,
      "n": "biebrich",
      "dn": "Biebrich",
      "bl": 7,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "07141014",
      "j": 2023,
      "n": "birlenbach",
      "dn": "Birlenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141015",
      "j": 2023,
      "n": "bogel",
      "dn": "Bogel",
      "bl": 7,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "07141016",
      "j": 2023,
      "n": "bornich",
      "dn": "Bornich",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07141018",
      "j": 2023,
      "n": "bremberg",
      "dn": "Bremberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141019",
      "j": 2023,
      "n": "buch",
      "dn": "Buch",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141020",
      "j": 2023,
      "n": "burgschwalbach",
      "dn": "Burgschwalbach",
      "bl": 7,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "07141021",
      "j": 2023,
      "n": "charlottenberg",
      "dn": "Charlottenberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141022",
      "j": 2023,
      "n": "cramberg",
      "dn": "Cramberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141023",
      "j": 2023,
      "n": "dachsenhausen",
      "dn": "Dachsenhausen",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07141024",
      "j": 2023,
      "n": "dahlheim",
      "dn": "Dahlheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141025",
      "j": 2023,
      "n": "dausenau",
      "dn": "Dausenau",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141026",
      "j": 2023,
      "n": "dessighofen",
      "dn": "Dessighofen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141027",
      "j": 2023,
      "n": "dienethal",
      "dn": "Dienethal",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141029",
      "j": 2023,
      "n": "diez",
      "dn": "Diez",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07141030",
      "j": 2023,
      "n": "dörnberg",
      "dn": "Dörnberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141031",
      "j": 2023,
      "n": "dörscheid",
      "dn": "Dörscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141032",
      "j": 2023,
      "n": "dörsdorf",
      "dn": "Dörsdorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141033",
      "j": 2023,
      "n": "dornholzhausen",
      "dn": "Dornholzhausen",
      "bl": 7,
      "hs": 485.0,
      "mns": 1
    },
    {
      "a": "07141034",
      "j": 2023,
      "n": "ebertshausen",
      "dn": "Ebertshausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141035",
      "j": 2023,
      "n": "ehr",
      "dn": "Ehr",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141036",
      "j": 2023,
      "n": "eisighofen",
      "dn": "Eisighofen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141037",
      "j": 2023,
      "n": "endlichhofen",
      "dn": "Endlichhofen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141038",
      "j": 2023,
      "n": "eppenrod",
      "dn": "Eppenrod",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141039",
      "j": 2023,
      "n": "ergeshausen",
      "dn": "Ergeshausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141040",
      "j": 2023,
      "n": "eschbach",
      "dn": "Eschbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141041",
      "j": 2023,
      "n": "fachbach",
      "dn": "Fachbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141042",
      "j": 2023,
      "n": "filsen",
      "dn": "Filsen",
      "bl": 7,
      "hs": 495.0,
      "mns": 1
    },
    {
      "a": "07141043",
      "j": 2023,
      "n": "flacht",
      "dn": "Flacht",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141044",
      "j": 2023,
      "n": "frücht",
      "dn": "Frücht",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141045",
      "j": 2023,
      "n": "geilnau",
      "dn": "Geilnau",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141046",
      "j": 2023,
      "n": "geisig",
      "dn": "Geisig",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141047",
      "j": 2023,
      "n": "gemmerich",
      "dn": "Gemmerich",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141049",
      "j": 2023,
      "n": "gückingen",
      "dn": "Gückingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141050",
      "j": 2023,
      "n": "gutenacker",
      "dn": "Gutenacker",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141051",
      "j": 2023,
      "n": "hahnstätten",
      "dn": "Hahnstätten",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141052",
      "j": 2023,
      "n": "hambach",
      "dn": "Hambach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141053",
      "j": 2023,
      "n": "heistenbach",
      "dn": "Heistenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141054",
      "j": 2023,
      "n": "herold",
      "dn": "Herold",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141055",
      "j": 2023,
      "n": "himmighofen",
      "dn": "Himmighofen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141057",
      "j": 2023,
      "n": "hirschberg",
      "dn": "Hirschberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141058",
      "j": 2023,
      "n": "hömberg",
      "dn": "Hömberg",
      "bl": 7,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "07141059",
      "j": 2023,
      "n": "holzappel",
      "dn": "Holzappel",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141060",
      "j": 2023,
      "n": "holzhausen an der haide",
      "dn": "Holzhausen an der Haide",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07141061",
      "j": 2023,
      "n": "holzheim",
      "dn": "Holzheim",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07141062",
      "j": 2023,
      "n": "horhausen",
      "dn": "Horhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141063",
      "j": 2023,
      "n": "hunzel",
      "dn": "Hunzel",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141064",
      "j": 2023,
      "n": "isselbach",
      "dn": "Isselbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141065",
      "j": 2023,
      "n": "kaltenholzhausen",
      "dn": "Kaltenholzhausen",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07141066",
      "j": 2023,
      "n": "kamp-bornhofen",
      "dn": "Kamp-Bornhofen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141067",
      "j": 2023,
      "n": "kasdorf",
      "dn": "Kasdorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141068",
      "j": 2023,
      "n": "katzenelnbogen",
      "dn": "Katzenelnbogen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141069",
      "j": 2023,
      "n": "kaub",
      "dn": "Kaub",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141070",
      "j": 2023,
      "n": "kehlbach",
      "dn": "Kehlbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141071",
      "j": 2023,
      "n": "kemmenau",
      "dn": "Kemmenau",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141072",
      "j": 2023,
      "n": "kestert",
      "dn": "Kestert",
      "bl": 7,
      "hs": 485.0,
      "mns": 1
    },
    {
      "a": "07141073",
      "j": 2023,
      "n": "klingelbach",
      "dn": "Klingelbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141074",
      "j": 2023,
      "n": "kördorf",
      "dn": "Kördorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141075",
      "j": 2023,
      "n": "lahnstein",
      "dn": "Lahnstein",
      "bl": 7,
      "hs": 540.0,
      "mns": 2
    },
    {
      "a": "07141076",
      "j": 2023,
      "n": "langenscheid",
      "dn": "Langenscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141077",
      "j": 2023,
      "n": "laurenburg",
      "dn": "Laurenburg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141078",
      "j": 2023,
      "n": "lautert",
      "dn": "Lautert",
      "bl": 7,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "07141079",
      "j": 2023,
      "n": "lierschied",
      "dn": "Lierschied",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141080",
      "j": 2023,
      "n": "lipporn",
      "dn": "Lipporn",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07141081",
      "j": 2023,
      "n": "lohrheim",
      "dn": "Lohrheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141082",
      "j": 2023,
      "n": "lollschied",
      "dn": "Lollschied",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07141083",
      "j": 2023,
      "n": "lykershausen",
      "dn": "Lykershausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141084",
      "j": 2023,
      "n": "marienfels",
      "dn": "Marienfels",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141085",
      "j": 2023,
      "n": "miehlen",
      "dn": "Miehlen",
      "bl": 7,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "07141086",
      "j": 2023,
      "n": "miellen",
      "dn": "Miellen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07141087",
      "j": 2023,
      "n": "misselberg",
      "dn": "Misselberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141088",
      "j": 2023,
      "n": "mittelfischbach",
      "dn": "Mittelfischbach",
      "bl": 7,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "07141089",
      "j": 2023,
      "n": "mudershausen",
      "dn": "Mudershausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141091",
      "j": 2023,
      "n": "nassau",
      "dn": "Nassau",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141092",
      "j": 2023,
      "n": "nastätten",
      "dn": "Nastätten",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141093",
      "j": 2023,
      "n": "netzbach",
      "dn": "Netzbach",
      "bl": 7,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "07141094",
      "j": 2023,
      "n": "niederbachheim",
      "dn": "Niederbachheim",
      "bl": 7,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "07141095",
      "j": 2023,
      "n": "niederneisen",
      "dn": "Niederneisen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141096",
      "j": 2023,
      "n": "niedertiefenbach",
      "dn": "Niedertiefenbach",
      "bl": 7,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "07141097",
      "j": 2023,
      "n": "niederwallmenach",
      "dn": "Niederwallmenach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141098",
      "j": 2023,
      "n": "nievern",
      "dn": "Nievern",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141099",
      "j": 2023,
      "n": "nochern",
      "dn": "Nochern",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141100",
      "j": 2023,
      "n": "oberbachheim",
      "dn": "Oberbachheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141101",
      "j": 2023,
      "n": "oberfischbach",
      "dn": "Oberfischbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141102",
      "j": 2023,
      "n": "oberneisen",
      "dn": "Oberneisen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141103",
      "j": 2023,
      "n": "obernhof",
      "dn": "Obernhof",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141104",
      "j": 2023,
      "n": "obertiefenbach",
      "dn": "Obertiefenbach",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07141105",
      "j": 2023,
      "n": "oberwallmenach",
      "dn": "Oberwallmenach",
      "bl": 7,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "07141106",
      "j": 2023,
      "n": "oberwies",
      "dn": "Oberwies",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141107",
      "j": 2023,
      "n": "oelsberg",
      "dn": "Oelsberg",
      "bl": 7,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "07141108",
      "j": 2023,
      "n": "osterspai",
      "dn": "Osterspai",
      "bl": 7,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "07141109",
      "j": 2023,
      "n": "patersberg",
      "dn": "Patersberg",
      "bl": 7,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "07141110",
      "j": 2023,
      "n": "hainau",
      "dn": "Hainau",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141111",
      "j": 2023,
      "n": "pohl",
      "dn": "Pohl",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141112",
      "j": 2023,
      "n": "prath",
      "dn": "Prath",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141113",
      "j": 2023,
      "n": "reckenroth",
      "dn": "Reckenroth",
      "bl": 7,
      "hs": 407.0,
      "mns": 1
    },
    {
      "a": "07141114",
      "j": 2023,
      "n": "reichenberg",
      "dn": "Reichenberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141115",
      "j": 2023,
      "n": "reitzenhain",
      "dn": "Reitzenhain",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141116",
      "j": 2023,
      "n": "rettershain",
      "dn": "Rettershain",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141117",
      "j": 2023,
      "n": "rettert",
      "dn": "Rettert",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141118",
      "j": 2023,
      "n": "roth",
      "dn": "Roth",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07141120",
      "j": 2023,
      "n": "ruppertshofen",
      "dn": "Ruppertshofen",
      "bl": 7,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "07141121",
      "j": 2023,
      "n": "sankt goarshausen",
      "dn": "Sankt Goarshausen",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07141122",
      "j": 2023,
      "n": "sauerthal",
      "dn": "Sauerthal",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141124",
      "j": 2023,
      "n": "scheidt",
      "dn": "Scheidt",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141125",
      "j": 2023,
      "n": "schiesheim",
      "dn": "Schiesheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141126",
      "j": 2023,
      "n": "schönborn",
      "dn": "Schönborn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141127",
      "j": 2023,
      "n": "schweighausen",
      "dn": "Schweighausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141128",
      "j": 2023,
      "n": "seelbach",
      "dn": "Seelbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141129",
      "j": 2023,
      "n": "singhofen",
      "dn": "Singhofen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141130",
      "j": 2023,
      "n": "steinsberg",
      "dn": "Steinsberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141131",
      "j": 2023,
      "n": "strüth",
      "dn": "Strüth",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141132",
      "j": 2023,
      "n": "sulzbach",
      "dn": "Sulzbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141133",
      "j": 2023,
      "n": "wasenbach",
      "dn": "Wasenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141134",
      "j": 2023,
      "n": "weidenbach",
      "dn": "Weidenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141135",
      "j": 2023,
      "n": "weinähr",
      "dn": "Weinähr",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141136",
      "j": 2023,
      "n": "weisel",
      "dn": "Weisel",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141137",
      "j": 2023,
      "n": "welterod",
      "dn": "Welterod",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07141138",
      "j": 2023,
      "n": "weyer",
      "dn": "Weyer",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141139",
      "j": 2023,
      "n": "winden",
      "dn": "Winden",
      "bl": 7,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "07141140",
      "j": 2023,
      "n": "winterwerb",
      "dn": "Winterwerb",
      "bl": 7,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "07141141",
      "j": 2023,
      "n": "zimmerschied",
      "dn": "Zimmerschied",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141201",
      "j": 2023,
      "n": "arzbach",
      "dn": "Arzbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141501",
      "j": 2023,
      "n": "braubach",
      "dn": "Braubach",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07141502",
      "j": 2023,
      "n": "diethardt",
      "dn": "Diethardt",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07141503",
      "j": 2023,
      "n": "balduinstein",
      "dn": "Balduinstein",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07143001",
      "j": 2023,
      "n": "alsbach",
      "dn": "Alsbach",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07143003",
      "j": 2023,
      "n": "bannberscheid",
      "dn": "Bannberscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143005",
      "j": 2023,
      "n": "boden",
      "dn": "Boden",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143006",
      "j": 2023,
      "n": "breitenau",
      "dn": "Breitenau",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143007",
      "j": 2023,
      "n": "caan",
      "dn": "Caan",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143008",
      "j": 2023,
      "n": "daubach",
      "dn": "Daubach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143009",
      "j": 2023,
      "n": "deesen",
      "dn": "Deesen",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07143010",
      "j": 2023,
      "n": "dernbach",
      "dn": "Dernbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143011",
      "j": 2023,
      "n": "dreikirchen",
      "dn": "Dreikirchen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143012",
      "j": 2023,
      "n": "ebernhahn",
      "dn": "Ebernhahn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143013",
      "j": 2023,
      "n": "eitelborn",
      "dn": "Eitelborn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143015",
      "j": 2023,
      "n": "ellenhausen",
      "dn": "Ellenhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143018",
      "j": 2023,
      "n": "freilingen",
      "dn": "Freilingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143019",
      "j": 2023,
      "n": "freirachdorf",
      "dn": "Freirachdorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143020",
      "j": 2023,
      "n": "gackenbach",
      "dn": "Gackenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143021",
      "j": 2023,
      "n": "girod",
      "dn": "Girod",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143022",
      "j": 2023,
      "n": "goddert",
      "dn": "Goddert",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143023",
      "j": 2023,
      "n": "görgeshausen",
      "dn": "Görgeshausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143024",
      "j": 2023,
      "n": "großholbach",
      "dn": "Großholbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143025",
      "j": 2023,
      "n": "hartenfels",
      "dn": "Hartenfels",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143026",
      "j": 2023,
      "n": "heilberscheid",
      "dn": "Heilberscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143027",
      "j": 2023,
      "n": "heiligenroth",
      "dn": "Heiligenroth",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143028",
      "j": 2023,
      "n": "helferskirchen",
      "dn": "Helferskirchen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143029",
      "j": 2023,
      "n": "herschbach",
      "dn": "Herschbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143030",
      "j": 2023,
      "n": "hilgert",
      "dn": "Hilgert",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143031",
      "j": 2023,
      "n": "hillscheid",
      "dn": "Hillscheid",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07143032",
      "j": 2023,
      "n": "höhr-grenzhausen",
      "dn": "Höhr-Grenzhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143033",
      "j": 2023,
      "n": "holler",
      "dn": "Holler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143034",
      "j": 2023,
      "n": "horbach",
      "dn": "Horbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143036",
      "j": 2023,
      "n": "hübingen",
      "dn": "Hübingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143037",
      "j": 2023,
      "n": "hundsangen",
      "dn": "Hundsangen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143038",
      "j": 2023,
      "n": "hundsdorf",
      "dn": "Hundsdorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143039",
      "j": 2023,
      "n": "kadenbach",
      "dn": "Kadenbach",
      "bl": 7,
      "hs": 565.0,
      "mns": 1
    },
    {
      "a": "07143040",
      "j": 2023,
      "n": "kammerforst",
      "dn": "Kammerforst",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143041",
      "j": 2023,
      "n": "krümmel",
      "dn": "Krümmel",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143042",
      "j": 2023,
      "n": "leuterod",
      "dn": "Leuterod",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143044",
      "j": 2023,
      "n": "marienrachdorf",
      "dn": "Marienrachdorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143045",
      "j": 2023,
      "n": "maroth",
      "dn": "Maroth",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143046",
      "j": 2023,
      "n": "maxsain",
      "dn": "Maxsain",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143047",
      "j": 2023,
      "n": "mogendorf",
      "dn": "Mogendorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143048",
      "j": 2023,
      "n": "montabaur",
      "dn": "Montabaur",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07143049",
      "j": 2023,
      "n": "moschheim",
      "dn": "Moschheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143050",
      "j": 2023,
      "n": "nauort",
      "dn": "Nauort",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143051",
      "j": 2023,
      "n": "nentershausen",
      "dn": "Nentershausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143052",
      "j": 2023,
      "n": "neuhäusel",
      "dn": "Neuhäusel",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07143053",
      "j": 2023,
      "n": "niederelbert",
      "dn": "Niederelbert",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143054",
      "j": 2023,
      "n": "niedererbach",
      "dn": "Niedererbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143055",
      "j": 2023,
      "n": "nomborn",
      "dn": "Nomborn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143056",
      "j": 2023,
      "n": "nordhofen",
      "dn": "Nordhofen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143057",
      "j": 2023,
      "n": "oberelbert",
      "dn": "Oberelbert",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07143058",
      "j": 2023,
      "n": "obererbach",
      "dn": "Obererbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143059",
      "j": 2023,
      "n": "oberhaid",
      "dn": "Oberhaid",
      "bl": 7,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "07143060",
      "j": 2023,
      "n": "ötzingen",
      "dn": "Ötzingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143061",
      "j": 2023,
      "n": "quirnbach",
      "dn": "Quirnbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143062",
      "j": 2023,
      "n": "ransbach-baumbach",
      "dn": "Ransbach-Baumbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143064",
      "j": 2023,
      "n": "rückeroth",
      "dn": "Rückeroth",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143065",
      "j": 2023,
      "n": "ruppach-goldhausen",
      "dn": "Ruppach-Goldhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143066",
      "j": 2023,
      "n": "schenkelberg",
      "dn": "Schenkelberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143067",
      "j": 2023,
      "n": "selters",
      "dn": "Selters",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143068",
      "j": 2023,
      "n": "sessenbach",
      "dn": "Sessenbach",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07143069",
      "j": 2023,
      "n": "sessenhausen",
      "dn": "Sessenhausen",
      "bl": 7,
      "hs": 565.0,
      "mns": 1
    },
    {
      "a": "07143070",
      "j": 2023,
      "n": "siershahn",
      "dn": "Siershahn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143071",
      "j": 2023,
      "n": "simmern",
      "dn": "Simmern",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07143072",
      "j": 2023,
      "n": "stahlhofen",
      "dn": "Stahlhofen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143073",
      "j": 2023,
      "n": "staudt",
      "dn": "Staudt",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143074",
      "j": 2023,
      "n": "steinefrenz",
      "dn": "Steinefrenz",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143075",
      "j": 2023,
      "n": "steinen",
      "dn": "Steinen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143077",
      "j": 2023,
      "n": "untershausen",
      "dn": "Untershausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143078",
      "j": 2023,
      "n": "vielbach",
      "dn": "Vielbach",
      "bl": 7,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "07143079",
      "j": 2023,
      "n": "welschneudorf",
      "dn": "Welschneudorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143080",
      "j": 2023,
      "n": "weroth",
      "dn": "Weroth",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143081",
      "j": 2023,
      "n": "wirges",
      "dn": "Wirges",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143082",
      "j": 2023,
      "n": "wirscheid",
      "dn": "Wirscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143084",
      "j": 2023,
      "n": "wittgert",
      "dn": "Wittgert",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143085",
      "j": 2023,
      "n": "wölferlingen",
      "dn": "Wölferlingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143200",
      "j": 2023,
      "n": "ailertchen",
      "dn": "Ailertchen",
      "bl": 7,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "07143202",
      "j": 2023,
      "n": "alpenrod",
      "dn": "Alpenrod",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143203",
      "j": 2023,
      "n": "arnshöfen",
      "dn": "Arnshöfen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143204",
      "j": 2023,
      "n": "astert",
      "dn": "Astert",
      "bl": 7,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "07143205",
      "j": 2023,
      "n": "atzelgift",
      "dn": "Atzelgift",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07143206",
      "j": 2023,
      "n": "bad marienberg",
      "dn": "Bad Marienberg",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07143207",
      "j": 2023,
      "n": "bellingen",
      "dn": "Bellingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143208",
      "j": 2023,
      "n": "berod bei wallmerod",
      "dn": "Berod bei Wallmerod",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143209",
      "j": 2023,
      "n": "berzhahn",
      "dn": "Berzhahn",
      "bl": 7,
      "hs": 565.0,
      "mns": 1
    },
    {
      "a": "07143210",
      "j": 2023,
      "n": "bilkheim",
      "dn": "Bilkheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143211",
      "j": 2023,
      "n": "bölsberg",
      "dn": "Bölsberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143212",
      "j": 2023,
      "n": "borod",
      "dn": "Borod",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07143213",
      "j": 2023,
      "n": "brandscheid",
      "dn": "Brandscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143214",
      "j": 2023,
      "n": "bretthausen",
      "dn": "Bretthausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143215",
      "j": 2023,
      "n": "dreifelden",
      "dn": "Dreifelden",
      "bl": 7,
      "hs": 488.0,
      "mns": 1
    },
    {
      "a": "07143216",
      "j": 2023,
      "n": "dreisbach",
      "dn": "Dreisbach",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07143218",
      "j": 2023,
      "n": "elsoff",
      "dn": "Elsoff",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143219",
      "j": 2023,
      "n": "enspel",
      "dn": "Enspel",
      "bl": 7,
      "hs": 590.0,
      "mns": 1
    },
    {
      "a": "07143220",
      "j": 2023,
      "n": "ettinghausen",
      "dn": "Ettinghausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143221",
      "j": 2023,
      "n": "ewighausen",
      "dn": "Ewighausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143222",
      "j": 2023,
      "n": "fehl-ritzhausen",
      "dn": "Fehl-Ritzhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143223",
      "j": 2023,
      "n": "gehlert",
      "dn": "Gehlert",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143224",
      "j": 2023,
      "n": "gemünden",
      "dn": "Gemünden",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07143225",
      "j": 2023,
      "n": "giesenhausen",
      "dn": "Giesenhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143226",
      "j": 2023,
      "n": "girkenroth",
      "dn": "Girkenroth",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143227",
      "j": 2023,
      "n": "großseifen",
      "dn": "Großseifen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143228",
      "j": 2023,
      "n": "guckheim",
      "dn": "Guckheim",
      "bl": 7,
      "hs": 590.0,
      "mns": 1
    },
    {
      "a": "07143229",
      "j": 2023,
      "n": "hachenburg",
      "dn": "Hachenburg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143230",
      "j": 2023,
      "n": "härtlingen",
      "dn": "Härtlingen",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07143231",
      "j": 2023,
      "n": "hahn bei marienberg",
      "dn": "Hahn bei Marienberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143232",
      "j": 2023,
      "n": "hahn am see",
      "dn": "Hahn am See",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143233",
      "j": 2023,
      "n": "halbs",
      "dn": "Halbs",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143234",
      "j": 2023,
      "n": "hardt",
      "dn": "Hardt",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143235",
      "j": 2023,
      "n": "hattert",
      "dn": "Hattert",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143236",
      "j": 2023,
      "n": "heimborn",
      "dn": "Heimborn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143237",
      "j": 2023,
      "n": "hellenhahn-schellenberg",
      "dn": "Hellenhahn-Schellenberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143238",
      "j": 2023,
      "n": "hergenroth",
      "dn": "Hergenroth",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143239",
      "j": 2023,
      "n": "herschbach",
      "dn": "Herschbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143240",
      "j": 2023,
      "n": "heuzert",
      "dn": "Heuzert",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07143241",
      "j": 2023,
      "n": "höchstenbach",
      "dn": "Höchstenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143242",
      "j": 2023,
      "n": "höhn",
      "dn": "Höhn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143243",
      "j": 2023,
      "n": "hof",
      "dn": "Hof",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143244",
      "j": 2023,
      "n": "homberg",
      "dn": "Homberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143245",
      "j": 2023,
      "n": "hüblingen",
      "dn": "Hüblingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143246",
      "j": 2023,
      "n": "irmtraut",
      "dn": "Irmtraut",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143247",
      "j": 2023,
      "n": "kaden",
      "dn": "Kaden",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07143248",
      "j": 2023,
      "n": "kirburg",
      "dn": "Kirburg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143249",
      "j": 2023,
      "n": "kölbingen",
      "dn": "Kölbingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143250",
      "j": 2023,
      "n": "kroppach",
      "dn": "Kroppach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143251",
      "j": 2023,
      "n": "kuhnhöfen",
      "dn": "Kuhnhöfen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143252",
      "j": 2023,
      "n": "kundert",
      "dn": "Kundert",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143253",
      "j": 2023,
      "n": "langenbach bei kirburg",
      "dn": "Langenbach bei Kirburg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143254",
      "j": 2023,
      "n": "langenhahn",
      "dn": "Langenhahn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143255",
      "j": 2023,
      "n": "lautzenbrücken",
      "dn": "Lautzenbrücken",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143256",
      "j": 2023,
      "n": "liebenscheid",
      "dn": "Liebenscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143257",
      "j": 2023,
      "n": "limbach",
      "dn": "Limbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143258",
      "j": 2023,
      "n": "linden",
      "dn": "Linden",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143259",
      "j": 2023,
      "n": "lochum",
      "dn": "Lochum",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143260",
      "j": 2023,
      "n": "luckenbach",
      "dn": "Luckenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143261",
      "j": 2023,
      "n": "marzhausen",
      "dn": "Marzhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143262",
      "j": 2023,
      "n": "merkelbach",
      "dn": "Merkelbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143263",
      "j": 2025,
      "n": "meudt",
      "dn": "Meudt",
      "bl": 7,
      "hs": 565.0,
      "mns": 1
    },
    {
      "a": "07143264",
      "j": 2023,
      "n": "mörlen",
      "dn": "Mörlen",
      "bl": 7,
      "hs": 495.0,
      "mns": 1
    },
    {
      "a": "07143265",
      "j": 2023,
      "n": "mörsbach",
      "dn": "Mörsbach",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07143266",
      "j": 2023,
      "n": "molsberg",
      "dn": "Molsberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143267",
      "j": 2023,
      "n": "mudenbach",
      "dn": "Mudenbach",
      "bl": 7,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "07143268",
      "j": 2023,
      "n": "mündersbach",
      "dn": "Mündersbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143269",
      "j": 2023,
      "n": "müschenbach",
      "dn": "Müschenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143270",
      "j": 2023,
      "n": "neunkhausen",
      "dn": "Neunkhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143271",
      "j": 2023,
      "n": "neunkirchen",
      "dn": "Neunkirchen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143272",
      "j": 2023,
      "n": "neustadt/westerwald",
      "dn": "Neustadt/Westerwald",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143273",
      "j": 2023,
      "n": "niederahr",
      "dn": "Niederahr",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143274",
      "j": 2023,
      "n": "niederroßbach",
      "dn": "Niederroßbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143275",
      "j": 2023,
      "n": "niedersayn",
      "dn": "Niedersayn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143276",
      "j": 2023,
      "n": "nister",
      "dn": "Nister",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07143277",
      "j": 2023,
      "n": "nisterau",
      "dn": "Nisterau",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143278",
      "j": 2023,
      "n": "nister-möhrendorf",
      "dn": "Nister-Möhrendorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143279",
      "j": 2023,
      "n": "nistertal",
      "dn": "Nistertal",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143280",
      "j": 2023,
      "n": "norken",
      "dn": "Norken",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143281",
      "j": 2023,
      "n": "oberahr",
      "dn": "Oberahr",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143282",
      "j": 2023,
      "n": "oberrod",
      "dn": "Oberrod",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143283",
      "j": 2023,
      "n": "oberroßbach",
      "dn": "Oberroßbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143284",
      "j": 2023,
      "n": "pottum",
      "dn": "Pottum",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143285",
      "j": 2023,
      "n": "rehe",
      "dn": "Rehe",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143286",
      "j": 2023,
      "n": "rennerod",
      "dn": "Rennerod",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143287",
      "j": 2023,
      "n": "roßbach",
      "dn": "Roßbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143288",
      "j": 2023,
      "n": "rotenhain",
      "dn": "Rotenhain",
      "bl": 7,
      "hs": 590.0,
      "mns": 1
    },
    {
      "a": "07143289",
      "j": 2023,
      "n": "rothenbach",
      "dn": "Rothenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143290",
      "j": 2023,
      "n": "salz",
      "dn": "Salz",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143291",
      "j": 2023,
      "n": "salzburg",
      "dn": "Salzburg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143292",
      "j": 2023,
      "n": "seck",
      "dn": "Seck",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143293",
      "j": 2023,
      "n": "stahlhofen am wiesensee",
      "dn": "Stahlhofen am Wiesensee",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07143294",
      "j": 2023,
      "n": "steinebach an der wied",
      "dn": "Steinebach an der Wied",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143295",
      "j": 2023,
      "n": "stein-neukirch",
      "dn": "Stein-Neukirch",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143296",
      "j": 2023,
      "n": "stein-wingert",
      "dn": "Stein-Wingert",
      "bl": 7,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "07143297",
      "j": 2023,
      "n": "stockhausen-illfurth",
      "dn": "Stockhausen-Illfurth",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143298",
      "j": 2023,
      "n": "stockum-püschen",
      "dn": "Stockum-Püschen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143299",
      "j": 2023,
      "n": "streithausen",
      "dn": "Streithausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143300",
      "j": 2023,
      "n": "unnau",
      "dn": "Unnau",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143301",
      "j": 2023,
      "n": "wahlrod",
      "dn": "Wahlrod",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143302",
      "j": 2023,
      "n": "waigandshain",
      "dn": "Waigandshain",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143303",
      "j": 2023,
      "n": "waldmühlen",
      "dn": "Waldmühlen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143304",
      "j": 2023,
      "n": "wallmerod",
      "dn": "Wallmerod",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143305",
      "j": 2023,
      "n": "weidenhahn",
      "dn": "Weidenhahn",
      "bl": 7,
      "hs": 565.0,
      "mns": 1
    },
    {
      "a": "07143306",
      "j": 2023,
      "n": "welkenbach",
      "dn": "Welkenbach",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07143307",
      "j": 2023,
      "n": "weltersburg",
      "dn": "Weltersburg",
      "bl": 7,
      "hs": 485.0,
      "mns": 1
    },
    {
      "a": "07143308",
      "j": 2023,
      "n": "westerburg",
      "dn": "Westerburg",
      "bl": 7,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "07143309",
      "j": 2023,
      "n": "westernohe",
      "dn": "Westernohe",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143310",
      "j": 2023,
      "n": "wied",
      "dn": "Wied",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143311",
      "j": 2023,
      "n": "willingen",
      "dn": "Willingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143312",
      "j": 2023,
      "n": "willmenrod",
      "dn": "Willmenrod",
      "bl": 7,
      "hs": 580.0,
      "mns": 1
    },
    {
      "a": "07143313",
      "j": 2023,
      "n": "winkelbach",
      "dn": "Winkelbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143314",
      "j": 2023,
      "n": "winnen",
      "dn": "Winnen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143315",
      "j": 2023,
      "n": "zehnhausen bei rennerod",
      "dn": "Zehnhausen bei Rennerod",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143316",
      "j": 2023,
      "n": "zehnhausen bei wallmerod",
      "dn": "Zehnhausen bei Wallmerod",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143501",
      "j": 2023,
      "n": "elbingen",
      "dn": "Elbingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07143502",
      "j": 2023,
      "n": "mähren",
      "dn": "Mähren",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07211000",
      "j": 2023,
      "n": "trier",
      "dn": "Trier",
      "bl": 7,
      "hs": 550.0,
      "mns": 3
    },
    {
      "a": "07231001",
      "j": 2023,
      "n": "altrich",
      "dn": "Altrich",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231003",
      "j": 2023,
      "n": "arenrath",
      "dn": "Arenrath",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07231004",
      "j": 2023,
      "n": "bausendorf",
      "dn": "Bausendorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231005",
      "j": 2023,
      "n": "bengel",
      "dn": "Bengel",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231006",
      "j": 2023,
      "n": "berglicht",
      "dn": "Berglicht",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231007",
      "j": 2023,
      "n": "bergweiler",
      "dn": "Bergweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231008",
      "j": 2023,
      "n": "bernkastel-kues",
      "dn": "Bernkastel-Kues",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231009",
      "j": 2023,
      "n": "bettenfeld",
      "dn": "Bettenfeld",
      "bl": 7,
      "hs": 388.0,
      "mns": 1
    },
    {
      "a": "07231010",
      "j": 2023,
      "n": "binsfeld",
      "dn": "Binsfeld",
      "bl": 7,
      "hs": 429.0,
      "mns": 1
    },
    {
      "a": "07231012",
      "j": 2023,
      "n": "brauneberg",
      "dn": "Brauneberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231013",
      "j": 2023,
      "n": "bruch",
      "dn": "Bruch",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231014",
      "j": 2023,
      "n": "burg",
      "dn": "Burg",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07231016",
      "j": 2023,
      "n": "burgen",
      "dn": "Burgen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231017",
      "j": 2023,
      "n": "burtscheid",
      "dn": "Burtscheid",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07231018",
      "j": 2023,
      "n": "deuselbach",
      "dn": "Deuselbach",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07231019",
      "j": 2023,
      "n": "dhronecken",
      "dn": "Dhronecken",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07231020",
      "j": 2023,
      "n": "diefenbach",
      "dn": "Diefenbach",
      "bl": 7,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "07231021",
      "j": 2023,
      "n": "dierfeld",
      "dn": "Dierfeld",
      "bl": 7,
      "hs": 250.0,
      "mns": 1
    },
    {
      "a": "07231022",
      "j": 2023,
      "n": "dierscheid",
      "dn": "Dierscheid",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07231023",
      "j": 2023,
      "n": "dodenburg",
      "dn": "Dodenburg",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07231024",
      "j": 2023,
      "n": "dreis",
      "dn": "Dreis",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231025",
      "j": 2023,
      "n": "eckfeld",
      "dn": "Eckfeld",
      "bl": 7,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "07231026",
      "j": 2023,
      "n": "eisenschmitt",
      "dn": "Eisenschmitt",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07231029",
      "j": 2023,
      "n": "enkirch",
      "dn": "Enkirch",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07231030",
      "j": 2023,
      "n": "erden",
      "dn": "Erden",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231031",
      "j": 2023,
      "n": "esch",
      "dn": "Esch",
      "bl": 7,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "07231032",
      "j": 2023,
      "n": "etgert",
      "dn": "Etgert",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231033",
      "j": 2023,
      "n": "flußbach",
      "dn": "Flußbach",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07231035",
      "j": 2023,
      "n": "gielert",
      "dn": "Gielert",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07231036",
      "j": 2023,
      "n": "gipperath",
      "dn": "Gipperath",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07231037",
      "j": 2023,
      "n": "gladbach",
      "dn": "Gladbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231040",
      "j": 2023,
      "n": "gornhausen",
      "dn": "Gornhausen",
      "bl": 7,
      "hs": 0.0,
      "mns": 1
    },
    {
      "a": "07231041",
      "j": 2023,
      "n": "graach an der mosel",
      "dn": "Graach an der Mosel",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231042",
      "j": 2023,
      "n": "gräfendhron",
      "dn": "Gräfendhron",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231044",
      "j": 2023,
      "n": "greimerath",
      "dn": "Greimerath",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231046",
      "j": 2023,
      "n": "großlittgen",
      "dn": "Großlittgen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231049",
      "j": 2023,
      "n": "hasborn",
      "dn": "Hasborn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231050",
      "j": 2023,
      "n": "heckenmünster",
      "dn": "Heckenmünster",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07231051",
      "j": 2023,
      "n": "heidweiler",
      "dn": "Heidweiler",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07231053",
      "j": 2023,
      "n": "hetzerath",
      "dn": "Hetzerath",
      "bl": 7,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "07231054",
      "j": 2023,
      "n": "hilscheid",
      "dn": "Hilscheid",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07231056",
      "j": 2023,
      "n": "hochscheid",
      "dn": "Hochscheid",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07231057",
      "j": 2023,
      "n": "hontheim",
      "dn": "Hontheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231058",
      "j": 2023,
      "n": "horath",
      "dn": "Horath",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07231062",
      "j": 2023,
      "n": "hupperath",
      "dn": "Hupperath",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231064",
      "j": 2023,
      "n": "immert",
      "dn": "Immert",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07231065",
      "j": 2023,
      "n": "karl",
      "dn": "Karl",
      "bl": 7,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "07231066",
      "j": 2023,
      "n": "kesten",
      "dn": "Kesten",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07231067",
      "j": 2023,
      "n": "kinderbeuern",
      "dn": "Kinderbeuern",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07231068",
      "j": 2023,
      "n": "kinheim",
      "dn": "Kinheim",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07231069",
      "j": 2023,
      "n": "klausen",
      "dn": "Klausen",
      "bl": 7,
      "hs": 429.0,
      "mns": 1
    },
    {
      "a": "07231070",
      "j": 2023,
      "n": "kleinich",
      "dn": "Kleinich",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07231071",
      "j": 2023,
      "n": "kommen",
      "dn": "Kommen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231072",
      "j": 2023,
      "n": "kröv",
      "dn": "Kröv",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07231074",
      "j": 2023,
      "n": "laufeld",
      "dn": "Laufeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231075",
      "j": 2023,
      "n": "lieser",
      "dn": "Lieser",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231076",
      "j": 2023,
      "n": "lösnich",
      "dn": "Lösnich",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231077",
      "j": 2023,
      "n": "longkamp",
      "dn": "Longkamp",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231078",
      "j": 2023,
      "n": "lückenburg",
      "dn": "Lückenburg",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07231079",
      "j": 2023,
      "n": "malborn",
      "dn": "Malborn",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07231080",
      "j": 2023,
      "n": "manderscheid",
      "dn": "Manderscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231081",
      "j": 2023,
      "n": "maring-noviand",
      "dn": "Maring-Noviand",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231082",
      "j": 2023,
      "n": "meerfeld",
      "dn": "Meerfeld",
      "bl": 7,
      "hs": 388.0,
      "mns": 1
    },
    {
      "a": "07231083",
      "j": 2023,
      "n": "merschbach",
      "dn": "Merschbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231085",
      "j": 2023,
      "n": "minderlittgen",
      "dn": "Minderlittgen",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07231086",
      "j": 2023,
      "n": "minheim",
      "dn": "Minheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231087",
      "j": 2023,
      "n": "monzelfeld",
      "dn": "Monzelfeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231090",
      "j": 2023,
      "n": "mülheim an der mosel",
      "dn": "Mülheim an der Mosel",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231091",
      "j": 2023,
      "n": "musweiler",
      "dn": "Musweiler",
      "bl": 7,
      "hs": 399.0,
      "mns": 1
    },
    {
      "a": "07231092",
      "j": 2023,
      "n": "neumagen-dhron",
      "dn": "Neumagen-Dhron",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231093",
      "j": 2023,
      "n": "neunkirchen",
      "dn": "Neunkirchen",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07231095",
      "j": 2023,
      "n": "niederöfflingen",
      "dn": "Niederöfflingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231096",
      "j": 2023,
      "n": "niederscheidweiler",
      "dn": "Niederscheidweiler",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07231100",
      "j": 2023,
      "n": "oberöfflingen",
      "dn": "Oberöfflingen",
      "bl": 7,
      "hs": 399.0,
      "mns": 1
    },
    {
      "a": "07231101",
      "j": 2023,
      "n": "oberscheidweiler",
      "dn": "Oberscheidweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231103",
      "j": 2023,
      "n": "osann-monzel",
      "dn": "Osann-Monzel",
      "bl": 7,
      "hs": 250.0,
      "mns": 1
    },
    {
      "a": "07231104",
      "j": 2023,
      "n": "pantenburg",
      "dn": "Pantenburg",
      "bl": 7,
      "hs": 388.0,
      "mns": 1
    },
    {
      "a": "07231105",
      "j": 2023,
      "n": "piesport",
      "dn": "Piesport",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231107",
      "j": 2023,
      "n": "platten",
      "dn": "Platten",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231108",
      "j": 2023,
      "n": "plein",
      "dn": "Plein",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231110",
      "j": 2023,
      "n": "reil",
      "dn": "Reil",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07231111",
      "j": 2023,
      "n": "rivenich",
      "dn": "Rivenich",
      "bl": 7,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "07231112",
      "j": 2023,
      "n": "rorodt",
      "dn": "Rorodt",
      "bl": 7,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "07231113",
      "j": 2023,
      "n": "salmtal",
      "dn": "Salmtal",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231114",
      "j": 2023,
      "n": "schladt",
      "dn": "Schladt",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231115",
      "j": 2023,
      "n": "schönberg",
      "dn": "Schönberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231116",
      "j": 2023,
      "n": "schwarzenborn",
      "dn": "Schwarzenborn",
      "bl": 7,
      "hs": 429.0,
      "mns": 1
    },
    {
      "a": "07231117",
      "j": 2023,
      "n": "sehlem",
      "dn": "Sehlem",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231120",
      "j": 2023,
      "n": "starkenburg",
      "dn": "Starkenburg",
      "bl": 7,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "07231122",
      "j": 2023,
      "n": "talling",
      "dn": "Talling",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07231123",
      "j": 2023,
      "n": "thalfang",
      "dn": "Thalfang",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07231124",
      "j": 2023,
      "n": "traben-trarbach",
      "dn": "Traben-Trarbach",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07231125",
      "j": 2023,
      "n": "ürzig",
      "dn": "Ürzig",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231126",
      "j": 2023,
      "n": "veldenz",
      "dn": "Veldenz",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231127",
      "j": 2023,
      "n": "wallscheid",
      "dn": "Wallscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231132",
      "j": 2023,
      "n": "willwerscheid",
      "dn": "Willwerscheid",
      "bl": 7,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "07231133",
      "j": 2023,
      "n": "wintrich",
      "dn": "Wintrich",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231134",
      "j": 2025,
      "n": "wittlich",
      "dn": "Wittlich",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07231136",
      "j": 2023,
      "n": "zeltingen-rachtig",
      "dn": "Zeltingen-Rachtig",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231202",
      "j": 2023,
      "n": "breit",
      "dn": "Breit",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231203",
      "j": 2023,
      "n": "büdlich",
      "dn": "Büdlich",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231204",
      "j": 2023,
      "n": "heidenburg",
      "dn": "Heidenburg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231206",
      "j": 2023,
      "n": "lötzbeuren",
      "dn": "Lötzbeuren",
      "bl": 7,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "07231501",
      "j": 2023,
      "n": "irmenach",
      "dn": "Irmenach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231502",
      "j": 2023,
      "n": "morbach",
      "dn": "Morbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231503",
      "j": 2023,
      "n": "landscheid",
      "dn": "Landscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07231504",
      "j": 2023,
      "n": "niersbach",
      "dn": "Niersbach",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232001",
      "j": 2023,
      "n": "affler",
      "dn": "Affler",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232002",
      "j": 2023,
      "n": "alsdorf",
      "dn": "Alsdorf",
      "bl": 7,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "07232003",
      "j": 2023,
      "n": "altscheid",
      "dn": "Altscheid",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232004",
      "j": 2023,
      "n": "ammeldingen an der our",
      "dn": "Ammeldingen an der Our",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232005",
      "j": 2023,
      "n": "ammeldingen bei neuerburg",
      "dn": "Ammeldingen bei Neuerburg",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232006",
      "j": 2023,
      "n": "auw an der kyll",
      "dn": "Auw an der Kyll",
      "bl": 7,
      "hs": 518.0,
      "mns": 1
    },
    {
      "a": "07232007",
      "j": 2023,
      "n": "badem",
      "dn": "Badem",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232008",
      "j": 2023,
      "n": "bauler",
      "dn": "Bauler",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232009",
      "j": 2023,
      "n": "baustert",
      "dn": "Baustert",
      "bl": 7,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "07232010",
      "j": 2023,
      "n": "beilingen",
      "dn": "Beilingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07232011",
      "j": 2023,
      "n": "berkoth",
      "dn": "Berkoth",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232012",
      "j": 2023,
      "n": "berscheid",
      "dn": "Berscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07232013",
      "j": 2023,
      "n": "bettingen",
      "dn": "Bettingen",
      "bl": 7,
      "hs": 600.0,
      "mns": 1
    },
    {
      "a": "07232014",
      "j": 2023,
      "n": "bickendorf",
      "dn": "Bickendorf",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232015",
      "j": 2023,
      "n": "biersdorf am see",
      "dn": "Biersdorf am See",
      "bl": 7,
      "hs": 600.0,
      "mns": 1
    },
    {
      "a": "07232016",
      "j": 2023,
      "n": "biesdorf",
      "dn": "Biesdorf",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232017",
      "j": 2023,
      "n": "birtlingen",
      "dn": "Birtlingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07232018",
      "j": 2023,
      "n": "bitburg",
      "dn": "Bitburg",
      "bl": 7,
      "hs": 500.0,
      "mns": 2
    },
    {
      "a": "07232019",
      "j": 2023,
      "n": "bollendorf",
      "dn": "Bollendorf",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232020",
      "j": 2023,
      "n": "brecht",
      "dn": "Brecht",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07232022",
      "j": 2023,
      "n": "burg",
      "dn": "Burg",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232024",
      "j": 2023,
      "n": "dahlem",
      "dn": "Dahlem",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07232025",
      "j": 2023,
      "n": "dauwelshausen",
      "dn": "Dauwelshausen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232026",
      "j": 2023,
      "n": "dockendorf",
      "dn": "Dockendorf",
      "bl": 7,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "07232027",
      "j": 2023,
      "n": "dudeldorf",
      "dn": "Dudeldorf",
      "bl": 7,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "07232028",
      "j": 2023,
      "n": "echternacherbrück",
      "dn": "Echternacherbrück",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232029",
      "j": 2023,
      "n": "echtershausen",
      "dn": "Echtershausen",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07232030",
      "j": 2023,
      "n": "ehlenz",
      "dn": "Ehlenz",
      "bl": 7,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "07232031",
      "j": 2023,
      "n": "emmelbaum",
      "dn": "Emmelbaum",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232032",
      "j": 2023,
      "n": "enzen",
      "dn": "Enzen",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07232033",
      "j": 2023,
      "n": "ernzen",
      "dn": "Ernzen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232034",
      "j": 2023,
      "n": "eßlingen",
      "dn": "Eßlingen",
      "bl": 7,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "07232035",
      "j": 2023,
      "n": "etteldorf",
      "dn": "Etteldorf",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232036",
      "j": 2023,
      "n": "feilsdorf",
      "dn": "Feilsdorf",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232037",
      "j": 2023,
      "n": "ferschweiler",
      "dn": "Ferschweiler",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232038",
      "j": 2023,
      "n": "fischbach-oberraden",
      "dn": "Fischbach-Oberraden",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232039",
      "j": 2023,
      "n": "fließem",
      "dn": "Fließem",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232040",
      "j": 2023,
      "n": "geichlingen",
      "dn": "Geichlingen",
      "bl": 7,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "07232041",
      "j": 2023,
      "n": "gemünd",
      "dn": "Gemünd",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232042",
      "j": 2023,
      "n": "gentingen",
      "dn": "Gentingen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232043",
      "j": 2023,
      "n": "gindorf",
      "dn": "Gindorf",
      "bl": 7,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "07232044",
      "j": 2023,
      "n": "gondorf",
      "dn": "Gondorf",
      "bl": 7,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "07232045",
      "j": 2023,
      "n": "halsdorf",
      "dn": "Halsdorf",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232046",
      "j": 2023,
      "n": "hamm",
      "dn": "Hamm",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07232047",
      "j": 2023,
      "n": "heilbach",
      "dn": "Heilbach",
      "bl": 7,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "07232048",
      "j": 2023,
      "n": "heilenbach",
      "dn": "Heilenbach",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232049",
      "j": 2023,
      "n": "herbstmühle",
      "dn": "Herbstmühle",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232050",
      "j": 2023,
      "n": "herforst",
      "dn": "Herforst",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07232053",
      "j": 2023,
      "n": "holsthum",
      "dn": "Holsthum",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232054",
      "j": 2023,
      "n": "hommerdingen",
      "dn": "Hommerdingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07232055",
      "j": 2023,
      "n": "hosten",
      "dn": "Hosten",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232056",
      "j": 2023,
      "n": "hütten",
      "dn": "Hütten",
      "bl": 7,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "07232057",
      "j": 2023,
      "n": "hütterscheid",
      "dn": "Hütterscheid",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232058",
      "j": 2023,
      "n": "hüttingen an der kyll",
      "dn": "Hüttingen an der Kyll",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07232059",
      "j": 2023,
      "n": "hüttingen bei lahr",
      "dn": "Hüttingen bei Lahr",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07232060",
      "j": 2023,
      "n": "idenheim",
      "dn": "Idenheim",
      "bl": 7,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "07232061",
      "j": 2023,
      "n": "idesheim",
      "dn": "Idesheim",
      "bl": 7,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "07232062",
      "j": 2023,
      "n": "ingendorf",
      "dn": "Ingendorf",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07232063",
      "j": 2023,
      "n": "irrel",
      "dn": "Irrel",
      "bl": 7,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "07232064",
      "j": 2023,
      "n": "karlshausen",
      "dn": "Karlshausen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232065",
      "j": 2023,
      "n": "kaschenbach",
      "dn": "Kaschenbach",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232066",
      "j": 2023,
      "n": "keppeshausen",
      "dn": "Keppeshausen",
      "bl": 7,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "07232067",
      "j": 2023,
      "n": "körperich",
      "dn": "Körperich",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232068",
      "j": 2023,
      "n": "koxhausen",
      "dn": "Koxhausen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232069",
      "j": 2023,
      "n": "kruchten",
      "dn": "Kruchten",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232070",
      "j": 2023,
      "n": "kyllburg",
      "dn": "Kyllburg",
      "bl": 7,
      "hs": 595.0,
      "mns": 1
    },
    {
      "a": "07232071",
      "j": 2023,
      "n": "kyllburgweiler",
      "dn": "Kyllburgweiler",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232072",
      "j": 2023,
      "n": "lahr",
      "dn": "Lahr",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232073",
      "j": 2023,
      "n": "leimbach",
      "dn": "Leimbach",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07232074",
      "j": 2023,
      "n": "ließem",
      "dn": "Ließem",
      "bl": 7,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "07232075",
      "j": 2023,
      "n": "malberg",
      "dn": "Malberg",
      "bl": 7,
      "hs": 660.0,
      "mns": 1
    },
    {
      "a": "07232076",
      "j": 2023,
      "n": "malbergweich",
      "dn": "Malbergweich",
      "bl": 7,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "07232077",
      "j": 2023,
      "n": "meckel",
      "dn": "Meckel",
      "bl": 7,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "07232078",
      "j": 2023,
      "n": "menningen",
      "dn": "Menningen",
      "bl": 7,
      "hs": 525.0,
      "mns": 1
    },
    {
      "a": "07232079",
      "j": 2023,
      "n": "messerich",
      "dn": "Messerich",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07232080",
      "j": 2023,
      "n": "mettendorf",
      "dn": "Mettendorf",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232081",
      "j": 2023,
      "n": "metterich",
      "dn": "Metterich",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07232082",
      "j": 2023,
      "n": "minden",
      "dn": "Minden",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232083",
      "j": 2023,
      "n": "mülbach",
      "dn": "Mülbach",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232084",
      "j": 2023,
      "n": "muxerath",
      "dn": "Muxerath",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07232085",
      "j": 2023,
      "n": "nasingen",
      "dn": "Nasingen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232086",
      "j": 2023,
      "n": "nattenheim",
      "dn": "Nattenheim",
      "bl": 7,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "07232087",
      "j": 2023,
      "n": "neidenbach",
      "dn": "Neidenbach",
      "bl": 7,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "07232088",
      "j": 2023,
      "n": "neuerburg",
      "dn": "Neuerburg",
      "bl": 7,
      "hs": 690.0,
      "mns": 1
    },
    {
      "a": "07232089",
      "j": 2023,
      "n": "niedergeckler",
      "dn": "Niedergeckler",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232090",
      "j": 2023,
      "n": "niederraden",
      "dn": "Niederraden",
      "bl": 7,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "07232091",
      "j": 2023,
      "n": "niederstedem",
      "dn": "Niederstedem",
      "bl": 7,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "07232092",
      "j": 2023,
      "n": "niederweiler",
      "dn": "Niederweiler",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07232093",
      "j": 2023,
      "n": "niederweis",
      "dn": "Niederweis",
      "bl": 7,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "07232094",
      "j": 2023,
      "n": "niehl",
      "dn": "Niehl",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07232095",
      "j": 2023,
      "n": "nusbaum",
      "dn": "Nusbaum",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232096",
      "j": 2023,
      "n": "obergeckler",
      "dn": "Obergeckler",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232097",
      "j": 2023,
      "n": "oberstedem",
      "dn": "Oberstedem",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07232098",
      "j": 2023,
      "n": "oberweiler",
      "dn": "Oberweiler",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232099",
      "j": 2023,
      "n": "oberweis",
      "dn": "Oberweis",
      "bl": 7,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "07232100",
      "j": 2023,
      "n": "olsdorf",
      "dn": "Olsdorf",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07232101",
      "j": 2023,
      "n": "orsfeld",
      "dn": "Orsfeld",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232102",
      "j": 2023,
      "n": "utscheid",
      "dn": "Utscheid",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232103",
      "j": 2023,
      "n": "peffingen",
      "dn": "Peffingen",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07232104",
      "j": 2023,
      "n": "philippsheim",
      "dn": "Philippsheim",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232105",
      "j": 2023,
      "n": "pickließem",
      "dn": "Pickließem",
      "bl": 7,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "07232106",
      "j": 2023,
      "n": "plascheid",
      "dn": "Plascheid",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232107",
      "j": 2023,
      "n": "preist",
      "dn": "Preist",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07232108",
      "j": 2023,
      "n": "prümzurlay",
      "dn": "Prümzurlay",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232109",
      "j": 2023,
      "n": "rittersdorf",
      "dn": "Rittersdorf",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07232110",
      "j": 2023,
      "n": "rodershausen",
      "dn": "Rodershausen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232111",
      "j": 2023,
      "n": "röhl",
      "dn": "Röhl",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232112",
      "j": 2023,
      "n": "roth an der our",
      "dn": "Roth an der Our",
      "bl": 7,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "07232113",
      "j": 2023,
      "n": "sankt thomas",
      "dn": "Sankt Thomas",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232114",
      "j": 2023,
      "n": "schankweiler",
      "dn": "Schankweiler",
      "bl": 7,
      "hs": 580.0,
      "mns": 1
    },
    {
      "a": "07232115",
      "j": 2023,
      "n": "scharfbillig",
      "dn": "Scharfbillig",
      "bl": 7,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "07232116",
      "j": 2023,
      "n": "scheitenkorb",
      "dn": "Scheitenkorb",
      "bl": 7,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "07232117",
      "j": 2023,
      "n": "scheuern",
      "dn": "Scheuern",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232118",
      "j": 2023,
      "n": "schleid",
      "dn": "Schleid",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232119",
      "j": 2023,
      "n": "seffern",
      "dn": "Seffern",
      "bl": 7,
      "hs": 515.0,
      "mns": 1
    },
    {
      "a": "07232120",
      "j": 2023,
      "n": "sefferweich",
      "dn": "Sefferweich",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07232121",
      "j": 2023,
      "n": "sevenig bei neuerburg",
      "dn": "Sevenig bei Neuerburg",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232122",
      "j": 2023,
      "n": "sinspelt",
      "dn": "Sinspelt",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232123",
      "j": 2023,
      "n": "speicher",
      "dn": "Speicher",
      "bl": 7,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "07232124",
      "j": 2023,
      "n": "stockem",
      "dn": "Stockem",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232125",
      "j": 2023,
      "n": "sülm",
      "dn": "Sülm",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07232126",
      "j": 2023,
      "n": "trimport",
      "dn": "Trimport",
      "bl": 7,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "07232127",
      "j": 2023,
      "n": "übereisenbach",
      "dn": "Übereisenbach",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232128",
      "j": 2023,
      "n": "uppershausen",
      "dn": "Uppershausen",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232129",
      "j": 2023,
      "n": "usch",
      "dn": "Usch",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07232130",
      "j": 2023,
      "n": "waldhof-falkenstein",
      "dn": "Waldhof-Falkenstein",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232131",
      "j": 2023,
      "n": "wallendorf",
      "dn": "Wallendorf",
      "bl": 7,
      "hs": 570.0,
      "mns": 1
    },
    {
      "a": "07232132",
      "j": 2023,
      "n": "weidingen",
      "dn": "Weidingen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232133",
      "j": 2023,
      "n": "wettlingen",
      "dn": "Wettlingen",
      "bl": 7,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "07232134",
      "j": 2023,
      "n": "wiersdorf",
      "dn": "Wiersdorf",
      "bl": 7,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "07232135",
      "j": 2023,
      "n": "wilsecker",
      "dn": "Wilsecker",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07232137",
      "j": 2023,
      "n": "wolsfeld",
      "dn": "Wolsfeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07232138",
      "j": 2023,
      "n": "zweifelscheid",
      "dn": "Zweifelscheid",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232201",
      "j": 2023,
      "n": "arzfeld",
      "dn": "Arzfeld",
      "bl": 7,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "07232202",
      "j": 2023,
      "n": "auw bei prüm",
      "dn": "Auw bei Prüm",
      "bl": 7,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "07232203",
      "j": 2023,
      "n": "balesfeld",
      "dn": "Balesfeld",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232206",
      "j": 2023,
      "n": "bleialf",
      "dn": "Bleialf",
      "bl": 7,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "07232207",
      "j": 2023,
      "n": "brandscheid",
      "dn": "Brandscheid",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232208",
      "j": 2023,
      "n": "buchet",
      "dn": "Buchet",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232209",
      "j": 2023,
      "n": "büdesheim",
      "dn": "Büdesheim",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07232210",
      "j": 2023,
      "n": "burbach",
      "dn": "Burbach",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232211",
      "j": 2023,
      "n": "dackscheid",
      "dn": "Dackscheid",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232212",
      "j": 2023,
      "n": "dahnen",
      "dn": "Dahnen",
      "bl": 7,
      "hs": 530.0,
      "mns": 1
    },
    {
      "a": "07232213",
      "j": 2023,
      "n": "daleiden",
      "dn": "Daleiden",
      "bl": 7,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "07232214",
      "j": 2023,
      "n": "dasburg",
      "dn": "Dasburg",
      "bl": 7,
      "hs": 530.0,
      "mns": 1
    },
    {
      "a": "07232216",
      "j": 2023,
      "n": "dingdorf",
      "dn": "Dingdorf",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232217",
      "j": 2023,
      "n": "eilscheid",
      "dn": "Eilscheid",
      "bl": 7,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "07232218",
      "j": 2023,
      "n": "eisenach",
      "dn": "Eisenach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07232220",
      "j": 2023,
      "n": "eschfeld",
      "dn": "Eschfeld",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232221",
      "j": 2023,
      "n": "euscheid",
      "dn": "Euscheid",
      "bl": 7,
      "hs": 650.0,
      "mns": 1
    },
    {
      "a": "07232222",
      "j": 2023,
      "n": "feuerscheid",
      "dn": "Feuerscheid",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232223",
      "j": 2023,
      "n": "fleringen",
      "dn": "Fleringen",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232224",
      "j": 2023,
      "n": "giesdorf",
      "dn": "Giesdorf",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232225",
      "j": 2023,
      "n": "gilzem",
      "dn": "Gilzem",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232226",
      "j": 2023,
      "n": "weinsheim",
      "dn": "Weinsheim",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232227",
      "j": 2023,
      "n": "gondenbrett",
      "dn": "Gondenbrett",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07232228",
      "j": 2023,
      "n": "gransdorf",
      "dn": "Gransdorf",
      "bl": 7,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "07232229",
      "j": 2023,
      "n": "großkampenberg",
      "dn": "Großkampenberg",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232230",
      "j": 2023,
      "n": "großlangenfeld",
      "dn": "Großlangenfeld",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232231",
      "j": 2023,
      "n": "habscheid",
      "dn": "Habscheid",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232233",
      "j": 2023,
      "n": "hargarten",
      "dn": "Hargarten",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232234",
      "j": 2023,
      "n": "harspelt",
      "dn": "Harspelt",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232236",
      "j": 2023,
      "n": "heckhuscheid",
      "dn": "Heckhuscheid",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232238",
      "j": 2023,
      "n": "heisdorf",
      "dn": "Heisdorf",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232240",
      "j": 2023,
      "n": "herzfeld",
      "dn": "Herzfeld",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232245",
      "j": 2023,
      "n": "irrhausen",
      "dn": "Irrhausen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232246",
      "j": 2023,
      "n": "jucken",
      "dn": "Jucken",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232247",
      "j": 2023,
      "n": "kesfeld",
      "dn": "Kesfeld",
      "bl": 7,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "07232248",
      "j": 2023,
      "n": "kickeshausen",
      "dn": "Kickeshausen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232249",
      "j": 2023,
      "n": "kinzenburg",
      "dn": "Kinzenburg",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232250",
      "j": 2023,
      "n": "kleinlangenfeld",
      "dn": "Kleinlangenfeld",
      "bl": 7,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "07232253",
      "j": 2023,
      "n": "krautscheid",
      "dn": "Krautscheid",
      "bl": 7,
      "hs": 600.0,
      "mns": 1
    },
    {
      "a": "07232254",
      "j": 2023,
      "n": "lambertsberg",
      "dn": "Lambertsberg",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232255",
      "j": 2023,
      "n": "lascheid",
      "dn": "Lascheid",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07232256",
      "j": 2023,
      "n": "lasel",
      "dn": "Lasel",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232258",
      "j": 2023,
      "n": "lauperath",
      "dn": "Lauperath",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232259",
      "j": 2023,
      "n": "leidenborn",
      "dn": "Leidenborn",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232260",
      "j": 2023,
      "n": "lichtenborn",
      "dn": "Lichtenborn",
      "bl": 7,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "07232261",
      "j": 2023,
      "n": "lierfeld",
      "dn": "Lierfeld",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232262",
      "j": 2023,
      "n": "lünebach",
      "dn": "Lünebach",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232263",
      "j": 2023,
      "n": "lützkampen",
      "dn": "Lützkampen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232264",
      "j": 2023,
      "n": "manderscheid",
      "dn": "Manderscheid",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232265",
      "j": 2023,
      "n": "masthorn",
      "dn": "Masthorn",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232266",
      "j": 2023,
      "n": "matzerath",
      "dn": "Matzerath",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232267",
      "j": 2023,
      "n": "mauel",
      "dn": "Mauel",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232270",
      "j": 2023,
      "n": "merlscheid",
      "dn": "Merlscheid",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232271",
      "j": 2023,
      "n": "mützenich",
      "dn": "Mützenich",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232272",
      "j": 2023,
      "n": "neuendorf",
      "dn": "Neuendorf",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232273",
      "j": 2023,
      "n": "neuheilenbach",
      "dn": "Neuheilenbach",
      "bl": 7,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "07232276",
      "j": 2023,
      "n": "niederlauch",
      "dn": "Niederlauch",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07232277",
      "j": 2023,
      "n": "niederpierscheid",
      "dn": "Niederpierscheid",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232279",
      "j": 2023,
      "n": "nimshuscheid",
      "dn": "Nimshuscheid",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07232280",
      "j": 2023,
      "n": "nimsreuland",
      "dn": "Nimsreuland",
      "bl": 7,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "07232282",
      "j": 2023,
      "n": "oberkail",
      "dn": "Oberkail",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232283",
      "j": 2023,
      "n": "oberlascheid",
      "dn": "Oberlascheid",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232284",
      "j": 2023,
      "n": "oberlauch",
      "dn": "Oberlauch",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232285",
      "j": 2023,
      "n": "oberpierscheid",
      "dn": "Oberpierscheid",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232287",
      "j": 2023,
      "n": "olmscheid",
      "dn": "Olmscheid",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232288",
      "j": 2023,
      "n": "olzheim",
      "dn": "Olzheim",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232289",
      "j": 2023,
      "n": "orenhofen",
      "dn": "Orenhofen",
      "bl": 7,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "07232290",
      "j": 2023,
      "n": "orlenbach",
      "dn": "Orlenbach",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07232291",
      "j": 2023,
      "n": "pintesfeld",
      "dn": "Pintesfeld",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232292",
      "j": 2023,
      "n": "pittenbach",
      "dn": "Pittenbach",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07232293",
      "j": 2023,
      "n": "plütscheid",
      "dn": "Plütscheid",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232294",
      "j": 2023,
      "n": "preischeid",
      "dn": "Preischeid",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232295",
      "j": 2023,
      "n": "pronsfeld",
      "dn": "Pronsfeld",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07232296",
      "j": 2023,
      "n": "prüm",
      "dn": "Prüm",
      "bl": 7,
      "hs": 435.0,
      "mns": 1
    },
    {
      "a": "07232297",
      "j": 2023,
      "n": "reiff",
      "dn": "Reiff",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232298",
      "j": 2023,
      "n": "reipeldingen",
      "dn": "Reipeldingen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232300",
      "j": 2023,
      "n": "rommersheim",
      "dn": "Rommersheim",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232301",
      "j": 2023,
      "n": "roscheid",
      "dn": "Roscheid",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232302",
      "j": 2023,
      "n": "roth bei prüm",
      "dn": "Roth bei Prüm",
      "bl": 7,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "07232304",
      "j": 2023,
      "n": "schönecken",
      "dn": "Schönecken",
      "bl": 7,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "07232305",
      "j": 2023,
      "n": "schwirzheim",
      "dn": "Schwirzheim",
      "bl": 7,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "07232306",
      "j": 2023,
      "n": "seinsfeld",
      "dn": "Seinsfeld",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07232307",
      "j": 2023,
      "n": "seiwerath",
      "dn": "Seiwerath",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232308",
      "j": 2023,
      "n": "sellerich",
      "dn": "Sellerich",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232309",
      "j": 2023,
      "n": "sengerich",
      "dn": "Sengerich",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232310",
      "j": 2023,
      "n": "sevenig",
      "dn": "Sevenig",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232311",
      "j": 2023,
      "n": "spangdahlem",
      "dn": "Spangdahlem",
      "bl": 7,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "07232313",
      "j": 2023,
      "n": "steinborn",
      "dn": "Steinborn",
      "bl": 7,
      "hs": 290.0,
      "mns": 1
    },
    {
      "a": "07232315",
      "j": 2023,
      "n": "strickscheid",
      "dn": "Strickscheid",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232318",
      "j": 2023,
      "n": "wallersheim",
      "dn": "Wallersheim",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232320",
      "j": 2023,
      "n": "watzerath",
      "dn": "Watzerath",
      "bl": 7,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "07232321",
      "j": 2023,
      "n": "wawern",
      "dn": "Wawern",
      "bl": 7,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "07232322",
      "j": 2023,
      "n": "waxweiler",
      "dn": "Waxweiler",
      "bl": 7,
      "hs": 690.0,
      "mns": 1
    },
    {
      "a": "07232327",
      "j": 2023,
      "n": "winringen",
      "dn": "Winringen",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232328",
      "j": 2023,
      "n": "winterscheid",
      "dn": "Winterscheid",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07232329",
      "j": 2023,
      "n": "winterspelt",
      "dn": "Winterspelt",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232331",
      "j": 2023,
      "n": "zendscheid",
      "dn": "Zendscheid",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232332",
      "j": 2023,
      "n": "hersdorf",
      "dn": "Hersdorf",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07232333",
      "j": 2023,
      "n": "üttfeld",
      "dn": "Üttfeld",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07232501",
      "j": 2023,
      "n": "wißmannsdorf",
      "dn": "Wißmannsdorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07232502",
      "j": 2023,
      "n": "brimingen",
      "dn": "Brimingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233002",
      "j": 2023,
      "n": "basberg",
      "dn": "Basberg",
      "bl": 7,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "07233003",
      "j": 2023,
      "n": "beinhausen",
      "dn": "Beinhausen",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233004",
      "j": 2023,
      "n": "berlingen",
      "dn": "Berlingen",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07233005",
      "j": 2023,
      "n": "berndorf",
      "dn": "Berndorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233006",
      "j": 2023,
      "n": "betteldorf",
      "dn": "Betteldorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233007",
      "j": 2023,
      "n": "birgel",
      "dn": "Birgel",
      "bl": 7,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "07233008",
      "j": 2023,
      "n": "bleckhausen",
      "dn": "Bleckhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233010",
      "j": 2023,
      "n": "boxberg",
      "dn": "Boxberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233011",
      "j": 2023,
      "n": "brockscheid",
      "dn": "Brockscheid",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07233014",
      "j": 2023,
      "n": "darscheid",
      "dn": "Darscheid",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07233016",
      "j": 2023,
      "n": "demerath",
      "dn": "Demerath",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233017",
      "j": 2023,
      "n": "deudesfeld",
      "dn": "Deudesfeld",
      "bl": 7,
      "hs": 600.0,
      "mns": 1
    },
    {
      "a": "07233018",
      "j": 2023,
      "n": "dockweiler",
      "dn": "Dockweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233019",
      "j": 2023,
      "n": "dohm-lammersdorf",
      "dn": "Dohm-Lammersdorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233020",
      "j": 2023,
      "n": "dreis-brück",
      "dn": "Dreis-Brück",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233021",
      "j": 2023,
      "n": "ellscheid",
      "dn": "Ellscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233022",
      "j": 2023,
      "n": "esch",
      "dn": "Esch",
      "bl": 7,
      "hs": 595.0,
      "mns": 1
    },
    {
      "a": "07233023",
      "j": 2025,
      "n": "feusdorf",
      "dn": "Feusdorf",
      "bl": 7,
      "hs": 525.0,
      "mns": 1
    },
    {
      "a": "07233025",
      "j": 2023,
      "n": "gefell",
      "dn": "Gefell",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233026",
      "j": 2023,
      "n": "gerolstein",
      "dn": "Gerolstein",
      "bl": 7,
      "hs": 525.0,
      "mns": 1
    },
    {
      "a": "07233027",
      "j": 2023,
      "n": "gillenfeld",
      "dn": "Gillenfeld",
      "bl": 7,
      "hs": 590.0,
      "mns": 1
    },
    {
      "a": "07233028",
      "j": 2023,
      "n": "gönnersdorf",
      "dn": "Gönnersdorf",
      "bl": 7,
      "hs": 700.0,
      "mns": 1
    },
    {
      "a": "07233029",
      "j": 2023,
      "n": "hillesheim",
      "dn": "Hillesheim",
      "bl": 7,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "07233030",
      "j": 2023,
      "n": "hinterweiler",
      "dn": "Hinterweiler",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233031",
      "j": 2023,
      "n": "hörscheid",
      "dn": "Hörscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233032",
      "j": 2023,
      "n": "hörschhausen",
      "dn": "Hörschhausen",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233033",
      "j": 2023,
      "n": "hohenfels-essingen",
      "dn": "Hohenfels-Essingen",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07233034",
      "j": 2023,
      "n": "immerath",
      "dn": "Immerath",
      "bl": 7,
      "hs": 590.0,
      "mns": 1
    },
    {
      "a": "07233035",
      "j": 2023,
      "n": "jünkerath",
      "dn": "Jünkerath",
      "bl": 7,
      "hs": 700.0,
      "mns": 1
    },
    {
      "a": "07233036",
      "j": 2023,
      "n": "kalenborn-scheuern",
      "dn": "Kalenborn-Scheuern",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07233037",
      "j": 2023,
      "n": "katzwinkel",
      "dn": "Katzwinkel",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233038",
      "j": 2023,
      "n": "kerpen",
      "dn": "Kerpen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07233039",
      "j": 2023,
      "n": "kirchweiler",
      "dn": "Kirchweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233040",
      "j": 2023,
      "n": "kradenbach",
      "dn": "Kradenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233041",
      "j": 2023,
      "n": "lissendorf",
      "dn": "Lissendorf",
      "bl": 7,
      "hs": 750.0,
      "mns": 1
    },
    {
      "a": "07233042",
      "j": 2023,
      "n": "mehren",
      "dn": "Mehren",
      "bl": 7,
      "hs": 540.0,
      "mns": 1
    },
    {
      "a": "07233043",
      "j": 2023,
      "n": "meisburg",
      "dn": "Meisburg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233046",
      "j": 2023,
      "n": "mückeln",
      "dn": "Mückeln",
      "bl": 7,
      "hs": 485.0,
      "mns": 1
    },
    {
      "a": "07233048",
      "j": 2023,
      "n": "neichen",
      "dn": "Neichen",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233049",
      "j": 2023,
      "n": "nerdlen",
      "dn": "Nerdlen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233050",
      "j": 2023,
      "n": "neroth",
      "dn": "Neroth",
      "bl": 7,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "07233052",
      "j": 2023,
      "n": "niederstadtfeld",
      "dn": "Niederstadtfeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233053",
      "j": 2023,
      "n": "oberbettingen",
      "dn": "Oberbettingen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07233054",
      "j": 2023,
      "n": "oberehe-stroheich",
      "dn": "Oberehe-Stroheich",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233055",
      "j": 2023,
      "n": "oberstadtfeld",
      "dn": "Oberstadtfeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233056",
      "j": 2023,
      "n": "pelm",
      "dn": "Pelm",
      "bl": 7,
      "hs": 530.0,
      "mns": 1
    },
    {
      "a": "07233058",
      "j": 2023,
      "n": "rockeskyll",
      "dn": "Rockeskyll",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07233060",
      "j": 2023,
      "n": "salm",
      "dn": "Salm",
      "bl": 7,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "07233061",
      "j": 2023,
      "n": "sarmersbach",
      "dn": "Sarmersbach",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07233062",
      "j": 2023,
      "n": "saxler",
      "dn": "Saxler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233063",
      "j": 2023,
      "n": "schalkenmehren",
      "dn": "Schalkenmehren",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07233064",
      "j": 2023,
      "n": "schönbach",
      "dn": "Schönbach",
      "bl": 7,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "07233065",
      "j": 2023,
      "n": "schutz",
      "dn": "Schutz",
      "bl": 7,
      "hs": 471.0,
      "mns": 1
    },
    {
      "a": "07233067",
      "j": 2023,
      "n": "steineberg",
      "dn": "Steineberg",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07233068",
      "j": 2023,
      "n": "steiningen",
      "dn": "Steiningen",
      "bl": 7,
      "hs": 600.0,
      "mns": 1
    },
    {
      "a": "07233070",
      "j": 2023,
      "n": "strohn",
      "dn": "Strohn",
      "bl": 7,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "07233071",
      "j": 2023,
      "n": "strotzbüsch",
      "dn": "Strotzbüsch",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233074",
      "j": 2023,
      "n": "udler",
      "dn": "Udler",
      "bl": 7,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "07233075",
      "j": 2023,
      "n": "üdersdorf",
      "dn": "Üdersdorf",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07233076",
      "j": 2023,
      "n": "üxheim",
      "dn": "Üxheim",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07233077",
      "j": 2023,
      "n": "utzerath",
      "dn": "Utzerath",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233079",
      "j": 2023,
      "n": "wallenborn",
      "dn": "Wallenborn",
      "bl": 7,
      "hs": 540.0,
      "mns": 1
    },
    {
      "a": "07233080",
      "j": 2023,
      "n": "walsdorf",
      "dn": "Walsdorf",
      "bl": 7,
      "hs": 290.0,
      "mns": 1
    },
    {
      "a": "07233081",
      "j": 2023,
      "n": "weidenbach",
      "dn": "Weidenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233083",
      "j": 2023,
      "n": "wiesbaum",
      "dn": "Wiesbaum",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233084",
      "j": 2023,
      "n": "winkel",
      "dn": "Winkel",
      "bl": 7,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "07233201",
      "j": 2023,
      "n": "arbach",
      "dn": "Arbach",
      "bl": 7,
      "hs": 290.0,
      "mns": 1
    },
    {
      "a": "07233202",
      "j": 2023,
      "n": "bereborn",
      "dn": "Bereborn",
      "bl": 7,
      "hs": 270.0,
      "mns": 1
    },
    {
      "a": "07233203",
      "j": 2023,
      "n": "berenbach",
      "dn": "Berenbach",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233204",
      "j": 2023,
      "n": "birresborn",
      "dn": "Birresborn",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07233205",
      "j": 2023,
      "n": "bodenbach",
      "dn": "Bodenbach",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233206",
      "j": 2023,
      "n": "bongard",
      "dn": "Bongard",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233207",
      "j": 2023,
      "n": "borler",
      "dn": "Borler",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233208",
      "j": 2023,
      "n": "brücktal",
      "dn": "Brücktal",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233209",
      "j": 2023,
      "n": "densborn",
      "dn": "Densborn",
      "bl": 7,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "07233210",
      "j": 2023,
      "n": "drees",
      "dn": "Drees",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233211",
      "j": 2023,
      "n": "duppach",
      "dn": "Duppach",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07233212",
      "j": 2023,
      "n": "gelenberg",
      "dn": "Gelenberg",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233213",
      "j": 2023,
      "n": "gunderath",
      "dn": "Gunderath",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233214",
      "j": 2023,
      "n": "hallschlag",
      "dn": "Hallschlag",
      "bl": 7,
      "hs": 700.0,
      "mns": 1
    },
    {
      "a": "07233215",
      "j": 2023,
      "n": "höchstberg",
      "dn": "Höchstberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233216",
      "j": 2023,
      "n": "horperath",
      "dn": "Horperath",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233217",
      "j": 2023,
      "n": "kaperich",
      "dn": "Kaperich",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233218",
      "j": 2023,
      "n": "kelberg",
      "dn": "Kelberg",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233219",
      "j": 2023,
      "n": "kerschenbach",
      "dn": "Kerschenbach",
      "bl": 7,
      "hs": 0.0,
      "mns": 1
    },
    {
      "a": "07233220",
      "j": 2023,
      "n": "kirsbach",
      "dn": "Kirsbach",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233221",
      "j": 2023,
      "n": "kötterichen",
      "dn": "Kötterichen",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233222",
      "j": 2023,
      "n": "kolverath",
      "dn": "Kolverath",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233223",
      "j": 2023,
      "n": "kopp",
      "dn": "Kopp",
      "bl": 7,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "07233224",
      "j": 2023,
      "n": "lirstal",
      "dn": "Lirstal",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233225",
      "j": 2023,
      "n": "mannebach",
      "dn": "Mannebach",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233226",
      "j": 2023,
      "n": "mosbruch",
      "dn": "Mosbruch",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233227",
      "j": 2023,
      "n": "mürlenbach",
      "dn": "Mürlenbach",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07233228",
      "j": 2023,
      "n": "nitz",
      "dn": "Nitz",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233229",
      "j": 2023,
      "n": "nohn",
      "dn": "Nohn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233230",
      "j": 2023,
      "n": "oberelz",
      "dn": "Oberelz",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233232",
      "j": 2023,
      "n": "ormont",
      "dn": "Ormont",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233233",
      "j": 2023,
      "n": "reimerath",
      "dn": "Reimerath",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233234",
      "j": 2023,
      "n": "retterath",
      "dn": "Retterath",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233235",
      "j": 2023,
      "n": "reuth",
      "dn": "Reuth",
      "bl": 7,
      "hs": 0.0,
      "mns": 1
    },
    {
      "a": "07233236",
      "j": 2023,
      "n": "sassen",
      "dn": "Sassen",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233237",
      "j": 2023,
      "n": "scheid",
      "dn": "Scheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233239",
      "j": 2023,
      "n": "schüller",
      "dn": "Schüller",
      "bl": 7,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "07233240",
      "j": 2023,
      "n": "stadtkyll",
      "dn": "Stadtkyll",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233241",
      "j": 2023,
      "n": "steffeln",
      "dn": "Steffeln",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233242",
      "j": 2023,
      "n": "uersfeld",
      "dn": "Uersfeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07233243",
      "j": 2023,
      "n": "ueß",
      "dn": "Ueß",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233244",
      "j": 2023,
      "n": "welcherath",
      "dn": "Welcherath",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07233501",
      "j": 2023,
      "n": "daun",
      "dn": "Daun",
      "bl": 7,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "07235001",
      "j": 2023,
      "n": "aach",
      "dn": "Aach",
      "bl": 7,
      "hs": 570.0,
      "mns": 1
    },
    {
      "a": "07235002",
      "j": 2023,
      "n": "ayl",
      "dn": "Ayl",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235003",
      "j": 2023,
      "n": "baldringen",
      "dn": "Baldringen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235004",
      "j": 2023,
      "n": "bekond",
      "dn": "Bekond",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235005",
      "j": 2023,
      "n": "bescheid",
      "dn": "Bescheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235008",
      "j": 2023,
      "n": "beuren (hochwald)",
      "dn": "Beuren (Hochwald)",
      "bl": 7,
      "hs": 485.0,
      "mns": 1
    },
    {
      "a": "07235010",
      "j": 2023,
      "n": "bonerath",
      "dn": "Bonerath",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07235014",
      "j": 2023,
      "n": "damflos",
      "dn": "Damflos",
      "bl": 7,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "07235015",
      "j": 2023,
      "n": "detzem",
      "dn": "Detzem",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235019",
      "j": 2023,
      "n": "ensch",
      "dn": "Ensch",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235021",
      "j": 2023,
      "n": "farschweiler",
      "dn": "Farschweiler",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07235022",
      "j": 2023,
      "n": "fell (gemeinde)",
      "dn": "Fell (Gemeinde)",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235025",
      "j": 2023,
      "n": "fisch",
      "dn": "Fisch",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235026",
      "j": 2023,
      "n": "föhren",
      "dn": "Föhren",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235027",
      "j": 2023,
      "n": "franzenheim",
      "dn": "Franzenheim",
      "bl": 7,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "07235028",
      "j": 2023,
      "n": "freudenburg",
      "dn": "Freudenburg",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07235030",
      "j": 2023,
      "n": "geisfeld",
      "dn": "Geisfeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235033",
      "j": 2023,
      "n": "greimerath",
      "dn": "Greimerath",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235035",
      "j": 2023,
      "n": "grimburg",
      "dn": "Grimburg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235036",
      "j": 2023,
      "n": "gusenburg",
      "dn": "Gusenburg",
      "bl": 7,
      "hs": 545.0,
      "mns": 1
    },
    {
      "a": "07235037",
      "j": 2023,
      "n": "gusterath",
      "dn": "Gusterath",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235038",
      "j": 2023,
      "n": "gutweiler",
      "dn": "Gutweiler",
      "bl": 7,
      "hs": 540.0,
      "mns": 1
    },
    {
      "a": "07235040",
      "j": 2023,
      "n": "heddert",
      "dn": "Heddert",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235043",
      "j": 2023,
      "n": "hentern",
      "dn": "Hentern",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07235044",
      "j": 2023,
      "n": "herl",
      "dn": "Herl",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07235045",
      "j": 2023,
      "n": "hermeskeil",
      "dn": "Hermeskeil",
      "bl": 7,
      "hs": 545.0,
      "mns": 1
    },
    {
      "a": "07235046",
      "j": 2023,
      "n": "hinzenburg",
      "dn": "Hinzenburg",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07235047",
      "j": 2023,
      "n": "hinzert-pölert",
      "dn": "Hinzert-Pölert",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235048",
      "j": 2023,
      "n": "hockweiler",
      "dn": "Hockweiler",
      "bl": 7,
      "hs": 575.0,
      "mns": 1
    },
    {
      "a": "07235050",
      "j": 2023,
      "n": "holzerath",
      "dn": "Holzerath",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235051",
      "j": 2023,
      "n": "igel",
      "dn": "Igel",
      "bl": 7,
      "hs": 575.0,
      "mns": 1
    },
    {
      "a": "07235052",
      "j": 2023,
      "n": "irsch",
      "dn": "Irsch",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235055",
      "j": 2023,
      "n": "kanzem",
      "dn": "Kanzem",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07235056",
      "j": 2023,
      "n": "kasel",
      "dn": "Kasel",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235057",
      "j": 2023,
      "n": "kastel-staadt",
      "dn": "Kastel-Staadt",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07235058",
      "j": 2023,
      "n": "kell am see",
      "dn": "Kell am See",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235060",
      "j": 2023,
      "n": "kenn",
      "dn": "Kenn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235062",
      "j": 2023,
      "n": "kirf",
      "dn": "Kirf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235063",
      "j": 2023,
      "n": "klüsserath",
      "dn": "Klüsserath",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235067",
      "j": 2023,
      "n": "köwerich",
      "dn": "Köwerich",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07235068",
      "j": 2023,
      "n": "konz",
      "dn": "Konz",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07235069",
      "j": 2023,
      "n": "kordel",
      "dn": "Kordel",
      "bl": 7,
      "hs": 695.0,
      "mns": 1
    },
    {
      "a": "07235070",
      "j": 2023,
      "n": "korlingen",
      "dn": "Korlingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235072",
      "j": 2023,
      "n": "lampaden",
      "dn": "Lampaden",
      "bl": 7,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "07235073",
      "j": 2023,
      "n": "langsur",
      "dn": "Langsur",
      "bl": 7,
      "hs": 570.0,
      "mns": 1
    },
    {
      "a": "07235074",
      "j": 2023,
      "n": "leiwen",
      "dn": "Leiwen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235077",
      "j": 2023,
      "n": "longen",
      "dn": "Longen",
      "bl": 7,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "07235078",
      "j": 2023,
      "n": "longuich",
      "dn": "Longuich",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07235080",
      "j": 2023,
      "n": "lorscheid",
      "dn": "Lorscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235081",
      "j": 2023,
      "n": "mandern",
      "dn": "Mandern",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235082",
      "j": 2023,
      "n": "mannebach",
      "dn": "Mannebach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235083",
      "j": 2023,
      "n": "mehring",
      "dn": "Mehring",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235085",
      "j": 2023,
      "n": "mertesdorf",
      "dn": "Mertesdorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235090",
      "j": 2023,
      "n": "morscheid",
      "dn": "Morscheid",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07235091",
      "j": 2023,
      "n": "naurath",
      "dn": "Naurath",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235092",
      "j": 2023,
      "n": "naurath",
      "dn": "Naurath",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235093",
      "j": 2023,
      "n": "neuhütten",
      "dn": "Neuhütten",
      "bl": 7,
      "hs": 545.0,
      "mns": 1
    },
    {
      "a": "07235094",
      "j": 2023,
      "n": "newel",
      "dn": "Newel",
      "bl": 7,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "07235095",
      "j": 2023,
      "n": "nittel",
      "dn": "Nittel",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07235096",
      "j": 2023,
      "n": "oberbillig",
      "dn": "Oberbillig",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07235098",
      "j": 2023,
      "n": "ockfen",
      "dn": "Ockfen",
      "bl": 7,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "07235100",
      "j": 2023,
      "n": "ollmuth",
      "dn": "Ollmuth",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07235101",
      "j": 2023,
      "n": "onsdorf",
      "dn": "Onsdorf",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07235103",
      "j": 2023,
      "n": "osburg",
      "dn": "Osburg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235104",
      "j": 2023,
      "n": "palzem",
      "dn": "Palzem",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07235105",
      "j": 2023,
      "n": "paschel",
      "dn": "Paschel",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07235106",
      "j": 2023,
      "n": "pellingen",
      "dn": "Pellingen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07235107",
      "j": 2023,
      "n": "pluwig",
      "dn": "Pluwig",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07235108",
      "j": 2023,
      "n": "pölich",
      "dn": "Pölich",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235111",
      "j": 2023,
      "n": "ralingen",
      "dn": "Ralingen",
      "bl": 7,
      "hs": 575.0,
      "mns": 1
    },
    {
      "a": "07235112",
      "j": 2023,
      "n": "rascheid",
      "dn": "Rascheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235114",
      "j": 2023,
      "n": "reinsfeld",
      "dn": "Reinsfeld",
      "bl": 7,
      "hs": 476.0,
      "mns": 1
    },
    {
      "a": "07235115",
      "j": 2023,
      "n": "riol",
      "dn": "Riol",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235116",
      "j": 2023,
      "n": "riveris",
      "dn": "Riveris",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235118",
      "j": 2023,
      "n": "saarburg",
      "dn": "Saarburg",
      "bl": 7,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "07235119",
      "j": 2023,
      "n": "schillingen",
      "dn": "Schillingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235120",
      "j": 2023,
      "n": "schleich",
      "dn": "Schleich",
      "bl": 7,
      "hs": 495.0,
      "mns": 1
    },
    {
      "a": "07235122",
      "j": 2023,
      "n": "schoden",
      "dn": "Schoden",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235123",
      "j": 2023,
      "n": "schömerich",
      "dn": "Schömerich",
      "bl": 7,
      "hs": 525.0,
      "mns": 1
    },
    {
      "a": "07235124",
      "j": 2023,
      "n": "schöndorf",
      "dn": "Schöndorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235125",
      "j": 2023,
      "n": "schweich",
      "dn": "Schweich",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235126",
      "j": 2023,
      "n": "serrig",
      "dn": "Serrig",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235129",
      "j": 2023,
      "n": "sommerau",
      "dn": "Sommerau",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07235131",
      "j": 2023,
      "n": "taben-rodt",
      "dn": "Taben-Rodt",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235132",
      "j": 2023,
      "n": "tawern",
      "dn": "Tawern",
      "bl": 7,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "07235133",
      "j": 2023,
      "n": "temmels",
      "dn": "Temmels",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07235134",
      "j": 2023,
      "n": "thörnich",
      "dn": "Thörnich",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235135",
      "j": 2023,
      "n": "thomm",
      "dn": "Thomm",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235136",
      "j": 2023,
      "n": "trassem",
      "dn": "Trassem",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235137",
      "j": 2023,
      "n": "trierweiler",
      "dn": "Trierweiler",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07235140",
      "j": 2023,
      "n": "vierherrenborn",
      "dn": "Vierherrenborn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235141",
      "j": 2023,
      "n": "waldrach",
      "dn": "Waldrach",
      "bl": 7,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "07235142",
      "j": 2023,
      "n": "waldweiler",
      "dn": "Waldweiler",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07235143",
      "j": 2023,
      "n": "wasserliesch",
      "dn": "Wasserliesch",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07235144",
      "j": 2023,
      "n": "wawern",
      "dn": "Wawern",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07235146",
      "j": 2023,
      "n": "wellen",
      "dn": "Wellen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07235148",
      "j": 2023,
      "n": "wiltingen",
      "dn": "Wiltingen",
      "bl": 7,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "07235149",
      "j": 2023,
      "n": "wincheringen",
      "dn": "Wincheringen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235151",
      "j": 2024,
      "n": "zemmer",
      "dn": "Zemmer",
      "bl": 7,
      "hs": 620.0,
      "mns": 1
    },
    {
      "a": "07235152",
      "j": 2023,
      "n": "zerf",
      "dn": "Zerf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235153",
      "j": 2023,
      "n": "züsch",
      "dn": "Züsch",
      "bl": 7,
      "hs": 545.0,
      "mns": 1
    },
    {
      "a": "07235154",
      "j": 2023,
      "n": "merzkirchen",
      "dn": "Merzkirchen",
      "bl": 7,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "07235207",
      "j": 2023,
      "n": "trittenheim",
      "dn": "Trittenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07235501",
      "j": 2023,
      "n": "welschbillig",
      "dn": "Welschbillig",
      "bl": 7,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "07311000",
      "j": 2023,
      "n": "frankenthal",
      "dn": "Frankenthal",
      "bl": 7,
      "hs": 540.0,
      "mns": 3
    },
    {
      "a": "07312000",
      "j": 2024,
      "n": "kaiserslautern",
      "dn": "Kaiserslautern",
      "bl": 7,
      "hs": 510.0,
      "mns": 2
    },
    {
      "a": "07313000",
      "j": 2023,
      "n": "landau in der pfalz",
      "dn": "Landau in der Pfalz",
      "bl": 7,
      "hs": 495.0,
      "mns": 3
    },
    {
      "a": "07314000",
      "j": 2023,
      "n": "ludwigshafen am rhein",
      "dn": "Ludwigshafen am Rhein",
      "bl": 7,
      "hs": 540.0,
      "mns": 4
    },
    {
      "a": "07315000",
      "j": 2023,
      "n": "mainz",
      "dn": "Mainz",
      "bl": 7,
      "hs": 480.0,
      "mns": 6
    },
    {
      "a": "07316000",
      "j": 2023,
      "n": "neustadt an der weinstraße",
      "dn": "Neustadt an der Weinstraße",
      "bl": 7,
      "hs": 505.0,
      "mns": 2
    },
    {
      "a": "07317000",
      "j": 2023,
      "n": "pirmasens",
      "dn": "Pirmasens",
      "bl": 7,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "07318000",
      "j": 2023,
      "n": "speyer",
      "dn": "Speyer",
      "bl": 7,
      "hs": 450.0,
      "mns": 3
    },
    {
      "a": "07319000",
      "j": 2024,
      "n": "worms",
      "dn": "Worms",
      "bl": 7,
      "hs": 633.0,
      "mns": 3
    },
    {
      "a": "07320000",
      "j": 2023,
      "n": "zweibrücken",
      "dn": "Zweibrücken",
      "bl": 7,
      "hs": 552.0,
      "mns": 1
    },
    {
      "a": "07331001",
      "j": 2023,
      "n": "albig",
      "dn": "Albig",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331002",
      "j": 2023,
      "n": "alsheim",
      "dn": "Alsheim",
      "bl": 7,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "07331003",
      "j": 2023,
      "n": "alzey",
      "dn": "Alzey",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07331004",
      "j": 2023,
      "n": "armsheim",
      "dn": "Armsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331005",
      "j": 2023,
      "n": "bechenheim",
      "dn": "Bechenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331006",
      "j": 2023,
      "n": "bechtheim",
      "dn": "Bechtheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331007",
      "j": 2023,
      "n": "bechtolsheim",
      "dn": "Bechtolsheim",
      "bl": 7,
      "hs": 485.0,
      "mns": 2
    },
    {
      "a": "07331008",
      "j": 2023,
      "n": "bermersheim vor der höhe",
      "dn": "Bermersheim vor der Höhe",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331009",
      "j": 2023,
      "n": "bermersheim",
      "dn": "Bermersheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331010",
      "j": 2023,
      "n": "biebelnheim",
      "dn": "Biebelnheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331011",
      "j": 2023,
      "n": "hochborn",
      "dn": "Hochborn",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331012",
      "j": 2023,
      "n": "bornheim",
      "dn": "Bornheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331014",
      "j": 2023,
      "n": "dintesheim",
      "dn": "Dintesheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331015",
      "j": 2023,
      "n": "dittelsheim-heßloch",
      "dn": "Dittelsheim-Heßloch",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331017",
      "j": 2023,
      "n": "eckelsheim",
      "dn": "Eckelsheim",
      "bl": 7,
      "hs": 365.0,
      "mns": 2
    },
    {
      "a": "07331018",
      "j": 2023,
      "n": "eich",
      "dn": "Eich",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331019",
      "j": 2023,
      "n": "ensheim",
      "dn": "Ensheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331020",
      "j": 2023,
      "n": "eppelsheim",
      "dn": "Eppelsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331021",
      "j": 2023,
      "n": "erbes-büdesheim",
      "dn": "Erbes-Büdesheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331022",
      "j": 2023,
      "n": "esselborn",
      "dn": "Esselborn",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331023",
      "j": 2023,
      "n": "flörsheim-dalsheim",
      "dn": "Flörsheim-Dalsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331024",
      "j": 2023,
      "n": "flomborn",
      "dn": "Flomborn",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331025",
      "j": 2023,
      "n": "flonheim",
      "dn": "Flonheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331026",
      "j": 2023,
      "n": "framersheim",
      "dn": "Framersheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331027",
      "j": 2023,
      "n": "freimersheim",
      "dn": "Freimersheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331028",
      "j": 2023,
      "n": "frettenheim",
      "dn": "Frettenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331029",
      "j": 2023,
      "n": "gabsheim",
      "dn": "Gabsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331030",
      "j": 2023,
      "n": "gau-bickelheim",
      "dn": "Gau-Bickelheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331031",
      "j": 2023,
      "n": "gau-heppenheim",
      "dn": "Gau-Heppenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331032",
      "j": 2023,
      "n": "gau-odernheim",
      "dn": "Gau-Odernheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331033",
      "j": 2023,
      "n": "gau-weinheim",
      "dn": "Gau-Weinheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331034",
      "j": 2023,
      "n": "gimbsheim",
      "dn": "Gimbsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331035",
      "j": 2023,
      "n": "gumbsheim",
      "dn": "Gumbsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331036",
      "j": 2023,
      "n": "gundersheim",
      "dn": "Gundersheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331037",
      "j": 2023,
      "n": "gundheim",
      "dn": "Gundheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331038",
      "j": 2023,
      "n": "hamm am rhein",
      "dn": "Hamm am Rhein",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331039",
      "j": 2023,
      "n": "hangen-weisheim",
      "dn": "Hangen-Weisheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331041",
      "j": 2023,
      "n": "hohen-sülzen",
      "dn": "Hohen-Sülzen",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331042",
      "j": 2023,
      "n": "kettenheim",
      "dn": "Kettenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331043",
      "j": 2023,
      "n": "lonsheim",
      "dn": "Lonsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331044",
      "j": 2023,
      "n": "mauchenheim",
      "dn": "Mauchenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331045",
      "j": 2023,
      "n": "mettenheim",
      "dn": "Mettenheim",
      "bl": 7,
      "hs": 485.0,
      "mns": 2
    },
    {
      "a": "07331046",
      "j": 2023,
      "n": "mölsheim",
      "dn": "Mölsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331047",
      "j": 2023,
      "n": "mörstadt",
      "dn": "Mörstadt",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331048",
      "j": 2023,
      "n": "monsheim",
      "dn": "Monsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331049",
      "j": 2023,
      "n": "monzernheim",
      "dn": "Monzernheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331050",
      "j": 2023,
      "n": "nack",
      "dn": "Nack",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331051",
      "j": 2023,
      "n": "nieder-wiesen",
      "dn": "Nieder-Wiesen",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331052",
      "j": 2023,
      "n": "ober-flörsheim",
      "dn": "Ober-Flörsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331053",
      "j": 2023,
      "n": "offenheim",
      "dn": "Offenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331054",
      "j": 2023,
      "n": "offstein",
      "dn": "Offstein",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331055",
      "j": 2024,
      "n": "osthofen",
      "dn": "Osthofen",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331056",
      "j": 2023,
      "n": "partenheim",
      "dn": "Partenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331058",
      "j": 2023,
      "n": "saulheim",
      "dn": "Saulheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331059",
      "j": 2023,
      "n": "schornsheim",
      "dn": "Schornsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331060",
      "j": 2023,
      "n": "siefersheim",
      "dn": "Siefersheim",
      "bl": 7,
      "hs": 365.0,
      "mns": 2
    },
    {
      "a": "07331061",
      "j": 2023,
      "n": "spiesheim",
      "dn": "Spiesheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331062",
      "j": 2023,
      "n": "stein-bockenheim",
      "dn": "Stein-Bockenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331063",
      "j": 2023,
      "n": "sulzheim",
      "dn": "Sulzheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331064",
      "j": 2023,
      "n": "udenheim",
      "dn": "Udenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331065",
      "j": 2023,
      "n": "vendersheim",
      "dn": "Vendersheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331066",
      "j": 2023,
      "n": "wachenheim",
      "dn": "Wachenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331067",
      "j": 2023,
      "n": "wahlheim",
      "dn": "Wahlheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331068",
      "j": 2023,
      "n": "wallertheim",
      "dn": "Wallertheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331070",
      "j": 2023,
      "n": "wendelsheim",
      "dn": "Wendelsheim",
      "bl": 7,
      "hs": 365.0,
      "mns": 2
    },
    {
      "a": "07331071",
      "j": 2023,
      "n": "westhofen",
      "dn": "Westhofen",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331072",
      "j": 2023,
      "n": "wöllstein",
      "dn": "Wöllstein",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331073",
      "j": 2023,
      "n": "wörrstadt",
      "dn": "Wörrstadt",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07331075",
      "j": 2023,
      "n": "wonsheim",
      "dn": "Wonsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332001",
      "j": 2023,
      "n": "altleiningen",
      "dn": "Altleiningen",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332002",
      "j": 2023,
      "n": "bad dürkheim",
      "dn": "Bad Dürkheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07332003",
      "j": 2023,
      "n": "battenberg",
      "dn": "Battenberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332004",
      "j": 2023,
      "n": "bissersheim",
      "dn": "Bissersheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332005",
      "j": 2023,
      "n": "bobenheim am berg",
      "dn": "Bobenheim am Berg",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332006",
      "j": 2023,
      "n": "bockenheim an der weinstraße",
      "dn": "Bockenheim an der Weinstraße",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332007",
      "j": 2023,
      "n": "carlsberg",
      "dn": "Carlsberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332008",
      "j": 2023,
      "n": "dackenheim",
      "dn": "Dackenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332009",
      "j": 2023,
      "n": "deidesheim",
      "dn": "Deidesheim",
      "bl": 7,
      "hs": 495.0,
      "mns": 2
    },
    {
      "a": "07332010",
      "j": 2023,
      "n": "dirmstein",
      "dn": "Dirmstein",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332012",
      "j": 2023,
      "n": "ebertsheim",
      "dn": "Ebertsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332013",
      "j": 2023,
      "n": "ellerstadt",
      "dn": "Ellerstadt",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332014",
      "j": 2023,
      "n": "elmstein",
      "dn": "Elmstein",
      "bl": 7,
      "hs": 510.0,
      "mns": 2
    },
    {
      "a": "07332015",
      "j": 2023,
      "n": "erpolzheim",
      "dn": "Erpolzheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332016",
      "j": 2023,
      "n": "esthal",
      "dn": "Esthal",
      "bl": 7,
      "hs": 510.0,
      "mns": 2
    },
    {
      "a": "07332017",
      "j": 2023,
      "n": "forst an der weinstraße",
      "dn": "Forst an der Weinstraße",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332018",
      "j": 2024,
      "n": "frankeneck",
      "dn": "Frankeneck",
      "bl": 7,
      "hs": 545.0,
      "mns": 2
    },
    {
      "a": "07332019",
      "j": 2023,
      "n": "freinsheim",
      "dn": "Freinsheim",
      "bl": 7,
      "hs": 501.0,
      "mns": 2
    },
    {
      "a": "07332020",
      "j": 2023,
      "n": "friedelsheim",
      "dn": "Friedelsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332021",
      "j": 2023,
      "n": "gerolsheim",
      "dn": "Gerolsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332022",
      "j": 2023,
      "n": "gönnheim",
      "dn": "Gönnheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332023",
      "j": 2023,
      "n": "großkarlbach",
      "dn": "Großkarlbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332024",
      "j": 2023,
      "n": "grünstadt",
      "dn": "Grünstadt",
      "bl": 7,
      "hs": 520.0,
      "mns": 3
    },
    {
      "a": "07332025",
      "j": 2023,
      "n": "haßloch",
      "dn": "Haßloch",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07332026",
      "j": 2023,
      "n": "herxheim am berg",
      "dn": "Herxheim am Berg",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332027",
      "j": 2023,
      "n": "hettenleidelheim",
      "dn": "Hettenleidelheim",
      "bl": 7,
      "hs": 500.0,
      "mns": 2
    },
    {
      "a": "07332028",
      "j": 2023,
      "n": "kallstadt",
      "dn": "Kallstadt",
      "bl": 7,
      "hs": 487.0,
      "mns": 2
    },
    {
      "a": "07332029",
      "j": 2023,
      "n": "kindenheim",
      "dn": "Kindenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332030",
      "j": 2023,
      "n": "kirchheim an der weinstraße",
      "dn": "Kirchheim an der Weinstraße",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332031",
      "j": 2023,
      "n": "kleinkarlbach",
      "dn": "Kleinkarlbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332032",
      "j": 2023,
      "n": "lambrecht",
      "dn": "Lambrecht",
      "bl": 7,
      "hs": 500.0,
      "mns": 2
    },
    {
      "a": "07332033",
      "j": 2023,
      "n": "laumersheim",
      "dn": "Laumersheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332034",
      "j": 2023,
      "n": "lindenberg",
      "dn": "Lindenberg",
      "bl": 7,
      "hs": 540.0,
      "mns": 2
    },
    {
      "a": "07332035",
      "j": 2023,
      "n": "meckenheim",
      "dn": "Meckenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332036",
      "j": 2023,
      "n": "mertesheim",
      "dn": "Mertesheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332037",
      "j": 2023,
      "n": "neidenfels",
      "dn": "Neidenfels",
      "bl": 7,
      "hs": 510.0,
      "mns": 2
    },
    {
      "a": "07332038",
      "j": 2023,
      "n": "neuleiningen",
      "dn": "Neuleiningen",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332039",
      "j": 2025,
      "n": "niederkirchen bei deidesheim",
      "dn": "Niederkirchen bei Deidesheim",
      "bl": 7,
      "hs": 515.0,
      "mns": 2
    },
    {
      "a": "07332040",
      "j": 2023,
      "n": "obersülzen",
      "dn": "Obersülzen",
      "bl": 7,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "07332041",
      "j": 2023,
      "n": "obrigheim",
      "dn": "Obrigheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332042",
      "j": 2023,
      "n": "quirnheim",
      "dn": "Quirnheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332043",
      "j": 2023,
      "n": "ruppertsberg",
      "dn": "Ruppertsberg",
      "bl": 7,
      "hs": 475.0,
      "mns": 2
    },
    {
      "a": "07332044",
      "j": 2023,
      "n": "tiefenthal",
      "dn": "Tiefenthal",
      "bl": 7,
      "hs": 485.0,
      "mns": 2
    },
    {
      "a": "07332046",
      "j": 2023,
      "n": "wachenheim an der weinstraße",
      "dn": "Wachenheim an der Weinstraße",
      "bl": 7,
      "hs": 500.0,
      "mns": 2
    },
    {
      "a": "07332047",
      "j": 2023,
      "n": "wattenheim",
      "dn": "Wattenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332048",
      "j": 2023,
      "n": "weidenthal",
      "dn": "Weidenthal",
      "bl": 7,
      "hs": 530.0,
      "mns": 2
    },
    {
      "a": "07332049",
      "j": 2023,
      "n": "weisenheim am berg",
      "dn": "Weisenheim am Berg",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07332050",
      "j": 2023,
      "n": "weisenheim am sand",
      "dn": "Weisenheim am Sand",
      "bl": 7,
      "hs": 480.0,
      "mns": 2
    },
    {
      "a": "07333001",
      "j": 2023,
      "n": "albisheim",
      "dn": "Albisheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07333003",
      "j": 2023,
      "n": "alsenz",
      "dn": "Alsenz",
      "bl": 7,
      "hs": 660.0,
      "mns": 1
    },
    {
      "a": "07333004",
      "j": 2023,
      "n": "bayerfeld-steckweiler",
      "dn": "Bayerfeld-Steckweiler",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333005",
      "j": 2023,
      "n": "bennhausen",
      "dn": "Bennhausen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333006",
      "j": 2023,
      "n": "biedesheim",
      "dn": "Biedesheim",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333007",
      "j": 2023,
      "n": "bischheim",
      "dn": "Bischheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07333008",
      "j": 2023,
      "n": "bisterschied",
      "dn": "Bisterschied",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333009",
      "j": 2023,
      "n": "börrstadt",
      "dn": "Börrstadt",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07333010",
      "j": 2023,
      "n": "bolanden",
      "dn": "Bolanden",
      "bl": 7,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "07333011",
      "j": 2023,
      "n": "breunigweiler",
      "dn": "Breunigweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07333012",
      "j": 2023,
      "n": "bubenheim",
      "dn": "Bubenheim",
      "bl": 7,
      "hs": 394.0,
      "mns": 1
    },
    {
      "a": "07333013",
      "j": 2023,
      "n": "dannenfels",
      "dn": "Dannenfels",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333014",
      "j": 2023,
      "n": "dielkirchen",
      "dn": "Dielkirchen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333016",
      "j": 2023,
      "n": "dörrmoschel",
      "dn": "Dörrmoschel",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333017",
      "j": 2023,
      "n": "dreisen",
      "dn": "Dreisen",
      "bl": 7,
      "hs": 436.0,
      "mns": 1
    },
    {
      "a": "07333018",
      "j": 2023,
      "n": "einselthum",
      "dn": "Einselthum",
      "bl": 7,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "07333019",
      "j": 2023,
      "n": "eisenberg",
      "dn": "Eisenberg",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333020",
      "j": 2023,
      "n": "falkenstein",
      "dn": "Falkenstein",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333021",
      "j": 2023,
      "n": "finkenbach-gersweiler",
      "dn": "Finkenbach-Gersweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07333022",
      "j": 2023,
      "n": "gauersheim",
      "dn": "Gauersheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07333023",
      "j": 2023,
      "n": "gaugrehweiler",
      "dn": "Gaugrehweiler",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333024",
      "j": 2023,
      "n": "gehrweiler",
      "dn": "Gehrweiler",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333025",
      "j": 2023,
      "n": "gerbach",
      "dn": "Gerbach",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333026",
      "j": 2023,
      "n": "göllheim",
      "dn": "Göllheim",
      "bl": 7,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "07333027",
      "j": 2023,
      "n": "gonbach",
      "dn": "Gonbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07333028",
      "j": 2023,
      "n": "gundersweiler",
      "dn": "Gundersweiler",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333030",
      "j": 2023,
      "n": "höringen",
      "dn": "Höringen",
      "bl": 7,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "07333031",
      "j": 2023,
      "n": "ilbesheim",
      "dn": "Ilbesheim",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333032",
      "j": 2023,
      "n": "immesheim",
      "dn": "Immesheim",
      "bl": 7,
      "hs": 409.0,
      "mns": 1
    },
    {
      "a": "07333033",
      "j": 2023,
      "n": "imsbach",
      "dn": "Imsbach",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333034",
      "j": 2023,
      "n": "imsweiler",
      "dn": "Imsweiler",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333035",
      "j": 2023,
      "n": "jakobsweiler",
      "dn": "Jakobsweiler",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07333036",
      "j": 2023,
      "n": "kalkofen",
      "dn": "Kalkofen",
      "bl": 7,
      "hs": 630.0,
      "mns": 1
    },
    {
      "a": "07333037",
      "j": 2023,
      "n": "katzenbach",
      "dn": "Katzenbach",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333038",
      "j": 2023,
      "n": "kerzenheim",
      "dn": "Kerzenheim",
      "bl": 7,
      "hs": 1000.0,
      "mns": 1
    },
    {
      "a": "07333039",
      "j": 2023,
      "n": "kirchheimbolanden",
      "dn": "Kirchheimbolanden",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07333040",
      "j": 2023,
      "n": "kriegsfeld",
      "dn": "Kriegsfeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07333041",
      "j": 2023,
      "n": "lautersheim",
      "dn": "Lautersheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07333042",
      "j": 2023,
      "n": "lohnsfeld",
      "dn": "Lohnsfeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07333043",
      "j": 2023,
      "n": "mannweiler-cölln",
      "dn": "Mannweiler-Cölln",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333045",
      "j": 2023,
      "n": "marnheim",
      "dn": "Marnheim",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333046",
      "j": 2023,
      "n": "mörsfeld",
      "dn": "Mörsfeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07333047",
      "j": 2023,
      "n": "morschheim",
      "dn": "Morschheim",
      "bl": 7,
      "hs": 428.0,
      "mns": 1
    },
    {
      "a": "07333048",
      "j": 2023,
      "n": "münchweiler an der alsenz",
      "dn": "Münchweiler an der Alsenz",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07333049",
      "j": 2023,
      "n": "münsterappel",
      "dn": "Münsterappel",
      "bl": 7,
      "hs": 750.0,
      "mns": 1
    },
    {
      "a": "07333050",
      "j": 2023,
      "n": "niederhausen an der appel",
      "dn": "Niederhausen an der Appel",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07333051",
      "j": 2023,
      "n": "niedermoschel",
      "dn": "Niedermoschel",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333053",
      "j": 2023,
      "n": "oberhausen an der appel",
      "dn": "Oberhausen an der Appel",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07333054",
      "j": 2023,
      "n": "obermoschel",
      "dn": "Obermoschel",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333055",
      "j": 2023,
      "n": "oberndorf",
      "dn": "Oberndorf",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07333056",
      "j": 2023,
      "n": "oberwiesen",
      "dn": "Oberwiesen",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07333057",
      "j": 2023,
      "n": "orbis",
      "dn": "Orbis",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07333058",
      "j": 2023,
      "n": "ottersheim",
      "dn": "Ottersheim",
      "bl": 7,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "07333060",
      "j": 2023,
      "n": "ramsen",
      "dn": "Ramsen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07333061",
      "j": 2023,
      "n": "ransweiler",
      "dn": "Ransweiler",
      "bl": 7,
      "hs": 568.0,
      "mns": 1
    },
    {
      "a": "07333062",
      "j": 2023,
      "n": "rittersheim",
      "dn": "Rittersheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07333064",
      "j": 2023,
      "n": "rüssingen",
      "dn": "Rüssingen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333065",
      "j": 2023,
      "n": "ruppertsecken",
      "dn": "Ruppertsecken",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333066",
      "j": 2023,
      "n": "sankt alban",
      "dn": "Sankt Alban",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333067",
      "j": 2023,
      "n": "schiersfeld",
      "dn": "Schiersfeld",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333068",
      "j": 2023,
      "n": "schönborn",
      "dn": "Schönborn",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333069",
      "j": 2023,
      "n": "schweisweiler",
      "dn": "Schweisweiler",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07333071",
      "j": 2023,
      "n": "sippersfeld",
      "dn": "Sippersfeld",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333072",
      "j": 2023,
      "n": "sitters",
      "dn": "Sitters",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333073",
      "j": 2023,
      "n": "stahlberg",
      "dn": "Stahlberg",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333074",
      "j": 2023,
      "n": "standenbühl",
      "dn": "Standenbühl",
      "bl": 7,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "07333075",
      "j": 2023,
      "n": "steinbach am donnersberg",
      "dn": "Steinbach am Donnersberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07333076",
      "j": 2023,
      "n": "stetten",
      "dn": "Stetten",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333077",
      "j": 2023,
      "n": "teschenmoschel",
      "dn": "Teschenmoschel",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333078",
      "j": 2023,
      "n": "unkenbach",
      "dn": "Unkenbach",
      "bl": 7,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "07333079",
      "j": 2023,
      "n": "waldgrehweiler",
      "dn": "Waldgrehweiler",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333080",
      "j": 2023,
      "n": "wartenberg-rohrbach",
      "dn": "Wartenberg-Rohrbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07333081",
      "j": 2023,
      "n": "weitersweiler",
      "dn": "Weitersweiler",
      "bl": 7,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "07333083",
      "j": 2023,
      "n": "winterborn",
      "dn": "Winterborn",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07333084",
      "j": 2023,
      "n": "würzweiler",
      "dn": "Würzweiler",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333201",
      "j": 2023,
      "n": "rathskirchen",
      "dn": "Rathskirchen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333202",
      "j": 2023,
      "n": "reichsthal",
      "dn": "Reichsthal",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333203",
      "j": 2023,
      "n": "seelen",
      "dn": "Seelen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07333501",
      "j": 2023,
      "n": "zellertal",
      "dn": "Zellertal",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333502",
      "j": 2023,
      "n": "rockenhausen",
      "dn": "Rockenhausen",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07333503",
      "j": 2023,
      "n": "winnweiler",
      "dn": "Winnweiler",
      "bl": 7,
      "hs": 659.0,
      "mns": 1
    },
    {
      "a": "07334001",
      "j": 2023,
      "n": "bellheim",
      "dn": "Bellheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07334002",
      "j": 2023,
      "n": "berg",
      "dn": "Berg",
      "bl": 7,
      "hs": 385.0,
      "mns": 2
    },
    {
      "a": "07334004",
      "j": 2023,
      "n": "erlenbach bei kandel",
      "dn": "Erlenbach bei Kandel",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07334005",
      "j": 2023,
      "n": "freckenfeld",
      "dn": "Freckenfeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07334006",
      "j": 2023,
      "n": "freisbach",
      "dn": "Freisbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07334007",
      "j": 2023,
      "n": "germersheim",
      "dn": "Germersheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07334008",
      "j": 2023,
      "n": "hagenbach",
      "dn": "Hagenbach",
      "bl": 7,
      "hs": 385.0,
      "mns": 2
    },
    {
      "a": "07334009",
      "j": 2023,
      "n": "hatzenbühl",
      "dn": "Hatzenbühl",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07334011",
      "j": 2023,
      "n": "hördt",
      "dn": "Hördt",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07334012",
      "j": 2023,
      "n": "jockgrim",
      "dn": "Jockgrim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07334013",
      "j": 2023,
      "n": "kandel",
      "dn": "Kandel",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07334014",
      "j": 2023,
      "n": "knittelsheim",
      "dn": "Knittelsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07334015",
      "j": 2023,
      "n": "kuhardt",
      "dn": "Kuhardt",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07334016",
      "j": 2023,
      "n": "leimersheim",
      "dn": "Leimersheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07334017",
      "j": 2023,
      "n": "lingenfeld",
      "dn": "Lingenfeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07334018",
      "j": 2023,
      "n": "lustadt",
      "dn": "Lustadt",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07334020",
      "j": 2023,
      "n": "minfeld",
      "dn": "Minfeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07334021",
      "j": 2023,
      "n": "neuburg am rhein",
      "dn": "Neuburg am Rhein",
      "bl": 7,
      "hs": 385.0,
      "mns": 2
    },
    {
      "a": "07334022",
      "j": 2023,
      "n": "neupotz",
      "dn": "Neupotz",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07334023",
      "j": 2023,
      "n": "ottersheim bei landau",
      "dn": "Ottersheim bei Landau",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07334024",
      "j": 2023,
      "n": "rheinzabern",
      "dn": "Rheinzabern",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07334025",
      "j": 2023,
      "n": "rülzheim",
      "dn": "Rülzheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07334027",
      "j": 2023,
      "n": "scheibenhardt",
      "dn": "Scheibenhardt",
      "bl": 7,
      "hs": 385.0,
      "mns": 2
    },
    {
      "a": "07334028",
      "j": 2023,
      "n": "schwegenheim",
      "dn": "Schwegenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07334030",
      "j": 2023,
      "n": "steinweiler",
      "dn": "Steinweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07334031",
      "j": 2023,
      "n": "vollmersweiler",
      "dn": "Vollmersweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07334032",
      "j": 2023,
      "n": "weingarten",
      "dn": "Weingarten",
      "bl": 7,
      "hs": 475.0,
      "mns": 2
    },
    {
      "a": "07334033",
      "j": 2023,
      "n": "westheim",
      "dn": "Westheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07334034",
      "j": 2023,
      "n": "winden",
      "dn": "Winden",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07334036",
      "j": 2023,
      "n": "zeiskam",
      "dn": "Zeiskam",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07334501",
      "j": 2023,
      "n": "wörth am rhein",
      "dn": "Wörth am Rhein",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07335002",
      "j": 2023,
      "n": "bann",
      "dn": "Bann",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07335003",
      "j": 2023,
      "n": "bruchmühlbach-miesau",
      "dn": "Bruchmühlbach-Miesau",
      "bl": 7,
      "hs": 489.0,
      "mns": 1
    },
    {
      "a": "07335004",
      "j": 2023,
      "n": "enkenbach-alsenborn",
      "dn": "Enkenbach-Alsenborn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07335005",
      "j": 2023,
      "n": "erzenhausen",
      "dn": "Erzenhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07335006",
      "j": 2023,
      "n": "eulenbis",
      "dn": "Eulenbis",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07335007",
      "j": 2023,
      "n": "fischbach",
      "dn": "Fischbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07335009",
      "j": 2023,
      "n": "frankelbach",
      "dn": "Frankelbach",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07335010",
      "j": 2023,
      "n": "frankenstein",
      "dn": "Frankenstein",
      "bl": 7,
      "hs": 554.0,
      "mns": 1
    },
    {
      "a": "07335011",
      "j": 2023,
      "n": "gerhardsbrunn",
      "dn": "Gerhardsbrunn",
      "bl": 7,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "07335012",
      "j": 2023,
      "n": "hauptstuhl",
      "dn": "Hauptstuhl",
      "bl": 7,
      "hs": 485.0,
      "mns": 1
    },
    {
      "a": "07335013",
      "j": 2023,
      "n": "heiligenmoschel",
      "dn": "Heiligenmoschel",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07335014",
      "j": 2023,
      "n": "hirschhorn/pfalz",
      "dn": "Hirschhorn/Pfalz",
      "bl": 7,
      "hs": 590.0,
      "mns": 1
    },
    {
      "a": "07335015",
      "j": 2023,
      "n": "hochspeyer",
      "dn": "Hochspeyer",
      "bl": 7,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "07335016",
      "j": 2023,
      "n": "hütschenhausen",
      "dn": "Hütschenhausen",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07335017",
      "j": 2023,
      "n": "katzweiler",
      "dn": "Katzweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07335018",
      "j": 2023,
      "n": "kindsbach",
      "dn": "Kindsbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07335019",
      "j": 2023,
      "n": "kollweiler",
      "dn": "Kollweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07335020",
      "j": 2023,
      "n": "kottweiler-schwanden",
      "dn": "Kottweiler-Schwanden",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07335021",
      "j": 2023,
      "n": "krickenbach",
      "dn": "Krickenbach",
      "bl": 7,
      "hs": 514.0,
      "mns": 1
    },
    {
      "a": "07335022",
      "j": 2023,
      "n": "landstuhl",
      "dn": "Landstuhl",
      "bl": 7,
      "hs": 562.0,
      "mns": 1
    },
    {
      "a": "07335023",
      "j": 2023,
      "n": "linden",
      "dn": "Linden",
      "bl": 7,
      "hs": 485.0,
      "mns": 1
    },
    {
      "a": "07335024",
      "j": 2023,
      "n": "mackenbach",
      "dn": "Mackenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07335025",
      "j": 2023,
      "n": "mehlbach",
      "dn": "Mehlbach",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07335026",
      "j": 2023,
      "n": "mehlingen",
      "dn": "Mehlingen",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07335027",
      "j": 2023,
      "n": "mittelbrunn",
      "dn": "Mittelbrunn",
      "bl": 7,
      "hs": 472.0,
      "mns": 1
    },
    {
      "a": "07335028",
      "j": 2023,
      "n": "neuhemsbach",
      "dn": "Neuhemsbach",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07335029",
      "j": 2023,
      "n": "niederkirchen",
      "dn": "Niederkirchen",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07335030",
      "j": 2023,
      "n": "niedermohr",
      "dn": "Niedermohr",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07335031",
      "j": 2023,
      "n": "oberarnbach",
      "dn": "Oberarnbach",
      "bl": 7,
      "hs": 468.0,
      "mns": 1
    },
    {
      "a": "07335033",
      "j": 2023,
      "n": "olsbrücken",
      "dn": "Olsbrücken",
      "bl": 7,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "07335034",
      "j": 2023,
      "n": "otterbach",
      "dn": "Otterbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07335035",
      "j": 2023,
      "n": "otterberg",
      "dn": "Otterberg",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07335037",
      "j": 2023,
      "n": "queidersbach",
      "dn": "Queidersbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07335038",
      "j": 2023,
      "n": "ramstein-miesenbach",
      "dn": "Ramstein-Miesenbach",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07335040",
      "j": 2023,
      "n": "rodenbach",
      "dn": "Rodenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07335041",
      "j": 2023,
      "n": "schallodenbach",
      "dn": "Schallodenbach",
      "bl": 7,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "07335042",
      "j": 2023,
      "n": "schneckenhausen",
      "dn": "Schneckenhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07335043",
      "j": 2023,
      "n": "schwedelbach",
      "dn": "Schwedelbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07335044",
      "j": 2023,
      "n": "steinwenden",
      "dn": "Steinwenden",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07335045",
      "j": 2023,
      "n": "stelzenberg",
      "dn": "Stelzenberg",
      "bl": 7,
      "hs": 493.0,
      "mns": 1
    },
    {
      "a": "07335046",
      "j": 2023,
      "n": "sulzbachtal",
      "dn": "Sulzbachtal",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07335047",
      "j": 2023,
      "n": "trippstadt",
      "dn": "Trippstadt",
      "bl": 7,
      "hs": 492.0,
      "mns": 1
    },
    {
      "a": "07335048",
      "j": 2023,
      "n": "waldleiningen",
      "dn": "Waldleiningen",
      "bl": 7,
      "hs": 525.0,
      "mns": 1
    },
    {
      "a": "07335049",
      "j": 2023,
      "n": "weilerbach",
      "dn": "Weilerbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07335201",
      "j": 2023,
      "n": "lambsborn",
      "dn": "Lambsborn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07335202",
      "j": 2023,
      "n": "langwieden",
      "dn": "Langwieden",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07335203",
      "j": 2023,
      "n": "martinshöhe",
      "dn": "Martinshöhe",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07335204",
      "j": 2023,
      "n": "schopp",
      "dn": "Schopp",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07335205",
      "j": 2023,
      "n": "sembach",
      "dn": "Sembach",
      "bl": 7,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "07335501",
      "j": 2023,
      "n": "reichenbach-steegen",
      "dn": "Reichenbach-Steegen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336001",
      "j": 2023,
      "n": "adenbach",
      "dn": "Adenbach",
      "bl": 7,
      "hs": 540.0,
      "mns": 1
    },
    {
      "a": "07336002",
      "j": 2023,
      "n": "albessen",
      "dn": "Albessen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336003",
      "j": 2023,
      "n": "altenglan",
      "dn": "Altenglan",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07336004",
      "j": 2023,
      "n": "altenkirchen",
      "dn": "Altenkirchen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336005",
      "j": 2023,
      "n": "aschbach",
      "dn": "Aschbach",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07336006",
      "j": 2023,
      "n": "blaubach",
      "dn": "Blaubach",
      "bl": 7,
      "hs": 495.0,
      "mns": 1
    },
    {
      "a": "07336008",
      "j": 2023,
      "n": "börsborn",
      "dn": "Börsborn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336009",
      "j": 2023,
      "n": "bosenbach",
      "dn": "Bosenbach",
      "bl": 7,
      "hs": 511.0,
      "mns": 1
    },
    {
      "a": "07336010",
      "j": 2023,
      "n": "breitenbach",
      "dn": "Breitenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336011",
      "j": 2023,
      "n": "brücken",
      "dn": "Brücken",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336012",
      "j": 2023,
      "n": "buborn",
      "dn": "Buborn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336013",
      "j": 2023,
      "n": "cronenberg",
      "dn": "Cronenberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336014",
      "j": 2023,
      "n": "deimberg",
      "dn": "Deimberg",
      "bl": 7,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "07336015",
      "j": 2023,
      "n": "dennweiler-frohnbach",
      "dn": "Dennweiler-Frohnbach",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07336016",
      "j": 2023,
      "n": "dittweiler",
      "dn": "Dittweiler",
      "bl": 7,
      "hs": 489.0,
      "mns": 1
    },
    {
      "a": "07336017",
      "j": 2023,
      "n": "dunzweiler",
      "dn": "Dunzweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336018",
      "j": 2023,
      "n": "ehweiler",
      "dn": "Ehweiler",
      "bl": 7,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "07336019",
      "j": 2023,
      "n": "einöllen",
      "dn": "Einöllen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336021",
      "j": 2023,
      "n": "elzweiler",
      "dn": "Elzweiler",
      "bl": 7,
      "hs": 485.0,
      "mns": 1
    },
    {
      "a": "07336022",
      "j": 2023,
      "n": "erdesbach",
      "dn": "Erdesbach",
      "bl": 7,
      "hs": 471.0,
      "mns": 1
    },
    {
      "a": "07336023",
      "j": 2023,
      "n": "eßweiler",
      "dn": "Eßweiler",
      "bl": 7,
      "hs": 548.0,
      "mns": 1
    },
    {
      "a": "07336024",
      "j": 2023,
      "n": "etschberg",
      "dn": "Etschberg",
      "bl": 7,
      "hs": 650.0,
      "mns": 1
    },
    {
      "a": "07336025",
      "j": 2023,
      "n": "föckelberg",
      "dn": "Föckelberg",
      "bl": 7,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "07336027",
      "j": 2023,
      "n": "frohnhofen",
      "dn": "Frohnhofen",
      "bl": 7,
      "hs": 521.0,
      "mns": 1
    },
    {
      "a": "07336029",
      "j": 2023,
      "n": "ginsweiler",
      "dn": "Ginsweiler",
      "bl": 7,
      "hs": 535.0,
      "mns": 1
    },
    {
      "a": "07336030",
      "j": 2023,
      "n": "glanbrücken",
      "dn": "Glanbrücken",
      "bl": 7,
      "hs": 655.0,
      "mns": 1
    },
    {
      "a": "07336031",
      "j": 2023,
      "n": "glan-münchweiler",
      "dn": "Glan-Münchweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336032",
      "j": 2023,
      "n": "gries",
      "dn": "Gries",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07336033",
      "j": 2023,
      "n": "grumbach",
      "dn": "Grumbach",
      "bl": 7,
      "hs": 575.0,
      "mns": 1
    },
    {
      "a": "07336034",
      "j": 2023,
      "n": "haschbach am remigiusberg",
      "dn": "Haschbach am Remigiusberg",
      "bl": 7,
      "hs": 494.0,
      "mns": 1
    },
    {
      "a": "07336035",
      "j": 2023,
      "n": "hausweiler",
      "dn": "Hausweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336036",
      "j": 2023,
      "n": "hefersweiler",
      "dn": "Hefersweiler",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07336037",
      "j": 2023,
      "n": "henschtal",
      "dn": "Henschtal",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07336038",
      "j": 2023,
      "n": "heinzenhausen",
      "dn": "Heinzenhausen",
      "bl": 7,
      "hs": 523.0,
      "mns": 1
    },
    {
      "a": "07336039",
      "j": 2023,
      "n": "herchweiler",
      "dn": "Herchweiler",
      "bl": 7,
      "hs": 494.0,
      "mns": 1
    },
    {
      "a": "07336040",
      "j": 2023,
      "n": "herren-sulzbach",
      "dn": "Herren-Sulzbach",
      "bl": 7,
      "hs": 530.0,
      "mns": 1
    },
    {
      "a": "07336041",
      "j": 2023,
      "n": "herschweiler-pettersheim",
      "dn": "Herschweiler-Pettersheim",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07336042",
      "j": 2023,
      "n": "hinzweiler",
      "dn": "Hinzweiler",
      "bl": 7,
      "hs": 593.0,
      "mns": 1
    },
    {
      "a": "07336043",
      "j": 2023,
      "n": "hohenöllen",
      "dn": "Hohenöllen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336044",
      "j": 2023,
      "n": "homberg",
      "dn": "Homberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336045",
      "j": 2023,
      "n": "hoppstädten",
      "dn": "Hoppstädten",
      "bl": 7,
      "hs": 485.0,
      "mns": 1
    },
    {
      "a": "07336046",
      "j": 2023,
      "n": "horschbach",
      "dn": "Horschbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336047",
      "j": 2023,
      "n": "hüffler",
      "dn": "Hüffler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336048",
      "j": 2023,
      "n": "jettenbach",
      "dn": "Jettenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336049",
      "j": 2023,
      "n": "kappeln",
      "dn": "Kappeln",
      "bl": 7,
      "hs": 613.0,
      "mns": 1
    },
    {
      "a": "07336050",
      "j": 2023,
      "n": "kirrweiler",
      "dn": "Kirrweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336051",
      "j": 2023,
      "n": "körborn",
      "dn": "Körborn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336052",
      "j": 2023,
      "n": "konken",
      "dn": "Konken",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07336053",
      "j": 2023,
      "n": "kreimbach-kaulbach",
      "dn": "Kreimbach-Kaulbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336054",
      "j": 2023,
      "n": "krottelbach",
      "dn": "Krottelbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336055",
      "j": 2023,
      "n": "kusel",
      "dn": "Kusel",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07336056",
      "j": 2023,
      "n": "langenbach",
      "dn": "Langenbach",
      "bl": 7,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "07336057",
      "j": 2023,
      "n": "langweiler",
      "dn": "Langweiler",
      "bl": 7,
      "hs": 525.0,
      "mns": 1
    },
    {
      "a": "07336058",
      "j": 2023,
      "n": "lauterecken",
      "dn": "Lauterecken",
      "bl": 7,
      "hs": 515.0,
      "mns": 1
    },
    {
      "a": "07336060",
      "j": 2023,
      "n": "lohnweiler",
      "dn": "Lohnweiler",
      "bl": 7,
      "hs": 491.0,
      "mns": 1
    },
    {
      "a": "07336061",
      "j": 2023,
      "n": "medard",
      "dn": "Medard",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336062",
      "j": 2023,
      "n": "merzweiler",
      "dn": "Merzweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336064",
      "j": 2023,
      "n": "nanzdietschweiler",
      "dn": "Nanzdietschweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336065",
      "j": 2023,
      "n": "nerzweiler",
      "dn": "Nerzweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336066",
      "j": 2023,
      "n": "neunkirchen am potzberg",
      "dn": "Neunkirchen am Potzberg",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07336067",
      "j": 2023,
      "n": "niederalben",
      "dn": "Niederalben",
      "bl": 7,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "07336068",
      "j": 2023,
      "n": "niederstaufenbach",
      "dn": "Niederstaufenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336069",
      "j": 2023,
      "n": "nußbach",
      "dn": "Nußbach",
      "bl": 7,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "07336070",
      "j": 2023,
      "n": "oberalben",
      "dn": "Oberalben",
      "bl": 7,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "07336071",
      "j": 2023,
      "n": "oberstaufenbach",
      "dn": "Oberstaufenbach",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07336072",
      "j": 2023,
      "n": "oberweiler im tal",
      "dn": "Oberweiler im Tal",
      "bl": 7,
      "hs": 545.0,
      "mns": 1
    },
    {
      "a": "07336073",
      "j": 2023,
      "n": "oberweiler-tiefenbach",
      "dn": "Oberweiler-Tiefenbach",
      "bl": 7,
      "hs": 505.0,
      "mns": 1
    },
    {
      "a": "07336074",
      "j": 2023,
      "n": "odenbach",
      "dn": "Odenbach",
      "bl": 7,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "07336075",
      "j": 2023,
      "n": "offenbach-hundheim",
      "dn": "Offenbach-Hundheim",
      "bl": 7,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "07336076",
      "j": 2023,
      "n": "ohmbach",
      "dn": "Ohmbach",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07336077",
      "j": 2023,
      "n": "pfeffelbach",
      "dn": "Pfeffelbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336079",
      "j": 2023,
      "n": "rammelsbach",
      "dn": "Rammelsbach",
      "bl": 7,
      "hs": 485.0,
      "mns": 1
    },
    {
      "a": "07336081",
      "j": 2023,
      "n": "rathsweiler",
      "dn": "Rathsweiler",
      "bl": 7,
      "hs": 495.0,
      "mns": 1
    },
    {
      "a": "07336082",
      "j": 2023,
      "n": "rehweiler",
      "dn": "Rehweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336084",
      "j": 2023,
      "n": "reichweiler",
      "dn": "Reichweiler",
      "bl": 7,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "07336085",
      "j": 2023,
      "n": "reipoltskirchen",
      "dn": "Reipoltskirchen",
      "bl": 7,
      "hs": 495.0,
      "mns": 1
    },
    {
      "a": "07336086",
      "j": 2023,
      "n": "relsberg",
      "dn": "Relsberg",
      "bl": 7,
      "hs": 540.0,
      "mns": 1
    },
    {
      "a": "07336087",
      "j": 2023,
      "n": "rothselberg",
      "dn": "Rothselberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336088",
      "j": 2023,
      "n": "ruthweiler",
      "dn": "Ruthweiler",
      "bl": 7,
      "hs": 492.0,
      "mns": 1
    },
    {
      "a": "07336089",
      "j": 2023,
      "n": "rutsweiler am glan",
      "dn": "Rutsweiler am Glan",
      "bl": 7,
      "hs": 485.0,
      "mns": 1
    },
    {
      "a": "07336090",
      "j": 2023,
      "n": "rutsweiler an der lauter",
      "dn": "Rutsweiler an der Lauter",
      "bl": 7,
      "hs": 515.0,
      "mns": 1
    },
    {
      "a": "07336091",
      "j": 2023,
      "n": "schellweiler",
      "dn": "Schellweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336092",
      "j": 2023,
      "n": "schönenberg-kübelberg",
      "dn": "Schönenberg-Kübelberg",
      "bl": 7,
      "hs": 485.0,
      "mns": 1
    },
    {
      "a": "07336094",
      "j": 2023,
      "n": "selchenbach",
      "dn": "Selchenbach",
      "bl": 7,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "07336095",
      "j": 2023,
      "n": "sankt julian",
      "dn": "Sankt Julian",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07336096",
      "j": 2023,
      "n": "steinbach am glan",
      "dn": "Steinbach am Glan",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336097",
      "j": 2023,
      "n": "thallichtenberg",
      "dn": "Thallichtenberg",
      "bl": 7,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "07336098",
      "j": 2023,
      "n": "theisbergstegen",
      "dn": "Theisbergstegen",
      "bl": 7,
      "hs": 495.0,
      "mns": 1
    },
    {
      "a": "07336099",
      "j": 2023,
      "n": "ulmet",
      "dn": "Ulmet",
      "bl": 7,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "07336100",
      "j": 2023,
      "n": "unterjeckenbach",
      "dn": "Unterjeckenbach",
      "bl": 7,
      "hs": 515.0,
      "mns": 1
    },
    {
      "a": "07336101",
      "j": 2023,
      "n": "wahnwegen",
      "dn": "Wahnwegen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336102",
      "j": 2023,
      "n": "waldmohr",
      "dn": "Waldmohr",
      "bl": 7,
      "hs": 530.0,
      "mns": 1
    },
    {
      "a": "07336103",
      "j": 2023,
      "n": "welchweiler",
      "dn": "Welchweiler",
      "bl": 7,
      "hs": 485.0,
      "mns": 1
    },
    {
      "a": "07336104",
      "j": 2023,
      "n": "wiesweiler",
      "dn": "Wiesweiler",
      "bl": 7,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "07336105",
      "j": 2023,
      "n": "wolfstein",
      "dn": "Wolfstein",
      "bl": 7,
      "hs": 615.0,
      "mns": 1
    },
    {
      "a": "07336106",
      "j": 2023,
      "n": "bedesbach",
      "dn": "Bedesbach",
      "bl": 7,
      "hs": 476.0,
      "mns": 1
    },
    {
      "a": "07336107",
      "j": 2023,
      "n": "matzenbach",
      "dn": "Matzenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07336501",
      "j": 2023,
      "n": "quirnbach",
      "dn": "Quirnbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07337001",
      "j": 2023,
      "n": "albersweiler",
      "dn": "Albersweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337002",
      "j": 2023,
      "n": "altdorf (pfalz)",
      "dn": "Altdorf (Pfalz)",
      "bl": 7,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "07337005",
      "j": 2023,
      "n": "bad bergzabern",
      "dn": "Bad Bergzabern",
      "bl": 7,
      "hs": 485.0,
      "mns": 2
    },
    {
      "a": "07337006",
      "j": 2023,
      "n": "barbelroth",
      "dn": "Barbelroth",
      "bl": 7,
      "hs": 365.0,
      "mns": 2
    },
    {
      "a": "07337007",
      "j": 2023,
      "n": "billigheim-ingenheim",
      "dn": "Billigheim-Ingenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337008",
      "j": 2023,
      "n": "birkenhördt",
      "dn": "Birkenhördt",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337009",
      "j": 2023,
      "n": "birkweiler",
      "dn": "Birkweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337011",
      "j": 2023,
      "n": "böbingen",
      "dn": "Böbingen",
      "bl": 7,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "07337012",
      "j": 2023,
      "n": "böchingen",
      "dn": "Böchingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337013",
      "j": 2023,
      "n": "böllenborn",
      "dn": "Böllenborn",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337014",
      "j": 2023,
      "n": "bornheim",
      "dn": "Bornheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337015",
      "j": 2023,
      "n": "burrweiler",
      "dn": "Burrweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337017",
      "j": 2023,
      "n": "dernbach",
      "dn": "Dernbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337018",
      "j": 2023,
      "n": "dierbach",
      "dn": "Dierbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337019",
      "j": 2023,
      "n": "dörrenbach",
      "dn": "Dörrenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337020",
      "j": 2023,
      "n": "edenkoben",
      "dn": "Edenkoben",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337021",
      "j": 2023,
      "n": "edesheim",
      "dn": "Edesheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337022",
      "j": 2023,
      "n": "eschbach",
      "dn": "Eschbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337023",
      "j": 2023,
      "n": "essingen",
      "dn": "Essingen",
      "bl": 7,
      "hs": 475.0,
      "mns": 2
    },
    {
      "a": "07337024",
      "j": 2023,
      "n": "eußerthal",
      "dn": "Eußerthal",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337025",
      "j": 2023,
      "n": "flemlingen",
      "dn": "Flemlingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337026",
      "j": 2023,
      "n": "frankweiler",
      "dn": "Frankweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337027",
      "j": 2023,
      "n": "freimersheim",
      "dn": "Freimersheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337028",
      "j": 2023,
      "n": "gleisweiler",
      "dn": "Gleisweiler",
      "bl": 7,
      "hs": 490.0,
      "mns": 2
    },
    {
      "a": "07337029",
      "j": 2023,
      "n": "gleiszellen-gleishorbach",
      "dn": "Gleiszellen-Gleishorbach",
      "bl": 7,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "07337031",
      "j": 2023,
      "n": "göcklingen",
      "dn": "Göcklingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337032",
      "j": 2023,
      "n": "gommersheim",
      "dn": "Gommersheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337033",
      "j": 2023,
      "n": "gossersweiler-stein",
      "dn": "Gossersweiler-Stein",
      "bl": 7,
      "hs": 365.0,
      "mns": 2
    },
    {
      "a": "07337035",
      "j": 2023,
      "n": "großfischlingen",
      "dn": "Großfischlingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337036",
      "j": 2023,
      "n": "hainfeld",
      "dn": "Hainfeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337037",
      "j": 2023,
      "n": "hergersweiler",
      "dn": "Hergersweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337038",
      "j": 2023,
      "n": "herxheim bei landau/pfalz",
      "dn": "Herxheim bei Landau/Pfalz",
      "bl": 7,
      "hs": 485.0,
      "mns": 3
    },
    {
      "a": "07337039",
      "j": 2023,
      "n": "herxheimweyher",
      "dn": "Herxheimweyher",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337040",
      "j": 2023,
      "n": "heuchelheim-klingen",
      "dn": "Heuchelheim-Klingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337041",
      "j": 2023,
      "n": "hochstadt",
      "dn": "Hochstadt",
      "bl": 7,
      "hs": 480.0,
      "mns": 2
    },
    {
      "a": "07337042",
      "j": 2023,
      "n": "ilbesheim bei landau in der pfalz",
      "dn": "Ilbesheim bei Landau in der Pfalz",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337043",
      "j": 2023,
      "n": "impflingen",
      "dn": "Impflingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337044",
      "j": 2023,
      "n": "insheim",
      "dn": "Insheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337045",
      "j": 2023,
      "n": "kapellen-drusweiler",
      "dn": "Kapellen-Drusweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337046",
      "j": 2023,
      "n": "kapsweyer",
      "dn": "Kapsweyer",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337047",
      "j": 2023,
      "n": "kirrweiler",
      "dn": "Kirrweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337048",
      "j": 2023,
      "n": "kleinfischlingen",
      "dn": "Kleinfischlingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337049",
      "j": 2023,
      "n": "klingenmünster",
      "dn": "Klingenmünster",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337050",
      "j": 2023,
      "n": "knöringen",
      "dn": "Knöringen",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337051",
      "j": 2023,
      "n": "leinsweiler",
      "dn": "Leinsweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337052",
      "j": 2023,
      "n": "maikammer",
      "dn": "Maikammer",
      "bl": 7,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "07337054",
      "j": 2023,
      "n": "münchweiler am klingbach",
      "dn": "Münchweiler am Klingbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337055",
      "j": 2023,
      "n": "niederhorbach",
      "dn": "Niederhorbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337056",
      "j": 2023,
      "n": "niederotterbach",
      "dn": "Niederotterbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337058",
      "j": 2023,
      "n": "oberhausen",
      "dn": "Oberhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337059",
      "j": 2023,
      "n": "oberotterbach",
      "dn": "Oberotterbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337060",
      "j": 2023,
      "n": "oberschlettenbach",
      "dn": "Oberschlettenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337061",
      "j": 2023,
      "n": "offenbach an der queich",
      "dn": "Offenbach an der Queich",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337062",
      "j": 2023,
      "n": "pleisweiler-oberhofen",
      "dn": "Pleisweiler-Oberhofen",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337064",
      "j": 2023,
      "n": "ramberg",
      "dn": "Ramberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337065",
      "j": 2023,
      "n": "ranschbach",
      "dn": "Ranschbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337066",
      "j": 2023,
      "n": "rhodt unter rietburg",
      "dn": "Rhodt unter Rietburg",
      "bl": 7,
      "hs": 480.0,
      "mns": 2
    },
    {
      "a": "07337067",
      "j": 2023,
      "n": "rinnthal",
      "dn": "Rinnthal",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337068",
      "j": 2023,
      "n": "rohrbach",
      "dn": "Rohrbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337069",
      "j": 2023,
      "n": "roschbach",
      "dn": "Roschbach",
      "bl": 7,
      "hs": 480.0,
      "mns": 2
    },
    {
      "a": "07337070",
      "j": 2023,
      "n": "sankt martin",
      "dn": "Sankt Martin",
      "bl": 7,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "07337071",
      "j": 2023,
      "n": "schweigen-rechtenbach",
      "dn": "Schweigen-Rechtenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337072",
      "j": 2023,
      "n": "schweighofen",
      "dn": "Schweighofen",
      "bl": 7,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "07337073",
      "j": 2023,
      "n": "siebeldingen",
      "dn": "Siebeldingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337074",
      "j": 2023,
      "n": "silz",
      "dn": "Silz",
      "bl": 7,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "07337076",
      "j": 2023,
      "n": "steinfeld",
      "dn": "Steinfeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337077",
      "j": 2023,
      "n": "venningen",
      "dn": "Venningen",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337078",
      "j": 2023,
      "n": "völkersweiler",
      "dn": "Völkersweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337079",
      "j": 2023,
      "n": "vorderweidenthal",
      "dn": "Vorderweidenthal",
      "bl": 7,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "07337080",
      "j": 2023,
      "n": "waldhambach",
      "dn": "Waldhambach",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337081",
      "j": 2023,
      "n": "waldrohrbach",
      "dn": "Waldrohrbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337082",
      "j": 2023,
      "n": "walsheim",
      "dn": "Walsheim",
      "bl": 7,
      "hs": 365.0,
      "mns": 2
    },
    {
      "a": "07337083",
      "j": 2023,
      "n": "wernersberg",
      "dn": "Wernersberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07337084",
      "j": 2023,
      "n": "weyher in der pfalz",
      "dn": "Weyher in der Pfalz",
      "bl": 7,
      "hs": 480.0,
      "mns": 2
    },
    {
      "a": "07337501",
      "j": 2023,
      "n": "annweiler am trifels",
      "dn": "Annweiler am Trifels",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07338001",
      "j": 2023,
      "n": "altrip",
      "dn": "Altrip",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07338002",
      "j": 2023,
      "n": "beindersheim",
      "dn": "Beindersheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07338003",
      "j": 2023,
      "n": "birkenheide",
      "dn": "Birkenheide",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07338004",
      "j": 2023,
      "n": "bobenheim-roxheim",
      "dn": "Bobenheim-Roxheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07338005",
      "j": 2023,
      "n": "böhl-iggelheim",
      "dn": "Böhl-Iggelheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "07338006",
      "j": 2023,
      "n": "dannstadt-schauernheim",
      "dn": "Dannstadt-Schauernheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07338007",
      "j": 2023,
      "n": "dudenhofen",
      "dn": "Dudenhofen",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07338008",
      "j": 2023,
      "n": "fußgönheim",
      "dn": "Fußgönheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07338009",
      "j": 2023,
      "n": "großniedesheim",
      "dn": "Großniedesheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07338010",
      "j": 2023,
      "n": "hanhofen",
      "dn": "Hanhofen",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07338011",
      "j": 2023,
      "n": "harthausen",
      "dn": "Harthausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07338012",
      "j": 2023,
      "n": "heßheim",
      "dn": "Heßheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07338013",
      "j": 2023,
      "n": "heuchelheim bei frankenthal",
      "dn": "Heuchelheim bei Frankenthal",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07338014",
      "j": 2023,
      "n": "hochdorf-assenheim",
      "dn": "Hochdorf-Assenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07338015",
      "j": 2023,
      "n": "kleinniedesheim",
      "dn": "Kleinniedesheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07338016",
      "j": 2023,
      "n": "lambsheim",
      "dn": "Lambsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07338017",
      "j": 2023,
      "n": "limburgerhof",
      "dn": "Limburgerhof",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07338018",
      "j": 2023,
      "n": "maxdorf",
      "dn": "Maxdorf",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07338019",
      "j": 2023,
      "n": "mutterstadt",
      "dn": "Mutterstadt",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07338020",
      "j": 2025,
      "n": "neuhofen",
      "dn": "Neuhofen",
      "bl": 7,
      "hs": 525.0,
      "mns": 3
    },
    {
      "a": "07338021",
      "j": 2023,
      "n": "otterstadt",
      "dn": "Otterstadt",
      "bl": 7,
      "hs": 365.0,
      "mns": 3
    },
    {
      "a": "07338022",
      "j": 2023,
      "n": "rödersheim-gronau",
      "dn": "Rödersheim-Gronau",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07338023",
      "j": 2023,
      "n": "römerberg",
      "dn": "Römerberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07338025",
      "j": 2023,
      "n": "schifferstadt",
      "dn": "Schifferstadt",
      "bl": 7,
      "hs": 490.0,
      "mns": 3
    },
    {
      "a": "07338026",
      "j": 2023,
      "n": "waldsee",
      "dn": "Waldsee",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339001",
      "j": 2023,
      "n": "appenheim",
      "dn": "Appenheim",
      "bl": 7,
      "hs": 365.0,
      "mns": 3
    },
    {
      "a": "07339002",
      "j": 2023,
      "n": "aspisheim",
      "dn": "Aspisheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339003",
      "j": 2023,
      "n": "bacharach",
      "dn": "Bacharach",
      "bl": 7,
      "hs": 580.0,
      "mns": 3
    },
    {
      "a": "07339004",
      "j": 2023,
      "n": "badenheim",
      "dn": "Badenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339005",
      "j": 2023,
      "n": "bingen am rhein",
      "dn": "Bingen am Rhein",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339006",
      "j": 2023,
      "n": "bodenheim",
      "dn": "Bodenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339007",
      "j": 2023,
      "n": "breitscheid",
      "dn": "Breitscheid",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339008",
      "j": 2023,
      "n": "bubenheim",
      "dn": "Bubenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339009",
      "j": 2023,
      "n": "budenheim",
      "dn": "Budenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339010",
      "j": 2023,
      "n": "dalheim",
      "dn": "Dalheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339011",
      "j": 2023,
      "n": "dexheim",
      "dn": "Dexheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339012",
      "j": 2023,
      "n": "dienheim",
      "dn": "Dienheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339013",
      "j": 2023,
      "n": "dolgesheim",
      "dn": "Dolgesheim",
      "bl": 7,
      "hs": 470.0,
      "mns": 3
    },
    {
      "a": "07339015",
      "j": 2023,
      "n": "eimsheim",
      "dn": "Eimsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339016",
      "j": 2023,
      "n": "engelstadt",
      "dn": "Engelstadt",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339017",
      "j": 2023,
      "n": "essenheim",
      "dn": "Essenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339018",
      "j": 2023,
      "n": "friesenheim",
      "dn": "Friesenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339019",
      "j": 2023,
      "n": "gau-algesheim",
      "dn": "Gau-Algesheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339020",
      "j": 2023,
      "n": "gau-bischofsheim",
      "dn": "Gau-Bischofsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339021",
      "j": 2023,
      "n": "gensingen",
      "dn": "Gensingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339022",
      "j": 2023,
      "n": "grolsheim",
      "dn": "Grolsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339024",
      "j": 2023,
      "n": "guntersblum",
      "dn": "Guntersblum",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339025",
      "j": 2023,
      "n": "hahnheim",
      "dn": "Hahnheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339026",
      "j": 2023,
      "n": "harxheim",
      "dn": "Harxheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339028",
      "j": 2023,
      "n": "hillesheim",
      "dn": "Hillesheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339029",
      "j": 2023,
      "n": "horrweiler",
      "dn": "Horrweiler",
      "bl": 7,
      "hs": 475.0,
      "mns": 3
    },
    {
      "a": "07339030",
      "j": 2023,
      "n": "ingelheim am rhein",
      "dn": "Ingelheim am Rhein",
      "bl": 7,
      "hs": 80.0,
      "mns": 4
    },
    {
      "a": "07339031",
      "j": 2023,
      "n": "jugenheim in rheinhessen",
      "dn": "Jugenheim in Rheinhessen",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339032",
      "j": 2023,
      "n": "klein-winternheim",
      "dn": "Klein-Winternheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339033",
      "j": 2023,
      "n": "köngernheim",
      "dn": "Köngernheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339034",
      "j": 2023,
      "n": "lörzweiler",
      "dn": "Lörzweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339035",
      "j": 2023,
      "n": "ludwigshöhe",
      "dn": "Ludwigshöhe",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339036",
      "j": 2023,
      "n": "manubach",
      "dn": "Manubach",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339037",
      "j": 2023,
      "n": "mommenheim",
      "dn": "Mommenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339038",
      "j": 2023,
      "n": "münster-sarmsheim",
      "dn": "Münster-Sarmsheim",
      "bl": 7,
      "hs": 480.0,
      "mns": 3
    },
    {
      "a": "07339039",
      "j": 2023,
      "n": "nackenheim",
      "dn": "Nackenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339040",
      "j": 2023,
      "n": "niederheimbach",
      "dn": "Niederheimbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339041",
      "j": 2023,
      "n": "nieder-hilbersheim",
      "dn": "Nieder-Hilbersheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339042",
      "j": 2023,
      "n": "nieder-olm",
      "dn": "Nieder-Olm",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339043",
      "j": 2023,
      "n": "nierstein",
      "dn": "Nierstein",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339044",
      "j": 2023,
      "n": "oberdiebach",
      "dn": "Oberdiebach",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339045",
      "j": 2023,
      "n": "oberheimbach",
      "dn": "Oberheimbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339046",
      "j": 2023,
      "n": "ober-hilbersheim",
      "dn": "Ober-Hilbersheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339047",
      "j": 2023,
      "n": "ober-olm",
      "dn": "Ober-Olm",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339048",
      "j": 2023,
      "n": "ockenheim",
      "dn": "Ockenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339049",
      "j": 2023,
      "n": "oppenheim",
      "dn": "Oppenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339050",
      "j": 2023,
      "n": "sankt johann",
      "dn": "Sankt Johann",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339051",
      "j": 2023,
      "n": "schwabenheim an der selz",
      "dn": "Schwabenheim an der Selz",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339053",
      "j": 2023,
      "n": "selzen",
      "dn": "Selzen",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339054",
      "j": 2023,
      "n": "sörgenloch",
      "dn": "Sörgenloch",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339056",
      "j": 2023,
      "n": "sprendlingen",
      "dn": "Sprendlingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339057",
      "j": 2023,
      "n": "stadecken-elsheim",
      "dn": "Stadecken-Elsheim",
      "bl": 7,
      "hs": 475.0,
      "mns": 3
    },
    {
      "a": "07339058",
      "j": 2023,
      "n": "trechtingshausen",
      "dn": "Trechtingshausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339059",
      "j": 2023,
      "n": "uelversheim",
      "dn": "Uelversheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339060",
      "j": 2023,
      "n": "undenheim",
      "dn": "Undenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339062",
      "j": 2023,
      "n": "waldalgesheim",
      "dn": "Waldalgesheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339063",
      "j": 2023,
      "n": "weiler bei bingen",
      "dn": "Weiler bei Bingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339064",
      "j": 2023,
      "n": "weinolsheim",
      "dn": "Weinolsheim",
      "bl": 7,
      "hs": 475.0,
      "mns": 3
    },
    {
      "a": "07339065",
      "j": 2023,
      "n": "welgesheim",
      "dn": "Welgesheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339066",
      "j": 2024,
      "n": "wintersheim",
      "dn": "Wintersheim",
      "bl": 7,
      "hs": 100.0,
      "mns": 3
    },
    {
      "a": "07339067",
      "j": 2023,
      "n": "zornheim",
      "dn": "Zornheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339068",
      "j": 2023,
      "n": "zotzenheim",
      "dn": "Zotzenheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339201",
      "j": 2023,
      "n": "dorn-dürkheim",
      "dn": "Dorn-Dürkheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07339202",
      "j": 2023,
      "n": "wolfsheim",
      "dn": "Wolfsheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 3
    },
    {
      "a": "07340001",
      "j": 2023,
      "n": "bobenthal",
      "dn": "Bobenthal",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340002",
      "j": 2023,
      "n": "busenberg",
      "dn": "Busenberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340003",
      "j": 2023,
      "n": "clausen",
      "dn": "Clausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340004",
      "j": 2023,
      "n": "dahn",
      "dn": "Dahn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340005",
      "j": 2023,
      "n": "darstein",
      "dn": "Darstein",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340006",
      "j": 2023,
      "n": "dimbach",
      "dn": "Dimbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340007",
      "j": 2023,
      "n": "donsieders",
      "dn": "Donsieders",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340008",
      "j": 2023,
      "n": "eppenbrunn",
      "dn": "Eppenbrunn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340009",
      "j": 2023,
      "n": "erfweiler",
      "dn": "Erfweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340010",
      "j": 2023,
      "n": "erlenbach bei dahn",
      "dn": "Erlenbach bei Dahn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340011",
      "j": 2023,
      "n": "fischbach bei dahn",
      "dn": "Fischbach bei Dahn",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340012",
      "j": 2023,
      "n": "geiselberg",
      "dn": "Geiselberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340014",
      "j": 2023,
      "n": "hauenstein",
      "dn": "Hauenstein",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340015",
      "j": 2023,
      "n": "heltersberg",
      "dn": "Heltersberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340016",
      "j": 2023,
      "n": "hermersberg",
      "dn": "Hermersberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340017",
      "j": 2023,
      "n": "herschberg",
      "dn": "Herschberg",
      "bl": 7,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "07340018",
      "j": 2023,
      "n": "hettenhausen",
      "dn": "Hettenhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340019",
      "j": 2023,
      "n": "hilst",
      "dn": "Hilst",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340020",
      "j": 2023,
      "n": "hinterweidenthal",
      "dn": "Hinterweidenthal",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340021",
      "j": 2023,
      "n": "hirschthal",
      "dn": "Hirschthal",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340022",
      "j": 2023,
      "n": "höheinöd",
      "dn": "Höheinöd",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340023",
      "j": 2023,
      "n": "höheischweiler",
      "dn": "Höheischweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340024",
      "j": 2023,
      "n": "höhfröschen",
      "dn": "Höhfröschen",
      "bl": 7,
      "hs": 387.0,
      "mns": 1
    },
    {
      "a": "07340025",
      "j": 2023,
      "n": "horbach",
      "dn": "Horbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340026",
      "j": 2023,
      "n": "kröppen",
      "dn": "Kröppen",
      "bl": 7,
      "hs": 485.0,
      "mns": 1
    },
    {
      "a": "07340027",
      "j": 2023,
      "n": "leimen",
      "dn": "Leimen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340028",
      "j": 2023,
      "n": "lemberg",
      "dn": "Lemberg",
      "bl": 7,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "07340029",
      "j": 2023,
      "n": "ludwigswinkel",
      "dn": "Ludwigswinkel",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340030",
      "j": 2023,
      "n": "lug",
      "dn": "Lug",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340031",
      "j": 2023,
      "n": "merzalben",
      "dn": "Merzalben",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340032",
      "j": 2023,
      "n": "münchweiler an der rodalb",
      "dn": "Münchweiler an der Rodalb",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340033",
      "j": 2023,
      "n": "niederschlettenbach",
      "dn": "Niederschlettenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340034",
      "j": 2023,
      "n": "nothweiler",
      "dn": "Nothweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340035",
      "j": 2023,
      "n": "nünschweiler",
      "dn": "Nünschweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340036",
      "j": 2023,
      "n": "obersimten",
      "dn": "Obersimten",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340037",
      "j": 2023,
      "n": "petersberg",
      "dn": "Petersberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340038",
      "j": 2023,
      "n": "rodalben",
      "dn": "Rodalben",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340039",
      "j": 2023,
      "n": "rumbach",
      "dn": "Rumbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340040",
      "j": 2023,
      "n": "ruppertsweiler",
      "dn": "Ruppertsweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340041",
      "j": 2023,
      "n": "saalstadt",
      "dn": "Saalstadt",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340042",
      "j": 2023,
      "n": "schauerberg",
      "dn": "Schauerberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340043",
      "j": 2023,
      "n": "schindhard",
      "dn": "Schindhard",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340044",
      "j": 2023,
      "n": "schmalenberg",
      "dn": "Schmalenberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340045",
      "j": 2023,
      "n": "schönau",
      "dn": "Schönau",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340047",
      "j": 2023,
      "n": "schwanheim",
      "dn": "Schwanheim",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340048",
      "j": 2023,
      "n": "schweix",
      "dn": "Schweix",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340049",
      "j": 2023,
      "n": "spirkelbach",
      "dn": "Spirkelbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340050",
      "j": 2023,
      "n": "steinalben",
      "dn": "Steinalben",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340051",
      "j": 2023,
      "n": "thaleischweiler-fröschen",
      "dn": "Thaleischweiler-Fröschen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340052",
      "j": 2023,
      "n": "trulben",
      "dn": "Trulben",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340053",
      "j": 2023,
      "n": "vinningen",
      "dn": "Vinningen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340054",
      "j": 2023,
      "n": "waldfischbach-burgalben",
      "dn": "Waldfischbach-Burgalben",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340055",
      "j": 2023,
      "n": "weselberg",
      "dn": "Weselberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340057",
      "j": 2023,
      "n": "wilgartswiesen",
      "dn": "Wilgartswiesen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340201",
      "j": 2023,
      "n": "althornbach",
      "dn": "Althornbach",
      "bl": 7,
      "hs": 511.0,
      "mns": 1
    },
    {
      "a": "07340202",
      "j": 2023,
      "n": "battweiler",
      "dn": "Battweiler",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340203",
      "j": 2023,
      "n": "bechhofen",
      "dn": "Bechhofen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340204",
      "j": 2023,
      "n": "biedershausen",
      "dn": "Biedershausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340205",
      "j": 2023,
      "n": "bottenbach",
      "dn": "Bottenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340206",
      "j": 2023,
      "n": "contwig",
      "dn": "Contwig",
      "bl": 7,
      "hs": 479.0,
      "mns": 1
    },
    {
      "a": "07340207",
      "j": 2023,
      "n": "dellfeld",
      "dn": "Dellfeld",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340208",
      "j": 2023,
      "n": "dietrichingen",
      "dn": "Dietrichingen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340209",
      "j": 2023,
      "n": "großbundenbach",
      "dn": "Großbundenbach",
      "bl": 7,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "07340210",
      "j": 2023,
      "n": "großsteinhausen",
      "dn": "Großsteinhausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340211",
      "j": 2023,
      "n": "hornbach",
      "dn": "Hornbach",
      "bl": 7,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "07340212",
      "j": 2023,
      "n": "käshofen",
      "dn": "Käshofen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340213",
      "j": 2023,
      "n": "kleinbundenbach",
      "dn": "Kleinbundenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340214",
      "j": 2023,
      "n": "kleinsteinhausen",
      "dn": "Kleinsteinhausen",
      "bl": 7,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "07340215",
      "j": 2023,
      "n": "knopp-labach",
      "dn": "Knopp-Labach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340216",
      "j": 2023,
      "n": "krähenberg",
      "dn": "Krähenberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340217",
      "j": 2023,
      "n": "maßweiler",
      "dn": "Maßweiler",
      "bl": 7,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "07340218",
      "j": 2023,
      "n": "mauschbach",
      "dn": "Mauschbach",
      "bl": 7,
      "hs": 278.0,
      "mns": 1
    },
    {
      "a": "07340219",
      "j": 2023,
      "n": "obernheim-kirchenarnbach",
      "dn": "Obernheim-Kirchenarnbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340220",
      "j": 2023,
      "n": "reifenberg",
      "dn": "Reifenberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340221",
      "j": 2023,
      "n": "riedelberg",
      "dn": "Riedelberg",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340222",
      "j": 2023,
      "n": "rieschweiler-mühlbach",
      "dn": "Rieschweiler-Mühlbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340223",
      "j": 2023,
      "n": "rosenkopf",
      "dn": "Rosenkopf",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340224",
      "j": 2023,
      "n": "schmitshausen",
      "dn": "Schmitshausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340225",
      "j": 2023,
      "n": "wallhalben",
      "dn": "Wallhalben",
      "bl": 7,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "07340226",
      "j": 2023,
      "n": "walshausen",
      "dn": "Walshausen",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340227",
      "j": 2023,
      "n": "wiesbach",
      "dn": "Wiesbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340228",
      "j": 2023,
      "n": "winterbach",
      "dn": "Winterbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340501",
      "j": 2023,
      "n": "bruchweiler-bärenbach",
      "dn": "Bruchweiler-Bärenbach",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "07340502",
      "j": 2023,
      "n": "bundenthal",
      "dn": "Bundenthal",
      "bl": 7,
      "hs": 465.0,
      "mns": 1
    }
  ],
  "8": [
    {
      "a": "08111000",
      "j": 2025,
      "n": "stuttgart",
      "dn": "Stuttgart",
      "bl": 8,
      "hs": 160.0,
      "mns": 6
    },
    {
      "a": "08115001",
      "j": 2025,
      "n": "aidlingen",
      "dn": "Aidlingen",
      "bl": 8,
      "hs": 195.0,
      "mns": 4
    },
    {
      "a": "08115002",
      "j": 2023,
      "n": "altdorf",
      "dn": "Altdorf",
      "bl": 8,
      "hs": 370.0,
      "mns": 4
    },
    {
      "a": "08115003",
      "j": 2025,
      "n": "böblingen",
      "dn": "Böblingen",
      "bl": 8,
      "hs": 198.0,
      "mns": 6
    },
    {
      "a": "08115004",
      "j": 2023,
      "n": "bondorf",
      "dn": "Bondorf",
      "bl": 8,
      "hs": 340.0,
      "mns": 4
    },
    {
      "a": "08115010",
      "j": 2023,
      "n": "deckenpfronn",
      "dn": "Deckenpfronn",
      "bl": 8,
      "hs": 370.0,
      "mns": 4
    },
    {
      "a": "08115013",
      "j": 2023,
      "n": "ehningen",
      "dn": "Ehningen",
      "bl": 8,
      "hs": 330.0,
      "mns": 4
    },
    {
      "a": "08115015",
      "j": 2023,
      "n": "gärtringen",
      "dn": "Gärtringen",
      "bl": 8,
      "hs": 350.0,
      "mns": 5
    },
    {
      "a": "08115016",
      "j": 2023,
      "n": "gäufelden",
      "dn": "Gäufelden",
      "bl": 8,
      "hs": 370.0,
      "mns": 4
    },
    {
      "a": "08115021",
      "j": 2023,
      "n": "herrenberg",
      "dn": "Herrenberg",
      "bl": 8,
      "hs": 450.0,
      "mns": 5
    },
    {
      "a": "08115022",
      "j": 2023,
      "n": "hildrizhausen",
      "dn": "Hildrizhausen",
      "bl": 8,
      "hs": 370.0,
      "mns": 4
    },
    {
      "a": "08115024",
      "j": 2023,
      "n": "holzgerlingen",
      "dn": "Holzgerlingen",
      "bl": 8,
      "hs": 310.0,
      "mns": 6
    },
    {
      "a": "08115028",
      "j": 2023,
      "n": "leonberg",
      "dn": "Leonberg",
      "bl": 8,
      "hs": 445.0,
      "mns": 6
    },
    {
      "a": "08115029",
      "j": 2023,
      "n": "magstadt",
      "dn": "Magstadt",
      "bl": 8,
      "hs": 290.0,
      "mns": 4
    },
    {
      "a": "08115034",
      "j": 2023,
      "n": "mötzingen",
      "dn": "Mötzingen",
      "bl": 8,
      "hs": 385.0,
      "mns": 4
    },
    {
      "a": "08115037",
      "j": 2023,
      "n": "nufringen",
      "dn": "Nufringen",
      "bl": 8,
      "hs": 360.0,
      "mns": 4
    },
    {
      "a": "08115041",
      "j": 2023,
      "n": "renningen",
      "dn": "Renningen",
      "bl": 8,
      "hs": 400.0,
      "mns": 6
    },
    {
      "a": "08115042",
      "j": 2023,
      "n": "rutesheim",
      "dn": "Rutesheim",
      "bl": 8,
      "hs": 340.0,
      "mns": 5
    },
    {
      "a": "08115044",
      "j": 2023,
      "n": "schönaich",
      "dn": "Schönaich",
      "bl": 8,
      "hs": 360.0,
      "mns": 4
    },
    {
      "a": "08115045",
      "j": 2023,
      "n": "sindelfingen",
      "dn": "Sindelfingen",
      "bl": 8,
      "hs": 390.0,
      "mns": 5
    },
    {
      "a": "08115046",
      "j": 2023,
      "n": "steinenbronn",
      "dn": "Steinenbronn",
      "bl": 8,
      "hs": 360.0,
      "mns": 4
    },
    {
      "a": "08115048",
      "j": 2023,
      "n": "waldenbuch",
      "dn": "Waldenbuch",
      "bl": 8,
      "hs": 375.0,
      "mns": 4
    },
    {
      "a": "08115050",
      "j": 2023,
      "n": "weil der stadt",
      "dn": "Weil der Stadt",
      "bl": 8,
      "hs": 450.0,
      "mns": 5
    },
    {
      "a": "08115051",
      "j": 2023,
      "n": "weil im schönbuch",
      "dn": "Weil im Schönbuch",
      "bl": 8,
      "hs": 310.0,
      "mns": 4
    },
    {
      "a": "08115052",
      "j": 2023,
      "n": "weissach",
      "dn": "Weissach",
      "bl": 8,
      "hs": 340.0,
      "mns": 4
    },
    {
      "a": "08115053",
      "j": 2023,
      "n": "jettingen",
      "dn": "Jettingen",
      "bl": 8,
      "hs": 330.0,
      "mns": 4
    },
    {
      "a": "08115054",
      "j": 2023,
      "n": "grafenau",
      "dn": "Grafenau",
      "bl": 8,
      "hs": 420.0,
      "mns": 4
    },
    {
      "a": "08116004",
      "j": 2023,
      "n": "altbach",
      "dn": "Altbach",
      "bl": 8,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "08116005",
      "j": 2023,
      "n": "altdorf",
      "dn": "Altdorf",
      "bl": 8,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "08116006",
      "j": 2023,
      "n": "altenriet",
      "dn": "Altenriet",
      "bl": 8,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "08116007",
      "j": 2025,
      "n": "baltmannsweiler",
      "dn": "Baltmannsweiler",
      "bl": 8,
      "hs": 175.0,
      "mns": 3
    },
    {
      "a": "08116008",
      "j": 2023,
      "n": "bempflingen",
      "dn": "Bempflingen",
      "bl": 8,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "08116011",
      "j": 2023,
      "n": "beuren",
      "dn": "Beuren",
      "bl": 8,
      "hs": 420.0,
      "mns": 3
    },
    {
      "a": "08116012",
      "j": 2023,
      "n": "bissingen an der teck",
      "dn": "Bissingen an der Teck",
      "bl": 8,
      "hs": 420.0,
      "mns": 3
    },
    {
      "a": "08116014",
      "j": 2023,
      "n": "deizisau",
      "dn": "Deizisau",
      "bl": 8,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "08116015",
      "j": 2023,
      "n": "denkendorf",
      "dn": "Denkendorf",
      "bl": 8,
      "hs": 350.0,
      "mns": 5
    },
    {
      "a": "08116016",
      "j": 2023,
      "n": "dettingen unter teck",
      "dn": "Dettingen unter Teck",
      "bl": 8,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "08116018",
      "j": 2023,
      "n": "erkenbrechtsweiler",
      "dn": "Erkenbrechtsweiler",
      "bl": 8,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "08116019",
      "j": 2023,
      "n": "esslingen am neckar",
      "dn": "Esslingen am Neckar",
      "bl": 8,
      "hs": 458.0,
      "mns": 5
    },
    {
      "a": "08116020",
      "j": 2023,
      "n": "frickenhausen",
      "dn": "Frickenhausen",
      "bl": 8,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "08116022",
      "j": 2023,
      "n": "großbettlingen",
      "dn": "Großbettlingen",
      "bl": 8,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "08116027",
      "j": 2023,
      "n": "hochdorf",
      "dn": "Hochdorf",
      "bl": 8,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "08116029",
      "j": 2023,
      "n": "holzmaden",
      "dn": "Holzmaden",
      "bl": 8,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "08116033",
      "j": 2025,
      "n": "kirchheim unter teck",
      "dn": "Kirchheim unter Teck",
      "bl": 8,
      "hs": 203.0,
      "mns": 5
    },
    {
      "a": "08116035",
      "j": 2023,
      "n": "köngen",
      "dn": "Köngen",
      "bl": 8,
      "hs": 370.0,
      "mns": 5
    },
    {
      "a": "08116036",
      "j": 2023,
      "n": "kohlberg",
      "dn": "Kohlberg",
      "bl": 8,
      "hs": 410.0,
      "mns": 3
    },
    {
      "a": "08116037",
      "j": 2023,
      "n": "lichtenwald",
      "dn": "Lichtenwald",
      "bl": 8,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "08116041",
      "j": 2023,
      "n": "neckartailfingen",
      "dn": "Neckartailfingen",
      "bl": 8,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "08116042",
      "j": 2023,
      "n": "neckartenzlingen",
      "dn": "Neckartenzlingen",
      "bl": 8,
      "hs": 355.0,
      "mns": 3
    },
    {
      "a": "08116043",
      "j": 2023,
      "n": "neidlingen",
      "dn": "Neidlingen",
      "bl": 8,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "08116046",
      "j": 2023,
      "n": "neuffen",
      "dn": "Neuffen",
      "bl": 8,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "08116047",
      "j": 2025,
      "n": "neuhausen auf den fildern",
      "dn": "Neuhausen auf den Fildern",
      "bl": 8,
      "hs": 210.0,
      "mns": 5
    },
    {
      "a": "08116048",
      "j": 2023,
      "n": "notzingen",
      "dn": "Notzingen",
      "bl": 8,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "08116049",
      "j": 2025,
      "n": "nürtingen",
      "dn": "Nürtingen",
      "bl": 8,
      "hs": 211.0,
      "mns": 4
    },
    {
      "a": "08116050",
      "j": 2023,
      "n": "oberboihingen",
      "dn": "Oberboihingen",
      "bl": 8,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "08116053",
      "j": 2023,
      "n": "ohmden",
      "dn": "Ohmden",
      "bl": 8,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "08116054",
      "j": 2023,
      "n": "owen",
      "dn": "Owen",
      "bl": 8,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "08116056",
      "j": 2023,
      "n": "plochingen",
      "dn": "Plochingen",
      "bl": 8,
      "hs": 420.0,
      "mns": 4
    },
    {
      "a": "08116058",
      "j": 2023,
      "n": "reichenbach an der fils",
      "dn": "Reichenbach an der Fils",
      "bl": 8,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "08116063",
      "j": 2023,
      "n": "schlaitdorf",
      "dn": "Schlaitdorf",
      "bl": 8,
      "hs": 345.0,
      "mns": 3
    },
    {
      "a": "08116068",
      "j": 2023,
      "n": "unterensingen",
      "dn": "Unterensingen",
      "bl": 8,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "08116070",
      "j": 2023,
      "n": "weilheim an der teck",
      "dn": "Weilheim an der Teck",
      "bl": 8,
      "hs": 380.0,
      "mns": 4
    },
    {
      "a": "08116071",
      "j": 2023,
      "n": "wendlingen am neckar",
      "dn": "Wendlingen am Neckar",
      "bl": 8,
      "hs": 320.0,
      "mns": 5
    },
    {
      "a": "08116072",
      "j": 2023,
      "n": "wernau (neckar)",
      "dn": "Wernau (Neckar)",
      "bl": 8,
      "hs": 390.0,
      "mns": 5
    },
    {
      "a": "08116073",
      "j": 2023,
      "n": "wolfschlugen",
      "dn": "Wolfschlugen",
      "bl": 8,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "08116076",
      "j": 2023,
      "n": "aichwald",
      "dn": "Aichwald",
      "bl": 8,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "08116077",
      "j": 2025,
      "n": "filderstadt",
      "dn": "Filderstadt",
      "bl": 8,
      "hs": 195.0,
      "mns": 5
    },
    {
      "a": "08116078",
      "j": 2025,
      "n": "leinfelden-echterdingen",
      "dn": "Leinfelden-Echterdingen",
      "bl": 8,
      "hs": 174.0,
      "mns": 6
    },
    {
      "a": "08116079",
      "j": 2023,
      "n": "lenningen",
      "dn": "Lenningen",
      "bl": 8,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "08116080",
      "j": 2023,
      "n": "ostfildern",
      "dn": "Ostfildern",
      "bl": 8,
      "hs": 425.0,
      "mns": 5
    },
    {
      "a": "08116081",
      "j": 2023,
      "n": "aichtal",
      "dn": "Aichtal",
      "bl": 8,
      "hs": 370.0,
      "mns": 4
    },
    {
      "a": "08117001",
      "j": 2023,
      "n": "adelberg",
      "dn": "Adelberg",
      "bl": 8,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "08117002",
      "j": 2023,
      "n": "aichelberg",
      "dn": "Aichelberg",
      "bl": 8,
      "hs": 520.0,
      "mns": 2
    },
    {
      "a": "08117003",
      "j": 2023,
      "n": "albershausen",
      "dn": "Albershausen",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08117006",
      "j": 2023,
      "n": "bad ditzenbach",
      "dn": "Bad Ditzenbach",
      "bl": 8,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "08117007",
      "j": 2023,
      "n": "bad überkingen",
      "dn": "Bad Überkingen",
      "bl": 8,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "08117009",
      "j": 2023,
      "n": "birenbach",
      "dn": "Birenbach",
      "bl": 8,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "08117010",
      "j": 2023,
      "n": "böhmenkirch",
      "dn": "Böhmenkirch",
      "bl": 8,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "08117011",
      "j": 2023,
      "n": "börtlingen",
      "dn": "Börtlingen",
      "bl": 8,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "08117012",
      "j": 2023,
      "n": "bad boll",
      "dn": "Bad Boll",
      "bl": 8,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "08117014",
      "j": 2023,
      "n": "deggingen",
      "dn": "Deggingen",
      "bl": 8,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "08117015",
      "j": 2023,
      "n": "donzdorf",
      "dn": "Donzdorf",
      "bl": 8,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "08117016",
      "j": 2023,
      "n": "drackenstein",
      "dn": "Drackenstein",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08117017",
      "j": 2023,
      "n": "dürnau",
      "dn": "Dürnau",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08117018",
      "j": 2023,
      "n": "ebersbach an der fils",
      "dn": "Ebersbach an der Fils",
      "bl": 8,
      "hs": 480.0,
      "mns": 4
    },
    {
      "a": "08117019",
      "j": 2023,
      "n": "eislingen/fils",
      "dn": "Eislingen/Fils",
      "bl": 8,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "08117020",
      "j": 2023,
      "n": "eschenbach",
      "dn": "Eschenbach",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08117023",
      "j": 2023,
      "n": "gammelshausen",
      "dn": "Gammelshausen",
      "bl": 8,
      "hs": 490.0,
      "mns": 2
    },
    {
      "a": "08117024",
      "j": 2023,
      "n": "geislingen an der steige",
      "dn": "Geislingen an der Steige",
      "bl": 8,
      "hs": 445.0,
      "mns": 2
    },
    {
      "a": "08117025",
      "j": 2023,
      "n": "gingen an der fils",
      "dn": "Gingen an der Fils",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08117026",
      "j": 2023,
      "n": "göppingen",
      "dn": "Göppingen",
      "bl": 8,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "08117028",
      "j": 2023,
      "n": "gruibingen",
      "dn": "Gruibingen",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08117029",
      "j": 2023,
      "n": "hattenhofen",
      "dn": "Hattenhofen",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08117030",
      "j": 2023,
      "n": "heiningen",
      "dn": "Heiningen",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08117031",
      "j": 2023,
      "n": "hohenstadt",
      "dn": "Hohenstadt",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08117033",
      "j": 2023,
      "n": "kuchen",
      "dn": "Kuchen",
      "bl": 8,
      "hs": 325.0,
      "mns": 2
    },
    {
      "a": "08117035",
      "j": 2023,
      "n": "mühlhausen im täle",
      "dn": "Mühlhausen im Täle",
      "bl": 8,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "08117037",
      "j": 2023,
      "n": "ottenbach",
      "dn": "Ottenbach",
      "bl": 8,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "08117038",
      "j": 2023,
      "n": "rechberghausen",
      "dn": "Rechberghausen",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08117042",
      "j": 2023,
      "n": "salach",
      "dn": "Salach",
      "bl": 8,
      "hs": 415.0,
      "mns": 2
    },
    {
      "a": "08117043",
      "j": 2023,
      "n": "schlat",
      "dn": "Schlat",
      "bl": 8,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "08117044",
      "j": 2023,
      "n": "schlierbach",
      "dn": "Schlierbach",
      "bl": 8,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "08117049",
      "j": 2023,
      "n": "süßen",
      "dn": "Süßen",
      "bl": 8,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "08117051",
      "j": 2023,
      "n": "uhingen",
      "dn": "Uhingen",
      "bl": 8,
      "hs": 365.0,
      "mns": 3
    },
    {
      "a": "08117053",
      "j": 2023,
      "n": "wäschenbeuren",
      "dn": "Wäschenbeuren",
      "bl": 8,
      "hs": 305.0,
      "mns": 2
    },
    {
      "a": "08117055",
      "j": 2023,
      "n": "wangen",
      "dn": "Wangen",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08117058",
      "j": 2023,
      "n": "wiesensteig",
      "dn": "Wiesensteig",
      "bl": 8,
      "hs": 495.0,
      "mns": 2
    },
    {
      "a": "08117060",
      "j": 2023,
      "n": "zell unter aichelberg",
      "dn": "Zell unter Aichelberg",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08117061",
      "j": 2023,
      "n": "lauterstein",
      "dn": "Lauterstein",
      "bl": 8,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "08118001",
      "j": 2023,
      "n": "affalterbach",
      "dn": "Affalterbach",
      "bl": 8,
      "hs": 280.0,
      "mns": 4
    },
    {
      "a": "08118003",
      "j": 2023,
      "n": "asperg",
      "dn": "Asperg",
      "bl": 8,
      "hs": 430.0,
      "mns": 5
    },
    {
      "a": "08118006",
      "j": 2023,
      "n": "benningen am neckar",
      "dn": "Benningen am Neckar",
      "bl": 8,
      "hs": 430.0,
      "mns": 4
    },
    {
      "a": "08118007",
      "j": 2025,
      "n": "besigheim",
      "dn": "Besigheim",
      "bl": 8,
      "hs": 509.0,
      "mns": 4
    },
    {
      "a": "08118010",
      "j": 2023,
      "n": "bönnigheim",
      "dn": "Bönnigheim",
      "bl": 8,
      "hs": 430.0,
      "mns": 4
    },
    {
      "a": "08118011",
      "j": 2023,
      "n": "ditzingen",
      "dn": "Ditzingen",
      "bl": 8,
      "hs": 360.0,
      "mns": 5
    },
    {
      "a": "08118012",
      "j": 2023,
      "n": "eberdingen",
      "dn": "Eberdingen",
      "bl": 8,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "08118014",
      "j": 2023,
      "n": "erdmannhausen",
      "dn": "Erdmannhausen",
      "bl": 8,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "08118015",
      "j": 2023,
      "n": "erligheim",
      "dn": "Erligheim",
      "bl": 8,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "08118016",
      "j": 2023,
      "n": "freudental (steinbachtal)",
      "dn": "Freudental (Steinbachtal)",
      "bl": 8,
      "hs": 430.0,
      "mns": 4
    },
    {
      "a": "08118018",
      "j": 2023,
      "n": "gemmrigheim",
      "dn": "Gemmrigheim",
      "bl": 8,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "08118019",
      "j": 2025,
      "n": "gerlingen",
      "dn": "Gerlingen",
      "bl": 8,
      "hs": 150.0,
      "mns": 6
    },
    {
      "a": "08118021",
      "j": 2023,
      "n": "großbottwar",
      "dn": "Großbottwar",
      "bl": 8,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "08118027",
      "j": 2023,
      "n": "hemmingen",
      "dn": "Hemmingen",
      "bl": 8,
      "hs": 340.0,
      "mns": 4
    },
    {
      "a": "08118028",
      "j": 2023,
      "n": "hessigheim",
      "dn": "Hessigheim",
      "bl": 8,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "08118040",
      "j": 2023,
      "n": "kirchheim am neckar",
      "dn": "Kirchheim am Neckar",
      "bl": 8,
      "hs": 450.0,
      "mns": 4
    },
    {
      "a": "08118046",
      "j": 2023,
      "n": "kornwestheim",
      "dn": "Kornwestheim",
      "bl": 8,
      "hs": 390.0,
      "mns": 5
    },
    {
      "a": "08118047",
      "j": 2023,
      "n": "löchgau",
      "dn": "Löchgau",
      "bl": 8,
      "hs": 360.0,
      "mns": 4
    },
    {
      "a": "08118048",
      "j": 2023,
      "n": "ludwigsburg",
      "dn": "Ludwigsburg",
      "bl": 8,
      "hs": 445.0,
      "mns": 5
    },
    {
      "a": "08118049",
      "j": 2023,
      "n": "marbach am neckar",
      "dn": "Marbach am Neckar",
      "bl": 8,
      "hs": 450.0,
      "mns": 5
    },
    {
      "a": "08118050",
      "j": 2025,
      "n": "markgröningen",
      "dn": "Markgröningen",
      "bl": 8,
      "hs": 240.0,
      "mns": 4
    },
    {
      "a": "08118051",
      "j": 2025,
      "n": "möglingen",
      "dn": "Möglingen",
      "bl": 8,
      "hs": 260.0,
      "mns": 5
    },
    {
      "a": "08118053",
      "j": 2023,
      "n": "mundelsheim",
      "dn": "Mundelsheim",
      "bl": 8,
      "hs": 450.0,
      "mns": 4
    },
    {
      "a": "08118054",
      "j": 2023,
      "n": "murr",
      "dn": "Murr",
      "bl": 8,
      "hs": 300.0,
      "mns": 4
    },
    {
      "a": "08118059",
      "j": 2023,
      "n": "oberriexingen",
      "dn": "Oberriexingen",
      "bl": 8,
      "hs": 380.0,
      "mns": 4
    },
    {
      "a": "08118060",
      "j": 2025,
      "n": "oberstenfeld",
      "dn": "Oberstenfeld",
      "bl": 8,
      "hs": 175.0,
      "mns": 4
    },
    {
      "a": "08118063",
      "j": 2023,
      "n": "pleidelsheim",
      "dn": "Pleidelsheim",
      "bl": 8,
      "hs": 410.0,
      "mns": 4
    },
    {
      "a": "08118067",
      "j": 2023,
      "n": "schwieberdingen",
      "dn": "Schwieberdingen",
      "bl": 8,
      "hs": 370.0,
      "mns": 5
    },
    {
      "a": "08118068",
      "j": 2025,
      "n": "sersheim",
      "dn": "Sersheim",
      "bl": 8,
      "hs": 210.0,
      "mns": 4
    },
    {
      "a": "08118070",
      "j": 2023,
      "n": "steinheim an der murr",
      "dn": "Steinheim an der Murr",
      "bl": 8,
      "hs": 445.0,
      "mns": 3
    },
    {
      "a": "08118071",
      "j": 2023,
      "n": "tamm",
      "dn": "Tamm",
      "bl": 8,
      "hs": 460.0,
      "mns": 5
    },
    {
      "a": "08118073",
      "j": 2025,
      "n": "vaihingen an der enz",
      "dn": "Vaihingen an der Enz",
      "bl": 8,
      "hs": 270.0,
      "mns": 3
    },
    {
      "a": "08118074",
      "j": 2023,
      "n": "walheim",
      "dn": "Walheim",
      "bl": 8,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "08118076",
      "j": 2025,
      "n": "sachsenheim",
      "dn": "Sachsenheim",
      "bl": 8,
      "hs": 265.0,
      "mns": 4
    },
    {
      "a": "08118077",
      "j": 2023,
      "n": "ingersheim",
      "dn": "Ingersheim",
      "bl": 8,
      "hs": 415.0,
      "mns": 4
    },
    {
      "a": "08118078",
      "j": 2025,
      "n": "freiberg am neckar",
      "dn": "Freiberg am Neckar",
      "bl": 8,
      "hs": 235.0,
      "mns": 5
    },
    {
      "a": "08118079",
      "j": 2023,
      "n": "bietigheim-bissingen",
      "dn": "Bietigheim-Bissingen",
      "bl": 8,
      "hs": 375.0,
      "mns": 5
    },
    {
      "a": "08118080",
      "j": 2023,
      "n": "korntal-münchingen",
      "dn": "Korntal-Münchingen",
      "bl": 8,
      "hs": 390.0,
      "mns": 6
    },
    {
      "a": "08118081",
      "j": 2023,
      "n": "remseck am neckar",
      "dn": "Remseck am Neckar",
      "bl": 8,
      "hs": 480.0,
      "mns": 5
    },
    {
      "a": "08119001",
      "j": 2023,
      "n": "alfdorf",
      "dn": "Alfdorf",
      "bl": 8,
      "hs": 325.0,
      "mns": 2
    },
    {
      "a": "08119003",
      "j": 2023,
      "n": "allmersbach im tal",
      "dn": "Allmersbach im Tal",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08119004",
      "j": 2023,
      "n": "althütte",
      "dn": "Althütte",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08119006",
      "j": 2025,
      "n": "auenwald",
      "dn": "Auenwald",
      "bl": 8,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "08119008",
      "j": 2023,
      "n": "backnang",
      "dn": "Backnang",
      "bl": 8,
      "hs": 405.0,
      "mns": 4
    },
    {
      "a": "08119018",
      "j": 2023,
      "n": "burgstetten",
      "dn": "Burgstetten",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08119020",
      "j": 2023,
      "n": "fellbach",
      "dn": "Fellbach",
      "bl": 8,
      "hs": 405.0,
      "mns": 5
    },
    {
      "a": "08119024",
      "j": 2023,
      "n": "großerlach",
      "dn": "Großerlach",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08119037",
      "j": 2023,
      "n": "kaisersbach",
      "dn": "Kaisersbach",
      "bl": 8,
      "hs": 345.0,
      "mns": 2
    },
    {
      "a": "08119038",
      "j": 2023,
      "n": "kirchberg an der murr",
      "dn": "Kirchberg an der Murr",
      "bl": 8,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "08119041",
      "j": 2023,
      "n": "korb",
      "dn": "Korb",
      "bl": 8,
      "hs": 395.0,
      "mns": 4
    },
    {
      "a": "08119042",
      "j": 2023,
      "n": "leutenbach",
      "dn": "Leutenbach",
      "bl": 8,
      "hs": 375.0,
      "mns": 3
    },
    {
      "a": "08119044",
      "j": 2023,
      "n": "murrhardt",
      "dn": "Murrhardt",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08119053",
      "j": 2023,
      "n": "oppenweiler",
      "dn": "Oppenweiler",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08119055",
      "j": 2023,
      "n": "plüderhausen",
      "dn": "Plüderhausen",
      "bl": 8,
      "hs": 395.0,
      "mns": 2
    },
    {
      "a": "08119061",
      "j": 2023,
      "n": "rudersberg",
      "dn": "Rudersberg",
      "bl": 8,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "08119067",
      "j": 2025,
      "n": "schorndorf",
      "dn": "Schorndorf",
      "bl": 8,
      "hs": 260.0,
      "mns": 4
    },
    {
      "a": "08119068",
      "j": 2023,
      "n": "schwaikheim",
      "dn": "Schwaikheim",
      "bl": 8,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "08119069",
      "j": 2023,
      "n": "spiegelberg",
      "dn": "Spiegelberg",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08119075",
      "j": 2023,
      "n": "sulzbach an der murr",
      "dn": "Sulzbach an der Murr",
      "bl": 8,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "08119076",
      "j": 2023,
      "n": "urbach",
      "dn": "Urbach",
      "bl": 8,
      "hs": 385.0,
      "mns": 2
    },
    {
      "a": "08119079",
      "j": 2023,
      "n": "waiblingen",
      "dn": "Waiblingen",
      "bl": 8,
      "hs": 390.0,
      "mns": 5
    },
    {
      "a": "08119083",
      "j": 2023,
      "n": "weissach im tal",
      "dn": "Weissach im Tal",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08119084",
      "j": 2023,
      "n": "welzheim",
      "dn": "Welzheim",
      "bl": 8,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "08119085",
      "j": 2023,
      "n": "winnenden",
      "dn": "Winnenden",
      "bl": 8,
      "hs": 420.0,
      "mns": 5
    },
    {
      "a": "08119086",
      "j": 2023,
      "n": "winterbach",
      "dn": "Winterbach",
      "bl": 8,
      "hs": 385.0,
      "mns": 2
    },
    {
      "a": "08119087",
      "j": 2023,
      "n": "aspach",
      "dn": "Aspach",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08119089",
      "j": 2025,
      "n": "berglen",
      "dn": "Berglen",
      "bl": 8,
      "hs": 170.0,
      "mns": 2
    },
    {
      "a": "08119090",
      "j": 2023,
      "n": "remshalden",
      "dn": "Remshalden",
      "bl": 8,
      "hs": 385.0,
      "mns": 3
    },
    {
      "a": "08119091",
      "j": 2025,
      "n": "weinstadt",
      "dn": "Weinstadt",
      "bl": 8,
      "hs": 285.0,
      "mns": 5
    },
    {
      "a": "08119093",
      "j": 2023,
      "n": "kernen im remstal",
      "dn": "Kernen im Remstal",
      "bl": 8,
      "hs": 385.0,
      "mns": 5
    },
    {
      "a": "08121000",
      "j": 2023,
      "n": "heilbronn",
      "dn": "Heilbronn",
      "bl": 8,
      "hs": 450.0,
      "mns": 4
    },
    {
      "a": "08125001",
      "j": 2023,
      "n": "abstatt",
      "dn": "Abstatt",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08125005",
      "j": 2023,
      "n": "bad friedrichshall",
      "dn": "Bad Friedrichshall",
      "bl": 8,
      "hs": 440.0,
      "mns": 3
    },
    {
      "a": "08125006",
      "j": 2023,
      "n": "bad rappenau",
      "dn": "Bad Rappenau",
      "bl": 8,
      "hs": 410.0,
      "mns": 3
    },
    {
      "a": "08125007",
      "j": 2023,
      "n": "bad wimpfen",
      "dn": "Bad Wimpfen",
      "bl": 8,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "08125008",
      "j": 2023,
      "n": "beilstein",
      "dn": "Beilstein",
      "bl": 8,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "08125013",
      "j": 2025,
      "n": "brackenheim",
      "dn": "Brackenheim",
      "bl": 8,
      "hs": 227.0,
      "mns": 3
    },
    {
      "a": "08125017",
      "j": 2023,
      "n": "cleebronn",
      "dn": "Cleebronn",
      "bl": 8,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "08125021",
      "j": 2023,
      "n": "eberstadt",
      "dn": "Eberstadt",
      "bl": 8,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "08125024",
      "j": 2023,
      "n": "ellhofen",
      "dn": "Ellhofen",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08125026",
      "j": 2024,
      "n": "eppingen",
      "dn": "Eppingen",
      "bl": 8,
      "hs": 260.0,
      "mns": 2
    },
    {
      "a": "08125027",
      "j": 2023,
      "n": "erlenbach",
      "dn": "Erlenbach",
      "bl": 8,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "08125030",
      "j": 2023,
      "n": "flein",
      "dn": "Flein",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08125034",
      "j": 2023,
      "n": "gemmingen",
      "dn": "Gemmingen",
      "bl": 8,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "08125038",
      "j": 2023,
      "n": "güglingen",
      "dn": "Güglingen",
      "bl": 8,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "08125039",
      "j": 2023,
      "n": "gundelsheim",
      "dn": "Gundelsheim",
      "bl": 8,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "08125046",
      "j": 2023,
      "n": "ilsfeld",
      "dn": "Ilsfeld",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08125047",
      "j": 2023,
      "n": "ittlingen",
      "dn": "Ittlingen",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08125048",
      "j": 2023,
      "n": "jagsthausen",
      "dn": "Jagsthausen",
      "bl": 8,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "08125049",
      "j": 2023,
      "n": "kirchardt",
      "dn": "Kirchardt",
      "bl": 8,
      "hs": 375.0,
      "mns": 2
    },
    {
      "a": "08125056",
      "j": 2023,
      "n": "lauffen am neckar",
      "dn": "Lauffen am Neckar",
      "bl": 8,
      "hs": 390.0,
      "mns": 4
    },
    {
      "a": "08125057",
      "j": 2024,
      "n": "lehrensteinsfeld",
      "dn": "Lehrensteinsfeld",
      "bl": 8,
      "hs": 395.0,
      "mns": 2
    },
    {
      "a": "08125058",
      "j": 2023,
      "n": "leingarten",
      "dn": "Leingarten",
      "bl": 8,
      "hs": 390.0,
      "mns": 4
    },
    {
      "a": "08125059",
      "j": 2023,
      "n": "löwenstein",
      "dn": "Löwenstein",
      "bl": 8,
      "hs": 480.0,
      "mns": 2
    },
    {
      "a": "08125061",
      "j": 2023,
      "n": "massenbachhausen",
      "dn": "Massenbachhausen",
      "bl": 8,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "08125063",
      "j": 2023,
      "n": "möckmühl",
      "dn": "Möckmühl",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08125065",
      "j": 2023,
      "n": "neckarsulm",
      "dn": "Neckarsulm",
      "bl": 8,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "08125066",
      "j": 2023,
      "n": "neckarwestheim",
      "dn": "Neckarwestheim",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08125068",
      "j": 2023,
      "n": "neudenau",
      "dn": "Neudenau",
      "bl": 8,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "08125069",
      "j": 2023,
      "n": "neuenstadt am kocher",
      "dn": "Neuenstadt am Kocher",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08125074",
      "j": 2023,
      "n": "nordheim",
      "dn": "Nordheim",
      "bl": 8,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "08125078",
      "j": 2023,
      "n": "oedheim",
      "dn": "Oedheim",
      "bl": 8,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "08125079",
      "j": 2023,
      "n": "offenau",
      "dn": "Offenau",
      "bl": 8,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "08125081",
      "j": 2023,
      "n": "pfaffenhofen",
      "dn": "Pfaffenhofen",
      "bl": 8,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "08125084",
      "j": 2023,
      "n": "roigheim",
      "dn": "Roigheim",
      "bl": 8,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "08125086",
      "j": 2023,
      "n": "schwaigern",
      "dn": "Schwaigern",
      "bl": 8,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "08125087",
      "j": 2023,
      "n": "siegelsbach",
      "dn": "Siegelsbach",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08125094",
      "j": 2023,
      "n": "talheim",
      "dn": "Talheim",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08125096",
      "j": 2023,
      "n": "untereisesheim",
      "dn": "Untereisesheim",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08125098",
      "j": 2023,
      "n": "untergruppenbach",
      "dn": "Untergruppenbach",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08125102",
      "j": 2023,
      "n": "weinsberg",
      "dn": "Weinsberg",
      "bl": 8,
      "hs": 440.0,
      "mns": 3
    },
    {
      "a": "08125103",
      "j": 2023,
      "n": "widdern",
      "dn": "Widdern",
      "bl": 8,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "08125107",
      "j": 2023,
      "n": "wüstenrot",
      "dn": "Wüstenrot",
      "bl": 8,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "08125108",
      "j": 2023,
      "n": "zaberfeld",
      "dn": "Zaberfeld",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08125110",
      "j": 2025,
      "n": "obersulm",
      "dn": "Obersulm",
      "bl": 8,
      "hs": 172.0,
      "mns": 3
    },
    {
      "a": "08125111",
      "j": 2023,
      "n": "hardthausen am kocher",
      "dn": "Hardthausen am Kocher",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08125113",
      "j": 2023,
      "n": "langenbrettach",
      "dn": "Langenbrettach",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08126011",
      "j": 2023,
      "n": "bretzfeld",
      "dn": "Bretzfeld",
      "bl": 8,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "08126020",
      "j": 2023,
      "n": "dörzbach",
      "dn": "Dörzbach",
      "bl": 8,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "08126028",
      "j": 2023,
      "n": "forchtenberg",
      "dn": "Forchtenberg",
      "bl": 8,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "08126039",
      "j": 2023,
      "n": "ingelfingen",
      "dn": "Ingelfingen",
      "bl": 8,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "08126045",
      "j": 2025,
      "n": "krautheim",
      "dn": "Krautheim",
      "bl": 8,
      "hs": 670.0,
      "mns": 1
    },
    {
      "a": "08126046",
      "j": 2023,
      "n": "künzelsau",
      "dn": "Künzelsau",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08126047",
      "j": 2023,
      "n": "kupferzell",
      "dn": "Kupferzell",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08126056",
      "j": 2023,
      "n": "mulfingen",
      "dn": "Mulfingen",
      "bl": 8,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "08126058",
      "j": 2023,
      "n": "neuenstein",
      "dn": "Neuenstein",
      "bl": 8,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "08126060",
      "j": 2025,
      "n": "niedernhall",
      "dn": "Niedernhall",
      "bl": 8,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "08126066",
      "j": 2023,
      "n": "öhringen",
      "dn": "Öhringen",
      "bl": 8,
      "hs": 395.0,
      "mns": 3
    },
    {
      "a": "08126069",
      "j": 2023,
      "n": "pfedelbach",
      "dn": "Pfedelbach",
      "bl": 8,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "08126072",
      "j": 2023,
      "n": "schöntal",
      "dn": "Schöntal",
      "bl": 8,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "08126085",
      "j": 2023,
      "n": "waldenburg",
      "dn": "Waldenburg",
      "bl": 8,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "08126086",
      "j": 2023,
      "n": "weißbach",
      "dn": "Weißbach",
      "bl": 8,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "08126094",
      "j": 2023,
      "n": "zweiflingen",
      "dn": "Zweiflingen",
      "bl": 8,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "08127008",
      "j": 2023,
      "n": "blaufelden",
      "dn": "Blaufelden",
      "bl": 8,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "08127009",
      "j": 2023,
      "n": "braunsbach",
      "dn": "Braunsbach",
      "bl": 8,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "08127012",
      "j": 2023,
      "n": "bühlertann",
      "dn": "Bühlertann",
      "bl": 8,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "08127013",
      "j": 2023,
      "n": "bühlerzell",
      "dn": "Bühlerzell",
      "bl": 8,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "08127014",
      "j": 2023,
      "n": "crailsheim",
      "dn": "Crailsheim",
      "bl": 8,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "08127023",
      "j": 2023,
      "n": "fichtenberg",
      "dn": "Fichtenberg",
      "bl": 8,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "08127025",
      "j": 2023,
      "n": "gaildorf",
      "dn": "Gaildorf",
      "bl": 8,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "08127032",
      "j": 2023,
      "n": "gerabronn",
      "dn": "Gerabronn",
      "bl": 8,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "08127043",
      "j": 2023,
      "n": "ilshofen",
      "dn": "Ilshofen",
      "bl": 8,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "08127046",
      "j": 2023,
      "n": "kirchberg an der jagst",
      "dn": "Kirchberg an der Jagst",
      "bl": 8,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "08127047",
      "j": 2023,
      "n": "langenburg",
      "dn": "Langenburg",
      "bl": 8,
      "hs": 680.0,
      "mns": 1
    },
    {
      "a": "08127052",
      "j": 2023,
      "n": "mainhardt",
      "dn": "Mainhardt",
      "bl": 8,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "08127056",
      "j": 2023,
      "n": "michelbach an der bilz",
      "dn": "Michelbach an der Bilz",
      "bl": 8,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "08127059",
      "j": 2023,
      "n": "michelfeld",
      "dn": "Michelfeld",
      "bl": 8,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "08127062",
      "j": 2023,
      "n": "oberrot",
      "dn": "Oberrot",
      "bl": 8,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "08127063",
      "j": 2023,
      "n": "obersontheim",
      "dn": "Obersontheim",
      "bl": 8,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "08127071",
      "j": 2023,
      "n": "rot am see",
      "dn": "Rot am See",
      "bl": 8,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "08127073",
      "j": 2023,
      "n": "satteldorf",
      "dn": "Satteldorf",
      "bl": 8,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "08127075",
      "j": 2023,
      "n": "schrozberg",
      "dn": "Schrozberg",
      "bl": 8,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "08127076",
      "j": 2023,
      "n": "schwäbisch hall",
      "dn": "Schwäbisch Hall",
      "bl": 8,
      "hs": 460.0,
      "mns": 2
    },
    {
      "a": "08127079",
      "j": 2023,
      "n": "sulzbach-laufen",
      "dn": "Sulzbach-Laufen",
      "bl": 8,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "08127086",
      "j": 2023,
      "n": "untermünkheim",
      "dn": "Untermünkheim",
      "bl": 8,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "08127089",
      "j": 2023,
      "n": "vellberg",
      "dn": "Vellberg",
      "bl": 8,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "08127091",
      "j": 2023,
      "n": "wallhausen",
      "dn": "Wallhausen",
      "bl": 8,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "08127099",
      "j": 2023,
      "n": "wolpertshausen",
      "dn": "Wolpertshausen",
      "bl": 8,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "08127100",
      "j": 2023,
      "n": "rosengarten",
      "dn": "Rosengarten",
      "bl": 8,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "08127101",
      "j": 2023,
      "n": "kreßberg",
      "dn": "Kreßberg",
      "bl": 8,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "08127102",
      "j": 2023,
      "n": "fichtenau",
      "dn": "Fichtenau",
      "bl": 8,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "08127103",
      "j": 2023,
      "n": "frankenhardt",
      "dn": "Frankenhardt",
      "bl": 8,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "08127104",
      "j": 2023,
      "n": "stimpfach",
      "dn": "Stimpfach",
      "bl": 8,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "08128006",
      "j": 2023,
      "n": "assamstadt",
      "dn": "Assamstadt",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08128007",
      "j": 2023,
      "n": "bad mergentheim",
      "dn": "Bad Mergentheim",
      "bl": 8,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "08128014",
      "j": 2023,
      "n": "boxberg",
      "dn": "Boxberg",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08128020",
      "j": 2023,
      "n": "creglingen",
      "dn": "Creglingen",
      "bl": 8,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "08128039",
      "j": 2025,
      "n": "freudenberg",
      "dn": "Freudenberg",
      "bl": 8,
      "hs": 540.0,
      "mns": 1
    },
    {
      "a": "08128045",
      "j": 2023,
      "n": "großrinderfeld",
      "dn": "Großrinderfeld",
      "bl": 8,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "08128047",
      "j": 2023,
      "n": "grünsfeld",
      "dn": "Grünsfeld",
      "bl": 8,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "08128058",
      "j": 2023,
      "n": "igersheim",
      "dn": "Igersheim",
      "bl": 8,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "08128061",
      "j": 2023,
      "n": "königheim",
      "dn": "Königheim",
      "bl": 8,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "08128064",
      "j": 2025,
      "n": "külsheim",
      "dn": "Külsheim",
      "bl": 8,
      "hs": 802.0,
      "mns": 1
    },
    {
      "a": "08128082",
      "j": 2025,
      "n": "niederstetten",
      "dn": "Niederstetten",
      "bl": 8,
      "hs": 1300.0,
      "mns": 1
    },
    {
      "a": "08128115",
      "j": 2023,
      "n": "tauberbischofsheim",
      "dn": "Tauberbischofsheim",
      "bl": 8,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "08128126",
      "j": 2023,
      "n": "weikersheim",
      "dn": "Weikersheim",
      "bl": 8,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "08128128",
      "j": 2023,
      "n": "werbach",
      "dn": "Werbach",
      "bl": 8,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "08128131",
      "j": 2023,
      "n": "wertheim",
      "dn": "Wertheim",
      "bl": 8,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "08128137",
      "j": 2023,
      "n": "wittighausen",
      "dn": "Wittighausen",
      "bl": 8,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "08128138",
      "j": 2023,
      "n": "ahorn",
      "dn": "Ahorn",
      "bl": 8,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "08128139",
      "j": 2023,
      "n": "lauda-königshofen",
      "dn": "Lauda-Königshofen",
      "bl": 8,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "08135010",
      "j": 2024,
      "n": "dischingen",
      "dn": "Dischingen",
      "bl": 8,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "08135015",
      "j": 2023,
      "n": "gerstetten",
      "dn": "Gerstetten",
      "bl": 8,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "08135016",
      "j": 2023,
      "n": "giengen an der brenz",
      "dn": "Giengen an der Brenz",
      "bl": 8,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "08135019",
      "j": 2023,
      "n": "heidenheim an der brenz",
      "dn": "Heidenheim an der Brenz",
      "bl": 8,
      "hs": 410.0,
      "mns": 3
    },
    {
      "a": "08135020",
      "j": 2023,
      "n": "herbrechtingen",
      "dn": "Herbrechtingen",
      "bl": 8,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "08135021",
      "j": 2023,
      "n": "hermaringen",
      "dn": "Hermaringen",
      "bl": 8,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "08135025",
      "j": 2023,
      "n": "königsbronn",
      "dn": "Königsbronn",
      "bl": 8,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "08135026",
      "j": 2023,
      "n": "nattheim",
      "dn": "Nattheim",
      "bl": 8,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "08135027",
      "j": 2023,
      "n": "niederstotzingen",
      "dn": "Niederstotzingen",
      "bl": 8,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "08135031",
      "j": 2023,
      "n": "sontheim an der brenz",
      "dn": "Sontheim an der Brenz",
      "bl": 8,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "08135032",
      "j": 2025,
      "n": "steinheim am albuch",
      "dn": "Steinheim am Albuch",
      "bl": 8,
      "hs": 455.0,
      "mns": 1
    },
    {
      "a": "08136002",
      "j": 2023,
      "n": "abtsgmünd",
      "dn": "Abtsgmünd",
      "bl": 8,
      "hs": 375.0,
      "mns": 2
    },
    {
      "a": "08136003",
      "j": 2023,
      "n": "adelmannsfelden",
      "dn": "Adelmannsfelden",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08136007",
      "j": 2023,
      "n": "bartholomä",
      "dn": "Bartholomä",
      "bl": 8,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "08136009",
      "j": 2023,
      "n": "böbingen an der rems",
      "dn": "Böbingen an der Rems",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08136010",
      "j": 2023,
      "n": "bopfingen",
      "dn": "Bopfingen",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08136015",
      "j": 2023,
      "n": "durlangen",
      "dn": "Durlangen",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08136018",
      "j": 2023,
      "n": "ellenberg",
      "dn": "Ellenberg",
      "bl": 8,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "08136019",
      "j": 2023,
      "n": "ellwangen (jagst)",
      "dn": "Ellwangen (Jagst)",
      "bl": 8,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "08136020",
      "j": 2023,
      "n": "eschach",
      "dn": "Eschach",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08136021",
      "j": 2023,
      "n": "essingen",
      "dn": "Essingen",
      "bl": 8,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "08136024",
      "j": 2023,
      "n": "göggingen",
      "dn": "Göggingen",
      "bl": 8,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "08136027",
      "j": 2023,
      "n": "gschwend",
      "dn": "Gschwend",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08136028",
      "j": 2023,
      "n": "heubach",
      "dn": "Heubach",
      "bl": 8,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "08136029",
      "j": 2023,
      "n": "heuchlingen",
      "dn": "Heuchlingen",
      "bl": 8,
      "hs": 395.0,
      "mns": 2
    },
    {
      "a": "08136033",
      "j": 2025,
      "n": "hüttlingen",
      "dn": "Hüttlingen",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08136034",
      "j": 2023,
      "n": "iggingen",
      "dn": "Iggingen",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08136035",
      "j": 2023,
      "n": "jagstzell",
      "dn": "Jagstzell",
      "bl": 8,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "08136037",
      "j": 2023,
      "n": "kirchheim am ries",
      "dn": "Kirchheim am Ries",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08136038",
      "j": 2023,
      "n": "lauchheim",
      "dn": "Lauchheim",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08136040",
      "j": 2023,
      "n": "leinzell",
      "dn": "Leinzell",
      "bl": 8,
      "hs": 590.0,
      "mns": 2
    },
    {
      "a": "08136042",
      "j": 2023,
      "n": "lorch",
      "dn": "Lorch",
      "bl": 8,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "08136043",
      "j": 2023,
      "n": "mögglingen",
      "dn": "Mögglingen",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08136044",
      "j": 2023,
      "n": "mutlangen",
      "dn": "Mutlangen",
      "bl": 8,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "08136045",
      "j": 2023,
      "n": "neresheim",
      "dn": "Neresheim",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08136046",
      "j": 2023,
      "n": "neuler",
      "dn": "Neuler",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08136049",
      "j": 2023,
      "n": "obergröningen",
      "dn": "Obergröningen",
      "bl": 8,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "08136050",
      "j": 2023,
      "n": "oberkochen",
      "dn": "Oberkochen",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08136060",
      "j": 2023,
      "n": "rosenberg",
      "dn": "Rosenberg",
      "bl": 8,
      "hs": 305.0,
      "mns": 2
    },
    {
      "a": "08136061",
      "j": 2023,
      "n": "ruppertshofen",
      "dn": "Ruppertshofen",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08136062",
      "j": 2023,
      "n": "schechingen",
      "dn": "Schechingen",
      "bl": 8,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "08136065",
      "j": 2023,
      "n": "schwäbisch gmünd",
      "dn": "Schwäbisch Gmünd",
      "bl": 8,
      "hs": 470.0,
      "mns": 3
    },
    {
      "a": "08136066",
      "j": 2023,
      "n": "spraitbach",
      "dn": "Spraitbach",
      "bl": 8,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "08136068",
      "j": 2023,
      "n": "stödtlen",
      "dn": "Stödtlen",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08136070",
      "j": 2023,
      "n": "täferrot",
      "dn": "Täferrot",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08136071",
      "j": 2023,
      "n": "tannhausen",
      "dn": "Tannhausen",
      "bl": 8,
      "hs": 395.0,
      "mns": 2
    },
    {
      "a": "08136075",
      "j": 2023,
      "n": "unterschneidheim",
      "dn": "Unterschneidheim",
      "bl": 8,
      "hs": 385.0,
      "mns": 2
    },
    {
      "a": "08136079",
      "j": 2023,
      "n": "waldstetten",
      "dn": "Waldstetten",
      "bl": 8,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "08136082",
      "j": 2023,
      "n": "westhausen",
      "dn": "Westhausen",
      "bl": 8,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "08136084",
      "j": 2023,
      "n": "wört",
      "dn": "Wört",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08136087",
      "j": 2023,
      "n": "riesbürg",
      "dn": "Riesbürg",
      "bl": 8,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "08136088",
      "j": 2023,
      "n": "aalen",
      "dn": "Aalen",
      "bl": 8,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "08136089",
      "j": 2023,
      "n": "rainau",
      "dn": "Rainau",
      "bl": 8,
      "hs": 395.0,
      "mns": 2
    },
    {
      "a": "08211000",
      "j": 2023,
      "n": "baden-baden",
      "dn": "Baden-Baden",
      "bl": 8,
      "hs": 490.0,
      "mns": 4
    },
    {
      "a": "08212000",
      "j": 2025,
      "n": "karlsruhe",
      "dn": "Karlsruhe",
      "bl": 8,
      "hs": 270.0,
      "mns": 4
    },
    {
      "a": "08215007",
      "j": 2023,
      "n": "bretten",
      "dn": "Bretten",
      "bl": 8,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "08215009",
      "j": 2025,
      "n": "bruchsal",
      "dn": "Bruchsal",
      "bl": 8,
      "hs": 215.0,
      "mns": 3
    },
    {
      "a": "08215017",
      "j": 2023,
      "n": "ettlingen",
      "dn": "Ettlingen",
      "bl": 8,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "08215021",
      "j": 2023,
      "n": "forst",
      "dn": "Forst",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08215025",
      "j": 2023,
      "n": "gondelsheim",
      "dn": "Gondelsheim",
      "bl": 8,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "08215029",
      "j": 2023,
      "n": "hambrücken",
      "dn": "Hambrücken",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08215039",
      "j": 2023,
      "n": "kronau",
      "dn": "Kronau",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08215040",
      "j": 2023,
      "n": "kürnbach",
      "dn": "Kürnbach",
      "bl": 8,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "08215046",
      "j": 2025,
      "n": "malsch",
      "dn": "Malsch",
      "bl": 8,
      "hs": 200.0,
      "mns": 3
    },
    {
      "a": "08215047",
      "j": 2023,
      "n": "marxzell",
      "dn": "Marxzell",
      "bl": 8,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "08215059",
      "j": 2025,
      "n": "oberderdingen",
      "dn": "Oberderdingen",
      "bl": 8,
      "hs": 230.0,
      "mns": 2
    },
    {
      "a": "08215064",
      "j": 2023,
      "n": "östringen",
      "dn": "Östringen",
      "bl": 8,
      "hs": 395.0,
      "mns": 2
    },
    {
      "a": "08215066",
      "j": 2023,
      "n": "philippsburg",
      "dn": "Philippsburg",
      "bl": 8,
      "hs": 280.0,
      "mns": 2
    },
    {
      "a": "08215082",
      "j": 2023,
      "n": "sulzfeld",
      "dn": "Sulzfeld",
      "bl": 8,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "08215084",
      "j": 2023,
      "n": "ubstadt-weiher",
      "dn": "Ubstadt-Weiher",
      "bl": 8,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "08215089",
      "j": 2023,
      "n": "walzbachtal",
      "dn": "Walzbachtal",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08215090",
      "j": 2023,
      "n": "weingarten (baden)",
      "dn": "Weingarten (Baden)",
      "bl": 8,
      "hs": 420.0,
      "mns": 3
    },
    {
      "a": "08215094",
      "j": 2023,
      "n": "zaisenhausen",
      "dn": "Zaisenhausen",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08215096",
      "j": 2023,
      "n": "karlsbad",
      "dn": "Karlsbad",
      "bl": 8,
      "hs": 325.0,
      "mns": 3
    },
    {
      "a": "08215097",
      "j": 2023,
      "n": "kraichtal",
      "dn": "Kraichtal",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08215099",
      "j": 2023,
      "n": "graben-neudorf",
      "dn": "Graben-Neudorf",
      "bl": 8,
      "hs": 280.0,
      "mns": 3
    },
    {
      "a": "08215100",
      "j": 2023,
      "n": "bad schönborn",
      "dn": "Bad Schönborn",
      "bl": 8,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "08215101",
      "j": 2023,
      "n": "pfinztal",
      "dn": "Pfinztal",
      "bl": 8,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "08215102",
      "j": 2023,
      "n": "eggenstein-leopoldshafen",
      "dn": "Eggenstein-Leopoldshafen",
      "bl": 8,
      "hs": 340.0,
      "mns": 4
    },
    {
      "a": "08215103",
      "j": 2024,
      "n": "karlsdorf-neuthard",
      "dn": "Karlsdorf-Neuthard",
      "bl": 8,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "08215105",
      "j": 2023,
      "n": "linkenheim-hochstetten",
      "dn": "Linkenheim-Hochstetten",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08215106",
      "j": 2023,
      "n": "waghäusel",
      "dn": "Waghäusel",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08215107",
      "j": 2023,
      "n": "oberhausen-rheinhausen",
      "dn": "Oberhausen-Rheinhausen",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08215108",
      "j": 2023,
      "n": "rheinstetten",
      "dn": "Rheinstetten",
      "bl": 8,
      "hs": 360.0,
      "mns": 4
    },
    {
      "a": "08215109",
      "j": 2023,
      "n": "stutensee",
      "dn": "Stutensee",
      "bl": 8,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "08215110",
      "j": 2023,
      "n": "waldbronn",
      "dn": "Waldbronn",
      "bl": 8,
      "hs": 470.0,
      "mns": 4
    },
    {
      "a": "08215111",
      "j": 2023,
      "n": "dettenheim",
      "dn": "Dettenheim",
      "bl": 8,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "08216002",
      "j": 2023,
      "n": "au am rhein",
      "dn": "Au am Rhein",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08216005",
      "j": 2023,
      "n": "bietigheim",
      "dn": "Bietigheim",
      "bl": 8,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "08216006",
      "j": 2023,
      "n": "bischweier",
      "dn": "Bischweier",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08216007",
      "j": 2023,
      "n": "bühl",
      "dn": "Bühl",
      "bl": 8,
      "hs": 375.0,
      "mns": 3
    },
    {
      "a": "08216008",
      "j": 2023,
      "n": "bühlertal",
      "dn": "Bühlertal",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08216009",
      "j": 2023,
      "n": "durmersheim",
      "dn": "Durmersheim",
      "bl": 8,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "08216012",
      "j": 2023,
      "n": "elchesheim-illingen",
      "dn": "Elchesheim-Illingen",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08216013",
      "j": 2023,
      "n": "forbach",
      "dn": "Forbach",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08216015",
      "j": 2025,
      "n": "gaggenau",
      "dn": "Gaggenau",
      "bl": 8,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "08216017",
      "j": 2023,
      "n": "gernsbach",
      "dn": "Gernsbach",
      "bl": 8,
      "hs": 440.0,
      "mns": 3
    },
    {
      "a": "08216022",
      "j": 2023,
      "n": "hügelsheim",
      "dn": "Hügelsheim",
      "bl": 8,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "08216023",
      "j": 2023,
      "n": "iffezheim",
      "dn": "Iffezheim",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08216024",
      "j": 2023,
      "n": "kuppenheim",
      "dn": "Kuppenheim",
      "bl": 8,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "08216028",
      "j": 2023,
      "n": "lichtenau",
      "dn": "Lichtenau",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08216029",
      "j": 2023,
      "n": "loffenau",
      "dn": "Loffenau",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08216033",
      "j": 2023,
      "n": "muggensturm",
      "dn": "Muggensturm",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08216039",
      "j": 2023,
      "n": "ötigheim",
      "dn": "Ötigheim",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08216041",
      "j": 2023,
      "n": "ottersweier",
      "dn": "Ottersweier",
      "bl": 8,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "08216043",
      "j": 2023,
      "n": "rastatt",
      "dn": "Rastatt",
      "bl": 8,
      "hs": 430.0,
      "mns": 3
    },
    {
      "a": "08216049",
      "j": 2025,
      "n": "sinzheim",
      "dn": "Sinzheim",
      "bl": 8,
      "hs": 250.0,
      "mns": 2
    },
    {
      "a": "08216052",
      "j": 2023,
      "n": "steinmauern",
      "dn": "Steinmauern",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08216059",
      "j": 2023,
      "n": "weisenbach",
      "dn": "Weisenbach",
      "bl": 8,
      "hs": 500.0,
      "mns": 2
    },
    {
      "a": "08216063",
      "j": 2023,
      "n": "rheinmünster",
      "dn": "Rheinmünster",
      "bl": 8,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "08221000",
      "j": 2025,
      "n": "heidelberg",
      "dn": "Heidelberg",
      "bl": 8,
      "hs": 750.0,
      "mns": 5
    },
    {
      "a": "08222000",
      "j": 2025,
      "n": "mannheim",
      "dn": "Mannheim",
      "bl": 8,
      "hs": 365.0,
      "mns": 5
    },
    {
      "a": "08225001",
      "j": 2023,
      "n": "adelsheim",
      "dn": "Adelsheim",
      "bl": 8,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "08225002",
      "j": 2023,
      "n": "aglasterhausen",
      "dn": "Aglasterhausen",
      "bl": 8,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "08225009",
      "j": 2023,
      "n": "billigheim",
      "dn": "Billigheim",
      "bl": 8,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "08225010",
      "j": 2023,
      "n": "binau",
      "dn": "Binau",
      "bl": 8,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "08225014",
      "j": 2023,
      "n": "buchen",
      "dn": "Buchen",
      "bl": 8,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "08225024",
      "j": 2023,
      "n": "fahrenbach",
      "dn": "Fahrenbach",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08225032",
      "j": 2023,
      "n": "hardheim",
      "dn": "Hardheim",
      "bl": 8,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "08225033",
      "j": 2023,
      "n": "haßmersheim",
      "dn": "Haßmersheim",
      "bl": 8,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "08225039",
      "j": 2023,
      "n": "höpfingen",
      "dn": "Höpfingen",
      "bl": 8,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "08225042",
      "j": 2023,
      "n": "hüffenhardt",
      "dn": "Hüffenhardt",
      "bl": 8,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "08225052",
      "j": 2023,
      "n": "limbach",
      "dn": "Limbach",
      "bl": 8,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "08225058",
      "j": 2023,
      "n": "mosbach",
      "dn": "Mosbach",
      "bl": 8,
      "hs": 430.0,
      "mns": 3
    },
    {
      "a": "08225060",
      "j": 2023,
      "n": "mudau",
      "dn": "Mudau",
      "bl": 8,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "08225064",
      "j": 2023,
      "n": "neckargerach",
      "dn": "Neckargerach",
      "bl": 8,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "08225067",
      "j": 2023,
      "n": "neckarzimmern",
      "dn": "Neckarzimmern",
      "bl": 8,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "08225068",
      "j": 2023,
      "n": "neunkirchen",
      "dn": "Neunkirchen",
      "bl": 8,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "08225074",
      "j": 2023,
      "n": "obrigheim",
      "dn": "Obrigheim",
      "bl": 8,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "08225075",
      "j": 2023,
      "n": "osterburken",
      "dn": "Osterburken",
      "bl": 8,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "08225082",
      "j": 2023,
      "n": "rosenberg",
      "dn": "Rosenberg",
      "bl": 8,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "08225091",
      "j": 2023,
      "n": "seckach",
      "dn": "Seckach",
      "bl": 8,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "08225109",
      "j": 2023,
      "n": "walldürn",
      "dn": "Walldürn",
      "bl": 8,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "08225113",
      "j": 2023,
      "n": "zwingenberg",
      "dn": "Zwingenberg",
      "bl": 8,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "08225114",
      "j": 2023,
      "n": "ravenstein",
      "dn": "Ravenstein",
      "bl": 8,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "08225115",
      "j": 2023,
      "n": "schefflenz",
      "dn": "Schefflenz",
      "bl": 8,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "08225116",
      "j": 2023,
      "n": "schwarzach",
      "dn": "Schwarzach",
      "bl": 8,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "08225117",
      "j": 2025,
      "n": "elztal",
      "dn": "Elztal",
      "bl": 8,
      "hs": 530.0,
      "mns": 1
    },
    {
      "a": "08225118",
      "j": 2023,
      "n": "waldbrunn",
      "dn": "Waldbrunn",
      "bl": 8,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "08226003",
      "j": 2025,
      "n": "altlußheim",
      "dn": "Altlußheim",
      "bl": 8,
      "hs": 125.0,
      "mns": 2
    },
    {
      "a": "08226006",
      "j": 2023,
      "n": "bammental",
      "dn": "Bammental",
      "bl": 8,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "08226009",
      "j": 2023,
      "n": "brühl",
      "dn": "Brühl",
      "bl": 8,
      "hs": 380.0,
      "mns": 4
    },
    {
      "a": "08226010",
      "j": 2023,
      "n": "dielheim",
      "dn": "Dielheim",
      "bl": 8,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "08226012",
      "j": 2023,
      "n": "dossenheim",
      "dn": "Dossenheim",
      "bl": 8,
      "hs": 370.0,
      "mns": 6
    },
    {
      "a": "08226013",
      "j": 2023,
      "n": "eberbach",
      "dn": "Eberbach",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08226017",
      "j": 2023,
      "n": "epfenbach",
      "dn": "Epfenbach",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08226018",
      "j": 2025,
      "n": "eppelheim",
      "dn": "Eppelheim",
      "bl": 8,
      "hs": 220.0,
      "mns": 5
    },
    {
      "a": "08226020",
      "j": 2023,
      "n": "eschelbronn",
      "dn": "Eschelbronn",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08226022",
      "j": 2023,
      "n": "gaiberg",
      "dn": "Gaiberg",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08226027",
      "j": 2023,
      "n": "heddesbach",
      "dn": "Heddesbach",
      "bl": 8,
      "hs": 280.0,
      "mns": 2
    },
    {
      "a": "08226028",
      "j": 2023,
      "n": "heddesheim",
      "dn": "Heddesheim",
      "bl": 8,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "08226029",
      "j": 2023,
      "n": "heiligkreuzsteinach",
      "dn": "Heiligkreuzsteinach",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08226031",
      "j": 2023,
      "n": "hemsbach",
      "dn": "Hemsbach",
      "bl": 8,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "08226032",
      "j": 2023,
      "n": "hockenheim",
      "dn": "Hockenheim",
      "bl": 8,
      "hs": 440.0,
      "mns": 4
    },
    {
      "a": "08226036",
      "j": 2023,
      "n": "ilvesheim",
      "dn": "Ilvesheim",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08226037",
      "j": 2023,
      "n": "ketsch",
      "dn": "Ketsch",
      "bl": 8,
      "hs": 440.0,
      "mns": 4
    },
    {
      "a": "08226038",
      "j": 2023,
      "n": "ladenburg",
      "dn": "Ladenburg",
      "bl": 8,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "08226040",
      "j": 2023,
      "n": "laudenbach",
      "dn": "Laudenbach",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08226041",
      "j": 2023,
      "n": "leimen",
      "dn": "Leimen",
      "bl": 8,
      "hs": 420.0,
      "mns": 5
    },
    {
      "a": "08226046",
      "j": 2023,
      "n": "malsch",
      "dn": "Malsch",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08226048",
      "j": 2023,
      "n": "mauer",
      "dn": "Mauer",
      "bl": 8,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "08226049",
      "j": 2023,
      "n": "meckesheim",
      "dn": "Meckesheim",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08226054",
      "j": 2023,
      "n": "mühlhausen",
      "dn": "Mühlhausen",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08226055",
      "j": 2023,
      "n": "neckarbischofsheim",
      "dn": "Neckarbischofsheim",
      "bl": 8,
      "hs": 485.0,
      "mns": 2
    },
    {
      "a": "08226056",
      "j": 2023,
      "n": "neckargemünd",
      "dn": "Neckargemünd",
      "bl": 8,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "08226058",
      "j": 2023,
      "n": "neidenstein",
      "dn": "Neidenstein",
      "bl": 8,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "08226059",
      "j": 2023,
      "n": "neulußheim",
      "dn": "Neulußheim",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08226060",
      "j": 2023,
      "n": "nußloch",
      "dn": "Nußloch",
      "bl": 8,
      "hs": 320.0,
      "mns": 5
    },
    {
      "a": "08226062",
      "j": 2023,
      "n": "oftersheim",
      "dn": "Oftersheim",
      "bl": 8,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "08226063",
      "j": 2023,
      "n": "plankstadt",
      "dn": "Plankstadt",
      "bl": 8,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "08226065",
      "j": 2023,
      "n": "rauenberg",
      "dn": "Rauenberg",
      "bl": 8,
      "hs": 385.0,
      "mns": 2
    },
    {
      "a": "08226066",
      "j": 2023,
      "n": "reichartshausen",
      "dn": "Reichartshausen",
      "bl": 8,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "08226068",
      "j": 2023,
      "n": "reilingen",
      "dn": "Reilingen",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08226076",
      "j": 2023,
      "n": "sandhausen",
      "dn": "Sandhausen",
      "bl": 8,
      "hs": 340.0,
      "mns": 4
    },
    {
      "a": "08226080",
      "j": 2023,
      "n": "schönau",
      "dn": "Schönau",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08226081",
      "j": 2023,
      "n": "schönbrunn",
      "dn": "Schönbrunn",
      "bl": 8,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "08226082",
      "j": 2023,
      "n": "schriesheim",
      "dn": "Schriesheim",
      "bl": 8,
      "hs": 440.0,
      "mns": 4
    },
    {
      "a": "08226084",
      "j": 2023,
      "n": "schwetzingen",
      "dn": "Schwetzingen",
      "bl": 8,
      "hs": 460.0,
      "mns": 4
    },
    {
      "a": "08226085",
      "j": 2023,
      "n": "sinsheim",
      "dn": "Sinsheim",
      "bl": 8,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "08226086",
      "j": 2023,
      "n": "spechbach",
      "dn": "Spechbach",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08226091",
      "j": 2025,
      "n": "waibstadt",
      "dn": "Waibstadt",
      "bl": 8,
      "hs": 256.0,
      "mns": 2
    },
    {
      "a": "08226095",
      "j": 2023,
      "n": "walldorf",
      "dn": "Walldorf",
      "bl": 8,
      "hs": 200.0,
      "mns": 5
    },
    {
      "a": "08226096",
      "j": 2023,
      "n": "weinheim",
      "dn": "Weinheim",
      "bl": 8,
      "hs": 450.0,
      "mns": 4
    },
    {
      "a": "08226097",
      "j": 2023,
      "n": "wiesenbach",
      "dn": "Wiesenbach",
      "bl": 8,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "08226098",
      "j": 2023,
      "n": "wiesloch",
      "dn": "Wiesloch",
      "bl": 8,
      "hs": 420.0,
      "mns": 5
    },
    {
      "a": "08226099",
      "j": 2023,
      "n": "wilhelmsfeld",
      "dn": "Wilhelmsfeld",
      "bl": 8,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "08226101",
      "j": 2025,
      "n": "zuzenhausen",
      "dn": "Zuzenhausen",
      "bl": 8,
      "hs": 185.0,
      "mns": 2
    },
    {
      "a": "08226102",
      "j": 2023,
      "n": "angelbachtal",
      "dn": "Angelbachtal",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08226103",
      "j": 2023,
      "n": "st. leon-rot",
      "dn": "St. Leon-Rot",
      "bl": 8,
      "hs": 200.0,
      "mns": 3
    },
    {
      "a": "08226104",
      "j": 2023,
      "n": "lobbach",
      "dn": "Lobbach",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08226105",
      "j": 2023,
      "n": "edingen-neckarhausen",
      "dn": "Edingen-Neckarhausen",
      "bl": 8,
      "hs": 380.0,
      "mns": 4
    },
    {
      "a": "08226106",
      "j": 2023,
      "n": "helmstadt-bargen",
      "dn": "Helmstadt-Bargen",
      "bl": 8,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "08226107",
      "j": 2023,
      "n": "hirschberg an der bergstraße",
      "dn": "Hirschberg an der Bergstraße",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08231000",
      "j": 2023,
      "n": "pforzheim",
      "dn": "Pforzheim",
      "bl": 8,
      "hs": 550.0,
      "mns": 4
    },
    {
      "a": "08235006",
      "j": 2023,
      "n": "altensteig",
      "dn": "Altensteig",
      "bl": 8,
      "hs": 480.0,
      "mns": 2
    },
    {
      "a": "08235007",
      "j": 2023,
      "n": "althengstett",
      "dn": "Althengstett",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08235008",
      "j": 2023,
      "n": "bad liebenzell",
      "dn": "Bad Liebenzell",
      "bl": 8,
      "hs": 525.0,
      "mns": 2
    },
    {
      "a": "08235018",
      "j": 2023,
      "n": "dobel",
      "dn": "Dobel",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08235020",
      "j": 2023,
      "n": "ebhausen",
      "dn": "Ebhausen",
      "bl": 8,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "08235022",
      "j": 2023,
      "n": "egenhausen",
      "dn": "Egenhausen",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08235025",
      "j": 2023,
      "n": "enzklösterle",
      "dn": "Enzklösterle",
      "bl": 8,
      "hs": 445.0,
      "mns": 2
    },
    {
      "a": "08235029",
      "j": 2023,
      "n": "gechingen",
      "dn": "Gechingen",
      "bl": 8,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "08235032",
      "j": 2023,
      "n": "haiterbach",
      "dn": "Haiterbach",
      "bl": 8,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "08235033",
      "j": 2023,
      "n": "bad herrenalb",
      "dn": "Bad Herrenalb",
      "bl": 8,
      "hs": 480.0,
      "mns": 2
    },
    {
      "a": "08235035",
      "j": 2023,
      "n": "höfen an der enz",
      "dn": "Höfen an der Enz",
      "bl": 8,
      "hs": 480.0,
      "mns": 2
    },
    {
      "a": "08235046",
      "j": 2023,
      "n": "nagold",
      "dn": "Nagold",
      "bl": 8,
      "hs": 470.0,
      "mns": 4
    },
    {
      "a": "08235047",
      "j": 2023,
      "n": "neubulach",
      "dn": "Neubulach",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08235050",
      "j": 2023,
      "n": "neuweiler",
      "dn": "Neuweiler",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08235055",
      "j": 2023,
      "n": "oberreichenbach",
      "dn": "Oberreichenbach",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08235057",
      "j": 2023,
      "n": "ostelsheim",
      "dn": "Ostelsheim",
      "bl": 8,
      "hs": 280.0,
      "mns": 2
    },
    {
      "a": "08235060",
      "j": 2023,
      "n": "rohrdorf",
      "dn": "Rohrdorf",
      "bl": 8,
      "hs": 280.0,
      "mns": 2
    },
    {
      "a": "08235065",
      "j": 2023,
      "n": "schömberg",
      "dn": "Schömberg",
      "bl": 8,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "08235066",
      "j": 2023,
      "n": "simmersfeld",
      "dn": "Simmersfeld",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08235067",
      "j": 2023,
      "n": "simmozheim",
      "dn": "Simmozheim",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08235073",
      "j": 2023,
      "n": "unterreichenbach",
      "dn": "Unterreichenbach",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08235079",
      "j": 2023,
      "n": "bad wildbad",
      "dn": "Bad Wildbad",
      "bl": 8,
      "hs": 490.0,
      "mns": 2
    },
    {
      "a": "08235080",
      "j": 2023,
      "n": "wildberg",
      "dn": "Wildberg",
      "bl": 8,
      "hs": 490.0,
      "mns": 2
    },
    {
      "a": "08235084",
      "j": 2023,
      "n": "bad teinach-zavelstein",
      "dn": "Bad Teinach-Zavelstein",
      "bl": 8,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "08235085",
      "j": 2023,
      "n": "calw",
      "dn": "Calw",
      "bl": 8,
      "hs": 520.0,
      "mns": 3
    },
    {
      "a": "08236004",
      "j": 2023,
      "n": "birkenfeld",
      "dn": "Birkenfeld",
      "bl": 8,
      "hs": 280.0,
      "mns": 3
    },
    {
      "a": "08236011",
      "j": 2023,
      "n": "eisingen",
      "dn": "Eisingen",
      "bl": 8,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "08236013",
      "j": 2023,
      "n": "engelsbrand",
      "dn": "Engelsbrand",
      "bl": 8,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "08236019",
      "j": 2023,
      "n": "friolzheim",
      "dn": "Friolzheim",
      "bl": 8,
      "hs": 250.0,
      "mns": 2
    },
    {
      "a": "08236025",
      "j": 2023,
      "n": "heimsheim",
      "dn": "Heimsheim",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08236028",
      "j": 2024,
      "n": "illingen",
      "dn": "Illingen",
      "bl": 8,
      "hs": 155.0,
      "mns": 2
    },
    {
      "a": "08236030",
      "j": 2023,
      "n": "ispringen",
      "dn": "Ispringen",
      "bl": 8,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "08236031",
      "j": 2023,
      "n": "kieselbronn",
      "dn": "Kieselbronn",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08236033",
      "j": 2023,
      "n": "knittlingen",
      "dn": "Knittlingen",
      "bl": 8,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "08236038",
      "j": 2023,
      "n": "maulbronn",
      "dn": "Maulbronn",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08236039",
      "j": 2023,
      "n": "mönsheim",
      "dn": "Mönsheim",
      "bl": 8,
      "hs": 305.0,
      "mns": 2
    },
    {
      "a": "08236040",
      "j": 2023,
      "n": "mühlacker",
      "dn": "Mühlacker",
      "bl": 8,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "08236043",
      "j": 2023,
      "n": "neuenbürg",
      "dn": "Neuenbürg",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08236044",
      "j": 2023,
      "n": "neuhausen",
      "dn": "Neuhausen",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08236046",
      "j": 2023,
      "n": "niefern-öschelbronn",
      "dn": "Niefern-Öschelbronn",
      "bl": 8,
      "hs": 280.0,
      "mns": 3
    },
    {
      "a": "08236050",
      "j": 2023,
      "n": "ötisheim",
      "dn": "Ötisheim",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08236061",
      "j": 2023,
      "n": "sternenfels",
      "dn": "Sternenfels",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08236062",
      "j": 2023,
      "n": "tiefenbronn",
      "dn": "Tiefenbronn",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08236065",
      "j": 2023,
      "n": "wiernsheim",
      "dn": "Wiernsheim",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08236067",
      "j": 2023,
      "n": "wimsheim",
      "dn": "Wimsheim",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08236068",
      "j": 2023,
      "n": "wurmberg",
      "dn": "Wurmberg",
      "bl": 8,
      "hs": 290.0,
      "mns": 2
    },
    {
      "a": "08236070",
      "j": 2023,
      "n": "keltern",
      "dn": "Keltern",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08236071",
      "j": 2023,
      "n": "remchingen",
      "dn": "Remchingen",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08236072",
      "j": 2023,
      "n": "straubenhardt",
      "dn": "Straubenhardt",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08236073",
      "j": 2023,
      "n": "neulingen",
      "dn": "Neulingen",
      "bl": 8,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "08236074",
      "j": 2023,
      "n": "kämpfelbach",
      "dn": "Kämpfelbach",
      "bl": 8,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "08236075",
      "j": 2023,
      "n": "ölbronn-dürrn",
      "dn": "Ölbronn-Dürrn",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08236076",
      "j": 2025,
      "n": "königsbach-stein",
      "dn": "Königsbach-Stein",
      "bl": 8,
      "hs": 180.0,
      "mns": 2
    },
    {
      "a": "08237002",
      "j": 2023,
      "n": "alpirsbach",
      "dn": "Alpirsbach",
      "bl": 8,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "08237004",
      "j": 2023,
      "n": "baiersbronn",
      "dn": "Baiersbronn",
      "bl": 8,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "08237019",
      "j": 2023,
      "n": "dornstetten",
      "dn": "Dornstetten",
      "bl": 8,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "08237024",
      "j": 2023,
      "n": "empfingen",
      "dn": "Empfingen",
      "bl": 8,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "08237027",
      "j": 2023,
      "n": "eutingen im gäu",
      "dn": "Eutingen im Gäu",
      "bl": 8,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "08237028",
      "j": 2023,
      "n": "freudenstadt",
      "dn": "Freudenstadt",
      "bl": 8,
      "hs": 450.0,
      "mns": 3
    },
    {
      "a": "08237030",
      "j": 2023,
      "n": "glatten",
      "dn": "Glatten",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08237032",
      "j": 2023,
      "n": "grömbach",
      "dn": "Grömbach",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08237040",
      "j": 2024,
      "n": "horb am neckar",
      "dn": "Horb am Neckar",
      "bl": 8,
      "hs": 408.0,
      "mns": 2
    },
    {
      "a": "08237045",
      "j": 2023,
      "n": "loßburg",
      "dn": "Loßburg",
      "bl": 8,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "08237054",
      "j": 2023,
      "n": "pfalzgrafenweiler",
      "dn": "Pfalzgrafenweiler",
      "bl": 8,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "08237061",
      "j": 2023,
      "n": "schopfloch",
      "dn": "Schopfloch",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08237072",
      "j": 2023,
      "n": "wörnersberg",
      "dn": "Wörnersberg",
      "bl": 8,
      "hs": 435.0,
      "mns": 1
    },
    {
      "a": "08237073",
      "j": 2023,
      "n": "seewald",
      "dn": "Seewald",
      "bl": 8,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "08237074",
      "j": 2023,
      "n": "waldachtal",
      "dn": "Waldachtal",
      "bl": 8,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "08237075",
      "j": 2023,
      "n": "bad rippoldsau-schapbach",
      "dn": "Bad Rippoldsau-Schapbach",
      "bl": 8,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "08311000",
      "j": 2025,
      "n": "freiburg im breisgau",
      "dn": "Freiburg im Breisgau",
      "bl": 8,
      "hs": 235.0,
      "mns": 6
    },
    {
      "a": "08315003",
      "j": 2023,
      "n": "au",
      "dn": "Au",
      "bl": 8,
      "hs": 460.0,
      "mns": 3
    },
    {
      "a": "08315004",
      "j": 2023,
      "n": "auggen",
      "dn": "Auggen",
      "bl": 8,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "08315006",
      "j": 2023,
      "n": "bad krozingen",
      "dn": "Bad Krozingen",
      "bl": 8,
      "hs": 400.0,
      "mns": 5
    },
    {
      "a": "08315007",
      "j": 2023,
      "n": "badenweiler",
      "dn": "Badenweiler",
      "bl": 8,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "08315008",
      "j": 2023,
      "n": "ballrechten-dottingen",
      "dn": "Ballrechten-Dottingen",
      "bl": 8,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "08315013",
      "j": 2023,
      "n": "bötzingen",
      "dn": "Bötzingen",
      "bl": 8,
      "hs": 280.0,
      "mns": 3
    },
    {
      "a": "08315014",
      "j": 2023,
      "n": "bollschweil",
      "dn": "Bollschweil",
      "bl": 8,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "08315015",
      "j": 2024,
      "n": "breisach am rhein",
      "dn": "Breisach am Rhein",
      "bl": 8,
      "hs": 440.0,
      "mns": 4
    },
    {
      "a": "08315016",
      "j": 2023,
      "n": "breitnau",
      "dn": "Breitnau",
      "bl": 8,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "08315020",
      "j": 2023,
      "n": "buchenbach",
      "dn": "Buchenbach",
      "bl": 8,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "08315022",
      "j": 2023,
      "n": "buggingen",
      "dn": "Buggingen",
      "bl": 8,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "08315028",
      "j": 2023,
      "n": "ebringen",
      "dn": "Ebringen",
      "bl": 8,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "08315030",
      "j": 2023,
      "n": "eichstetten am kaiserstuhl",
      "dn": "Eichstetten am Kaiserstuhl",
      "bl": 8,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "08315031",
      "j": 2023,
      "n": "eisenbach (hochschwarzwald)",
      "dn": "Eisenbach (Hochschwarzwald)",
      "bl": 8,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "08315033",
      "j": 2023,
      "n": "eschbach",
      "dn": "Eschbach",
      "bl": 8,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "08315037",
      "j": 2023,
      "n": "feldberg (schwarzwald)",
      "dn": "Feldberg (Schwarzwald)",
      "bl": 8,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "08315039",
      "j": 2023,
      "n": "friedenweiler",
      "dn": "Friedenweiler",
      "bl": 8,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "08315041",
      "j": 2023,
      "n": "glottertal",
      "dn": "Glottertal",
      "bl": 8,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "08315043",
      "j": 2023,
      "n": "gottenheim",
      "dn": "Gottenheim",
      "bl": 8,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "08315047",
      "j": 2023,
      "n": "gundelfingen",
      "dn": "Gundelfingen",
      "bl": 8,
      "hs": 365.0,
      "mns": 6
    },
    {
      "a": "08315048",
      "j": 2023,
      "n": "hartheim am rhein",
      "dn": "Hartheim am Rhein",
      "bl": 8,
      "hs": 365.0,
      "mns": 3
    },
    {
      "a": "08315050",
      "j": 2023,
      "n": "heitersheim",
      "dn": "Heitersheim",
      "bl": 8,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "08315051",
      "j": 2023,
      "n": "heuweiler",
      "dn": "Heuweiler",
      "bl": 8,
      "hs": 385.0,
      "mns": 3
    },
    {
      "a": "08315052",
      "j": 2023,
      "n": "hinterzarten",
      "dn": "Hinterzarten",
      "bl": 8,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "08315056",
      "j": 2023,
      "n": "horben",
      "dn": "Horben",
      "bl": 8,
      "hs": 510.0,
      "mns": 3
    },
    {
      "a": "08315059",
      "j": 2023,
      "n": "ihringen",
      "dn": "Ihringen",
      "bl": 8,
      "hs": 430.0,
      "mns": 3
    },
    {
      "a": "08315064",
      "j": 2023,
      "n": "kirchzarten",
      "dn": "Kirchzarten",
      "bl": 8,
      "hs": 450.0,
      "mns": 3
    },
    {
      "a": "08315068",
      "j": 2023,
      "n": "lenzkirch",
      "dn": "Lenzkirch",
      "bl": 8,
      "hs": 500.0,
      "mns": 3
    },
    {
      "a": "08315070",
      "j": 2023,
      "n": "löffingen",
      "dn": "Löffingen",
      "bl": 8,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "08315072",
      "j": 2023,
      "n": "merdingen",
      "dn": "Merdingen",
      "bl": 8,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "08315073",
      "j": 2023,
      "n": "merzhausen",
      "dn": "Merzhausen",
      "bl": 8,
      "hs": 475.0,
      "mns": 3
    },
    {
      "a": "08315074",
      "j": 2023,
      "n": "müllheim",
      "dn": "Müllheim",
      "bl": 8,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "08315076",
      "j": 2023,
      "n": "neuenburg am rhein",
      "dn": "Neuenburg am Rhein",
      "bl": 8,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "08315084",
      "j": 2023,
      "n": "oberried",
      "dn": "Oberried",
      "bl": 8,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "08315089",
      "j": 2023,
      "n": "pfaffenweiler",
      "dn": "Pfaffenweiler",
      "bl": 8,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "08315094",
      "j": 2023,
      "n": "st. märgen",
      "dn": "St. Märgen",
      "bl": 8,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "08315095",
      "j": 2023,
      "n": "st. peter",
      "dn": "St. Peter",
      "bl": 8,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "08315098",
      "j": 2023,
      "n": "schallstadt",
      "dn": "Schallstadt",
      "bl": 8,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "08315102",
      "j": 2023,
      "n": "schluchsee",
      "dn": "Schluchsee",
      "bl": 8,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "08315107",
      "j": 2023,
      "n": "sölden",
      "dn": "Sölden",
      "bl": 8,
      "hs": 475.0,
      "mns": 3
    },
    {
      "a": "08315108",
      "j": 2023,
      "n": "staufen im breisgau",
      "dn": "Staufen im Breisgau",
      "bl": 8,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "08315109",
      "j": 2023,
      "n": "stegen",
      "dn": "Stegen",
      "bl": 8,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "08315111",
      "j": 2023,
      "n": "sulzburg",
      "dn": "Sulzburg",
      "bl": 8,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "08315113",
      "j": 2023,
      "n": "titisee-neustadt",
      "dn": "Titisee-Neustadt",
      "bl": 8,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "08315115",
      "j": 2023,
      "n": "umkirch",
      "dn": "Umkirch",
      "bl": 8,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "08315125",
      "j": 2023,
      "n": "wittnau",
      "dn": "Wittnau",
      "bl": 8,
      "hs": 520.0,
      "mns": 3
    },
    {
      "a": "08315130",
      "j": 2023,
      "n": "münstertal/schwarzwald",
      "dn": "Münstertal/Schwarzwald",
      "bl": 8,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "08315131",
      "j": 2023,
      "n": "ehrenkirchen",
      "dn": "Ehrenkirchen",
      "bl": 8,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "08315132",
      "j": 2023,
      "n": "march",
      "dn": "March",
      "bl": 8,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "08315133",
      "j": 2023,
      "n": "vogtsburg im kaiserstuhl",
      "dn": "Vogtsburg im Kaiserstuhl",
      "bl": 8,
      "hs": 440.0,
      "mns": 3
    },
    {
      "a": "08316002",
      "j": 2023,
      "n": "bahlingen am kaiserstuhl",
      "dn": "Bahlingen am Kaiserstuhl",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08316003",
      "j": 2023,
      "n": "biederbach",
      "dn": "Biederbach",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08316009",
      "j": 2023,
      "n": "denzlingen",
      "dn": "Denzlingen",
      "bl": 8,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "08316010",
      "j": 2023,
      "n": "elzach",
      "dn": "Elzach",
      "bl": 8,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "08316011",
      "j": 2023,
      "n": "emmendingen",
      "dn": "Emmendingen",
      "bl": 8,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "08316012",
      "j": 2023,
      "n": "endingen am kaiserstuhl",
      "dn": "Endingen am Kaiserstuhl",
      "bl": 8,
      "hs": 500.0,
      "mns": 2
    },
    {
      "a": "08316013",
      "j": 2023,
      "n": "forchheim",
      "dn": "Forchheim",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08316014",
      "j": 2023,
      "n": "gutach im breisgau",
      "dn": "Gutach im Breisgau",
      "bl": 8,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "08316017",
      "j": 2023,
      "n": "herbolzheim",
      "dn": "Herbolzheim",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08316020",
      "j": 2023,
      "n": "kenzingen",
      "dn": "Kenzingen",
      "bl": 8,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "08316024",
      "j": 2023,
      "n": "malterdingen",
      "dn": "Malterdingen",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08316036",
      "j": 2023,
      "n": "reute",
      "dn": "Reute",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08316037",
      "j": 2023,
      "n": "riegel am kaiserstuhl",
      "dn": "Riegel am Kaiserstuhl",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08316038",
      "j": 2023,
      "n": "sasbach am kaiserstuhl",
      "dn": "Sasbach am Kaiserstuhl",
      "bl": 8,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "08316039",
      "j": 2023,
      "n": "sexau",
      "dn": "Sexau",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08316042",
      "j": 2023,
      "n": "simonswald",
      "dn": "Simonswald",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08316043",
      "j": 2023,
      "n": "teningen",
      "dn": "Teningen",
      "bl": 8,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "08316045",
      "j": 2023,
      "n": "vörstetten",
      "dn": "Vörstetten",
      "bl": 8,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "08316049",
      "j": 2023,
      "n": "weisweil",
      "dn": "Weisweil",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08316051",
      "j": 2023,
      "n": "wyhl am kaiserstuhl",
      "dn": "Wyhl am Kaiserstuhl",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08316053",
      "j": 2023,
      "n": "rheinhausen",
      "dn": "Rheinhausen",
      "bl": 8,
      "hs": 520.0,
      "mns": 2
    },
    {
      "a": "08316054",
      "j": 2023,
      "n": "freiamt",
      "dn": "Freiamt",
      "bl": 8,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "08316055",
      "j": 2023,
      "n": "winden im elztal",
      "dn": "Winden im Elztal",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08316056",
      "j": 2023,
      "n": "waldkirch",
      "dn": "Waldkirch",
      "bl": 8,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "08317001",
      "j": 2023,
      "n": "achern",
      "dn": "Achern",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08317005",
      "j": 2023,
      "n": "appenweier",
      "dn": "Appenweier",
      "bl": 8,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "08317008",
      "j": 2023,
      "n": "bad peterstal-griesbach",
      "dn": "Bad Peterstal-Griesbach",
      "bl": 8,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "08317009",
      "j": 2023,
      "n": "berghaupten",
      "dn": "Berghaupten",
      "bl": 8,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "08317011",
      "j": 2023,
      "n": "biberach",
      "dn": "Biberach",
      "bl": 8,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "08317021",
      "j": 2023,
      "n": "durbach",
      "dn": "Durbach",
      "bl": 8,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "08317026",
      "j": 2023,
      "n": "ettenheim",
      "dn": "Ettenheim",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08317029",
      "j": 2023,
      "n": "fischerbach",
      "dn": "Fischerbach",
      "bl": 8,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "08317031",
      "j": 2023,
      "n": "friesenheim",
      "dn": "Friesenheim",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08317034",
      "j": 2023,
      "n": "gengenbach",
      "dn": "Gengenbach",
      "bl": 8,
      "hs": 480.0,
      "mns": 2
    },
    {
      "a": "08317039",
      "j": 2023,
      "n": "gutach (schwarzwaldbahn)",
      "dn": "Gutach (Schwarzwaldbahn)",
      "bl": 8,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "08317040",
      "j": 2023,
      "n": "haslach im kinzigtal",
      "dn": "Haslach im Kinzigtal",
      "bl": 8,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "08317041",
      "j": 2023,
      "n": "hausach",
      "dn": "Hausach",
      "bl": 8,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "08317046",
      "j": 2023,
      "n": "hofstetten",
      "dn": "Hofstetten",
      "bl": 8,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "08317047",
      "j": 2023,
      "n": "hohberg",
      "dn": "Hohberg",
      "bl": 8,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "08317051",
      "j": 2023,
      "n": "hornberg",
      "dn": "Hornberg",
      "bl": 8,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "08317056",
      "j": 2023,
      "n": "kappelrodeck",
      "dn": "Kappelrodeck",
      "bl": 8,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "08317057",
      "j": 2023,
      "n": "kehl",
      "dn": "Kehl",
      "bl": 8,
      "hs": 460.0,
      "mns": 3
    },
    {
      "a": "08317059",
      "j": 2023,
      "n": "kippenheim",
      "dn": "Kippenheim",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08317065",
      "j": 2023,
      "n": "lahr/schwarzwald",
      "dn": "Lahr/Schwarzwald",
      "bl": 8,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "08317067",
      "j": 2023,
      "n": "lautenbach",
      "dn": "Lautenbach",
      "bl": 8,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "08317068",
      "j": 2023,
      "n": "lauf",
      "dn": "Lauf",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08317073",
      "j": 2023,
      "n": "mahlberg",
      "dn": "Mahlberg",
      "bl": 8,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "08317075",
      "j": 2023,
      "n": "meißenheim",
      "dn": "Meißenheim",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08317078",
      "j": 2023,
      "n": "mühlenbach",
      "dn": "Mühlenbach",
      "bl": 8,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "08317085",
      "j": 2023,
      "n": "nordrach",
      "dn": "Nordrach",
      "bl": 8,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "08317088",
      "j": 2023,
      "n": "oberharmersbach",
      "dn": "Oberharmersbach",
      "bl": 8,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "08317089",
      "j": 2023,
      "n": "oberkirch",
      "dn": "Oberkirch",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08317093",
      "j": 2023,
      "n": "oberwolfach",
      "dn": "Oberwolfach",
      "bl": 8,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "08317096",
      "j": 2023,
      "n": "offenburg",
      "dn": "Offenburg",
      "bl": 8,
      "hs": 420.0,
      "mns": 3
    },
    {
      "a": "08317097",
      "j": 2023,
      "n": "ohlsbach",
      "dn": "Ohlsbach",
      "bl": 8,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "08317098",
      "j": 2023,
      "n": "oppenau",
      "dn": "Oppenau",
      "bl": 8,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "08317100",
      "j": 2023,
      "n": "ortenberg",
      "dn": "Ortenberg",
      "bl": 8,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "08317102",
      "j": 2023,
      "n": "ottenhöfen im schwarzwald",
      "dn": "Ottenhöfen im Schwarzwald",
      "bl": 8,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "08317110",
      "j": 2023,
      "n": "renchen",
      "dn": "Renchen",
      "bl": 8,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "08317113",
      "j": 2023,
      "n": "ringsheim",
      "dn": "Ringsheim",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08317114",
      "j": 2023,
      "n": "rust",
      "dn": "Rust",
      "bl": 8,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "08317116",
      "j": 2023,
      "n": "sasbach",
      "dn": "Sasbach",
      "bl": 8,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "08317118",
      "j": 2023,
      "n": "sasbachwalden",
      "dn": "Sasbachwalden",
      "bl": 8,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "08317121",
      "j": 2023,
      "n": "schuttertal",
      "dn": "Schuttertal",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08317122",
      "j": 2023,
      "n": "schutterwald",
      "dn": "Schutterwald",
      "bl": 8,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "08317126",
      "j": 2023,
      "n": "seebach",
      "dn": "Seebach",
      "bl": 8,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "08317127",
      "j": 2023,
      "n": "seelbach",
      "dn": "Seelbach",
      "bl": 8,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "08317129",
      "j": 2023,
      "n": "steinach",
      "dn": "Steinach",
      "bl": 8,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "08317141",
      "j": 2023,
      "n": "willstätt",
      "dn": "Willstätt",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08317145",
      "j": 2023,
      "n": "wolfach",
      "dn": "Wolfach",
      "bl": 8,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "08317146",
      "j": 2023,
      "n": "zell am harmersbach",
      "dn": "Zell am Harmersbach",
      "bl": 8,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "08317150",
      "j": 2023,
      "n": "schwanau",
      "dn": "Schwanau",
      "bl": 8,
      "hs": 290.0,
      "mns": 1
    },
    {
      "a": "08317151",
      "j": 2023,
      "n": "neuried",
      "dn": "Neuried",
      "bl": 8,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "08317152",
      "j": 2023,
      "n": "kappel-grafenhausen",
      "dn": "Kappel-Grafenhausen",
      "bl": 8,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "08317153",
      "j": 2023,
      "n": "rheinau",
      "dn": "Rheinau",
      "bl": 8,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "08325001",
      "j": 2023,
      "n": "aichhalden",
      "dn": "Aichhalden",
      "bl": 8,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "08325009",
      "j": 2023,
      "n": "bösingen",
      "dn": "Bösingen",
      "bl": 8,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "08325011",
      "j": 2023,
      "n": "dietingen",
      "dn": "Dietingen",
      "bl": 8,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "08325012",
      "j": 2023,
      "n": "dornhan",
      "dn": "Dornhan",
      "bl": 8,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "08325014",
      "j": 2023,
      "n": "dunningen",
      "dn": "Dunningen",
      "bl": 8,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "08325015",
      "j": 2023,
      "n": "epfendorf",
      "dn": "Epfendorf",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08325024",
      "j": 2023,
      "n": "hardt",
      "dn": "Hardt",
      "bl": 8,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "08325036",
      "j": 2023,
      "n": "lauterbach",
      "dn": "Lauterbach",
      "bl": 8,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "08325045",
      "j": 2023,
      "n": "oberndorf am neckar",
      "dn": "Oberndorf am Neckar",
      "bl": 8,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "08325049",
      "j": 2023,
      "n": "rottweil",
      "dn": "Rottweil",
      "bl": 8,
      "hs": 430.0,
      "mns": 3
    },
    {
      "a": "08325050",
      "j": 2023,
      "n": "schenkenzell",
      "dn": "Schenkenzell",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08325051",
      "j": 2023,
      "n": "schiltach",
      "dn": "Schiltach",
      "bl": 8,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "08325053",
      "j": 2023,
      "n": "schramberg",
      "dn": "Schramberg",
      "bl": 8,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "08325057",
      "j": 2023,
      "n": "sulz am neckar",
      "dn": "Sulz am Neckar",
      "bl": 8,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "08325060",
      "j": 2023,
      "n": "villingendorf",
      "dn": "Villingendorf",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08325061",
      "j": 2023,
      "n": "vöhringen",
      "dn": "Vöhringen",
      "bl": 8,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "08325064",
      "j": 2023,
      "n": "wellendingen",
      "dn": "Wellendingen",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08325069",
      "j": 2023,
      "n": "zimmern ob rottweil",
      "dn": "Zimmern ob Rottweil",
      "bl": 8,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "08325070",
      "j": 2023,
      "n": "fluorn-winzeln",
      "dn": "Fluorn-Winzeln",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08325071",
      "j": 2023,
      "n": "eschbronn",
      "dn": "Eschbronn",
      "bl": 8,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "08325072",
      "j": 2023,
      "n": "deißlingen",
      "dn": "Deißlingen",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08326003",
      "j": 2023,
      "n": "bad dürrheim",
      "dn": "Bad Dürrheim",
      "bl": 8,
      "hs": 500.0,
      "mns": 3
    },
    {
      "a": "08326005",
      "j": 2023,
      "n": "blumberg",
      "dn": "Blumberg",
      "bl": 8,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "08326006",
      "j": 2023,
      "n": "bräunlingen",
      "dn": "Bräunlingen",
      "bl": 8,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "08326010",
      "j": 2023,
      "n": "dauchingen",
      "dn": "Dauchingen",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08326012",
      "j": 2023,
      "n": "donaueschingen",
      "dn": "Donaueschingen",
      "bl": 8,
      "hs": 435.0,
      "mns": 2
    },
    {
      "a": "08326017",
      "j": 2023,
      "n": "furtwangen im schwarzwald",
      "dn": "Furtwangen im Schwarzwald",
      "bl": 8,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "08326020",
      "j": 2023,
      "n": "gütenbach",
      "dn": "Gütenbach",
      "bl": 8,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "08326027",
      "j": 2023,
      "n": "hüfingen",
      "dn": "Hüfingen",
      "bl": 8,
      "hs": 435.0,
      "mns": 2
    },
    {
      "a": "08326031",
      "j": 2023,
      "n": "königsfeld im schwarzwald",
      "dn": "Königsfeld im Schwarzwald",
      "bl": 8,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "08326037",
      "j": 2023,
      "n": "mönchweiler",
      "dn": "Mönchweiler",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08326041",
      "j": 2023,
      "n": "niedereschach",
      "dn": "Niedereschach",
      "bl": 8,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "08326052",
      "j": 2023,
      "n": "st. georgen im schwarzwald",
      "dn": "St. Georgen im Schwarzwald",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08326054",
      "j": 2023,
      "n": "schönwald im schwarzwald",
      "dn": "Schönwald im Schwarzwald",
      "bl": 8,
      "hs": 460.0,
      "mns": 2
    },
    {
      "a": "08326055",
      "j": 2023,
      "n": "schonach im schwarzwald",
      "dn": "Schonach im Schwarzwald",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08326060",
      "j": 2023,
      "n": "triberg im schwarzwald",
      "dn": "Triberg im Schwarzwald",
      "bl": 8,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "08326061",
      "j": 2023,
      "n": "tuningen",
      "dn": "Tuningen",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08326065",
      "j": 2023,
      "n": "unterkirnach",
      "dn": "Unterkirnach",
      "bl": 8,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "08326068",
      "j": 2023,
      "n": "vöhrenbach",
      "dn": "Vöhrenbach",
      "bl": 8,
      "hs": 480.0,
      "mns": 2
    },
    {
      "a": "08326074",
      "j": 2023,
      "n": "villingen-schwenningen",
      "dn": "Villingen-Schwenningen",
      "bl": 8,
      "hs": 455.0,
      "mns": 3
    },
    {
      "a": "08326075",
      "j": 2023,
      "n": "brigachtal",
      "dn": "Brigachtal",
      "bl": 8,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "08327002",
      "j": 2023,
      "n": "aldingen",
      "dn": "Aldingen",
      "bl": 8,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "08327004",
      "j": 2023,
      "n": "bärenthal",
      "dn": "Bärenthal",
      "bl": 8,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "08327005",
      "j": 2023,
      "n": "balgheim",
      "dn": "Balgheim",
      "bl": 8,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "08327006",
      "j": 2023,
      "n": "böttingen",
      "dn": "Böttingen",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08327007",
      "j": 2023,
      "n": "bubsheim",
      "dn": "Bubsheim",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08327008",
      "j": 2023,
      "n": "buchheim",
      "dn": "Buchheim",
      "bl": 8,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "08327009",
      "j": 2023,
      "n": "deilingen",
      "dn": "Deilingen",
      "bl": 8,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "08327010",
      "j": 2023,
      "n": "denkingen",
      "dn": "Denkingen",
      "bl": 8,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "08327011",
      "j": 2023,
      "n": "dürbheim",
      "dn": "Dürbheim",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08327012",
      "j": 2023,
      "n": "durchhausen",
      "dn": "Durchhausen",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08327013",
      "j": 2023,
      "n": "egesheim",
      "dn": "Egesheim",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08327016",
      "j": 2023,
      "n": "fridingen an der donau",
      "dn": "Fridingen an der Donau",
      "bl": 8,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "08327017",
      "j": 2023,
      "n": "frittlingen",
      "dn": "Frittlingen",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08327018",
      "j": 2023,
      "n": "geisingen",
      "dn": "Geisingen",
      "bl": 8,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "08327019",
      "j": 2023,
      "n": "gosheim",
      "dn": "Gosheim",
      "bl": 8,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "08327020",
      "j": 2023,
      "n": "gunningen",
      "dn": "Gunningen",
      "bl": 8,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "08327023",
      "j": 2023,
      "n": "hausen ob verena",
      "dn": "Hausen ob Verena",
      "bl": 8,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "08327025",
      "j": 2023,
      "n": "immendingen",
      "dn": "Immendingen",
      "bl": 8,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "08327027",
      "j": 2023,
      "n": "irndorf",
      "dn": "Irndorf",
      "bl": 8,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "08327029",
      "j": 2023,
      "n": "königsheim",
      "dn": "Königsheim",
      "bl": 8,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "08327030",
      "j": 2025,
      "n": "kolbingen",
      "dn": "Kolbingen",
      "bl": 8,
      "hs": 630.0,
      "mns": 1
    },
    {
      "a": "08327033",
      "j": 2023,
      "n": "mahlstetten",
      "dn": "Mahlstetten",
      "bl": 8,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "08327036",
      "j": 2023,
      "n": "mühlheim an der donau",
      "dn": "Mühlheim an der Donau",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08327038",
      "j": 2023,
      "n": "neuhausen ob eck",
      "dn": "Neuhausen ob Eck",
      "bl": 8,
      "hs": 335.0,
      "mns": 1
    },
    {
      "a": "08327040",
      "j": 2023,
      "n": "reichenbach am heuberg",
      "dn": "Reichenbach am Heuberg",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08327041",
      "j": 2023,
      "n": "renquishausen",
      "dn": "Renquishausen",
      "bl": 8,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "08327046",
      "j": 2023,
      "n": "spaichingen",
      "dn": "Spaichingen",
      "bl": 8,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "08327048",
      "j": 2023,
      "n": "talheim",
      "dn": "Talheim",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08327049",
      "j": 2023,
      "n": "trossingen",
      "dn": "Trossingen",
      "bl": 8,
      "hs": 440.0,
      "mns": 3
    },
    {
      "a": "08327050",
      "j": 2023,
      "n": "tuttlingen",
      "dn": "Tuttlingen",
      "bl": 8,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "08327051",
      "j": 2023,
      "n": "wehingen",
      "dn": "Wehingen",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08327054",
      "j": 2023,
      "n": "wurmlingen",
      "dn": "Wurmlingen",
      "bl": 8,
      "hs": 270.0,
      "mns": 1
    },
    {
      "a": "08327055",
      "j": 2023,
      "n": "seitingen-oberflacht",
      "dn": "Seitingen-Oberflacht",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08327056",
      "j": 2023,
      "n": "rietheim-weilheim",
      "dn": "Rietheim-Weilheim",
      "bl": 8,
      "hs": 270.0,
      "mns": 1
    },
    {
      "a": "08327057",
      "j": 2023,
      "n": "emmingen-liptingen",
      "dn": "Emmingen-Liptingen",
      "bl": 8,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "08335001",
      "j": 2023,
      "n": "aach",
      "dn": "Aach",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08335002",
      "j": 2023,
      "n": "allensbach",
      "dn": "Allensbach",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08335015",
      "j": 2023,
      "n": "büsingen am hochrhein",
      "dn": "Büsingen am Hochrhein",
      "bl": 8,
      "hs": 0.0,
      "mns": 2
    },
    {
      "a": "08335021",
      "j": 2023,
      "n": "eigeltingen",
      "dn": "Eigeltingen",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08335022",
      "j": 2023,
      "n": "engen",
      "dn": "Engen",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08335025",
      "j": 2023,
      "n": "gaienhofen",
      "dn": "Gaienhofen",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08335026",
      "j": 2023,
      "n": "gailingen am hochrhein",
      "dn": "Gailingen am Hochrhein",
      "bl": 8,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "08335028",
      "j": 2023,
      "n": "gottmadingen",
      "dn": "Gottmadingen",
      "bl": 8,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "08335035",
      "j": 2023,
      "n": "hilzingen",
      "dn": "Hilzingen",
      "bl": 8,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "08335043",
      "j": 2023,
      "n": "konstanz",
      "dn": "Konstanz",
      "bl": 8,
      "hs": 410.0,
      "mns": 5
    },
    {
      "a": "08335055",
      "j": 2023,
      "n": "moos",
      "dn": "Moos",
      "bl": 8,
      "hs": 325.0,
      "mns": 2
    },
    {
      "a": "08335057",
      "j": 2023,
      "n": "mühlingen",
      "dn": "Mühlingen",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08335061",
      "j": 2023,
      "n": "öhningen",
      "dn": "Öhningen",
      "bl": 8,
      "hs": 325.0,
      "mns": 2
    },
    {
      "a": "08335063",
      "j": 2023,
      "n": "radolfzell am bodensee",
      "dn": "Radolfzell am Bodensee",
      "bl": 8,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "08335066",
      "j": 2023,
      "n": "reichenau",
      "dn": "Reichenau",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08335075",
      "j": 2023,
      "n": "singen (hohentwiel)",
      "dn": "Singen (Hohentwiel)",
      "bl": 8,
      "hs": 360.0,
      "mns": 4
    },
    {
      "a": "08335077",
      "j": 2023,
      "n": "steißlingen",
      "dn": "Steißlingen",
      "bl": 8,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "08335079",
      "j": 2023,
      "n": "stockach",
      "dn": "Stockach",
      "bl": 8,
      "hs": 395.0,
      "mns": 3
    },
    {
      "a": "08335080",
      "j": 2023,
      "n": "tengen",
      "dn": "Tengen",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08335081",
      "j": 2023,
      "n": "volkertshausen",
      "dn": "Volkertshausen",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08335096",
      "j": 2023,
      "n": "hohenfels",
      "dn": "Hohenfels",
      "bl": 8,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "08335097",
      "j": 2023,
      "n": "mühlhausen-ehingen",
      "dn": "Mühlhausen-Ehingen",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08335098",
      "j": 2023,
      "n": "bodman-ludwigshafen",
      "dn": "Bodman-Ludwigshafen",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08335099",
      "j": 2023,
      "n": "orsingen-nenzingen",
      "dn": "Orsingen-Nenzingen",
      "bl": 8,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "08335100",
      "j": 2023,
      "n": "rielasingen-worblingen",
      "dn": "Rielasingen-Worblingen",
      "bl": 8,
      "hs": 380.0,
      "mns": 4
    },
    {
      "a": "08336004",
      "j": 2023,
      "n": "aitern",
      "dn": "Aitern",
      "bl": 8,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "08336006",
      "j": 2023,
      "n": "bad bellingen",
      "dn": "Bad Bellingen",
      "bl": 8,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "08336008",
      "j": 2023,
      "n": "binzen",
      "dn": "Binzen",
      "bl": 8,
      "hs": 265.0,
      "mns": 3
    },
    {
      "a": "08336010",
      "j": 2023,
      "n": "böllen",
      "dn": "Böllen",
      "bl": 8,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "08336014",
      "j": 2025,
      "n": "efringen-kirchen",
      "dn": "Efringen-Kirchen",
      "bl": 8,
      "hs": 265.0,
      "mns": 3
    },
    {
      "a": "08336019",
      "j": 2023,
      "n": "eimeldingen",
      "dn": "Eimeldingen",
      "bl": 8,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "08336024",
      "j": 2023,
      "n": "fischingen",
      "dn": "Fischingen",
      "bl": 8,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "08336025",
      "j": 2023,
      "n": "fröhnd",
      "dn": "Fröhnd",
      "bl": 8,
      "hs": 420.0,
      "mns": 3
    },
    {
      "a": "08336034",
      "j": 2023,
      "n": "hasel",
      "dn": "Hasel",
      "bl": 8,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "08336036",
      "j": 2023,
      "n": "hausen im wiesental",
      "dn": "Hausen im Wiesental",
      "bl": 8,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "08336043",
      "j": 2023,
      "n": "inzlingen",
      "dn": "Inzlingen",
      "bl": 8,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "08336045",
      "j": 2023,
      "n": "kandern",
      "dn": "Kandern",
      "bl": 8,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "08336050",
      "j": 2023,
      "n": "lörrach",
      "dn": "Lörrach",
      "bl": 8,
      "hs": 450.0,
      "mns": 4
    },
    {
      "a": "08336057",
      "j": 2023,
      "n": "maulburg",
      "dn": "Maulburg",
      "bl": 8,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "08336069",
      "j": 2023,
      "n": "rheinfelden (baden)",
      "dn": "Rheinfelden (Baden)",
      "bl": 8,
      "hs": 420.0,
      "mns": 4
    },
    {
      "a": "08336073",
      "j": 2023,
      "n": "rümmingen",
      "dn": "Rümmingen",
      "bl": 8,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "08336075",
      "j": 2023,
      "n": "schallbach",
      "dn": "Schallbach",
      "bl": 8,
      "hs": 270.0,
      "mns": 3
    },
    {
      "a": "08336078",
      "j": 2023,
      "n": "schliengen",
      "dn": "Schliengen",
      "bl": 8,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "08336079",
      "j": 2023,
      "n": "schönau im schwarzwald",
      "dn": "Schönau im Schwarzwald",
      "bl": 8,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "08336080",
      "j": 2023,
      "n": "schönenberg",
      "dn": "Schönenberg",
      "bl": 8,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "08336081",
      "j": 2023,
      "n": "schopfheim",
      "dn": "Schopfheim",
      "bl": 8,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "08336082",
      "j": 2023,
      "n": "schwörstadt",
      "dn": "Schwörstadt",
      "bl": 8,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "08336084",
      "j": 2023,
      "n": "steinen",
      "dn": "Steinen",
      "bl": 8,
      "hs": 390.0,
      "mns": 5
    },
    {
      "a": "08336087",
      "j": 2023,
      "n": "todtnau",
      "dn": "Todtnau",
      "bl": 8,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "08336089",
      "j": 2023,
      "n": "tunau",
      "dn": "Tunau",
      "bl": 8,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "08336090",
      "j": 2023,
      "n": "utzenfeld",
      "dn": "Utzenfeld",
      "bl": 8,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "08336091",
      "j": 2023,
      "n": "weil am rhein",
      "dn": "Weil am Rhein",
      "bl": 8,
      "hs": 400.0,
      "mns": 5
    },
    {
      "a": "08336094",
      "j": 2023,
      "n": "wembach",
      "dn": "Wembach",
      "bl": 8,
      "hs": 280.0,
      "mns": 3
    },
    {
      "a": "08336096",
      "j": 2023,
      "n": "wieden",
      "dn": "Wieden",
      "bl": 8,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "08336100",
      "j": 2023,
      "n": "wittlingen",
      "dn": "Wittlingen",
      "bl": 8,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "08336103",
      "j": 2023,
      "n": "zell im wiesental",
      "dn": "Zell im Wiesental",
      "bl": 8,
      "hs": 430.0,
      "mns": 3
    },
    {
      "a": "08336104",
      "j": 2023,
      "n": "malsburg-marzell",
      "dn": "Malsburg-Marzell",
      "bl": 8,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "08336105",
      "j": 2023,
      "n": "grenzach-wyhlen",
      "dn": "Grenzach-Wyhlen",
      "bl": 8,
      "hs": 400.0,
      "mns": 5
    },
    {
      "a": "08336106",
      "j": 2023,
      "n": "häg-ehrsberg",
      "dn": "Häg-Ehrsberg",
      "bl": 8,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "08336107",
      "j": 2023,
      "n": "kleines wiesental",
      "dn": "Kleines Wiesental",
      "bl": 8,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "08337002",
      "j": 2023,
      "n": "albbruck",
      "dn": "Albbruck",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08337013",
      "j": 2023,
      "n": "bernau im schwarzwald",
      "dn": "Bernau im Schwarzwald",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08337022",
      "j": 2023,
      "n": "bonndorf im schwarzwald",
      "dn": "Bonndorf im Schwarzwald",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08337027",
      "j": 2023,
      "n": "dachsberg",
      "dn": "Dachsberg",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08337030",
      "j": 2023,
      "n": "dettighofen",
      "dn": "Dettighofen",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08337032",
      "j": 2025,
      "n": "dogern",
      "dn": "Dogern",
      "bl": 8,
      "hs": 520.0,
      "mns": 2
    },
    {
      "a": "08337038",
      "j": 2023,
      "n": "görwihl",
      "dn": "Görwihl",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08337039",
      "j": 2023,
      "n": "grafenhausen",
      "dn": "Grafenhausen",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08337045",
      "j": 2023,
      "n": "häusern",
      "dn": "Häusern",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08337049",
      "j": 2023,
      "n": "herrischried",
      "dn": "Herrischried",
      "bl": 8,
      "hs": 355.0,
      "mns": 2
    },
    {
      "a": "08337051",
      "j": 2023,
      "n": "höchenschwand",
      "dn": "Höchenschwand",
      "bl": 8,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "08337053",
      "j": 2023,
      "n": "hohentengen am hochrhein",
      "dn": "Hohentengen am Hochrhein",
      "bl": 8,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "08337059",
      "j": 2023,
      "n": "ibach",
      "dn": "Ibach",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08337060",
      "j": 2023,
      "n": "jestetten",
      "dn": "Jestetten",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08337062",
      "j": 2023,
      "n": "klettgau",
      "dn": "Klettgau",
      "bl": 8,
      "hs": 250.0,
      "mns": 2
    },
    {
      "a": "08337065",
      "j": 2023,
      "n": "lauchringen",
      "dn": "Lauchringen",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08337066",
      "j": 2023,
      "n": "laufenburg (baden)",
      "dn": "Laufenburg (Baden)",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08337070",
      "j": 2023,
      "n": "lottstetten",
      "dn": "Lottstetten",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08337076",
      "j": 2023,
      "n": "murg",
      "dn": "Murg",
      "bl": 8,
      "hs": 335.0,
      "mns": 2
    },
    {
      "a": "08337090",
      "j": 2023,
      "n": "rickenbach",
      "dn": "Rickenbach",
      "bl": 8,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "08337096",
      "j": 2023,
      "n": "bad säckingen",
      "dn": "Bad Säckingen",
      "bl": 8,
      "hs": 490.0,
      "mns": 4
    },
    {
      "a": "08337097",
      "j": 2023,
      "n": "st. blasien",
      "dn": "St. Blasien",
      "bl": 8,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "08337106",
      "j": 2025,
      "n": "stühlingen",
      "dn": "Stühlingen",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08337108",
      "j": 2023,
      "n": "todtmoos",
      "dn": "Todtmoos",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08337116",
      "j": 2023,
      "n": "wehr",
      "dn": "Wehr",
      "bl": 8,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "08337118",
      "j": 2023,
      "n": "weilheim",
      "dn": "Weilheim",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08337123",
      "j": 2023,
      "n": "wutöschingen",
      "dn": "Wutöschingen",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08337124",
      "j": 2023,
      "n": "eggingen",
      "dn": "Eggingen",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08337125",
      "j": 2023,
      "n": "küssaberg",
      "dn": "Küssaberg",
      "bl": 8,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "08337126",
      "j": 2023,
      "n": "waldshut-tiengen",
      "dn": "Waldshut-Tiengen",
      "bl": 8,
      "hs": 420.0,
      "mns": 3
    },
    {
      "a": "08337127",
      "j": 2023,
      "n": "wutach",
      "dn": "Wutach",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08337128",
      "j": 2023,
      "n": "ühlingen-birkendorf",
      "dn": "Ühlingen-Birkendorf",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08415014",
      "j": 2023,
      "n": "dettingen an der erms",
      "dn": "Dettingen an der Erms",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08415019",
      "j": 2023,
      "n": "eningen unter achalm",
      "dn": "Eningen unter Achalm",
      "bl": 8,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "08415027",
      "j": 2023,
      "n": "gomadingen",
      "dn": "Gomadingen",
      "bl": 8,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "08415028",
      "j": 2023,
      "n": "grabenstetten",
      "dn": "Grabenstetten",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08415029",
      "j": 2023,
      "n": "grafenberg",
      "dn": "Grafenberg",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08415034",
      "j": 2023,
      "n": "hayingen",
      "dn": "Hayingen",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08415039",
      "j": 2023,
      "n": "hülben",
      "dn": "Hülben",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08415048",
      "j": 2023,
      "n": "mehrstetten",
      "dn": "Mehrstetten",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08415050",
      "j": 2023,
      "n": "metzingen",
      "dn": "Metzingen",
      "bl": 8,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "08415053",
      "j": 2023,
      "n": "münsingen",
      "dn": "Münsingen",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08415058",
      "j": 2023,
      "n": "pfronstetten",
      "dn": "Pfronstetten",
      "bl": 8,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "08415059",
      "j": 2023,
      "n": "pfullingen",
      "dn": "Pfullingen",
      "bl": 8,
      "hs": 380.0,
      "mns": 4
    },
    {
      "a": "08415060",
      "j": 2023,
      "n": "pliezhausen",
      "dn": "Pliezhausen",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08415061",
      "j": 2023,
      "n": "reutlingen",
      "dn": "Reutlingen",
      "bl": 8,
      "hs": 500.0,
      "mns": 4
    },
    {
      "a": "08415062",
      "j": 2023,
      "n": "riederich",
      "dn": "Riederich",
      "bl": 8,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "08415073",
      "j": 2023,
      "n": "trochtelfingen",
      "dn": "Trochtelfingen",
      "bl": 8,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "08415078",
      "j": 2023,
      "n": "bad urach",
      "dn": "Bad Urach",
      "bl": 8,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "08415080",
      "j": 2023,
      "n": "wannweil",
      "dn": "Wannweil",
      "bl": 8,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "08415085",
      "j": 2023,
      "n": "zwiefalten",
      "dn": "Zwiefalten",
      "bl": 8,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "08415087",
      "j": 2023,
      "n": "walddorfhäslach",
      "dn": "Walddorfhäslach",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08415088",
      "j": 2023,
      "n": "römerstein",
      "dn": "Römerstein",
      "bl": 8,
      "hs": 355.0,
      "mns": 2
    },
    {
      "a": "08415089",
      "j": 2023,
      "n": "engstingen",
      "dn": "Engstingen",
      "bl": 8,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "08415090",
      "j": 2023,
      "n": "hohenstein",
      "dn": "Hohenstein",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08415091",
      "j": 2023,
      "n": "sonnenbühl",
      "dn": "Sonnenbühl",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08415092",
      "j": 2023,
      "n": "lichtenstein",
      "dn": "Lichtenstein",
      "bl": 8,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "08415093",
      "j": 2023,
      "n": "st. johann",
      "dn": "St. Johann",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08416006",
      "j": 2023,
      "n": "bodelshausen",
      "dn": "Bodelshausen",
      "bl": 8,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "08416009",
      "j": 2023,
      "n": "dettenhausen",
      "dn": "Dettenhausen",
      "bl": 8,
      "hs": 360.0,
      "mns": 4
    },
    {
      "a": "08416011",
      "j": 2023,
      "n": "dußlingen",
      "dn": "Dußlingen",
      "bl": 8,
      "hs": 340.0,
      "mns": 4
    },
    {
      "a": "08416015",
      "j": 2023,
      "n": "gomaringen",
      "dn": "Gomaringen",
      "bl": 8,
      "hs": 380.0,
      "mns": 4
    },
    {
      "a": "08416018",
      "j": 2023,
      "n": "hirrlingen",
      "dn": "Hirrlingen",
      "bl": 8,
      "hs": 320.0,
      "mns": 4
    },
    {
      "a": "08416022",
      "j": 2023,
      "n": "kirchentellinsfurt",
      "dn": "Kirchentellinsfurt",
      "bl": 8,
      "hs": 360.0,
      "mns": 4
    },
    {
      "a": "08416023",
      "j": 2023,
      "n": "kusterdingen",
      "dn": "Kusterdingen",
      "bl": 8,
      "hs": 500.0,
      "mns": 4
    },
    {
      "a": "08416025",
      "j": 2023,
      "n": "mössingen",
      "dn": "Mössingen",
      "bl": 8,
      "hs": 380.0,
      "mns": 4
    },
    {
      "a": "08416026",
      "j": 2023,
      "n": "nehren",
      "dn": "Nehren",
      "bl": 8,
      "hs": 370.0,
      "mns": 4
    },
    {
      "a": "08416031",
      "j": 2023,
      "n": "ofterdingen",
      "dn": "Ofterdingen",
      "bl": 8,
      "hs": 380.0,
      "mns": 4
    },
    {
      "a": "08416036",
      "j": 2023,
      "n": "rottenburg am neckar",
      "dn": "Rottenburg am Neckar",
      "bl": 8,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "08416041",
      "j": 2023,
      "n": "tübingen",
      "dn": "Tübingen",
      "bl": 8,
      "hs": 660.0,
      "mns": 7
    },
    {
      "a": "08416048",
      "j": 2023,
      "n": "ammerbuch",
      "dn": "Ammerbuch",
      "bl": 8,
      "hs": 360.0,
      "mns": 4
    },
    {
      "a": "08416049",
      "j": 2023,
      "n": "neustetten",
      "dn": "Neustetten",
      "bl": 8,
      "hs": 320.0,
      "mns": 4
    },
    {
      "a": "08416050",
      "j": 2023,
      "n": "starzach",
      "dn": "Starzach",
      "bl": 8,
      "hs": 550.0,
      "mns": 4
    },
    {
      "a": "08417002",
      "j": 2023,
      "n": "balingen",
      "dn": "Balingen",
      "bl": 8,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "08417008",
      "j": 2023,
      "n": "bisingen",
      "dn": "Bisingen",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08417010",
      "j": 2023,
      "n": "bitz",
      "dn": "Bitz",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08417013",
      "j": 2023,
      "n": "burladingen",
      "dn": "Burladingen",
      "bl": 8,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "08417014",
      "j": 2023,
      "n": "dautmergen",
      "dn": "Dautmergen",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08417015",
      "j": 2023,
      "n": "dormettingen",
      "dn": "Dormettingen",
      "bl": 8,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "08417016",
      "j": 2023,
      "n": "dotternhausen",
      "dn": "Dotternhausen",
      "bl": 8,
      "hs": 315.0,
      "mns": 1
    },
    {
      "a": "08417022",
      "j": 2023,
      "n": "geislingen",
      "dn": "Geislingen",
      "bl": 8,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "08417023",
      "j": 2023,
      "n": "grosselfingen",
      "dn": "Grosselfingen",
      "bl": 8,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "08417025",
      "j": 2023,
      "n": "haigerloch",
      "dn": "Haigerloch",
      "bl": 8,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "08417029",
      "j": 2023,
      "n": "hausen am tann",
      "dn": "Hausen am Tann",
      "bl": 8,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "08417031",
      "j": 2023,
      "n": "hechingen",
      "dn": "Hechingen",
      "bl": 8,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "08417036",
      "j": 2023,
      "n": "jungingen",
      "dn": "Jungingen",
      "bl": 8,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "08417044",
      "j": 2023,
      "n": "meßstetten",
      "dn": "Meßstetten",
      "bl": 8,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "08417045",
      "j": 2023,
      "n": "nusplingen",
      "dn": "Nusplingen",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08417047",
      "j": 2023,
      "n": "obernheim",
      "dn": "Obernheim",
      "bl": 8,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "08417051",
      "j": 2023,
      "n": "rangendingen",
      "dn": "Rangendingen",
      "bl": 8,
      "hs": 250.0,
      "mns": 1
    },
    {
      "a": "08417052",
      "j": 2023,
      "n": "ratshausen",
      "dn": "Ratshausen",
      "bl": 8,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "08417054",
      "j": 2023,
      "n": "rosenfeld",
      "dn": "Rosenfeld",
      "bl": 8,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "08417057",
      "j": 2023,
      "n": "schömberg",
      "dn": "Schömberg",
      "bl": 8,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "08417063",
      "j": 2023,
      "n": "straßberg",
      "dn": "Straßberg",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08417071",
      "j": 2023,
      "n": "weilen unter den rinnen",
      "dn": "Weilen unter den Rinnen",
      "bl": 8,
      "hs": 335.0,
      "mns": 1
    },
    {
      "a": "08417075",
      "j": 2023,
      "n": "winterlingen",
      "dn": "Winterlingen",
      "bl": 8,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "08417078",
      "j": 2023,
      "n": "zimmern unter der burg",
      "dn": "Zimmern unter der Burg",
      "bl": 8,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "08417079",
      "j": 2023,
      "n": "albstadt",
      "dn": "Albstadt",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08421000",
      "j": 2025,
      "n": "ulm",
      "dn": "Ulm",
      "bl": 8,
      "hs": 390.0,
      "mns": 4
    },
    {
      "a": "08425002",
      "j": 2023,
      "n": "allmendingen",
      "dn": "Allmendingen",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08425004",
      "j": 2023,
      "n": "altheim",
      "dn": "Altheim",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08425005",
      "j": 2023,
      "n": "altheim (alb)",
      "dn": "Altheim (Alb)",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08425008",
      "j": 2023,
      "n": "amstetten",
      "dn": "Amstetten",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08425011",
      "j": 2023,
      "n": "asselfingen",
      "dn": "Asselfingen",
      "bl": 8,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "08425013",
      "j": 2023,
      "n": "ballendorf",
      "dn": "Ballendorf",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08425014",
      "j": 2023,
      "n": "beimerstetten",
      "dn": "Beimerstetten",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08425017",
      "j": 2023,
      "n": "berghülen",
      "dn": "Berghülen",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08425019",
      "j": 2023,
      "n": "bernstadt",
      "dn": "Bernstadt",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08425020",
      "j": 2023,
      "n": "blaubeuren",
      "dn": "Blaubeuren",
      "bl": 8,
      "hs": 335.0,
      "mns": 2
    },
    {
      "a": "08425022",
      "j": 2023,
      "n": "börslingen",
      "dn": "Börslingen",
      "bl": 8,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "08425024",
      "j": 2023,
      "n": "breitingen",
      "dn": "Breitingen",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08425028",
      "j": 2023,
      "n": "dietenheim",
      "dn": "Dietenheim",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08425031",
      "j": 2023,
      "n": "dornstadt",
      "dn": "Dornstadt",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08425033",
      "j": 2023,
      "n": "ehingen",
      "dn": "Ehingen",
      "bl": 8,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "08425035",
      "j": 2023,
      "n": "emeringen",
      "dn": "Emeringen",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08425036",
      "j": 2023,
      "n": "emerkingen",
      "dn": "Emerkingen",
      "bl": 8,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "08425039",
      "j": 2023,
      "n": "erbach",
      "dn": "Erbach",
      "bl": 8,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "08425050",
      "j": 2023,
      "n": "griesingen",
      "dn": "Griesingen",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08425052",
      "j": 2023,
      "n": "grundsheim",
      "dn": "Grundsheim",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08425055",
      "j": 2023,
      "n": "hausen am bussen",
      "dn": "Hausen am Bussen",
      "bl": 8,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "08425062",
      "j": 2023,
      "n": "holzkirch",
      "dn": "Holzkirch",
      "bl": 8,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "08425064",
      "j": 2023,
      "n": "hüttisheim",
      "dn": "Hüttisheim",
      "bl": 8,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "08425066",
      "j": 2023,
      "n": "illerrieden",
      "dn": "Illerrieden",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08425071",
      "j": 2023,
      "n": "laichingen",
      "dn": "Laichingen",
      "bl": 8,
      "hs": 375.0,
      "mns": 2
    },
    {
      "a": "08425072",
      "j": 2023,
      "n": "langenau",
      "dn": "Langenau",
      "bl": 8,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "08425073",
      "j": 2023,
      "n": "lauterach",
      "dn": "Lauterach",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08425075",
      "j": 2023,
      "n": "lonsee",
      "dn": "Lonsee",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08425079",
      "j": 2023,
      "n": "merklingen",
      "dn": "Merklingen",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08425081",
      "j": 2023,
      "n": "munderkingen",
      "dn": "Munderkingen",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08425083",
      "j": 2023,
      "n": "neenstetten",
      "dn": "Neenstetten",
      "bl": 8,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "08425084",
      "j": 2023,
      "n": "nellingen",
      "dn": "Nellingen",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08425085",
      "j": 2023,
      "n": "nerenstetten",
      "dn": "Nerenstetten",
      "bl": 8,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "08425088",
      "j": 2023,
      "n": "oberdischingen",
      "dn": "Oberdischingen",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08425090",
      "j": 2023,
      "n": "obermarchtal",
      "dn": "Obermarchtal",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08425091",
      "j": 2023,
      "n": "oberstadion",
      "dn": "Oberstadion",
      "bl": 8,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "08425092",
      "j": 2023,
      "n": "öllingen",
      "dn": "Öllingen",
      "bl": 8,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "08425093",
      "j": 2023,
      "n": "öpfingen",
      "dn": "Öpfingen",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08425097",
      "j": 2023,
      "n": "rammingen",
      "dn": "Rammingen",
      "bl": 8,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "08425098",
      "j": 2023,
      "n": "rechtenstein",
      "dn": "Rechtenstein",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08425104",
      "j": 2023,
      "n": "rottenacker",
      "dn": "Rottenacker",
      "bl": 8,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "08425108",
      "j": 2023,
      "n": "schelklingen",
      "dn": "Schelklingen",
      "bl": 8,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "08425110",
      "j": 2023,
      "n": "schnürpflingen",
      "dn": "Schnürpflingen",
      "bl": 8,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "08425112",
      "j": 2023,
      "n": "setzingen",
      "dn": "Setzingen",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08425123",
      "j": 2023,
      "n": "untermarchtal",
      "dn": "Untermarchtal",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08425124",
      "j": 2023,
      "n": "unterstadion",
      "dn": "Unterstadion",
      "bl": 8,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "08425125",
      "j": 2023,
      "n": "unterwachingen",
      "dn": "Unterwachingen",
      "bl": 8,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "08425130",
      "j": 2023,
      "n": "weidenstetten",
      "dn": "Weidenstetten",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08425134",
      "j": 2023,
      "n": "westerheim",
      "dn": "Westerheim",
      "bl": 8,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "08425135",
      "j": 2023,
      "n": "westerstetten",
      "dn": "Westerstetten",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08425137",
      "j": 2023,
      "n": "illerkirchberg",
      "dn": "Illerkirchberg",
      "bl": 8,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "08425138",
      "j": 2023,
      "n": "staig",
      "dn": "Staig",
      "bl": 8,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "08425139",
      "j": 2023,
      "n": "heroldstatt",
      "dn": "Heroldstatt",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08425140",
      "j": 2023,
      "n": "balzheim",
      "dn": "Balzheim",
      "bl": 8,
      "hs": 290.0,
      "mns": 2
    },
    {
      "a": "08425141",
      "j": 2023,
      "n": "blaustein",
      "dn": "Blaustein",
      "bl": 8,
      "hs": 360.0,
      "mns": 4
    },
    {
      "a": "08426001",
      "j": 2025,
      "n": "achstetten",
      "dn": "Achstetten",
      "bl": 8,
      "hs": 250.0,
      "mns": 1
    },
    {
      "a": "08426005",
      "j": 2023,
      "n": "alleshausen",
      "dn": "Alleshausen",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08426006",
      "j": 2023,
      "n": "allmannsweiler",
      "dn": "Allmannsweiler",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08426008",
      "j": 2023,
      "n": "altheim",
      "dn": "Altheim",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08426011",
      "j": 2023,
      "n": "attenweiler",
      "dn": "Attenweiler",
      "bl": 8,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "08426013",
      "j": 2023,
      "n": "bad buchau",
      "dn": "Bad Buchau",
      "bl": 8,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "08426014",
      "j": 2023,
      "n": "bad schussenried",
      "dn": "Bad Schussenried",
      "bl": 8,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "08426019",
      "j": 2025,
      "n": "berkheim",
      "dn": "Berkheim",
      "bl": 8,
      "hs": 248.0,
      "mns": 1
    },
    {
      "a": "08426020",
      "j": 2023,
      "n": "betzenweiler",
      "dn": "Betzenweiler",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08426021",
      "j": 2023,
      "n": "biberach an der riß",
      "dn": "Biberach an der Riß",
      "bl": 8,
      "hs": 275.0,
      "mns": 3
    },
    {
      "a": "08426028",
      "j": 2023,
      "n": "burgrieden",
      "dn": "Burgrieden",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08426031",
      "j": 2023,
      "n": "dettingen an der iller",
      "dn": "Dettingen an der Iller",
      "bl": 8,
      "hs": 305.0,
      "mns": 1
    },
    {
      "a": "08426035",
      "j": 2023,
      "n": "dürmentingen",
      "dn": "Dürmentingen",
      "bl": 8,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "08426036",
      "j": 2023,
      "n": "dürnau",
      "dn": "Dürnau",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08426038",
      "j": 2023,
      "n": "eberhardzell",
      "dn": "Eberhardzell",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08426043",
      "j": 2023,
      "n": "erlenmoos",
      "dn": "Erlenmoos",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08426044",
      "j": 2023,
      "n": "erolzheim",
      "dn": "Erolzheim",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08426045",
      "j": 2023,
      "n": "ertingen",
      "dn": "Ertingen",
      "bl": 8,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "08426058",
      "j": 2023,
      "n": "hochdorf",
      "dn": "Hochdorf",
      "bl": 8,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "08426062",
      "j": 2023,
      "n": "ingoldingen",
      "dn": "Ingoldingen",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08426064",
      "j": 2023,
      "n": "kanzach",
      "dn": "Kanzach",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08426065",
      "j": 2023,
      "n": "kirchberg an der iller",
      "dn": "Kirchberg an der Iller",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08426066",
      "j": 2023,
      "n": "kirchdorf an der iller",
      "dn": "Kirchdorf an der Iller",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08426067",
      "j": 2023,
      "n": "langenenslingen",
      "dn": "Langenenslingen",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08426070",
      "j": 2023,
      "n": "laupheim",
      "dn": "Laupheim",
      "bl": 8,
      "hs": 335.0,
      "mns": 3
    },
    {
      "a": "08426071",
      "j": 2023,
      "n": "maselheim",
      "dn": "Maselheim",
      "bl": 8,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "08426073",
      "j": 2023,
      "n": "mietingen",
      "dn": "Mietingen",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08426074",
      "j": 2023,
      "n": "mittelbiberach",
      "dn": "Mittelbiberach",
      "bl": 8,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "08426078",
      "j": 2023,
      "n": "moosburg",
      "dn": "Moosburg",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08426087",
      "j": 2023,
      "n": "ochsenhausen",
      "dn": "Ochsenhausen",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08426090",
      "j": 2023,
      "n": "oggelshausen",
      "dn": "Oggelshausen",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08426097",
      "j": 2023,
      "n": "riedlingen",
      "dn": "Riedlingen",
      "bl": 8,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "08426100",
      "j": 2023,
      "n": "rot an der rot",
      "dn": "Rot an der Rot",
      "bl": 8,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "08426108",
      "j": 2023,
      "n": "schwendi",
      "dn": "Schwendi",
      "bl": 8,
      "hs": 335.0,
      "mns": 1
    },
    {
      "a": "08426109",
      "j": 2023,
      "n": "seekirch",
      "dn": "Seekirch",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08426113",
      "j": 2023,
      "n": "steinhausen an der rottum",
      "dn": "Steinhausen an der Rottum",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08426117",
      "j": 2023,
      "n": "tannheim",
      "dn": "Tannheim",
      "bl": 8,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "08426118",
      "j": 2023,
      "n": "tiefenbach",
      "dn": "Tiefenbach",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08426120",
      "j": 2023,
      "n": "ummendorf",
      "dn": "Ummendorf",
      "bl": 8,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "08426121",
      "j": 2023,
      "n": "unlingen",
      "dn": "Unlingen",
      "bl": 8,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "08426124",
      "j": 2023,
      "n": "uttenweiler",
      "dn": "Uttenweiler",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08426125",
      "j": 2023,
      "n": "wain",
      "dn": "Wain",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08426128",
      "j": 2023,
      "n": "warthausen",
      "dn": "Warthausen",
      "bl": 8,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "08426134",
      "j": 2023,
      "n": "schemmerhofen",
      "dn": "Schemmerhofen",
      "bl": 8,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "08426135",
      "j": 2023,
      "n": "gutenzell-hürbel",
      "dn": "Gutenzell-Hürbel",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08435005",
      "j": 2023,
      "n": "bermatingen",
      "dn": "Bermatingen",
      "bl": 8,
      "hs": 340.0,
      "mns": 4
    },
    {
      "a": "08435010",
      "j": 2023,
      "n": "daisendorf",
      "dn": "Daisendorf",
      "bl": 8,
      "hs": 430.0,
      "mns": 4
    },
    {
      "a": "08435013",
      "j": 2025,
      "n": "eriskirch",
      "dn": "Eriskirch",
      "bl": 8,
      "hs": 220.0,
      "mns": 4
    },
    {
      "a": "08435015",
      "j": 2023,
      "n": "frickingen",
      "dn": "Frickingen",
      "bl": 8,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "08435016",
      "j": 2024,
      "n": "friedrichshafen",
      "dn": "Friedrichshafen",
      "bl": 8,
      "hs": 340.0,
      "mns": 5
    },
    {
      "a": "08435018",
      "j": 2023,
      "n": "hagnau am bodensee",
      "dn": "Hagnau am Bodensee",
      "bl": 8,
      "hs": 360.0,
      "mns": 4
    },
    {
      "a": "08435020",
      "j": 2023,
      "n": "heiligenberg",
      "dn": "Heiligenberg",
      "bl": 8,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "08435024",
      "j": 2023,
      "n": "immenstaad am bodensee",
      "dn": "Immenstaad am Bodensee",
      "bl": 8,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "08435029",
      "j": 2023,
      "n": "kressbronn am bodensee",
      "dn": "Kressbronn am Bodensee",
      "bl": 8,
      "hs": 380.0,
      "mns": 4
    },
    {
      "a": "08435030",
      "j": 2023,
      "n": "langenargen",
      "dn": "Langenargen",
      "bl": 8,
      "hs": 360.0,
      "mns": 4
    },
    {
      "a": "08435034",
      "j": 2025,
      "n": "markdorf",
      "dn": "Markdorf",
      "bl": 8,
      "hs": 245.0,
      "mns": 4
    },
    {
      "a": "08435035",
      "j": 2023,
      "n": "meckenbeuren",
      "dn": "Meckenbeuren",
      "bl": 8,
      "hs": 370.0,
      "mns": 4
    },
    {
      "a": "08435036",
      "j": 2023,
      "n": "meersburg",
      "dn": "Meersburg",
      "bl": 8,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "08435042",
      "j": 2023,
      "n": "neukirch",
      "dn": "Neukirch",
      "bl": 8,
      "hs": 360.0,
      "mns": 4
    },
    {
      "a": "08435045",
      "j": 2023,
      "n": "oberteuringen",
      "dn": "Oberteuringen",
      "bl": 8,
      "hs": 330.0,
      "mns": 4
    },
    {
      "a": "08435047",
      "j": 2023,
      "n": "owingen",
      "dn": "Owingen",
      "bl": 8,
      "hs": 340.0,
      "mns": 4
    },
    {
      "a": "08435052",
      "j": 2025,
      "n": "salem",
      "dn": "Salem",
      "bl": 8,
      "hs": 185.0,
      "mns": 3
    },
    {
      "a": "08435053",
      "j": 2023,
      "n": "sipplingen",
      "dn": "Sipplingen",
      "bl": 8,
      "hs": 370.0,
      "mns": 4
    },
    {
      "a": "08435054",
      "j": 2023,
      "n": "stetten",
      "dn": "Stetten",
      "bl": 8,
      "hs": 390.0,
      "mns": 4
    },
    {
      "a": "08435057",
      "j": 2023,
      "n": "tettnang",
      "dn": "Tettnang",
      "bl": 8,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "08435059",
      "j": 2023,
      "n": "überlingen",
      "dn": "Überlingen",
      "bl": 8,
      "hs": 445.0,
      "mns": 4
    },
    {
      "a": "08435066",
      "j": 2023,
      "n": "uhldingen-mühlhofen",
      "dn": "Uhldingen-Mühlhofen",
      "bl": 8,
      "hs": 340.0,
      "mns": 4
    },
    {
      "a": "08435067",
      "j": 2023,
      "n": "deggenhausertal",
      "dn": "Deggenhausertal",
      "bl": 8,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "08436001",
      "j": 2023,
      "n": "achberg",
      "dn": "Achberg",
      "bl": 8,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "08436003",
      "j": 2023,
      "n": "aichstetten",
      "dn": "Aichstetten",
      "bl": 8,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "08436004",
      "j": 2023,
      "n": "aitrach",
      "dn": "Aitrach",
      "bl": 8,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "08436005",
      "j": 2023,
      "n": "altshausen",
      "dn": "Altshausen",
      "bl": 8,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "08436006",
      "j": 2023,
      "n": "amtzell",
      "dn": "Amtzell",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08436008",
      "j": 2023,
      "n": "aulendorf",
      "dn": "Aulendorf",
      "bl": 8,
      "hs": 600.0,
      "mns": 2
    },
    {
      "a": "08436009",
      "j": 2023,
      "n": "bad waldsee",
      "dn": "Bad Waldsee",
      "bl": 8,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "08436010",
      "j": 2023,
      "n": "bad wurzach",
      "dn": "Bad Wurzach",
      "bl": 8,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "08436011",
      "j": 2023,
      "n": "baienfurt",
      "dn": "Baienfurt",
      "bl": 8,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "08436012",
      "j": 2023,
      "n": "baindt",
      "dn": "Baindt",
      "bl": 8,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "08436013",
      "j": 2023,
      "n": "berg",
      "dn": "Berg",
      "bl": 8,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "08436014",
      "j": 2023,
      "n": "bergatreute",
      "dn": "Bergatreute",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08436018",
      "j": 2023,
      "n": "bodnegg",
      "dn": "Bodnegg",
      "bl": 8,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "08436019",
      "j": 2023,
      "n": "boms",
      "dn": "Boms",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08436024",
      "j": 2023,
      "n": "ebenweiler",
      "dn": "Ebenweiler",
      "bl": 8,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "08436027",
      "j": 2023,
      "n": "eichstegen",
      "dn": "Eichstegen",
      "bl": 8,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "08436032",
      "j": 2023,
      "n": "fleischwangen",
      "dn": "Fleischwangen",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08436039",
      "j": 2023,
      "n": "grünkraut",
      "dn": "Grünkraut",
      "bl": 8,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "08436040",
      "j": 2023,
      "n": "guggenhausen",
      "dn": "Guggenhausen",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08436047",
      "j": 2023,
      "n": "hoßkirch",
      "dn": "Hoßkirch",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08436049",
      "j": 2023,
      "n": "isny im allgäu",
      "dn": "Isny im Allgäu",
      "bl": 8,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "08436052",
      "j": 2023,
      "n": "kißlegg",
      "dn": "Kißlegg",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08436053",
      "j": 2023,
      "n": "königseggwald",
      "dn": "Königseggwald",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08436055",
      "j": 2023,
      "n": "leutkirch im allgäu",
      "dn": "Leutkirch im Allgäu",
      "bl": 8,
      "hs": 385.0,
      "mns": 2
    },
    {
      "a": "08436064",
      "j": 2023,
      "n": "ravensburg",
      "dn": "Ravensburg",
      "bl": 8,
      "hs": 500.0,
      "mns": 5
    },
    {
      "a": "08436067",
      "j": 2023,
      "n": "riedhausen",
      "dn": "Riedhausen",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08436069",
      "j": 2023,
      "n": "schlier",
      "dn": "Schlier",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08436077",
      "j": 2023,
      "n": "unterwaldhausen",
      "dn": "Unterwaldhausen",
      "bl": 8,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "08436078",
      "j": 2023,
      "n": "vogt",
      "dn": "Vogt",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08436079",
      "j": 2023,
      "n": "waldburg",
      "dn": "Waldburg",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08436081",
      "j": 2023,
      "n": "wangen im allgäu",
      "dn": "Wangen im Allgäu",
      "bl": 8,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "08436082",
      "j": 2023,
      "n": "weingarten",
      "dn": "Weingarten",
      "bl": 8,
      "hs": 500.0,
      "mns": 5
    },
    {
      "a": "08436083",
      "j": 2023,
      "n": "wilhelmsdorf",
      "dn": "Wilhelmsdorf",
      "bl": 8,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "08436085",
      "j": 2023,
      "n": "wolfegg",
      "dn": "Wolfegg",
      "bl": 8,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "08436087",
      "j": 2025,
      "n": "wolpertswende",
      "dn": "Wolpertswende",
      "bl": 8,
      "hs": 220.0,
      "mns": 2
    },
    {
      "a": "08436093",
      "j": 2023,
      "n": "ebersbach-musbach",
      "dn": "Ebersbach-Musbach",
      "bl": 8,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "08436094",
      "j": 2023,
      "n": "argenbühl",
      "dn": "Argenbühl",
      "bl": 8,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "08436095",
      "j": 2023,
      "n": "horgenzell",
      "dn": "Horgenzell",
      "bl": 8,
      "hs": 355.0,
      "mns": 2
    },
    {
      "a": "08436096",
      "j": 2023,
      "n": "fronreute",
      "dn": "Fronreute",
      "bl": 8,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "08437005",
      "j": 2023,
      "n": "beuron",
      "dn": "Beuron",
      "bl": 8,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "08437008",
      "j": 2023,
      "n": "bingen",
      "dn": "Bingen",
      "bl": 8,
      "hs": 335.0,
      "mns": 1
    },
    {
      "a": "08437031",
      "j": 2023,
      "n": "gammertingen",
      "dn": "Gammertingen",
      "bl": 8,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "08437044",
      "j": 2023,
      "n": "herbertingen",
      "dn": "Herbertingen",
      "bl": 8,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "08437047",
      "j": 2023,
      "n": "hettingen",
      "dn": "Hettingen",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08437053",
      "j": 2023,
      "n": "hohentengen",
      "dn": "Hohentengen",
      "bl": 8,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "08437056",
      "j": 2023,
      "n": "illmensee",
      "dn": "Illmensee",
      "bl": 8,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "08437059",
      "j": 2023,
      "n": "inzigkofen",
      "dn": "Inzigkofen",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08437065",
      "j": 2023,
      "n": "krauchenwies",
      "dn": "Krauchenwies",
      "bl": 8,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "08437072",
      "j": 2023,
      "n": "leibertingen",
      "dn": "Leibertingen",
      "bl": 8,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "08437076",
      "j": 2023,
      "n": "mengen",
      "dn": "Mengen",
      "bl": 8,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "08437078",
      "j": 2023,
      "n": "meßkirch",
      "dn": "Meßkirch",
      "bl": 8,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "08437082",
      "j": 2023,
      "n": "neufra",
      "dn": "Neufra",
      "bl": 8,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "08437086",
      "j": 2023,
      "n": "ostrach",
      "dn": "Ostrach",
      "bl": 8,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "08437088",
      "j": 2023,
      "n": "pfullendorf",
      "dn": "Pfullendorf",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08437100",
      "j": 2023,
      "n": "bad saulgau",
      "dn": "Bad Saulgau",
      "bl": 8,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "08437101",
      "j": 2023,
      "n": "scheer",
      "dn": "Scheer",
      "bl": 8,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "08437102",
      "j": 2023,
      "n": "schwenningen",
      "dn": "Schwenningen",
      "bl": 8,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "08437104",
      "j": 2025,
      "n": "sigmaringen",
      "dn": "Sigmaringen",
      "bl": 8,
      "hs": 530.0,
      "mns": 2
    },
    {
      "a": "08437105",
      "j": 2023,
      "n": "sigmaringendorf",
      "dn": "Sigmaringendorf",
      "bl": 8,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "08437107",
      "j": 2023,
      "n": "stetten am kalten markt",
      "dn": "Stetten am kalten Markt",
      "bl": 8,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "08437114",
      "j": 2023,
      "n": "veringenstadt",
      "dn": "Veringenstadt",
      "bl": 8,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "08437118",
      "j": 2023,
      "n": "wald",
      "dn": "Wald",
      "bl": 8,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "08437123",
      "j": 2023,
      "n": "sauldorf",
      "dn": "Sauldorf",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "08437124",
      "j": 2023,
      "n": "herdwangen-schönach",
      "dn": "Herdwangen-Schönach",
      "bl": 8,
      "hs": 300.0,
      "mns": 1
    }
  ],
  "9": [
    {
      "a": "09161000",
      "j": 2023,
      "n": "ingolstadt",
      "dn": "Ingolstadt",
      "bl": 9,
      "hs": 460.0,
      "mns": 4
    },
    {
      "a": "09162000",
      "j": 2025,
      "n": "münchen",
      "dn": "München",
      "bl": 9,
      "hs": 824.0,
      "mns": 7
    },
    {
      "a": "09163000",
      "j": 2023,
      "n": "rosenheim",
      "dn": "Rosenheim",
      "bl": 9,
      "hs": 480.0,
      "mns": 5
    },
    {
      "a": "09171111",
      "j": 2023,
      "n": "altötting",
      "dn": "Altötting",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09171112",
      "j": 2023,
      "n": "burghausen",
      "dn": "Burghausen",
      "bl": 9,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "09171113",
      "j": 2023,
      "n": "burgkirchen an der alz",
      "dn": "Burgkirchen an der Alz",
      "bl": 9,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "09171114",
      "j": 2023,
      "n": "emmerting",
      "dn": "Emmerting",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09171115",
      "j": 2023,
      "n": "erlbach",
      "dn": "Erlbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09171116",
      "j": 2023,
      "n": "feichten an der alz",
      "dn": "Feichten an der Alz",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09171117",
      "j": 2023,
      "n": "garching an der alz",
      "dn": "Garching an der Alz",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09171118",
      "j": 2023,
      "n": "haiming",
      "dn": "Haiming",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09171119",
      "j": 2023,
      "n": "halsbach",
      "dn": "Halsbach",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09171121",
      "j": 2023,
      "n": "kastl",
      "dn": "Kastl",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09171122",
      "j": 2023,
      "n": "kirchweidach",
      "dn": "Kirchweidach",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09171123",
      "j": 2023,
      "n": "marktl",
      "dn": "Marktl",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09171124",
      "j": 2023,
      "n": "mehring",
      "dn": "Mehring",
      "bl": 9,
      "hs": 295.0,
      "mns": 1
    },
    {
      "a": "09171125",
      "j": 2023,
      "n": "neuötting",
      "dn": "Neuötting",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09171126",
      "j": 2023,
      "n": "perach",
      "dn": "Perach",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09171127",
      "j": 2023,
      "n": "pleiskirchen",
      "dn": "Pleiskirchen",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09171129",
      "j": 2023,
      "n": "reischach",
      "dn": "Reischach",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09171130",
      "j": 2023,
      "n": "stammham",
      "dn": "Stammham",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09171131",
      "j": 2023,
      "n": "teising",
      "dn": "Teising",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09171132",
      "j": 2023,
      "n": "töging am inn",
      "dn": "Töging am Inn",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09171133",
      "j": 2023,
      "n": "tüßling",
      "dn": "Tüßling",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09171134",
      "j": 2023,
      "n": "tyrlaching",
      "dn": "Tyrlaching",
      "bl": 9,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "09171135",
      "j": 2023,
      "n": "unterneukirchen",
      "dn": "Unterneukirchen",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09171137",
      "j": 2023,
      "n": "winhöring",
      "dn": "Winhöring",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09172111",
      "j": 2023,
      "n": "ainring",
      "dn": "Ainring",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09172112",
      "j": 2023,
      "n": "anger",
      "dn": "Anger",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09172114",
      "j": 2025,
      "n": "bad reichenhall",
      "dn": "Bad Reichenhall",
      "bl": 9,
      "hs": 490.0,
      "mns": 4
    },
    {
      "a": "09172115",
      "j": 2023,
      "n": "bayerisch gmain",
      "dn": "Bayerisch Gmain",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09172116",
      "j": 2023,
      "n": "berchtesgaden",
      "dn": "Berchtesgaden",
      "bl": 9,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "09172117",
      "j": 2023,
      "n": "bischofswiesen",
      "dn": "Bischofswiesen",
      "bl": 9,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "09172118",
      "j": 2023,
      "n": "freilassing",
      "dn": "Freilassing",
      "bl": 9,
      "hs": 320.0,
      "mns": 4
    },
    {
      "a": "09172122",
      "j": 2023,
      "n": "laufen",
      "dn": "Laufen",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09172124",
      "j": 2023,
      "n": "marktschellenberg",
      "dn": "Marktschellenberg",
      "bl": 9,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "09172128",
      "j": 2023,
      "n": "piding",
      "dn": "Piding",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09172129",
      "j": 2023,
      "n": "ramsau bei berchtesgaden",
      "dn": "Ramsau bei Berchtesgaden",
      "bl": 9,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "09172130",
      "j": 2023,
      "n": "saaldorf-surheim",
      "dn": "Saaldorf-Surheim",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09172131",
      "j": 2023,
      "n": "schneizlreuth",
      "dn": "Schneizlreuth",
      "bl": 9,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "09172132",
      "j": 2023,
      "n": "schönau am königssee",
      "dn": "Schönau am Königssee",
      "bl": 9,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "09172134",
      "j": 2023,
      "n": "teisendorf",
      "dn": "Teisendorf",
      "bl": 9,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "09173111",
      "j": 2023,
      "n": "bad heilbrunn",
      "dn": "Bad Heilbrunn",
      "bl": 9,
      "hs": 320.0,
      "mns": 4
    },
    {
      "a": "09173112",
      "j": 2023,
      "n": "bad tölz",
      "dn": "Bad Tölz",
      "bl": 9,
      "hs": 420.0,
      "mns": 5
    },
    {
      "a": "09173113",
      "j": 2023,
      "n": "benediktbeuern",
      "dn": "Benediktbeuern",
      "bl": 9,
      "hs": 320.0,
      "mns": 4
    },
    {
      "a": "09173115",
      "j": 2023,
      "n": "bichl",
      "dn": "Bichl",
      "bl": 9,
      "hs": 310.0,
      "mns": 4
    },
    {
      "a": "09173118",
      "j": 2023,
      "n": "dietramszell",
      "dn": "Dietramszell",
      "bl": 9,
      "hs": 320.0,
      "mns": 4
    },
    {
      "a": "09173120",
      "j": 2023,
      "n": "egling",
      "dn": "Egling",
      "bl": 9,
      "hs": 320.0,
      "mns": 4
    },
    {
      "a": "09173123",
      "j": 2023,
      "n": "eurasburg",
      "dn": "Eurasburg",
      "bl": 9,
      "hs": 310.0,
      "mns": 4
    },
    {
      "a": "09173124",
      "j": 2023,
      "n": "gaißach",
      "dn": "Gaißach",
      "bl": 9,
      "hs": 300.0,
      "mns": 4
    },
    {
      "a": "09173126",
      "j": 2023,
      "n": "geretsried",
      "dn": "Geretsried",
      "bl": 9,
      "hs": 380.0,
      "mns": 4
    },
    {
      "a": "09173127",
      "j": 2023,
      "n": "greiling",
      "dn": "Greiling",
      "bl": 9,
      "hs": 310.0,
      "mns": 4
    },
    {
      "a": "09173130",
      "j": 2023,
      "n": "icking",
      "dn": "Icking",
      "bl": 9,
      "hs": 280.0,
      "mns": 4
    },
    {
      "a": "09173131",
      "j": 2023,
      "n": "jachenau",
      "dn": "Jachenau",
      "bl": 9,
      "hs": 310.0,
      "mns": 4
    },
    {
      "a": "09173133",
      "j": 2023,
      "n": "kochel am see",
      "dn": "Kochel am See",
      "bl": 9,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "09173134",
      "j": 2023,
      "n": "königsdorf",
      "dn": "Königsdorf",
      "bl": 9,
      "hs": 330.0,
      "mns": 4
    },
    {
      "a": "09173135",
      "j": 2023,
      "n": "lenggries",
      "dn": "Lenggries",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09173137",
      "j": 2023,
      "n": "münsing",
      "dn": "Münsing",
      "bl": 9,
      "hs": 290.0,
      "mns": 4
    },
    {
      "a": "09173140",
      "j": 2023,
      "n": "reichersbeuern",
      "dn": "Reichersbeuern",
      "bl": 9,
      "hs": 300.0,
      "mns": 4
    },
    {
      "a": "09173141",
      "j": 2023,
      "n": "sachsenkam",
      "dn": "Sachsenkam",
      "bl": 9,
      "hs": 310.0,
      "mns": 4
    },
    {
      "a": "09173142",
      "j": 2023,
      "n": "schlehdorf",
      "dn": "Schlehdorf",
      "bl": 9,
      "hs": 320.0,
      "mns": 4
    },
    {
      "a": "09173145",
      "j": 2023,
      "n": "wackersberg",
      "dn": "Wackersberg",
      "bl": 9,
      "hs": 310.0,
      "mns": 4
    },
    {
      "a": "09173147",
      "j": 2023,
      "n": "wolfratshausen",
      "dn": "Wolfratshausen",
      "bl": 9,
      "hs": 400.0,
      "mns": 6
    },
    {
      "a": "09174111",
      "j": 2023,
      "n": "altomünster",
      "dn": "Altomünster",
      "bl": 9,
      "hs": 330.0,
      "mns": 5
    },
    {
      "a": "09174113",
      "j": 2024,
      "n": "bergkirchen",
      "dn": "Bergkirchen",
      "bl": 9,
      "hs": 310.0,
      "mns": 5
    },
    {
      "a": "09174115",
      "j": 2023,
      "n": "dachau",
      "dn": "Dachau",
      "bl": 9,
      "hs": 350.0,
      "mns": 7
    },
    {
      "a": "09174118",
      "j": 2023,
      "n": "erdweg",
      "dn": "Erdweg",
      "bl": 9,
      "hs": 310.0,
      "mns": 5
    },
    {
      "a": "09174121",
      "j": 2023,
      "n": "haimhausen",
      "dn": "Haimhausen",
      "bl": 9,
      "hs": 360.0,
      "mns": 5
    },
    {
      "a": "09174122",
      "j": 2023,
      "n": "hebertshausen",
      "dn": "Hebertshausen",
      "bl": 9,
      "hs": 350.0,
      "mns": 5
    },
    {
      "a": "09174126",
      "j": 2023,
      "n": "karlsfeld",
      "dn": "Karlsfeld",
      "bl": 9,
      "hs": 400.0,
      "mns": 7
    },
    {
      "a": "09174131",
      "j": 2023,
      "n": "markt indersdorf",
      "dn": "Markt Indersdorf",
      "bl": 9,
      "hs": 330.0,
      "mns": 5
    },
    {
      "a": "09174135",
      "j": 2023,
      "n": "odelzhausen",
      "dn": "Odelzhausen",
      "bl": 9,
      "hs": 310.0,
      "mns": 5
    },
    {
      "a": "09174136",
      "j": 2023,
      "n": "petershausen",
      "dn": "Petershausen",
      "bl": 9,
      "hs": 390.0,
      "mns": 5
    },
    {
      "a": "09174137",
      "j": 2023,
      "n": "pfaffenhofen an der glonn",
      "dn": "Pfaffenhofen an der Glonn",
      "bl": 9,
      "hs": 350.0,
      "mns": 5
    },
    {
      "a": "09174141",
      "j": 2023,
      "n": "röhrmoos",
      "dn": "Röhrmoos",
      "bl": 9,
      "hs": 340.0,
      "mns": 5
    },
    {
      "a": "09174143",
      "j": 2023,
      "n": "schwabhausen",
      "dn": "Schwabhausen",
      "bl": 9,
      "hs": 350.0,
      "mns": 5
    },
    {
      "a": "09174146",
      "j": 2023,
      "n": "sulzemoos",
      "dn": "Sulzemoos",
      "bl": 9,
      "hs": 310.0,
      "mns": 5
    },
    {
      "a": "09174147",
      "j": 2023,
      "n": "hilgertshausen-tandern",
      "dn": "Hilgertshausen-Tandern",
      "bl": 9,
      "hs": 350.0,
      "mns": 5
    },
    {
      "a": "09174150",
      "j": 2023,
      "n": "vierkirchen",
      "dn": "Vierkirchen",
      "bl": 9,
      "hs": 360.0,
      "mns": 5
    },
    {
      "a": "09174151",
      "j": 2023,
      "n": "weichs",
      "dn": "Weichs",
      "bl": 9,
      "hs": 380.0,
      "mns": 5
    },
    {
      "a": "09175111",
      "j": 2023,
      "n": "anzing",
      "dn": "Anzing",
      "bl": 9,
      "hs": 360.0,
      "mns": 6
    },
    {
      "a": "09175112",
      "j": 2023,
      "n": "aßling",
      "dn": "Aßling",
      "bl": 9,
      "hs": 330.0,
      "mns": 6
    },
    {
      "a": "09175113",
      "j": 2023,
      "n": "baiern",
      "dn": "Baiern",
      "bl": 9,
      "hs": 350.0,
      "mns": 6
    },
    {
      "a": "09175114",
      "j": 2023,
      "n": "bruck",
      "dn": "Bruck",
      "bl": 9,
      "hs": 330.0,
      "mns": 6
    },
    {
      "a": "09175115",
      "j": 2023,
      "n": "ebersberg",
      "dn": "Ebersberg",
      "bl": 9,
      "hs": 400.0,
      "mns": 6
    },
    {
      "a": "09175116",
      "j": 2023,
      "n": "egmating",
      "dn": "Egmating",
      "bl": 9,
      "hs": 330.0,
      "mns": 6
    },
    {
      "a": "09175118",
      "j": 2023,
      "n": "forstinning",
      "dn": "Forstinning",
      "bl": 9,
      "hs": 310.0,
      "mns": 6
    },
    {
      "a": "09175119",
      "j": 2023,
      "n": "frauenneuharting",
      "dn": "Frauenneuharting",
      "bl": 9,
      "hs": 320.0,
      "mns": 6
    },
    {
      "a": "09175121",
      "j": 2023,
      "n": "glonn",
      "dn": "Glonn",
      "bl": 9,
      "hs": 350.0,
      "mns": 6
    },
    {
      "a": "09175122",
      "j": 2023,
      "n": "grafing bei münchen",
      "dn": "Grafing bei München",
      "bl": 9,
      "hs": 350.0,
      "mns": 7
    },
    {
      "a": "09175123",
      "j": 2023,
      "n": "hohenlinden",
      "dn": "Hohenlinden",
      "bl": 9,
      "hs": 340.0,
      "mns": 6
    },
    {
      "a": "09175124",
      "j": 2023,
      "n": "kirchseeon",
      "dn": "Kirchseeon",
      "bl": 9,
      "hs": 390.0,
      "mns": 7
    },
    {
      "a": "09175127",
      "j": 2023,
      "n": "markt schwaben",
      "dn": "Markt Schwaben",
      "bl": 9,
      "hs": 400.0,
      "mns": 7
    },
    {
      "a": "09175128",
      "j": 2023,
      "n": "moosach",
      "dn": "Moosach",
      "bl": 9,
      "hs": 310.0,
      "mns": 6
    },
    {
      "a": "09175131",
      "j": 2023,
      "n": "oberpframmern",
      "dn": "Oberpframmern",
      "bl": 9,
      "hs": 310.0,
      "mns": 6
    },
    {
      "a": "09175132",
      "j": 2023,
      "n": "vaterstetten",
      "dn": "Vaterstetten",
      "bl": 9,
      "hs": 390.0,
      "mns": 7
    },
    {
      "a": "09175133",
      "j": 2023,
      "n": "pliening",
      "dn": "Pliening",
      "bl": 9,
      "hs": 350.0,
      "mns": 6
    },
    {
      "a": "09175135",
      "j": 2023,
      "n": "poing",
      "dn": "Poing",
      "bl": 9,
      "hs": 385.0,
      "mns": 7
    },
    {
      "a": "09175136",
      "j": 2023,
      "n": "emmering",
      "dn": "Emmering",
      "bl": 9,
      "hs": 320.0,
      "mns": 6
    },
    {
      "a": "09175137",
      "j": 2023,
      "n": "steinhöring",
      "dn": "Steinhöring",
      "bl": 9,
      "hs": 320.0,
      "mns": 6
    },
    {
      "a": "09175139",
      "j": 2023,
      "n": "zorneding",
      "dn": "Zorneding",
      "bl": 9,
      "hs": 310.0,
      "mns": 6
    },
    {
      "a": "09176111",
      "j": 2023,
      "n": "adelschlag",
      "dn": "Adelschlag",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09176112",
      "j": 2023,
      "n": "altmannstein",
      "dn": "Altmannstein",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09176114",
      "j": 2023,
      "n": "beilngries",
      "dn": "Beilngries",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09176116",
      "j": 2023,
      "n": "böhmfeld",
      "dn": "Böhmfeld",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09176118",
      "j": 2023,
      "n": "buxheim",
      "dn": "Buxheim",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09176120",
      "j": 2023,
      "n": "denkendorf",
      "dn": "Denkendorf",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09176121",
      "j": 2023,
      "n": "dollnstein",
      "dn": "Dollnstein",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09176122",
      "j": 2023,
      "n": "egweil",
      "dn": "Egweil",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09176123",
      "j": 2023,
      "n": "eichstätt",
      "dn": "Eichstätt",
      "bl": 9,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "09176124",
      "j": 2023,
      "n": "eitensheim",
      "dn": "Eitensheim",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09176126",
      "j": 2023,
      "n": "gaimersheim",
      "dn": "Gaimersheim",
      "bl": 9,
      "hs": 250.0,
      "mns": 4
    },
    {
      "a": "09176129",
      "j": 2023,
      "n": "großmehring",
      "dn": "Großmehring",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09176131",
      "j": 2023,
      "n": "hepberg",
      "dn": "Hepberg",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09176132",
      "j": 2023,
      "n": "hitzhofen",
      "dn": "Hitzhofen",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09176137",
      "j": 2023,
      "n": "kinding",
      "dn": "Kinding",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09176138",
      "j": 2023,
      "n": "kipfenberg",
      "dn": "Kipfenberg",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09176139",
      "j": 2023,
      "n": "kösching",
      "dn": "Kösching",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09176143",
      "j": 2023,
      "n": "lenting",
      "dn": "Lenting",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09176147",
      "j": 2023,
      "n": "mindelstetten",
      "dn": "Mindelstetten",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09176148",
      "j": 2023,
      "n": "mörnsheim",
      "dn": "Mörnsheim",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09176149",
      "j": 2023,
      "n": "nassenfels",
      "dn": "Nassenfels",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09176150",
      "j": 2023,
      "n": "oberdolling",
      "dn": "Oberdolling",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09176153",
      "j": 2023,
      "n": "pförring",
      "dn": "Pförring",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09176155",
      "j": 2023,
      "n": "pollenfeld",
      "dn": "Pollenfeld",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09176160",
      "j": 2023,
      "n": "schernfeld",
      "dn": "Schernfeld",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09176161",
      "j": 2023,
      "n": "stammham",
      "dn": "Stammham",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09176164",
      "j": 2023,
      "n": "titting",
      "dn": "Titting",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09176165",
      "j": 2023,
      "n": "walting",
      "dn": "Walting",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09176166",
      "j": 2023,
      "n": "wellheim",
      "dn": "Wellheim",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09176167",
      "j": 2023,
      "n": "wettstetten",
      "dn": "Wettstetten",
      "bl": 9,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "09177112",
      "j": 2023,
      "n": "berglern",
      "dn": "Berglern",
      "bl": 9,
      "hs": 450.0,
      "mns": 4
    },
    {
      "a": "09177113",
      "j": 2023,
      "n": "bockhorn",
      "dn": "Bockhorn",
      "bl": 9,
      "hs": 300.0,
      "mns": 4
    },
    {
      "a": "09177114",
      "j": 2023,
      "n": "buch am buchrain",
      "dn": "Buch am Buchrain",
      "bl": 9,
      "hs": 380.0,
      "mns": 4
    },
    {
      "a": "09177115",
      "j": 2023,
      "n": "dorfen",
      "dn": "Dorfen",
      "bl": 9,
      "hs": 380.0,
      "mns": 4
    },
    {
      "a": "09177116",
      "j": 2023,
      "n": "eitting",
      "dn": "Eitting",
      "bl": 9,
      "hs": 300.0,
      "mns": 4
    },
    {
      "a": "09177117",
      "j": 2023,
      "n": "erding",
      "dn": "Erding",
      "bl": 9,
      "hs": 450.0,
      "mns": 6
    },
    {
      "a": "09177118",
      "j": 2023,
      "n": "finsing",
      "dn": "Finsing",
      "bl": 9,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "09177119",
      "j": 2025,
      "n": "forstern",
      "dn": "Forstern",
      "bl": 9,
      "hs": 450.0,
      "mns": 4
    },
    {
      "a": "09177120",
      "j": 2023,
      "n": "fraunberg",
      "dn": "Fraunberg",
      "bl": 9,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "09177121",
      "j": 2023,
      "n": "hohenpolding",
      "dn": "Hohenpolding",
      "bl": 9,
      "hs": 480.0,
      "mns": 4
    },
    {
      "a": "09177122",
      "j": 2023,
      "n": "inning am holz",
      "dn": "Inning am Holz",
      "bl": 9,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "09177123",
      "j": 2023,
      "n": "isen",
      "dn": "Isen",
      "bl": 9,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "09177124",
      "j": 2023,
      "n": "kirchberg",
      "dn": "Kirchberg",
      "bl": 9,
      "hs": 440.0,
      "mns": 4
    },
    {
      "a": "09177126",
      "j": 2023,
      "n": "langenpreising",
      "dn": "Langenpreising",
      "bl": 9,
      "hs": 440.0,
      "mns": 4
    },
    {
      "a": "09177127",
      "j": 2023,
      "n": "lengdorf",
      "dn": "Lengdorf",
      "bl": 9,
      "hs": 780.0,
      "mns": 4
    },
    {
      "a": "09177130",
      "j": 2024,
      "n": "moosinning",
      "dn": "Moosinning",
      "bl": 9,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "09177131",
      "j": 2023,
      "n": "neuching",
      "dn": "Neuching",
      "bl": 9,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "09177133",
      "j": 2023,
      "n": "oberding",
      "dn": "Oberding",
      "bl": 9,
      "hs": 250.0,
      "mns": 4
    },
    {
      "a": "09177134",
      "j": 2023,
      "n": "ottenhofen",
      "dn": "Ottenhofen",
      "bl": 9,
      "hs": 320.0,
      "mns": 4
    },
    {
      "a": "09177135",
      "j": 2023,
      "n": "pastetten",
      "dn": "Pastetten",
      "bl": 9,
      "hs": 380.0,
      "mns": 4
    },
    {
      "a": "09177137",
      "j": 2023,
      "n": "sankt wolfgang",
      "dn": "Sankt Wolfgang",
      "bl": 9,
      "hs": 340.0,
      "mns": 4
    },
    {
      "a": "09177138",
      "j": 2023,
      "n": "steinkirchen",
      "dn": "Steinkirchen",
      "bl": 9,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "09177139",
      "j": 2023,
      "n": "taufkirchen",
      "dn": "Taufkirchen",
      "bl": 9,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "09177142",
      "j": 2023,
      "n": "walpertskirchen",
      "dn": "Walpertskirchen",
      "bl": 9,
      "hs": 370.0,
      "mns": 4
    },
    {
      "a": "09177143",
      "j": 2023,
      "n": "wartenberg",
      "dn": "Wartenberg",
      "bl": 9,
      "hs": 450.0,
      "mns": 4
    },
    {
      "a": "09177144",
      "j": 2023,
      "n": "wörth",
      "dn": "Wörth",
      "bl": 9,
      "hs": 320.0,
      "mns": 4
    },
    {
      "a": "09178113",
      "j": 2023,
      "n": "allershausen",
      "dn": "Allershausen",
      "bl": 9,
      "hs": 320.0,
      "mns": 4
    },
    {
      "a": "09178115",
      "j": 2023,
      "n": "attenkirchen",
      "dn": "Attenkirchen",
      "bl": 9,
      "hs": 330.0,
      "mns": 4
    },
    {
      "a": "09178116",
      "j": 2023,
      "n": "au in der hallertau",
      "dn": "Au in der Hallertau",
      "bl": 9,
      "hs": 335.0,
      "mns": 4
    },
    {
      "a": "09178120",
      "j": 2023,
      "n": "eching",
      "dn": "Eching",
      "bl": 9,
      "hs": 320.0,
      "mns": 7
    },
    {
      "a": "09178122",
      "j": 2023,
      "n": "rudelzhausen",
      "dn": "Rudelzhausen",
      "bl": 9,
      "hs": 390.0,
      "mns": 4
    },
    {
      "a": "09178123",
      "j": 2023,
      "n": "fahrenzhausen",
      "dn": "Fahrenzhausen",
      "bl": 9,
      "hs": 360.0,
      "mns": 4
    },
    {
      "a": "09178124",
      "j": 2023,
      "n": "freising",
      "dn": "Freising",
      "bl": 9,
      "hs": 360.0,
      "mns": 6
    },
    {
      "a": "09178125",
      "j": 2023,
      "n": "gammelsdorf",
      "dn": "Gammelsdorf",
      "bl": 9,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "09178129",
      "j": 2023,
      "n": "haag an der amper",
      "dn": "Haag an der Amper",
      "bl": 9,
      "hs": 285.0,
      "mns": 4
    },
    {
      "a": "09178130",
      "j": 2023,
      "n": "hallbergmoos",
      "dn": "Hallbergmoos",
      "bl": 9,
      "hs": 275.0,
      "mns": 7
    },
    {
      "a": "09178132",
      "j": 2023,
      "n": "hörgertshausen",
      "dn": "Hörgertshausen",
      "bl": 9,
      "hs": 300.0,
      "mns": 4
    },
    {
      "a": "09178133",
      "j": 2023,
      "n": "hohenkammer",
      "dn": "Hohenkammer",
      "bl": 9,
      "hs": 280.0,
      "mns": 4
    },
    {
      "a": "09178136",
      "j": 2023,
      "n": "kirchdorf an der amper",
      "dn": "Kirchdorf an der Amper",
      "bl": 9,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "09178137",
      "j": 2023,
      "n": "kranzberg",
      "dn": "Kranzberg",
      "bl": 9,
      "hs": 330.0,
      "mns": 4
    },
    {
      "a": "09178138",
      "j": 2023,
      "n": "langenbach",
      "dn": "Langenbach",
      "bl": 9,
      "hs": 330.0,
      "mns": 4
    },
    {
      "a": "09178140",
      "j": 2023,
      "n": "marzling",
      "dn": "Marzling",
      "bl": 9,
      "hs": 345.0,
      "mns": 4
    },
    {
      "a": "09178142",
      "j": 2023,
      "n": "mauern",
      "dn": "Mauern",
      "bl": 9,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "09178143",
      "j": 2023,
      "n": "moosburg an der isar",
      "dn": "Moosburg an der Isar",
      "bl": 9,
      "hs": 400.0,
      "mns": 5
    },
    {
      "a": "09178144",
      "j": 2023,
      "n": "nandlstadt",
      "dn": "Nandlstadt",
      "bl": 9,
      "hs": 290.0,
      "mns": 4
    },
    {
      "a": "09178145",
      "j": 2023,
      "n": "neufahrn bei freising",
      "dn": "Neufahrn bei Freising",
      "bl": 9,
      "hs": 390.0,
      "mns": 7
    },
    {
      "a": "09178150",
      "j": 2023,
      "n": "paunzhausen",
      "dn": "Paunzhausen",
      "bl": 9,
      "hs": 320.0,
      "mns": 4
    },
    {
      "a": "09178155",
      "j": 2023,
      "n": "wang",
      "dn": "Wang",
      "bl": 9,
      "hs": 330.0,
      "mns": 4
    },
    {
      "a": "09178156",
      "j": 2023,
      "n": "wolfersdorf",
      "dn": "Wolfersdorf",
      "bl": 9,
      "hs": 310.0,
      "mns": 4
    },
    {
      "a": "09178157",
      "j": 2023,
      "n": "zolling",
      "dn": "Zolling",
      "bl": 9,
      "hs": 300.0,
      "mns": 4
    },
    {
      "a": "09179111",
      "j": 2023,
      "n": "adelshofen",
      "dn": "Adelshofen",
      "bl": 9,
      "hs": 310.0,
      "mns": 6
    },
    {
      "a": "09179113",
      "j": 2023,
      "n": "alling",
      "dn": "Alling",
      "bl": 9,
      "hs": 350.0,
      "mns": 6
    },
    {
      "a": "09179114",
      "j": 2023,
      "n": "althegnenberg",
      "dn": "Althegnenberg",
      "bl": 9,
      "hs": 330.0,
      "mns": 6
    },
    {
      "a": "09179117",
      "j": 2023,
      "n": "egenhofen",
      "dn": "Egenhofen",
      "bl": 9,
      "hs": 340.0,
      "mns": 6
    },
    {
      "a": "09179118",
      "j": 2023,
      "n": "eichenau",
      "dn": "Eichenau",
      "bl": 9,
      "hs": 330.0,
      "mns": 7
    },
    {
      "a": "09179119",
      "j": 2023,
      "n": "emmering",
      "dn": "Emmering",
      "bl": 9,
      "hs": 310.0,
      "mns": 6
    },
    {
      "a": "09179121",
      "j": 2024,
      "n": "fürstenfeldbruck",
      "dn": "Fürstenfeldbruck",
      "bl": 9,
      "hs": 480.0,
      "mns": 7
    },
    {
      "a": "09179123",
      "j": 2025,
      "n": "germering",
      "dn": "Germering",
      "bl": 9,
      "hs": 545.0,
      "mns": 7
    },
    {
      "a": "09179125",
      "j": 2023,
      "n": "grafrath",
      "dn": "Grafrath",
      "bl": 9,
      "hs": 330.0,
      "mns": 6
    },
    {
      "a": "09179126",
      "j": 2025,
      "n": "gröbenzell",
      "dn": "Gröbenzell",
      "bl": 9,
      "hs": 600.0,
      "mns": 7
    },
    {
      "a": "09179128",
      "j": 2023,
      "n": "hattenhofen",
      "dn": "Hattenhofen",
      "bl": 9,
      "hs": 310.0,
      "mns": 6
    },
    {
      "a": "09179130",
      "j": 2023,
      "n": "jesenwang",
      "dn": "Jesenwang",
      "bl": 9,
      "hs": 310.0,
      "mns": 6
    },
    {
      "a": "09179131",
      "j": 2023,
      "n": "kottgeisering",
      "dn": "Kottgeisering",
      "bl": 9,
      "hs": 310.0,
      "mns": 6
    },
    {
      "a": "09179132",
      "j": 2023,
      "n": "landsberied",
      "dn": "Landsberied",
      "bl": 9,
      "hs": 310.0,
      "mns": 6
    },
    {
      "a": "09179134",
      "j": 2023,
      "n": "maisach",
      "dn": "Maisach",
      "bl": 9,
      "hs": 360.0,
      "mns": 6
    },
    {
      "a": "09179136",
      "j": 2023,
      "n": "mammendorf",
      "dn": "Mammendorf",
      "bl": 9,
      "hs": 310.0,
      "mns": 6
    },
    {
      "a": "09179137",
      "j": 2023,
      "n": "mittelstetten",
      "dn": "Mittelstetten",
      "bl": 9,
      "hs": 310.0,
      "mns": 6
    },
    {
      "a": "09179138",
      "j": 2023,
      "n": "moorenweis",
      "dn": "Moorenweis",
      "bl": 9,
      "hs": 295.0,
      "mns": 6
    },
    {
      "a": "09179140",
      "j": 2023,
      "n": "oberschweinbach",
      "dn": "Oberschweinbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 6
    },
    {
      "a": "09179142",
      "j": 2025,
      "n": "olching",
      "dn": "Olching",
      "bl": 9,
      "hs": 360.0,
      "mns": 6
    },
    {
      "a": "09179145",
      "j": 2023,
      "n": "puchheim",
      "dn": "Puchheim",
      "bl": 9,
      "hs": 320.0,
      "mns": 7
    },
    {
      "a": "09179147",
      "j": 2023,
      "n": "schöngeising",
      "dn": "Schöngeising",
      "bl": 9,
      "hs": 330.0,
      "mns": 6
    },
    {
      "a": "09179149",
      "j": 2023,
      "n": "türkenfeld",
      "dn": "Türkenfeld",
      "bl": 9,
      "hs": 300.0,
      "mns": 6
    },
    {
      "a": "09180112",
      "j": 2024,
      "n": "bad kohlgrub",
      "dn": "Bad Kohlgrub",
      "bl": 9,
      "hs": 550.0,
      "mns": 4
    },
    {
      "a": "09180113",
      "j": 2023,
      "n": "bad bayersoien",
      "dn": "Bad Bayersoien",
      "bl": 9,
      "hs": 480.0,
      "mns": 4
    },
    {
      "a": "09180114",
      "j": 2023,
      "n": "eschenlohe",
      "dn": "Eschenlohe",
      "bl": 9,
      "hs": 420.0,
      "mns": 4
    },
    {
      "a": "09180115",
      "j": 2023,
      "n": "ettal",
      "dn": "Ettal",
      "bl": 9,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "09180116",
      "j": 2023,
      "n": "farchant",
      "dn": "Farchant",
      "bl": 9,
      "hs": 390.0,
      "mns": 4
    },
    {
      "a": "09180117",
      "j": 2023,
      "n": "garmisch-partenkirchen",
      "dn": "Garmisch-Partenkirchen",
      "bl": 9,
      "hs": 430.0,
      "mns": 6
    },
    {
      "a": "09180118",
      "j": 2023,
      "n": "grainau",
      "dn": "Grainau",
      "bl": 9,
      "hs": 460.0,
      "mns": 4
    },
    {
      "a": "09180119",
      "j": 2023,
      "n": "großweil",
      "dn": "Großweil",
      "bl": 9,
      "hs": 360.0,
      "mns": 4
    },
    {
      "a": "09180122",
      "j": 2023,
      "n": "krün",
      "dn": "Krün",
      "bl": 9,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "09180123",
      "j": 2023,
      "n": "mittenwald",
      "dn": "Mittenwald",
      "bl": 9,
      "hs": 490.0,
      "mns": 4
    },
    {
      "a": "09180124",
      "j": 2023,
      "n": "murnau am staffelsee",
      "dn": "Murnau am Staffelsee",
      "bl": 9,
      "hs": 430.0,
      "mns": 5
    },
    {
      "a": "09180125",
      "j": 2023,
      "n": "oberammergau",
      "dn": "Oberammergau",
      "bl": 9,
      "hs": 450.0,
      "mns": 4
    },
    {
      "a": "09180126",
      "j": 2023,
      "n": "oberau",
      "dn": "Oberau",
      "bl": 9,
      "hs": 450.0,
      "mns": 4
    },
    {
      "a": "09180127",
      "j": 2023,
      "n": "ohlstadt",
      "dn": "Ohlstadt",
      "bl": 9,
      "hs": 330.0,
      "mns": 4
    },
    {
      "a": "09180128",
      "j": 2023,
      "n": "riegsee",
      "dn": "Riegsee",
      "bl": 9,
      "hs": 210.0,
      "mns": 4
    },
    {
      "a": "09180129",
      "j": 2023,
      "n": "saulgrub",
      "dn": "Saulgrub",
      "bl": 9,
      "hs": 280.0,
      "mns": 4
    },
    {
      "a": "09180131",
      "j": 2023,
      "n": "schwaigen",
      "dn": "Schwaigen",
      "bl": 9,
      "hs": 380.0,
      "mns": 4
    },
    {
      "a": "09180132",
      "j": 2023,
      "n": "seehausen am staffelsee",
      "dn": "Seehausen am Staffelsee",
      "bl": 9,
      "hs": 280.0,
      "mns": 4
    },
    {
      "a": "09180133",
      "j": 2023,
      "n": "spatzenhausen",
      "dn": "Spatzenhausen",
      "bl": 9,
      "hs": 270.0,
      "mns": 4
    },
    {
      "a": "09180134",
      "j": 2023,
      "n": "uffing am staffelsee",
      "dn": "Uffing am Staffelsee",
      "bl": 9,
      "hs": 310.0,
      "mns": 4
    },
    {
      "a": "09180135",
      "j": 2023,
      "n": "unterammergau",
      "dn": "Unterammergau",
      "bl": 9,
      "hs": 450.0,
      "mns": 4
    },
    {
      "a": "09180136",
      "j": 2023,
      "n": "wallgau",
      "dn": "Wallgau",
      "bl": 9,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "09181111",
      "j": 2023,
      "n": "apfeldorf",
      "dn": "Apfeldorf",
      "bl": 9,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "09181113",
      "j": 2023,
      "n": "denklingen",
      "dn": "Denklingen",
      "bl": 9,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "09181114",
      "j": 2023,
      "n": "dießen am ammersee",
      "dn": "Dießen am Ammersee",
      "bl": 9,
      "hs": 310.0,
      "mns": 5
    },
    {
      "a": "09181115",
      "j": 2023,
      "n": "eching am ammersee",
      "dn": "Eching am Ammersee",
      "bl": 9,
      "hs": 290.0,
      "mns": 3
    },
    {
      "a": "09181116",
      "j": 2023,
      "n": "egling an der paar",
      "dn": "Egling an der Paar",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09181118",
      "j": 2023,
      "n": "eresing",
      "dn": "Eresing",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09181120",
      "j": 2023,
      "n": "finning",
      "dn": "Finning",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09181121",
      "j": 2023,
      "n": "fuchstal",
      "dn": "Fuchstal",
      "bl": 9,
      "hs": 280.0,
      "mns": 3
    },
    {
      "a": "09181122",
      "j": 2025,
      "n": "geltendorf",
      "dn": "Geltendorf",
      "bl": 9,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "09181123",
      "j": 2023,
      "n": "greifenberg",
      "dn": "Greifenberg",
      "bl": 9,
      "hs": 260.0,
      "mns": 3
    },
    {
      "a": "09181124",
      "j": 2023,
      "n": "hofstetten",
      "dn": "Hofstetten",
      "bl": 9,
      "hs": 280.0,
      "mns": 3
    },
    {
      "a": "09181126",
      "j": 2023,
      "n": "hurlach",
      "dn": "Hurlach",
      "bl": 9,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "09181127",
      "j": 2023,
      "n": "igling",
      "dn": "Igling",
      "bl": 9,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "09181128",
      "j": 2023,
      "n": "kaufering",
      "dn": "Kaufering",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09181129",
      "j": 2023,
      "n": "kinsau",
      "dn": "Kinsau",
      "bl": 9,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "09181130",
      "j": 2023,
      "n": "landsberg am lech",
      "dn": "Landsberg am Lech",
      "bl": 9,
      "hs": 370.0,
      "mns": 5
    },
    {
      "a": "09181131",
      "j": 2024,
      "n": "obermeitingen",
      "dn": "Obermeitingen",
      "bl": 9,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "09181132",
      "j": 2023,
      "n": "penzing",
      "dn": "Penzing",
      "bl": 9,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "09181133",
      "j": 2023,
      "n": "vilgertshofen",
      "dn": "Vilgertshofen",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09181134",
      "j": 2023,
      "n": "prittriching",
      "dn": "Prittriching",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09181135",
      "j": 2023,
      "n": "reichling",
      "dn": "Reichling",
      "bl": 9,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "09181137",
      "j": 2023,
      "n": "rott",
      "dn": "Rott",
      "bl": 9,
      "hs": 280.0,
      "mns": 3
    },
    {
      "a": "09181138",
      "j": 2023,
      "n": "scheuring",
      "dn": "Scheuring",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09181139",
      "j": 2023,
      "n": "schondorf am ammersee",
      "dn": "Schondorf am Ammersee",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09181140",
      "j": 2023,
      "n": "schwifting",
      "dn": "Schwifting",
      "bl": 9,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "09181141",
      "j": 2023,
      "n": "pürgen",
      "dn": "Pürgen",
      "bl": 9,
      "hs": 280.0,
      "mns": 3
    },
    {
      "a": "09181142",
      "j": 2023,
      "n": "thaining",
      "dn": "Thaining",
      "bl": 9,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "09181143",
      "j": 2023,
      "n": "unterdießen",
      "dn": "Unterdießen",
      "bl": 9,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "09181144",
      "j": 2023,
      "n": "utting am ammersee",
      "dn": "Utting am Ammersee",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09181145",
      "j": 2023,
      "n": "weil",
      "dn": "Weil",
      "bl": 9,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "09181146",
      "j": 2023,
      "n": "windach",
      "dn": "Windach",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09182111",
      "j": 2023,
      "n": "bad wiessee",
      "dn": "Bad Wiessee",
      "bl": 9,
      "hs": 340.0,
      "mns": 4
    },
    {
      "a": "09182112",
      "j": 2023,
      "n": "bayrischzell",
      "dn": "Bayrischzell",
      "bl": 9,
      "hs": 340.0,
      "mns": 4
    },
    {
      "a": "09182114",
      "j": 2023,
      "n": "fischbachau",
      "dn": "Fischbachau",
      "bl": 9,
      "hs": 310.0,
      "mns": 4
    },
    {
      "a": "09182116",
      "j": 2023,
      "n": "gmund am tegernsee",
      "dn": "Gmund am Tegernsee",
      "bl": 9,
      "hs": 320.0,
      "mns": 4
    },
    {
      "a": "09182119",
      "j": 2023,
      "n": "hausham",
      "dn": "Hausham",
      "bl": 9,
      "hs": 310.0,
      "mns": 4
    },
    {
      "a": "09182120",
      "j": 2023,
      "n": "holzkirchen",
      "dn": "Holzkirchen",
      "bl": 9,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "09182123",
      "j": 2023,
      "n": "irschenberg",
      "dn": "Irschenberg",
      "bl": 9,
      "hs": 330.0,
      "mns": 4
    },
    {
      "a": "09182124",
      "j": 2023,
      "n": "kreuth",
      "dn": "Kreuth",
      "bl": 9,
      "hs": 345.0,
      "mns": 4
    },
    {
      "a": "09182125",
      "j": 2023,
      "n": "miesbach",
      "dn": "Miesbach",
      "bl": 9,
      "hs": 420.0,
      "mns": 5
    },
    {
      "a": "09182127",
      "j": 2023,
      "n": "otterfing",
      "dn": "Otterfing",
      "bl": 9,
      "hs": 340.0,
      "mns": 4
    },
    {
      "a": "09182129",
      "j": 2025,
      "n": "rottach-egern",
      "dn": "Rottach-Egern",
      "bl": 9,
      "hs": 500.0,
      "mns": 4
    },
    {
      "a": "09182131",
      "j": 2023,
      "n": "schliersee",
      "dn": "Schliersee",
      "bl": 9,
      "hs": 380.0,
      "mns": 4
    },
    {
      "a": "09182132",
      "j": 2023,
      "n": "tegernsee",
      "dn": "Tegernsee",
      "bl": 9,
      "hs": 330.0,
      "mns": 4
    },
    {
      "a": "09182133",
      "j": 2023,
      "n": "valley",
      "dn": "Valley",
      "bl": 9,
      "hs": 250.0,
      "mns": 4
    },
    {
      "a": "09182134",
      "j": 2023,
      "n": "waakirchen",
      "dn": "Waakirchen",
      "bl": 9,
      "hs": 270.0,
      "mns": 4
    },
    {
      "a": "09182136",
      "j": 2023,
      "n": "warngau",
      "dn": "Warngau",
      "bl": 9,
      "hs": 280.0,
      "mns": 4
    },
    {
      "a": "09182137",
      "j": 2023,
      "n": "weyarn",
      "dn": "Weyarn",
      "bl": 9,
      "hs": 380.0,
      "mns": 4
    },
    {
      "a": "09183112",
      "j": 2023,
      "n": "ampfing",
      "dn": "Ampfing",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09183113",
      "j": 2023,
      "n": "aschau am inn",
      "dn": "Aschau am Inn",
      "bl": 9,
      "hs": 250.0,
      "mns": 1
    },
    {
      "a": "09183114",
      "j": 2023,
      "n": "buchbach",
      "dn": "Buchbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09183115",
      "j": 2023,
      "n": "egglkofen",
      "dn": "Egglkofen",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09183116",
      "j": 2023,
      "n": "erharting",
      "dn": "Erharting",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09183118",
      "j": 2023,
      "n": "gars am inn",
      "dn": "Gars am Inn",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09183119",
      "j": 2023,
      "n": "haag in oberbayern",
      "dn": "Haag in Oberbayern",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09183120",
      "j": 2023,
      "n": "heldenstein",
      "dn": "Heldenstein",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09183122",
      "j": 2023,
      "n": "jettenbach",
      "dn": "Jettenbach",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09183123",
      "j": 2023,
      "n": "kirchdorf",
      "dn": "Kirchdorf",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09183124",
      "j": 2023,
      "n": "kraiburg am inn",
      "dn": "Kraiburg am Inn",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09183125",
      "j": 2023,
      "n": "lohkirchen",
      "dn": "Lohkirchen",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09183126",
      "j": 2023,
      "n": "maitenbeth",
      "dn": "Maitenbeth",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09183127",
      "j": 2023,
      "n": "mettenheim",
      "dn": "Mettenheim",
      "bl": 9,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "09183128",
      "j": 2023,
      "n": "mühldorf am inn",
      "dn": "Mühldorf am Inn",
      "bl": 9,
      "hs": 365.0,
      "mns": 2
    },
    {
      "a": "09183129",
      "j": 2023,
      "n": "neumarkt-sankt veit",
      "dn": "Neumarkt-Sankt Veit",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09183130",
      "j": 2023,
      "n": "niederbergkirchen",
      "dn": "Niederbergkirchen",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09183131",
      "j": 2023,
      "n": "niedertaufkirchen",
      "dn": "Niedertaufkirchen",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09183132",
      "j": 2023,
      "n": "oberbergkirchen",
      "dn": "Oberbergkirchen",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09183134",
      "j": 2023,
      "n": "oberneukirchen",
      "dn": "Oberneukirchen",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09183135",
      "j": 2023,
      "n": "obertaufkirchen",
      "dn": "Obertaufkirchen",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09183136",
      "j": 2023,
      "n": "polling",
      "dn": "Polling",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09183138",
      "j": 2023,
      "n": "rattenkirchen",
      "dn": "Rattenkirchen",
      "bl": 9,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "09183139",
      "j": 2023,
      "n": "rechtmehring",
      "dn": "Rechtmehring",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09183140",
      "j": 2023,
      "n": "reichertsheim",
      "dn": "Reichertsheim",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09183143",
      "j": 2023,
      "n": "schönberg",
      "dn": "Schönberg",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09183144",
      "j": 2023,
      "n": "schwindegg",
      "dn": "Schwindegg",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09183145",
      "j": 2023,
      "n": "taufkirchen",
      "dn": "Taufkirchen",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09183147",
      "j": 2023,
      "n": "unterreit",
      "dn": "Unterreit",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09183148",
      "j": 2023,
      "n": "waldkraiburg",
      "dn": "Waldkraiburg",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09183151",
      "j": 2023,
      "n": "zangberg",
      "dn": "Zangberg",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09184112",
      "j": 2025,
      "n": "aschheim",
      "dn": "Aschheim",
      "bl": 9,
      "hs": 330.0,
      "mns": 7
    },
    {
      "a": "09184113",
      "j": 2023,
      "n": "baierbrunn",
      "dn": "Baierbrunn",
      "bl": 9,
      "hs": 250.0,
      "mns": 7
    },
    {
      "a": "09184114",
      "j": 2023,
      "n": "brunnthal",
      "dn": "Brunnthal",
      "bl": 9,
      "hs": 300.0,
      "mns": 7
    },
    {
      "a": "09184118",
      "j": 2023,
      "n": "feldkirchen",
      "dn": "Feldkirchen",
      "bl": 9,
      "hs": 280.0,
      "mns": 7
    },
    {
      "a": "09184119",
      "j": 2023,
      "n": "garching bei münchen",
      "dn": "Garching bei München",
      "bl": 9,
      "hs": 310.0,
      "mns": 6
    },
    {
      "a": "09184120",
      "j": 2023,
      "n": "gräfelfing",
      "dn": "Gräfelfing",
      "bl": 9,
      "hs": 200.0,
      "mns": 7
    },
    {
      "a": "09184121",
      "j": 2023,
      "n": "grasbrunn",
      "dn": "Grasbrunn",
      "bl": 9,
      "hs": 260.0,
      "mns": 7
    },
    {
      "a": "09184122",
      "j": 2023,
      "n": "grünwald",
      "dn": "Grünwald",
      "bl": 9,
      "hs": 200.0,
      "mns": 7
    },
    {
      "a": "09184123",
      "j": 2023,
      "n": "haar",
      "dn": "Haar",
      "bl": 9,
      "hs": 310.0,
      "mns": 7
    },
    {
      "a": "09184127",
      "j": 2023,
      "n": "höhenkirchen-siegertsbrunn",
      "dn": "Höhenkirchen-Siegertsbrunn",
      "bl": 9,
      "hs": 310.0,
      "mns": 7
    },
    {
      "a": "09184129",
      "j": 2023,
      "n": "hohenbrunn",
      "dn": "Hohenbrunn",
      "bl": 9,
      "hs": 280.0,
      "mns": 7
    },
    {
      "a": "09184130",
      "j": 2025,
      "n": "ismaning",
      "dn": "Ismaning",
      "bl": 9,
      "hs": 295.0,
      "mns": 7
    },
    {
      "a": "09184131",
      "j": 2023,
      "n": "kirchheim bei münchen",
      "dn": "Kirchheim bei München",
      "bl": 9,
      "hs": 280.0,
      "mns": 7
    },
    {
      "a": "09184132",
      "j": 2023,
      "n": "neuried",
      "dn": "Neuried",
      "bl": 9,
      "hs": 330.0,
      "mns": 7
    },
    {
      "a": "09184134",
      "j": 2023,
      "n": "oberhaching",
      "dn": "Oberhaching",
      "bl": 9,
      "hs": 300.0,
      "mns": 7
    },
    {
      "a": "09184135",
      "j": 2023,
      "n": "oberschleißheim",
      "dn": "Oberschleißheim",
      "bl": 9,
      "hs": 310.0,
      "mns": 7
    },
    {
      "a": "09184136",
      "j": 2023,
      "n": "ottobrunn",
      "dn": "Ottobrunn",
      "bl": 9,
      "hs": 340.0,
      "mns": 7
    },
    {
      "a": "09184137",
      "j": 2023,
      "n": "aying",
      "dn": "Aying",
      "bl": 9,
      "hs": 310.0,
      "mns": 7
    },
    {
      "a": "09184138",
      "j": 2023,
      "n": "planegg",
      "dn": "Planegg",
      "bl": 9,
      "hs": 310.0,
      "mns": 7
    },
    {
      "a": "09184139",
      "j": 2023,
      "n": "pullach im isartal",
      "dn": "Pullach im Isartal",
      "bl": 9,
      "hs": 225.0,
      "mns": 7
    },
    {
      "a": "09184140",
      "j": 2023,
      "n": "putzbrunn",
      "dn": "Putzbrunn",
      "bl": 9,
      "hs": 260.0,
      "mns": 7
    },
    {
      "a": "09184141",
      "j": 2023,
      "n": "sauerlach",
      "dn": "Sauerlach",
      "bl": 9,
      "hs": 300.0,
      "mns": 7
    },
    {
      "a": "09184142",
      "j": 2023,
      "n": "schäftlarn",
      "dn": "Schäftlarn",
      "bl": 9,
      "hs": 340.0,
      "mns": 7
    },
    {
      "a": "09184144",
      "j": 2023,
      "n": "straßlach-dingharting",
      "dn": "Straßlach-Dingharting",
      "bl": 9,
      "hs": 310.0,
      "mns": 7
    },
    {
      "a": "09184145",
      "j": 2023,
      "n": "taufkirchen",
      "dn": "Taufkirchen",
      "bl": 9,
      "hs": 295.0,
      "mns": 3
    },
    {
      "a": "09184146",
      "j": 2023,
      "n": "neubiberg",
      "dn": "Neubiberg",
      "bl": 9,
      "hs": 320.0,
      "mns": 7
    },
    {
      "a": "09184147",
      "j": 2023,
      "n": "unterföhring",
      "dn": "Unterföhring",
      "bl": 9,
      "hs": 250.0,
      "mns": 7
    },
    {
      "a": "09184148",
      "j": 2023,
      "n": "unterhaching",
      "dn": "Unterhaching",
      "bl": 9,
      "hs": 280.0,
      "mns": 7
    },
    {
      "a": "09184149",
      "j": 2023,
      "n": "unterschleißheim",
      "dn": "Unterschleißheim",
      "bl": 9,
      "hs": 280.0,
      "mns": 7
    },
    {
      "a": "09185113",
      "j": 2023,
      "n": "aresing",
      "dn": "Aresing",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09185116",
      "j": 2023,
      "n": "berg im gau",
      "dn": "Berg im Gau",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09185118",
      "j": 2023,
      "n": "bergheim",
      "dn": "Bergheim",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09185123",
      "j": 2023,
      "n": "brunnen",
      "dn": "Brunnen",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09185125",
      "j": 2023,
      "n": "burgheim",
      "dn": "Burgheim",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09185127",
      "j": 2023,
      "n": "ehekirchen",
      "dn": "Ehekirchen",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09185131",
      "j": 2023,
      "n": "gachenbach",
      "dn": "Gachenbach",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09185139",
      "j": 2023,
      "n": "karlshuld",
      "dn": "Karlshuld",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09185140",
      "j": 2023,
      "n": "karlskron",
      "dn": "Karlskron",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09185143",
      "j": 2023,
      "n": "langenmosen",
      "dn": "Langenmosen",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09185149",
      "j": 2023,
      "n": "neuburg an der donau",
      "dn": "Neuburg an der Donau",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09185150",
      "j": 2023,
      "n": "oberhausen",
      "dn": "Oberhausen",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09185153",
      "j": 2023,
      "n": "rennertshofen",
      "dn": "Rennertshofen",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09185157",
      "j": 2023,
      "n": "rohrenfels",
      "dn": "Rohrenfels",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09185158",
      "j": 2025,
      "n": "schrobenhausen",
      "dn": "Schrobenhausen",
      "bl": 9,
      "hs": 260.0,
      "mns": 2
    },
    {
      "a": "09185163",
      "j": 2023,
      "n": "königsmoos",
      "dn": "Königsmoos",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09185166",
      "j": 2023,
      "n": "waidhofen",
      "dn": "Waidhofen",
      "bl": 9,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "09185168",
      "j": 2023,
      "n": "weichering",
      "dn": "Weichering",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09186113",
      "j": 2023,
      "n": "baar-ebenhausen",
      "dn": "Baar-Ebenhausen",
      "bl": 9,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "09186116",
      "j": 2024,
      "n": "ernsgaden",
      "dn": "Ernsgaden",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09186122",
      "j": 2023,
      "n": "geisenfeld",
      "dn": "Geisenfeld",
      "bl": 9,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "09186125",
      "j": 2023,
      "n": "gerolsbach",
      "dn": "Gerolsbach",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09186126",
      "j": 2023,
      "n": "hettenshausen",
      "dn": "Hettenshausen",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09186128",
      "j": 2023,
      "n": "hohenwart",
      "dn": "Hohenwart",
      "bl": 9,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "09186130",
      "j": 2023,
      "n": "ilmmünster",
      "dn": "Ilmmünster",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09186132",
      "j": 2023,
      "n": "jetzendorf",
      "dn": "Jetzendorf",
      "bl": 9,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "09186137",
      "j": 2023,
      "n": "manching",
      "dn": "Manching",
      "bl": 9,
      "hs": 275.0,
      "mns": 5
    },
    {
      "a": "09186139",
      "j": 2023,
      "n": "münchsmünster",
      "dn": "Münchsmünster",
      "bl": 9,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "09186143",
      "j": 2023,
      "n": "pfaffenhofen an der ilm",
      "dn": "Pfaffenhofen an der Ilm",
      "bl": 9,
      "hs": 380.0,
      "mns": 4
    },
    {
      "a": "09186144",
      "j": 2023,
      "n": "pörnbach",
      "dn": "Pörnbach",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09186146",
      "j": 2023,
      "n": "reichertshausen",
      "dn": "Reichertshausen",
      "bl": 9,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "09186147",
      "j": 2023,
      "n": "reichertshofen",
      "dn": "Reichertshofen",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09186149",
      "j": 2023,
      "n": "rohrbach",
      "dn": "Rohrbach",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09186151",
      "j": 2023,
      "n": "scheyern",
      "dn": "Scheyern",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09186152",
      "j": 2023,
      "n": "schweitenkirchen",
      "dn": "Schweitenkirchen",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09186158",
      "j": 2023,
      "n": "vohburg an der donau",
      "dn": "Vohburg an der Donau",
      "bl": 9,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "09186162",
      "j": 2023,
      "n": "wolnzach",
      "dn": "Wolnzach",
      "bl": 9,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "09187113",
      "j": 2023,
      "n": "amerang",
      "dn": "Amerang",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09187114",
      "j": 2023,
      "n": "aschau im chiemgau",
      "dn": "Aschau im Chiemgau",
      "bl": 9,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "09187116",
      "j": 2023,
      "n": "babensham",
      "dn": "Babensham",
      "bl": 9,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "09187117",
      "j": 2023,
      "n": "bad aibling",
      "dn": "Bad Aibling",
      "bl": 9,
      "hs": 330.0,
      "mns": 4
    },
    {
      "a": "09187118",
      "j": 2023,
      "n": "bernau am chiemsee",
      "dn": "Bernau am Chiemsee",
      "bl": 9,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "09187120",
      "j": 2023,
      "n": "brannenburg",
      "dn": "Brannenburg",
      "bl": 9,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "09187121",
      "j": 2023,
      "n": "breitbrunn am chiemsee",
      "dn": "Breitbrunn am Chiemsee",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09187122",
      "j": 2023,
      "n": "bruckmühl",
      "dn": "Bruckmühl",
      "bl": 9,
      "hs": 320.0,
      "mns": 4
    },
    {
      "a": "09187123",
      "j": 2023,
      "n": "chiemsee",
      "dn": "Chiemsee",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09187124",
      "j": 2023,
      "n": "edling",
      "dn": "Edling",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09187125",
      "j": 2023,
      "n": "eggstätt",
      "dn": "Eggstätt",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09187126",
      "j": 2023,
      "n": "eiselfing",
      "dn": "Eiselfing",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09187128",
      "j": 2025,
      "n": "bad endorf",
      "dn": "Bad Endorf",
      "bl": 9,
      "hs": 375.0,
      "mns": 3
    },
    {
      "a": "09187129",
      "j": 2023,
      "n": "bad feilnbach",
      "dn": "Bad Feilnbach",
      "bl": 9,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "09187130",
      "j": 2023,
      "n": "feldkirchen-westerham",
      "dn": "Feldkirchen-Westerham",
      "bl": 9,
      "hs": 330.0,
      "mns": 5
    },
    {
      "a": "09187131",
      "j": 2023,
      "n": "flintsbach am inn",
      "dn": "Flintsbach am Inn",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09187132",
      "j": 2023,
      "n": "frasdorf",
      "dn": "Frasdorf",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09187134",
      "j": 2023,
      "n": "griesstätt",
      "dn": "Griesstätt",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09187137",
      "j": 2023,
      "n": "großkarolinenfeld",
      "dn": "Großkarolinenfeld",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09187138",
      "j": 2023,
      "n": "gstadt am chiemsee",
      "dn": "Gstadt am Chiemsee",
      "bl": 9,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "09187139",
      "j": 2023,
      "n": "halfing",
      "dn": "Halfing",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09187142",
      "j": 2023,
      "n": "schechen",
      "dn": "Schechen",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09187145",
      "j": 2023,
      "n": "höslwang",
      "dn": "Höslwang",
      "bl": 9,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "09187148",
      "j": 2023,
      "n": "kiefersfelden",
      "dn": "Kiefersfelden",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09187150",
      "j": 2025,
      "n": "kolbermoor",
      "dn": "Kolbermoor",
      "bl": 9,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "09187154",
      "j": 2023,
      "n": "neubeuern",
      "dn": "Neubeuern",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09187156",
      "j": 2023,
      "n": "nußdorf am inn",
      "dn": "Nußdorf am Inn",
      "bl": 9,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "09187157",
      "j": 2023,
      "n": "oberaudorf",
      "dn": "Oberaudorf",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09187159",
      "j": 2023,
      "n": "pfaffing",
      "dn": "Pfaffing",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09187162",
      "j": 2023,
      "n": "prien am chiemsee",
      "dn": "Prien am Chiemsee",
      "bl": 9,
      "hs": 360.0,
      "mns": 4
    },
    {
      "a": "09187163",
      "j": 2023,
      "n": "prutting",
      "dn": "Prutting",
      "bl": 9,
      "hs": 325.0,
      "mns": 3
    },
    {
      "a": "09187164",
      "j": 2023,
      "n": "ramerberg",
      "dn": "Ramerberg",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09187165",
      "j": 2023,
      "n": "raubling",
      "dn": "Raubling",
      "bl": 9,
      "hs": 310.0,
      "mns": 4
    },
    {
      "a": "09187167",
      "j": 2023,
      "n": "riedering",
      "dn": "Riedering",
      "bl": 9,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "09187168",
      "j": 2023,
      "n": "rimsting",
      "dn": "Rimsting",
      "bl": 9,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "09187169",
      "j": 2023,
      "n": "rohrdorf",
      "dn": "Rohrdorf",
      "bl": 9,
      "hs": 240.0,
      "mns": 3
    },
    {
      "a": "09187170",
      "j": 2023,
      "n": "rott am inn",
      "dn": "Rott am Inn",
      "bl": 9,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "09187172",
      "j": 2023,
      "n": "samerberg",
      "dn": "Samerberg",
      "bl": 9,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "09187173",
      "j": 2023,
      "n": "schonstett",
      "dn": "Schonstett",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09187174",
      "j": 2023,
      "n": "söchtenau",
      "dn": "Söchtenau",
      "bl": 9,
      "hs": 325.0,
      "mns": 3
    },
    {
      "a": "09187176",
      "j": 2023,
      "n": "soyen",
      "dn": "Soyen",
      "bl": 9,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "09187177",
      "j": 2023,
      "n": "stephanskirchen",
      "dn": "Stephanskirchen",
      "bl": 9,
      "hs": 270.0,
      "mns": 5
    },
    {
      "a": "09187179",
      "j": 2023,
      "n": "tuntenhausen",
      "dn": "Tuntenhausen",
      "bl": 9,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "09187181",
      "j": 2023,
      "n": "vogtareuth",
      "dn": "Vogtareuth",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09187182",
      "j": 2023,
      "n": "wasserburg am inn",
      "dn": "Wasserburg am Inn",
      "bl": 9,
      "hs": 380.0,
      "mns": 4
    },
    {
      "a": "09187186",
      "j": 2023,
      "n": "albaching",
      "dn": "Albaching",
      "bl": 9,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "09188113",
      "j": 2023,
      "n": "berg",
      "dn": "Berg",
      "bl": 9,
      "hs": 350.0,
      "mns": 6
    },
    {
      "a": "09188117",
      "j": 2023,
      "n": "andechs",
      "dn": "Andechs",
      "bl": 9,
      "hs": 310.0,
      "mns": 6
    },
    {
      "a": "09188118",
      "j": 2023,
      "n": "feldafing",
      "dn": "Feldafing",
      "bl": 9,
      "hs": 340.0,
      "mns": 6
    },
    {
      "a": "09188120",
      "j": 2023,
      "n": "gauting",
      "dn": "Gauting",
      "bl": 9,
      "hs": 360.0,
      "mns": 7
    },
    {
      "a": "09188121",
      "j": 2023,
      "n": "gilching",
      "dn": "Gilching",
      "bl": 9,
      "hs": 340.0,
      "mns": 7
    },
    {
      "a": "09188124",
      "j": 2023,
      "n": "herrsching am ammersee",
      "dn": "Herrsching am Ammersee",
      "bl": 9,
      "hs": 300.0,
      "mns": 5
    },
    {
      "a": "09188126",
      "j": 2023,
      "n": "inning am ammersee",
      "dn": "Inning am Ammersee",
      "bl": 9,
      "hs": 320.0,
      "mns": 6
    },
    {
      "a": "09188127",
      "j": 2023,
      "n": "krailling",
      "dn": "Krailling",
      "bl": 9,
      "hs": 340.0,
      "mns": 6
    },
    {
      "a": "09188132",
      "j": 2023,
      "n": "seefeld",
      "dn": "Seefeld",
      "bl": 9,
      "hs": 290.0,
      "mns": 6
    },
    {
      "a": "09188137",
      "j": 2023,
      "n": "pöcking",
      "dn": "Pöcking",
      "bl": 9,
      "hs": 330.0,
      "mns": 6
    },
    {
      "a": "09188139",
      "j": 2023,
      "n": "starnberg",
      "dn": "Starnberg",
      "bl": 9,
      "hs": 430.0,
      "mns": 7
    },
    {
      "a": "09188141",
      "j": 2023,
      "n": "tutzing",
      "dn": "Tutzing",
      "bl": 9,
      "hs": 340.0,
      "mns": 6
    },
    {
      "a": "09188144",
      "j": 2023,
      "n": "weßling",
      "dn": "Weßling",
      "bl": 9,
      "hs": 330.0,
      "mns": 6
    },
    {
      "a": "09188145",
      "j": 2023,
      "n": "wörthsee",
      "dn": "Wörthsee",
      "bl": 9,
      "hs": 320.0,
      "mns": 6
    },
    {
      "a": "09189111",
      "j": 2023,
      "n": "altenmarkt an der alz",
      "dn": "Altenmarkt an der Alz",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09189113",
      "j": 2023,
      "n": "bergen",
      "dn": "Bergen",
      "bl": 9,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "09189114",
      "j": 2023,
      "n": "chieming",
      "dn": "Chieming",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09189115",
      "j": 2023,
      "n": "engelsberg",
      "dn": "Engelsberg",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09189118",
      "j": 2023,
      "n": "fridolfing",
      "dn": "Fridolfing",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09189119",
      "j": 2023,
      "n": "grabenstätt",
      "dn": "Grabenstätt",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09189120",
      "j": 2023,
      "n": "grassau",
      "dn": "Grassau",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09189124",
      "j": 2023,
      "n": "inzell",
      "dn": "Inzell",
      "bl": 9,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "09189126",
      "j": 2023,
      "n": "kienberg",
      "dn": "Kienberg",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09189127",
      "j": 2023,
      "n": "kirchanschöring",
      "dn": "Kirchanschöring",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09189129",
      "j": 2023,
      "n": "marquartstein",
      "dn": "Marquartstein",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09189130",
      "j": 2023,
      "n": "nußdorf",
      "dn": "Nußdorf",
      "bl": 9,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "09189133",
      "j": 2023,
      "n": "obing",
      "dn": "Obing",
      "bl": 9,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "09189134",
      "j": 2023,
      "n": "palling",
      "dn": "Palling",
      "bl": 9,
      "hs": 290.0,
      "mns": 2
    },
    {
      "a": "09189135",
      "j": 2023,
      "n": "petting",
      "dn": "Petting",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09189137",
      "j": 2023,
      "n": "pittenhart",
      "dn": "Pittenhart",
      "bl": 9,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "09189139",
      "j": 2023,
      "n": "reit im winkl",
      "dn": "Reit im Winkl",
      "bl": 9,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "09189140",
      "j": 2023,
      "n": "ruhpolding",
      "dn": "Ruhpolding",
      "bl": 9,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "09189141",
      "j": 2023,
      "n": "schleching",
      "dn": "Schleching",
      "bl": 9,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "09189142",
      "j": 2023,
      "n": "schnaitsee",
      "dn": "Schnaitsee",
      "bl": 9,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "09189143",
      "j": 2023,
      "n": "seeon-seebruck",
      "dn": "Seeon-Seebruck",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09189145",
      "j": 2023,
      "n": "siegsdorf",
      "dn": "Siegsdorf",
      "bl": 9,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "09189146",
      "j": 2023,
      "n": "staudach-egerndach",
      "dn": "Staudach-Egerndach",
      "bl": 9,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "09189148",
      "j": 2023,
      "n": "surberg",
      "dn": "Surberg",
      "bl": 9,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "09189149",
      "j": 2023,
      "n": "tacherting",
      "dn": "Tacherting",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09189150",
      "j": 2023,
      "n": "taching am see",
      "dn": "Taching am See",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09189152",
      "j": 2023,
      "n": "tittmoning",
      "dn": "Tittmoning",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09189154",
      "j": 2023,
      "n": "traunreut",
      "dn": "Traunreut",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09189155",
      "j": 2023,
      "n": "traunstein",
      "dn": "Traunstein",
      "bl": 9,
      "hs": 375.0,
      "mns": 3
    },
    {
      "a": "09189157",
      "j": 2023,
      "n": "trostberg",
      "dn": "Trostberg",
      "bl": 9,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "09189159",
      "j": 2023,
      "n": "übersee",
      "dn": "Übersee",
      "bl": 9,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "09189160",
      "j": 2023,
      "n": "unterwössen",
      "dn": "Unterwössen",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09189161",
      "j": 2023,
      "n": "vachendorf",
      "dn": "Vachendorf",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09189162",
      "j": 2023,
      "n": "waging am see",
      "dn": "Waging am See",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09189165",
      "j": 2023,
      "n": "wonneberg",
      "dn": "Wonneberg",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09190111",
      "j": 2023,
      "n": "altenstadt",
      "dn": "Altenstadt",
      "bl": 9,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "09190113",
      "j": 2023,
      "n": "antdorf",
      "dn": "Antdorf",
      "bl": 9,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "09190114",
      "j": 2023,
      "n": "bernbeuren",
      "dn": "Bernbeuren",
      "bl": 9,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "09190115",
      "j": 2023,
      "n": "bernried am starnberger see",
      "dn": "Bernried am Starnberger See",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09190117",
      "j": 2023,
      "n": "böbing",
      "dn": "Böbing",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09190118",
      "j": 2023,
      "n": "burggen",
      "dn": "Burggen",
      "bl": 9,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "09190120",
      "j": 2023,
      "n": "eberfing",
      "dn": "Eberfing",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09190121",
      "j": 2023,
      "n": "eglfing",
      "dn": "Eglfing",
      "bl": 9,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "09190126",
      "j": 2023,
      "n": "habach",
      "dn": "Habach",
      "bl": 9,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "09190129",
      "j": 2023,
      "n": "hohenfurch",
      "dn": "Hohenfurch",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09190130",
      "j": 2023,
      "n": "hohenpeißenberg",
      "dn": "Hohenpeißenberg",
      "bl": 9,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "09190131",
      "j": 2023,
      "n": "huglfing",
      "dn": "Huglfing",
      "bl": 9,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "09190132",
      "j": 2023,
      "n": "iffeldorf",
      "dn": "Iffeldorf",
      "bl": 9,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "09190133",
      "j": 2023,
      "n": "ingenried",
      "dn": "Ingenried",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09190135",
      "j": 2023,
      "n": "oberhausen",
      "dn": "Oberhausen",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09190136",
      "j": 2023,
      "n": "obersöchering",
      "dn": "Obersöchering",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09190138",
      "j": 2023,
      "n": "pähl",
      "dn": "Pähl",
      "bl": 9,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "09190139",
      "j": 2023,
      "n": "peißenberg",
      "dn": "Peißenberg",
      "bl": 9,
      "hs": 380.0,
      "mns": 4
    },
    {
      "a": "09190140",
      "j": 2023,
      "n": "peiting",
      "dn": "Peiting",
      "bl": 9,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "09190141",
      "j": 2023,
      "n": "penzberg",
      "dn": "Penzberg",
      "bl": 9,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "09190142",
      "j": 2023,
      "n": "polling",
      "dn": "Polling",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09190143",
      "j": 2023,
      "n": "prem",
      "dn": "Prem",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09190144",
      "j": 2023,
      "n": "raisting",
      "dn": "Raisting",
      "bl": 9,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "09190145",
      "j": 2023,
      "n": "rottenbuch",
      "dn": "Rottenbuch",
      "bl": 9,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "09190148",
      "j": 2023,
      "n": "schongau",
      "dn": "Schongau",
      "bl": 9,
      "hs": 380.0,
      "mns": 4
    },
    {
      "a": "09190149",
      "j": 2023,
      "n": "schwabbruck",
      "dn": "Schwabbruck",
      "bl": 9,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "09190151",
      "j": 2023,
      "n": "schwabsoien",
      "dn": "Schwabsoien",
      "bl": 9,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "09190152",
      "j": 2023,
      "n": "seeshaupt",
      "dn": "Seeshaupt",
      "bl": 9,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "09190153",
      "j": 2023,
      "n": "sindelsdorf",
      "dn": "Sindelsdorf",
      "bl": 9,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "09190154",
      "j": 2023,
      "n": "steingaden",
      "dn": "Steingaden",
      "bl": 9,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "09190157",
      "j": 2023,
      "n": "weilheim in oberbayern",
      "dn": "Weilheim in Oberbayern",
      "bl": 9,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "09190158",
      "j": 2023,
      "n": "wessobrunn",
      "dn": "Wessobrunn",
      "bl": 9,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "09190159",
      "j": 2023,
      "n": "wielenbach",
      "dn": "Wielenbach",
      "bl": 9,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "09190160",
      "j": 2023,
      "n": "wildsteig",
      "dn": "Wildsteig",
      "bl": 9,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "09261000",
      "j": 2023,
      "n": "landshut",
      "dn": "Landshut",
      "bl": 9,
      "hs": 430.0,
      "mns": 4
    },
    {
      "a": "09262000",
      "j": 2023,
      "n": "passau",
      "dn": "Passau",
      "bl": 9,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "09263000",
      "j": 2023,
      "n": "straubing",
      "dn": "Straubing",
      "bl": 9,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "09271111",
      "j": 2023,
      "n": "aholming",
      "dn": "Aholming",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09271113",
      "j": 2023,
      "n": "auerbach",
      "dn": "Auerbach",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09271114",
      "j": 2023,
      "n": "außernzell",
      "dn": "Außernzell",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09271116",
      "j": 2023,
      "n": "bernried",
      "dn": "Bernried",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09271118",
      "j": 2023,
      "n": "buchhofen",
      "dn": "Buchhofen",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09271119",
      "j": 2023,
      "n": "deggendorf",
      "dn": "Deggendorf",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09271122",
      "j": 2023,
      "n": "grafling",
      "dn": "Grafling",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09271123",
      "j": 2023,
      "n": "grattersdorf",
      "dn": "Grattersdorf",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09271125",
      "j": 2023,
      "n": "hengersberg",
      "dn": "Hengersberg",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09271126",
      "j": 2023,
      "n": "hunding",
      "dn": "Hunding",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09271127",
      "j": 2023,
      "n": "iggensbach",
      "dn": "Iggensbach",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09271128",
      "j": 2023,
      "n": "künzing",
      "dn": "Künzing",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09271130",
      "j": 2023,
      "n": "lalling",
      "dn": "Lalling",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09271132",
      "j": 2023,
      "n": "metten",
      "dn": "Metten",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09271135",
      "j": 2023,
      "n": "moos",
      "dn": "Moos",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09271138",
      "j": 2023,
      "n": "niederalteich",
      "dn": "Niederalteich",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09271139",
      "j": 2023,
      "n": "oberpöring",
      "dn": "Oberpöring",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09271140",
      "j": 2023,
      "n": "offenberg",
      "dn": "Offenberg",
      "bl": 9,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "09271141",
      "j": 2023,
      "n": "osterhofen",
      "dn": "Osterhofen",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09271143",
      "j": 2023,
      "n": "otzing",
      "dn": "Otzing",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09271146",
      "j": 2023,
      "n": "plattling",
      "dn": "Plattling",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09271148",
      "j": 2023,
      "n": "schaufling",
      "dn": "Schaufling",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09271149",
      "j": 2023,
      "n": "schöllnach",
      "dn": "Schöllnach",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09271151",
      "j": 2023,
      "n": "stephansposching",
      "dn": "Stephansposching",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09271152",
      "j": 2023,
      "n": "wallerfing",
      "dn": "Wallerfing",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09271153",
      "j": 2023,
      "n": "winzer",
      "dn": "Winzer",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09272116",
      "j": 2023,
      "n": "eppenschlag",
      "dn": "Eppenschlag",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09272118",
      "j": 2023,
      "n": "freyung",
      "dn": "Freyung",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09272119",
      "j": 2023,
      "n": "fürsteneck",
      "dn": "Fürsteneck",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09272120",
      "j": 2023,
      "n": "grafenau",
      "dn": "Grafenau",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09272121",
      "j": 2023,
      "n": "grainet",
      "dn": "Grainet",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09272122",
      "j": 2023,
      "n": "haidmühle",
      "dn": "Haidmühle",
      "bl": 9,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "09272126",
      "j": 2023,
      "n": "hinterschmiding",
      "dn": "Hinterschmiding",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09272127",
      "j": 2023,
      "n": "hohenau",
      "dn": "Hohenau",
      "bl": 9,
      "hs": 345.0,
      "mns": 1
    },
    {
      "a": "09272128",
      "j": 2023,
      "n": "innernzell",
      "dn": "Innernzell",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09272129",
      "j": 2023,
      "n": "jandelsbrunn",
      "dn": "Jandelsbrunn",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09272134",
      "j": 2023,
      "n": "mauth",
      "dn": "Mauth",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09272136",
      "j": 2023,
      "n": "neureichenau",
      "dn": "Neureichenau",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09272138",
      "j": 2023,
      "n": "perlesreut",
      "dn": "Perlesreut",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09272139",
      "j": 2023,
      "n": "philippsreut",
      "dn": "Philippsreut",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09272140",
      "j": 2023,
      "n": "ringelai",
      "dn": "Ringelai",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09272141",
      "j": 2023,
      "n": "röhrnbach",
      "dn": "Röhrnbach",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09272142",
      "j": 2023,
      "n": "saldenburg",
      "dn": "Saldenburg",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09272143",
      "j": 2023,
      "n": "sankt oswald-riedlhütte",
      "dn": "Sankt Oswald-Riedlhütte",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09272145",
      "j": 2023,
      "n": "schöfweg",
      "dn": "Schöfweg",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09272146",
      "j": 2023,
      "n": "neuschönau",
      "dn": "Neuschönau",
      "bl": 9,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "09272147",
      "j": 2023,
      "n": "schönberg",
      "dn": "Schönberg",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09272149",
      "j": 2023,
      "n": "spiegelau",
      "dn": "Spiegelau",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09272150",
      "j": 2023,
      "n": "thurmansbang",
      "dn": "Thurmansbang",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09272151",
      "j": 2023,
      "n": "waldkirchen",
      "dn": "Waldkirchen",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09272152",
      "j": 2023,
      "n": "zenting",
      "dn": "Zenting",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09273111",
      "j": 2023,
      "n": "abensberg",
      "dn": "Abensberg",
      "bl": 9,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "09273113",
      "j": 2023,
      "n": "aiglsbach",
      "dn": "Aiglsbach",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09273115",
      "j": 2023,
      "n": "attenhofen",
      "dn": "Attenhofen",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09273116",
      "j": 2023,
      "n": "bad abbach",
      "dn": "Bad Abbach",
      "bl": 9,
      "hs": 430.0,
      "mns": 3
    },
    {
      "a": "09273119",
      "j": 2023,
      "n": "biburg",
      "dn": "Biburg",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09273121",
      "j": 2023,
      "n": "essing",
      "dn": "Essing",
      "bl": 9,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "09273125",
      "j": 2023,
      "n": "hausen",
      "dn": "Hausen",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09273127",
      "j": 2023,
      "n": "herrngiersdorf",
      "dn": "Herrngiersdorf",
      "bl": 9,
      "hs": 250.0,
      "mns": 1
    },
    {
      "a": "09273133",
      "j": 2023,
      "n": "ihrlerstein",
      "dn": "Ihrlerstein",
      "bl": 9,
      "hs": 290.0,
      "mns": 1
    },
    {
      "a": "09273137",
      "j": 2023,
      "n": "kelheim",
      "dn": "Kelheim",
      "bl": 9,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "09273139",
      "j": 2023,
      "n": "kirchdorf",
      "dn": "Kirchdorf",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09273141",
      "j": 2023,
      "n": "langquaid",
      "dn": "Langquaid",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09273147",
      "j": 2023,
      "n": "mainburg",
      "dn": "Mainburg",
      "bl": 9,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "09273152",
      "j": 2023,
      "n": "neustadt an der donau",
      "dn": "Neustadt an der Donau",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09273159",
      "j": 2023,
      "n": "painten",
      "dn": "Painten",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09273163",
      "j": 2023,
      "n": "elsendorf",
      "dn": "Elsendorf",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09273164",
      "j": 2023,
      "n": "riedenburg",
      "dn": "Riedenburg",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09273165",
      "j": 2023,
      "n": "rohr in niederbayern",
      "dn": "Rohr in Niederbayern",
      "bl": 9,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "09273166",
      "j": 2023,
      "n": "saal an der donau",
      "dn": "Saal an der Donau",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09273172",
      "j": 2023,
      "n": "siegenburg",
      "dn": "Siegenburg",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09273175",
      "j": 2023,
      "n": "teugn",
      "dn": "Teugn",
      "bl": 9,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "09273177",
      "j": 2023,
      "n": "train",
      "dn": "Train",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09273178",
      "j": 2023,
      "n": "volkenschwand",
      "dn": "Volkenschwand",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09273181",
      "j": 2023,
      "n": "wildenberg",
      "dn": "Wildenberg",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09274111",
      "j": 2023,
      "n": "adlkofen",
      "dn": "Adlkofen",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09274112",
      "j": 2023,
      "n": "aham",
      "dn": "Aham",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09274113",
      "j": 2023,
      "n": "altdorf",
      "dn": "Altdorf",
      "bl": 9,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "09274114",
      "j": 2023,
      "n": "altfraunhofen",
      "dn": "Altfraunhofen",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09274118",
      "j": 2023,
      "n": "baierbach",
      "dn": "Baierbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09274119",
      "j": 2023,
      "n": "bayerbach bei ergoldsbach",
      "dn": "Bayerbach bei Ergoldsbach",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09274120",
      "j": 2023,
      "n": "bodenkirchen",
      "dn": "Bodenkirchen",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09274121",
      "j": 2023,
      "n": "buch am erlbach",
      "dn": "Buch am Erlbach",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09274124",
      "j": 2023,
      "n": "eching",
      "dn": "Eching",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09274126",
      "j": 2023,
      "n": "ergolding",
      "dn": "Ergolding",
      "bl": 9,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "09274127",
      "j": 2023,
      "n": "ergoldsbach",
      "dn": "Ergoldsbach",
      "bl": 9,
      "hs": 315.0,
      "mns": 1
    },
    {
      "a": "09274128",
      "j": 2023,
      "n": "essenbach",
      "dn": "Essenbach",
      "bl": 9,
      "hs": 250.0,
      "mns": 2
    },
    {
      "a": "09274132",
      "j": 2023,
      "n": "furth",
      "dn": "Furth",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09274134",
      "j": 2023,
      "n": "geisenhausen",
      "dn": "Geisenhausen",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09274135",
      "j": 2023,
      "n": "gerzen",
      "dn": "Gerzen",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09274141",
      "j": 2025,
      "n": "hohenthann",
      "dn": "Hohenthann",
      "bl": 9,
      "hs": 250.0,
      "mns": 1
    },
    {
      "a": "09274145",
      "j": 2023,
      "n": "kröning",
      "dn": "Kröning",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09274146",
      "j": 2023,
      "n": "kumhausen",
      "dn": "Kumhausen",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09274153",
      "j": 2023,
      "n": "neufahrn in niederbayern",
      "dn": "Neufahrn in Niederbayern",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09274154",
      "j": 2023,
      "n": "neufraunhofen",
      "dn": "Neufraunhofen",
      "bl": 9,
      "hs": 260.0,
      "mns": 1
    },
    {
      "a": "09274156",
      "j": 2023,
      "n": "niederaichbach",
      "dn": "Niederaichbach",
      "bl": 9,
      "hs": 250.0,
      "mns": 1
    },
    {
      "a": "09274165",
      "j": 2023,
      "n": "obersüßbach",
      "dn": "Obersüßbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09274172",
      "j": 2023,
      "n": "pfeffenhausen",
      "dn": "Pfeffenhausen",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09274174",
      "j": 2023,
      "n": "postau",
      "dn": "Postau",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09274176",
      "j": 2023,
      "n": "rottenburg an der laaber",
      "dn": "Rottenburg an der Laaber",
      "bl": 9,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "09274179",
      "j": 2023,
      "n": "schalkham",
      "dn": "Schalkham",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09274182",
      "j": 2023,
      "n": "tiefenbach",
      "dn": "Tiefenbach",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09274183",
      "j": 2023,
      "n": "velden",
      "dn": "Velden",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09274184",
      "j": 2023,
      "n": "vilsbiburg",
      "dn": "Vilsbiburg",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09274185",
      "j": 2023,
      "n": "vilsheim",
      "dn": "Vilsheim",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09274187",
      "j": 2023,
      "n": "weihmichl",
      "dn": "Weihmichl",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09274188",
      "j": 2023,
      "n": "weng",
      "dn": "Weng",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09274191",
      "j": 2023,
      "n": "wörth an der isar",
      "dn": "Wörth an der Isar",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09274193",
      "j": 2023,
      "n": "wurmsham",
      "dn": "Wurmsham",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09274194",
      "j": 2023,
      "n": "bruckberg",
      "dn": "Bruckberg",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09275111",
      "j": 2023,
      "n": "aicha vorm wald",
      "dn": "Aicha vorm Wald",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09275112",
      "j": 2023,
      "n": "aidenbach",
      "dn": "Aidenbach",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09275114",
      "j": 2023,
      "n": "aldersbach",
      "dn": "Aldersbach",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09275116",
      "j": 2025,
      "n": "bad füssing",
      "dn": "Bad Füssing",
      "bl": 9,
      "hs": 295.0,
      "mns": 1
    },
    {
      "a": "09275117",
      "j": 2023,
      "n": "beutelsbach",
      "dn": "Beutelsbach",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09275118",
      "j": 2023,
      "n": "breitenberg",
      "dn": "Breitenberg",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09275119",
      "j": 2023,
      "n": "büchlberg",
      "dn": "Büchlberg",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09275120",
      "j": 2023,
      "n": "eging am see",
      "dn": "Eging am See",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09275121",
      "j": 2023,
      "n": "fürstenstein",
      "dn": "Fürstenstein",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09275122",
      "j": 2023,
      "n": "fürstenzell",
      "dn": "Fürstenzell",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09275124",
      "j": 2023,
      "n": "bad griesbach im rottal",
      "dn": "Bad Griesbach im Rottal",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09275125",
      "j": 2023,
      "n": "haarbach",
      "dn": "Haarbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09275126",
      "j": 2023,
      "n": "hauzenberg",
      "dn": "Hauzenberg",
      "bl": 9,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "09275127",
      "j": 2023,
      "n": "hofkirchen",
      "dn": "Hofkirchen",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09275128",
      "j": 2023,
      "n": "hutthurm",
      "dn": "Hutthurm",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09275130",
      "j": 2023,
      "n": "kirchham",
      "dn": "Kirchham",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09275131",
      "j": 2023,
      "n": "kößlarn",
      "dn": "Kößlarn",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09275132",
      "j": 2023,
      "n": "malching",
      "dn": "Malching",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09275133",
      "j": 2023,
      "n": "neuburg am inn",
      "dn": "Neuburg am Inn",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09275134",
      "j": 2023,
      "n": "neuhaus am inn",
      "dn": "Neuhaus am Inn",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09275135",
      "j": 2023,
      "n": "neukirchen vorm wald",
      "dn": "Neukirchen vorm Wald",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09275137",
      "j": 2023,
      "n": "obernzell",
      "dn": "Obernzell",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09275138",
      "j": 2023,
      "n": "ortenburg",
      "dn": "Ortenburg",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09275141",
      "j": 2023,
      "n": "pocking",
      "dn": "Pocking",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09275143",
      "j": 2023,
      "n": "rotthalmünster",
      "dn": "Rotthalmünster",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09275144",
      "j": 2023,
      "n": "ruderting",
      "dn": "Ruderting",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09275145",
      "j": 2023,
      "n": "ruhstorf an der rott",
      "dn": "Ruhstorf an der Rott",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09275146",
      "j": 2023,
      "n": "salzweg",
      "dn": "Salzweg",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09275148",
      "j": 2023,
      "n": "sonnen",
      "dn": "Sonnen",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09275149",
      "j": 2023,
      "n": "tettenweis",
      "dn": "Tettenweis",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09275150",
      "j": 2023,
      "n": "thyrnau",
      "dn": "Thyrnau",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09275151",
      "j": 2023,
      "n": "tiefenbach",
      "dn": "Tiefenbach",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09275152",
      "j": 2023,
      "n": "tittling",
      "dn": "Tittling",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09275153",
      "j": 2023,
      "n": "untergriesbach",
      "dn": "Untergriesbach",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09275154",
      "j": 2023,
      "n": "vilshofen an der donau",
      "dn": "Vilshofen an der Donau",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09275156",
      "j": 2023,
      "n": "wegscheid",
      "dn": "Wegscheid",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09275159",
      "j": 2023,
      "n": "windorf",
      "dn": "Windorf",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09275160",
      "j": 2023,
      "n": "witzmannsberg",
      "dn": "Witzmannsberg",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09276111",
      "j": 2023,
      "n": "achslach",
      "dn": "Achslach",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09276113",
      "j": 2023,
      "n": "arnbruck",
      "dn": "Arnbruck",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09276115",
      "j": 2023,
      "n": "bayerisch eisenstein",
      "dn": "Bayerisch Eisenstein",
      "bl": 9,
      "hs": 570.0,
      "mns": 1
    },
    {
      "a": "09276116",
      "j": 2023,
      "n": "bischofsmais",
      "dn": "Bischofsmais",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09276117",
      "j": 2023,
      "n": "bodenmais",
      "dn": "Bodenmais",
      "bl": 9,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "09276118",
      "j": 2023,
      "n": "böbrach",
      "dn": "Böbrach",
      "bl": 9,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "09276120",
      "j": 2023,
      "n": "drachselsried",
      "dn": "Drachselsried",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09276121",
      "j": 2023,
      "n": "frauenau",
      "dn": "Frauenau",
      "bl": 9,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "09276122",
      "j": 2023,
      "n": "geiersthal",
      "dn": "Geiersthal",
      "bl": 9,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "09276123",
      "j": 2023,
      "n": "gotteszell",
      "dn": "Gotteszell",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09276126",
      "j": 2023,
      "n": "kirchberg im wald",
      "dn": "Kirchberg im Wald",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09276127",
      "j": 2023,
      "n": "kirchdorf im wald",
      "dn": "Kirchdorf im Wald",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09276128",
      "j": 2023,
      "n": "kollnburg",
      "dn": "Kollnburg",
      "bl": 9,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "09276129",
      "j": 2023,
      "n": "langdorf",
      "dn": "Langdorf",
      "bl": 9,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "09276130",
      "j": 2023,
      "n": "lindberg",
      "dn": "Lindberg",
      "bl": 9,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "09276134",
      "j": 2023,
      "n": "patersdorf",
      "dn": "Patersdorf",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09276135",
      "j": 2023,
      "n": "prackenbach",
      "dn": "Prackenbach",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09276138",
      "j": 2023,
      "n": "regen",
      "dn": "Regen",
      "bl": 9,
      "hs": 570.0,
      "mns": 1
    },
    {
      "a": "09276139",
      "j": 2023,
      "n": "rinchnach",
      "dn": "Rinchnach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09276142",
      "j": 2023,
      "n": "ruhmannsfelden",
      "dn": "Ruhmannsfelden",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09276143",
      "j": 2023,
      "n": "teisnach",
      "dn": "Teisnach",
      "bl": 9,
      "hs": 290.0,
      "mns": 1
    },
    {
      "a": "09276144",
      "j": 2023,
      "n": "viechtach",
      "dn": "Viechtach",
      "bl": 9,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "09276146",
      "j": 2023,
      "n": "zachenberg",
      "dn": "Zachenberg",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09276148",
      "j": 2023,
      "n": "zwiesel",
      "dn": "Zwiesel",
      "bl": 9,
      "hs": 570.0,
      "mns": 1
    },
    {
      "a": "09277111",
      "j": 2023,
      "n": "markt arnstorf",
      "dn": "Markt Arnstorf",
      "bl": 9,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "09277112",
      "j": 2023,
      "n": "bayerbach",
      "dn": "Bayerbach",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09277113",
      "j": 2023,
      "n": "bad birnbach",
      "dn": "Bad Birnbach",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09277114",
      "j": 2023,
      "n": "dietersburg",
      "dn": "Dietersburg",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09277116",
      "j": 2023,
      "n": "eggenfelden",
      "dn": "Eggenfelden",
      "bl": 9,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "09277117",
      "j": 2023,
      "n": "egglham",
      "dn": "Egglham",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09277118",
      "j": 2023,
      "n": "ering",
      "dn": "Ering",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09277119",
      "j": 2023,
      "n": "falkenberg",
      "dn": "Falkenberg",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09277121",
      "j": 2023,
      "n": "gangkofen",
      "dn": "Gangkofen",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09277122",
      "j": 2023,
      "n": "geratskirchen",
      "dn": "Geratskirchen",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09277124",
      "j": 2023,
      "n": "hebertsfelden",
      "dn": "Hebertsfelden",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09277126",
      "j": 2023,
      "n": "johanniskirchen",
      "dn": "Johanniskirchen",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09277127",
      "j": 2023,
      "n": "julbach",
      "dn": "Julbach",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09277128",
      "j": 2025,
      "n": "kirchdorf am inn",
      "dn": "Kirchdorf am Inn",
      "bl": 9,
      "hs": 220.0,
      "mns": 1
    },
    {
      "a": "09277131",
      "j": 2023,
      "n": "malgersdorf",
      "dn": "Malgersdorf",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09277133",
      "j": 2023,
      "n": "massing",
      "dn": "Massing",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09277134",
      "j": 2023,
      "n": "mitterskirchen",
      "dn": "Mitterskirchen",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09277138",
      "j": 2023,
      "n": "pfarrkirchen",
      "dn": "Pfarrkirchen",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09277139",
      "j": 2023,
      "n": "postmünster",
      "dn": "Postmünster",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09277140",
      "j": 2023,
      "n": "reut",
      "dn": "Reut",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09277141",
      "j": 2023,
      "n": "rimbach",
      "dn": "Rimbach",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09277142",
      "j": 2023,
      "n": "roßbach",
      "dn": "Roßbach",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09277144",
      "j": 2023,
      "n": "schönau",
      "dn": "Schönau",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09277145",
      "j": 2023,
      "n": "simbach am inn",
      "dn": "Simbach am Inn",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09277147",
      "j": 2023,
      "n": "stubenberg",
      "dn": "Stubenberg",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09277148",
      "j": 2023,
      "n": "tann",
      "dn": "Tann",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09277149",
      "j": 2023,
      "n": "triftern",
      "dn": "Triftern",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09277151",
      "j": 2023,
      "n": "unterdietfurt",
      "dn": "Unterdietfurt",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09277152",
      "j": 2023,
      "n": "wittibreut",
      "dn": "Wittibreut",
      "bl": 9,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "09277153",
      "j": 2023,
      "n": "wurmannsquick",
      "dn": "Wurmannsquick",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09277154",
      "j": 2023,
      "n": "zeilarn",
      "dn": "Zeilarn",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09278112",
      "j": 2023,
      "n": "aholfing",
      "dn": "Aholfing",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09278113",
      "j": 2023,
      "n": "aiterhofen",
      "dn": "Aiterhofen",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09278116",
      "j": 2023,
      "n": "ascha",
      "dn": "Ascha",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09278117",
      "j": 2023,
      "n": "atting",
      "dn": "Atting",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09278118",
      "j": 2023,
      "n": "bogen",
      "dn": "Bogen",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09278120",
      "j": 2023,
      "n": "falkenfels",
      "dn": "Falkenfels",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09278121",
      "j": 2023,
      "n": "feldkirchen",
      "dn": "Feldkirchen",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09278123",
      "j": 2023,
      "n": "geiselhöring",
      "dn": "Geiselhöring",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09278129",
      "j": 2023,
      "n": "haibach",
      "dn": "Haibach",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09278134",
      "j": 2023,
      "n": "haselbach",
      "dn": "Haselbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09278139",
      "j": 2023,
      "n": "hunderdorf",
      "dn": "Hunderdorf",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09278140",
      "j": 2023,
      "n": "irlbach",
      "dn": "Irlbach",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09278141",
      "j": 2023,
      "n": "kirchroth",
      "dn": "Kirchroth",
      "bl": 9,
      "hs": 250.0,
      "mns": 1
    },
    {
      "a": "09278143",
      "j": 2023,
      "n": "konzell",
      "dn": "Konzell",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09278144",
      "j": 2023,
      "n": "laberweinting",
      "dn": "Laberweinting",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09278146",
      "j": 2023,
      "n": "leiblfing",
      "dn": "Leiblfing",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09278147",
      "j": 2023,
      "n": "loitzendorf",
      "dn": "Loitzendorf",
      "bl": 9,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "09278148",
      "j": 2023,
      "n": "mallersdorf-pfaffenberg",
      "dn": "Mallersdorf-Pfaffenberg",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09278149",
      "j": 2023,
      "n": "mariaposching",
      "dn": "Mariaposching",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09278151",
      "j": 2023,
      "n": "mitterfels",
      "dn": "Mitterfels",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09278154",
      "j": 2023,
      "n": "neukirchen",
      "dn": "Neukirchen",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09278159",
      "j": 2023,
      "n": "niederwinkling",
      "dn": "Niederwinkling",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09278167",
      "j": 2023,
      "n": "oberschneiding",
      "dn": "Oberschneiding",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09278170",
      "j": 2023,
      "n": "parkstetten",
      "dn": "Parkstetten",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09278171",
      "j": 2023,
      "n": "perasdorf",
      "dn": "Perasdorf",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09278172",
      "j": 2023,
      "n": "perkam",
      "dn": "Perkam",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09278177",
      "j": 2023,
      "n": "rain",
      "dn": "Rain",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09278178",
      "j": 2023,
      "n": "rattenberg",
      "dn": "Rattenberg",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09278179",
      "j": 2023,
      "n": "rattiszell",
      "dn": "Rattiszell",
      "bl": 9,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "09278182",
      "j": 2023,
      "n": "salching",
      "dn": "Salching",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09278184",
      "j": 2023,
      "n": "sankt englmar",
      "dn": "Sankt Englmar",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09278187",
      "j": 2023,
      "n": "schwarzach",
      "dn": "Schwarzach",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09278189",
      "j": 2023,
      "n": "stallwang",
      "dn": "Stallwang",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09278190",
      "j": 2023,
      "n": "steinach",
      "dn": "Steinach",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09278192",
      "j": 2023,
      "n": "straßkirchen",
      "dn": "Straßkirchen",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09278197",
      "j": 2023,
      "n": "wiesenfelden",
      "dn": "Wiesenfelden",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09278198",
      "j": 2023,
      "n": "windberg",
      "dn": "Windberg",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09279112",
      "j": 2023,
      "n": "dingolfing",
      "dn": "Dingolfing",
      "bl": 9,
      "hs": 250.0,
      "mns": 2
    },
    {
      "a": "09279113",
      "j": 2023,
      "n": "eichendorf",
      "dn": "Eichendorf",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09279115",
      "j": 2023,
      "n": "frontenhausen",
      "dn": "Frontenhausen",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09279116",
      "j": 2023,
      "n": "gottfrieding",
      "dn": "Gottfrieding",
      "bl": 9,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "09279122",
      "j": 2023,
      "n": "landau an der isar",
      "dn": "Landau an der Isar",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09279124",
      "j": 2025,
      "n": "loiching",
      "dn": "Loiching",
      "bl": 9,
      "hs": 260.0,
      "mns": 1
    },
    {
      "a": "09279125",
      "j": 2023,
      "n": "mamming",
      "dn": "Mamming",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09279126",
      "j": 2023,
      "n": "marklkofen",
      "dn": "Marklkofen",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09279127",
      "j": 2023,
      "n": "mengkofen",
      "dn": "Mengkofen",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09279128",
      "j": 2023,
      "n": "moosthenning",
      "dn": "Moosthenning",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09279130",
      "j": 2023,
      "n": "niederviehbach",
      "dn": "Niederviehbach",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09279132",
      "j": 2023,
      "n": "pilsting",
      "dn": "Pilsting",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09279134",
      "j": 2023,
      "n": "reisbach",
      "dn": "Reisbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09279135",
      "j": 2023,
      "n": "simbach",
      "dn": "Simbach",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09279137",
      "j": 2023,
      "n": "wallersdorf",
      "dn": "Wallersdorf",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09361000",
      "j": 2023,
      "n": "amberg",
      "dn": "Amberg",
      "bl": 9,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "09362000",
      "j": 2023,
      "n": "regensburg",
      "dn": "Regensburg",
      "bl": 9,
      "hs": 395.0,
      "mns": 5
    },
    {
      "a": "09363000",
      "j": 2023,
      "n": "weiden in der oberpfalz",
      "dn": "Weiden in der Oberpfalz",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09371111",
      "j": 2023,
      "n": "ammerthal",
      "dn": "Ammerthal",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09371113",
      "j": 2023,
      "n": "auerbach in der oberpfalz",
      "dn": "Auerbach in der Oberpfalz",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09371116",
      "j": 2023,
      "n": "birgland",
      "dn": "Birgland",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09371118",
      "j": 2023,
      "n": "ebermannsdorf",
      "dn": "Ebermannsdorf",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09371119",
      "j": 2023,
      "n": "edelsfeld",
      "dn": "Edelsfeld",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09371120",
      "j": 2023,
      "n": "ensdorf",
      "dn": "Ensdorf",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09371121",
      "j": 2023,
      "n": "freihung",
      "dn": "Freihung",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09371122",
      "j": 2025,
      "n": "freudenberg",
      "dn": "Freudenberg",
      "bl": 9,
      "hs": 200.0,
      "mns": 1
    },
    {
      "a": "09371123",
      "j": 2023,
      "n": "gebenbach",
      "dn": "Gebenbach",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09371126",
      "j": 2023,
      "n": "hahnbach",
      "dn": "Hahnbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09371127",
      "j": 2023,
      "n": "hirschau",
      "dn": "Hirschau",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09371128",
      "j": 2023,
      "n": "hirschbach",
      "dn": "Hirschbach",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09371129",
      "j": 2023,
      "n": "hohenburg",
      "dn": "Hohenburg",
      "bl": 9,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "09371131",
      "j": 2023,
      "n": "illschwang",
      "dn": "Illschwang",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09371132",
      "j": 2023,
      "n": "kastl",
      "dn": "Kastl",
      "bl": 9,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "09371135",
      "j": 2023,
      "n": "königstein",
      "dn": "Königstein",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09371136",
      "j": 2023,
      "n": "kümmersbruck",
      "dn": "Kümmersbruck",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09371140",
      "j": 2023,
      "n": "etzelwang",
      "dn": "Etzelwang",
      "bl": 9,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "09371141",
      "j": 2023,
      "n": "neukirchen bei sulzbach-rosenberg",
      "dn": "Neukirchen bei Sulzbach-Rosenberg",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09371144",
      "j": 2023,
      "n": "poppenricht",
      "dn": "Poppenricht",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09371146",
      "j": 2023,
      "n": "rieden",
      "dn": "Rieden",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09371148",
      "j": 2023,
      "n": "schmidmühlen",
      "dn": "Schmidmühlen",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09371150",
      "j": 2023,
      "n": "schnaittenbach",
      "dn": "Schnaittenbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09371151",
      "j": 2023,
      "n": "sulzbach-rosenberg",
      "dn": "Sulzbach-Rosenberg",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09371154",
      "j": 2023,
      "n": "ursensollen",
      "dn": "Ursensollen",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09371156",
      "j": 2023,
      "n": "vilseck",
      "dn": "Vilseck",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09371157",
      "j": 2023,
      "n": "weigendorf",
      "dn": "Weigendorf",
      "bl": 9,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "09372112",
      "j": 2023,
      "n": "arnschwang",
      "dn": "Arnschwang",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09372113",
      "j": 2023,
      "n": "arrach",
      "dn": "Arrach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09372115",
      "j": 2023,
      "n": "blaibach",
      "dn": "Blaibach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09372116",
      "j": 2023,
      "n": "cham",
      "dn": "Cham",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09372117",
      "j": 2023,
      "n": "chamerau",
      "dn": "Chamerau",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09372124",
      "j": 2025,
      "n": "eschlkam",
      "dn": "Eschlkam",
      "bl": 9,
      "hs": 230.0,
      "mns": 1
    },
    {
      "a": "09372125",
      "j": 2023,
      "n": "falkenstein",
      "dn": "Falkenstein",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09372126",
      "j": 2023,
      "n": "furth im wald",
      "dn": "Furth im Wald",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09372128",
      "j": 2023,
      "n": "gleißenberg",
      "dn": "Gleißenberg",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09372130",
      "j": 2023,
      "n": "grafenwiesen",
      "dn": "Grafenwiesen",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09372135",
      "j": 2023,
      "n": "hohenwarth",
      "dn": "Hohenwarth",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09372137",
      "j": 2023,
      "n": "bad kötzting",
      "dn": "Bad Kötzting",
      "bl": 9,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "09372138",
      "j": 2023,
      "n": "lam",
      "dn": "Lam",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09372142",
      "j": 2023,
      "n": "michelsneukirchen",
      "dn": "Michelsneukirchen",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09372143",
      "j": 2023,
      "n": "miltach",
      "dn": "Miltach",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09372144",
      "j": 2023,
      "n": "neukirchen beim heiligen blut",
      "dn": "Neukirchen beim Heiligen Blut",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09372146",
      "j": 2023,
      "n": "pemfling",
      "dn": "Pemfling",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09372147",
      "j": 2023,
      "n": "pösing",
      "dn": "Pösing",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09372149",
      "j": 2023,
      "n": "reichenbach",
      "dn": "Reichenbach",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09372150",
      "j": 2023,
      "n": "rettenbach",
      "dn": "Rettenbach",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09372151",
      "j": 2023,
      "n": "rimbach",
      "dn": "Rimbach",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09372153",
      "j": 2023,
      "n": "roding",
      "dn": "Roding",
      "bl": 9,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "09372154",
      "j": 2023,
      "n": "rötz",
      "dn": "Rötz",
      "bl": 9,
      "hs": 345.0,
      "mns": 1
    },
    {
      "a": "09372155",
      "j": 2023,
      "n": "runding",
      "dn": "Runding",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09372157",
      "j": 2023,
      "n": "schönthal",
      "dn": "Schönthal",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09372158",
      "j": 2023,
      "n": "schorndorf",
      "dn": "Schorndorf",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09372161",
      "j": 2023,
      "n": "stamsried",
      "dn": "Stamsried",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09372163",
      "j": 2023,
      "n": "tiefenbach",
      "dn": "Tiefenbach",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09372164",
      "j": 2023,
      "n": "traitsching",
      "dn": "Traitsching",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09372165",
      "j": 2023,
      "n": "treffelstein",
      "dn": "Treffelstein",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09372167",
      "j": 2023,
      "n": "zell",
      "dn": "Zell",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09372168",
      "j": 2023,
      "n": "waffenbrunn",
      "dn": "Waffenbrunn",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09372169",
      "j": 2023,
      "n": "wald",
      "dn": "Wald",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09372170",
      "j": 2023,
      "n": "walderbach",
      "dn": "Walderbach",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09372171",
      "j": 2023,
      "n": "waldmünchen",
      "dn": "Waldmünchen",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09372174",
      "j": 2023,
      "n": "weiding",
      "dn": "Weiding",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09372175",
      "j": 2023,
      "n": "willmering",
      "dn": "Willmering",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09372177",
      "j": 2023,
      "n": "zandt",
      "dn": "Zandt",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09372178",
      "j": 2023,
      "n": "lohberg",
      "dn": "Lohberg",
      "bl": 9,
      "hs": 270.0,
      "mns": 1
    },
    {
      "a": "09373112",
      "j": 2023,
      "n": "berching",
      "dn": "Berching",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09373113",
      "j": 2025,
      "n": "berg bei neumarkt in der oberpfalz",
      "dn": "Berg bei Neumarkt in der Oberpfalz",
      "bl": 9,
      "hs": 165.0,
      "mns": 1
    },
    {
      "a": "09373114",
      "j": 2023,
      "n": "berngau",
      "dn": "Berngau",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09373115",
      "j": 2023,
      "n": "breitenbrunn",
      "dn": "Breitenbrunn",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09373119",
      "j": 2023,
      "n": "deining",
      "dn": "Deining",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09373121",
      "j": 2023,
      "n": "dietfurt an der altmühl",
      "dn": "Dietfurt an der Altmühl",
      "bl": 9,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "09373126",
      "j": 2025,
      "n": "freystadt",
      "dn": "Freystadt",
      "bl": 9,
      "hs": 190.0,
      "mns": 1
    },
    {
      "a": "09373134",
      "j": 2023,
      "n": "hohenfels",
      "dn": "Hohenfels",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09373140",
      "j": 2023,
      "n": "lauterhofen",
      "dn": "Lauterhofen",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09373143",
      "j": 2023,
      "n": "lupburg",
      "dn": "Lupburg",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09373146",
      "j": 2023,
      "n": "mühlhausen",
      "dn": "Mühlhausen",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09373147",
      "j": 2023,
      "n": "neumarkt in der oberpfalz",
      "dn": "Neumarkt in der Oberpfalz",
      "bl": 9,
      "hs": 275.0,
      "mns": 3
    },
    {
      "a": "09373151",
      "j": 2023,
      "n": "parsberg",
      "dn": "Parsberg",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09373153",
      "j": 2023,
      "n": "pilsach",
      "dn": "Pilsach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09373155",
      "j": 2023,
      "n": "postbauer-heng",
      "dn": "Postbauer-Heng",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09373156",
      "j": 2023,
      "n": "pyrbaum",
      "dn": "Pyrbaum",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09373159",
      "j": 2023,
      "n": "sengenthal",
      "dn": "Sengenthal",
      "bl": 9,
      "hs": 270.0,
      "mns": 1
    },
    {
      "a": "09373160",
      "j": 2023,
      "n": "seubersdorf in der oberpfalz",
      "dn": "Seubersdorf in der Oberpfalz",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09373167",
      "j": 2023,
      "n": "velburg",
      "dn": "Velburg",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09374111",
      "j": 2023,
      "n": "altenstadt an der waldnaab",
      "dn": "Altenstadt an der Waldnaab",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09374117",
      "j": 2023,
      "n": "eschenbach in der oberpfalz",
      "dn": "Eschenbach in der Oberpfalz",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09374118",
      "j": 2023,
      "n": "eslarn",
      "dn": "Eslarn",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09374119",
      "j": 2023,
      "n": "etzenricht",
      "dn": "Etzenricht",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09374121",
      "j": 2023,
      "n": "floß",
      "dn": "Floß",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09374122",
      "j": 2023,
      "n": "flossenbürg",
      "dn": "Flossenbürg",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09374123",
      "j": 2023,
      "n": "georgenberg",
      "dn": "Georgenberg",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09374124",
      "j": 2023,
      "n": "grafenwöhr",
      "dn": "Grafenwöhr",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09374127",
      "j": 2023,
      "n": "irchenrieth",
      "dn": "Irchenrieth",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09374128",
      "j": 2023,
      "n": "kirchendemenreuth",
      "dn": "Kirchendemenreuth",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09374129",
      "j": 2023,
      "n": "kirchenthumbach",
      "dn": "Kirchenthumbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09374131",
      "j": 2023,
      "n": "kohlberg",
      "dn": "Kohlberg",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09374132",
      "j": 2023,
      "n": "leuchtenberg",
      "dn": "Leuchtenberg",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09374133",
      "j": 2023,
      "n": "luhe-wildenau",
      "dn": "Luhe-Wildenau",
      "bl": 9,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "09374134",
      "j": 2023,
      "n": "mantel",
      "dn": "Mantel",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09374137",
      "j": 2023,
      "n": "moosbach",
      "dn": "Moosbach",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09374139",
      "j": 2023,
      "n": "neustadt an der waldnaab",
      "dn": "Neustadt an der Waldnaab",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09374140",
      "j": 2023,
      "n": "neustadt am kulm",
      "dn": "Neustadt am Kulm",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09374144",
      "j": 2023,
      "n": "parkstein",
      "dn": "Parkstein",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09374146",
      "j": 2023,
      "n": "pirk",
      "dn": "Pirk",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09374147",
      "j": 2023,
      "n": "pleystein",
      "dn": "Pleystein",
      "bl": 9,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "09374148",
      "j": 2023,
      "n": "trabitz",
      "dn": "Trabitz",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09374149",
      "j": 2023,
      "n": "pressath",
      "dn": "Pressath",
      "bl": 9,
      "hs": 290.0,
      "mns": 1
    },
    {
      "a": "09374150",
      "j": 2023,
      "n": "püchersreuth",
      "dn": "Püchersreuth",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09374154",
      "j": 2023,
      "n": "schirmitz",
      "dn": "Schirmitz",
      "bl": 9,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "09374155",
      "j": 2023,
      "n": "schlammersdorf",
      "dn": "Schlammersdorf",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09374156",
      "j": 2023,
      "n": "schwarzenbach",
      "dn": "Schwarzenbach",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09374157",
      "j": 2023,
      "n": "speinshart",
      "dn": "Speinshart",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09374158",
      "j": 2023,
      "n": "störnstein",
      "dn": "Störnstein",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09374159",
      "j": 2023,
      "n": "tännesberg",
      "dn": "Tännesberg",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09374160",
      "j": 2023,
      "n": "theisseil",
      "dn": "Theisseil",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09374162",
      "j": 2023,
      "n": "vohenstrauß",
      "dn": "Vohenstrauß",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09374163",
      "j": 2023,
      "n": "vorbach",
      "dn": "Vorbach",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09374164",
      "j": 2023,
      "n": "waidhaus",
      "dn": "Waidhaus",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09374165",
      "j": 2023,
      "n": "waldthurn",
      "dn": "Waldthurn",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09374166",
      "j": 2023,
      "n": "weiherhammer",
      "dn": "Weiherhammer",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09374168",
      "j": 2023,
      "n": "windischeschenbach",
      "dn": "Windischeschenbach",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09374170",
      "j": 2023,
      "n": "bechtsrieth",
      "dn": "Bechtsrieth",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09375113",
      "j": 2023,
      "n": "alteglofsheim",
      "dn": "Alteglofsheim",
      "bl": 9,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "09375114",
      "j": 2023,
      "n": "altenthann",
      "dn": "Altenthann",
      "bl": 9,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "09375115",
      "j": 2023,
      "n": "aufhausen",
      "dn": "Aufhausen",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09375116",
      "j": 2023,
      "n": "bach an der donau",
      "dn": "Bach an der Donau",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09375117",
      "j": 2023,
      "n": "barbing",
      "dn": "Barbing",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09375118",
      "j": 2023,
      "n": "beratzhausen",
      "dn": "Beratzhausen",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09375119",
      "j": 2023,
      "n": "bernhardswald",
      "dn": "Bernhardswald",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09375120",
      "j": 2023,
      "n": "brennberg",
      "dn": "Brennberg",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09375122",
      "j": 2023,
      "n": "brunn",
      "dn": "Brunn",
      "bl": 9,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "09375127",
      "j": 2023,
      "n": "deuerling",
      "dn": "Deuerling",
      "bl": 9,
      "hs": 280.0,
      "mns": 2
    },
    {
      "a": "09375130",
      "j": 2023,
      "n": "donaustauf",
      "dn": "Donaustauf",
      "bl": 9,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "09375131",
      "j": 2023,
      "n": "duggendorf",
      "dn": "Duggendorf",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09375143",
      "j": 2023,
      "n": "hagelstadt",
      "dn": "Hagelstadt",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09375148",
      "j": 2023,
      "n": "hemau",
      "dn": "Hemau",
      "bl": 9,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "09375153",
      "j": 2023,
      "n": "holzheim am forst",
      "dn": "Holzheim am Forst",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09375156",
      "j": 2023,
      "n": "kallmünz",
      "dn": "Kallmünz",
      "bl": 9,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "09375161",
      "j": 2023,
      "n": "köfering",
      "dn": "Köfering",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09375162",
      "j": 2023,
      "n": "laaber",
      "dn": "Laaber",
      "bl": 9,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "09375165",
      "j": 2023,
      "n": "lappersdorf",
      "dn": "Lappersdorf",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09375170",
      "j": 2023,
      "n": "mintraching",
      "dn": "Mintraching",
      "bl": 9,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "09375171",
      "j": 2023,
      "n": "mötzing",
      "dn": "Mötzing",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09375174",
      "j": 2023,
      "n": "neutraubling",
      "dn": "Neutraubling",
      "bl": 9,
      "hs": 310.0,
      "mns": 4
    },
    {
      "a": "09375175",
      "j": 2023,
      "n": "nittendorf",
      "dn": "Nittendorf",
      "bl": 9,
      "hs": 250.0,
      "mns": 2
    },
    {
      "a": "09375179",
      "j": 2023,
      "n": "obertraubling",
      "dn": "Obertraubling",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09375180",
      "j": 2023,
      "n": "pentling",
      "dn": "Pentling",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09375181",
      "j": 2023,
      "n": "pettendorf",
      "dn": "Pettendorf",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09375182",
      "j": 2023,
      "n": "pfakofen",
      "dn": "Pfakofen",
      "bl": 9,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "09375183",
      "j": 2023,
      "n": "pfatter",
      "dn": "Pfatter",
      "bl": 9,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "09375184",
      "j": 2023,
      "n": "pielenhofen",
      "dn": "Pielenhofen",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09375190",
      "j": 2023,
      "n": "regenstauf",
      "dn": "Regenstauf",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09375191",
      "j": 2023,
      "n": "riekofen",
      "dn": "Riekofen",
      "bl": 9,
      "hs": 280.0,
      "mns": 2
    },
    {
      "a": "09375196",
      "j": 2023,
      "n": "schierling",
      "dn": "Schierling",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09375199",
      "j": 2023,
      "n": "sinzing",
      "dn": "Sinzing",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09375201",
      "j": 2023,
      "n": "sünching",
      "dn": "Sünching",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09375204",
      "j": 2023,
      "n": "tegernheim",
      "dn": "Tegernheim",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09375205",
      "j": 2023,
      "n": "thalmassing",
      "dn": "Thalmassing",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09375208",
      "j": 2023,
      "n": "wenzenbach",
      "dn": "Wenzenbach",
      "bl": 9,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "09375209",
      "j": 2023,
      "n": "wiesent",
      "dn": "Wiesent",
      "bl": 9,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "09375210",
      "j": 2023,
      "n": "wörth an der donau",
      "dn": "Wörth an der Donau",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09375211",
      "j": 2023,
      "n": "wolfsegg",
      "dn": "Wolfsegg",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09375213",
      "j": 2023,
      "n": "zeitlarn",
      "dn": "Zeitlarn",
      "bl": 9,
      "hs": 280.0,
      "mns": 2
    },
    {
      "a": "09376112",
      "j": 2023,
      "n": "altendorf",
      "dn": "Altendorf",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09376116",
      "j": 2023,
      "n": "bodenwöhr",
      "dn": "Bodenwöhr",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09376117",
      "j": 2023,
      "n": "bruck in der oberpfalz",
      "dn": "Bruck in der Oberpfalz",
      "bl": 9,
      "hs": 285.0,
      "mns": 1
    },
    {
      "a": "09376119",
      "j": 2023,
      "n": "burglengenfeld",
      "dn": "Burglengenfeld",
      "bl": 9,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "09376122",
      "j": 2023,
      "n": "dieterskirchen",
      "dn": "Dieterskirchen",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09376125",
      "j": 2023,
      "n": "fensterbach",
      "dn": "Fensterbach",
      "bl": 9,
      "hs": 352.0,
      "mns": 1
    },
    {
      "a": "09376131",
      "j": 2023,
      "n": "gleiritsch",
      "dn": "Gleiritsch",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09376133",
      "j": 2023,
      "n": "guteneck",
      "dn": "Guteneck",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09376141",
      "j": 2023,
      "n": "maxhütte-haidhof",
      "dn": "Maxhütte-Haidhof",
      "bl": 9,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "09376144",
      "j": 2023,
      "n": "nabburg",
      "dn": "Nabburg",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09376146",
      "j": 2023,
      "n": "neukirchen-balbini",
      "dn": "Neukirchen-Balbini",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09376147",
      "j": 2023,
      "n": "neunburg vorm wald",
      "dn": "Neunburg vorm Wald",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09376148",
      "j": 2023,
      "n": "niedermurach",
      "dn": "Niedermurach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09376149",
      "j": 2023,
      "n": "nittenau",
      "dn": "Nittenau",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09376150",
      "j": 2023,
      "n": "wernberg-köblitz",
      "dn": "Wernberg-Köblitz",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09376151",
      "j": 2023,
      "n": "oberviechtach",
      "dn": "Oberviechtach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09376153",
      "j": 2023,
      "n": "pfreimd",
      "dn": "Pfreimd",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09376159",
      "j": 2023,
      "n": "schmidgaden",
      "dn": "Schmidgaden",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09376160",
      "j": 2023,
      "n": "schönsee",
      "dn": "Schönsee",
      "bl": 9,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "09376161",
      "j": 2023,
      "n": "schwandorf",
      "dn": "Schwandorf",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09376162",
      "j": 2023,
      "n": "schwarzach bei nabburg",
      "dn": "Schwarzach bei Nabburg",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09376163",
      "j": 2023,
      "n": "schwarzenfeld",
      "dn": "Schwarzenfeld",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09376164",
      "j": 2023,
      "n": "schwarzhofen",
      "dn": "Schwarzhofen",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09376167",
      "j": 2023,
      "n": "stadlern",
      "dn": "Stadlern",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09376168",
      "j": 2023,
      "n": "steinberg am see",
      "dn": "Steinberg am See",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09376169",
      "j": 2023,
      "n": "stulln",
      "dn": "Stulln",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09376170",
      "j": 2023,
      "n": "teublitz",
      "dn": "Teublitz",
      "bl": 9,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "09376171",
      "j": 2023,
      "n": "teunz",
      "dn": "Teunz",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09376172",
      "j": 2023,
      "n": "thanstein",
      "dn": "Thanstein",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09376173",
      "j": 2023,
      "n": "trausnitz",
      "dn": "Trausnitz",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09376175",
      "j": 2025,
      "n": "wackersdorf",
      "dn": "Wackersdorf",
      "bl": 9,
      "hs": 225.0,
      "mns": 1
    },
    {
      "a": "09376176",
      "j": 2023,
      "n": "weiding",
      "dn": "Weiding",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09376178",
      "j": 2023,
      "n": "winklarn",
      "dn": "Winklarn",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09377112",
      "j": 2023,
      "n": "bärnau",
      "dn": "Bärnau",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09377113",
      "j": 2023,
      "n": "brand",
      "dn": "Brand",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09377115",
      "j": 2023,
      "n": "ebnath",
      "dn": "Ebnath",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09377116",
      "j": 2023,
      "n": "erbendorf",
      "dn": "Erbendorf",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09377117",
      "j": 2023,
      "n": "falkenberg",
      "dn": "Falkenberg",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09377118",
      "j": 2023,
      "n": "friedenfels",
      "dn": "Friedenfels",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09377119",
      "j": 2023,
      "n": "fuchsmühl",
      "dn": "Fuchsmühl",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09377127",
      "j": 2023,
      "n": "immenreuth",
      "dn": "Immenreuth",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09377128",
      "j": 2023,
      "n": "kastl",
      "dn": "Kastl",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09377129",
      "j": 2023,
      "n": "kemnath",
      "dn": "Kemnath",
      "bl": 9,
      "hs": 230.0,
      "mns": 1
    },
    {
      "a": "09377131",
      "j": 2023,
      "n": "konnersreuth",
      "dn": "Konnersreuth",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09377132",
      "j": 2023,
      "n": "krummennaab",
      "dn": "Krummennaab",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09377133",
      "j": 2023,
      "n": "kulmain",
      "dn": "Kulmain",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09377137",
      "j": 2023,
      "n": "leonberg",
      "dn": "Leonberg",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09377139",
      "j": 2023,
      "n": "mähring",
      "dn": "Mähring",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09377141",
      "j": 2023,
      "n": "mitterteich",
      "dn": "Mitterteich",
      "bl": 9,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "09377142",
      "j": 2023,
      "n": "bad neualbenreuth",
      "dn": "Bad Neualbenreuth",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09377143",
      "j": 2023,
      "n": "neusorg",
      "dn": "Neusorg",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09377145",
      "j": 2023,
      "n": "pechbrunn",
      "dn": "Pechbrunn",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09377146",
      "j": 2023,
      "n": "plößberg",
      "dn": "Plößberg",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09377148",
      "j": 2023,
      "n": "pullenreuth",
      "dn": "Pullenreuth",
      "bl": 9,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "09377149",
      "j": 2023,
      "n": "reuth bei erbendorf",
      "dn": "Reuth bei Erbendorf",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09377154",
      "j": 2023,
      "n": "tirschenreuth",
      "dn": "Tirschenreuth",
      "bl": 9,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "09377157",
      "j": 2023,
      "n": "waldershof",
      "dn": "Waldershof",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09377158",
      "j": 2023,
      "n": "waldsassen",
      "dn": "Waldsassen",
      "bl": 9,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "09377159",
      "j": 2023,
      "n": "wiesau",
      "dn": "Wiesau",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09461000",
      "j": 2023,
      "n": "bamberg",
      "dn": "Bamberg",
      "bl": 9,
      "hs": 535.0,
      "mns": 3
    },
    {
      "a": "09462000",
      "j": 2023,
      "n": "bayreuth",
      "dn": "Bayreuth",
      "bl": 9,
      "hs": 430.0,
      "mns": 3
    },
    {
      "a": "09463000",
      "j": 2023,
      "n": "coburg",
      "dn": "Coburg",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09464000",
      "j": 2023,
      "n": "hof",
      "dn": "Hof",
      "bl": 9,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "09471111",
      "j": 2023,
      "n": "altendorf",
      "dn": "Altendorf",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09471115",
      "j": 2023,
      "n": "baunach",
      "dn": "Baunach",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09471117",
      "j": 2023,
      "n": "bischberg",
      "dn": "Bischberg",
      "bl": 9,
      "hs": 315.0,
      "mns": 1
    },
    {
      "a": "09471119",
      "j": 2023,
      "n": "breitengüßbach",
      "dn": "Breitengüßbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09471120",
      "j": 2023,
      "n": "burgebrach",
      "dn": "Burgebrach",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09471122",
      "j": 2023,
      "n": "burgwindheim",
      "dn": "Burgwindheim",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09471123",
      "j": 2023,
      "n": "buttenheim",
      "dn": "Buttenheim",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09471128",
      "j": 2023,
      "n": "ebrach",
      "dn": "Ebrach",
      "bl": 9,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "09471131",
      "j": 2023,
      "n": "frensdorf",
      "dn": "Frensdorf",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09471133",
      "j": 2023,
      "n": "gerach",
      "dn": "Gerach",
      "bl": 9,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "09471137",
      "j": 2023,
      "n": "gundelsheim",
      "dn": "Gundelsheim",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09471140",
      "j": 2023,
      "n": "hallstadt",
      "dn": "Hallstadt",
      "bl": 9,
      "hs": 250.0,
      "mns": 1
    },
    {
      "a": "09471142",
      "j": 2023,
      "n": "heiligenstadt in oberfranken",
      "dn": "Heiligenstadt in Oberfranken",
      "bl": 9,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "09471145",
      "j": 2023,
      "n": "hirschaid",
      "dn": "Hirschaid",
      "bl": 9,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "09471150",
      "j": 2023,
      "n": "kemmern",
      "dn": "Kemmern",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09471151",
      "j": 2023,
      "n": "königsfeld",
      "dn": "Königsfeld",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09471152",
      "j": 2023,
      "n": "lauter",
      "dn": "Lauter",
      "bl": 9,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "09471154",
      "j": 2023,
      "n": "lisberg",
      "dn": "Lisberg",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09471155",
      "j": 2023,
      "n": "litzendorf",
      "dn": "Litzendorf",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09471159",
      "j": 2023,
      "n": "memmelsdorf",
      "dn": "Memmelsdorf",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09471165",
      "j": 2023,
      "n": "oberhaid",
      "dn": "Oberhaid",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09471169",
      "j": 2023,
      "n": "pettstadt",
      "dn": "Pettstadt",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09471172",
      "j": 2023,
      "n": "pommersfelden",
      "dn": "Pommersfelden",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09471173",
      "j": 2023,
      "n": "priesendorf",
      "dn": "Priesendorf",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09471174",
      "j": 2023,
      "n": "rattelsdorf",
      "dn": "Rattelsdorf",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09471175",
      "j": 2023,
      "n": "reckendorf",
      "dn": "Reckendorf",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09471185",
      "j": 2023,
      "n": "scheßlitz",
      "dn": "Scheßlitz",
      "bl": 9,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "09471186",
      "j": 2023,
      "n": "schönbrunn im steigerwald",
      "dn": "Schönbrunn im Steigerwald",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09471189",
      "j": 2023,
      "n": "stadelhofen",
      "dn": "Stadelhofen",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09471191",
      "j": 2025,
      "n": "stegaurach",
      "dn": "Stegaurach",
      "bl": 9,
      "hs": 220.0,
      "mns": 1
    },
    {
      "a": "09471195",
      "j": 2023,
      "n": "strullendorf",
      "dn": "Strullendorf",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09471207",
      "j": 2023,
      "n": "viereth-trunstadt",
      "dn": "Viereth-Trunstadt",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09471208",
      "j": 2023,
      "n": "walsdorf",
      "dn": "Walsdorf",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09471209",
      "j": 2023,
      "n": "wattendorf",
      "dn": "Wattendorf",
      "bl": 9,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "09471214",
      "j": 2023,
      "n": "zapfendorf",
      "dn": "Zapfendorf",
      "bl": 9,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "09471220",
      "j": 2023,
      "n": "schlüsselfeld",
      "dn": "Schlüsselfeld",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09472111",
      "j": 2023,
      "n": "ahorntal",
      "dn": "Ahorntal",
      "bl": 9,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "09472115",
      "j": 2023,
      "n": "aufseß",
      "dn": "Aufseß",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09472116",
      "j": 2023,
      "n": "bad berneck im fichtelgebirge",
      "dn": "Bad Berneck im Fichtelgebirge",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09472118",
      "j": 2023,
      "n": "betzenstein",
      "dn": "Betzenstein",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09472119",
      "j": 2023,
      "n": "bindlach",
      "dn": "Bindlach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09472121",
      "j": 2023,
      "n": "bischofsgrün",
      "dn": "Bischofsgrün",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09472127",
      "j": 2023,
      "n": "creußen",
      "dn": "Creußen",
      "bl": 9,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "09472131",
      "j": 2023,
      "n": "eckersdorf",
      "dn": "Eckersdorf",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09472133",
      "j": 2023,
      "n": "emtmannsberg",
      "dn": "Emtmannsberg",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09472138",
      "j": 2023,
      "n": "fichtelberg",
      "dn": "Fichtelberg",
      "bl": 9,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "09472139",
      "j": 2023,
      "n": "gefrees",
      "dn": "Gefrees",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09472140",
      "j": 2023,
      "n": "gesees",
      "dn": "Gesees",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09472141",
      "j": 2023,
      "n": "glashütten",
      "dn": "Glashütten",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09472143",
      "j": 2023,
      "n": "goldkronach",
      "dn": "Goldkronach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09472146",
      "j": 2023,
      "n": "haag",
      "dn": "Haag",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09472150",
      "j": 2023,
      "n": "heinersreuth",
      "dn": "Heinersreuth",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09472154",
      "j": 2023,
      "n": "hollfeld",
      "dn": "Hollfeld",
      "bl": 9,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "09472155",
      "j": 2023,
      "n": "hummeltal",
      "dn": "Hummeltal",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09472156",
      "j": 2023,
      "n": "kirchenpingarten",
      "dn": "Kirchenpingarten",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09472164",
      "j": 2023,
      "n": "mehlmeisel",
      "dn": "Mehlmeisel",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09472166",
      "j": 2023,
      "n": "mistelbach",
      "dn": "Mistelbach",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09472167",
      "j": 2023,
      "n": "mistelgau",
      "dn": "Mistelgau",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09472175",
      "j": 2023,
      "n": "pegnitz",
      "dn": "Pegnitz",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09472176",
      "j": 2023,
      "n": "plankenfels",
      "dn": "Plankenfels",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09472177",
      "j": 2023,
      "n": "plech",
      "dn": "Plech",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09472179",
      "j": 2023,
      "n": "pottenstein",
      "dn": "Pottenstein",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09472180",
      "j": 2023,
      "n": "prebitz",
      "dn": "Prebitz",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09472184",
      "j": 2023,
      "n": "schnabelwaid",
      "dn": "Schnabelwaid",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09472188",
      "j": 2023,
      "n": "seybothenreuth",
      "dn": "Seybothenreuth",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09472190",
      "j": 2023,
      "n": "speichersdorf",
      "dn": "Speichersdorf",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09472197",
      "j": 2023,
      "n": "waischenfeld",
      "dn": "Waischenfeld",
      "bl": 9,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "09472198",
      "j": 2023,
      "n": "warmensteinach",
      "dn": "Warmensteinach",
      "bl": 9,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "09472199",
      "j": 2023,
      "n": "weidenberg",
      "dn": "Weidenberg",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09473112",
      "j": 2023,
      "n": "ahorn",
      "dn": "Ahorn",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09473120",
      "j": 2023,
      "n": "dörfles-esbach",
      "dn": "Dörfles-Esbach",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09473121",
      "j": 2023,
      "n": "ebersdorf bei coburg",
      "dn": "Ebersdorf bei Coburg",
      "bl": 9,
      "hs": 335.0,
      "mns": 1
    },
    {
      "a": "09473132",
      "j": 2023,
      "n": "großheirath",
      "dn": "Großheirath",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09473134",
      "j": 2023,
      "n": "grub am forst",
      "dn": "Grub am Forst",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09473138",
      "j": 2023,
      "n": "itzgrund",
      "dn": "Itzgrund",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09473141",
      "j": 2023,
      "n": "lautertal",
      "dn": "Lautertal",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09473144",
      "j": 2023,
      "n": "meeder",
      "dn": "Meeder",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09473151",
      "j": 2023,
      "n": "neustadt bei coburg",
      "dn": "Neustadt bei Coburg",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09473153",
      "j": 2023,
      "n": "niederfüllbach",
      "dn": "Niederfüllbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09473158",
      "j": 2023,
      "n": "bad rodach",
      "dn": "Bad Rodach",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09473159",
      "j": 2023,
      "n": "rödental",
      "dn": "Rödental",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09473165",
      "j": 2023,
      "n": "seßlach",
      "dn": "Seßlach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09473166",
      "j": 2023,
      "n": "sonnefeld",
      "dn": "Sonnefeld",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09473170",
      "j": 2023,
      "n": "untersiemau",
      "dn": "Untersiemau",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09473174",
      "j": 2023,
      "n": "weidhausen bei coburg",
      "dn": "Weidhausen bei Coburg",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09473175",
      "j": 2023,
      "n": "weitramsdorf",
      "dn": "Weitramsdorf",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09474119",
      "j": 2023,
      "n": "dormitz",
      "dn": "Dormitz",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09474121",
      "j": 2025,
      "n": "ebermannstadt",
      "dn": "Ebermannstadt",
      "bl": 9,
      "hs": 290.0,
      "mns": 1
    },
    {
      "a": "09474122",
      "j": 2023,
      "n": "effeltrich",
      "dn": "Effeltrich",
      "bl": 9,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "09474123",
      "j": 2023,
      "n": "eggolsheim",
      "dn": "Eggolsheim",
      "bl": 9,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "09474124",
      "j": 2023,
      "n": "egloffstein",
      "dn": "Egloffstein",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09474126",
      "j": 2023,
      "n": "forchheim",
      "dn": "Forchheim",
      "bl": 9,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "09474129",
      "j": 2023,
      "n": "gößweinstein",
      "dn": "Gößweinstein",
      "bl": 9,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "09474132",
      "j": 2023,
      "n": "gräfenberg",
      "dn": "Gräfenberg",
      "bl": 9,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "09474133",
      "j": 2023,
      "n": "hallerndorf",
      "dn": "Hallerndorf",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09474134",
      "j": 2023,
      "n": "hausen",
      "dn": "Hausen",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09474135",
      "j": 2023,
      "n": "heroldsbach",
      "dn": "Heroldsbach",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09474137",
      "j": 2023,
      "n": "hetzles",
      "dn": "Hetzles",
      "bl": 9,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "09474138",
      "j": 2023,
      "n": "hiltpoltstein",
      "dn": "Hiltpoltstein",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09474140",
      "j": 2023,
      "n": "igensdorf",
      "dn": "Igensdorf",
      "bl": 9,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "09474143",
      "j": 2023,
      "n": "kirchehrenbach",
      "dn": "Kirchehrenbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09474144",
      "j": 2023,
      "n": "kleinsendelbach",
      "dn": "Kleinsendelbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09474145",
      "j": 2023,
      "n": "kunreuth",
      "dn": "Kunreuth",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09474146",
      "j": 2023,
      "n": "langensendelbach",
      "dn": "Langensendelbach",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09474147",
      "j": 2023,
      "n": "leutenbach",
      "dn": "Leutenbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09474154",
      "j": 2023,
      "n": "neunkirchen am brand",
      "dn": "Neunkirchen am Brand",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09474156",
      "j": 2023,
      "n": "obertrubach",
      "dn": "Obertrubach",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09474158",
      "j": 2023,
      "n": "pinzberg",
      "dn": "Pinzberg",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09474160",
      "j": 2023,
      "n": "poxdorf",
      "dn": "Poxdorf",
      "bl": 9,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "09474161",
      "j": 2023,
      "n": "pretzfeld",
      "dn": "Pretzfeld",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09474168",
      "j": 2023,
      "n": "unterleinleiter",
      "dn": "Unterleinleiter",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09474171",
      "j": 2023,
      "n": "weilersbach",
      "dn": "Weilersbach",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09474173",
      "j": 2023,
      "n": "weißenohe",
      "dn": "Weißenohe",
      "bl": 9,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "09474175",
      "j": 2023,
      "n": "wiesenthau",
      "dn": "Wiesenthau",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09474176",
      "j": 2023,
      "n": "wiesenttal",
      "dn": "Wiesenttal",
      "bl": 9,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "09475112",
      "j": 2023,
      "n": "bad steben",
      "dn": "Bad Steben",
      "bl": 9,
      "hs": 345.0,
      "mns": 1
    },
    {
      "a": "09475113",
      "j": 2023,
      "n": "berg",
      "dn": "Berg",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09475120",
      "j": 2023,
      "n": "döhlau",
      "dn": "Döhlau",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09475123",
      "j": 2023,
      "n": "feilitzsch",
      "dn": "Feilitzsch",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09475127",
      "j": 2023,
      "n": "gattendorf",
      "dn": "Gattendorf",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09475128",
      "j": 2023,
      "n": "geroldsgrün",
      "dn": "Geroldsgrün",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09475136",
      "j": 2023,
      "n": "helmbrechts",
      "dn": "Helmbrechts",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09475137",
      "j": 2023,
      "n": "issigau",
      "dn": "Issigau",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09475141",
      "j": 2023,
      "n": "köditz",
      "dn": "Köditz",
      "bl": 9,
      "hs": 345.0,
      "mns": 1
    },
    {
      "a": "09475142",
      "j": 2023,
      "n": "konradsreuth",
      "dn": "Konradsreuth",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09475145",
      "j": 2023,
      "n": "leupoldsgrün",
      "dn": "Leupoldsgrün",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09475146",
      "j": 2023,
      "n": "lichtenberg",
      "dn": "Lichtenberg",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09475154",
      "j": 2023,
      "n": "münchberg",
      "dn": "Münchberg",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09475156",
      "j": 2023,
      "n": "naila",
      "dn": "Naila",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09475158",
      "j": 2023,
      "n": "oberkotzau",
      "dn": "Oberkotzau",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09475161",
      "j": 2023,
      "n": "regnitzlosau",
      "dn": "Regnitzlosau",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09475162",
      "j": 2023,
      "n": "rehau",
      "dn": "Rehau",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09475165",
      "j": 2023,
      "n": "schauenstein",
      "dn": "Schauenstein",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09475168",
      "j": 2023,
      "n": "schwarzenbach an der saale",
      "dn": "Schwarzenbach an der Saale",
      "bl": 9,
      "hs": 342.0,
      "mns": 1
    },
    {
      "a": "09475169",
      "j": 2023,
      "n": "schwarzenbach am wald",
      "dn": "Schwarzenbach am Wald",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09475171",
      "j": 2023,
      "n": "selbitz",
      "dn": "Selbitz",
      "bl": 9,
      "hs": 335.0,
      "mns": 1
    },
    {
      "a": "09475174",
      "j": 2023,
      "n": "sparneck",
      "dn": "Sparneck",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09475175",
      "j": 2023,
      "n": "stammbach",
      "dn": "Stammbach",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09475181",
      "j": 2023,
      "n": "töpen",
      "dn": "Töpen",
      "bl": 9,
      "hs": 290.0,
      "mns": 1
    },
    {
      "a": "09475182",
      "j": 2023,
      "n": "trogen",
      "dn": "Trogen",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09475184",
      "j": 2023,
      "n": "weißdorf",
      "dn": "Weißdorf",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09475189",
      "j": 2023,
      "n": "zell im fichtelgebirge",
      "dn": "Zell im Fichtelgebirge",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09476145",
      "j": 2023,
      "n": "kronach",
      "dn": "Kronach",
      "bl": 9,
      "hs": 345.0,
      "mns": 2
    },
    {
      "a": "09476146",
      "j": 2023,
      "n": "küps",
      "dn": "Küps",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09476152",
      "j": 2023,
      "n": "ludwigsstadt",
      "dn": "Ludwigsstadt",
      "bl": 9,
      "hs": 339.0,
      "mns": 1
    },
    {
      "a": "09476154",
      "j": 2023,
      "n": "mitwitz",
      "dn": "Mitwitz",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09476159",
      "j": 2023,
      "n": "nordhalben",
      "dn": "Nordhalben",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09476164",
      "j": 2023,
      "n": "pressig",
      "dn": "Pressig",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09476166",
      "j": 2023,
      "n": "reichenbach",
      "dn": "Reichenbach",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09476171",
      "j": 2023,
      "n": "schneckenlohe",
      "dn": "Schneckenlohe",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09476175",
      "j": 2023,
      "n": "steinbach am wald",
      "dn": "Steinbach am Wald",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09476177",
      "j": 2023,
      "n": "steinwiesen",
      "dn": "Steinwiesen",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09476178",
      "j": 2023,
      "n": "stockheim",
      "dn": "Stockheim",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09476179",
      "j": 2023,
      "n": "tettau",
      "dn": "Tettau",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09476180",
      "j": 2023,
      "n": "teuschnitz",
      "dn": "Teuschnitz",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09476182",
      "j": 2023,
      "n": "tschirn",
      "dn": "Tschirn",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09476183",
      "j": 2023,
      "n": "marktrodach",
      "dn": "Marktrodach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09476184",
      "j": 2023,
      "n": "wallenfels",
      "dn": "Wallenfels",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09476185",
      "j": 2023,
      "n": "weißenbrunn",
      "dn": "Weißenbrunn",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09476189",
      "j": 2023,
      "n": "wilhelmsthal",
      "dn": "Wilhelmsthal",
      "bl": 9,
      "hs": 345.0,
      "mns": 1
    },
    {
      "a": "09477117",
      "j": 2023,
      "n": "grafengehaig",
      "dn": "Grafengehaig",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09477118",
      "j": 2023,
      "n": "guttenberg",
      "dn": "Guttenberg",
      "bl": 9,
      "hs": 341.0,
      "mns": 1
    },
    {
      "a": "09477119",
      "j": 2023,
      "n": "harsdorf",
      "dn": "Harsdorf",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09477121",
      "j": 2023,
      "n": "himmelkron",
      "dn": "Himmelkron",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09477124",
      "j": 2023,
      "n": "kasendorf",
      "dn": "Kasendorf",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09477127",
      "j": 2023,
      "n": "ködnitz",
      "dn": "Ködnitz",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09477128",
      "j": 2023,
      "n": "kulmbach",
      "dn": "Kulmbach",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09477129",
      "j": 2023,
      "n": "kupferberg",
      "dn": "Kupferberg",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09477135",
      "j": 2023,
      "n": "ludwigschorgast",
      "dn": "Ludwigschorgast",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09477136",
      "j": 2023,
      "n": "mainleus",
      "dn": "Mainleus",
      "bl": 9,
      "hs": 270.0,
      "mns": 1
    },
    {
      "a": "09477138",
      "j": 2023,
      "n": "marktleugast",
      "dn": "Marktleugast",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09477139",
      "j": 2023,
      "n": "marktschorgast",
      "dn": "Marktschorgast",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09477142",
      "j": 2023,
      "n": "neudrossenfeld",
      "dn": "Neudrossenfeld",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09477143",
      "j": 2023,
      "n": "neuenmarkt",
      "dn": "Neuenmarkt",
      "bl": 9,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "09477148",
      "j": 2023,
      "n": "presseck",
      "dn": "Presseck",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09477151",
      "j": 2023,
      "n": "rugendorf",
      "dn": "Rugendorf",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09477156",
      "j": 2023,
      "n": "stadtsteinach",
      "dn": "Stadtsteinach",
      "bl": 9,
      "hs": 344.0,
      "mns": 1
    },
    {
      "a": "09477157",
      "j": 2023,
      "n": "thurnau",
      "dn": "Thurnau",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09477158",
      "j": 2023,
      "n": "trebgast",
      "dn": "Trebgast",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09477159",
      "j": 2023,
      "n": "untersteinach",
      "dn": "Untersteinach",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09477163",
      "j": 2023,
      "n": "wirsberg",
      "dn": "Wirsberg",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09477164",
      "j": 2023,
      "n": "wonsees",
      "dn": "Wonsees",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09478111",
      "j": 2023,
      "n": "altenkunstadt",
      "dn": "Altenkunstadt",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09478116",
      "j": 2023,
      "n": "burgkunstadt",
      "dn": "Burgkunstadt",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09478120",
      "j": 2023,
      "n": "ebensfeld",
      "dn": "Ebensfeld",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09478127",
      "j": 2023,
      "n": "hochstadt am main",
      "dn": "Hochstadt am Main",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09478139",
      "j": 2023,
      "n": "lichtenfels",
      "dn": "Lichtenfels",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09478143",
      "j": 2023,
      "n": "marktgraitz",
      "dn": "Marktgraitz",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09478144",
      "j": 2023,
      "n": "marktzeuln",
      "dn": "Marktzeuln",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09478145",
      "j": 2023,
      "n": "michelau in oberfranken",
      "dn": "Michelau in Oberfranken",
      "bl": 9,
      "hs": 315.0,
      "mns": 1
    },
    {
      "a": "09478155",
      "j": 2023,
      "n": "redwitz an der rodach",
      "dn": "Redwitz an der Rodach",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09478165",
      "j": 2023,
      "n": "bad staffelstein",
      "dn": "Bad Staffelstein",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09478176",
      "j": 2023,
      "n": "weismain",
      "dn": "Weismain",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09479111",
      "j": 2023,
      "n": "bad alexandersbad",
      "dn": "Bad Alexandersbad",
      "bl": 9,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "09479112",
      "j": 2023,
      "n": "arzberg",
      "dn": "Arzberg",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09479126",
      "j": 2023,
      "n": "höchstädt im fichtelgebirge",
      "dn": "Höchstädt im Fichtelgebirge",
      "bl": 9,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "09479127",
      "j": 2023,
      "n": "hohenberg an der eger",
      "dn": "Hohenberg an der Eger",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09479129",
      "j": 2023,
      "n": "kirchenlamitz",
      "dn": "Kirchenlamitz",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09479135",
      "j": 2023,
      "n": "marktleuthen",
      "dn": "Marktleuthen",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09479136",
      "j": 2023,
      "n": "marktredwitz",
      "dn": "Marktredwitz",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09479138",
      "j": 2023,
      "n": "nagel",
      "dn": "Nagel",
      "bl": 9,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "09479145",
      "j": 2023,
      "n": "röslau",
      "dn": "Röslau",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09479147",
      "j": 2023,
      "n": "schirnding",
      "dn": "Schirnding",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09479150",
      "j": 2023,
      "n": "schönwald",
      "dn": "Schönwald",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09479152",
      "j": 2023,
      "n": "selb",
      "dn": "Selb",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09479158",
      "j": 2023,
      "n": "thiersheim",
      "dn": "Thiersheim",
      "bl": 9,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "09479159",
      "j": 2023,
      "n": "thierstein",
      "dn": "Thierstein",
      "bl": 9,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "09479161",
      "j": 2023,
      "n": "tröstau",
      "dn": "Tröstau",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09479166",
      "j": 2023,
      "n": "weißenstadt",
      "dn": "Weißenstadt",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09479169",
      "j": 2023,
      "n": "wunsiedel",
      "dn": "Wunsiedel",
      "bl": 9,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "09561000",
      "j": 2025,
      "n": "ansbach",
      "dn": "Ansbach",
      "bl": 9,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "09562000",
      "j": 2025,
      "n": "erlangen",
      "dn": "Erlangen",
      "bl": 9,
      "hs": 590.0,
      "mns": 4
    },
    {
      "a": "09563000",
      "j": 2023,
      "n": "fürth",
      "dn": "Fürth",
      "bl": 9,
      "hs": 555.0,
      "mns": 4
    },
    {
      "a": "09564000",
      "j": 2025,
      "n": "nürnberg",
      "dn": "Nürnberg",
      "bl": 9,
      "hs": 780.0,
      "mns": 5
    },
    {
      "a": "09565000",
      "j": 2023,
      "n": "schwabach",
      "dn": "Schwabach",
      "bl": 9,
      "hs": 450.0,
      "mns": 3
    },
    {
      "a": "09571111",
      "j": 2023,
      "n": "adelshofen",
      "dn": "Adelshofen",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09571113",
      "j": 2023,
      "n": "arberg",
      "dn": "Arberg",
      "bl": 9,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "09571114",
      "j": 2023,
      "n": "aurach",
      "dn": "Aurach",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09571115",
      "j": 2023,
      "n": "bechhofen",
      "dn": "Bechhofen",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09571122",
      "j": 2023,
      "n": "bruckberg",
      "dn": "Bruckberg",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09571125",
      "j": 2023,
      "n": "buch am wald",
      "dn": "Buch am Wald",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09571127",
      "j": 2023,
      "n": "burgoberbach",
      "dn": "Burgoberbach",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09571128",
      "j": 2023,
      "n": "burk",
      "dn": "Burk",
      "bl": 9,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "09571130",
      "j": 2023,
      "n": "colmberg",
      "dn": "Colmberg",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09571132",
      "j": 2023,
      "n": "dentlein am forst",
      "dn": "Dentlein am Forst",
      "bl": 9,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "09571134",
      "j": 2023,
      "n": "diebach",
      "dn": "Diebach",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09571135",
      "j": 2023,
      "n": "dietenhofen",
      "dn": "Dietenhofen",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09571136",
      "j": 2023,
      "n": "dinkelsbühl",
      "dn": "Dinkelsbühl",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09571137",
      "j": 2023,
      "n": "dombühl",
      "dn": "Dombühl",
      "bl": 9,
      "hs": 495.0,
      "mns": 1
    },
    {
      "a": "09571139",
      "j": 2023,
      "n": "dürrwangen",
      "dn": "Dürrwangen",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09571141",
      "j": 2023,
      "n": "ehingen",
      "dn": "Ehingen",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09571145",
      "j": 2023,
      "n": "feuchtwangen",
      "dn": "Feuchtwangen",
      "bl": 9,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "09571146",
      "j": 2023,
      "n": "flachslanden",
      "dn": "Flachslanden",
      "bl": 9,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "09571152",
      "j": 2023,
      "n": "gebsattel",
      "dn": "Gebsattel",
      "bl": 9,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "09571154",
      "j": 2023,
      "n": "gerolfingen",
      "dn": "Gerolfingen",
      "bl": 9,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "09571155",
      "j": 2023,
      "n": "geslau",
      "dn": "Geslau",
      "bl": 9,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "09571165",
      "j": 2023,
      "n": "heilsbronn",
      "dn": "Heilsbronn",
      "bl": 9,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "09571166",
      "j": 2023,
      "n": "herrieden",
      "dn": "Herrieden",
      "bl": 9,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "09571169",
      "j": 2023,
      "n": "insingen",
      "dn": "Insingen",
      "bl": 9,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "09571170",
      "j": 2023,
      "n": "langfurth",
      "dn": "Langfurth",
      "bl": 9,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "09571171",
      "j": 2023,
      "n": "lehrberg",
      "dn": "Lehrberg",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09571174",
      "j": 2023,
      "n": "leutershausen",
      "dn": "Leutershausen",
      "bl": 9,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "09571175",
      "j": 2023,
      "n": "lichtenau",
      "dn": "Lichtenau",
      "bl": 9,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "09571177",
      "j": 2023,
      "n": "merkendorf",
      "dn": "Merkendorf",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09571178",
      "j": 2023,
      "n": "mitteleschenbach",
      "dn": "Mitteleschenbach",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09571179",
      "j": 2023,
      "n": "mönchsroth",
      "dn": "Mönchsroth",
      "bl": 9,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "09571180",
      "j": 2023,
      "n": "neuendettelsau",
      "dn": "Neuendettelsau",
      "bl": 9,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "09571181",
      "j": 2023,
      "n": "neusitz",
      "dn": "Neusitz",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09571183",
      "j": 2023,
      "n": "oberdachstetten",
      "dn": "Oberdachstetten",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09571188",
      "j": 2023,
      "n": "ohrenbach",
      "dn": "Ohrenbach",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09571189",
      "j": 2023,
      "n": "ornbau",
      "dn": "Ornbau",
      "bl": 9,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "09571190",
      "j": 2025,
      "n": "petersaurach",
      "dn": "Petersaurach",
      "bl": 9,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "09571192",
      "j": 2023,
      "n": "röckingen",
      "dn": "Röckingen",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09571193",
      "j": 2023,
      "n": "rothenburg ob der tauber",
      "dn": "Rothenburg ob der Tauber",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09571194",
      "j": 2023,
      "n": "rügland",
      "dn": "Rügland",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09571196",
      "j": 2023,
      "n": "sachsen bei ansbach",
      "dn": "Sachsen bei Ansbach",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09571198",
      "j": 2023,
      "n": "schillingsfürst",
      "dn": "Schillingsfürst",
      "bl": 9,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "09571199",
      "j": 2023,
      "n": "schnelldorf",
      "dn": "Schnelldorf",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09571200",
      "j": 2023,
      "n": "schopfloch",
      "dn": "Schopfloch",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09571205",
      "j": 2023,
      "n": "steinsfeld",
      "dn": "Steinsfeld",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09571208",
      "j": 2023,
      "n": "unterschwaningen",
      "dn": "Unterschwaningen",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09571214",
      "j": 2023,
      "n": "wassertrüdingen",
      "dn": "Wassertrüdingen",
      "bl": 9,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "09571216",
      "j": 2023,
      "n": "weidenbach",
      "dn": "Weidenbach",
      "bl": 9,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "09571217",
      "j": 2023,
      "n": "weihenzell",
      "dn": "Weihenzell",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09571218",
      "j": 2023,
      "n": "weiltingen",
      "dn": "Weiltingen",
      "bl": 9,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "09571222",
      "j": 2025,
      "n": "wettringen",
      "dn": "Wettringen",
      "bl": 9,
      "hs": 290.0,
      "mns": 1
    },
    {
      "a": "09571223",
      "j": 2023,
      "n": "wieseth",
      "dn": "Wieseth",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09571224",
      "j": 2023,
      "n": "wilburgstetten",
      "dn": "Wilburgstetten",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09571225",
      "j": 2023,
      "n": "windelsbach",
      "dn": "Windelsbach",
      "bl": 9,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "09571226",
      "j": 2023,
      "n": "windsbach",
      "dn": "Windsbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09571227",
      "j": 2023,
      "n": "wittelshofen",
      "dn": "Wittelshofen",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09571228",
      "j": 2023,
      "n": "wörnitz",
      "dn": "Wörnitz",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09571229",
      "j": 2023,
      "n": "wolframs-eschenbach",
      "dn": "Wolframs-Eschenbach",
      "bl": 9,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "09572111",
      "j": 2023,
      "n": "adelsdorf",
      "dn": "Adelsdorf",
      "bl": 9,
      "hs": 550.0,
      "mns": 3
    },
    {
      "a": "09572114",
      "j": 2023,
      "n": "aurachtal",
      "dn": "Aurachtal",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09572115",
      "j": 2023,
      "n": "baiersdorf",
      "dn": "Baiersdorf",
      "bl": 9,
      "hs": 450.0,
      "mns": 3
    },
    {
      "a": "09572119",
      "j": 2023,
      "n": "bubenreuth",
      "dn": "Bubenreuth",
      "bl": 9,
      "hs": 420.0,
      "mns": 3
    },
    {
      "a": "09572120",
      "j": 2023,
      "n": "buckenhof",
      "dn": "Buckenhof",
      "bl": 9,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "09572121",
      "j": 2023,
      "n": "eckental",
      "dn": "Eckental",
      "bl": 9,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "09572126",
      "j": 2023,
      "n": "gremsdorf",
      "dn": "Gremsdorf",
      "bl": 9,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "09572127",
      "j": 2023,
      "n": "großenseebach",
      "dn": "Großenseebach",
      "bl": 9,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "09572130",
      "j": 2025,
      "n": "hemhofen",
      "dn": "Hemhofen",
      "bl": 9,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "09572131",
      "j": 2023,
      "n": "heroldsberg",
      "dn": "Heroldsberg",
      "bl": 9,
      "hs": 450.0,
      "mns": 3
    },
    {
      "a": "09572132",
      "j": 2023,
      "n": "herzogenaurach",
      "dn": "Herzogenaurach",
      "bl": 9,
      "hs": 280.0,
      "mns": 4
    },
    {
      "a": "09572133",
      "j": 2023,
      "n": "heßdorf",
      "dn": "Heßdorf",
      "bl": 9,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "09572135",
      "j": 2023,
      "n": "höchstadt an der aisch",
      "dn": "Höchstadt an der Aisch",
      "bl": 9,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "09572137",
      "j": 2023,
      "n": "kalchreuth",
      "dn": "Kalchreuth",
      "bl": 9,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "09572139",
      "j": 2023,
      "n": "lonnerstadt",
      "dn": "Lonnerstadt",
      "bl": 9,
      "hs": 600.0,
      "mns": 3
    },
    {
      "a": "09572141",
      "j": 2023,
      "n": "marloffstein",
      "dn": "Marloffstein",
      "bl": 9,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "09572142",
      "j": 2023,
      "n": "möhrendorf",
      "dn": "Möhrendorf",
      "bl": 9,
      "hs": 260.0,
      "mns": 3
    },
    {
      "a": "09572143",
      "j": 2023,
      "n": "mühlhausen",
      "dn": "Mühlhausen",
      "bl": 9,
      "hs": 600.0,
      "mns": 3
    },
    {
      "a": "09572147",
      "j": 2023,
      "n": "oberreichenbach",
      "dn": "Oberreichenbach",
      "bl": 9,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "09572149",
      "j": 2023,
      "n": "röttenbach",
      "dn": "Röttenbach",
      "bl": 9,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "09572154",
      "j": 2023,
      "n": "spardorf",
      "dn": "Spardorf",
      "bl": 9,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "09572158",
      "j": 2023,
      "n": "uttenreuth",
      "dn": "Uttenreuth",
      "bl": 9,
      "hs": 450.0,
      "mns": 3
    },
    {
      "a": "09572159",
      "j": 2023,
      "n": "vestenbergsgreuth",
      "dn": "Vestenbergsgreuth",
      "bl": 9,
      "hs": 300.0,
      "mns": 3
    },
    {
      "a": "09572160",
      "j": 2023,
      "n": "wachenroth",
      "dn": "Wachenroth",
      "bl": 9,
      "hs": 450.0,
      "mns": 3
    },
    {
      "a": "09572164",
      "j": 2023,
      "n": "weisendorf",
      "dn": "Weisendorf",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09573111",
      "j": 2023,
      "n": "ammerndorf",
      "dn": "Ammerndorf",
      "bl": 9,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "09573114",
      "j": 2023,
      "n": "cadolzburg",
      "dn": "Cadolzburg",
      "bl": 9,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "09573115",
      "j": 2023,
      "n": "großhabersdorf",
      "dn": "Großhabersdorf",
      "bl": 9,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "09573120",
      "j": 2023,
      "n": "langenzenn",
      "dn": "Langenzenn",
      "bl": 9,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "09573122",
      "j": 2024,
      "n": "oberasbach",
      "dn": "Oberasbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "09573123",
      "j": 2023,
      "n": "obermichelbach",
      "dn": "Obermichelbach",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09573124",
      "j": 2023,
      "n": "puschendorf",
      "dn": "Puschendorf",
      "bl": 9,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "09573125",
      "j": 2023,
      "n": "roßtal",
      "dn": "Roßtal",
      "bl": 9,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "09573126",
      "j": 2023,
      "n": "seukendorf",
      "dn": "Seukendorf",
      "bl": 9,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "09573127",
      "j": 2025,
      "n": "stein",
      "dn": "Stein",
      "bl": 9,
      "hs": 567.0,
      "mns": 4
    },
    {
      "a": "09573129",
      "j": 2023,
      "n": "tuchenbach",
      "dn": "Tuchenbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "09573130",
      "j": 2023,
      "n": "veitsbronn",
      "dn": "Veitsbronn",
      "bl": 9,
      "hs": 330.0,
      "mns": 3
    },
    {
      "a": "09573133",
      "j": 2023,
      "n": "wilhermsdorf",
      "dn": "Wilhermsdorf",
      "bl": 9,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "09573134",
      "j": 2023,
      "n": "zirndorf",
      "dn": "Zirndorf",
      "bl": 9,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "09574111",
      "j": 2023,
      "n": "alfeld",
      "dn": "Alfeld",
      "bl": 9,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "09574112",
      "j": 2025,
      "n": "altdorf bei nürnberg",
      "dn": "Altdorf bei Nürnberg",
      "bl": 9,
      "hs": 251.0,
      "mns": 3
    },
    {
      "a": "09574117",
      "j": 2023,
      "n": "burgthann",
      "dn": "Burgthann",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09574120",
      "j": 2023,
      "n": "engelthal",
      "dn": "Engelthal",
      "bl": 9,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "09574123",
      "j": 2023,
      "n": "feucht",
      "dn": "Feucht",
      "bl": 9,
      "hs": 340.0,
      "mns": 4
    },
    {
      "a": "09574128",
      "j": 2023,
      "n": "happurg",
      "dn": "Happurg",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09574129",
      "j": 2023,
      "n": "hartenstein",
      "dn": "Hartenstein",
      "bl": 9,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "09574131",
      "j": 2023,
      "n": "henfenfeld",
      "dn": "Henfenfeld",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09574132",
      "j": 2023,
      "n": "hersbruck",
      "dn": "Hersbruck",
      "bl": 9,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "09574135",
      "j": 2023,
      "n": "kirchensittenbach",
      "dn": "Kirchensittenbach",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09574138",
      "j": 2023,
      "n": "lauf an der pegnitz",
      "dn": "Lauf an der Pegnitz",
      "bl": 9,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "09574139",
      "j": 2023,
      "n": "leinburg",
      "dn": "Leinburg",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09574140",
      "j": 2023,
      "n": "neuhaus an der pegnitz",
      "dn": "Neuhaus an der Pegnitz",
      "bl": 9,
      "hs": 447.0,
      "mns": 2
    },
    {
      "a": "09574141",
      "j": 2023,
      "n": "neunkirchen am sand",
      "dn": "Neunkirchen am Sand",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09574145",
      "j": 2023,
      "n": "offenhausen",
      "dn": "Offenhausen",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09574146",
      "j": 2023,
      "n": "ottensoos",
      "dn": "Ottensoos",
      "bl": 9,
      "hs": 345.0,
      "mns": 2
    },
    {
      "a": "09574147",
      "j": 2023,
      "n": "pommelsbrunn",
      "dn": "Pommelsbrunn",
      "bl": 9,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "09574150",
      "j": 2023,
      "n": "reichenschwand",
      "dn": "Reichenschwand",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09574152",
      "j": 2025,
      "n": "röthenbach an der pegnitz",
      "dn": "Röthenbach an der Pegnitz",
      "bl": 9,
      "hs": 260.0,
      "mns": 3
    },
    {
      "a": "09574154",
      "j": 2023,
      "n": "rückersdorf",
      "dn": "Rückersdorf",
      "bl": 9,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "09574155",
      "j": 2025,
      "n": "schnaittach",
      "dn": "Schnaittach",
      "bl": 9,
      "hs": 247.0,
      "mns": 2
    },
    {
      "a": "09574156",
      "j": 2023,
      "n": "schwaig bei nürnberg",
      "dn": "Schwaig bei Nürnberg",
      "bl": 9,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "09574157",
      "j": 2023,
      "n": "schwarzenbruck",
      "dn": "Schwarzenbruck",
      "bl": 9,
      "hs": 335.0,
      "mns": 2
    },
    {
      "a": "09574158",
      "j": 2023,
      "n": "simmelsdorf",
      "dn": "Simmelsdorf",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09574160",
      "j": 2023,
      "n": "velden",
      "dn": "Velden",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09574161",
      "j": 2023,
      "n": "vorra",
      "dn": "Vorra",
      "bl": 9,
      "hs": 375.0,
      "mns": 2
    },
    {
      "a": "09574164",
      "j": 2023,
      "n": "winkelhaid",
      "dn": "Winkelhaid",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09575112",
      "j": 2023,
      "n": "bad windsheim",
      "dn": "Bad Windsheim",
      "bl": 9,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "09575113",
      "j": 2023,
      "n": "baudenbach",
      "dn": "Baudenbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09575115",
      "j": 2023,
      "n": "burgbernheim",
      "dn": "Burgbernheim",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09575116",
      "j": 2023,
      "n": "burghaslach",
      "dn": "Burghaslach",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09575117",
      "j": 2023,
      "n": "dachsbach",
      "dn": "Dachsbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09575118",
      "j": 2023,
      "n": "diespeck",
      "dn": "Diespeck",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09575119",
      "j": 2023,
      "n": "dietersheim",
      "dn": "Dietersheim",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09575121",
      "j": 2023,
      "n": "emskirchen",
      "dn": "Emskirchen",
      "bl": 9,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "09575122",
      "j": 2023,
      "n": "ergersheim",
      "dn": "Ergersheim",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09575124",
      "j": 2023,
      "n": "gallmersgarten",
      "dn": "Gallmersgarten",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09575125",
      "j": 2023,
      "n": "gerhardshofen",
      "dn": "Gerhardshofen",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09575127",
      "j": 2023,
      "n": "gollhofen",
      "dn": "Gollhofen",
      "bl": 9,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "09575128",
      "j": 2023,
      "n": "gutenstetten",
      "dn": "Gutenstetten",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09575129",
      "j": 2023,
      "n": "hagenbüchach",
      "dn": "Hagenbüchach",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09575130",
      "j": 2023,
      "n": "hemmersheim",
      "dn": "Hemmersheim",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09575133",
      "j": 2023,
      "n": "illesheim",
      "dn": "Illesheim",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09575134",
      "j": 2023,
      "n": "ippesheim",
      "dn": "Ippesheim",
      "bl": 9,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "09575135",
      "j": 2023,
      "n": "ipsheim",
      "dn": "Ipsheim",
      "bl": 9,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "09575138",
      "j": 2023,
      "n": "langenfeld",
      "dn": "Langenfeld",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09575143",
      "j": 2023,
      "n": "marktbergel",
      "dn": "Marktbergel",
      "bl": 9,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "09575144",
      "j": 2023,
      "n": "markt bibart",
      "dn": "Markt Bibart",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09575145",
      "j": 2023,
      "n": "markt erlbach",
      "dn": "Markt Erlbach",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09575146",
      "j": 2023,
      "n": "markt nordheim",
      "dn": "Markt Nordheim",
      "bl": 9,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "09575147",
      "j": 2023,
      "n": "markt taschendorf",
      "dn": "Markt Taschendorf",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09575150",
      "j": 2023,
      "n": "münchsteinach",
      "dn": "Münchsteinach",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09575152",
      "j": 2023,
      "n": "neuhof an der zenn",
      "dn": "Neuhof an der Zenn",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09575153",
      "j": 2023,
      "n": "neustadt an der aisch",
      "dn": "Neustadt an der Aisch",
      "bl": 9,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "09575155",
      "j": 2023,
      "n": "oberickelsheim",
      "dn": "Oberickelsheim",
      "bl": 9,
      "hs": 650.0,
      "mns": 1
    },
    {
      "a": "09575156",
      "j": 2023,
      "n": "obernzenn",
      "dn": "Obernzenn",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09575157",
      "j": 2023,
      "n": "oberscheinfeld",
      "dn": "Oberscheinfeld",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09575161",
      "j": 2023,
      "n": "scheinfeld",
      "dn": "Scheinfeld",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09575163",
      "j": 2023,
      "n": "simmershofen",
      "dn": "Simmershofen",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09575165",
      "j": 2023,
      "n": "sugenheim",
      "dn": "Sugenheim",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09575166",
      "j": 2023,
      "n": "trautskirchen",
      "dn": "Trautskirchen",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09575167",
      "j": 2023,
      "n": "uehlfeld",
      "dn": "Uehlfeld",
      "bl": 9,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "09575168",
      "j": 2023,
      "n": "uffenheim",
      "dn": "Uffenheim",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09575179",
      "j": 2023,
      "n": "weigenheim",
      "dn": "Weigenheim",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09575181",
      "j": 2023,
      "n": "wilhelmsdorf",
      "dn": "Wilhelmsdorf",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09576111",
      "j": 2023,
      "n": "abenberg",
      "dn": "Abenberg",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09576113",
      "j": 2023,
      "n": "allersberg",
      "dn": "Allersberg",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09576117",
      "j": 2023,
      "n": "büchenbach",
      "dn": "Büchenbach",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09576121",
      "j": 2023,
      "n": "georgensgmünd",
      "dn": "Georgensgmünd",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09576122",
      "j": 2025,
      "n": "greding",
      "dn": "Greding",
      "bl": 9,
      "hs": 240.0,
      "mns": 1
    },
    {
      "a": "09576126",
      "j": 2024,
      "n": "heideck",
      "dn": "Heideck",
      "bl": 9,
      "hs": 205.0,
      "mns": 1
    },
    {
      "a": "09576127",
      "j": 2023,
      "n": "hilpoltstein",
      "dn": "Hilpoltstein",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09576128",
      "j": 2023,
      "n": "kammerstein",
      "dn": "Kammerstein",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09576132",
      "j": 2023,
      "n": "schwanstetten",
      "dn": "Schwanstetten",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09576137",
      "j": 2023,
      "n": "rednitzhembach",
      "dn": "Rednitzhembach",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09576141",
      "j": 2023,
      "n": "röttenbach",
      "dn": "Röttenbach",
      "bl": 9,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "09576142",
      "j": 2023,
      "n": "rohr",
      "dn": "Rohr",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09576143",
      "j": 2025,
      "n": "roth",
      "dn": "Roth",
      "bl": 9,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "09576147",
      "j": 2023,
      "n": "spalt",
      "dn": "Spalt",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09576148",
      "j": 2023,
      "n": "thalmässing",
      "dn": "Thalmässing",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09576151",
      "j": 2023,
      "n": "wendelstein",
      "dn": "Wendelstein",
      "bl": 9,
      "hs": 310.0,
      "mns": 4
    },
    {
      "a": "09577111",
      "j": 2023,
      "n": "absberg",
      "dn": "Absberg",
      "bl": 9,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "09577113",
      "j": 2023,
      "n": "alesheim",
      "dn": "Alesheim",
      "bl": 9,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "09577114",
      "j": 2023,
      "n": "muhr am see",
      "dn": "Muhr am See",
      "bl": 9,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "09577115",
      "j": 2023,
      "n": "bergen",
      "dn": "Bergen",
      "bl": 9,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "09577120",
      "j": 2023,
      "n": "burgsalach",
      "dn": "Burgsalach",
      "bl": 9,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "09577122",
      "j": 2023,
      "n": "dittenheim",
      "dn": "Dittenheim",
      "bl": 9,
      "hs": 525.0,
      "mns": 1
    },
    {
      "a": "09577125",
      "j": 2023,
      "n": "ellingen",
      "dn": "Ellingen",
      "bl": 9,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "09577127",
      "j": 2023,
      "n": "ettenstatt",
      "dn": "Ettenstatt",
      "bl": 9,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "09577133",
      "j": 2023,
      "n": "gnotzheim",
      "dn": "Gnotzheim",
      "bl": 9,
      "hs": 650.0,
      "mns": 1
    },
    {
      "a": "09577136",
      "j": 2023,
      "n": "gunzenhausen",
      "dn": "Gunzenhausen",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09577138",
      "j": 2023,
      "n": "haundorf",
      "dn": "Haundorf",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09577140",
      "j": 2023,
      "n": "heidenheim",
      "dn": "Heidenheim",
      "bl": 9,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "09577141",
      "j": 2023,
      "n": "höttingen",
      "dn": "Höttingen",
      "bl": 9,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "09577148",
      "j": 2023,
      "n": "langenaltheim",
      "dn": "Langenaltheim",
      "bl": 9,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "09577149",
      "j": 2023,
      "n": "markt berolzheim",
      "dn": "Markt Berolzheim",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09577150",
      "j": 2023,
      "n": "meinheim",
      "dn": "Meinheim",
      "bl": 9,
      "hs": 650.0,
      "mns": 1
    },
    {
      "a": "09577151",
      "j": 2023,
      "n": "nennslingen",
      "dn": "Nennslingen",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09577158",
      "j": 2023,
      "n": "pappenheim",
      "dn": "Pappenheim",
      "bl": 9,
      "hs": 475.0,
      "mns": 1
    },
    {
      "a": "09577159",
      "j": 2023,
      "n": "pfofeld",
      "dn": "Pfofeld",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09577161",
      "j": 2023,
      "n": "pleinfeld",
      "dn": "Pleinfeld",
      "bl": 9,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "09577162",
      "j": 2023,
      "n": "polsingen",
      "dn": "Polsingen",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09577163",
      "j": 2023,
      "n": "raitenbuch",
      "dn": "Raitenbuch",
      "bl": 9,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "09577168",
      "j": 2023,
      "n": "solnhofen",
      "dn": "Solnhofen",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09577172",
      "j": 2023,
      "n": "theilenhofen",
      "dn": "Theilenhofen",
      "bl": 9,
      "hs": 600.0,
      "mns": 1
    },
    {
      "a": "09577173",
      "j": 2023,
      "n": "treuchtlingen",
      "dn": "Treuchtlingen",
      "bl": 9,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "09577177",
      "j": 2023,
      "n": "weißenburg in bayern",
      "dn": "Weißenburg in Bayern",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09577179",
      "j": 2023,
      "n": "westheim",
      "dn": "Westheim",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09661000",
      "j": 2025,
      "n": "aschaffenburg",
      "dn": "Aschaffenburg",
      "bl": 9,
      "hs": 430.0,
      "mns": 4
    },
    {
      "a": "09662000",
      "j": 2023,
      "n": "schweinfurt",
      "dn": "Schweinfurt",
      "bl": 9,
      "hs": 385.0,
      "mns": 2
    },
    {
      "a": "09663000",
      "j": 2025,
      "n": "würzburg",
      "dn": "Würzburg",
      "bl": 9,
      "hs": 510.0,
      "mns": 4
    },
    {
      "a": "09671111",
      "j": 2023,
      "n": "alzenau",
      "dn": "Alzenau",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09671112",
      "j": 2023,
      "n": "bessenbach",
      "dn": "Bessenbach",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09671113",
      "j": 2023,
      "n": "blankenbach",
      "dn": "Blankenbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09671114",
      "j": 2023,
      "n": "karlstein am main",
      "dn": "Karlstein am Main",
      "bl": 9,
      "hs": 275.0,
      "mns": 2
    },
    {
      "a": "09671119",
      "j": 2023,
      "n": "geiselbach",
      "dn": "Geiselbach",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09671120",
      "j": 2023,
      "n": "glattbach",
      "dn": "Glattbach",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09671121",
      "j": 2023,
      "n": "goldbach",
      "dn": "Goldbach",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09671122",
      "j": 2025,
      "n": "großostheim",
      "dn": "Großostheim",
      "bl": 9,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "09671124",
      "j": 2023,
      "n": "haibach",
      "dn": "Haibach",
      "bl": 9,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "09671126",
      "j": 2023,
      "n": "heigenbrücken",
      "dn": "Heigenbrücken",
      "bl": 9,
      "hs": 325.0,
      "mns": 2
    },
    {
      "a": "09671127",
      "j": 2023,
      "n": "heimbuchenthal",
      "dn": "Heimbuchenthal",
      "bl": 9,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "09671128",
      "j": 2023,
      "n": "heinrichsthal",
      "dn": "Heinrichsthal",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09671130",
      "j": 2023,
      "n": "hösbach",
      "dn": "Hösbach",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09671133",
      "j": 2023,
      "n": "johannesberg",
      "dn": "Johannesberg",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09671134",
      "j": 2023,
      "n": "kahl am main",
      "dn": "Kahl am Main",
      "bl": 9,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "09671135",
      "j": 2023,
      "n": "kleinkahl",
      "dn": "Kleinkahl",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09671136",
      "j": 2023,
      "n": "kleinostheim",
      "dn": "Kleinostheim",
      "bl": 9,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "09671138",
      "j": 2023,
      "n": "krombach",
      "dn": "Krombach",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09671139",
      "j": 2023,
      "n": "laufach",
      "dn": "Laufach",
      "bl": 9,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "09671140",
      "j": 2023,
      "n": "mainaschaff",
      "dn": "Mainaschaff",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09671141",
      "j": 2023,
      "n": "mespelbrunn",
      "dn": "Mespelbrunn",
      "bl": 9,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "09671143",
      "j": 2023,
      "n": "mömbris",
      "dn": "Mömbris",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09671148",
      "j": 2023,
      "n": "rothenbuch",
      "dn": "Rothenbuch",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09671150",
      "j": 2023,
      "n": "sailauf",
      "dn": "Sailauf",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09671152",
      "j": 2023,
      "n": "schöllkrippen",
      "dn": "Schöllkrippen",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09671153",
      "j": 2023,
      "n": "sommerkahl",
      "dn": "Sommerkahl",
      "bl": 9,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "09671155",
      "j": 2023,
      "n": "stockstadt am main",
      "dn": "Stockstadt am Main",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09671156",
      "j": 2023,
      "n": "waldaschaff",
      "dn": "Waldaschaff",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09671157",
      "j": 2023,
      "n": "weibersbrunn",
      "dn": "Weibersbrunn",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09671159",
      "j": 2023,
      "n": "westerngrund",
      "dn": "Westerngrund",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09671160",
      "j": 2023,
      "n": "dammbach",
      "dn": "Dammbach",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09671162",
      "j": 2023,
      "n": "wiesen",
      "dn": "Wiesen",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09672111",
      "j": 2023,
      "n": "aura an der saale",
      "dn": "Aura an der Saale",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09672112",
      "j": 2023,
      "n": "bad bocklet",
      "dn": "Bad Bocklet",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09672113",
      "j": 2023,
      "n": "bad brückenau",
      "dn": "Bad Brückenau",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09672114",
      "j": 2023,
      "n": "bad kissingen",
      "dn": "Bad Kissingen",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09672117",
      "j": 2023,
      "n": "burkardroth",
      "dn": "Burkardroth",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09672121",
      "j": 2023,
      "n": "elfershausen",
      "dn": "Elfershausen",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09672122",
      "j": 2023,
      "n": "euerdorf",
      "dn": "Euerdorf",
      "bl": 9,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "09672124",
      "j": 2023,
      "n": "fuchsstadt",
      "dn": "Fuchsstadt",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09672126",
      "j": 2023,
      "n": "geroda",
      "dn": "Geroda",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09672127",
      "j": 2023,
      "n": "hammelburg",
      "dn": "Hammelburg",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09672131",
      "j": 2023,
      "n": "maßbach",
      "dn": "Maßbach",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09672134",
      "j": 2023,
      "n": "motten",
      "dn": "Motten",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09672135",
      "j": 2023,
      "n": "münnerstadt",
      "dn": "Münnerstadt",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09672136",
      "j": 2023,
      "n": "nüdlingen",
      "dn": "Nüdlingen",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09672138",
      "j": 2023,
      "n": "oberleichtersbach",
      "dn": "Oberleichtersbach",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09672139",
      "j": 2023,
      "n": "oberthulba",
      "dn": "Oberthulba",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09672140",
      "j": 2023,
      "n": "oerlenbach",
      "dn": "Oerlenbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09672142",
      "j": 2023,
      "n": "ramsthal",
      "dn": "Ramsthal",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09672143",
      "j": 2023,
      "n": "rannungen",
      "dn": "Rannungen",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09672145",
      "j": 2023,
      "n": "riedenberg",
      "dn": "Riedenberg",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09672149",
      "j": 2023,
      "n": "schondra",
      "dn": "Schondra",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09672155",
      "j": 2023,
      "n": "sulzthal",
      "dn": "Sulzthal",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09672157",
      "j": 2023,
      "n": "thundorf in unterfranken",
      "dn": "Thundorf in Unterfranken",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09672161",
      "j": 2023,
      "n": "wartmannsroth",
      "dn": "Wartmannsroth",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09672163",
      "j": 2023,
      "n": "wildflecken",
      "dn": "Wildflecken",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09672166",
      "j": 2023,
      "n": "zeitlofs",
      "dn": "Zeitlofs",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09673113",
      "j": 2023,
      "n": "aubstadt",
      "dn": "Aubstadt",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09673114",
      "j": 2023,
      "n": "bad neustadt an der saale",
      "dn": "Bad Neustadt an der Saale",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09673116",
      "j": 2023,
      "n": "bastheim",
      "dn": "Bastheim",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09673117",
      "j": 2023,
      "n": "bischofsheim in der rhön",
      "dn": "Bischofsheim in der Rhön",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09673123",
      "j": 2023,
      "n": "fladungen",
      "dn": "Fladungen",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09673126",
      "j": 2023,
      "n": "großbardorf",
      "dn": "Großbardorf",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09673127",
      "j": 2023,
      "n": "großeibstadt",
      "dn": "Großeibstadt",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09673129",
      "j": 2023,
      "n": "hausen",
      "dn": "Hausen",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09673130",
      "j": 2023,
      "n": "hendungen",
      "dn": "Hendungen",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09673131",
      "j": 2023,
      "n": "herbstadt",
      "dn": "Herbstadt",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09673133",
      "j": 2023,
      "n": "heustreu",
      "dn": "Heustreu",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09673134",
      "j": 2023,
      "n": "höchheim",
      "dn": "Höchheim",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09673135",
      "j": 2025,
      "n": "hohenroth",
      "dn": "Hohenroth",
      "bl": 9,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "09673136",
      "j": 2023,
      "n": "hollstadt",
      "dn": "Hollstadt",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09673141",
      "j": 2023,
      "n": "bad königshofen im grabfeld",
      "dn": "Bad Königshofen im Grabfeld",
      "bl": 9,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "09673142",
      "j": 2023,
      "n": "mellrichstadt",
      "dn": "Mellrichstadt",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09673146",
      "j": 2023,
      "n": "niederlauer",
      "dn": "Niederlauer",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09673147",
      "j": 2023,
      "n": "nordheim vor der rhön",
      "dn": "Nordheim vor der Rhön",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09673149",
      "j": 2023,
      "n": "oberelsbach",
      "dn": "Oberelsbach",
      "bl": 9,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "09673151",
      "j": 2023,
      "n": "oberstreu",
      "dn": "Oberstreu",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09673153",
      "j": 2023,
      "n": "ostheim vor der rhön",
      "dn": "Ostheim vor der Rhön",
      "bl": 9,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "09673156",
      "j": 2023,
      "n": "rödelmaier",
      "dn": "Rödelmaier",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09673160",
      "j": 2023,
      "n": "saal an der saale",
      "dn": "Saal an der Saale",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09673161",
      "j": 2023,
      "n": "salz",
      "dn": "Salz",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09673162",
      "j": 2023,
      "n": "sandberg",
      "dn": "Sandberg",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09673163",
      "j": 2023,
      "n": "schönau an der brend",
      "dn": "Schönau an der Brend",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09673167",
      "j": 2023,
      "n": "sondheim vor der rhön",
      "dn": "Sondheim vor der Rhön",
      "bl": 9,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "09673170",
      "j": 2023,
      "n": "stockheim",
      "dn": "Stockheim",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09673171",
      "j": 2023,
      "n": "strahlungen",
      "dn": "Strahlungen",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09673172",
      "j": 2023,
      "n": "sulzdorf an der lederhecke",
      "dn": "Sulzdorf an der Lederhecke",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09673173",
      "j": 2023,
      "n": "sulzfeld",
      "dn": "Sulzfeld",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09673174",
      "j": 2023,
      "n": "trappstadt",
      "dn": "Trappstadt",
      "bl": 9,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "09673175",
      "j": 2023,
      "n": "unsleben",
      "dn": "Unsleben",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09673182",
      "j": 2023,
      "n": "willmars",
      "dn": "Willmars",
      "bl": 9,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "09673183",
      "j": 2023,
      "n": "wollbach",
      "dn": "Wollbach",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09673184",
      "j": 2023,
      "n": "wülfershausen an der saale",
      "dn": "Wülfershausen an der Saale",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09673186",
      "j": 2023,
      "n": "burglauer",
      "dn": "Burglauer",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09674111",
      "j": 2025,
      "n": "aidhausen",
      "dn": "Aidhausen",
      "bl": 9,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "09674118",
      "j": 2023,
      "n": "breitbrunn (unterfranken)",
      "dn": "Breitbrunn (Unterfranken)",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09674120",
      "j": 2023,
      "n": "bundorf",
      "dn": "Bundorf",
      "bl": 9,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "09674121",
      "j": 2023,
      "n": "burgpreppach",
      "dn": "Burgpreppach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09674129",
      "j": 2023,
      "n": "ebelsbach",
      "dn": "Ebelsbach",
      "bl": 9,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "09674130",
      "j": 2023,
      "n": "ebern",
      "dn": "Ebern",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09674133",
      "j": 2023,
      "n": "eltmann",
      "dn": "Eltmann",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09674139",
      "j": 2023,
      "n": "gädheim",
      "dn": "Gädheim",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09674147",
      "j": 2023,
      "n": "haßfurt",
      "dn": "Haßfurt",
      "bl": 9,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "09674149",
      "j": 2023,
      "n": "hofheim in unterfranken",
      "dn": "Hofheim in Unterfranken",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09674153",
      "j": 2023,
      "n": "riedbach",
      "dn": "Riedbach",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09674159",
      "j": 2023,
      "n": "oberaurach",
      "dn": "Oberaurach",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09674160",
      "j": 2023,
      "n": "kirchlauter",
      "dn": "Kirchlauter",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09674163",
      "j": 2023,
      "n": "knetzgau",
      "dn": "Knetzgau",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09674164",
      "j": 2023,
      "n": "königsberg in bayern",
      "dn": "Königsberg in Bayern",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09674171",
      "j": 2023,
      "n": "maroldsweisach",
      "dn": "Maroldsweisach",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09674180",
      "j": 2023,
      "n": "theres",
      "dn": "Theres",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09674184",
      "j": 2023,
      "n": "pfarrweisach",
      "dn": "Pfarrweisach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09674187",
      "j": 2023,
      "n": "rauhenebrach",
      "dn": "Rauhenebrach",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09674190",
      "j": 2023,
      "n": "rentweinsdorf",
      "dn": "Rentweinsdorf",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09674195",
      "j": 2023,
      "n": "sand am main",
      "dn": "Sand am Main",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09674201",
      "j": 2023,
      "n": "stettfeld",
      "dn": "Stettfeld",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09674210",
      "j": 2023,
      "n": "untermerzbach",
      "dn": "Untermerzbach",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09674219",
      "j": 2023,
      "n": "wonfurt",
      "dn": "Wonfurt",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09674221",
      "j": 2023,
      "n": "zeil am main",
      "dn": "Zeil am Main",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09674223",
      "j": 2023,
      "n": "ermershausen",
      "dn": "Ermershausen",
      "bl": 9,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "09675111",
      "j": 2023,
      "n": "abtswind",
      "dn": "Abtswind",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09675112",
      "j": 2023,
      "n": "albertshofen",
      "dn": "Albertshofen",
      "bl": 9,
      "hs": 275.0,
      "mns": 1
    },
    {
      "a": "09675113",
      "j": 2023,
      "n": "biebelried",
      "dn": "Biebelried",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09675114",
      "j": 2023,
      "n": "buchbrunn",
      "dn": "Buchbrunn",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09675116",
      "j": 2023,
      "n": "castell",
      "dn": "Castell",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09675117",
      "j": 2023,
      "n": "dettelbach",
      "dn": "Dettelbach",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09675127",
      "j": 2023,
      "n": "geiselwind",
      "dn": "Geiselwind",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09675131",
      "j": 2023,
      "n": "großlangheim",
      "dn": "Großlangheim",
      "bl": 9,
      "hs": 260.0,
      "mns": 1
    },
    {
      "a": "09675139",
      "j": 2023,
      "n": "iphofen",
      "dn": "Iphofen",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09675141",
      "j": 2023,
      "n": "kitzingen",
      "dn": "Kitzingen",
      "bl": 9,
      "hs": 315.0,
      "mns": 2
    },
    {
      "a": "09675142",
      "j": 2023,
      "n": "kleinlangheim",
      "dn": "Kleinlangheim",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09675144",
      "j": 2023,
      "n": "mainbernheim",
      "dn": "Mainbernheim",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09675146",
      "j": 2023,
      "n": "mainstockheim",
      "dn": "Mainstockheim",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09675147",
      "j": 2023,
      "n": "marktbreit",
      "dn": "Marktbreit",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09675148",
      "j": 2023,
      "n": "markt einersheim",
      "dn": "Markt Einersheim",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09675149",
      "j": 2023,
      "n": "marktsteft",
      "dn": "Marktsteft",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09675150",
      "j": 2023,
      "n": "martinsheim",
      "dn": "Martinsheim",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09675155",
      "j": 2023,
      "n": "nordheim am main",
      "dn": "Nordheim am Main",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09675156",
      "j": 2023,
      "n": "obernbreit",
      "dn": "Obernbreit",
      "bl": 9,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "09675158",
      "j": 2023,
      "n": "prichsenstadt",
      "dn": "Prichsenstadt",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09675161",
      "j": 2023,
      "n": "rödelsee",
      "dn": "Rödelsee",
      "bl": 9,
      "hs": 345.0,
      "mns": 1
    },
    {
      "a": "09675162",
      "j": 2023,
      "n": "rüdenhausen",
      "dn": "Rüdenhausen",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09675165",
      "j": 2023,
      "n": "schwarzach am main",
      "dn": "Schwarzach am Main",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09675166",
      "j": 2023,
      "n": "segnitz",
      "dn": "Segnitz",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09675167",
      "j": 2023,
      "n": "seinsheim",
      "dn": "Seinsheim",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09675169",
      "j": 2023,
      "n": "sommerach",
      "dn": "Sommerach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09675170",
      "j": 2023,
      "n": "sulzfeld am main",
      "dn": "Sulzfeld am Main",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09675174",
      "j": 2023,
      "n": "volkach",
      "dn": "Volkach",
      "bl": 9,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "09675177",
      "j": 2023,
      "n": "wiesenbronn",
      "dn": "Wiesenbronn",
      "bl": 9,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "09675178",
      "j": 2023,
      "n": "wiesentheid",
      "dn": "Wiesentheid",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09675179",
      "j": 2023,
      "n": "willanzheim",
      "dn": "Willanzheim",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09676111",
      "j": 2023,
      "n": "altenbuch",
      "dn": "Altenbuch",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09676112",
      "j": 2023,
      "n": "amorbach",
      "dn": "Amorbach",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09676116",
      "j": 2023,
      "n": "bürgstadt",
      "dn": "Bürgstadt",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09676117",
      "j": 2023,
      "n": "collenberg",
      "dn": "Collenberg",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09676118",
      "j": 2023,
      "n": "dorfprozelten",
      "dn": "Dorfprozelten",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09676119",
      "j": 2023,
      "n": "eichenbühl",
      "dn": "Eichenbühl",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09676121",
      "j": 2023,
      "n": "elsenfeld",
      "dn": "Elsenfeld",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09676122",
      "j": 2023,
      "n": "erlenbach am main",
      "dn": "Erlenbach am Main",
      "bl": 9,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "09676123",
      "j": 2023,
      "n": "eschau",
      "dn": "Eschau",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09676124",
      "j": 2023,
      "n": "faulbach",
      "dn": "Faulbach",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09676125",
      "j": 2023,
      "n": "großheubach",
      "dn": "Großheubach",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09676126",
      "j": 2023,
      "n": "großwallstadt",
      "dn": "Großwallstadt",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09676128",
      "j": 2023,
      "n": "hausen",
      "dn": "Hausen",
      "bl": 9,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "09676131",
      "j": 2023,
      "n": "kirchzell",
      "dn": "Kirchzell",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09676132",
      "j": 2023,
      "n": "kleinheubach",
      "dn": "Kleinheubach",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09676133",
      "j": 2023,
      "n": "kleinwallstadt",
      "dn": "Kleinwallstadt",
      "bl": 9,
      "hs": 270.0,
      "mns": 1
    },
    {
      "a": "09676134",
      "j": 2023,
      "n": "klingenberg am main",
      "dn": "Klingenberg am Main",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09676135",
      "j": 2023,
      "n": "laudenbach",
      "dn": "Laudenbach",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09676136",
      "j": 2023,
      "n": "leidersbach",
      "dn": "Leidersbach",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09676139",
      "j": 2023,
      "n": "miltenberg",
      "dn": "Miltenberg",
      "bl": 9,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "09676140",
      "j": 2023,
      "n": "mömlingen",
      "dn": "Mömlingen",
      "bl": 9,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "09676141",
      "j": 2023,
      "n": "mönchberg",
      "dn": "Mönchberg",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09676143",
      "j": 2023,
      "n": "neunkirchen",
      "dn": "Neunkirchen",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09676144",
      "j": 2023,
      "n": "niedernberg",
      "dn": "Niedernberg",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09676145",
      "j": 2023,
      "n": "obernburg am main",
      "dn": "Obernburg am Main",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09676151",
      "j": 2023,
      "n": "röllbach",
      "dn": "Röllbach",
      "bl": 9,
      "hs": 270.0,
      "mns": 1
    },
    {
      "a": "09676153",
      "j": 2023,
      "n": "rüdenau",
      "dn": "Rüdenau",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09676156",
      "j": 2023,
      "n": "schneeberg",
      "dn": "Schneeberg",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09676158",
      "j": 2023,
      "n": "stadtprozelten",
      "dn": "Stadtprozelten",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09676160",
      "j": 2023,
      "n": "sulzbach am main",
      "dn": "Sulzbach am Main",
      "bl": 9,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "09676165",
      "j": 2023,
      "n": "weilbach",
      "dn": "Weilbach",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09676169",
      "j": 2023,
      "n": "wörth am main",
      "dn": "Wörth am Main",
      "bl": 9,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "09677114",
      "j": 2023,
      "n": "arnstein",
      "dn": "Arnstein",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09677116",
      "j": 2023,
      "n": "aura im sinngrund",
      "dn": "Aura im Sinngrund",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09677119",
      "j": 2023,
      "n": "birkenfeld",
      "dn": "Birkenfeld",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09677120",
      "j": 2023,
      "n": "bischbrunn",
      "dn": "Bischbrunn",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09677122",
      "j": 2023,
      "n": "burgsinn",
      "dn": "Burgsinn",
      "bl": 9,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "09677125",
      "j": 2023,
      "n": "erlenbach bei marktheidenfeld",
      "dn": "Erlenbach bei Marktheidenfeld",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09677126",
      "j": 2023,
      "n": "esselbach",
      "dn": "Esselbach",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09677127",
      "j": 2023,
      "n": "eußenheim",
      "dn": "Eußenheim",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09677128",
      "j": 2023,
      "n": "fellen",
      "dn": "Fellen",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09677129",
      "j": 2023,
      "n": "frammersbach",
      "dn": "Frammersbach",
      "bl": 9,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "09677131",
      "j": 2023,
      "n": "gemünden am main",
      "dn": "Gemünden am Main",
      "bl": 9,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "09677132",
      "j": 2023,
      "n": "gössenheim",
      "dn": "Gössenheim",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09677133",
      "j": 2023,
      "n": "gräfendorf",
      "dn": "Gräfendorf",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09677135",
      "j": 2023,
      "n": "hafenlohr",
      "dn": "Hafenlohr",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09677137",
      "j": 2023,
      "n": "hasloch",
      "dn": "Hasloch",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09677142",
      "j": 2023,
      "n": "himmelstadt",
      "dn": "Himmelstadt",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09677146",
      "j": 2023,
      "n": "karbach",
      "dn": "Karbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09677148",
      "j": 2023,
      "n": "karlstadt am main",
      "dn": "Karlstadt am Main",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09677149",
      "j": 2023,
      "n": "karsbach",
      "dn": "Karsbach",
      "bl": 9,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "09677151",
      "j": 2023,
      "n": "kreuzwertheim",
      "dn": "Kreuzwertheim",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09677154",
      "j": 2023,
      "n": "triefenstein",
      "dn": "Triefenstein",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09677155",
      "j": 2023,
      "n": "lohr am main",
      "dn": "Lohr am Main",
      "bl": 9,
      "hs": 500.0,
      "mns": 2
    },
    {
      "a": "09677157",
      "j": 2023,
      "n": "marktheidenfeld",
      "dn": "Marktheidenfeld",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09677159",
      "j": 2023,
      "n": "mittelsinn",
      "dn": "Mittelsinn",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09677164",
      "j": 2023,
      "n": "neuendorf",
      "dn": "Neuendorf",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09677165",
      "j": 2023,
      "n": "neuhütten",
      "dn": "Neuhütten",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09677166",
      "j": 2023,
      "n": "neustadt am main",
      "dn": "Neustadt am Main",
      "bl": 9,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "09677169",
      "j": 2023,
      "n": "obersinn",
      "dn": "Obersinn",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09677170",
      "j": 2023,
      "n": "partenstein",
      "dn": "Partenstein",
      "bl": 9,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "09677172",
      "j": 2023,
      "n": "rechtenbach",
      "dn": "Rechtenbach",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09677175",
      "j": 2023,
      "n": "retzstadt",
      "dn": "Retzstadt",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09677177",
      "j": 2023,
      "n": "rieneck",
      "dn": "Rieneck",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09677178",
      "j": 2023,
      "n": "roden",
      "dn": "Roden",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09677181",
      "j": 2023,
      "n": "rothenfels",
      "dn": "Rothenfels",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09677182",
      "j": 2023,
      "n": "schollbrunn",
      "dn": "Schollbrunn",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09677186",
      "j": 2023,
      "n": "steinfeld",
      "dn": "Steinfeld",
      "bl": 9,
      "hs": 275.0,
      "mns": 1
    },
    {
      "a": "09677189",
      "j": 2023,
      "n": "thüngen",
      "dn": "Thüngen",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09677193",
      "j": 2023,
      "n": "urspringen",
      "dn": "Urspringen",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09677200",
      "j": 2023,
      "n": "wiesthal",
      "dn": "Wiesthal",
      "bl": 9,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "09677203",
      "j": 2023,
      "n": "zellingen",
      "dn": "Zellingen",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09678115",
      "j": 2023,
      "n": "bergrheinfeld",
      "dn": "Bergrheinfeld",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09678122",
      "j": 2023,
      "n": "dingolshausen",
      "dn": "Dingolshausen",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09678123",
      "j": 2023,
      "n": "dittelbrunn",
      "dn": "Dittelbrunn",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09678124",
      "j": 2023,
      "n": "donnersdorf",
      "dn": "Donnersdorf",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09678128",
      "j": 2023,
      "n": "euerbach",
      "dn": "Euerbach",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09678130",
      "j": 2023,
      "n": "frankenwinheim",
      "dn": "Frankenwinheim",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09678132",
      "j": 2023,
      "n": "geldersheim",
      "dn": "Geldersheim",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09678134",
      "j": 2023,
      "n": "gerolzhofen",
      "dn": "Gerolzhofen",
      "bl": 9,
      "hs": 345.0,
      "mns": 1
    },
    {
      "a": "09678135",
      "j": 2023,
      "n": "gochsheim",
      "dn": "Gochsheim",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09678136",
      "j": 2023,
      "n": "grafenrheinfeld",
      "dn": "Grafenrheinfeld",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09678138",
      "j": 2023,
      "n": "grettstadt",
      "dn": "Grettstadt",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09678150",
      "j": 2023,
      "n": "kolitzheim",
      "dn": "Kolitzheim",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09678153",
      "j": 2023,
      "n": "lülsfeld",
      "dn": "Lülsfeld",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09678157",
      "j": 2023,
      "n": "michelau im steigerwald",
      "dn": "Michelau im Steigerwald",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09678160",
      "j": 2023,
      "n": "niederwerrn",
      "dn": "Niederwerrn",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09678164",
      "j": 2023,
      "n": "oberschwarzach",
      "dn": "Oberschwarzach",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09678168",
      "j": 2023,
      "n": "poppenhausen",
      "dn": "Poppenhausen",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09678170",
      "j": 2023,
      "n": "röthlein",
      "dn": "Röthlein",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09678174",
      "j": 2023,
      "n": "schonungen",
      "dn": "Schonungen",
      "bl": 9,
      "hs": 540.0,
      "mns": 1
    },
    {
      "a": "09678175",
      "j": 2023,
      "n": "schwanfeld",
      "dn": "Schwanfeld",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09678176",
      "j": 2023,
      "n": "schwebheim",
      "dn": "Schwebheim",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09678178",
      "j": 2023,
      "n": "sennfeld",
      "dn": "Sennfeld",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09678181",
      "j": 2023,
      "n": "stadtlauringen",
      "dn": "Stadtlauringen",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09678183",
      "j": 2023,
      "n": "sulzheim",
      "dn": "Sulzheim",
      "bl": 9,
      "hs": 297.0,
      "mns": 1
    },
    {
      "a": "09678186",
      "j": 2023,
      "n": "üchtelhausen",
      "dn": "Üchtelhausen",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09678190",
      "j": 2023,
      "n": "waigolshausen",
      "dn": "Waigolshausen",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09678192",
      "j": 2023,
      "n": "wasserlosen",
      "dn": "Wasserlosen",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09678193",
      "j": 2023,
      "n": "werneck",
      "dn": "Werneck",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09678196",
      "j": 2023,
      "n": "wipfeld",
      "dn": "Wipfeld",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09679114",
      "j": 2023,
      "n": "aub",
      "dn": "Aub",
      "bl": 9,
      "hs": 395.0,
      "mns": 2
    },
    {
      "a": "09679117",
      "j": 2023,
      "n": "bergtheim",
      "dn": "Bergtheim",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09679118",
      "j": 2023,
      "n": "bieberehren",
      "dn": "Bieberehren",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09679122",
      "j": 2023,
      "n": "bütthard",
      "dn": "Bütthard",
      "bl": 9,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "09679124",
      "j": 2025,
      "n": "eibelstadt",
      "dn": "Eibelstadt",
      "bl": 9,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "09679126",
      "j": 2023,
      "n": "eisingen",
      "dn": "Eisingen",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09679128",
      "j": 2023,
      "n": "erlabrunn",
      "dn": "Erlabrunn",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09679130",
      "j": 2023,
      "n": "estenfeld",
      "dn": "Estenfeld",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09679131",
      "j": 2023,
      "n": "frickenhausen am main",
      "dn": "Frickenhausen am Main",
      "bl": 9,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "09679134",
      "j": 2023,
      "n": "gaukönigshofen",
      "dn": "Gaukönigshofen",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09679135",
      "j": 2023,
      "n": "gelchsheim",
      "dn": "Gelchsheim",
      "bl": 9,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "09679136",
      "j": 2023,
      "n": "gerbrunn",
      "dn": "Gerbrunn",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09679137",
      "j": 2023,
      "n": "geroldshausen",
      "dn": "Geroldshausen",
      "bl": 9,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "09679138",
      "j": 2023,
      "n": "giebelstadt",
      "dn": "Giebelstadt",
      "bl": 9,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "09679141",
      "j": 2023,
      "n": "greußenheim",
      "dn": "Greußenheim",
      "bl": 9,
      "hs": 355.0,
      "mns": 2
    },
    {
      "a": "09679142",
      "j": 2023,
      "n": "güntersleben",
      "dn": "Güntersleben",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09679143",
      "j": 2023,
      "n": "hausen bei würzburg",
      "dn": "Hausen bei Würzburg",
      "bl": 9,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "09679144",
      "j": 2023,
      "n": "helmstadt",
      "dn": "Helmstadt",
      "bl": 9,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "09679146",
      "j": 2023,
      "n": "hettstadt",
      "dn": "Hettstadt",
      "bl": 9,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "09679147",
      "j": 2023,
      "n": "höchberg",
      "dn": "Höchberg",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09679149",
      "j": 2023,
      "n": "holzkirchen",
      "dn": "Holzkirchen",
      "bl": 9,
      "hs": 290.0,
      "mns": 2
    },
    {
      "a": "09679153",
      "j": 2023,
      "n": "kirchheim",
      "dn": "Kirchheim",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09679154",
      "j": 2023,
      "n": "kist",
      "dn": "Kist",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09679155",
      "j": 2023,
      "n": "kleinrinderfeld",
      "dn": "Kleinrinderfeld",
      "bl": 9,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "09679156",
      "j": 2023,
      "n": "kürnach",
      "dn": "Kürnach",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09679161",
      "j": 2023,
      "n": "margetshöchheim",
      "dn": "Margetshöchheim",
      "bl": 9,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "09679164",
      "j": 2023,
      "n": "neubrunn",
      "dn": "Neubrunn",
      "bl": 9,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "09679165",
      "j": 2023,
      "n": "altertheim",
      "dn": "Altertheim",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09679167",
      "j": 2023,
      "n": "eisenheim",
      "dn": "Eisenheim",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09679169",
      "j": 2023,
      "n": "oberpleichfeld",
      "dn": "Oberpleichfeld",
      "bl": 9,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "09679170",
      "j": 2023,
      "n": "ochsenfurt",
      "dn": "Ochsenfurt",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09679174",
      "j": 2023,
      "n": "prosselsheim",
      "dn": "Prosselsheim",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09679175",
      "j": 2023,
      "n": "randersacker",
      "dn": "Randersacker",
      "bl": 9,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "09679176",
      "j": 2023,
      "n": "reichenberg",
      "dn": "Reichenberg",
      "bl": 9,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "09679177",
      "j": 2023,
      "n": "remlingen",
      "dn": "Remlingen",
      "bl": 9,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "09679179",
      "j": 2023,
      "n": "riedenheim",
      "dn": "Riedenheim",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09679180",
      "j": 2023,
      "n": "rimpar",
      "dn": "Rimpar",
      "bl": 9,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "09679182",
      "j": 2023,
      "n": "röttingen",
      "dn": "Röttingen",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09679185",
      "j": 2023,
      "n": "rottendorf",
      "dn": "Rottendorf",
      "bl": 9,
      "hs": 275.0,
      "mns": 2
    },
    {
      "a": "09679187",
      "j": 2023,
      "n": "sommerhausen",
      "dn": "Sommerhausen",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09679188",
      "j": 2023,
      "n": "sonderhofen",
      "dn": "Sonderhofen",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09679192",
      "j": 2023,
      "n": "tauberrettersheim",
      "dn": "Tauberrettersheim",
      "bl": 9,
      "hs": 395.0,
      "mns": 2
    },
    {
      "a": "09679193",
      "j": 2023,
      "n": "theilheim",
      "dn": "Theilheim",
      "bl": 9,
      "hs": 395.0,
      "mns": 2
    },
    {
      "a": "09679194",
      "j": 2023,
      "n": "thüngersheim",
      "dn": "Thüngersheim",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09679196",
      "j": 2023,
      "n": "uettingen",
      "dn": "Uettingen",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09679200",
      "j": 2023,
      "n": "leinach",
      "dn": "Leinach",
      "bl": 9,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "09679201",
      "j": 2023,
      "n": "unterpleichfeld",
      "dn": "Unterpleichfeld",
      "bl": 9,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "09679202",
      "j": 2023,
      "n": "veitshöchheim",
      "dn": "Veitshöchheim",
      "bl": 9,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "09679204",
      "j": 2023,
      "n": "waldbrunn",
      "dn": "Waldbrunn",
      "bl": 9,
      "hs": 275.0,
      "mns": 2
    },
    {
      "a": "09679205",
      "j": 2023,
      "n": "waldbüttelbrunn",
      "dn": "Waldbüttelbrunn",
      "bl": 9,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "09679206",
      "j": 2023,
      "n": "winterhausen",
      "dn": "Winterhausen",
      "bl": 9,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "09679209",
      "j": 2023,
      "n": "zell am main",
      "dn": "Zell am Main",
      "bl": 9,
      "hs": 280.0,
      "mns": 2
    },
    {
      "a": "09761000",
      "j": 2023,
      "n": "augsburg",
      "dn": "Augsburg",
      "bl": 9,
      "hs": 555.0,
      "mns": 4
    },
    {
      "a": "09762000",
      "j": 2023,
      "n": "kaufbeuren",
      "dn": "Kaufbeuren",
      "bl": 9,
      "hs": 385.0,
      "mns": 3
    },
    {
      "a": "09763000",
      "j": 2023,
      "n": "kempten",
      "dn": "Kempten",
      "bl": 9,
      "hs": 420.0,
      "mns": 4
    },
    {
      "a": "09764000",
      "j": 2023,
      "n": "memmingen",
      "dn": "Memmingen",
      "bl": 9,
      "hs": 370.0,
      "mns": 3
    },
    {
      "a": "09771111",
      "j": 2023,
      "n": "adelzhausen",
      "dn": "Adelzhausen",
      "bl": 9,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "09771112",
      "j": 2023,
      "n": "affing",
      "dn": "Affing",
      "bl": 9,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "09771113",
      "j": 2023,
      "n": "aichach",
      "dn": "Aichach",
      "bl": 9,
      "hs": 320.0,
      "mns": 3
    },
    {
      "a": "09771114",
      "j": 2023,
      "n": "aindling",
      "dn": "Aindling",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09771122",
      "j": 2023,
      "n": "dasing",
      "dn": "Dasing",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09771129",
      "j": 2023,
      "n": "eurasburg",
      "dn": "Eurasburg",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09771130",
      "j": 2023,
      "n": "friedberg",
      "dn": "Friedberg",
      "bl": 9,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "09771140",
      "j": 2023,
      "n": "hollenbach",
      "dn": "Hollenbach",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09771141",
      "j": 2023,
      "n": "inchenhofen",
      "dn": "Inchenhofen",
      "bl": 9,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "09771142",
      "j": 2023,
      "n": "kissing",
      "dn": "Kissing",
      "bl": 9,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "09771144",
      "j": 2023,
      "n": "kühbach",
      "dn": "Kühbach",
      "bl": 9,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "09771145",
      "j": 2023,
      "n": "merching",
      "dn": "Merching",
      "bl": 9,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "09771146",
      "j": 2025,
      "n": "mering",
      "dn": "Mering",
      "bl": 9,
      "hs": 450.0,
      "mns": 4
    },
    {
      "a": "09771149",
      "j": 2023,
      "n": "obergriesbach",
      "dn": "Obergriesbach",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09771155",
      "j": 2023,
      "n": "petersdorf",
      "dn": "Petersdorf",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09771156",
      "j": 2023,
      "n": "pöttmes",
      "dn": "Pöttmes",
      "bl": 9,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "09771158",
      "j": 2023,
      "n": "rehling",
      "dn": "Rehling",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09771160",
      "j": 2023,
      "n": "ried",
      "dn": "Ried",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09771162",
      "j": 2023,
      "n": "schiltberg",
      "dn": "Schiltberg",
      "bl": 9,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "09771163",
      "j": 2023,
      "n": "schmiechen",
      "dn": "Schmiechen",
      "bl": 9,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "09771165",
      "j": 2023,
      "n": "sielenbach",
      "dn": "Sielenbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09771168",
      "j": 2023,
      "n": "steindorf",
      "dn": "Steindorf",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09771169",
      "j": 2023,
      "n": "todtenweis",
      "dn": "Todtenweis",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09771176",
      "j": 2023,
      "n": "baar",
      "dn": "Baar",
      "bl": 9,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "09772111",
      "j": 2023,
      "n": "adelsried",
      "dn": "Adelsried",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09772114",
      "j": 2023,
      "n": "allmannshofen",
      "dn": "Allmannshofen",
      "bl": 9,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "09772115",
      "j": 2023,
      "n": "altenmünster",
      "dn": "Altenmünster",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09772117",
      "j": 2023,
      "n": "aystetten",
      "dn": "Aystetten",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09772121",
      "j": 2023,
      "n": "biberbach",
      "dn": "Biberbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09772125",
      "j": 2023,
      "n": "bobingen",
      "dn": "Bobingen",
      "bl": 9,
      "hs": 390.0,
      "mns": 4
    },
    {
      "a": "09772126",
      "j": 2023,
      "n": "bonstetten",
      "dn": "Bonstetten",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09772130",
      "j": 2023,
      "n": "diedorf",
      "dn": "Diedorf",
      "bl": 9,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "09772131",
      "j": 2023,
      "n": "dinkelscherben",
      "dn": "Dinkelscherben",
      "bl": 9,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "09772134",
      "j": 2023,
      "n": "ehingen",
      "dn": "Ehingen",
      "bl": 9,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "09772136",
      "j": 2023,
      "n": "ellgau",
      "dn": "Ellgau",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09772137",
      "j": 2023,
      "n": "emersacker",
      "dn": "Emersacker",
      "bl": 9,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "09772141",
      "j": 2023,
      "n": "fischach",
      "dn": "Fischach",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09772145",
      "j": 2023,
      "n": "gablingen",
      "dn": "Gablingen",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09772147",
      "j": 2023,
      "n": "gersthofen",
      "dn": "Gersthofen",
      "bl": 9,
      "hs": 310.0,
      "mns": 3
    },
    {
      "a": "09772148",
      "j": 2023,
      "n": "gessertshausen",
      "dn": "Gessertshausen",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09772149",
      "j": 2023,
      "n": "graben",
      "dn": "Graben",
      "bl": 9,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "09772151",
      "j": 2025,
      "n": "großaitingen",
      "dn": "Großaitingen",
      "bl": 9,
      "hs": 210.0,
      "mns": 2
    },
    {
      "a": "09772156",
      "j": 2023,
      "n": "heretsried",
      "dn": "Heretsried",
      "bl": 9,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "09772157",
      "j": 2023,
      "n": "hiltenfingen",
      "dn": "Hiltenfingen",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09772159",
      "j": 2023,
      "n": "horgau",
      "dn": "Horgau",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09772160",
      "j": 2023,
      "n": "kleinaitingen",
      "dn": "Kleinaitingen",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09772162",
      "j": 2023,
      "n": "klosterlechfeld",
      "dn": "Klosterlechfeld",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09772163",
      "j": 2023,
      "n": "königsbrunn",
      "dn": "Königsbrunn",
      "bl": 9,
      "hs": 320.0,
      "mns": 4
    },
    {
      "a": "09772166",
      "j": 2023,
      "n": "kühlenthal",
      "dn": "Kühlenthal",
      "bl": 9,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "09772167",
      "j": 2023,
      "n": "kutzenhausen",
      "dn": "Kutzenhausen",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09772168",
      "j": 2023,
      "n": "langenneufnach",
      "dn": "Langenneufnach",
      "bl": 9,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "09772170",
      "j": 2023,
      "n": "langerringen",
      "dn": "Langerringen",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09772171",
      "j": 2023,
      "n": "langweid am lech",
      "dn": "Langweid am Lech",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09772177",
      "j": 2023,
      "n": "meitingen",
      "dn": "Meitingen",
      "bl": 9,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "09772178",
      "j": 2023,
      "n": "mickhausen",
      "dn": "Mickhausen",
      "bl": 9,
      "hs": 500.0,
      "mns": 2
    },
    {
      "a": "09772179",
      "j": 2023,
      "n": "mittelneufnach",
      "dn": "Mittelneufnach",
      "bl": 9,
      "hs": 500.0,
      "mns": 2
    },
    {
      "a": "09772184",
      "j": 2023,
      "n": "neusäß",
      "dn": "Neusäß",
      "bl": 9,
      "hs": 310.0,
      "mns": 4
    },
    {
      "a": "09772185",
      "j": 2023,
      "n": "nordendorf",
      "dn": "Nordendorf",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09772186",
      "j": 2023,
      "n": "oberottmarshausen",
      "dn": "Oberottmarshausen",
      "bl": 9,
      "hs": 290.0,
      "mns": 2
    },
    {
      "a": "09772197",
      "j": 2023,
      "n": "scherstetten",
      "dn": "Scherstetten",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09772200",
      "j": 2023,
      "n": "schwabmünchen",
      "dn": "Schwabmünchen",
      "bl": 9,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "09772202",
      "j": 2023,
      "n": "stadtbergen",
      "dn": "Stadtbergen",
      "bl": 9,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "09772207",
      "j": 2023,
      "n": "thierhaupten",
      "dn": "Thierhaupten",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09772209",
      "j": 2023,
      "n": "untermeitingen",
      "dn": "Untermeitingen",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09772211",
      "j": 2023,
      "n": "ustersbach",
      "dn": "Ustersbach",
      "bl": 9,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "09772214",
      "j": 2023,
      "n": "walkertshofen",
      "dn": "Walkertshofen",
      "bl": 9,
      "hs": 500.0,
      "mns": 2
    },
    {
      "a": "09772215",
      "j": 2023,
      "n": "wehringen",
      "dn": "Wehringen",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09772216",
      "j": 2023,
      "n": "welden",
      "dn": "Welden",
      "bl": 9,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "09772217",
      "j": 2023,
      "n": "westendorf",
      "dn": "Westendorf",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09772223",
      "j": 2023,
      "n": "zusmarshausen",
      "dn": "Zusmarshausen",
      "bl": 9,
      "hs": 345.0,
      "mns": 2
    },
    {
      "a": "09773111",
      "j": 2023,
      "n": "aislingen",
      "dn": "Aislingen",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09773112",
      "j": 2023,
      "n": "bachhagel",
      "dn": "Bachhagel",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09773113",
      "j": 2023,
      "n": "bächingen an der brenz",
      "dn": "Bächingen an der Brenz",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09773116",
      "j": 2023,
      "n": "binswangen",
      "dn": "Binswangen",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09773117",
      "j": 2023,
      "n": "bissingen",
      "dn": "Bissingen",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09773119",
      "j": 2023,
      "n": "blindheim",
      "dn": "Blindheim",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09773122",
      "j": 2023,
      "n": "buttenwiesen",
      "dn": "Buttenwiesen",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09773125",
      "j": 2023,
      "n": "dillingen an der donau",
      "dn": "Dillingen an der Donau",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09773133",
      "j": 2023,
      "n": "glött",
      "dn": "Glött",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09773136",
      "j": 2023,
      "n": "gundelfingen an der donau",
      "dn": "Gundelfingen an der Donau",
      "bl": 9,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "09773137",
      "j": 2023,
      "n": "haunsheim",
      "dn": "Haunsheim",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09773139",
      "j": 2023,
      "n": "höchstädt an der donau",
      "dn": "Höchstädt an der Donau",
      "bl": 9,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "09773140",
      "j": 2023,
      "n": "holzheim",
      "dn": "Holzheim",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09773143",
      "j": 2023,
      "n": "laugna",
      "dn": "Laugna",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09773144",
      "j": 2023,
      "n": "lauingen",
      "dn": "Lauingen",
      "bl": 9,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "09773146",
      "j": 2023,
      "n": "lutzingen",
      "dn": "Lutzingen",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09773147",
      "j": 2023,
      "n": "mödingen",
      "dn": "Mödingen",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09773150",
      "j": 2023,
      "n": "finningen",
      "dn": "Finningen",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09773153",
      "j": 2023,
      "n": "medlingen",
      "dn": "Medlingen",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09773164",
      "j": 2023,
      "n": "schwenningen",
      "dn": "Schwenningen",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09773170",
      "j": 2023,
      "n": "syrgenstein",
      "dn": "Syrgenstein",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09773179",
      "j": 2023,
      "n": "villenbach",
      "dn": "Villenbach",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09773182",
      "j": 2023,
      "n": "wertingen",
      "dn": "Wertingen",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09773183",
      "j": 2023,
      "n": "wittislingen",
      "dn": "Wittislingen",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09773186",
      "j": 2023,
      "n": "ziertheim",
      "dn": "Ziertheim",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09773187",
      "j": 2023,
      "n": "zöschingen",
      "dn": "Zöschingen",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09773188",
      "j": 2023,
      "n": "zusamaltheim",
      "dn": "Zusamaltheim",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09774111",
      "j": 2023,
      "n": "aletshausen",
      "dn": "Aletshausen",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09774115",
      "j": 2023,
      "n": "balzhausen",
      "dn": "Balzhausen",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09774116",
      "j": 2023,
      "n": "ursberg",
      "dn": "Ursberg",
      "bl": 9,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "09774117",
      "j": 2023,
      "n": "breitenthal",
      "dn": "Breitenthal",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09774118",
      "j": 2023,
      "n": "bubesheim",
      "dn": "Bubesheim",
      "bl": 9,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "09774119",
      "j": 2023,
      "n": "bibertal",
      "dn": "Bibertal",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09774121",
      "j": 2025,
      "n": "burgau",
      "dn": "Burgau",
      "bl": 9,
      "hs": 280.0,
      "mns": 2
    },
    {
      "a": "09774122",
      "j": 2023,
      "n": "burtenbach",
      "dn": "Burtenbach",
      "bl": 9,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "09774124",
      "j": 2023,
      "n": "deisenhausen",
      "dn": "Deisenhausen",
      "bl": 9,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "09774127",
      "j": 2023,
      "n": "dürrlauingen",
      "dn": "Dürrlauingen",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09774129",
      "j": 2023,
      "n": "ebershausen",
      "dn": "Ebershausen",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09774133",
      "j": 2023,
      "n": "ellzee",
      "dn": "Ellzee",
      "bl": 9,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "09774135",
      "j": 2023,
      "n": "günzburg",
      "dn": "Günzburg",
      "bl": 9,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "09774136",
      "j": 2023,
      "n": "gundremmingen",
      "dn": "Gundremmingen",
      "bl": 9,
      "hs": 150.0,
      "mns": 2
    },
    {
      "a": "09774140",
      "j": 2023,
      "n": "haldenwang",
      "dn": "Haldenwang",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09774143",
      "j": 2023,
      "n": "ichenhausen",
      "dn": "Ichenhausen",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09774144",
      "j": 2023,
      "n": "jettingen-scheppach",
      "dn": "Jettingen-Scheppach",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09774145",
      "j": 2023,
      "n": "kammeltal",
      "dn": "Kammeltal",
      "bl": 9,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "09774148",
      "j": 2023,
      "n": "kötz",
      "dn": "Kötz",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09774150",
      "j": 2023,
      "n": "krumbach",
      "dn": "Krumbach",
      "bl": 9,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "09774151",
      "j": 2023,
      "n": "landensberg",
      "dn": "Landensberg",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09774155",
      "j": 2023,
      "n": "leipheim",
      "dn": "Leipheim",
      "bl": 9,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "09774160",
      "j": 2023,
      "n": "münsterhausen",
      "dn": "Münsterhausen",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09774162",
      "j": 2023,
      "n": "neuburg an der kammel",
      "dn": "Neuburg an der Kammel",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09774166",
      "j": 2023,
      "n": "aichen",
      "dn": "Aichen",
      "bl": 9,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "09774171",
      "j": 2023,
      "n": "offingen",
      "dn": "Offingen",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09774174",
      "j": 2023,
      "n": "rettenbach",
      "dn": "Rettenbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09774178",
      "j": 2023,
      "n": "röfingen",
      "dn": "Röfingen",
      "bl": 9,
      "hs": 315.0,
      "mns": 2
    },
    {
      "a": "09774185",
      "j": 2023,
      "n": "thannhausen",
      "dn": "Thannhausen",
      "bl": 9,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "09774189",
      "j": 2023,
      "n": "wiesenbach",
      "dn": "Wiesenbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09774191",
      "j": 2023,
      "n": "waldstetten",
      "dn": "Waldstetten",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09774192",
      "j": 2023,
      "n": "waltenhausen",
      "dn": "Waltenhausen",
      "bl": 9,
      "hs": 375.0,
      "mns": 2
    },
    {
      "a": "09774196",
      "j": 2023,
      "n": "winterbach",
      "dn": "Winterbach",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09774198",
      "j": 2023,
      "n": "ziemetshausen",
      "dn": "Ziemetshausen",
      "bl": 9,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "09775111",
      "j": 2023,
      "n": "altenstadt",
      "dn": "Altenstadt",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09775115",
      "j": 2023,
      "n": "bellenberg",
      "dn": "Bellenberg",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09775118",
      "j": 2023,
      "n": "buch",
      "dn": "Buch",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09775126",
      "j": 2023,
      "n": "holzheim",
      "dn": "Holzheim",
      "bl": 9,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "09775129",
      "j": 2023,
      "n": "illertissen",
      "dn": "Illertissen",
      "bl": 9,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "09775132",
      "j": 2023,
      "n": "kellmünz an der iller",
      "dn": "Kellmünz an der Iller",
      "bl": 9,
      "hs": 375.0,
      "mns": 2
    },
    {
      "a": "09775134",
      "j": 2023,
      "n": "nersingen",
      "dn": "Nersingen",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09775135",
      "j": 2023,
      "n": "neu-ulm",
      "dn": "Neu-Ulm",
      "bl": 9,
      "hs": 375.0,
      "mns": 4
    },
    {
      "a": "09775139",
      "j": 2023,
      "n": "elchingen",
      "dn": "Elchingen",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09775141",
      "j": 2023,
      "n": "oberroth",
      "dn": "Oberroth",
      "bl": 9,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "09775142",
      "j": 2023,
      "n": "osterberg",
      "dn": "Osterberg",
      "bl": 9,
      "hs": 395.0,
      "mns": 2
    },
    {
      "a": "09775143",
      "j": 2023,
      "n": "pfaffenhofen an der roth",
      "dn": "Pfaffenhofen an der Roth",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09775149",
      "j": 2023,
      "n": "roggenburg",
      "dn": "Roggenburg",
      "bl": 9,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "09775152",
      "j": 2023,
      "n": "senden",
      "dn": "Senden",
      "bl": 9,
      "hs": 380.0,
      "mns": 4
    },
    {
      "a": "09775161",
      "j": 2023,
      "n": "unterroth",
      "dn": "Unterroth",
      "bl": 9,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "09775162",
      "j": 2023,
      "n": "vöhringen",
      "dn": "Vöhringen",
      "bl": 9,
      "hs": 340.0,
      "mns": 3
    },
    {
      "a": "09775164",
      "j": 2023,
      "n": "weißenhorn",
      "dn": "Weißenhorn",
      "bl": 9,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "09776111",
      "j": 2023,
      "n": "bodolz",
      "dn": "Bodolz",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09776112",
      "j": 2023,
      "n": "gestratz",
      "dn": "Gestratz",
      "bl": 9,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "09776113",
      "j": 2023,
      "n": "grünenbach",
      "dn": "Grünenbach",
      "bl": 9,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "09776114",
      "j": 2023,
      "n": "heimenkirch",
      "dn": "Heimenkirch",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09776115",
      "j": 2023,
      "n": "hergensweiler",
      "dn": "Hergensweiler",
      "bl": 9,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "09776116",
      "j": 2025,
      "n": "lindau",
      "dn": "Lindau",
      "bl": 9,
      "hs": 530.0,
      "mns": 5
    },
    {
      "a": "09776117",
      "j": 2023,
      "n": "lindenberg im allgäu",
      "dn": "Lindenberg im Allgäu",
      "bl": 9,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "09776118",
      "j": 2023,
      "n": "maierhöfen",
      "dn": "Maierhöfen",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09776120",
      "j": 2023,
      "n": "nonnenhorn",
      "dn": "Nonnenhorn",
      "bl": 9,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "09776121",
      "j": 2023,
      "n": "oberreute",
      "dn": "Oberreute",
      "bl": 9,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "09776122",
      "j": 2023,
      "n": "opfenbach",
      "dn": "Opfenbach",
      "bl": 9,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "09776124",
      "j": 2023,
      "n": "röthenbach",
      "dn": "Röthenbach",
      "bl": 9,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "09776125",
      "j": 2023,
      "n": "scheidegg",
      "dn": "Scheidegg",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09776126",
      "j": 2023,
      "n": "sigmarszell",
      "dn": "Sigmarszell",
      "bl": 9,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "09776127",
      "j": 2023,
      "n": "stiefenhofen",
      "dn": "Stiefenhofen",
      "bl": 9,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "09776128",
      "j": 2023,
      "n": "wasserburg",
      "dn": "Wasserburg",
      "bl": 9,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "09776129",
      "j": 2023,
      "n": "weiler-simmerberg",
      "dn": "Weiler-Simmerberg",
      "bl": 9,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "09776130",
      "j": 2023,
      "n": "weißensberg",
      "dn": "Weißensberg",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09776131",
      "j": 2023,
      "n": "hergatz",
      "dn": "Hergatz",
      "bl": 9,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "09777111",
      "j": 2023,
      "n": "aitrang",
      "dn": "Aitrang",
      "bl": 9,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "09777112",
      "j": 2023,
      "n": "biessenhofen",
      "dn": "Biessenhofen",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09777114",
      "j": 2023,
      "n": "baisweil",
      "dn": "Baisweil",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09777118",
      "j": 2023,
      "n": "bidingen",
      "dn": "Bidingen",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09777121",
      "j": 2023,
      "n": "buchloe",
      "dn": "Buchloe",
      "bl": 9,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "09777124",
      "j": 2023,
      "n": "eggenthal",
      "dn": "Eggenthal",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09777125",
      "j": 2023,
      "n": "eisenberg",
      "dn": "Eisenberg",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09777128",
      "j": 2023,
      "n": "friesenried",
      "dn": "Friesenried",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09777129",
      "j": 2025,
      "n": "füssen",
      "dn": "Füssen",
      "bl": 9,
      "hs": 475.0,
      "mns": 3
    },
    {
      "a": "09777130",
      "j": 2023,
      "n": "germaringen",
      "dn": "Germaringen",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09777131",
      "j": 2023,
      "n": "görisried",
      "dn": "Görisried",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09777135",
      "j": 2023,
      "n": "hopferau",
      "dn": "Hopferau",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09777138",
      "j": 2023,
      "n": "günzach",
      "dn": "Günzach",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09777139",
      "j": 2023,
      "n": "irsee",
      "dn": "Irsee",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09777140",
      "j": 2023,
      "n": "jengen",
      "dn": "Jengen",
      "bl": 9,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "09777141",
      "j": 2023,
      "n": "kaltental",
      "dn": "Kaltental",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09777144",
      "j": 2023,
      "n": "kraftisried",
      "dn": "Kraftisried",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09777145",
      "j": 2023,
      "n": "lamerdingen",
      "dn": "Lamerdingen",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09777147",
      "j": 2023,
      "n": "lechbruck am see",
      "dn": "Lechbruck am See",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09777149",
      "j": 2023,
      "n": "lengenwang",
      "dn": "Lengenwang",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09777151",
      "j": 2023,
      "n": "marktoberdorf",
      "dn": "Marktoberdorf",
      "bl": 9,
      "hs": 375.0,
      "mns": 2
    },
    {
      "a": "09777152",
      "j": 2023,
      "n": "mauerstetten",
      "dn": "Mauerstetten",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09777153",
      "j": 2023,
      "n": "nesselwang",
      "dn": "Nesselwang",
      "bl": 9,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "09777154",
      "j": 2023,
      "n": "obergünzburg",
      "dn": "Obergünzburg",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09777155",
      "j": 2023,
      "n": "oberostendorf",
      "dn": "Oberostendorf",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09777157",
      "j": 2023,
      "n": "osterzell",
      "dn": "Osterzell",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09777158",
      "j": 2023,
      "n": "pforzen",
      "dn": "Pforzen",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09777159",
      "j": 2025,
      "n": "pfronten",
      "dn": "Pfronten",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09777163",
      "j": 2023,
      "n": "rieden am forggensee",
      "dn": "Rieden am Forggensee",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09777164",
      "j": 2023,
      "n": "rieden",
      "dn": "Rieden",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09777165",
      "j": 2023,
      "n": "ronsberg",
      "dn": "Ronsberg",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09777166",
      "j": 2023,
      "n": "roßhaupten",
      "dn": "Roßhaupten",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09777167",
      "j": 2023,
      "n": "ruderatshofen",
      "dn": "Ruderatshofen",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09777168",
      "j": 2023,
      "n": "rückholz",
      "dn": "Rückholz",
      "bl": 9,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "09777169",
      "j": 2023,
      "n": "schwangau",
      "dn": "Schwangau",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09777170",
      "j": 2023,
      "n": "seeg",
      "dn": "Seeg",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09777171",
      "j": 2023,
      "n": "stötten am auerberg",
      "dn": "Stötten am Auerberg",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09777172",
      "j": 2023,
      "n": "stöttwang",
      "dn": "Stöttwang",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09777173",
      "j": 2023,
      "n": "halblech",
      "dn": "Halblech",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09777175",
      "j": 2023,
      "n": "unterthingau",
      "dn": "Unterthingau",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09777176",
      "j": 2023,
      "n": "untrasried",
      "dn": "Untrasried",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09777177",
      "j": 2023,
      "n": "waal",
      "dn": "Waal",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09777179",
      "j": 2023,
      "n": "wald",
      "dn": "Wald",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09777182",
      "j": 2023,
      "n": "westendorf",
      "dn": "Westendorf",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09777183",
      "j": 2023,
      "n": "rettenbach am auerberg",
      "dn": "Rettenbach am Auerberg",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09778111",
      "j": 2023,
      "n": "amberg",
      "dn": "Amberg",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09778113",
      "j": 2023,
      "n": "apfeltrach",
      "dn": "Apfeltrach",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09778115",
      "j": 2023,
      "n": "babenhausen",
      "dn": "Babenhausen",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09778116",
      "j": 2023,
      "n": "bad wörishofen",
      "dn": "Bad Wörishofen",
      "bl": 9,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "09778118",
      "j": 2023,
      "n": "benningen",
      "dn": "Benningen",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09778119",
      "j": 2023,
      "n": "böhen",
      "dn": "Böhen",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09778120",
      "j": 2023,
      "n": "boos",
      "dn": "Boos",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09778121",
      "j": 2023,
      "n": "breitenbrunn",
      "dn": "Breitenbrunn",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09778123",
      "j": 2023,
      "n": "buxheim",
      "dn": "Buxheim",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09778127",
      "j": 2023,
      "n": "dirlewang",
      "dn": "Dirlewang",
      "bl": 9,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "09778130",
      "j": 2023,
      "n": "egg an der günz",
      "dn": "Egg an der Günz",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09778134",
      "j": 2023,
      "n": "eppishausen",
      "dn": "Eppishausen",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09778136",
      "j": 2023,
      "n": "erkheim",
      "dn": "Erkheim",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09778137",
      "j": 2023,
      "n": "ettringen",
      "dn": "Ettringen",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09778139",
      "j": 2023,
      "n": "fellheim",
      "dn": "Fellheim",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09778144",
      "j": 2023,
      "n": "bad grönenbach",
      "dn": "Bad Grönenbach",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09778149",
      "j": 2023,
      "n": "hawangen",
      "dn": "Hawangen",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09778150",
      "j": 2023,
      "n": "heimertingen",
      "dn": "Heimertingen",
      "bl": 9,
      "hs": 280.0,
      "mns": 1
    },
    {
      "a": "09778151",
      "j": 2023,
      "n": "holzgünz",
      "dn": "Holzgünz",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09778157",
      "j": 2023,
      "n": "kirchhaslach",
      "dn": "Kirchhaslach",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09778158",
      "j": 2023,
      "n": "kirchheim in schwaben",
      "dn": "Kirchheim in Schwaben",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09778161",
      "j": 2023,
      "n": "kronburg",
      "dn": "Kronburg",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09778162",
      "j": 2023,
      "n": "lachen",
      "dn": "Lachen",
      "bl": 9,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "09778163",
      "j": 2023,
      "n": "lauben",
      "dn": "Lauben",
      "bl": 9,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "09778164",
      "j": 2023,
      "n": "lautrach",
      "dn": "Lautrach",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09778165",
      "j": 2023,
      "n": "legau",
      "dn": "Legau",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09778168",
      "j": 2023,
      "n": "markt rettenbach",
      "dn": "Markt Rettenbach",
      "bl": 9,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "09778169",
      "j": 2023,
      "n": "markt wald",
      "dn": "Markt Wald",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09778171",
      "j": 2023,
      "n": "memmingerberg",
      "dn": "Memmingerberg",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09778173",
      "j": 2023,
      "n": "mindelheim",
      "dn": "Mindelheim",
      "bl": 9,
      "hs": 335.0,
      "mns": 2
    },
    {
      "a": "09778177",
      "j": 2023,
      "n": "niederrieden",
      "dn": "Niederrieden",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09778180",
      "j": 2023,
      "n": "kammlach",
      "dn": "Kammlach",
      "bl": 9,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "09778183",
      "j": 2023,
      "n": "oberrieden",
      "dn": "Oberrieden",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09778184",
      "j": 2023,
      "n": "oberschönegg",
      "dn": "Oberschönegg",
      "bl": 9,
      "hs": 295.0,
      "mns": 1
    },
    {
      "a": "09778186",
      "j": 2023,
      "n": "ottobeuren",
      "dn": "Ottobeuren",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09778187",
      "j": 2023,
      "n": "pfaffenhausen",
      "dn": "Pfaffenhausen",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09778188",
      "j": 2023,
      "n": "pleß",
      "dn": "Pleß",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09778190",
      "j": 2023,
      "n": "salgen",
      "dn": "Salgen",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09778196",
      "j": 2023,
      "n": "sontheim",
      "dn": "Sontheim",
      "bl": 9,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "09778199",
      "j": 2023,
      "n": "stetten",
      "dn": "Stetten",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09778202",
      "j": 2023,
      "n": "trunkelsberg",
      "dn": "Trunkelsberg",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09778203",
      "j": 2023,
      "n": "türkheim",
      "dn": "Türkheim",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09778204",
      "j": 2023,
      "n": "tussenhausen",
      "dn": "Tussenhausen",
      "bl": 9,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "09778205",
      "j": 2023,
      "n": "ungerhausen",
      "dn": "Ungerhausen",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09778207",
      "j": 2023,
      "n": "unteregg",
      "dn": "Unteregg",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09778209",
      "j": 2023,
      "n": "rammingen",
      "dn": "Rammingen",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09778214",
      "j": 2023,
      "n": "westerheim",
      "dn": "Westerheim",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09778216",
      "j": 2023,
      "n": "wiedergeltingen",
      "dn": "Wiedergeltingen",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09778217",
      "j": 2023,
      "n": "winterrieden",
      "dn": "Winterrieden",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09778218",
      "j": 2023,
      "n": "wolfertschwenden",
      "dn": "Wolfertschwenden",
      "bl": 9,
      "hs": 230.0,
      "mns": 1
    },
    {
      "a": "09778219",
      "j": 2023,
      "n": "woringen",
      "dn": "Woringen",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09778221",
      "j": 2023,
      "n": "kettershausen",
      "dn": "Kettershausen",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09779111",
      "j": 2023,
      "n": "alerheim",
      "dn": "Alerheim",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09779112",
      "j": 2023,
      "n": "amerdingen",
      "dn": "Amerdingen",
      "bl": 9,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "09779115",
      "j": 2023,
      "n": "asbach-bäumenheim",
      "dn": "Asbach-Bäumenheim",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09779117",
      "j": 2023,
      "n": "auhausen",
      "dn": "Auhausen",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09779126",
      "j": 2023,
      "n": "buchdorf",
      "dn": "Buchdorf",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09779129",
      "j": 2023,
      "n": "daiting",
      "dn": "Daiting",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09779130",
      "j": 2023,
      "n": "deiningen",
      "dn": "Deiningen",
      "bl": 9,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "09779131",
      "j": 2023,
      "n": "donauwörth",
      "dn": "Donauwörth",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09779136",
      "j": 2025,
      "n": "ederheim",
      "dn": "Ederheim",
      "bl": 9,
      "hs": 230.0,
      "mns": 1
    },
    {
      "a": "09779138",
      "j": 2023,
      "n": "ehingen am ries",
      "dn": "Ehingen am Ries",
      "bl": 9,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "09779146",
      "j": 2023,
      "n": "forheim",
      "dn": "Forheim",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09779147",
      "j": 2023,
      "n": "fremdingen",
      "dn": "Fremdingen",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09779148",
      "j": 2023,
      "n": "fünfstetten",
      "dn": "Fünfstetten",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09779149",
      "j": 2023,
      "n": "genderkingen",
      "dn": "Genderkingen",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09779154",
      "j": 2025,
      "n": "hainsfarth",
      "dn": "Hainsfarth",
      "bl": 9,
      "hs": 235.0,
      "mns": 1
    },
    {
      "a": "09779155",
      "j": 2023,
      "n": "harburg",
      "dn": "Harburg",
      "bl": 9,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "09779162",
      "j": 2023,
      "n": "hohenaltheim",
      "dn": "Hohenaltheim",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09779163",
      "j": 2023,
      "n": "holzheim",
      "dn": "Holzheim",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09779167",
      "j": 2023,
      "n": "huisheim",
      "dn": "Huisheim",
      "bl": 9,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "09779169",
      "j": 2023,
      "n": "kaisheim",
      "dn": "Kaisheim",
      "bl": 9,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "09779176",
      "j": 2023,
      "n": "maihingen",
      "dn": "Maihingen",
      "bl": 9,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "09779177",
      "j": 2023,
      "n": "marktoffingen",
      "dn": "Marktoffingen",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09779178",
      "j": 2023,
      "n": "marxheim",
      "dn": "Marxheim",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09779180",
      "j": 2023,
      "n": "megesheim",
      "dn": "Megesheim",
      "bl": 9,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "09779181",
      "j": 2023,
      "n": "mertingen",
      "dn": "Mertingen",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09779184",
      "j": 2023,
      "n": "mönchsdeggingen",
      "dn": "Mönchsdeggingen",
      "bl": 9,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "09779185",
      "j": 2023,
      "n": "möttingen",
      "dn": "Möttingen",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09779186",
      "j": 2023,
      "n": "monheim",
      "dn": "Monheim",
      "bl": 9,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "09779187",
      "j": 2023,
      "n": "münster",
      "dn": "Münster",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09779188",
      "j": 2023,
      "n": "munningen",
      "dn": "Munningen",
      "bl": 9,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "09779192",
      "j": 2023,
      "n": "niederschönenfeld",
      "dn": "Niederschönenfeld",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09779194",
      "j": 2023,
      "n": "nördlingen",
      "dn": "Nördlingen",
      "bl": 9,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "09779196",
      "j": 2023,
      "n": "oberndorf am lech",
      "dn": "Oberndorf am Lech",
      "bl": 9,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "09779197",
      "j": 2023,
      "n": "oettingen in bayern",
      "dn": "Oettingen in Bayern",
      "bl": 9,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "09779198",
      "j": 2023,
      "n": "otting",
      "dn": "Otting",
      "bl": 9,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "09779201",
      "j": 2023,
      "n": "rain",
      "dn": "Rain",
      "bl": 9,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "09779203",
      "j": 2023,
      "n": "reimlingen",
      "dn": "Reimlingen",
      "bl": 9,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "09779206",
      "j": 2023,
      "n": "rögling",
      "dn": "Rögling",
      "bl": 9,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "09779217",
      "j": 2023,
      "n": "tagmersheim",
      "dn": "Tagmersheim",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09779218",
      "j": 2023,
      "n": "tapfheim",
      "dn": "Tapfheim",
      "bl": 9,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "09779224",
      "j": 2023,
      "n": "wallerstein",
      "dn": "Wallerstein",
      "bl": 9,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "09779226",
      "j": 2023,
      "n": "wechingen",
      "dn": "Wechingen",
      "bl": 9,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "09779228",
      "j": 2023,
      "n": "wemding",
      "dn": "Wemding",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09779231",
      "j": 2023,
      "n": "wolferstadt",
      "dn": "Wolferstadt",
      "bl": 9,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "09780112",
      "j": 2023,
      "n": "altusried",
      "dn": "Altusried",
      "bl": 9,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "09780113",
      "j": 2023,
      "n": "balderschwang",
      "dn": "Balderschwang",
      "bl": 9,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "09780114",
      "j": 2023,
      "n": "betzigau",
      "dn": "Betzigau",
      "bl": 9,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "09780115",
      "j": 2023,
      "n": "blaichach",
      "dn": "Blaichach",
      "bl": 9,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "09780116",
      "j": 2023,
      "n": "bolsterlang",
      "dn": "Bolsterlang",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09780117",
      "j": 2023,
      "n": "buchenberg",
      "dn": "Buchenberg",
      "bl": 9,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "09780118",
      "j": 2023,
      "n": "burgberg im allgäu",
      "dn": "Burgberg im Allgäu",
      "bl": 9,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "09780119",
      "j": 2023,
      "n": "dietmannsried",
      "dn": "Dietmannsried",
      "bl": 9,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "09780120",
      "j": 2023,
      "n": "durach",
      "dn": "Durach",
      "bl": 9,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "09780121",
      "j": 2023,
      "n": "fischen im allgäu",
      "dn": "Fischen im Allgäu",
      "bl": 9,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "09780122",
      "j": 2023,
      "n": "haldenwang",
      "dn": "Haldenwang",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09780123",
      "j": 2023,
      "n": "bad hindelang",
      "dn": "Bad Hindelang",
      "bl": 9,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "09780124",
      "j": 2023,
      "n": "immenstadt im allgäu",
      "dn": "Immenstadt im Allgäu",
      "bl": 9,
      "hs": 535.0,
      "mns": 3
    },
    {
      "a": "09780125",
      "j": 2023,
      "n": "lauben",
      "dn": "Lauben",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09780127",
      "j": 2023,
      "n": "missen-wilhams",
      "dn": "Missen-Wilhams",
      "bl": 9,
      "hs": 375.0,
      "mns": 2
    },
    {
      "a": "09780128",
      "j": 2023,
      "n": "oy-mittelberg",
      "dn": "Oy-Mittelberg",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09780131",
      "j": 2023,
      "n": "obermaiselstein",
      "dn": "Obermaiselstein",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09780132",
      "j": 2023,
      "n": "oberstaufen",
      "dn": "Oberstaufen",
      "bl": 9,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "09780133",
      "j": 2023,
      "n": "oberstdorf",
      "dn": "Oberstdorf",
      "bl": 9,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "09780134",
      "j": 2023,
      "n": "ofterschwang",
      "dn": "Ofterschwang",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "09780137",
      "j": 2023,
      "n": "rettenberg",
      "dn": "Rettenberg",
      "bl": 9,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "09780139",
      "j": 2023,
      "n": "sonthofen",
      "dn": "Sonthofen",
      "bl": 9,
      "hs": 440.0,
      "mns": 3
    },
    {
      "a": "09780140",
      "j": 2023,
      "n": "sulzberg",
      "dn": "Sulzberg",
      "bl": 9,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "09780143",
      "j": 2023,
      "n": "waltenhofen",
      "dn": "Waltenhofen",
      "bl": 9,
      "hs": 385.0,
      "mns": 2
    },
    {
      "a": "09780144",
      "j": 2023,
      "n": "weitnau",
      "dn": "Weitnau",
      "bl": 9,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "09780145",
      "j": 2023,
      "n": "wertach",
      "dn": "Wertach",
      "bl": 9,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "09780146",
      "j": 2023,
      "n": "wiggensbach",
      "dn": "Wiggensbach",
      "bl": 9,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "09780147",
      "j": 2023,
      "n": "wildpoldsried",
      "dn": "Wildpoldsried",
      "bl": 9,
      "hs": 350.0,
      "mns": 2
    }
  ],
  "10": [
    {
      "a": "10041100",
      "j": 2024,
      "n": "saarbrücken",
      "dn": "Saarbrücken",
      "bl": 10,
      "hs": 520.0,
      "mns": 3
    },
    {
      "a": "10041511",
      "j": 2024,
      "n": "friedrichsthal",
      "dn": "Friedrichsthal",
      "bl": 10,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "10041512",
      "j": 2024,
      "n": "großrosseln",
      "dn": "Großrosseln",
      "bl": 10,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "10041513",
      "j": 2024,
      "n": "heusweiler",
      "dn": "Heusweiler",
      "bl": 10,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "10041514",
      "j": 2024,
      "n": "kleinblittersdorf",
      "dn": "Kleinblittersdorf",
      "bl": 10,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "10041515",
      "j": 2024,
      "n": "püttlingen",
      "dn": "Püttlingen",
      "bl": 10,
      "hs": 360.0,
      "mns": 3
    },
    {
      "a": "10041516",
      "j": 2024,
      "n": "quierschied",
      "dn": "Quierschied",
      "bl": 10,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "10041517",
      "j": 2024,
      "n": "riegelsberg",
      "dn": "Riegelsberg",
      "bl": 10,
      "hs": 384.0,
      "mns": 2
    },
    {
      "a": "10041518",
      "j": 2024,
      "n": "sulzbach/saar",
      "dn": "Sulzbach/Saar",
      "bl": 10,
      "hs": 439.0,
      "mns": 2
    },
    {
      "a": "10041519",
      "j": 2024,
      "n": "völklingen",
      "dn": "Völklingen",
      "bl": 10,
      "hs": 605.0,
      "mns": 2
    },
    {
      "a": "10042111",
      "j": 2024,
      "n": "beckingen",
      "dn": "Beckingen",
      "bl": 10,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "10042112",
      "j": 2024,
      "n": "losheim am see",
      "dn": "Losheim am See",
      "bl": 10,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "10042113",
      "j": 2024,
      "n": "merzig",
      "dn": "Merzig",
      "bl": 10,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "10042114",
      "j": 2024,
      "n": "mettlach",
      "dn": "Mettlach",
      "bl": 10,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "10042115",
      "j": 2024,
      "n": "perl",
      "dn": "Perl",
      "bl": 10,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "10042116",
      "j": 2024,
      "n": "wadern",
      "dn": "Wadern",
      "bl": 10,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "10042117",
      "j": 2024,
      "n": "weiskirchen",
      "dn": "Weiskirchen",
      "bl": 10,
      "hs": 550.0,
      "mns": 2
    },
    {
      "a": "10043111",
      "j": 2025,
      "n": "gemeinde eppelborn",
      "dn": "Gemeinde Eppelborn",
      "bl": 10,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "10043112",
      "j": 2024,
      "n": "illingen",
      "dn": "Illingen",
      "bl": 10,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "10043113",
      "j": 2024,
      "n": "merchweiler",
      "dn": "Merchweiler",
      "bl": 10,
      "hs": 490.0,
      "mns": 2
    },
    {
      "a": "10043114",
      "j": 2024,
      "n": "neunkirchen",
      "dn": "Neunkirchen",
      "bl": 10,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "10043115",
      "j": 2024,
      "n": "ottweiler",
      "dn": "Ottweiler",
      "bl": 10,
      "hs": 495.0,
      "mns": 1
    },
    {
      "a": "10043116",
      "j": 2024,
      "n": "schiffweiler",
      "dn": "Schiffweiler",
      "bl": 10,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "10043117",
      "j": 2024,
      "n": "spiesen-elversberg",
      "dn": "Spiesen-Elversberg",
      "bl": 10,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "10044111",
      "j": 2024,
      "n": "dillingen/saar",
      "dn": "Dillingen/Saar",
      "bl": 10,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "10044112",
      "j": 2025,
      "n": "lebach",
      "dn": "Lebach",
      "bl": 10,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "10044113",
      "j": 2024,
      "n": "nalbach",
      "dn": "Nalbach",
      "bl": 10,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "10044114",
      "j": 2024,
      "n": "rehlingen-siersburg",
      "dn": "Rehlingen-Siersburg",
      "bl": 10,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "10044115",
      "j": 2024,
      "n": "saarlouis",
      "dn": "Saarlouis",
      "bl": 10,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "10044116",
      "j": 2024,
      "n": "saarwellingen",
      "dn": "Saarwellingen",
      "bl": 10,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "10044117",
      "j": 2024,
      "n": "schmelz",
      "dn": "Schmelz",
      "bl": 10,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "10044118",
      "j": 2024,
      "n": "schwalbach",
      "dn": "Schwalbach",
      "bl": 10,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "10044119",
      "j": 2025,
      "n": "überherrn",
      "dn": "Überherrn",
      "bl": 10,
      "hs": 435.0,
      "mns": 2
    },
    {
      "a": "10044120",
      "j": 2024,
      "n": "wadgassen",
      "dn": "Wadgassen",
      "bl": 10,
      "hs": 386.0,
      "mns": 1
    },
    {
      "a": "10044121",
      "j": 2024,
      "n": "wallerfangen",
      "dn": "Wallerfangen",
      "bl": 10,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "10044122",
      "j": 2024,
      "n": "bous",
      "dn": "Bous",
      "bl": 10,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "10044123",
      "j": 2024,
      "n": "ensdorf",
      "dn": "Ensdorf",
      "bl": 10,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "10045111",
      "j": 2024,
      "n": "bexbach",
      "dn": "Bexbach",
      "bl": 10,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "10045112",
      "j": 2024,
      "n": "blieskastel",
      "dn": "Blieskastel",
      "bl": 10,
      "hs": 560.0,
      "mns": 2
    },
    {
      "a": "10045113",
      "j": 2024,
      "n": "gersheim",
      "dn": "Gersheim",
      "bl": 10,
      "hs": 680.0,
      "mns": 1
    },
    {
      "a": "10045114",
      "j": 2024,
      "n": "homburg",
      "dn": "Homburg",
      "bl": 10,
      "hs": 560.0,
      "mns": 3
    },
    {
      "a": "10045115",
      "j": 2024,
      "n": "kirkel",
      "dn": "Kirkel",
      "bl": 10,
      "hs": 600.0,
      "mns": 4
    },
    {
      "a": "10045116",
      "j": 2024,
      "n": "mandelbachtal",
      "dn": "Mandelbachtal",
      "bl": 10,
      "hs": 550.0,
      "mns": 1
    },
    {
      "a": "10045117",
      "j": 2024,
      "n": "st. ingbert",
      "dn": "St. Ingbert",
      "bl": 10,
      "hs": 675.0,
      "mns": 2
    },
    {
      "a": "10046111",
      "j": 2024,
      "n": "freisen",
      "dn": "Freisen",
      "bl": 10,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "10046112",
      "j": 2024,
      "n": "marpingen",
      "dn": "Marpingen",
      "bl": 10,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "10046113",
      "j": 2024,
      "n": "namborn",
      "dn": "Namborn",
      "bl": 10,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "10046114",
      "j": 2024,
      "n": "nohfelden",
      "dn": "Nohfelden",
      "bl": 10,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "10046115",
      "j": 2024,
      "n": "nonnweiler",
      "dn": "Nonnweiler",
      "bl": 10,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "10046116",
      "j": 2024,
      "n": "oberthal",
      "dn": "Oberthal",
      "bl": 10,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "10046117",
      "j": 2024,
      "n": "st. wendel",
      "dn": "St. Wendel",
      "bl": 10,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "10046118",
      "j": 2024,
      "n": "tholey",
      "dn": "Tholey",
      "bl": 10,
      "hs": 450.0,
      "mns": 1
    }
  ],
  "11": [
    {
      "a": "11000000",
      "j": 2025,
      "n": "berlin",
      "dn": "Berlin",
      "bl": 11,
      "hs": 470.0,
      "mns": 4
    }
  ],
  "12": [
    {
      "a": "12051000",
      "j": 2023,
      "n": "brandenburg an der havel",
      "dn": "Brandenburg an der Havel",
      "bl": 12,
      "hs": 530.0,
      "mns": 2
    },
    {
      "a": "12052000",
      "j": 2023,
      "n": "cottbus",
      "dn": "Cottbus",
      "bl": 12,
      "hs": 500.0,
      "mns": 2
    },
    {
      "a": "12053000",
      "j": 2023,
      "n": "frankfurt (oder)",
      "dn": "Frankfurt (Oder)",
      "bl": 12,
      "hs": 480.0,
      "mns": 2
    },
    {
      "a": "12054000",
      "j": 2023,
      "n": "potsdam",
      "dn": "Potsdam",
      "bl": 12,
      "hs": 545.0,
      "mns": 4
    },
    {
      "a": "12060005",
      "j": 2023,
      "n": "ahrensfelde",
      "dn": "Ahrensfelde",
      "bl": 12,
      "hs": 420.0,
      "mns": 3
    },
    {
      "a": "12060012",
      "j": 2023,
      "n": "althüttendorf",
      "dn": "Althüttendorf",
      "bl": 12,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "12060020",
      "j": 2023,
      "n": "bernau bei berlin",
      "dn": "Bernau bei Berlin",
      "bl": 12,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "12060024",
      "j": 2023,
      "n": "biesenthal",
      "dn": "Biesenthal",
      "bl": 12,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "12060034",
      "j": 2023,
      "n": "breydin",
      "dn": "Breydin",
      "bl": 12,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "12060036",
      "j": 2023,
      "n": "britz",
      "dn": "Britz",
      "bl": 12,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "12060045",
      "j": 2023,
      "n": "chorin",
      "dn": "Chorin",
      "bl": 12,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "12060052",
      "j": 2023,
      "n": "eberswalde",
      "dn": "Eberswalde",
      "bl": 12,
      "hs": 415.0,
      "mns": 3
    },
    {
      "a": "12060068",
      "j": 2023,
      "n": "friedrichswalde",
      "dn": "Friedrichswalde",
      "bl": 12,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "12060092",
      "j": 2023,
      "n": "hohenfinow",
      "dn": "Hohenfinow",
      "bl": 12,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "12060100",
      "j": 2023,
      "n": "joachimsthal",
      "dn": "Joachimsthal",
      "bl": 12,
      "hs": 434.0,
      "mns": 2
    },
    {
      "a": "12060128",
      "j": 2023,
      "n": "liepe",
      "dn": "Liepe",
      "bl": 12,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "12060149",
      "j": 2023,
      "n": "lunow-stolzenhagen",
      "dn": "Lunow-Stolzenhagen",
      "bl": 12,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "12060154",
      "j": 2023,
      "n": "marienwerder",
      "dn": "Marienwerder",
      "bl": 12,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "12060161",
      "j": 2023,
      "n": "melchow",
      "dn": "Melchow",
      "bl": 12,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "12060172",
      "j": 2023,
      "n": "niederfinow",
      "dn": "Niederfinow",
      "bl": 12,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "12060176",
      "j": 2023,
      "n": "oderberg",
      "dn": "Oderberg",
      "bl": 12,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "12060181",
      "j": 2023,
      "n": "panketal",
      "dn": "Panketal",
      "bl": 12,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "12060185",
      "j": 2023,
      "n": "parsteinsee",
      "dn": "Parsteinsee",
      "bl": 12,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "12060192",
      "j": 2023,
      "n": "rüdnitz",
      "dn": "Rüdnitz",
      "bl": 12,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "12060198",
      "j": 2023,
      "n": "schorfheide",
      "dn": "Schorfheide",
      "bl": 12,
      "hs": 460.0,
      "mns": 2
    },
    {
      "a": "12060250",
      "j": 2023,
      "n": "sydower fließ",
      "dn": "Sydower Fließ",
      "bl": 12,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "12060269",
      "j": 2023,
      "n": "wandlitz",
      "dn": "Wandlitz",
      "bl": 12,
      "hs": 450.0,
      "mns": 3
    },
    {
      "a": "12060280",
      "j": 2023,
      "n": "werneuchen",
      "dn": "Werneuchen",
      "bl": 12,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "12060296",
      "j": 2023,
      "n": "ziethen, barnim",
      "dn": "Ziethen, Barnim",
      "bl": 12,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "12061005",
      "j": 2023,
      "n": "alt zauche-wußwerk",
      "dn": "Alt Zauche-Wußwerk",
      "bl": 12,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "12061017",
      "j": 2023,
      "n": "bersteland",
      "dn": "Bersteland",
      "bl": 12,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "12061020",
      "j": 2023,
      "n": "bestensee",
      "dn": "Bestensee",
      "bl": 12,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "12061061",
      "j": 2023,
      "n": "byhleguhre-byhlen",
      "dn": "Byhleguhre-Byhlen",
      "bl": 12,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "12061097",
      "j": 2023,
      "n": "drahnsdorf",
      "dn": "Drahnsdorf",
      "bl": 12,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "12061164",
      "j": 2023,
      "n": "golßen",
      "dn": "Golßen",
      "bl": 12,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "12061192",
      "j": 2023,
      "n": "groß köris",
      "dn": "Groß Köris",
      "bl": 12,
      "hs": 310.0,
      "mns": 2
    },
    {
      "a": "12061216",
      "j": 2023,
      "n": "halbe",
      "dn": "Halbe",
      "bl": 12,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "12061217",
      "j": 2023,
      "n": "heidesee",
      "dn": "Heidesee",
      "bl": 12,
      "hs": 349.0,
      "mns": 2
    },
    {
      "a": "12061219",
      "j": 2023,
      "n": "heideblick",
      "dn": "Heideblick",
      "bl": 12,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "12061224",
      "j": 2023,
      "n": "jamlitz",
      "dn": "Jamlitz",
      "bl": 12,
      "hs": 377.0,
      "mns": 2
    },
    {
      "a": "12061244",
      "j": 2023,
      "n": "kasel-golzig",
      "dn": "Kasel-Golzig",
      "bl": 12,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "12061260",
      "j": 2023,
      "n": "königs wusterhausen",
      "dn": "Königs Wusterhausen",
      "bl": 12,
      "hs": 405.0,
      "mns": 3
    },
    {
      "a": "12061265",
      "j": 2023,
      "n": "krausnick-groß wasserburg",
      "dn": "Krausnick-Groß Wasserburg",
      "bl": 12,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "12061308",
      "j": 2023,
      "n": "lieberose",
      "dn": "Lieberose",
      "bl": 12,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "12061316",
      "j": 2023,
      "n": "lübben (spreewald)",
      "dn": "Lübben (Spreewald)",
      "bl": 12,
      "hs": 395.0,
      "mns": 2
    },
    {
      "a": "12061320",
      "j": 2023,
      "n": "luckau",
      "dn": "Luckau",
      "bl": 12,
      "hs": 391.0,
      "mns": 2
    },
    {
      "a": "12061328",
      "j": 2023,
      "n": "märkisch buchholz",
      "dn": "Märkisch Buchholz",
      "bl": 12,
      "hs": 395.0,
      "mns": 2
    },
    {
      "a": "12061329",
      "j": 2023,
      "n": "märkische heide",
      "dn": "Märkische Heide",
      "bl": 12,
      "hs": 374.0,
      "mns": 2
    },
    {
      "a": "12061332",
      "j": 2023,
      "n": "mittenwalde",
      "dn": "Mittenwalde",
      "bl": 12,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "12061344",
      "j": 2023,
      "n": "münchehofe",
      "dn": "Münchehofe",
      "bl": 12,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "12061352",
      "j": 2023,
      "n": "neu zauche",
      "dn": "Neu Zauche",
      "bl": 12,
      "hs": 369.0,
      "mns": 2
    },
    {
      "a": "12061405",
      "j": 2023,
      "n": "rietzneuendorf-staakow",
      "dn": "Rietzneuendorf-Staakow",
      "bl": 12,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "12061428",
      "j": 2023,
      "n": "schlepzig",
      "dn": "Schlepzig",
      "bl": 12,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "12061433",
      "j": 2023,
      "n": "schönefeld",
      "dn": "Schönefeld",
      "bl": 12,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "12061435",
      "j": 2023,
      "n": "schönwald",
      "dn": "Schönwald",
      "bl": 12,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "12061444",
      "j": 2023,
      "n": "schulzendorf",
      "dn": "Schulzendorf",
      "bl": 12,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "12061448",
      "j": 2023,
      "n": "schwerin",
      "dn": "Schwerin",
      "bl": 12,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "12061450",
      "j": 2023,
      "n": "schwielochsee",
      "dn": "Schwielochsee",
      "bl": 12,
      "hs": 382.0,
      "mns": 2
    },
    {
      "a": "12061470",
      "j": 2023,
      "n": "spreewaldheide",
      "dn": "Spreewaldheide",
      "bl": 12,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "12061471",
      "j": 2023,
      "n": "steinreich",
      "dn": "Steinreich",
      "bl": 12,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "12061476",
      "j": 2023,
      "n": "straupitz (spreewald)",
      "dn": "Straupitz (Spreewald)",
      "bl": 12,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "12061492",
      "j": 2023,
      "n": "teupitz",
      "dn": "Teupitz",
      "bl": 12,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "12061510",
      "j": 2023,
      "n": "unterspreewald",
      "dn": "Unterspreewald",
      "bl": 12,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "12061540",
      "j": 2023,
      "n": "wildau",
      "dn": "Wildau",
      "bl": 12,
      "hs": 385.0,
      "mns": 4
    },
    {
      "a": "12061572",
      "j": 2023,
      "n": "zeuthen",
      "dn": "Zeuthen",
      "bl": 12,
      "hs": 410.0,
      "mns": 3
    },
    {
      "a": "12062024",
      "j": 2023,
      "n": "bad liebenwerda",
      "dn": "Bad Liebenwerda",
      "bl": 12,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "12062088",
      "j": 2023,
      "n": "crinitz",
      "dn": "Crinitz",
      "bl": 12,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "12062092",
      "j": 2023,
      "n": "doberlug-kirchhain",
      "dn": "Doberlug-Kirchhain",
      "bl": 12,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "12062124",
      "j": 2023,
      "n": "elsterwerda",
      "dn": "Elsterwerda",
      "bl": 12,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "12062128",
      "j": 2023,
      "n": "falkenberg/elster",
      "dn": "Falkenberg/Elster",
      "bl": 12,
      "hs": 391.0,
      "mns": 1
    },
    {
      "a": "12062134",
      "j": 2023,
      "n": "fichtwald",
      "dn": "Fichtwald",
      "bl": 12,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "12062140",
      "j": 2023,
      "n": "finsterwalde",
      "dn": "Finsterwalde",
      "bl": 12,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "12062177",
      "j": 2023,
      "n": "gorden-staupitz",
      "dn": "Gorden-Staupitz",
      "bl": 12,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "12062196",
      "j": 2023,
      "n": "gröden",
      "dn": "Gröden",
      "bl": 12,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "12062208",
      "j": 2023,
      "n": "großthiemig",
      "dn": "Großthiemig",
      "bl": 12,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "12062219",
      "j": 2023,
      "n": "heideland",
      "dn": "Heideland",
      "bl": 12,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "12062224",
      "j": 2023,
      "n": "herzberg",
      "dn": "Herzberg",
      "bl": 12,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "12062232",
      "j": 2023,
      "n": "hirschfeld",
      "dn": "Hirschfeld",
      "bl": 12,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "12062237",
      "j": 2023,
      "n": "hohenbucko",
      "dn": "Hohenbucko",
      "bl": 12,
      "hs": 401.0,
      "mns": 1
    },
    {
      "a": "12062240",
      "j": 2023,
      "n": "hohenleipisch",
      "dn": "Hohenleipisch",
      "bl": 12,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "12062282",
      "j": 2023,
      "n": "kremitzaue",
      "dn": "Kremitzaue",
      "bl": 12,
      "hs": 401.0,
      "mns": 1
    },
    {
      "a": "12062289",
      "j": 2023,
      "n": "lebusa",
      "dn": "Lebusa",
      "bl": 12,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "12062293",
      "j": 2023,
      "n": "lichterfeld-schacksdorf",
      "dn": "Lichterfeld-Schacksdorf",
      "bl": 12,
      "hs": 377.0,
      "mns": 1
    },
    {
      "a": "12062333",
      "j": 2023,
      "n": "massen-niederlausitz",
      "dn": "Massen-Niederlausitz",
      "bl": 12,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "12062336",
      "j": 2023,
      "n": "merzdorf",
      "dn": "Merzdorf",
      "bl": 12,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "12062341",
      "j": 2023,
      "n": "mühlberg/elbe",
      "dn": "Mühlberg/Elbe",
      "bl": 12,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "12062372",
      "j": 2023,
      "n": "plessa",
      "dn": "Plessa",
      "bl": 12,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "12062410",
      "j": 2023,
      "n": "röderland",
      "dn": "Röderland",
      "bl": 12,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "12062417",
      "j": 2023,
      "n": "rückersdorf",
      "dn": "Rückersdorf",
      "bl": 12,
      "hs": 387.0,
      "mns": 1
    },
    {
      "a": "12062425",
      "j": 2023,
      "n": "sallgast",
      "dn": "Sallgast",
      "bl": 12,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "12062440",
      "j": 2023,
      "n": "schilda",
      "dn": "Schilda",
      "bl": 12,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "12062445",
      "j": 2023,
      "n": "schlieben",
      "dn": "Schlieben",
      "bl": 12,
      "hs": 384.0,
      "mns": 1
    },
    {
      "a": "12062453",
      "j": 2023,
      "n": "schönborn",
      "dn": "Schönborn",
      "bl": 12,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "12062461",
      "j": 2023,
      "n": "schönewalde",
      "dn": "Schönewalde",
      "bl": 12,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "12062464",
      "j": 2023,
      "n": "schraden",
      "dn": "Schraden",
      "bl": 12,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "12062469",
      "j": 2023,
      "n": "sonnewalde",
      "dn": "Sonnewalde",
      "bl": 12,
      "hs": 383.0,
      "mns": 1
    },
    {
      "a": "12062492",
      "j": 2023,
      "n": "tröbitz",
      "dn": "Tröbitz",
      "bl": 12,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "12062500",
      "j": 2023,
      "n": "uebigau-wahrenbrück",
      "dn": "Uebigau-Wahrenbrück",
      "bl": 12,
      "hs": 391.0,
      "mns": 1
    },
    {
      "a": "12063036",
      "j": 2025,
      "n": "brieselang",
      "dn": "Brieselang",
      "bl": 12,
      "hs": 190.0,
      "mns": 5
    },
    {
      "a": "12063056",
      "j": 2023,
      "n": "dallgow-döberitz",
      "dn": "Dallgow-Döberitz",
      "bl": 12,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "12063080",
      "j": 2023,
      "n": "falkensee",
      "dn": "Falkensee",
      "bl": 12,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "12063088",
      "j": 2023,
      "n": "friesack",
      "dn": "Friesack",
      "bl": 12,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "12063094",
      "j": 2023,
      "n": "gollenberg",
      "dn": "Gollenberg",
      "bl": 12,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "12063112",
      "j": 2023,
      "n": "großderschau",
      "dn": "Großderschau",
      "bl": 12,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "12063134",
      "j": 2023,
      "n": "havelaue",
      "dn": "Havelaue",
      "bl": 12,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "12063142",
      "j": 2023,
      "n": "wiesenaue",
      "dn": "Wiesenaue",
      "bl": 12,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "12063148",
      "j": 2023,
      "n": "ketzin/havel",
      "dn": "Ketzin/Havel",
      "bl": 12,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "12063161",
      "j": 2023,
      "n": "kleßen-görne",
      "dn": "Kleßen-Görne",
      "bl": 12,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "12063165",
      "j": 2023,
      "n": "kotzen",
      "dn": "Kotzen",
      "bl": 12,
      "hs": 412.0,
      "mns": 2
    },
    {
      "a": "12063186",
      "j": 2023,
      "n": "märkisch luch",
      "dn": "Märkisch Luch",
      "bl": 12,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "12063189",
      "j": 2023,
      "n": "milower land",
      "dn": "Milower Land",
      "bl": 12,
      "hs": 374.0,
      "mns": 2
    },
    {
      "a": "12063202",
      "j": 2023,
      "n": "mühlenberge",
      "dn": "Mühlenberge",
      "bl": 12,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "12063208",
      "j": 2023,
      "n": "nauen",
      "dn": "Nauen",
      "bl": 12,
      "hs": 425.0,
      "mns": 3
    },
    {
      "a": "12063212",
      "j": 2023,
      "n": "nennhausen",
      "dn": "Nennhausen",
      "bl": 12,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "12063228",
      "j": 2023,
      "n": "paulinenaue",
      "dn": "Paulinenaue",
      "bl": 12,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "12063240",
      "j": 2023,
      "n": "pessin",
      "dn": "Pessin",
      "bl": 12,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "12063244",
      "j": 2023,
      "n": "premnitz",
      "dn": "Premnitz",
      "bl": 12,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "12063252",
      "j": 2023,
      "n": "rathenow",
      "dn": "Rathenow",
      "bl": 12,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "12063256",
      "j": 2023,
      "n": "retzow",
      "dn": "Retzow",
      "bl": 12,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "12063260",
      "j": 2023,
      "n": "rhinow",
      "dn": "Rhinow",
      "bl": 12,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "12063273",
      "j": 2023,
      "n": "schönwalde-glien",
      "dn": "Schönwalde-Glien",
      "bl": 12,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "12063274",
      "j": 2023,
      "n": "seeblick",
      "dn": "Seeblick",
      "bl": 12,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "12063293",
      "j": 2023,
      "n": "stechow-ferchesar",
      "dn": "Stechow-Ferchesar",
      "bl": 12,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "12063357",
      "j": 2023,
      "n": "wustermark",
      "dn": "Wustermark",
      "bl": 12,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "12064009",
      "j": 2023,
      "n": "alt tucheband",
      "dn": "Alt Tucheband",
      "bl": 12,
      "hs": 386.0,
      "mns": 1
    },
    {
      "a": "12064029",
      "j": 2023,
      "n": "altlandsberg",
      "dn": "Altlandsberg",
      "bl": 12,
      "hs": 445.0,
      "mns": 1
    },
    {
      "a": "12064044",
      "j": 2023,
      "n": "bad freienwalde",
      "dn": "Bad Freienwalde",
      "bl": 12,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "12064053",
      "j": 2023,
      "n": "beiersdorf-freudenberg",
      "dn": "Beiersdorf-Freudenberg",
      "bl": 12,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "12064057",
      "j": 2023,
      "n": "bleyen-genschmar",
      "dn": "Bleyen-Genschmar",
      "bl": 12,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "12064061",
      "j": 2023,
      "n": "bliesdorf",
      "dn": "Bliesdorf",
      "bl": 12,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "12064084",
      "j": 2023,
      "n": "buckow",
      "dn": "Buckow",
      "bl": 12,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "12064125",
      "j": 2023,
      "n": "falkenberg",
      "dn": "Falkenberg",
      "bl": 12,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "12064128",
      "j": 2023,
      "n": "falkenhagen (mark)",
      "dn": "Falkenhagen (Mark)",
      "bl": 12,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "12064130",
      "j": 2023,
      "n": "fichtenhöhe",
      "dn": "Fichtenhöhe",
      "bl": 12,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "12064136",
      "j": 2023,
      "n": "fredersdorf-vogelsdorf",
      "dn": "Fredersdorf-Vogelsdorf",
      "bl": 12,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "12064153",
      "j": 2023,
      "n": "garzau-garzin",
      "dn": "Garzau-Garzin",
      "bl": 12,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "12064172",
      "j": 2023,
      "n": "golzow",
      "dn": "Golzow",
      "bl": 12,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "12064190",
      "j": 2023,
      "n": "gusow-platkow",
      "dn": "Gusow-Platkow",
      "bl": 12,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "12064205",
      "j": 2023,
      "n": "heckelberg-brunow",
      "dn": "Heckelberg-Brunow",
      "bl": 12,
      "hs": 394.0,
      "mns": 1
    },
    {
      "a": "12064222",
      "j": 2023,
      "n": "höhenland",
      "dn": "Höhenland",
      "bl": 12,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "12064227",
      "j": 2023,
      "n": "hoppegarten",
      "dn": "Hoppegarten",
      "bl": 12,
      "hs": 370.0,
      "mns": 5
    },
    {
      "a": "12064266",
      "j": 2023,
      "n": "küstriner vorland",
      "dn": "Küstriner Vorland",
      "bl": 12,
      "hs": 384.0,
      "mns": 1
    },
    {
      "a": "12064268",
      "j": 2023,
      "n": "lebus",
      "dn": "Lebus",
      "bl": 12,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "12064274",
      "j": 2023,
      "n": "letschin",
      "dn": "Letschin",
      "bl": 12,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "12064288",
      "j": 2023,
      "n": "lietzen",
      "dn": "Lietzen",
      "bl": 12,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "12064290",
      "j": 2023,
      "n": "lindendorf",
      "dn": "Lindendorf",
      "bl": 12,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "12064303",
      "j": 2023,
      "n": "märkische höhe",
      "dn": "Märkische Höhe",
      "bl": 12,
      "hs": 387.0,
      "mns": 1
    },
    {
      "a": "12064317",
      "j": 2023,
      "n": "müncheberg",
      "dn": "Müncheberg",
      "bl": 12,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "12064336",
      "j": 2023,
      "n": "neuenhagen bei berlin",
      "dn": "Neuenhagen bei Berlin",
      "bl": 12,
      "hs": 350.0,
      "mns": 4
    },
    {
      "a": "12064340",
      "j": 2023,
      "n": "neuhardenberg",
      "dn": "Neuhardenberg",
      "bl": 12,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "12064349",
      "j": 2023,
      "n": "neulewin",
      "dn": "Neulewin",
      "bl": 12,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "12064365",
      "j": 2023,
      "n": "neutrebbin",
      "dn": "Neutrebbin",
      "bl": 12,
      "hs": 384.0,
      "mns": 1
    },
    {
      "a": "12064370",
      "j": 2023,
      "n": "oberbarnim",
      "dn": "Oberbarnim",
      "bl": 12,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "12064371",
      "j": 2023,
      "n": "oderaue",
      "dn": "Oderaue",
      "bl": 12,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "12064380",
      "j": 2023,
      "n": "petershagen/eggersdorf",
      "dn": "Petershagen/Eggersdorf",
      "bl": 12,
      "hs": 385.0,
      "mns": 3
    },
    {
      "a": "12064388",
      "j": 2023,
      "n": "podelzig",
      "dn": "Podelzig",
      "bl": 12,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "12064393",
      "j": 2023,
      "n": "prötzel",
      "dn": "Prötzel",
      "bl": 12,
      "hs": 386.0,
      "mns": 1
    },
    {
      "a": "12064408",
      "j": 2023,
      "n": "rehfelde",
      "dn": "Rehfelde",
      "bl": 12,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "12064417",
      "j": 2023,
      "n": "reichenow-möglin",
      "dn": "Reichenow-Möglin",
      "bl": 12,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "12064420",
      "j": 2023,
      "n": "reitwein",
      "dn": "Reitwein",
      "bl": 12,
      "hs": 377.0,
      "mns": 1
    },
    {
      "a": "12064428",
      "j": 2023,
      "n": "rüdersdorf bei berlin",
      "dn": "Rüdersdorf bei Berlin",
      "bl": 12,
      "hs": 385.0,
      "mns": 2
    },
    {
      "a": "12064448",
      "j": 2023,
      "n": "seelow",
      "dn": "Seelow",
      "bl": 12,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "12064472",
      "j": 2023,
      "n": "strausberg",
      "dn": "Strausberg",
      "bl": 12,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "12064480",
      "j": 2023,
      "n": "treplin",
      "dn": "Treplin",
      "bl": 12,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "12064482",
      "j": 2025,
      "n": "vierlinden",
      "dn": "Vierlinden",
      "bl": 12,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "12064484",
      "j": 2023,
      "n": "waldsieversdorf",
      "dn": "Waldsieversdorf",
      "bl": 12,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "12064512",
      "j": 2023,
      "n": "wriezen",
      "dn": "Wriezen",
      "bl": 12,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "12064538",
      "j": 2023,
      "n": "zechin",
      "dn": "Zechin",
      "bl": 12,
      "hs": 374.0,
      "mns": 1
    },
    {
      "a": "12064539",
      "j": 2023,
      "n": "zeschdorf",
      "dn": "Zeschdorf",
      "bl": 12,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "12065036",
      "j": 2023,
      "n": "birkenwerder",
      "dn": "Birkenwerder",
      "bl": 12,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "12065084",
      "j": 2023,
      "n": "fürstenberg/havel",
      "dn": "Fürstenberg/Havel",
      "bl": 12,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "12065096",
      "j": 2023,
      "n": "glienicke/nordbahn",
      "dn": "Glienicke/Nordbahn",
      "bl": 12,
      "hs": 400.0,
      "mns": 5
    },
    {
      "a": "12065100",
      "j": 2023,
      "n": "gransee",
      "dn": "Gransee",
      "bl": 12,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "12065117",
      "j": 2023,
      "n": "großwoltersdorf",
      "dn": "Großwoltersdorf",
      "bl": 12,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "12065136",
      "j": 2023,
      "n": "hennigsdorf",
      "dn": "Hennigsdorf",
      "bl": 12,
      "hs": 410.0,
      "mns": 3
    },
    {
      "a": "12065144",
      "j": 2023,
      "n": "hohen neuendorf",
      "dn": "Hohen Neuendorf",
      "bl": 12,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "12065165",
      "j": 2023,
      "n": "kremmen",
      "dn": "Kremmen",
      "bl": 12,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "12065180",
      "j": 2023,
      "n": "leegebruch",
      "dn": "Leegebruch",
      "bl": 12,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "12065193",
      "j": 2023,
      "n": "liebenwalde",
      "dn": "Liebenwalde",
      "bl": 12,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "12065198",
      "j": 2023,
      "n": "löwenberger land",
      "dn": "Löwenberger Land",
      "bl": 12,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "12065225",
      "j": 2023,
      "n": "mühlenbecker land",
      "dn": "Mühlenbecker Land",
      "bl": 12,
      "hs": 375.0,
      "mns": 4
    },
    {
      "a": "12065251",
      "j": 2023,
      "n": "oberkrämer",
      "dn": "Oberkrämer",
      "bl": 12,
      "hs": 350.0,
      "mns": 3
    },
    {
      "a": "12065256",
      "j": 2023,
      "n": "oranienburg",
      "dn": "Oranienburg",
      "bl": 12,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "12065276",
      "j": 2023,
      "n": "schönermark",
      "dn": "Schönermark",
      "bl": 12,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "12065301",
      "j": 2023,
      "n": "sonnenberg",
      "dn": "Sonnenberg",
      "bl": 12,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "12065310",
      "j": 2023,
      "n": "stechlin",
      "dn": "Stechlin",
      "bl": 12,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "12065332",
      "j": 2023,
      "n": "velten",
      "dn": "Velten",
      "bl": 12,
      "hs": 355.0,
      "mns": 2
    },
    {
      "a": "12065356",
      "j": 2023,
      "n": "zehdenick",
      "dn": "Zehdenick",
      "bl": 12,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "12066008",
      "j": 2023,
      "n": "altdöbern",
      "dn": "Altdöbern",
      "bl": 12,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "12066041",
      "j": 2023,
      "n": "bronkow",
      "dn": "Bronkow",
      "bl": 12,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "12066052",
      "j": 2023,
      "n": "calau",
      "dn": "Calau",
      "bl": 12,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "12066064",
      "j": 2023,
      "n": "frauendorf",
      "dn": "Frauendorf",
      "bl": 12,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "12066104",
      "j": 2023,
      "n": "großkmehlen",
      "dn": "Großkmehlen",
      "bl": 12,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "12066112",
      "j": 2023,
      "n": "großräschen",
      "dn": "Großräschen",
      "bl": 12,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "12066116",
      "j": 2023,
      "n": "grünewald",
      "dn": "Grünewald",
      "bl": 12,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "12066120",
      "j": 2023,
      "n": "guteborn",
      "dn": "Guteborn",
      "bl": 12,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "12066124",
      "j": 2023,
      "n": "hermsdorf",
      "dn": "Hermsdorf",
      "bl": 12,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "12066132",
      "j": 2023,
      "n": "hohenbocka",
      "dn": "Hohenbocka",
      "bl": 12,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "12066168",
      "j": 2023,
      "n": "kroppen",
      "dn": "Kroppen",
      "bl": 12,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "12066176",
      "j": 2023,
      "n": "lauchhammer",
      "dn": "Lauchhammer",
      "bl": 12,
      "hs": 416.0,
      "mns": 2
    },
    {
      "a": "12066188",
      "j": 2023,
      "n": "lindenau",
      "dn": "Lindenau",
      "bl": 12,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "12066196",
      "j": 2023,
      "n": "lübbenau/spreewald",
      "dn": "Lübbenau/Spreewald",
      "bl": 12,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "12066202",
      "j": 2023,
      "n": "luckaitztal",
      "dn": "Luckaitztal",
      "bl": 12,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "12066226",
      "j": 2023,
      "n": "neu-seeland",
      "dn": "Neu-Seeland",
      "bl": 12,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "12066228",
      "j": 2023,
      "n": "neupetershain",
      "dn": "Neupetershain",
      "bl": 12,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "12066240",
      "j": 2023,
      "n": "ortrand",
      "dn": "Ortrand",
      "bl": 12,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "12066272",
      "j": 2023,
      "n": "ruhland",
      "dn": "Ruhland",
      "bl": 12,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "12066285",
      "j": 2023,
      "n": "schipkau",
      "dn": "Schipkau",
      "bl": 12,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "12066292",
      "j": 2023,
      "n": "schwarzbach",
      "dn": "Schwarzbach",
      "bl": 12,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "12066296",
      "j": 2023,
      "n": "schwarzheide",
      "dn": "Schwarzheide",
      "bl": 12,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "12066304",
      "j": 2023,
      "n": "senftenberg",
      "dn": "Senftenberg",
      "bl": 12,
      "hs": 385.0,
      "mns": 2
    },
    {
      "a": "12066316",
      "j": 2023,
      "n": "tettau",
      "dn": "Tettau",
      "bl": 12,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "12066320",
      "j": 2023,
      "n": "vetschau/spreewald",
      "dn": "Vetschau/Spreewald",
      "bl": 12,
      "hs": 394.0,
      "mns": 1
    },
    {
      "a": "12067024",
      "j": 2023,
      "n": "bad saarow",
      "dn": "Bad Saarow",
      "bl": 12,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "12067036",
      "j": 2023,
      "n": "beeskow",
      "dn": "Beeskow",
      "bl": 12,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "12067040",
      "j": 2023,
      "n": "berkenbrück",
      "dn": "Berkenbrück",
      "bl": 12,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "12067072",
      "j": 2023,
      "n": "briesen (mark)",
      "dn": "Briesen (Mark)",
      "bl": 12,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "12067076",
      "j": 2023,
      "n": "brieskow-finkenheerd",
      "dn": "Brieskow-Finkenheerd",
      "bl": 12,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "12067112",
      "j": 2023,
      "n": "diensdorf-radlow",
      "dn": "Diensdorf-Radlow",
      "bl": 12,
      "hs": 445.0,
      "mns": 2
    },
    {
      "a": "12067120",
      "j": 2023,
      "n": "eisenhüttenstadt",
      "dn": "Eisenhüttenstadt",
      "bl": 12,
      "hs": 445.0,
      "mns": 3
    },
    {
      "a": "12067124",
      "j": 2023,
      "n": "erkner",
      "dn": "Erkner",
      "bl": 12,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "12067137",
      "j": 2023,
      "n": "friedland (niederlausitz)",
      "dn": "Friedland (Niederlausitz)",
      "bl": 12,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "12067144",
      "j": 2023,
      "n": "fürstenwalde/spree",
      "dn": "Fürstenwalde/Spree",
      "bl": 12,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "12067173",
      "j": 2023,
      "n": "gosen-neu zittau",
      "dn": "Gosen-Neu Zittau",
      "bl": 12,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "12067180",
      "j": 2023,
      "n": "groß lindow",
      "dn": "Groß Lindow",
      "bl": 12,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "12067201",
      "j": 2023,
      "n": "grünheide (mark)",
      "dn": "Grünheide (Mark)",
      "bl": 12,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "12067205",
      "j": 2023,
      "n": "grunow-dammendorf",
      "dn": "Grunow-Dammendorf",
      "bl": 12,
      "hs": 335.0,
      "mns": 2
    },
    {
      "a": "12067237",
      "j": 2023,
      "n": "jacobsdorf",
      "dn": "Jacobsdorf",
      "bl": 12,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "12067288",
      "j": 2023,
      "n": "langewahl",
      "dn": "Langewahl",
      "bl": 12,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "12067292",
      "j": 2023,
      "n": "lawitz",
      "dn": "Lawitz",
      "bl": 12,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "12067324",
      "j": 2023,
      "n": "mixdorf",
      "dn": "Mixdorf",
      "bl": 12,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "12067336",
      "j": 2023,
      "n": "müllrose",
      "dn": "Müllrose",
      "bl": 12,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "12067338",
      "j": 2023,
      "n": "neißemünde",
      "dn": "Neißemünde",
      "bl": 12,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "12067357",
      "j": 2023,
      "n": "neuzelle",
      "dn": "Neuzelle",
      "bl": 12,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "12067397",
      "j": 2023,
      "n": "ragow-merz",
      "dn": "Ragow-Merz",
      "bl": 12,
      "hs": 385.0,
      "mns": 2
    },
    {
      "a": "12067408",
      "j": 2023,
      "n": "rauen",
      "dn": "Rauen",
      "bl": 12,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "12067413",
      "j": 2023,
      "n": "reichenwalde",
      "dn": "Reichenwalde",
      "bl": 12,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "12067426",
      "j": 2023,
      "n": "rietz-neuendorf",
      "dn": "Rietz-Neuendorf",
      "bl": 12,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "12067438",
      "j": 2023,
      "n": "schlaubetal",
      "dn": "Schlaubetal",
      "bl": 12,
      "hs": 375.0,
      "mns": 2
    },
    {
      "a": "12067440",
      "j": 2023,
      "n": "schöneiche bei berlin",
      "dn": "Schöneiche bei Berlin",
      "bl": 12,
      "hs": 440.0,
      "mns": 3
    },
    {
      "a": "12067458",
      "j": 2023,
      "n": "siehdichum",
      "dn": "Siehdichum",
      "bl": 12,
      "hs": 385.0,
      "mns": 2
    },
    {
      "a": "12067469",
      "j": 2023,
      "n": "spreenhagen",
      "dn": "Spreenhagen",
      "bl": 12,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "12067473",
      "j": 2023,
      "n": "steinhöfel",
      "dn": "Steinhöfel",
      "bl": 12,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "12067481",
      "j": 2023,
      "n": "storkow (mark)",
      "dn": "Storkow (Mark)",
      "bl": 12,
      "hs": 391.0,
      "mns": 2
    },
    {
      "a": "12067493",
      "j": 2023,
      "n": "tauche",
      "dn": "Tauche",
      "bl": 12,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "12067508",
      "j": 2023,
      "n": "vogelsang",
      "dn": "Vogelsang",
      "bl": 12,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "12067520",
      "j": 2023,
      "n": "wendisch rietz",
      "dn": "Wendisch Rietz",
      "bl": 12,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "12067528",
      "j": 2023,
      "n": "wiesenau",
      "dn": "Wiesenau",
      "bl": 12,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "12067544",
      "j": 2023,
      "n": "woltersdorf",
      "dn": "Woltersdorf",
      "bl": 12,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "12067552",
      "j": 2023,
      "n": "ziltendorf",
      "dn": "Ziltendorf",
      "bl": 12,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "12068052",
      "j": 2023,
      "n": "breddin",
      "dn": "Breddin",
      "bl": 12,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "12068072",
      "j": 2023,
      "n": "dabergotz",
      "dn": "Dabergotz",
      "bl": 12,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "12068109",
      "j": 2023,
      "n": "dreetz",
      "dn": "Dreetz",
      "bl": 12,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "12068117",
      "j": 2023,
      "n": "fehrbellin",
      "dn": "Fehrbellin",
      "bl": 12,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "12068181",
      "j": 2023,
      "n": "heiligengrabe",
      "dn": "Heiligengrabe",
      "bl": 12,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "12068188",
      "j": 2023,
      "n": "herzberg",
      "dn": "Herzberg",
      "bl": 12,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "12068264",
      "j": 2023,
      "n": "kyritz",
      "dn": "Kyritz",
      "bl": 12,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "12068280",
      "j": 2023,
      "n": "lindow (mark)",
      "dn": "Lindow (Mark)",
      "bl": 12,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "12068306",
      "j": 2023,
      "n": "märkisch linden",
      "dn": "Märkisch Linden",
      "bl": 12,
      "hs": 345.0,
      "mns": 1
    },
    {
      "a": "12068320",
      "j": 2023,
      "n": "neuruppin",
      "dn": "Neuruppin",
      "bl": 12,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "12068324",
      "j": 2023,
      "n": "neustadt",
      "dn": "Neustadt",
      "bl": 12,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "12068353",
      "j": 2023,
      "n": "rheinsberg",
      "dn": "Rheinsberg",
      "bl": 12,
      "hs": 391.0,
      "mns": 1
    },
    {
      "a": "12068372",
      "j": 2023,
      "n": "rüthnick",
      "dn": "Rüthnick",
      "bl": 12,
      "hs": 331.0,
      "mns": 1
    },
    {
      "a": "12068409",
      "j": 2023,
      "n": "sieversdorf-hohenofen",
      "dn": "Sieversdorf-Hohenofen",
      "bl": 12,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "12068413",
      "j": 2023,
      "n": "storbeck-frankendorf",
      "dn": "Storbeck-Frankendorf",
      "bl": 12,
      "hs": 345.0,
      "mns": 1
    },
    {
      "a": "12068417",
      "j": 2023,
      "n": "stüdenitz-schönermark",
      "dn": "Stüdenitz-Schönermark",
      "bl": 12,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "12068425",
      "j": 2023,
      "n": "temnitzquell",
      "dn": "Temnitzquell",
      "bl": 12,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "12068426",
      "j": 2023,
      "n": "temnitztal",
      "dn": "Temnitztal",
      "bl": 12,
      "hs": 345.0,
      "mns": 1
    },
    {
      "a": "12068437",
      "j": 2023,
      "n": "vielitzsee",
      "dn": "Vielitzsee",
      "bl": 12,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "12068452",
      "j": 2023,
      "n": "walsleben",
      "dn": "Walsleben",
      "bl": 12,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "12068468",
      "j": 2023,
      "n": "wittstock/dosse",
      "dn": "Wittstock/Dosse",
      "bl": 12,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "12068477",
      "j": 2023,
      "n": "wusterhausen/dosse",
      "dn": "Wusterhausen/Dosse",
      "bl": 12,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "12068501",
      "j": 2023,
      "n": "zernitz-lohm",
      "dn": "Zernitz-Lohm",
      "bl": 12,
      "hs": 379.0,
      "mns": 1
    },
    {
      "a": "12069017",
      "j": 2023,
      "n": "beelitz",
      "dn": "Beelitz",
      "bl": 12,
      "hs": 375.0,
      "mns": 3
    },
    {
      "a": "12069018",
      "j": 2023,
      "n": "beetzsee",
      "dn": "Beetzsee",
      "bl": 12,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "12069019",
      "j": 2023,
      "n": "beetzseeheide",
      "dn": "Beetzseeheide",
      "bl": 12,
      "hs": 395.0,
      "mns": 2
    },
    {
      "a": "12069020",
      "j": 2023,
      "n": "bad belzig",
      "dn": "Bad Belzig",
      "bl": 12,
      "hs": 415.0,
      "mns": 2
    },
    {
      "a": "12069028",
      "j": 2023,
      "n": "bensdorf",
      "dn": "Bensdorf",
      "bl": 12,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "12069052",
      "j": 2023,
      "n": "borkheide",
      "dn": "Borkheide",
      "bl": 12,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "12069056",
      "j": 2023,
      "n": "borkwalde",
      "dn": "Borkwalde",
      "bl": 12,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "12069076",
      "j": 2023,
      "n": "brück",
      "dn": "Brück",
      "bl": 12,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "12069089",
      "j": 2023,
      "n": "buckautal",
      "dn": "Buckautal",
      "bl": 12,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "12069216",
      "j": 2023,
      "n": "golzow, mittelmark",
      "dn": "Golzow, Mittelmark",
      "bl": 12,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "12069224",
      "j": 2023,
      "n": "görzke",
      "dn": "Görzke",
      "bl": 12,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "12069232",
      "j": 2023,
      "n": "gräben",
      "dn": "Gräben",
      "bl": 12,
      "hs": 455.0,
      "mns": 2
    },
    {
      "a": "12069249",
      "j": 2023,
      "n": "groß kreutz (havel)",
      "dn": "Groß Kreutz (Havel)",
      "bl": 12,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "12069270",
      "j": 2023,
      "n": "havelsee",
      "dn": "Havelsee",
      "bl": 12,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "12069304",
      "j": 2023,
      "n": "kleinmachnow",
      "dn": "Kleinmachnow",
      "bl": 12,
      "hs": 365.0,
      "mns": 4
    },
    {
      "a": "12069306",
      "j": 2023,
      "n": "kloster lehnin",
      "dn": "Kloster Lehnin",
      "bl": 12,
      "hs": 411.0,
      "mns": 2
    },
    {
      "a": "12069345",
      "j": 2023,
      "n": "linthe",
      "dn": "Linthe",
      "bl": 12,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "12069397",
      "j": 2025,
      "n": "michendorf",
      "dn": "Michendorf",
      "bl": 12,
      "hs": 200.0,
      "mns": 5
    },
    {
      "a": "12069402",
      "j": 2023,
      "n": "mühlenfließ",
      "dn": "Mühlenfließ",
      "bl": 12,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "12069448",
      "j": 2023,
      "n": "niemegk",
      "dn": "Niemegk",
      "bl": 12,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "12069454",
      "j": 2023,
      "n": "nuthetal",
      "dn": "Nuthetal",
      "bl": 12,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "12069460",
      "j": 2023,
      "n": "päwesin",
      "dn": "Päwesin",
      "bl": 12,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "12069470",
      "j": 2023,
      "n": "planebruch",
      "dn": "Planebruch",
      "bl": 12,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "12069474",
      "j": 2023,
      "n": "planetal",
      "dn": "Planetal",
      "bl": 12,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "12069485",
      "j": 2023,
      "n": "rabenstein/fläming",
      "dn": "Rabenstein/Fläming",
      "bl": 12,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "12069537",
      "j": 2023,
      "n": "rosenau",
      "dn": "Rosenau",
      "bl": 12,
      "hs": 545.0,
      "mns": 2
    },
    {
      "a": "12069541",
      "j": 2023,
      "n": "roskow",
      "dn": "Roskow",
      "bl": 12,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "12069590",
      "j": 2023,
      "n": "schwielowsee",
      "dn": "Schwielowsee",
      "bl": 12,
      "hs": 390.0,
      "mns": 4
    },
    {
      "a": "12069596",
      "j": 2023,
      "n": "seddiner see",
      "dn": "Seddiner See",
      "bl": 12,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "12069604",
      "j": 2023,
      "n": "stahnsdorf",
      "dn": "Stahnsdorf",
      "bl": 12,
      "hs": 420.0,
      "mns": 4
    },
    {
      "a": "12069616",
      "j": 2023,
      "n": "teltow",
      "dn": "Teltow",
      "bl": 12,
      "hs": 400.0,
      "mns": 4
    },
    {
      "a": "12069632",
      "j": 2023,
      "n": "treuenbrietzen",
      "dn": "Treuenbrietzen",
      "bl": 12,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "12069648",
      "j": 2023,
      "n": "wenzlow",
      "dn": "Wenzlow",
      "bl": 12,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "12069656",
      "j": 2023,
      "n": "werder (havel)",
      "dn": "Werder (Havel)",
      "bl": 12,
      "hs": 385.0,
      "mns": 3
    },
    {
      "a": "12069665",
      "j": 2023,
      "n": "wiesenburg/mark",
      "dn": "Wiesenburg/Mark",
      "bl": 12,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "12069680",
      "j": 2023,
      "n": "wollin",
      "dn": "Wollin",
      "bl": 12,
      "hs": 457.0,
      "mns": 2
    },
    {
      "a": "12069688",
      "j": 2023,
      "n": "wusterwitz",
      "dn": "Wusterwitz",
      "bl": 12,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "12069696",
      "j": 2023,
      "n": "ziesar",
      "dn": "Ziesar",
      "bl": 12,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "12070008",
      "j": 2023,
      "n": "bad wilsnack",
      "dn": "Bad Wilsnack",
      "bl": 12,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "12070028",
      "j": 2023,
      "n": "berge",
      "dn": "Berge",
      "bl": 12,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "12070052",
      "j": 2023,
      "n": "breese",
      "dn": "Breese",
      "bl": 12,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "12070060",
      "j": 2023,
      "n": "cumlosen",
      "dn": "Cumlosen",
      "bl": 12,
      "hs": 413.0,
      "mns": 1
    },
    {
      "a": "12070096",
      "j": 2023,
      "n": "gerdshagen",
      "dn": "Gerdshagen",
      "bl": 12,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "12070125",
      "j": 2023,
      "n": "groß pankow",
      "dn": "Groß Pankow",
      "bl": 12,
      "hs": 381.0,
      "mns": 1
    },
    {
      "a": "12070145",
      "j": 2023,
      "n": "gülitz-reetz",
      "dn": "Gülitz-Reetz",
      "bl": 12,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "12070149",
      "j": 2023,
      "n": "gumtow",
      "dn": "Gumtow",
      "bl": 12,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "12070153",
      "j": 2023,
      "n": "halenbeck-rohlsdorf",
      "dn": "Halenbeck-Rohlsdorf",
      "bl": 12,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "12070173",
      "j": 2023,
      "n": "karstädt",
      "dn": "Karstädt",
      "bl": 12,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "12070222",
      "j": 2023,
      "n": "kümmernitztal",
      "dn": "Kümmernitztal",
      "bl": 12,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "12070236",
      "j": 2023,
      "n": "lanz",
      "dn": "Lanz",
      "bl": 12,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "12070241",
      "j": 2023,
      "n": "legde/quitzöbel",
      "dn": "Legde/Quitzöbel",
      "bl": 12,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "12070244",
      "j": 2023,
      "n": "lenzen (elbe)",
      "dn": "Lenzen (Elbe)",
      "bl": 12,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "12070246",
      "j": 2023,
      "n": "lenzerwische",
      "dn": "Lenzerwische",
      "bl": 12,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "12070266",
      "j": 2023,
      "n": "marienfließ",
      "dn": "Marienfließ",
      "bl": 12,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "12070280",
      "j": 2023,
      "n": "meyenburg",
      "dn": "Meyenburg",
      "bl": 12,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "12070296",
      "j": 2023,
      "n": "perleberg",
      "dn": "Perleberg",
      "bl": 12,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "12070300",
      "j": 2023,
      "n": "pirow",
      "dn": "Pirow",
      "bl": 12,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "12070302",
      "j": 2023,
      "n": "plattenburg",
      "dn": "Plattenburg",
      "bl": 12,
      "hs": 479.0,
      "mns": 1
    },
    {
      "a": "12070316",
      "j": 2023,
      "n": "pritzwalk",
      "dn": "Pritzwalk",
      "bl": 12,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "12070325",
      "j": 2023,
      "n": "putlitz",
      "dn": "Putlitz",
      "bl": 12,
      "hs": 384.0,
      "mns": 1
    },
    {
      "a": "12070348",
      "j": 2023,
      "n": "rühstädt",
      "dn": "Rühstädt",
      "bl": 12,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "12070393",
      "j": 2023,
      "n": "triglitz",
      "dn": "Triglitz",
      "bl": 12,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "12070416",
      "j": 2023,
      "n": "weisen",
      "dn": "Weisen",
      "bl": 12,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "12070424",
      "j": 2023,
      "n": "wittenberge",
      "dn": "Wittenberge",
      "bl": 12,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "12071028",
      "j": 2023,
      "n": "briesen",
      "dn": "Briesen",
      "bl": 12,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "12071032",
      "j": 2023,
      "n": "burg (spreewald)",
      "dn": "Burg (Spreewald)",
      "bl": 12,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "12071041",
      "j": 2023,
      "n": "dissen-striesow",
      "dn": "Dissen-Striesow",
      "bl": 12,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "12071044",
      "j": 2023,
      "n": "döbern",
      "dn": "Döbern",
      "bl": 12,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "12071052",
      "j": 2023,
      "n": "drachhausen",
      "dn": "Drachhausen",
      "bl": 12,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "12071057",
      "j": 2023,
      "n": "drebkau",
      "dn": "Drebkau",
      "bl": 12,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "12071060",
      "j": 2023,
      "n": "drehnow",
      "dn": "Drehnow",
      "bl": 12,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "12071074",
      "j": 2023,
      "n": "felixsee",
      "dn": "Felixsee",
      "bl": 12,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "12071076",
      "j": 2023,
      "n": "forst (lausitz)",
      "dn": "Forst (Lausitz)",
      "bl": 12,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "12071153",
      "j": 2023,
      "n": "groß schacksdorf-simmersdorf",
      "dn": "Groß Schacksdorf-Simmersdorf",
      "bl": 12,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "12071160",
      "j": 2023,
      "n": "guben",
      "dn": "Guben",
      "bl": 12,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "12071164",
      "j": 2023,
      "n": "guhrow",
      "dn": "Guhrow",
      "bl": 12,
      "hs": 393.0,
      "mns": 1
    },
    {
      "a": "12071176",
      "j": 2023,
      "n": "heinersbrück",
      "dn": "Heinersbrück",
      "bl": 12,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "12071189",
      "j": 2023,
      "n": "jämlitz-klein düben",
      "dn": "Jämlitz-Klein Düben",
      "bl": 12,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "12071193",
      "j": 2023,
      "n": "jänschwalde",
      "dn": "Jänschwalde",
      "bl": 12,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "12071244",
      "j": 2023,
      "n": "kolkwitz",
      "dn": "Kolkwitz",
      "bl": 12,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "12071294",
      "j": 2023,
      "n": "neiße-malxetal",
      "dn": "Neiße-Malxetal",
      "bl": 12,
      "hs": 374.0,
      "mns": 1
    },
    {
      "a": "12071301",
      "j": 2023,
      "n": "neuhausen/spree",
      "dn": "Neuhausen/Spree",
      "bl": 12,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "12071304",
      "j": 2023,
      "n": "peitz",
      "dn": "Peitz",
      "bl": 12,
      "hs": 394.0,
      "mns": 1
    },
    {
      "a": "12071337",
      "j": 2023,
      "n": "schenkendöbern",
      "dn": "Schenkendöbern",
      "bl": 12,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "12071341",
      "j": 2023,
      "n": "schmogrow-fehrow",
      "dn": "Schmogrow-Fehrow",
      "bl": 12,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "12071372",
      "j": 2023,
      "n": "spremberg",
      "dn": "Spremberg",
      "bl": 12,
      "hs": 417.0,
      "mns": 2
    },
    {
      "a": "12071384",
      "j": 2023,
      "n": "tauer",
      "dn": "Tauer",
      "bl": 12,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "12071386",
      "j": 2023,
      "n": "teichland",
      "dn": "Teichland",
      "bl": 12,
      "hs": 391.0,
      "mns": 1
    },
    {
      "a": "12071392",
      "j": 2023,
      "n": "tschernitz",
      "dn": "Tschernitz",
      "bl": 12,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "12071401",
      "j": 2023,
      "n": "turnow-preilack",
      "dn": "Turnow-Preilack",
      "bl": 12,
      "hs": 381.0,
      "mns": 1
    },
    {
      "a": "12071408",
      "j": 2023,
      "n": "welzow",
      "dn": "Welzow",
      "bl": 12,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "12071412",
      "j": 2023,
      "n": "werben",
      "dn": "Werben",
      "bl": 12,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "12071414",
      "j": 2023,
      "n": "wiesengrund",
      "dn": "Wiesengrund",
      "bl": 12,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "12072002",
      "j": 2023,
      "n": "am mellensee",
      "dn": "Am Mellensee",
      "bl": 12,
      "hs": 394.0,
      "mns": 2
    },
    {
      "a": "12072014",
      "j": 2023,
      "n": "baruth/mark",
      "dn": "Baruth/Mark",
      "bl": 12,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "12072017",
      "j": 2023,
      "n": "blankenfelde-mahlow",
      "dn": "Blankenfelde-Mahlow",
      "bl": 12,
      "hs": 360.0,
      "mns": 4
    },
    {
      "a": "12072053",
      "j": 2023,
      "n": "dahme/mark",
      "dn": "Dahme/Mark",
      "bl": 12,
      "hs": 395.0,
      "mns": 2
    },
    {
      "a": "12072055",
      "j": 2023,
      "n": "dahmetal",
      "dn": "Dahmetal",
      "bl": 12,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "12072120",
      "j": 2023,
      "n": "großbeeren",
      "dn": "Großbeeren",
      "bl": 12,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "12072157",
      "j": 2023,
      "n": "ihlow, fläming",
      "dn": "Ihlow, Fläming",
      "bl": 12,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "12072169",
      "j": 2023,
      "n": "jüterbog",
      "dn": "Jüterbog",
      "bl": 12,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "12072232",
      "j": 2023,
      "n": "luckenwalde",
      "dn": "Luckenwalde",
      "bl": 12,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "12072240",
      "j": 2025,
      "n": "ludwigsfelde",
      "dn": "Ludwigsfelde",
      "bl": 12,
      "hs": 322.0,
      "mns": 3
    },
    {
      "a": "12072297",
      "j": 2023,
      "n": "niedergörsdorf",
      "dn": "Niedergörsdorf",
      "bl": 12,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "12072298",
      "j": 2023,
      "n": "niederer fläming",
      "dn": "Niederer Fläming",
      "bl": 12,
      "hs": 385.0,
      "mns": 2
    },
    {
      "a": "12072312",
      "j": 2023,
      "n": "nuthe-urstromtal",
      "dn": "Nuthe-Urstromtal",
      "bl": 12,
      "hs": 391.0,
      "mns": 2
    },
    {
      "a": "12072340",
      "j": 2023,
      "n": "rangsdorf",
      "dn": "Rangsdorf",
      "bl": 12,
      "hs": 413.0,
      "mns": 3
    },
    {
      "a": "12072426",
      "j": 2023,
      "n": "trebbin",
      "dn": "Trebbin",
      "bl": 12,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "12072477",
      "j": 2025,
      "n": "zossen",
      "dn": "Zossen",
      "bl": 12,
      "hs": 290.0,
      "mns": 2
    },
    {
      "a": "12073008",
      "j": 2024,
      "n": "angermünde",
      "dn": "Angermünde",
      "bl": 12,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "12073069",
      "j": 2023,
      "n": "boitzenburger land",
      "dn": "Boitzenburger Land",
      "bl": 12,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "12073085",
      "j": 2023,
      "n": "brüssow",
      "dn": "Brüssow",
      "bl": 12,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "12073093",
      "j": 2023,
      "n": "carmzow-wallmow",
      "dn": "Carmzow-Wallmow",
      "bl": 12,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "12073097",
      "j": 2023,
      "n": "casekow",
      "dn": "Casekow",
      "bl": 12,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "12073157",
      "j": 2023,
      "n": "flieth-stegelitz",
      "dn": "Flieth-Stegelitz",
      "bl": 12,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "12073189",
      "j": 2023,
      "n": "gartz (oder)",
      "dn": "Gartz (Oder)",
      "bl": 12,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "12073201",
      "j": 2023,
      "n": "gerswalde",
      "dn": "Gerswalde",
      "bl": 12,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "12073216",
      "j": 2023,
      "n": "göritz",
      "dn": "Göritz",
      "bl": 12,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "12073225",
      "j": 2023,
      "n": "gramzow",
      "dn": "Gramzow",
      "bl": 12,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "12073261",
      "j": 2023,
      "n": "grünow",
      "dn": "Grünow",
      "bl": 12,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "12073309",
      "j": 2023,
      "n": "hohenselchow-groß pinnow",
      "dn": "Hohenselchow-Groß Pinnow",
      "bl": 12,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "12073384",
      "j": 2023,
      "n": "lychen",
      "dn": "Lychen",
      "bl": 12,
      "hs": 393.0,
      "mns": 1
    },
    {
      "a": "12073386",
      "j": 2022,
      "n": "mark landin",
      "dn": "Mark Landin",
      "bl": 12,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "12073393",
      "j": 2023,
      "n": "mescherin",
      "dn": "Mescherin",
      "bl": 12,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "12073396",
      "j": 2023,
      "n": "milmersdorf",
      "dn": "Milmersdorf",
      "bl": 12,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "12073404",
      "j": 2023,
      "n": "mittenwalde",
      "dn": "Mittenwalde",
      "bl": 12,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "12073429",
      "j": 2023,
      "n": "nordwestuckermark",
      "dn": "Nordwestuckermark",
      "bl": 12,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "12073430",
      "j": 2023,
      "n": "oberuckersee",
      "dn": "Oberuckersee",
      "bl": 12,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "12073440",
      "j": 2023,
      "n": "pinnow",
      "dn": "Pinnow",
      "bl": 12,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "12073452",
      "j": 2023,
      "n": "prenzlau",
      "dn": "Prenzlau",
      "bl": 12,
      "hs": 445.0,
      "mns": 2
    },
    {
      "a": "12073458",
      "j": 2023,
      "n": "randowtal",
      "dn": "Randowtal",
      "bl": 12,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "12073490",
      "j": 2023,
      "n": "schenkenberg",
      "dn": "Schenkenberg",
      "bl": 12,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "12073520",
      "j": 2023,
      "n": "schönfeld",
      "dn": "Schönfeld",
      "bl": 12,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "12073532",
      "j": 2023,
      "n": "schwedt/oder",
      "dn": "Schwedt/Oder",
      "bl": 12,
      "hs": 443.0,
      "mns": 2
    },
    {
      "a": "12073565",
      "j": 2023,
      "n": "tantow",
      "dn": "Tantow",
      "bl": 12,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "12073569",
      "j": 2023,
      "n": "temmen-ringenwalde",
      "dn": "Temmen-Ringenwalde",
      "bl": 12,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "12073572",
      "j": 2023,
      "n": "templin",
      "dn": "Templin",
      "bl": 12,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "12073578",
      "j": 2023,
      "n": "uckerfelde",
      "dn": "Uckerfelde",
      "bl": 12,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "12073579",
      "j": 2023,
      "n": "uckerland",
      "dn": "Uckerland",
      "bl": 12,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "12073603",
      "j": 2022,
      "n": "passow",
      "dn": "Passow",
      "bl": 12,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "12073645",
      "j": 2023,
      "n": "zichow",
      "dn": "Zichow",
      "bl": 12,
      "hs": 410.0,
      "mns": 1
    }
  ],
  "13": [
    {
      "a": "13003000",
      "j": 2023,
      "n": "rostock",
      "dn": "Rostock",
      "bl": 13,
      "hs": 520.0,
      "mns": 4
    },
    {
      "a": "13004000",
      "j": 2023,
      "n": "schwerin",
      "dn": "Schwerin",
      "bl": 13,
      "hs": 595.0,
      "mns": 3
    },
    {
      "a": "13071001",
      "j": 2023,
      "n": "alt schwerin",
      "dn": "Alt Schwerin",
      "bl": 13,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "13071002",
      "j": 2023,
      "n": "altenhagen",
      "dn": "Altenhagen",
      "bl": 13,
      "hs": 406.0,
      "mns": 1
    },
    {
      "a": "13071003",
      "j": 2023,
      "n": "altenhof",
      "dn": "Altenhof",
      "bl": 13,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "13071004",
      "j": 2023,
      "n": "altentreptow",
      "dn": "Altentreptow",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13071005",
      "j": 2023,
      "n": "ankershagen",
      "dn": "Ankershagen",
      "bl": 13,
      "hs": 408.0,
      "mns": 1
    },
    {
      "a": "13071006",
      "j": 2023,
      "n": "bartow",
      "dn": "Bartow",
      "bl": 13,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "13071007",
      "j": 2023,
      "n": "basedow",
      "dn": "Basedow",
      "bl": 13,
      "hs": 396.0,
      "mns": 1
    },
    {
      "a": "13071008",
      "j": 2023,
      "n": "beggerow",
      "dn": "Beggerow",
      "bl": 13,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "13071009",
      "j": 2023,
      "n": "beseritz",
      "dn": "Beseritz",
      "bl": 13,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "13071010",
      "j": 2023,
      "n": "blankenhof",
      "dn": "Blankenhof",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13071011",
      "j": 2023,
      "n": "blankensee",
      "dn": "Blankensee",
      "bl": 13,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "13071012",
      "j": 2023,
      "n": "blumenholz",
      "dn": "Blumenholz",
      "bl": 13,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "13071013",
      "j": 2023,
      "n": "bollewick",
      "dn": "Bollewick",
      "bl": 13,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "13071014",
      "j": 2023,
      "n": "borrentin",
      "dn": "Borrentin",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13071015",
      "j": 2023,
      "n": "bredenfelde",
      "dn": "Bredenfelde",
      "bl": 13,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "13071016",
      "j": 2023,
      "n": "breesen",
      "dn": "Breesen",
      "bl": 13,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "13071017",
      "j": 2023,
      "n": "breest",
      "dn": "Breest",
      "bl": 13,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "13071018",
      "j": 2023,
      "n": "briggow",
      "dn": "Briggow",
      "bl": 13,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "13071019",
      "j": 2023,
      "n": "brunn",
      "dn": "Brunn",
      "bl": 13,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "13071020",
      "j": 2023,
      "n": "buchholz",
      "dn": "Buchholz",
      "bl": 13,
      "hs": 396.0,
      "mns": 1
    },
    {
      "a": "13071021",
      "j": 2023,
      "n": "burg stargard",
      "dn": "Burg Stargard",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13071022",
      "j": 2023,
      "n": "burow",
      "dn": "Burow",
      "bl": 13,
      "hs": 407.0,
      "mns": 1
    },
    {
      "a": "13071023",
      "j": 2023,
      "n": "bütow",
      "dn": "Bütow",
      "bl": 13,
      "hs": 310.0,
      "mns": 1
    },
    {
      "a": "13071025",
      "j": 2023,
      "n": "carpin",
      "dn": "Carpin",
      "bl": 13,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "13071026",
      "j": 2023,
      "n": "cölpin",
      "dn": "Cölpin",
      "bl": 13,
      "hs": 396.0,
      "mns": 1
    },
    {
      "a": "13071027",
      "j": 2023,
      "n": "dargun",
      "dn": "Dargun",
      "bl": 13,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "13071028",
      "j": 2023,
      "n": "datzetal",
      "dn": "Datzetal",
      "bl": 13,
      "hs": 408.0,
      "mns": 1
    },
    {
      "a": "13071029",
      "j": 2023,
      "n": "demmin",
      "dn": "Demmin",
      "bl": 13,
      "hs": 375.0,
      "mns": 2
    },
    {
      "a": "13071032",
      "j": 2023,
      "n": "faulenrost",
      "dn": "Faulenrost",
      "bl": 13,
      "hs": 396.0,
      "mns": 1
    },
    {
      "a": "13071033",
      "j": 2023,
      "n": "feldberger seenlandschaft",
      "dn": "Feldberger Seenlandschaft",
      "bl": 13,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "13071034",
      "j": 2023,
      "n": "fincken",
      "dn": "Fincken",
      "bl": 13,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "13071035",
      "j": 2023,
      "n": "friedland",
      "dn": "Friedland",
      "bl": 13,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "13071036",
      "j": 2023,
      "n": "fünfseen",
      "dn": "Fünfseen",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13071037",
      "j": 2023,
      "n": "galenbeck",
      "dn": "Galenbeck",
      "bl": 13,
      "hs": 414.0,
      "mns": 1
    },
    {
      "a": "13071039",
      "j": 2023,
      "n": "gielow",
      "dn": "Gielow",
      "bl": 13,
      "hs": 403.0,
      "mns": 1
    },
    {
      "a": "13071041",
      "j": 2023,
      "n": "gnevkow",
      "dn": "Gnevkow",
      "bl": 13,
      "hs": 386.0,
      "mns": 1
    },
    {
      "a": "13071042",
      "j": 2023,
      "n": "godendorf",
      "dn": "Godendorf",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13071043",
      "j": 2023,
      "n": "göhren-lebbin",
      "dn": "Göhren-Lebbin",
      "bl": 13,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "13071044",
      "j": 2023,
      "n": "golchen",
      "dn": "Golchen",
      "bl": 13,
      "hs": 408.0,
      "mns": 1
    },
    {
      "a": "13071045",
      "j": 2023,
      "n": "gotthun",
      "dn": "Gotthun",
      "bl": 13,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "13071047",
      "j": 2023,
      "n": "grabowhöfe",
      "dn": "Grabowhöfe",
      "bl": 13,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "13071048",
      "j": 2023,
      "n": "grammentin",
      "dn": "Grammentin",
      "bl": 13,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "13071049",
      "j": 2023,
      "n": "grapzow",
      "dn": "Grapzow",
      "bl": 13,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "13071050",
      "j": 2023,
      "n": "grischow",
      "dn": "Grischow",
      "bl": 13,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "13071053",
      "j": 2023,
      "n": "groß kelle",
      "dn": "Groß Kelle",
      "bl": 13,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "13071054",
      "j": 2023,
      "n": "groß miltzow",
      "dn": "Groß Miltzow",
      "bl": 13,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "13071055",
      "j": 2023,
      "n": "groß nemerow",
      "dn": "Groß Nemerow",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13071056",
      "j": 2023,
      "n": "groß plasten",
      "dn": "Groß Plasten",
      "bl": 13,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "13071057",
      "j": 2023,
      "n": "groß teetzleben",
      "dn": "Groß Teetzleben",
      "bl": 13,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "13071058",
      "j": 2023,
      "n": "grünow",
      "dn": "Grünow",
      "bl": 13,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "13071059",
      "j": 2023,
      "n": "gültz",
      "dn": "Gültz",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13071060",
      "j": 2023,
      "n": "gülzow",
      "dn": "Gülzow",
      "bl": 13,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "13071063",
      "j": 2023,
      "n": "hohen wangelin",
      "dn": "Hohen Wangelin",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13071064",
      "j": 2023,
      "n": "hohenbollentin",
      "dn": "Hohenbollentin",
      "bl": 13,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "13071065",
      "j": 2023,
      "n": "hohenmocker",
      "dn": "Hohenmocker",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13071066",
      "j": 2023,
      "n": "hohenzieritz",
      "dn": "Hohenzieritz",
      "bl": 13,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "13071067",
      "j": 2023,
      "n": "holldorf",
      "dn": "Holldorf",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13071068",
      "j": 2023,
      "n": "ivenack",
      "dn": "Ivenack",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13071069",
      "j": 2023,
      "n": "jabel",
      "dn": "Jabel",
      "bl": 13,
      "hs": 396.0,
      "mns": 1
    },
    {
      "a": "13071070",
      "j": 2023,
      "n": "jürgenstorf",
      "dn": "Jürgenstorf",
      "bl": 13,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "13071071",
      "j": 2023,
      "n": "kargow",
      "dn": "Kargow",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13071072",
      "j": 2023,
      "n": "kentzlin",
      "dn": "Kentzlin",
      "bl": 13,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "13071073",
      "j": 2023,
      "n": "kieve",
      "dn": "Kieve",
      "bl": 13,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "13071074",
      "j": 2023,
      "n": "kittendorf",
      "dn": "Kittendorf",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13071075",
      "j": 2023,
      "n": "klein vielen",
      "dn": "Klein Vielen",
      "bl": 13,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "13071076",
      "j": 2023,
      "n": "kletzin",
      "dn": "Kletzin",
      "bl": 13,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "13071077",
      "j": 2023,
      "n": "klink",
      "dn": "Klink",
      "bl": 13,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "13071078",
      "j": 2023,
      "n": "klocksin",
      "dn": "Klocksin",
      "bl": 13,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "13071079",
      "j": 2023,
      "n": "knorrendorf",
      "dn": "Knorrendorf",
      "bl": 13,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "13071080",
      "j": 2023,
      "n": "kratzeburg",
      "dn": "Kratzeburg",
      "bl": 13,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "13071081",
      "j": 2023,
      "n": "kriesow",
      "dn": "Kriesow",
      "bl": 13,
      "hs": 386.0,
      "mns": 1
    },
    {
      "a": "13071083",
      "j": 2023,
      "n": "kublank",
      "dn": "Kublank",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13071084",
      "j": 2023,
      "n": "kummerow",
      "dn": "Kummerow",
      "bl": 13,
      "hs": 396.0,
      "mns": 1
    },
    {
      "a": "13071087",
      "j": 2023,
      "n": "lärz",
      "dn": "Lärz",
      "bl": 13,
      "hs": 386.0,
      "mns": 1
    },
    {
      "a": "13071088",
      "j": 2023,
      "n": "leizen",
      "dn": "Leizen",
      "bl": 13,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "13071089",
      "j": 2023,
      "n": "lindenberg",
      "dn": "Lindenberg",
      "bl": 13,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "13071090",
      "j": 2023,
      "n": "lindetal",
      "dn": "Lindetal",
      "bl": 13,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "13071092",
      "j": 2023,
      "n": "malchin",
      "dn": "Malchin",
      "bl": 13,
      "hs": 399.0,
      "mns": 1
    },
    {
      "a": "13071093",
      "j": 2023,
      "n": "malchow",
      "dn": "Malchow",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13071096",
      "j": 2023,
      "n": "meesiger",
      "dn": "Meesiger",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13071097",
      "j": 2023,
      "n": "melz",
      "dn": "Melz",
      "bl": 13,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "13071099",
      "j": 2023,
      "n": "mirow",
      "dn": "Mirow",
      "bl": 13,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "13071100",
      "j": 2023,
      "n": "möllenbeck",
      "dn": "Möllenbeck",
      "bl": 13,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "13071101",
      "j": 2023,
      "n": "möllenhagen",
      "dn": "Möllenhagen",
      "bl": 13,
      "hs": 414.0,
      "mns": 1
    },
    {
      "a": "13071102",
      "j": 2023,
      "n": "mölln",
      "dn": "Mölln",
      "bl": 13,
      "hs": 408.0,
      "mns": 1
    },
    {
      "a": "13071103",
      "j": 2023,
      "n": "moltzow",
      "dn": "Moltzow",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13071104",
      "j": 2023,
      "n": "neddemin",
      "dn": "Neddemin",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13071105",
      "j": 2023,
      "n": "neetzka",
      "dn": "Neetzka",
      "bl": 13,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "13071107",
      "j": 2023,
      "n": "neubrandenburg",
      "dn": "Neubrandenburg",
      "bl": 13,
      "hs": 550.0,
      "mns": 2
    },
    {
      "a": "13071108",
      "j": 2023,
      "n": "neuenkirchen",
      "dn": "Neuenkirchen",
      "bl": 13,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "13071109",
      "j": 2023,
      "n": "neukalen",
      "dn": "Neukalen",
      "bl": 13,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "13071110",
      "j": 2023,
      "n": "neustrelitz",
      "dn": "Neustrelitz",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13071111",
      "j": 2023,
      "n": "neverin",
      "dn": "Neverin",
      "bl": 13,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "13071112",
      "j": 2023,
      "n": "nossendorf",
      "dn": "Nossendorf",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13071113",
      "j": 2023,
      "n": "nossentiner hütte",
      "dn": "Nossentiner Hütte",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13071114",
      "j": 2023,
      "n": "penkow",
      "dn": "Penkow",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13071115",
      "j": 2023,
      "n": "penzlin",
      "dn": "Penzlin",
      "bl": 13,
      "hs": 416.0,
      "mns": 1
    },
    {
      "a": "13071117",
      "j": 2023,
      "n": "pragsdorf",
      "dn": "Pragsdorf",
      "bl": 13,
      "hs": 396.0,
      "mns": 1
    },
    {
      "a": "13071118",
      "j": 2023,
      "n": "priborn",
      "dn": "Priborn",
      "bl": 13,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "13071119",
      "j": 2023,
      "n": "priepert",
      "dn": "Priepert",
      "bl": 13,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "13071120",
      "j": 2023,
      "n": "pripsleben",
      "dn": "Pripsleben",
      "bl": 13,
      "hs": 406.0,
      "mns": 1
    },
    {
      "a": "13071122",
      "j": 2023,
      "n": "rechlin",
      "dn": "Rechlin",
      "bl": 13,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "13071123",
      "j": 2023,
      "n": "ritzerow",
      "dn": "Ritzerow",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13071124",
      "j": 2023,
      "n": "röbel/müritz",
      "dn": "Röbel/Müritz",
      "bl": 13,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "13071125",
      "j": 2023,
      "n": "röckwitz",
      "dn": "Röckwitz",
      "bl": 13,
      "hs": 388.0,
      "mns": 1
    },
    {
      "a": "13071127",
      "j": 2023,
      "n": "rosenow",
      "dn": "Rosenow",
      "bl": 13,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "13071128",
      "j": 2023,
      "n": "sarow",
      "dn": "Sarow",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13071130",
      "j": 2023,
      "n": "schönbeck",
      "dn": "Schönbeck",
      "bl": 13,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "13071131",
      "j": 2023,
      "n": "schönfeld",
      "dn": "Schönfeld",
      "bl": 13,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "13071132",
      "j": 2023,
      "n": "schönhausen",
      "dn": "Schönhausen",
      "bl": 13,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "13071133",
      "j": 2023,
      "n": "schwarz",
      "dn": "Schwarz",
      "bl": 13,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "13071135",
      "j": 2023,
      "n": "siedenbollentin",
      "dn": "Siedenbollentin",
      "bl": 13,
      "hs": 408.0,
      "mns": 1
    },
    {
      "a": "13071136",
      "j": 2023,
      "n": "siedenbrünzow",
      "dn": "Siedenbrünzow",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13071137",
      "j": 2023,
      "n": "sietow",
      "dn": "Sietow",
      "bl": 13,
      "hs": 315.0,
      "mns": 1
    },
    {
      "a": "13071138",
      "j": 2023,
      "n": "silz",
      "dn": "Silz",
      "bl": 13,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "13071139",
      "j": 2023,
      "n": "sommersdorf",
      "dn": "Sommersdorf",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13071140",
      "j": 2023,
      "n": "sponholz",
      "dn": "Sponholz",
      "bl": 13,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "13071141",
      "j": 2023,
      "n": "staven",
      "dn": "Staven",
      "bl": 13,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "13071142",
      "j": 2023,
      "n": "stavenhagen",
      "dn": "Stavenhagen",
      "bl": 13,
      "hs": 407.0,
      "mns": 1
    },
    {
      "a": "13071143",
      "j": 2023,
      "n": "stuer",
      "dn": "Stuer",
      "bl": 13,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "13071144",
      "j": 2023,
      "n": "torgelow am see",
      "dn": "Torgelow am See",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13071145",
      "j": 2023,
      "n": "trollenhagen",
      "dn": "Trollenhagen",
      "bl": 13,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "13071146",
      "j": 2023,
      "n": "tützpatz",
      "dn": "Tützpatz",
      "bl": 13,
      "hs": 406.0,
      "mns": 1
    },
    {
      "a": "13071147",
      "j": 2023,
      "n": "userin",
      "dn": "Userin",
      "bl": 13,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "13071148",
      "j": 2023,
      "n": "utzedel",
      "dn": "Utzedel",
      "bl": 13,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "13071150",
      "j": 2023,
      "n": "verchen",
      "dn": "Verchen",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13071153",
      "j": 2023,
      "n": "voigtsdorf",
      "dn": "Voigtsdorf",
      "bl": 13,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "13071154",
      "j": 2023,
      "n": "vollrathsruhe",
      "dn": "Vollrathsruhe",
      "bl": 13,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "13071155",
      "j": 2023,
      "n": "walow",
      "dn": "Walow",
      "bl": 13,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "13071156",
      "j": 2023,
      "n": "waren (müritz)",
      "dn": "Waren (Müritz)",
      "bl": 13,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "13071157",
      "j": 2023,
      "n": "warrenzin",
      "dn": "Warrenzin",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13071158",
      "j": 2023,
      "n": "werder",
      "dn": "Werder",
      "bl": 13,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "13071159",
      "j": 2023,
      "n": "wesenberg",
      "dn": "Wesenberg",
      "bl": 13,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "13071160",
      "j": 2023,
      "n": "wildberg",
      "dn": "Wildberg",
      "bl": 13,
      "hs": 408.0,
      "mns": 1
    },
    {
      "a": "13071161",
      "j": 2023,
      "n": "woggersin",
      "dn": "Woggersin",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13071162",
      "j": 2023,
      "n": "wokuhl-dabelow",
      "dn": "Wokuhl-Dabelow",
      "bl": 13,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "13071163",
      "j": 2023,
      "n": "wolde",
      "dn": "Wolde",
      "bl": 13,
      "hs": 406.0,
      "mns": 1
    },
    {
      "a": "13071164",
      "j": 2023,
      "n": "woldegk",
      "dn": "Woldegk",
      "bl": 13,
      "hs": 447.0,
      "mns": 1
    },
    {
      "a": "13071166",
      "j": 2023,
      "n": "wulkenzin",
      "dn": "Wulkenzin",
      "bl": 13,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "13071167",
      "j": 2023,
      "n": "wustrow",
      "dn": "Wustrow",
      "bl": 13,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "13071169",
      "j": 2023,
      "n": "zettemin",
      "dn": "Zettemin",
      "bl": 13,
      "hs": 408.0,
      "mns": 1
    },
    {
      "a": "13071170",
      "j": 2024,
      "n": "zirzow",
      "dn": "Zirzow",
      "bl": 13,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "13071171",
      "j": 2023,
      "n": "zislow",
      "dn": "Zislow",
      "bl": 13,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "13071172",
      "j": 2023,
      "n": "peenehagen",
      "dn": "Peenehagen",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13071173",
      "j": 2023,
      "n": "kuckssee",
      "dn": "Kuckssee",
      "bl": 13,
      "hs": 408.0,
      "mns": 1
    },
    {
      "a": "13071174",
      "j": 2023,
      "n": "schloen-dratow",
      "dn": "Schloen-Dratow",
      "bl": 13,
      "hs": 396.0,
      "mns": 1
    },
    {
      "a": "13071175",
      "j": 2023,
      "n": "eldetal",
      "dn": "Eldetal",
      "bl": 13,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "13071176",
      "j": 2023,
      "n": "südmüritz",
      "dn": "Südmüritz",
      "bl": 13,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "13072001",
      "j": 2023,
      "n": "admannshagen-bargeshagen",
      "dn": "Admannshagen-Bargeshagen",
      "bl": 13,
      "hs": 355.0,
      "mns": 2
    },
    {
      "a": "13072002",
      "j": 2023,
      "n": "alt bukow",
      "dn": "Alt Bukow",
      "bl": 13,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "13072003",
      "j": 2023,
      "n": "alt sührkow",
      "dn": "Alt Sührkow",
      "bl": 13,
      "hs": 415.0,
      "mns": 2
    },
    {
      "a": "13072004",
      "j": 2023,
      "n": "altkalen",
      "dn": "Altkalen",
      "bl": 13,
      "hs": 354.0,
      "mns": 2
    },
    {
      "a": "13072005",
      "j": 2023,
      "n": "am salzhaff",
      "dn": "Am Salzhaff",
      "bl": 13,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "13072006",
      "j": 2023,
      "n": "bad doberan",
      "dn": "Bad Doberan",
      "bl": 13,
      "hs": 430.0,
      "mns": 3
    },
    {
      "a": "13072007",
      "j": 2023,
      "n": "bartenshagen-parkentin",
      "dn": "Bartenshagen-Parkentin",
      "bl": 13,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "13072008",
      "j": 2023,
      "n": "bastorf",
      "dn": "Bastorf",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13072009",
      "j": 2023,
      "n": "baumgarten",
      "dn": "Baumgarten",
      "bl": 13,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "13072010",
      "j": 2023,
      "n": "behren-lübchin",
      "dn": "Behren-Lübchin",
      "bl": 13,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "13072011",
      "j": 2023,
      "n": "benitz",
      "dn": "Benitz",
      "bl": 13,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "13072012",
      "j": 2025,
      "n": "bentwisch",
      "dn": "Bentwisch",
      "bl": 13,
      "hs": 367.0,
      "mns": 2
    },
    {
      "a": "13072013",
      "j": 2023,
      "n": "bernitt",
      "dn": "Bernitt",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13072014",
      "j": 2023,
      "n": "biendorf",
      "dn": "Biendorf",
      "bl": 13,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "13072015",
      "j": 2023,
      "n": "blankenhagen",
      "dn": "Blankenhagen",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13072017",
      "j": 2023,
      "n": "börgerende-rethwisch",
      "dn": "Börgerende-Rethwisch",
      "bl": 13,
      "hs": 325.0,
      "mns": 2
    },
    {
      "a": "13072018",
      "j": 2023,
      "n": "bröbberow",
      "dn": "Bröbberow",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13072019",
      "j": 2023,
      "n": "broderstorf",
      "dn": "Broderstorf",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13072020",
      "j": 2023,
      "n": "bützow",
      "dn": "Bützow",
      "bl": 13,
      "hs": 412.0,
      "mns": 2
    },
    {
      "a": "13072021",
      "j": 2023,
      "n": "cammin",
      "dn": "Cammin",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13072022",
      "j": 2023,
      "n": "carinerland",
      "dn": "Carinerland",
      "bl": 13,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "13072023",
      "j": 2023,
      "n": "dahmen",
      "dn": "Dahmen",
      "bl": 13,
      "hs": 415.0,
      "mns": 2
    },
    {
      "a": "13072024",
      "j": 2023,
      "n": "dalkendorf",
      "dn": "Dalkendorf",
      "bl": 13,
      "hs": 415.0,
      "mns": 2
    },
    {
      "a": "13072026",
      "j": 2023,
      "n": "dobbin-linstow",
      "dn": "Dobbin-Linstow",
      "bl": 13,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "13072027",
      "j": 2023,
      "n": "dolgen am see",
      "dn": "Dolgen am See",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13072028",
      "j": 2023,
      "n": "dreetz",
      "dn": "Dreetz",
      "bl": 13,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "13072029",
      "j": 2023,
      "n": "dummerstorf",
      "dn": "Dummerstorf",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13072030",
      "j": 2023,
      "n": "elmenhorst/lichtenhagen",
      "dn": "Elmenhorst/Lichtenhagen",
      "bl": 13,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "13072031",
      "j": 2023,
      "n": "finkenthal",
      "dn": "Finkenthal",
      "bl": 13,
      "hs": 365.0,
      "mns": 2
    },
    {
      "a": "13072032",
      "j": 2023,
      "n": "gelbensande",
      "dn": "Gelbensande",
      "bl": 13,
      "hs": 365.0,
      "mns": 2
    },
    {
      "a": "13072033",
      "j": 2023,
      "n": "glasewitz",
      "dn": "Glasewitz",
      "bl": 13,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "13072034",
      "j": 2023,
      "n": "gnewitz",
      "dn": "Gnewitz",
      "bl": 13,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "13072035",
      "j": 2023,
      "n": "gnoien",
      "dn": "Gnoien",
      "bl": 13,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "13072036",
      "j": 2023,
      "n": "graal-müritz",
      "dn": "Graal-Müritz",
      "bl": 13,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "13072037",
      "j": 2023,
      "n": "grammow",
      "dn": "Grammow",
      "bl": 13,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "13072038",
      "j": 2023,
      "n": "groß roge",
      "dn": "Groß Roge",
      "bl": 13,
      "hs": 415.0,
      "mns": 2
    },
    {
      "a": "13072039",
      "j": 2023,
      "n": "groß schwiesow",
      "dn": "Groß Schwiesow",
      "bl": 13,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "13072040",
      "j": 2023,
      "n": "groß wokern",
      "dn": "Groß Wokern",
      "bl": 13,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "13072041",
      "j": 2023,
      "n": "groß wüstenfelde",
      "dn": "Groß Wüstenfelde",
      "bl": 13,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "13072042",
      "j": 2023,
      "n": "gülzow-prüzen",
      "dn": "Gülzow-Prüzen",
      "bl": 13,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "13072043",
      "j": 2023,
      "n": "güstrow",
      "dn": "Güstrow",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13072044",
      "j": 2023,
      "n": "gutow",
      "dn": "Gutow",
      "bl": 13,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "13072045",
      "j": 2023,
      "n": "hohen demzin",
      "dn": "Hohen Demzin",
      "bl": 13,
      "hs": 408.0,
      "mns": 2
    },
    {
      "a": "13072046",
      "j": 2023,
      "n": "hohen sprenz",
      "dn": "Hohen Sprenz",
      "bl": 13,
      "hs": 354.0,
      "mns": 2
    },
    {
      "a": "13072047",
      "j": 2023,
      "n": "hohenfelde",
      "dn": "Hohenfelde",
      "bl": 13,
      "hs": 356.0,
      "mns": 2
    },
    {
      "a": "13072048",
      "j": 2023,
      "n": "hoppenrade",
      "dn": "Hoppenrade",
      "bl": 13,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "13072049",
      "j": 2023,
      "n": "jördenstorf",
      "dn": "Jördenstorf",
      "bl": 13,
      "hs": 415.0,
      "mns": 2
    },
    {
      "a": "13072050",
      "j": 2023,
      "n": "jürgenshagen",
      "dn": "Jürgenshagen",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13072051",
      "j": 2023,
      "n": "kassow",
      "dn": "Kassow",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13072053",
      "j": 2023,
      "n": "klein belitz",
      "dn": "Klein Belitz",
      "bl": 13,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "13072055",
      "j": 2023,
      "n": "klein upahl",
      "dn": "Klein Upahl",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13072056",
      "j": 2023,
      "n": "krakow am see",
      "dn": "Krakow am See",
      "bl": 13,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "13072057",
      "j": 2023,
      "n": "kritzmow",
      "dn": "Kritzmow",
      "bl": 13,
      "hs": 375.0,
      "mns": 2
    },
    {
      "a": "13072058",
      "j": 2025,
      "n": "kröpelin",
      "dn": "Kröpelin",
      "bl": 13,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "13072059",
      "j": 2023,
      "n": "kuchelmiß",
      "dn": "Kuchelmiß",
      "bl": 13,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "13072060",
      "j": 2023,
      "n": "kühlungsborn",
      "dn": "Kühlungsborn",
      "bl": 13,
      "hs": 375.0,
      "mns": 2
    },
    {
      "a": "13072061",
      "j": 2023,
      "n": "kuhs",
      "dn": "Kuhs",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13072062",
      "j": 2023,
      "n": "laage",
      "dn": "Laage",
      "bl": 13,
      "hs": 395.0,
      "mns": 2
    },
    {
      "a": "13072063",
      "j": 2023,
      "n": "lalendorf",
      "dn": "Lalendorf",
      "bl": 13,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "13072064",
      "j": 2023,
      "n": "lambrechtshagen",
      "dn": "Lambrechtshagen",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13072066",
      "j": 2023,
      "n": "lelkendorf",
      "dn": "Lelkendorf",
      "bl": 13,
      "hs": 408.0,
      "mns": 2
    },
    {
      "a": "13072067",
      "j": 2023,
      "n": "lohmen",
      "dn": "Lohmen",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13072069",
      "j": 2023,
      "n": "lüssow",
      "dn": "Lüssow",
      "bl": 13,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "13072071",
      "j": 2023,
      "n": "mistorf",
      "dn": "Mistorf",
      "bl": 13,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "13072072",
      "j": 2023,
      "n": "mönchhagen",
      "dn": "Mönchhagen",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13072073",
      "j": 2023,
      "n": "mühl rosin",
      "dn": "Mühl Rosin",
      "bl": 13,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "13072074",
      "j": 2023,
      "n": "neubukow",
      "dn": "Neubukow",
      "bl": 13,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "13072075",
      "j": 2023,
      "n": "nienhagen",
      "dn": "Nienhagen",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13072076",
      "j": 2023,
      "n": "nustrow",
      "dn": "Nustrow",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13072077",
      "j": 2023,
      "n": "papendorf",
      "dn": "Papendorf",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13072078",
      "j": 2023,
      "n": "penzin",
      "dn": "Penzin",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13072079",
      "j": 2023,
      "n": "plaaz",
      "dn": "Plaaz",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13072080",
      "j": 2023,
      "n": "pölchow",
      "dn": "Pölchow",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13072081",
      "j": 2023,
      "n": "poppendorf",
      "dn": "Poppendorf",
      "bl": 13,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "13072082",
      "j": 2023,
      "n": "prebberede",
      "dn": "Prebberede",
      "bl": 13,
      "hs": 415.0,
      "mns": 2
    },
    {
      "a": "13072083",
      "j": 2023,
      "n": "reddelich",
      "dn": "Reddelich",
      "bl": 13,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "13072084",
      "j": 2023,
      "n": "reimershagen",
      "dn": "Reimershagen",
      "bl": 13,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "13072085",
      "j": 2023,
      "n": "rerik",
      "dn": "Rerik",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13072086",
      "j": 2023,
      "n": "retschow",
      "dn": "Retschow",
      "bl": 13,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "13072087",
      "j": 2023,
      "n": "roggentin",
      "dn": "Roggentin",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13072088",
      "j": 2023,
      "n": "rövershagen",
      "dn": "Rövershagen",
      "bl": 13,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "13072089",
      "j": 2023,
      "n": "rühn",
      "dn": "Rühn",
      "bl": 13,
      "hs": 375.0,
      "mns": 2
    },
    {
      "a": "13072090",
      "j": 2023,
      "n": "rukieten",
      "dn": "Rukieten",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13072091",
      "j": 2023,
      "n": "sanitz",
      "dn": "Sanitz",
      "bl": 13,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "13072092",
      "j": 2023,
      "n": "sarmstorf",
      "dn": "Sarmstorf",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13072093",
      "j": 2023,
      "n": "satow",
      "dn": "Satow",
      "bl": 13,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "13072094",
      "j": 2023,
      "n": "schorssow",
      "dn": "Schorssow",
      "bl": 13,
      "hs": 415.0,
      "mns": 2
    },
    {
      "a": "13072095",
      "j": 2023,
      "n": "schwaan",
      "dn": "Schwaan",
      "bl": 13,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "13072096",
      "j": 2023,
      "n": "schwasdorf",
      "dn": "Schwasdorf",
      "bl": 13,
      "hs": 415.0,
      "mns": 2
    },
    {
      "a": "13072097",
      "j": 2023,
      "n": "selpin",
      "dn": "Selpin",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13072098",
      "j": 2023,
      "n": "stäbelow",
      "dn": "Stäbelow",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13072099",
      "j": 2023,
      "n": "steffenshagen",
      "dn": "Steffenshagen",
      "bl": 13,
      "hs": 325.0,
      "mns": 2
    },
    {
      "a": "13072101",
      "j": 2023,
      "n": "steinhagen",
      "dn": "Steinhagen",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13072102",
      "j": 2023,
      "n": "stubbendorf",
      "dn": "Stubbendorf",
      "bl": 13,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "13072103",
      "j": 2023,
      "n": "sukow-levitzow",
      "dn": "Sukow-Levitzow",
      "bl": 13,
      "hs": 415.0,
      "mns": 2
    },
    {
      "a": "13072104",
      "j": 2023,
      "n": "tarnow",
      "dn": "Tarnow",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13072105",
      "j": 2023,
      "n": "tessin",
      "dn": "Tessin",
      "bl": 13,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "13072106",
      "j": 2023,
      "n": "teterow",
      "dn": "Teterow",
      "bl": 13,
      "hs": 480.0,
      "mns": 2
    },
    {
      "a": "13072107",
      "j": 2023,
      "n": "thelkow",
      "dn": "Thelkow",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13072108",
      "j": 2023,
      "n": "thulendorf",
      "dn": "Thulendorf",
      "bl": 13,
      "hs": 395.0,
      "mns": 2
    },
    {
      "a": "13072109",
      "j": 2023,
      "n": "thürkow",
      "dn": "Thürkow",
      "bl": 13,
      "hs": 408.0,
      "mns": 2
    },
    {
      "a": "13072110",
      "j": 2023,
      "n": "vorbeck",
      "dn": "Vorbeck",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13072111",
      "j": 2023,
      "n": "walkendorf",
      "dn": "Walkendorf",
      "bl": 13,
      "hs": 354.0,
      "mns": 2
    },
    {
      "a": "13072112",
      "j": 2023,
      "n": "wardow",
      "dn": "Wardow",
      "bl": 13,
      "hs": 354.0,
      "mns": 2
    },
    {
      "a": "13072113",
      "j": 2023,
      "n": "warnkenhagen",
      "dn": "Warnkenhagen",
      "bl": 13,
      "hs": 415.0,
      "mns": 2
    },
    {
      "a": "13072114",
      "j": 2023,
      "n": "warnow",
      "dn": "Warnow",
      "bl": 13,
      "hs": 415.0,
      "mns": 2
    },
    {
      "a": "13072116",
      "j": 2023,
      "n": "wiendorf",
      "dn": "Wiendorf",
      "bl": 13,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "13072117",
      "j": 2023,
      "n": "wittenbeck",
      "dn": "Wittenbeck",
      "bl": 13,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "13072118",
      "j": 2023,
      "n": "zarnewanz",
      "dn": "Zarnewanz",
      "bl": 13,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "13072119",
      "j": 2023,
      "n": "zehna",
      "dn": "Zehna",
      "bl": 13,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "13072120",
      "j": 2023,
      "n": "zepelin",
      "dn": "Zepelin",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13072121",
      "j": 2023,
      "n": "ziesendorf",
      "dn": "Ziesendorf",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13073001",
      "j": 2023,
      "n": "ahrenshagen-daskow",
      "dn": "Ahrenshagen-Daskow",
      "bl": 13,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "13073002",
      "j": 2023,
      "n": "ahrenshoop",
      "dn": "Ahrenshoop",
      "bl": 13,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "13073003",
      "j": 2023,
      "n": "altefähr",
      "dn": "Altefähr",
      "bl": 13,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "13073004",
      "j": 2023,
      "n": "altenkirchen",
      "dn": "Altenkirchen",
      "bl": 13,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "13073005",
      "j": 2023,
      "n": "altenpleen",
      "dn": "Altenpleen",
      "bl": 13,
      "hs": 366.0,
      "mns": 2
    },
    {
      "a": "13073006",
      "j": 2023,
      "n": "baabe",
      "dn": "Baabe",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13073007",
      "j": 2023,
      "n": "bad sülze",
      "dn": "Bad Sülze",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13073009",
      "j": 2023,
      "n": "barth",
      "dn": "Barth",
      "bl": 13,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "13073010",
      "j": 2023,
      "n": "bergen auf rügen",
      "dn": "Bergen auf Rügen",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13073011",
      "j": 2023,
      "n": "binz",
      "dn": "Binz",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13073012",
      "j": 2023,
      "n": "born a. darß",
      "dn": "Born a. Darß",
      "bl": 13,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "13073013",
      "j": 2023,
      "n": "breege",
      "dn": "Breege",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13073014",
      "j": 2023,
      "n": "buschvitz",
      "dn": "Buschvitz",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13073015",
      "j": 2023,
      "n": "dettmannsdorf",
      "dn": "Dettmannsdorf",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13073016",
      "j": 2023,
      "n": "deyelsdorf",
      "dn": "Deyelsdorf",
      "bl": 13,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "13073017",
      "j": 2023,
      "n": "dierhagen",
      "dn": "Dierhagen",
      "bl": 13,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "13073018",
      "j": 2023,
      "n": "divitz-spoldershagen",
      "dn": "Divitz-Spoldershagen",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13073019",
      "j": 2023,
      "n": "dranske",
      "dn": "Dranske",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13073020",
      "j": 2023,
      "n": "drechow",
      "dn": "Drechow",
      "bl": 13,
      "hs": 396.0,
      "mns": 2
    },
    {
      "a": "13073021",
      "j": 2023,
      "n": "dreschvitz",
      "dn": "Dreschvitz",
      "bl": 13,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "13073022",
      "j": 2023,
      "n": "eixen",
      "dn": "Eixen",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13073023",
      "j": 2023,
      "n": "elmenhorst",
      "dn": "Elmenhorst",
      "bl": 13,
      "hs": 406.0,
      "mns": 2
    },
    {
      "a": "13073024",
      "j": 2023,
      "n": "franzburg",
      "dn": "Franzburg",
      "bl": 13,
      "hs": 435.0,
      "mns": 2
    },
    {
      "a": "13073025",
      "j": 2023,
      "n": "fuhlendorf",
      "dn": "Fuhlendorf",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13073027",
      "j": 2023,
      "n": "garz/rügen",
      "dn": "Garz/Rügen",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13073028",
      "j": 2023,
      "n": "gingst",
      "dn": "Gingst",
      "bl": 13,
      "hs": 520.0,
      "mns": 2
    },
    {
      "a": "13073029",
      "j": 2023,
      "n": "glewitz",
      "dn": "Glewitz",
      "bl": 13,
      "hs": 435.0,
      "mns": 2
    },
    {
      "a": "13073030",
      "j": 2023,
      "n": "glowe",
      "dn": "Glowe",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13073031",
      "j": 2023,
      "n": "göhren",
      "dn": "Göhren",
      "bl": 13,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "13073032",
      "j": 2023,
      "n": "grammendorf",
      "dn": "Grammendorf",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13073033",
      "j": 2023,
      "n": "gransebieth",
      "dn": "Gransebieth",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13073034",
      "j": 2023,
      "n": "gremersdorf-buchholz",
      "dn": "Gremersdorf-Buchholz",
      "bl": 13,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "13073035",
      "j": 2023,
      "n": "grimmen",
      "dn": "Grimmen",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13073036",
      "j": 2023,
      "n": "groß kordshagen",
      "dn": "Groß Kordshagen",
      "bl": 13,
      "hs": 396.0,
      "mns": 2
    },
    {
      "a": "13073037",
      "j": 2023,
      "n": "groß mohrdorf",
      "dn": "Groß Mohrdorf",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13073038",
      "j": 2023,
      "n": "gustow",
      "dn": "Gustow",
      "bl": 13,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "13073039",
      "j": 2023,
      "n": "hugoldsdorf",
      "dn": "Hugoldsdorf",
      "bl": 13,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "13073040",
      "j": 2023,
      "n": "insel hiddensee",
      "dn": "Insel Hiddensee",
      "bl": 13,
      "hs": 400.0,
      "mns": 5
    },
    {
      "a": "13073041",
      "j": 2023,
      "n": "jakobsdorf",
      "dn": "Jakobsdorf",
      "bl": 13,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "13073042",
      "j": 2023,
      "n": "karnin",
      "dn": "Karnin",
      "bl": 13,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "13073043",
      "j": 2023,
      "n": "kenz-küstrow",
      "dn": "Kenz-Küstrow",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13073044",
      "j": 2023,
      "n": "klausdorf",
      "dn": "Klausdorf",
      "bl": 13,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "13073045",
      "j": 2023,
      "n": "kluis",
      "dn": "Kluis",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13073046",
      "j": 2023,
      "n": "kramerhof",
      "dn": "Kramerhof",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13073048",
      "j": 2023,
      "n": "lancken-granitz",
      "dn": "Lancken-Granitz",
      "bl": 13,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "13073049",
      "j": 2023,
      "n": "lietzow",
      "dn": "Lietzow",
      "bl": 13,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "13073050",
      "j": 2023,
      "n": "lindholz",
      "dn": "Lindholz",
      "bl": 13,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "13073051",
      "j": 2023,
      "n": "löbnitz",
      "dn": "Löbnitz",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13073052",
      "j": 2023,
      "n": "lohme",
      "dn": "Lohme",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13073053",
      "j": 2023,
      "n": "lüdershagen",
      "dn": "Lüdershagen",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13073054",
      "j": 2023,
      "n": "lüssow",
      "dn": "Lüssow",
      "bl": 13,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "13073055",
      "j": 2023,
      "n": "marlow",
      "dn": "Marlow",
      "bl": 13,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "13073057",
      "j": 2023,
      "n": "millienhagen-oebelitz",
      "dn": "Millienhagen-Oebelitz",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13073059",
      "j": 2023,
      "n": "neuenkirchen",
      "dn": "Neuenkirchen",
      "bl": 13,
      "hs": 500.0,
      "mns": 2
    },
    {
      "a": "13073060",
      "j": 2023,
      "n": "niepars",
      "dn": "Niepars",
      "bl": 13,
      "hs": 365.0,
      "mns": 2
    },
    {
      "a": "13073061",
      "j": 2023,
      "n": "pantelitz",
      "dn": "Pantelitz",
      "bl": 13,
      "hs": 396.0,
      "mns": 2
    },
    {
      "a": "13073062",
      "j": 2023,
      "n": "papenhagen",
      "dn": "Papenhagen",
      "bl": 13,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "13073063",
      "j": 2023,
      "n": "parchtitz",
      "dn": "Parchtitz",
      "bl": 13,
      "hs": 375.0,
      "mns": 2
    },
    {
      "a": "13073064",
      "j": 2023,
      "n": "patzig",
      "dn": "Patzig",
      "bl": 13,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "13073065",
      "j": 2023,
      "n": "poseritz",
      "dn": "Poseritz",
      "bl": 13,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "13073066",
      "j": 2023,
      "n": "preetz",
      "dn": "Preetz",
      "bl": 13,
      "hs": 385.0,
      "mns": 2
    },
    {
      "a": "13073067",
      "j": 2023,
      "n": "prerow",
      "dn": "Prerow",
      "bl": 13,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "13073068",
      "j": 2023,
      "n": "prohn",
      "dn": "Prohn",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13073069",
      "j": 2023,
      "n": "pruchten",
      "dn": "Pruchten",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13073070",
      "j": 2023,
      "n": "putbus",
      "dn": "Putbus",
      "bl": 13,
      "hs": 490.0,
      "mns": 2
    },
    {
      "a": "13073071",
      "j": 2023,
      "n": "putgarten",
      "dn": "Putgarten",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13073072",
      "j": 2023,
      "n": "ralswiek",
      "dn": "Ralswiek",
      "bl": 13,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "13073073",
      "j": 2023,
      "n": "rambin",
      "dn": "Rambin",
      "bl": 13,
      "hs": 480.0,
      "mns": 2
    },
    {
      "a": "13073074",
      "j": 2023,
      "n": "rappin",
      "dn": "Rappin",
      "bl": 13,
      "hs": 375.0,
      "mns": 2
    },
    {
      "a": "13073075",
      "j": 2023,
      "n": "ribnitz-damgarten",
      "dn": "Ribnitz-Damgarten",
      "bl": 13,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "13073076",
      "j": 2023,
      "n": "richtenberg",
      "dn": "Richtenberg",
      "bl": 13,
      "hs": 396.0,
      "mns": 2
    },
    {
      "a": "13073077",
      "j": 2023,
      "n": "saal",
      "dn": "Saal",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13073078",
      "j": 2023,
      "n": "sagard",
      "dn": "Sagard",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13073079",
      "j": 2023,
      "n": "samtens",
      "dn": "Samtens",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13073080",
      "j": 2023,
      "n": "sassnitz",
      "dn": "Sassnitz",
      "bl": 13,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "13073081",
      "j": 2023,
      "n": "schaprode",
      "dn": "Schaprode",
      "bl": 13,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "13073082",
      "j": 2023,
      "n": "schlemmin",
      "dn": "Schlemmin",
      "bl": 13,
      "hs": 406.0,
      "mns": 2
    },
    {
      "a": "13073083",
      "j": 2023,
      "n": "sehlen",
      "dn": "Sehlen",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13073084",
      "j": 2023,
      "n": "sellin",
      "dn": "Sellin",
      "bl": 13,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "13073085",
      "j": 2023,
      "n": "semlow",
      "dn": "Semlow",
      "bl": 13,
      "hs": 406.0,
      "mns": 2
    },
    {
      "a": "13073086",
      "j": 2023,
      "n": "splietsdorf",
      "dn": "Splietsdorf",
      "bl": 13,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "13073087",
      "j": 2023,
      "n": "steinhagen",
      "dn": "Steinhagen",
      "bl": 13,
      "hs": 396.0,
      "mns": 2
    },
    {
      "a": "13073088",
      "j": 2023,
      "n": "stralsund",
      "dn": "Stralsund",
      "bl": 13,
      "hs": 533.0,
      "mns": 3
    },
    {
      "a": "13073089",
      "j": 2023,
      "n": "süderholz",
      "dn": "Süderholz",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13073090",
      "j": 2023,
      "n": "sundhagen",
      "dn": "Sundhagen",
      "bl": 13,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "13073092",
      "j": 2023,
      "n": "trent",
      "dn": "Trent",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13073093",
      "j": 2023,
      "n": "tribsees",
      "dn": "Tribsees",
      "bl": 13,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "13073094",
      "j": 2023,
      "n": "trinwillershagen",
      "dn": "Trinwillershagen",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13073095",
      "j": 2023,
      "n": "ummanz",
      "dn": "Ummanz",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13073096",
      "j": 2023,
      "n": "velgast",
      "dn": "Velgast",
      "bl": 13,
      "hs": 396.0,
      "mns": 2
    },
    {
      "a": "13073097",
      "j": 2023,
      "n": "weitenhagen",
      "dn": "Weitenhagen",
      "bl": 13,
      "hs": 396.0,
      "mns": 2
    },
    {
      "a": "13073098",
      "j": 2023,
      "n": "wendisch baggendorf",
      "dn": "Wendisch Baggendorf",
      "bl": 13,
      "hs": 396.0,
      "mns": 2
    },
    {
      "a": "13073099",
      "j": 2023,
      "n": "wendorf",
      "dn": "Wendorf",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13073100",
      "j": 2023,
      "n": "wieck a. darß",
      "dn": "Wieck a. Darß",
      "bl": 13,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "13073101",
      "j": 2023,
      "n": "wiek",
      "dn": "Wiek",
      "bl": 13,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "13073102",
      "j": 2023,
      "n": "wittenhagen",
      "dn": "Wittenhagen",
      "bl": 13,
      "hs": 437.0,
      "mns": 2
    },
    {
      "a": "13073103",
      "j": 2023,
      "n": "wustrow",
      "dn": "Wustrow",
      "bl": 13,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "13073104",
      "j": 2023,
      "n": "zarrendorf",
      "dn": "Zarrendorf",
      "bl": 13,
      "hs": 396.0,
      "mns": 2
    },
    {
      "a": "13073105",
      "j": 2023,
      "n": "zingst",
      "dn": "Zingst",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13073106",
      "j": 2023,
      "n": "zirkow",
      "dn": "Zirkow",
      "bl": 13,
      "hs": 375.0,
      "mns": 2
    },
    {
      "a": "13073107",
      "j": 2023,
      "n": "mönchgut",
      "dn": "Mönchgut",
      "bl": 13,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "13074001",
      "j": 2023,
      "n": "alt meteln",
      "dn": "Alt Meteln",
      "bl": 13,
      "hs": 345.0,
      "mns": 2
    },
    {
      "a": "13074002",
      "j": 2023,
      "n": "bad kleinen",
      "dn": "Bad Kleinen",
      "bl": 13,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "13074003",
      "j": 2023,
      "n": "barnekow",
      "dn": "Barnekow",
      "bl": 13,
      "hs": 395.0,
      "mns": 2
    },
    {
      "a": "13074004",
      "j": 2023,
      "n": "benz",
      "dn": "Benz",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13074005",
      "j": 2023,
      "n": "bernstorf",
      "dn": "Bernstorf",
      "bl": 13,
      "hs": 480.0,
      "mns": 2
    },
    {
      "a": "13074006",
      "j": 2023,
      "n": "bibow",
      "dn": "Bibow",
      "bl": 13,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "13074007",
      "j": 2023,
      "n": "blowatz",
      "dn": "Blowatz",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13074008",
      "j": 2023,
      "n": "bobitz",
      "dn": "Bobitz",
      "bl": 13,
      "hs": 383.0,
      "mns": 2
    },
    {
      "a": "13074009",
      "j": 2023,
      "n": "boiensdorf",
      "dn": "Boiensdorf",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13074010",
      "j": 2023,
      "n": "boltenhagen",
      "dn": "Boltenhagen",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13074012",
      "j": 2023,
      "n": "brüsewitz",
      "dn": "Brüsewitz",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13074013",
      "j": 2023,
      "n": "carlow",
      "dn": "Carlow",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13074014",
      "j": 2023,
      "n": "cramonshagen",
      "dn": "Cramonshagen",
      "bl": 13,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "13074015",
      "j": 2023,
      "n": "dalberg-wendelstorf",
      "dn": "Dalberg-Wendelstorf",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13074016",
      "j": 2023,
      "n": "damshagen",
      "dn": "Damshagen",
      "bl": 13,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "13074017",
      "j": 2023,
      "n": "dassow",
      "dn": "Dassow",
      "bl": 13,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "13074018",
      "j": 2023,
      "n": "dechow",
      "dn": "Dechow",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13074019",
      "j": 2023,
      "n": "dorf mecklenburg",
      "dn": "Dorf Mecklenburg",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13074020",
      "j": 2023,
      "n": "dragun",
      "dn": "Dragun",
      "bl": 13,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "13074021",
      "j": 2023,
      "n": "gadebusch",
      "dn": "Gadebusch",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13074022",
      "j": 2023,
      "n": "gägelow",
      "dn": "Gägelow",
      "bl": 13,
      "hs": 354.0,
      "mns": 2
    },
    {
      "a": "13074023",
      "j": 2023,
      "n": "glasin",
      "dn": "Glasin",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13074024",
      "j": 2023,
      "n": "gottesgabe",
      "dn": "Gottesgabe",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13074025",
      "j": 2023,
      "n": "grambow",
      "dn": "Grambow",
      "bl": 13,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "13074026",
      "j": 2023,
      "n": "grevesmühlen",
      "dn": "Grevesmühlen",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13074027",
      "j": 2023,
      "n": "grieben",
      "dn": "Grieben",
      "bl": 13,
      "hs": 408.0,
      "mns": 2
    },
    {
      "a": "13074028",
      "j": 2023,
      "n": "groß molzahn",
      "dn": "Groß Molzahn",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13074030",
      "j": 2023,
      "n": "groß stieten",
      "dn": "Groß Stieten",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13074031",
      "j": 2023,
      "n": "hohen viecheln",
      "dn": "Hohen Viecheln",
      "bl": 13,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "13074032",
      "j": 2023,
      "n": "hohenkirchen",
      "dn": "Hohenkirchen",
      "bl": 13,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "13074033",
      "j": 2023,
      "n": "holdorf",
      "dn": "Holdorf",
      "bl": 13,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "13074034",
      "j": 2023,
      "n": "hornstorf",
      "dn": "Hornstorf",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13074035",
      "j": 2023,
      "n": "ostseebad insel poel",
      "dn": "Ostseebad Insel Poel",
      "bl": 13,
      "hs": 375.0,
      "mns": 2
    },
    {
      "a": "13074036",
      "j": 2023,
      "n": "jesendorf",
      "dn": "Jesendorf",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13074037",
      "j": 2023,
      "n": "kalkhorst",
      "dn": "Kalkhorst",
      "bl": 13,
      "hs": 404.0,
      "mns": 2
    },
    {
      "a": "13074038",
      "j": 2023,
      "n": "klein trebbow",
      "dn": "Klein Trebbow",
      "bl": 13,
      "hs": 409.0,
      "mns": 2
    },
    {
      "a": "13074039",
      "j": 2023,
      "n": "klütz",
      "dn": "Klütz",
      "bl": 13,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "13074040",
      "j": 2023,
      "n": "kneese",
      "dn": "Kneese",
      "bl": 13,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "13074042",
      "j": 2023,
      "n": "königsfeld",
      "dn": "Königsfeld",
      "bl": 13,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "13074043",
      "j": 2023,
      "n": "krembz",
      "dn": "Krembz",
      "bl": 13,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "13074044",
      "j": 2023,
      "n": "krusenhagen",
      "dn": "Krusenhagen",
      "bl": 13,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "13074046",
      "j": 2023,
      "n": "lübberstorf",
      "dn": "Lübberstorf",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13074047",
      "j": 2023,
      "n": "lübow",
      "dn": "Lübow",
      "bl": 13,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "13074048",
      "j": 2023,
      "n": "lübstorf",
      "dn": "Lübstorf",
      "bl": 13,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "13074049",
      "j": 2023,
      "n": "lüdersdorf",
      "dn": "Lüdersdorf",
      "bl": 13,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "13074050",
      "j": 2023,
      "n": "lützow",
      "dn": "Lützow",
      "bl": 13,
      "hs": 335.0,
      "mns": 2
    },
    {
      "a": "13074052",
      "j": 2023,
      "n": "menzendorf",
      "dn": "Menzendorf",
      "bl": 13,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "13074053",
      "j": 2023,
      "n": "metelsdorf",
      "dn": "Metelsdorf",
      "bl": 13,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "13074054",
      "j": 2023,
      "n": "mühlen eichsen",
      "dn": "Mühlen Eichsen",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13074056",
      "j": 2023,
      "n": "neuburg",
      "dn": "Neuburg",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13074057",
      "j": 2023,
      "n": "neukloster",
      "dn": "Neukloster",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13074060",
      "j": 2023,
      "n": "passee",
      "dn": "Passee",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13074061",
      "j": 2023,
      "n": "perlin",
      "dn": "Perlin",
      "bl": 13,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "13074062",
      "j": 2023,
      "n": "pingelshagen",
      "dn": "Pingelshagen",
      "bl": 13,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "13074064",
      "j": 2023,
      "n": "pokrent",
      "dn": "Pokrent",
      "bl": 13,
      "hs": 396.0,
      "mns": 2
    },
    {
      "a": "13074065",
      "j": 2023,
      "n": "rehna",
      "dn": "Rehna",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13074066",
      "j": 2023,
      "n": "rieps",
      "dn": "Rieps",
      "bl": 13,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "13074067",
      "j": 2023,
      "n": "roduchelstorf",
      "dn": "Roduchelstorf",
      "bl": 13,
      "hs": 395.0,
      "mns": 2
    },
    {
      "a": "13074068",
      "j": 2023,
      "n": "roggendorf",
      "dn": "Roggendorf",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13074069",
      "j": 2023,
      "n": "roggenstorf",
      "dn": "Roggenstorf",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13074070",
      "j": 2023,
      "n": "rögnitz",
      "dn": "Rögnitz",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13074071",
      "j": 2023,
      "n": "rüting",
      "dn": "Rüting",
      "bl": 13,
      "hs": 355.0,
      "mns": 2
    },
    {
      "a": "13074072",
      "j": 2023,
      "n": "schildetal",
      "dn": "Schildetal",
      "bl": 13,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "13074073",
      "j": 2023,
      "n": "schlagsdorf",
      "dn": "Schlagsdorf",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13074074",
      "j": 2023,
      "n": "schönberg",
      "dn": "Schönberg",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13074075",
      "j": 2023,
      "n": "seehof",
      "dn": "Seehof",
      "bl": 13,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "13074076",
      "j": 2023,
      "n": "selmsdorf",
      "dn": "Selmsdorf",
      "bl": 13,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "13074077",
      "j": 2023,
      "n": "testorf-steinfort",
      "dn": "Testorf-Steinfort",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13074078",
      "j": 2023,
      "n": "thandorf",
      "dn": "Thandorf",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13074079",
      "j": 2023,
      "n": "upahl",
      "dn": "Upahl",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13074080",
      "j": 2023,
      "n": "utecht",
      "dn": "Utecht",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13074081",
      "j": 2023,
      "n": "veelböken",
      "dn": "Veelböken",
      "bl": 13,
      "hs": 460.0,
      "mns": 2
    },
    {
      "a": "13074082",
      "j": 2023,
      "n": "ventschow",
      "dn": "Ventschow",
      "bl": 13,
      "hs": 406.0,
      "mns": 2
    },
    {
      "a": "13074084",
      "j": 2023,
      "n": "warin",
      "dn": "Warin",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13074085",
      "j": 2023,
      "n": "warnow",
      "dn": "Warnow",
      "bl": 13,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "13074087",
      "j": 2023,
      "n": "wismar",
      "dn": "Wismar",
      "bl": 13,
      "hs": 580.0,
      "mns": 3
    },
    {
      "a": "13074088",
      "j": 2023,
      "n": "zickhusen",
      "dn": "Zickhusen",
      "bl": 13,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "13074089",
      "j": 2023,
      "n": "zierow",
      "dn": "Zierow",
      "bl": 13,
      "hs": 368.0,
      "mns": 2
    },
    {
      "a": "13074090",
      "j": 2023,
      "n": "zurow",
      "dn": "Zurow",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13074091",
      "j": 2023,
      "n": "züsow",
      "dn": "Züsow",
      "bl": 13,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "13074092",
      "j": 2023,
      "n": "wedendorfersee",
      "dn": "Wedendorfersee",
      "bl": 13,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "13074093",
      "j": 2023,
      "n": "stepenitztal",
      "dn": "Stepenitztal",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13074094",
      "j": 2023,
      "n": "siemz-niendorf",
      "dn": "Siemz-Niendorf",
      "bl": 13,
      "hs": 399.0,
      "mns": 2
    },
    {
      "a": "13075001",
      "j": 2023,
      "n": "ahlbeck",
      "dn": "Ahlbeck",
      "bl": 13,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "13075002",
      "j": 2023,
      "n": "alt tellin",
      "dn": "Alt Tellin",
      "bl": 13,
      "hs": 408.0,
      "mns": 2
    },
    {
      "a": "13075003",
      "j": 2023,
      "n": "altwarp",
      "dn": "Altwarp",
      "bl": 13,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "13075004",
      "j": 2023,
      "n": "altwigshagen",
      "dn": "Altwigshagen",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13075005",
      "j": 2023,
      "n": "anklam",
      "dn": "Anklam",
      "bl": 13,
      "hs": 490.0,
      "mns": 2
    },
    {
      "a": "13075006",
      "j": 2023,
      "n": "bandelin",
      "dn": "Bandelin",
      "bl": 13,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "13075007",
      "j": 2023,
      "n": "bargischow",
      "dn": "Bargischow",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075008",
      "j": 2023,
      "n": "behrenhoff",
      "dn": "Behrenhoff",
      "bl": 13,
      "hs": 500.0,
      "mns": 2
    },
    {
      "a": "13075009",
      "j": 2023,
      "n": "bentzin",
      "dn": "Bentzin",
      "bl": 13,
      "hs": 408.0,
      "mns": 2
    },
    {
      "a": "13075010",
      "j": 2023,
      "n": "benz",
      "dn": "Benz",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075011",
      "j": 2023,
      "n": "bergholz",
      "dn": "Bergholz",
      "bl": 13,
      "hs": 406.0,
      "mns": 2
    },
    {
      "a": "13075012",
      "j": 2023,
      "n": "blankensee",
      "dn": "Blankensee",
      "bl": 13,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "13075013",
      "j": 2023,
      "n": "blesewitz",
      "dn": "Blesewitz",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075015",
      "j": 2023,
      "n": "boldekow",
      "dn": "Boldekow",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075016",
      "j": 2023,
      "n": "boock",
      "dn": "Boock",
      "bl": 13,
      "hs": 435.0,
      "mns": 2
    },
    {
      "a": "13075017",
      "j": 2023,
      "n": "brietzig",
      "dn": "Brietzig",
      "bl": 13,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "13075018",
      "j": 2023,
      "n": "brünzow",
      "dn": "Brünzow",
      "bl": 13,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "13075020",
      "j": 2023,
      "n": "bugewitz",
      "dn": "Bugewitz",
      "bl": 13,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "13075021",
      "j": 2023,
      "n": "buggenhagen",
      "dn": "Buggenhagen",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075022",
      "j": 2023,
      "n": "butzow",
      "dn": "Butzow",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075023",
      "j": 2023,
      "n": "daberkow",
      "dn": "Daberkow",
      "bl": 13,
      "hs": 408.0,
      "mns": 2
    },
    {
      "a": "13075025",
      "j": 2023,
      "n": "dargelin",
      "dn": "Dargelin",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075026",
      "j": 2023,
      "n": "dargen",
      "dn": "Dargen",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075027",
      "j": 2023,
      "n": "dersekow",
      "dn": "Dersekow",
      "bl": 13,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "13075029",
      "j": 2023,
      "n": "ducherow",
      "dn": "Ducherow",
      "bl": 13,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "13075031",
      "j": 2023,
      "n": "eggesin",
      "dn": "Eggesin",
      "bl": 13,
      "hs": 480.0,
      "mns": 2
    },
    {
      "a": "13075032",
      "j": 2023,
      "n": "fahrenwalde",
      "dn": "Fahrenwalde",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13075033",
      "j": 2023,
      "n": "ferdinandshof",
      "dn": "Ferdinandshof",
      "bl": 13,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "13075034",
      "j": 2023,
      "n": "garz",
      "dn": "Garz",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075035",
      "j": 2023,
      "n": "glasow",
      "dn": "Glasow",
      "bl": 13,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "13075036",
      "j": 2023,
      "n": "görmin",
      "dn": "Görmin",
      "bl": 13,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "13075037",
      "j": 2023,
      "n": "grambin",
      "dn": "Grambin",
      "bl": 13,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "13075038",
      "j": 2023,
      "n": "grambow",
      "dn": "Grambow",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075039",
      "j": 2023,
      "n": "greifswald",
      "dn": "Greifswald",
      "bl": 13,
      "hs": 480.0,
      "mns": 3
    },
    {
      "a": "13075040",
      "j": 2023,
      "n": "gribow",
      "dn": "Gribow",
      "bl": 13,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "13075041",
      "j": 2023,
      "n": "groß kiesow",
      "dn": "Groß Kiesow",
      "bl": 13,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "13075042",
      "j": 2023,
      "n": "groß luckow",
      "dn": "Groß Luckow",
      "bl": 13,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "13075043",
      "j": 2023,
      "n": "groß polzin",
      "dn": "Groß Polzin",
      "bl": 13,
      "hs": 439.0,
      "mns": 2
    },
    {
      "a": "13075044",
      "j": 2023,
      "n": "gützkow",
      "dn": "Gützkow",
      "bl": 13,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "13075045",
      "j": 2023,
      "n": "hammer a. d. uecker",
      "dn": "Hammer a. d. Uecker",
      "bl": 13,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "13075046",
      "j": 2023,
      "n": "hanshagen",
      "dn": "Hanshagen",
      "bl": 13,
      "hs": 437.0,
      "mns": 2
    },
    {
      "a": "13075048",
      "j": 2023,
      "n": "heinrichswalde",
      "dn": "Heinrichswalde",
      "bl": 13,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "13075049",
      "j": 2023,
      "n": "heringsdorf",
      "dn": "Heringsdorf",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075050",
      "j": 2023,
      "n": "hinrichshagen",
      "dn": "Hinrichshagen",
      "bl": 13,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "13075051",
      "j": 2023,
      "n": "hintersee",
      "dn": "Hintersee",
      "bl": 13,
      "hs": 406.0,
      "mns": 2
    },
    {
      "a": "13075053",
      "j": 2023,
      "n": "iven",
      "dn": "Iven",
      "bl": 13,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "13075054",
      "j": 2023,
      "n": "jarmen",
      "dn": "Jarmen",
      "bl": 13,
      "hs": 414.0,
      "mns": 2
    },
    {
      "a": "13075055",
      "j": 2023,
      "n": "jatznick",
      "dn": "Jatznick",
      "bl": 13,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "13075056",
      "j": 2023,
      "n": "kamminke",
      "dn": "Kamminke",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075058",
      "j": 2023,
      "n": "karlshagen",
      "dn": "Karlshagen",
      "bl": 13,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "13075059",
      "j": 2023,
      "n": "katzow",
      "dn": "Katzow",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075060",
      "j": 2023,
      "n": "kemnitz",
      "dn": "Kemnitz",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075061",
      "j": 2023,
      "n": "klein bünzow",
      "dn": "Klein Bünzow",
      "bl": 13,
      "hs": 436.0,
      "mns": 2
    },
    {
      "a": "13075063",
      "j": 2023,
      "n": "koblentz",
      "dn": "Koblentz",
      "bl": 13,
      "hs": 435.0,
      "mns": 2
    },
    {
      "a": "13075065",
      "j": 2023,
      "n": "korswandt",
      "dn": "Korswandt",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075066",
      "j": 2023,
      "n": "koserow",
      "dn": "Koserow",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075067",
      "j": 2023,
      "n": "krackow",
      "dn": "Krackow",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075068",
      "j": 2023,
      "n": "krien",
      "dn": "Krien",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075069",
      "j": 2023,
      "n": "kröslin",
      "dn": "Kröslin",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075070",
      "j": 2023,
      "n": "kruckow",
      "dn": "Kruckow",
      "bl": 13,
      "hs": 408.0,
      "mns": 2
    },
    {
      "a": "13075071",
      "j": 2023,
      "n": "krugsdorf",
      "dn": "Krugsdorf",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13075072",
      "j": 2023,
      "n": "krummin",
      "dn": "Krummin",
      "bl": 13,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "13075073",
      "j": 2023,
      "n": "krusenfelde",
      "dn": "Krusenfelde",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075074",
      "j": 2023,
      "n": "lassan",
      "dn": "Lassan",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075075",
      "j": 2023,
      "n": "leopoldshagen",
      "dn": "Leopoldshagen",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13075076",
      "j": 2023,
      "n": "levenhagen",
      "dn": "Levenhagen",
      "bl": 13,
      "hs": 491.0,
      "mns": 2
    },
    {
      "a": "13075078",
      "j": 2023,
      "n": "liepgarten",
      "dn": "Liepgarten",
      "bl": 13,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "13075079",
      "j": 2023,
      "n": "löcknitz",
      "dn": "Löcknitz",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075080",
      "j": 2023,
      "n": "loddin",
      "dn": "Loddin",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075081",
      "j": 2023,
      "n": "loissin",
      "dn": "Loissin",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075082",
      "j": 2023,
      "n": "loitz",
      "dn": "Loitz",
      "bl": 13,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "13075083",
      "j": 2023,
      "n": "lubmin",
      "dn": "Lubmin",
      "bl": 13,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "13075084",
      "j": 2023,
      "n": "lübs",
      "dn": "Lübs",
      "bl": 13,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "13075085",
      "j": 2023,
      "n": "luckow",
      "dn": "Luckow",
      "bl": 13,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "13075087",
      "j": 2023,
      "n": "lütow",
      "dn": "Lütow",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13075088",
      "j": 2023,
      "n": "medow",
      "dn": "Medow",
      "bl": 13,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "13075089",
      "j": 2023,
      "n": "meiersberg",
      "dn": "Meiersberg",
      "bl": 13,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "13075090",
      "j": 2023,
      "n": "mellenthin",
      "dn": "Mellenthin",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075091",
      "j": 2023,
      "n": "mesekenhagen",
      "dn": "Mesekenhagen",
      "bl": 13,
      "hs": 396.0,
      "mns": 2
    },
    {
      "a": "13075092",
      "j": 2023,
      "n": "mölschow",
      "dn": "Mölschow",
      "bl": 13,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "13075093",
      "j": 2023,
      "n": "mönkebude",
      "dn": "Mönkebude",
      "bl": 13,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "13075094",
      "j": 2023,
      "n": "murchin",
      "dn": "Murchin",
      "bl": 13,
      "hs": 436.0,
      "mns": 2
    },
    {
      "a": "13075095",
      "j": 2023,
      "n": "nadrensee",
      "dn": "Nadrensee",
      "bl": 13,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "13075097",
      "j": 2023,
      "n": "neu boltenhagen",
      "dn": "Neu Boltenhagen",
      "bl": 13,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "13075098",
      "j": 2023,
      "n": "neu kosenow",
      "dn": "Neu Kosenow",
      "bl": 13,
      "hs": 395.0,
      "mns": 2
    },
    {
      "a": "13075101",
      "j": 2023,
      "n": "neuenkirchen",
      "dn": "Neuenkirchen",
      "bl": 13,
      "hs": 435.0,
      "mns": 2
    },
    {
      "a": "13075102",
      "j": 2023,
      "n": "neuenkirchen",
      "dn": "Neuenkirchen",
      "bl": 13,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "13075103",
      "j": 2023,
      "n": "nieden",
      "dn": "Nieden",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075104",
      "j": 2023,
      "n": "papendorf",
      "dn": "Papendorf",
      "bl": 13,
      "hs": 426.0,
      "mns": 2
    },
    {
      "a": "13075105",
      "j": 2023,
      "n": "pasewalk",
      "dn": "Pasewalk",
      "bl": 13,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "13075106",
      "j": 2023,
      "n": "peenemünde",
      "dn": "Peenemünde",
      "bl": 13,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "13075107",
      "j": 2023,
      "n": "penkun",
      "dn": "Penkun",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075108",
      "j": 2023,
      "n": "plöwen",
      "dn": "Plöwen",
      "bl": 13,
      "hs": 406.0,
      "mns": 2
    },
    {
      "a": "13075109",
      "j": 2023,
      "n": "polzow",
      "dn": "Polzow",
      "bl": 13,
      "hs": 435.0,
      "mns": 2
    },
    {
      "a": "13075110",
      "j": 2023,
      "n": "postlow",
      "dn": "Postlow",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075111",
      "j": 2023,
      "n": "pudagla",
      "dn": "Pudagla",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075113",
      "j": 2023,
      "n": "ramin",
      "dn": "Ramin",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075114",
      "j": 2023,
      "n": "rankwitz",
      "dn": "Rankwitz",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075115",
      "j": 2023,
      "n": "rollwitz",
      "dn": "Rollwitz",
      "bl": 13,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "13075116",
      "j": 2023,
      "n": "rossin",
      "dn": "Rossin",
      "bl": 13,
      "hs": 435.0,
      "mns": 2
    },
    {
      "a": "13075117",
      "j": 2023,
      "n": "rossow",
      "dn": "Rossow",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075118",
      "j": 2023,
      "n": "rothemühl",
      "dn": "Rothemühl",
      "bl": 13,
      "hs": 381.0,
      "mns": 2
    },
    {
      "a": "13075119",
      "j": 2023,
      "n": "rothenklempenow",
      "dn": "Rothenklempenow",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075120",
      "j": 2023,
      "n": "rubenow",
      "dn": "Rubenow",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075121",
      "j": 2023,
      "n": "rubkow",
      "dn": "Rubkow",
      "bl": 13,
      "hs": 436.0,
      "mns": 2
    },
    {
      "a": "13075122",
      "j": 2023,
      "n": "sarnow",
      "dn": "Sarnow",
      "bl": 13,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "13075123",
      "j": 2023,
      "n": "sassen-trantow",
      "dn": "Sassen-Trantow",
      "bl": 13,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "13075124",
      "j": 2023,
      "n": "sauzin",
      "dn": "Sauzin",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075125",
      "j": 2023,
      "n": "schmatzin",
      "dn": "Schmatzin",
      "bl": 13,
      "hs": 439.0,
      "mns": 2
    },
    {
      "a": "13075126",
      "j": 2023,
      "n": "schönwalde",
      "dn": "Schönwalde",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13075127",
      "j": 2023,
      "n": "spantekow",
      "dn": "Spantekow",
      "bl": 13,
      "hs": 435.0,
      "mns": 2
    },
    {
      "a": "13075128",
      "j": 2023,
      "n": "stolpe an der peene",
      "dn": "Stolpe an der Peene",
      "bl": 13,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "13075129",
      "j": 2023,
      "n": "stolpe auf usedom",
      "dn": "Stolpe auf Usedom",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075130",
      "j": 2023,
      "n": "strasburg",
      "dn": "Strasburg",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075131",
      "j": 2023,
      "n": "torgelow",
      "dn": "Torgelow",
      "bl": 13,
      "hs": 475.0,
      "mns": 2
    },
    {
      "a": "13075133",
      "j": 2023,
      "n": "trassenheide",
      "dn": "Trassenheide",
      "bl": 13,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "13075134",
      "j": 2023,
      "n": "tutow",
      "dn": "Tutow",
      "bl": 13,
      "hs": 403.0,
      "mns": 2
    },
    {
      "a": "13075135",
      "j": 2023,
      "n": "ückeritz",
      "dn": "Ückeritz",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075136",
      "j": 2023,
      "n": "ueckermünde",
      "dn": "Ueckermünde",
      "bl": 13,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "13075137",
      "j": 2023,
      "n": "usedom",
      "dn": "Usedom",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075138",
      "j": 2023,
      "n": "viereck",
      "dn": "Viereck",
      "bl": 13,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "13075139",
      "j": 2023,
      "n": "vogelsang-warsin",
      "dn": "Vogelsang-Warsin",
      "bl": 13,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "13075140",
      "j": 2023,
      "n": "völschow",
      "dn": "Völschow",
      "bl": 13,
      "hs": 396.0,
      "mns": 2
    },
    {
      "a": "13075141",
      "j": 2023,
      "n": "wackerow",
      "dn": "Wackerow",
      "bl": 13,
      "hs": 700.0,
      "mns": 2
    },
    {
      "a": "13075142",
      "j": 2023,
      "n": "weitenhagen",
      "dn": "Weitenhagen",
      "bl": 13,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "13075143",
      "j": 2023,
      "n": "wilhelmsburg",
      "dn": "Wilhelmsburg",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13075144",
      "j": 2023,
      "n": "wolgast",
      "dn": "Wolgast",
      "bl": 13,
      "hs": 450.0,
      "mns": 3
    },
    {
      "a": "13075145",
      "j": 2023,
      "n": "wrangelsburg",
      "dn": "Wrangelsburg",
      "bl": 13,
      "hs": 439.0,
      "mns": 2
    },
    {
      "a": "13075146",
      "j": 2023,
      "n": "wusterhusen",
      "dn": "Wusterhusen",
      "bl": 13,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "13075147",
      "j": 2023,
      "n": "zemitz",
      "dn": "Zemitz",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075148",
      "j": 2023,
      "n": "zempin",
      "dn": "Zempin",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075149",
      "j": 2023,
      "n": "zerrenthin",
      "dn": "Zerrenthin",
      "bl": 13,
      "hs": 435.0,
      "mns": 2
    },
    {
      "a": "13075150",
      "j": 2023,
      "n": "ziethen",
      "dn": "Ziethen",
      "bl": 13,
      "hs": 436.0,
      "mns": 2
    },
    {
      "a": "13075151",
      "j": 2023,
      "n": "zinnowitz",
      "dn": "Zinnowitz",
      "bl": 13,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "13075152",
      "j": 2023,
      "n": "zirchow",
      "dn": "Zirchow",
      "bl": 13,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "13075154",
      "j": 2023,
      "n": "züssow",
      "dn": "Züssow",
      "bl": 13,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "13075155",
      "j": 2023,
      "n": "neetzow-liepen",
      "dn": "Neetzow-Liepen",
      "bl": 13,
      "hs": 435.0,
      "mns": 2
    },
    {
      "a": "13075156",
      "j": 2023,
      "n": "karlsburg",
      "dn": "Karlsburg",
      "bl": 13,
      "hs": 439.0,
      "mns": 2
    },
    {
      "a": "13076001",
      "j": 2023,
      "n": "alt krenzlin",
      "dn": "Alt Krenzlin",
      "bl": 13,
      "hs": 396.0,
      "mns": 1
    },
    {
      "a": "13076002",
      "j": 2023,
      "n": "alt zachun",
      "dn": "Alt Zachun",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13076003",
      "j": 2023,
      "n": "balow",
      "dn": "Balow",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13076004",
      "j": 2023,
      "n": "bandenitz",
      "dn": "Bandenitz",
      "bl": 13,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "13076005",
      "j": 2023,
      "n": "banzkow",
      "dn": "Banzkow",
      "bl": 13,
      "hs": 435.0,
      "mns": 1
    },
    {
      "a": "13076006",
      "j": 2023,
      "n": "barkhagen",
      "dn": "Barkhagen",
      "bl": 13,
      "hs": 435.0,
      "mns": 1
    },
    {
      "a": "13076007",
      "j": 2023,
      "n": "barnin",
      "dn": "Barnin",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13076008",
      "j": 2023,
      "n": "belsch",
      "dn": "Belsch",
      "bl": 13,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "13076009",
      "j": 2023,
      "n": "bengerstorf",
      "dn": "Bengerstorf",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13076010",
      "j": 2023,
      "n": "besitz",
      "dn": "Besitz",
      "bl": 13,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "13076011",
      "j": 2023,
      "n": "blankenberg",
      "dn": "Blankenberg",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13076012",
      "j": 2023,
      "n": "blievenstorf",
      "dn": "Blievenstorf",
      "bl": 13,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "13076013",
      "j": 2023,
      "n": "bobzin",
      "dn": "Bobzin",
      "bl": 13,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "13076014",
      "j": 2023,
      "n": "boizenburg/elbe",
      "dn": "Boizenburg/Elbe",
      "bl": 13,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "13076015",
      "j": 2023,
      "n": "borkow",
      "dn": "Borkow",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13076016",
      "j": 2023,
      "n": "brahlstorf",
      "dn": "Brahlstorf",
      "bl": 13,
      "hs": 341.0,
      "mns": 1
    },
    {
      "a": "13076017",
      "j": 2023,
      "n": "brenz",
      "dn": "Brenz",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13076018",
      "j": 2023,
      "n": "bresegard bei eldena",
      "dn": "Bresegard bei Eldena",
      "bl": 13,
      "hs": 396.0,
      "mns": 1
    },
    {
      "a": "13076019",
      "j": 2023,
      "n": "bresegard bei picher",
      "dn": "Bresegard bei Picher",
      "bl": 13,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "13076020",
      "j": 2023,
      "n": "brüel",
      "dn": "Brüel",
      "bl": 13,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "13076021",
      "j": 2023,
      "n": "brunow",
      "dn": "Brunow",
      "bl": 13,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "13076023",
      "j": 2023,
      "n": "bülow",
      "dn": "Bülow",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13076024",
      "j": 2023,
      "n": "cambs",
      "dn": "Cambs",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13076025",
      "j": 2023,
      "n": "crivitz",
      "dn": "Crivitz",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13076026",
      "j": 2023,
      "n": "dabel",
      "dn": "Dabel",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13076027",
      "j": 2023,
      "n": "dambeck",
      "dn": "Dambeck",
      "bl": 13,
      "hs": 416.0,
      "mns": 1
    },
    {
      "a": "13076029",
      "j": 2023,
      "n": "demen",
      "dn": "Demen",
      "bl": 13,
      "hs": 437.0,
      "mns": 1
    },
    {
      "a": "13076030",
      "j": 2023,
      "n": "dersenow",
      "dn": "Dersenow",
      "bl": 13,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "13076032",
      "j": 2023,
      "n": "dobbertin",
      "dn": "Dobbertin",
      "bl": 13,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "13076033",
      "j": 2023,
      "n": "dobin am see",
      "dn": "Dobin am See",
      "bl": 13,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "13076034",
      "j": 2023,
      "n": "dömitz",
      "dn": "Dömitz",
      "bl": 13,
      "hs": 416.0,
      "mns": 1
    },
    {
      "a": "13076035",
      "j": 2023,
      "n": "domsühl",
      "dn": "Domsühl",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13076036",
      "j": 2023,
      "n": "dümmer",
      "dn": "Dümmer",
      "bl": 13,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "13076037",
      "j": 2023,
      "n": "eldena",
      "dn": "Eldena",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13076038",
      "j": 2023,
      "n": "friedrichsruhe",
      "dn": "Friedrichsruhe",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13076039",
      "j": 2023,
      "n": "gallin",
      "dn": "Gallin",
      "bl": 13,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "13076040",
      "j": 2023,
      "n": "gallin-kuppentin",
      "dn": "Gallin-Kuppentin",
      "bl": 13,
      "hs": 396.0,
      "mns": 1
    },
    {
      "a": "13076041",
      "j": 2023,
      "n": "gammelin",
      "dn": "Gammelin",
      "bl": 13,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "13076044",
      "j": 2023,
      "n": "gneven",
      "dn": "Gneven",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13076046",
      "j": 2023,
      "n": "göhlen",
      "dn": "Göhlen",
      "bl": 13,
      "hs": 396.0,
      "mns": 1
    },
    {
      "a": "13076048",
      "j": 2023,
      "n": "goldberg",
      "dn": "Goldberg",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13076049",
      "j": 2023,
      "n": "gorlosen",
      "dn": "Gorlosen",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13076050",
      "j": 2023,
      "n": "grabow",
      "dn": "Grabow",
      "bl": 13,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "13076051",
      "j": 2023,
      "n": "granzin",
      "dn": "Granzin",
      "bl": 13,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "13076053",
      "j": 2023,
      "n": "grebs-niendorf",
      "dn": "Grebs-Niendorf",
      "bl": 13,
      "hs": 411.0,
      "mns": 1
    },
    {
      "a": "13076054",
      "j": 2023,
      "n": "gresse",
      "dn": "Gresse",
      "bl": 13,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "13076055",
      "j": 2023,
      "n": "greven",
      "dn": "Greven",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13076056",
      "j": 2023,
      "n": "groß godems",
      "dn": "Groß Godems",
      "bl": 13,
      "hs": 437.0,
      "mns": 1
    },
    {
      "a": "13076057",
      "j": 2023,
      "n": "groß krams",
      "dn": "Groß Krams",
      "bl": 13,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "13076058",
      "j": 2023,
      "n": "groß laasch",
      "dn": "Groß Laasch",
      "bl": 13,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "13076060",
      "j": 2023,
      "n": "hagenow",
      "dn": "Hagenow",
      "bl": 13,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "13076062",
      "j": 2023,
      "n": "hohen pritz",
      "dn": "Hohen Pritz",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13076063",
      "j": 2023,
      "n": "holthusen",
      "dn": "Holthusen",
      "bl": 13,
      "hs": 396.0,
      "mns": 1
    },
    {
      "a": "13076064",
      "j": 2023,
      "n": "hoort",
      "dn": "Hoort",
      "bl": 13,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "13076065",
      "j": 2023,
      "n": "hülseburg",
      "dn": "Hülseburg",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13076067",
      "j": 2023,
      "n": "karenz",
      "dn": "Karenz",
      "bl": 13,
      "hs": 411.0,
      "mns": 1
    },
    {
      "a": "13076068",
      "j": 2023,
      "n": "karrenzin",
      "dn": "Karrenzin",
      "bl": 13,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "13076069",
      "j": 2023,
      "n": "karstädt",
      "dn": "Karstädt",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13076070",
      "j": 2023,
      "n": "kirch jesar",
      "dn": "Kirch Jesar",
      "bl": 13,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "13076071",
      "j": 2023,
      "n": "klein rogahn",
      "dn": "Klein Rogahn",
      "bl": 13,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "13076072",
      "j": 2023,
      "n": "kobrow",
      "dn": "Kobrow",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13076073",
      "j": 2023,
      "n": "kogel",
      "dn": "Kogel",
      "bl": 13,
      "hs": 396.0,
      "mns": 1
    },
    {
      "a": "13076075",
      "j": 2023,
      "n": "kreien",
      "dn": "Kreien",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13076076",
      "j": 2023,
      "n": "kremmin",
      "dn": "Kremmin",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13076077",
      "j": 2023,
      "n": "kritzow",
      "dn": "Kritzow",
      "bl": 13,
      "hs": 396.0,
      "mns": 1
    },
    {
      "a": "13076078",
      "j": 2023,
      "n": "kuhlen-wendorf",
      "dn": "Kuhlen-Wendorf",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13076079",
      "j": 2023,
      "n": "kuhstorf",
      "dn": "Kuhstorf",
      "bl": 13,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "13076080",
      "j": 2023,
      "n": "langen brütz",
      "dn": "Langen Brütz",
      "bl": 13,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "13076082",
      "j": 2023,
      "n": "leezen",
      "dn": "Leezen",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13076085",
      "j": 2023,
      "n": "lewitzrand",
      "dn": "Lewitzrand",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13076086",
      "j": 2023,
      "n": "lübesse",
      "dn": "Lübesse",
      "bl": 13,
      "hs": 373.0,
      "mns": 1
    },
    {
      "a": "13076087",
      "j": 2023,
      "n": "lüblow",
      "dn": "Lüblow",
      "bl": 13,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "13076088",
      "j": 2023,
      "n": "lübtheen",
      "dn": "Lübtheen",
      "bl": 13,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "13076089",
      "j": 2023,
      "n": "lübz",
      "dn": "Lübz",
      "bl": 13,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "13076090",
      "j": 2023,
      "n": "ludwigslust",
      "dn": "Ludwigslust",
      "bl": 13,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "13076092",
      "j": 2023,
      "n": "lüttow-valluhn",
      "dn": "Lüttow-Valluhn",
      "bl": 13,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "13076093",
      "j": 2023,
      "n": "malk göhren",
      "dn": "Malk Göhren",
      "bl": 13,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "13076094",
      "j": 2023,
      "n": "malliß",
      "dn": "Malliß",
      "bl": 13,
      "hs": 435.0,
      "mns": 1
    },
    {
      "a": "13076096",
      "j": 2023,
      "n": "mestlin",
      "dn": "Mestlin",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13076097",
      "j": 2023,
      "n": "milow",
      "dn": "Milow",
      "bl": 13,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "13076098",
      "j": 2023,
      "n": "möllenbeck",
      "dn": "Möllenbeck",
      "bl": 13,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "13076099",
      "j": 2023,
      "n": "moraas",
      "dn": "Moraas",
      "bl": 13,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "13076100",
      "j": 2023,
      "n": "muchow",
      "dn": "Muchow",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13076101",
      "j": 2023,
      "n": "mustin",
      "dn": "Mustin",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13076102",
      "j": 2023,
      "n": "neu gülze",
      "dn": "Neu Gülze",
      "bl": 13,
      "hs": 354.0,
      "mns": 1
    },
    {
      "a": "13076103",
      "j": 2023,
      "n": "neu kaliß",
      "dn": "Neu Kaliß",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13076104",
      "j": 2023,
      "n": "neu poserin",
      "dn": "Neu Poserin",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13076105",
      "j": 2023,
      "n": "neustadt-glewe",
      "dn": "Neustadt-Glewe",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13076106",
      "j": 2023,
      "n": "nostorf",
      "dn": "Nostorf",
      "bl": 13,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "13076107",
      "j": 2023,
      "n": "pampow",
      "dn": "Pampow",
      "bl": 13,
      "hs": 396.0,
      "mns": 1
    },
    {
      "a": "13076108",
      "j": 2023,
      "n": "parchim",
      "dn": "Parchim",
      "bl": 13,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "13076109",
      "j": 2023,
      "n": "passow",
      "dn": "Passow",
      "bl": 13,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "13076110",
      "j": 2023,
      "n": "pätow-steegen",
      "dn": "Pätow-Steegen",
      "bl": 13,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "13076111",
      "j": 2023,
      "n": "picher",
      "dn": "Picher",
      "bl": 13,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "13076112",
      "j": 2023,
      "n": "pinnow",
      "dn": "Pinnow",
      "bl": 13,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "13076113",
      "j": 2023,
      "n": "plate",
      "dn": "Plate",
      "bl": 13,
      "hs": 396.0,
      "mns": 1
    },
    {
      "a": "13076114",
      "j": 2023,
      "n": "plau am see",
      "dn": "Plau am See",
      "bl": 13,
      "hs": 428.0,
      "mns": 1
    },
    {
      "a": "13076115",
      "j": 2023,
      "n": "prislich",
      "dn": "Prislich",
      "bl": 13,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "13076116",
      "j": 2023,
      "n": "pritzier",
      "dn": "Pritzier",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13076117",
      "j": 2023,
      "n": "raben steinfeld",
      "dn": "Raben Steinfeld",
      "bl": 13,
      "hs": 439.0,
      "mns": 1
    },
    {
      "a": "13076118",
      "j": 2023,
      "n": "rastow",
      "dn": "Rastow",
      "bl": 13,
      "hs": 426.0,
      "mns": 1
    },
    {
      "a": "13076119",
      "j": 2023,
      "n": "redefin",
      "dn": "Redefin",
      "bl": 13,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "13076120",
      "j": 2023,
      "n": "rom",
      "dn": "Rom",
      "bl": 13,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "13076121",
      "j": 2023,
      "n": "schossin",
      "dn": "Schossin",
      "bl": 13,
      "hs": 396.0,
      "mns": 1
    },
    {
      "a": "13076122",
      "j": 2023,
      "n": "schwanheide",
      "dn": "Schwanheide",
      "bl": 13,
      "hs": 396.0,
      "mns": 1
    },
    {
      "a": "13076125",
      "j": 2023,
      "n": "siggelkow",
      "dn": "Siggelkow",
      "bl": 13,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "13076126",
      "j": 2023,
      "n": "spornitz",
      "dn": "Spornitz",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13076128",
      "j": 2023,
      "n": "sternberg",
      "dn": "Sternberg",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13076129",
      "j": 2023,
      "n": "stolpe",
      "dn": "Stolpe",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13076130",
      "j": 2023,
      "n": "stralendorf",
      "dn": "Stralendorf",
      "bl": 13,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "13076131",
      "j": 2023,
      "n": "strohkirchen",
      "dn": "Strohkirchen",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13076133",
      "j": 2023,
      "n": "sukow",
      "dn": "Sukow",
      "bl": 13,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "13076134",
      "j": 2023,
      "n": "sülstorf",
      "dn": "Sülstorf",
      "bl": 13,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "13076135",
      "j": 2023,
      "n": "techentin",
      "dn": "Techentin",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13076136",
      "j": 2023,
      "n": "teldau",
      "dn": "Teldau",
      "bl": 13,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "13076138",
      "j": 2023,
      "n": "tessin b. boizenburg",
      "dn": "Tessin b. Boizenburg",
      "bl": 13,
      "hs": 416.0,
      "mns": 1
    },
    {
      "a": "13076140",
      "j": 2023,
      "n": "tramm",
      "dn": "Tramm",
      "bl": 13,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "13076141",
      "j": 2023,
      "n": "uelitz",
      "dn": "Uelitz",
      "bl": 13,
      "hs": 396.0,
      "mns": 1
    },
    {
      "a": "13076142",
      "j": 2023,
      "n": "vellahn",
      "dn": "Vellahn",
      "bl": 13,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "13076143",
      "j": 2023,
      "n": "vielank",
      "dn": "Vielank",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13076145",
      "j": 2023,
      "n": "warlitz",
      "dn": "Warlitz",
      "bl": 13,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "13076146",
      "j": 2023,
      "n": "warlow",
      "dn": "Warlow",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13076147",
      "j": 2023,
      "n": "warsow",
      "dn": "Warsow",
      "bl": 13,
      "hs": 396.0,
      "mns": 1
    },
    {
      "a": "13076148",
      "j": 2023,
      "n": "weitendorf (bei brüel)",
      "dn": "Weitendorf (bei Brüel)",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13076151",
      "j": 2023,
      "n": "werder",
      "dn": "Werder",
      "bl": 13,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "13076152",
      "j": 2023,
      "n": "wittenburg",
      "dn": "Wittenburg",
      "bl": 13,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "13076153",
      "j": 2023,
      "n": "wittendörp",
      "dn": "Wittendörp",
      "bl": 13,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "13076154",
      "j": 2023,
      "n": "wittenförden",
      "dn": "Wittenförden",
      "bl": 13,
      "hs": 324.0,
      "mns": 1
    },
    {
      "a": "13076155",
      "j": 2023,
      "n": "witzin",
      "dn": "Witzin",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13076156",
      "j": 2023,
      "n": "wöbbelin",
      "dn": "Wöbbelin",
      "bl": 13,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "13076158",
      "j": 2023,
      "n": "zapel",
      "dn": "Zapel",
      "bl": 13,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "13076159",
      "j": 2023,
      "n": "zarrentin am schaalsee",
      "dn": "Zarrentin am Schaalsee",
      "bl": 13,
      "hs": 435.0,
      "mns": 1
    },
    {
      "a": "13076160",
      "j": 2023,
      "n": "ziegendorf",
      "dn": "Ziegendorf",
      "bl": 13,
      "hs": 408.0,
      "mns": 1
    },
    {
      "a": "13076161",
      "j": 2023,
      "n": "zierzow",
      "dn": "Zierzow",
      "bl": 13,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "13076162",
      "j": 2023,
      "n": "zölkow",
      "dn": "Zölkow",
      "bl": 13,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "13076163",
      "j": 2023,
      "n": "zülow",
      "dn": "Zülow",
      "bl": 13,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "13076164",
      "j": 2023,
      "n": "obere warnow",
      "dn": "Obere Warnow",
      "bl": 13,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "13076165",
      "j": 2023,
      "n": "gehlsbach",
      "dn": "Gehlsbach",
      "bl": 13,
      "hs": 406.0,
      "mns": 1
    },
    {
      "a": "13076166",
      "j": 2023,
      "n": "ganzlin",
      "dn": "Ganzlin",
      "bl": 13,
      "hs": 437.0,
      "mns": 1
    },
    {
      "a": "13076167",
      "j": 2023,
      "n": "kloster tempzin",
      "dn": "Kloster Tempzin",
      "bl": 13,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "13076168",
      "j": 2023,
      "n": "ruhner berge",
      "dn": "Ruhner Berge",
      "bl": 13,
      "hs": 396.0,
      "mns": 1
    },
    {
      "a": "13076169",
      "j": 2023,
      "n": "toddin",
      "dn": "Toddin",
      "bl": 13,
      "hs": 420.0,
      "mns": 1
    }
  ],
  "14": [
    {
      "a": "14511000",
      "j": 2025,
      "n": "chemnitz",
      "dn": "Chemnitz",
      "bl": 14,
      "hs": 493.0,
      "mns": 2
    },
    {
      "a": "14521010",
      "j": 2023,
      "n": "amtsberg",
      "dn": "Amtsberg",
      "bl": 14,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "14521020",
      "j": 2023,
      "n": "annaberg-buchholz",
      "dn": "Annaberg-Buchholz",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14521035",
      "j": 2023,
      "n": "aue-bad schlema",
      "dn": "Aue-Bad Schlema",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14521040",
      "j": 2023,
      "n": "auerbach",
      "dn": "Auerbach",
      "bl": 14,
      "hs": 435.0,
      "mns": 1
    },
    {
      "a": "14521060",
      "j": 2023,
      "n": "bärenstein",
      "dn": "Bärenstein",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14521080",
      "j": 2023,
      "n": "bockau",
      "dn": "Bockau",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14521090",
      "j": 2023,
      "n": "börnichen/erzgeb.",
      "dn": "Börnichen/Erzgeb.",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14521110",
      "j": 2023,
      "n": "breitenbrunn/erzgebirge",
      "dn": "Breitenbrunn/Erzgebirge",
      "bl": 14,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "14521120",
      "j": 2023,
      "n": "burkhardtsdorf",
      "dn": "Burkhardtsdorf",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14521130",
      "j": 2023,
      "n": "crottendorf",
      "dn": "Crottendorf",
      "bl": 14,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "14521140",
      "j": 2023,
      "n": "deutschneudorf",
      "dn": "Deutschneudorf",
      "bl": 14,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "14521150",
      "j": 2023,
      "n": "drebach",
      "dn": "Drebach",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14521160",
      "j": 2023,
      "n": "ehrenfriedersdorf",
      "dn": "Ehrenfriedersdorf",
      "bl": 14,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "14521170",
      "j": 2023,
      "n": "eibenstock",
      "dn": "Eibenstock",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14521180",
      "j": 2023,
      "n": "elterlein",
      "dn": "Elterlein",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14521200",
      "j": 2023,
      "n": "gelenau/erzgeb.",
      "dn": "Gelenau/Erzgeb.",
      "bl": 14,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "14521210",
      "j": 2023,
      "n": "geyer",
      "dn": "Geyer",
      "bl": 14,
      "hs": 435.0,
      "mns": 1
    },
    {
      "a": "14521220",
      "j": 2023,
      "n": "gornau/erzgeb.",
      "dn": "Gornau/Erzgeb.",
      "bl": 14,
      "hs": 428.0,
      "mns": 1
    },
    {
      "a": "14521230",
      "j": 2023,
      "n": "gornsdorf",
      "dn": "Gornsdorf",
      "bl": 14,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "14521240",
      "j": 2023,
      "n": "großolbersdorf",
      "dn": "Großolbersdorf",
      "bl": 14,
      "hs": 428.0,
      "mns": 1
    },
    {
      "a": "14521250",
      "j": 2023,
      "n": "großrückerswalde",
      "dn": "Großrückerswalde",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14521260",
      "j": 2023,
      "n": "grünhain-beierfeld",
      "dn": "Grünhain-Beierfeld",
      "bl": 14,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "14521270",
      "j": 2023,
      "n": "grünhainichen",
      "dn": "Grünhainichen",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14521280",
      "j": 2023,
      "n": "heidersdorf",
      "dn": "Heidersdorf",
      "bl": 14,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "14521290",
      "j": 2023,
      "n": "hohndorf",
      "dn": "Hohndorf",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14521310",
      "j": 2023,
      "n": "jahnsdorf/erzgeb.",
      "dn": "Jahnsdorf/Erzgeb.",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14521320",
      "j": 2023,
      "n": "johanngeorgenstadt",
      "dn": "Johanngeorgenstadt",
      "bl": 14,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "14521330",
      "j": 2023,
      "n": "jöhstadt",
      "dn": "Jöhstadt",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14521340",
      "j": 2023,
      "n": "königswalde",
      "dn": "Königswalde",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14521355",
      "j": 2023,
      "n": "lauter-bernsbach",
      "dn": "Lauter-Bernsbach",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14521370",
      "j": 2023,
      "n": "lößnitz",
      "dn": "Lößnitz",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14521380",
      "j": 2023,
      "n": "lugau",
      "dn": "Lugau",
      "bl": 14,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "14521390",
      "j": 2023,
      "n": "marienberg",
      "dn": "Marienberg",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14521400",
      "j": 2023,
      "n": "mildenau",
      "dn": "Mildenau",
      "bl": 14,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "14521410",
      "j": 2025,
      "n": "neukirchen/erzgeb.",
      "dn": "Neukirchen/Erzgeb.",
      "bl": 14,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "14521420",
      "j": 2023,
      "n": "niederdorf",
      "dn": "Niederdorf",
      "bl": 14,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "14521430",
      "j": 2023,
      "n": "niederwürschnitz",
      "dn": "Niederwürschnitz",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14521440",
      "j": 2023,
      "n": "oberwiesenthal",
      "dn": "Oberwiesenthal",
      "bl": 14,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "14521450",
      "j": 2023,
      "n": "oelsnitz/erzgeb.",
      "dn": "Oelsnitz/Erzgeb.",
      "bl": 14,
      "hs": 413.0,
      "mns": 1
    },
    {
      "a": "14521460",
      "j": 2023,
      "n": "olbernhau",
      "dn": "Olbernhau",
      "bl": 14,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "14521495",
      "j": 2023,
      "n": "pockau-lengefeld",
      "dn": "Pockau-Lengefeld",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14521500",
      "j": 2023,
      "n": "raschau-markersbach",
      "dn": "Raschau-Markersbach",
      "bl": 14,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "14521510",
      "j": 2023,
      "n": "scheibenberg",
      "dn": "Scheibenberg",
      "bl": 14,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "14521520",
      "j": 2023,
      "n": "schlettau",
      "dn": "Schlettau",
      "bl": 14,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "14521530",
      "j": 2023,
      "n": "schneeberg",
      "dn": "Schneeberg",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14521540",
      "j": 2023,
      "n": "schönheide",
      "dn": "Schönheide",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14521550",
      "j": 2023,
      "n": "schwarzenberg/erzgebirge",
      "dn": "Schwarzenberg/Erzgebirge",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14521560",
      "j": 2023,
      "n": "sehmatal",
      "dn": "Sehmatal",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14521570",
      "j": 2023,
      "n": "seiffen",
      "dn": "Seiffen",
      "bl": 14,
      "hs": 435.0,
      "mns": 1
    },
    {
      "a": "14521590",
      "j": 2023,
      "n": "stollberg/erzgebirge",
      "dn": "Stollberg/Erzgebirge",
      "bl": 14,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "14521600",
      "j": 2023,
      "n": "stützengrün",
      "dn": "Stützengrün",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14521610",
      "j": 2023,
      "n": "tannenberg",
      "dn": "Tannenberg",
      "bl": 14,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "14521620",
      "j": 2023,
      "n": "thalheim/erzgeb.",
      "dn": "Thalheim/Erzgeb.",
      "bl": 14,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "14521630",
      "j": 2023,
      "n": "thermalbad wiesenbad",
      "dn": "Thermalbad Wiesenbad",
      "bl": 14,
      "hs": 428.0,
      "mns": 1
    },
    {
      "a": "14521640",
      "j": 2023,
      "n": "thum",
      "dn": "Thum",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14521670",
      "j": 2023,
      "n": "wolkenstein",
      "dn": "Wolkenstein",
      "bl": 14,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "14521690",
      "j": 2023,
      "n": "zschopau",
      "dn": "Zschopau",
      "bl": 14,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "14521700",
      "j": 2023,
      "n": "zschorlau",
      "dn": "Zschorlau",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14521710",
      "j": 2023,
      "n": "zwönitz",
      "dn": "Zwönitz",
      "bl": 14,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "14522010",
      "j": 2023,
      "n": "altmittweida",
      "dn": "Altmittweida",
      "bl": 14,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "14522020",
      "j": 2023,
      "n": "augustusburg",
      "dn": "Augustusburg",
      "bl": 14,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "14522035",
      "j": 2023,
      "n": "bobritzsch-hilbersdorf",
      "dn": "Bobritzsch-Hilbersdorf",
      "bl": 14,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "14522050",
      "j": 2023,
      "n": "brand-erbisdorf",
      "dn": "Brand-Erbisdorf",
      "bl": 14,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "14522060",
      "j": 2023,
      "n": "burgstädt",
      "dn": "Burgstädt",
      "bl": 14,
      "hs": 555.0,
      "mns": 1
    },
    {
      "a": "14522070",
      "j": 2023,
      "n": "claußnitz",
      "dn": "Claußnitz",
      "bl": 14,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "14522080",
      "j": 2023,
      "n": "döbeln",
      "dn": "Döbeln",
      "bl": 14,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "14522090",
      "j": 2023,
      "n": "dorfchemnitz",
      "dn": "Dorfchemnitz",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14522110",
      "j": 2023,
      "n": "eppendorf",
      "dn": "Eppendorf",
      "bl": 14,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "14522120",
      "j": 2023,
      "n": "erlau",
      "dn": "Erlau",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14522140",
      "j": 2023,
      "n": "flöha",
      "dn": "Flöha",
      "bl": 14,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "14522150",
      "j": 2023,
      "n": "frankenberg/sa.",
      "dn": "Frankenberg/Sa.",
      "bl": 14,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "14522170",
      "j": 2023,
      "n": "frauenstein",
      "dn": "Frauenstein",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14522180",
      "j": 2023,
      "n": "freiberg",
      "dn": "Freiberg",
      "bl": 14,
      "hs": 460.0,
      "mns": 2
    },
    {
      "a": "14522190",
      "j": 2023,
      "n": "geringswalde",
      "dn": "Geringswalde",
      "bl": 14,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "14522200",
      "j": 2023,
      "n": "großhartmannsdorf",
      "dn": "Großhartmannsdorf",
      "bl": 14,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "14522210",
      "j": 2023,
      "n": "großschirma",
      "dn": "Großschirma",
      "bl": 14,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "14522220",
      "j": 2023,
      "n": "großweitzschen",
      "dn": "Großweitzschen",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14522230",
      "j": 2023,
      "n": "hainichen",
      "dn": "Hainichen",
      "bl": 14,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "14522240",
      "j": 2023,
      "n": "halsbrücke",
      "dn": "Halsbrücke",
      "bl": 14,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "14522250",
      "j": 2023,
      "n": "hartha",
      "dn": "Hartha",
      "bl": 14,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "14522260",
      "j": 2023,
      "n": "hartmannsdorf",
      "dn": "Hartmannsdorf",
      "bl": 14,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "14522280",
      "j": 2023,
      "n": "königsfeld",
      "dn": "Königsfeld",
      "bl": 14,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "14522290",
      "j": 2023,
      "n": "königshain-wiederau",
      "dn": "Königshain-Wiederau",
      "bl": 14,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "14522300",
      "j": 2023,
      "n": "kriebstein",
      "dn": "Kriebstein",
      "bl": 14,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "14522310",
      "j": 2025,
      "n": "leisnig",
      "dn": "Leisnig",
      "bl": 14,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "14522320",
      "j": 2023,
      "n": "leubsdorf",
      "dn": "Leubsdorf",
      "bl": 14,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "14522330",
      "j": 2023,
      "n": "lichtenau",
      "dn": "Lichtenau",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14522340",
      "j": 2023,
      "n": "lichtenberg/erzgeb.",
      "dn": "Lichtenberg/Erzgeb.",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14522350",
      "j": 2023,
      "n": "lunzenau",
      "dn": "Lunzenau",
      "bl": 14,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "14522360",
      "j": 2023,
      "n": "mittweida",
      "dn": "Mittweida",
      "bl": 14,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "14522380",
      "j": 2023,
      "n": "mühlau",
      "dn": "Mühlau",
      "bl": 14,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "14522390",
      "j": 2023,
      "n": "mulda/sa.",
      "dn": "Mulda/Sa.",
      "bl": 14,
      "hs": 428.0,
      "mns": 1
    },
    {
      "a": "14522400",
      "j": 2023,
      "n": "neuhausen/erzgeb.",
      "dn": "Neuhausen/Erzgeb.",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14522420",
      "j": 2023,
      "n": "niederwiesa",
      "dn": "Niederwiesa",
      "bl": 14,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "14522430",
      "j": 2023,
      "n": "oberschöna",
      "dn": "Oberschöna",
      "bl": 14,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "14522440",
      "j": 2023,
      "n": "oederan",
      "dn": "Oederan",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14522450",
      "j": 2022,
      "n": "ostrau",
      "dn": "Ostrau",
      "bl": 14,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "14522460",
      "j": 2023,
      "n": "penig",
      "dn": "Penig",
      "bl": 14,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "14522470",
      "j": 2023,
      "n": "rechenberg-bienenmühle",
      "dn": "Rechenberg-Bienenmühle",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14522480",
      "j": 2023,
      "n": "reinsberg",
      "dn": "Reinsberg",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14522490",
      "j": 2023,
      "n": "rochlitz",
      "dn": "Rochlitz",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14522500",
      "j": 2023,
      "n": "rossau",
      "dn": "Rossau",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14522510",
      "j": 2023,
      "n": "roßwein",
      "dn": "Roßwein",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14522520",
      "j": 2023,
      "n": "sayda",
      "dn": "Sayda",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14522530",
      "j": 2023,
      "n": "seelitz",
      "dn": "Seelitz",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14522540",
      "j": 2023,
      "n": "striegistal",
      "dn": "Striegistal",
      "bl": 14,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "14522550",
      "j": 2023,
      "n": "taura",
      "dn": "Taura",
      "bl": 14,
      "hs": 530.0,
      "mns": 1
    },
    {
      "a": "14522570",
      "j": 2023,
      "n": "waldheim",
      "dn": "Waldheim",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14522580",
      "j": 2023,
      "n": "wechselburg",
      "dn": "Wechselburg",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14522590",
      "j": 2023,
      "n": "weißenborn/erzgeb.",
      "dn": "Weißenborn/Erzgeb.",
      "bl": 14,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "14522600",
      "j": 2023,
      "n": "zettlitz",
      "dn": "Zettlitz",
      "bl": 14,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "14522620",
      "j": 2022,
      "n": "zschaitz-ottewig",
      "dn": "Zschaitz-Ottewig",
      "bl": 14,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "14523010",
      "j": 2023,
      "n": "adorf/vogtl.",
      "dn": "Adorf/Vogtl.",
      "bl": 14,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "14523020",
      "j": 2023,
      "n": "auerbach/vogtl.",
      "dn": "Auerbach/Vogtl.",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14523030",
      "j": 2023,
      "n": "bad brambach",
      "dn": "Bad Brambach",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14523040",
      "j": 2023,
      "n": "bad elster",
      "dn": "Bad Elster",
      "bl": 14,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "14523050",
      "j": 2023,
      "n": "bergen",
      "dn": "Bergen",
      "bl": 14,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "14523060",
      "j": 2023,
      "n": "bösenbrunn",
      "dn": "Bösenbrunn",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14523080",
      "j": 2023,
      "n": "eichigt",
      "dn": "Eichigt",
      "bl": 14,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "14523090",
      "j": 2023,
      "n": "ellefeld",
      "dn": "Ellefeld",
      "bl": 14,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "14523100",
      "j": 2023,
      "n": "elsterberg",
      "dn": "Elsterberg",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14523120",
      "j": 2023,
      "n": "falkenstein/vogtl.",
      "dn": "Falkenstein/Vogtl.",
      "bl": 14,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "14523130",
      "j": 2023,
      "n": "grünbach",
      "dn": "Grünbach",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14523150",
      "j": 2023,
      "n": "heinsdorfergrund",
      "dn": "Heinsdorfergrund",
      "bl": 14,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "14523160",
      "j": 2023,
      "n": "klingenthal",
      "dn": "Klingenthal",
      "bl": 14,
      "hs": 435.0,
      "mns": 1
    },
    {
      "a": "14523170",
      "j": 2023,
      "n": "lengenfeld",
      "dn": "Lengenfeld",
      "bl": 14,
      "hs": 428.0,
      "mns": 1
    },
    {
      "a": "14523190",
      "j": 2023,
      "n": "limbach",
      "dn": "Limbach",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14523200",
      "j": 2023,
      "n": "markneukirchen",
      "dn": "Markneukirchen",
      "bl": 14,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "14523230",
      "j": 2023,
      "n": "mühlental",
      "dn": "Mühlental",
      "bl": 14,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "14523245",
      "j": 2023,
      "n": "muldenhammer",
      "dn": "Muldenhammer",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14523260",
      "j": 2023,
      "n": "netzschkau",
      "dn": "Netzschkau",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14523270",
      "j": 2023,
      "n": "neuensalz",
      "dn": "Neuensalz",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14523280",
      "j": 2023,
      "n": "neumark",
      "dn": "Neumark",
      "bl": 14,
      "hs": 457.0,
      "mns": 1
    },
    {
      "a": "14523290",
      "j": 2023,
      "n": "neustadt/vogtl.",
      "dn": "Neustadt/Vogtl.",
      "bl": 14,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "14523300",
      "j": 2023,
      "n": "oelsnitz/vogtl.",
      "dn": "Oelsnitz/Vogtl.",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14523310",
      "j": 2023,
      "n": "pausa-mühltroff",
      "dn": "Pausa-Mühltroff",
      "bl": 14,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "14523320",
      "j": 2023,
      "n": "plauen",
      "dn": "Plauen",
      "bl": 14,
      "hs": 505.0,
      "mns": 1
    },
    {
      "a": "14523330",
      "j": 2023,
      "n": "pöhl",
      "dn": "Pöhl",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14523340",
      "j": 2023,
      "n": "reichenbach im vogtland",
      "dn": "Reichenbach im Vogtland",
      "bl": 14,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "14523360",
      "j": 2023,
      "n": "rodewisch",
      "dn": "Rodewisch",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14523365",
      "j": 2023,
      "n": "rosenbach/vogtl.",
      "dn": "Rosenbach/Vogtl.",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14523370",
      "j": 2023,
      "n": "schöneck/vogtland",
      "dn": "Schöneck/Vogtland",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14523380",
      "j": 2023,
      "n": "steinberg",
      "dn": "Steinberg",
      "bl": 14,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "14523410",
      "j": 2023,
      "n": "theuma",
      "dn": "Theuma",
      "bl": 14,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "14523420",
      "j": 2023,
      "n": "tirpersdorf",
      "dn": "Tirpersdorf",
      "bl": 14,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "14523430",
      "j": 2023,
      "n": "treuen",
      "dn": "Treuen",
      "bl": 14,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "14523440",
      "j": 2023,
      "n": "triebel",
      "dn": "Triebel",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14523450",
      "j": 2023,
      "n": "weischlitz",
      "dn": "Weischlitz",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14523460",
      "j": 2023,
      "n": "werda",
      "dn": "Werda",
      "bl": 14,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "14524010",
      "j": 2023,
      "n": "bernsdorf",
      "dn": "Bernsdorf",
      "bl": 14,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "14524020",
      "j": 2023,
      "n": "callenberg",
      "dn": "Callenberg",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14524030",
      "j": 2023,
      "n": "crimmitschau",
      "dn": "Crimmitschau",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14524040",
      "j": 2023,
      "n": "crinitzberg",
      "dn": "Crinitzberg",
      "bl": 14,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "14524050",
      "j": 2023,
      "n": "dennheritz",
      "dn": "Dennheritz",
      "bl": 14,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "14524060",
      "j": 2023,
      "n": "fraureuth",
      "dn": "Fraureuth",
      "bl": 14,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "14524070",
      "j": 2023,
      "n": "gersdorf",
      "dn": "Gersdorf",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14524080",
      "j": 2023,
      "n": "glauchau",
      "dn": "Glauchau",
      "bl": 14,
      "hs": 490.0,
      "mns": 2
    },
    {
      "a": "14524090",
      "j": 2023,
      "n": "hartenstein",
      "dn": "Hartenstein",
      "bl": 14,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "14524100",
      "j": 2023,
      "n": "hartmannsdorf bei kirchberg",
      "dn": "Hartmannsdorf bei Kirchberg",
      "bl": 14,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "14524110",
      "j": 2023,
      "n": "hirschfeld",
      "dn": "Hirschfeld",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14524120",
      "j": 2023,
      "n": "hohenstein-ernstthal",
      "dn": "Hohenstein-Ernstthal",
      "bl": 14,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "14524130",
      "j": 2023,
      "n": "kirchberg",
      "dn": "Kirchberg",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14524140",
      "j": 2023,
      "n": "langenbernsdorf",
      "dn": "Langenbernsdorf",
      "bl": 14,
      "hs": 413.0,
      "mns": 1
    },
    {
      "a": "14524150",
      "j": 2023,
      "n": "langenweißbach",
      "dn": "Langenweißbach",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14524160",
      "j": 2023,
      "n": "lichtenstein/sa.",
      "dn": "Lichtenstein/Sa.",
      "bl": 14,
      "hs": 473.0,
      "mns": 1
    },
    {
      "a": "14524170",
      "j": 2023,
      "n": "lichtentanne",
      "dn": "Lichtentanne",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14524180",
      "j": 2023,
      "n": "limbach-oberfrohna",
      "dn": "Limbach-Oberfrohna",
      "bl": 14,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "14524190",
      "j": 2023,
      "n": "meerane",
      "dn": "Meerane",
      "bl": 14,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "14524200",
      "j": 2023,
      "n": "mülsen",
      "dn": "Mülsen",
      "bl": 14,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "14524210",
      "j": 2023,
      "n": "neukirchen/pleiße",
      "dn": "Neukirchen/Pleiße",
      "bl": 14,
      "hs": 435.0,
      "mns": 1
    },
    {
      "a": "14524220",
      "j": 2023,
      "n": "niederfrohna",
      "dn": "Niederfrohna",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14524230",
      "j": 2023,
      "n": "oberlungwitz",
      "dn": "Oberlungwitz",
      "bl": 14,
      "hs": 365.0,
      "mns": 1
    },
    {
      "a": "14524240",
      "j": 2023,
      "n": "oberwiera",
      "dn": "Oberwiera",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14524250",
      "j": 2023,
      "n": "reinsdorf",
      "dn": "Reinsdorf",
      "bl": 14,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "14524260",
      "j": 2023,
      "n": "remse",
      "dn": "Remse",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14524270",
      "j": 2023,
      "n": "schönberg",
      "dn": "Schönberg",
      "bl": 14,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "14524280",
      "j": 2023,
      "n": "st. egidien",
      "dn": "St. Egidien",
      "bl": 14,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "14524290",
      "j": 2023,
      "n": "waldenburg",
      "dn": "Waldenburg",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14524300",
      "j": 2023,
      "n": "werdau",
      "dn": "Werdau",
      "bl": 14,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "14524310",
      "j": 2023,
      "n": "wildenfels",
      "dn": "Wildenfels",
      "bl": 14,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "14524320",
      "j": 2023,
      "n": "wilkau-haßlau",
      "dn": "Wilkau-Haßlau",
      "bl": 14,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "14524330",
      "j": 2025,
      "n": "zwickau",
      "dn": "Zwickau",
      "bl": 14,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "14612000",
      "j": 2023,
      "n": "dresden",
      "dn": "Dresden",
      "bl": 14,
      "hs": 635.0,
      "mns": 3
    },
    {
      "a": "14625010",
      "j": 2023,
      "n": "arnsdorf",
      "dn": "Arnsdorf",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14625020",
      "j": 2023,
      "n": "bautzen",
      "dn": "Bautzen",
      "bl": 14,
      "hs": 460.0,
      "mns": 2
    },
    {
      "a": "14625030",
      "j": 2023,
      "n": "bernsdorf",
      "dn": "Bernsdorf",
      "bl": 14,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "14625040",
      "j": 2023,
      "n": "bischofswerda",
      "dn": "Bischofswerda",
      "bl": 14,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "14625060",
      "j": 2023,
      "n": "burkau",
      "dn": "Burkau",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14625080",
      "j": 2023,
      "n": "crostwitz",
      "dn": "Crostwitz",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14625090",
      "j": 2023,
      "n": "cunewalde",
      "dn": "Cunewalde",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14625100",
      "j": 2023,
      "n": "demitz-thumitz",
      "dn": "Demitz-Thumitz",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14625110",
      "j": 2023,
      "n": "doberschau-gaußig",
      "dn": "Doberschau-Gaußig",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14625120",
      "j": 2023,
      "n": "elsterheide",
      "dn": "Elsterheide",
      "bl": 14,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "14625130",
      "j": 2023,
      "n": "elstra",
      "dn": "Elstra",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14625140",
      "j": 2023,
      "n": "frankenthal",
      "dn": "Frankenthal",
      "bl": 14,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "14625150",
      "j": 2023,
      "n": "göda",
      "dn": "Göda",
      "bl": 14,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "14625160",
      "j": 2023,
      "n": "großdubrau",
      "dn": "Großdubrau",
      "bl": 14,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "14625170",
      "j": 2023,
      "n": "großharthau",
      "dn": "Großharthau",
      "bl": 14,
      "hs": 428.0,
      "mns": 1
    },
    {
      "a": "14625180",
      "j": 2023,
      "n": "großnaundorf",
      "dn": "Großnaundorf",
      "bl": 14,
      "hs": 488.0,
      "mns": 1
    },
    {
      "a": "14625190",
      "j": 2023,
      "n": "großpostwitz",
      "dn": "Großpostwitz",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14625200",
      "j": 2023,
      "n": "großröhrsdorf",
      "dn": "Großröhrsdorf",
      "bl": 14,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "14625220",
      "j": 2023,
      "n": "haselbachtal",
      "dn": "Haselbachtal",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14625230",
      "j": 2023,
      "n": "hochkirch",
      "dn": "Hochkirch",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14625240",
      "j": 2023,
      "n": "hoyerswerda",
      "dn": "Hoyerswerda",
      "bl": 14,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "14625250",
      "j": 2025,
      "n": "kamenz",
      "dn": "Kamenz",
      "bl": 14,
      "hs": 345.0,
      "mns": 2
    },
    {
      "a": "14625270",
      "j": 2023,
      "n": "königsbrück",
      "dn": "Königsbrück",
      "bl": 14,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "14625280",
      "j": 2023,
      "n": "königswartha",
      "dn": "Königswartha",
      "bl": 14,
      "hs": 443.0,
      "mns": 1
    },
    {
      "a": "14625290",
      "j": 2023,
      "n": "kubschütz",
      "dn": "Kubschütz",
      "bl": 14,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "14625300",
      "j": 2023,
      "n": "laußnitz",
      "dn": "Laußnitz",
      "bl": 14,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "14625310",
      "j": 2023,
      "n": "lauta",
      "dn": "Lauta",
      "bl": 14,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "14625320",
      "j": 2023,
      "n": "lichtenberg",
      "dn": "Lichtenberg",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14625330",
      "j": 2023,
      "n": "lohsa",
      "dn": "Lohsa",
      "bl": 14,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "14625340",
      "j": 2023,
      "n": "malschwitz",
      "dn": "Malschwitz",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14625350",
      "j": 2023,
      "n": "nebelschütz",
      "dn": "Nebelschütz",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14625360",
      "j": 2023,
      "n": "neschwitz",
      "dn": "Neschwitz",
      "bl": 14,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "14625370",
      "j": 2023,
      "n": "neukirch",
      "dn": "Neukirch",
      "bl": 14,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "14625380",
      "j": 2023,
      "n": "neukirch/lausitz",
      "dn": "Neukirch/Lausitz",
      "bl": 14,
      "hs": 448.0,
      "mns": 1
    },
    {
      "a": "14625390",
      "j": 2023,
      "n": "obergurig",
      "dn": "Obergurig",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14625410",
      "j": 2023,
      "n": "ohorn",
      "dn": "Ohorn",
      "bl": 14,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "14625420",
      "j": 2023,
      "n": "oßling",
      "dn": "Oßling",
      "bl": 14,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "14625430",
      "j": 2023,
      "n": "ottendorf-okrilla",
      "dn": "Ottendorf-Okrilla",
      "bl": 14,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "14625440",
      "j": 2023,
      "n": "panschwitz-kuckau",
      "dn": "Panschwitz-Kuckau",
      "bl": 14,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "14625450",
      "j": 2023,
      "n": "pulsnitz",
      "dn": "Pulsnitz",
      "bl": 14,
      "hs": 428.0,
      "mns": 1
    },
    {
      "a": "14625460",
      "j": 2023,
      "n": "puschwitz",
      "dn": "Puschwitz",
      "bl": 14,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "14625470",
      "j": 2023,
      "n": "räckelwitz",
      "dn": "Räckelwitz",
      "bl": 14,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "14625480",
      "j": 2023,
      "n": "radeberg",
      "dn": "Radeberg",
      "bl": 14,
      "hs": 427.0,
      "mns": 2
    },
    {
      "a": "14625490",
      "j": 2023,
      "n": "radibor",
      "dn": "Radibor",
      "bl": 14,
      "hs": 435.0,
      "mns": 1
    },
    {
      "a": "14625500",
      "j": 2023,
      "n": "ralbitz-rosenthal",
      "dn": "Ralbitz-Rosenthal",
      "bl": 14,
      "hs": 412.0,
      "mns": 1
    },
    {
      "a": "14625510",
      "j": 2023,
      "n": "rammenau",
      "dn": "Rammenau",
      "bl": 14,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "14625525",
      "j": 2023,
      "n": "schirgiswalde-kirschau",
      "dn": "Schirgiswalde-Kirschau",
      "bl": 14,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "14625530",
      "j": 2023,
      "n": "schmölln-putzkau",
      "dn": "Schmölln-Putzkau",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14625550",
      "j": 2023,
      "n": "schwepnitz",
      "dn": "Schwepnitz",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14625560",
      "j": 2023,
      "n": "sohland an der spree",
      "dn": "Sohland an der Spree",
      "bl": 14,
      "hs": 407.0,
      "mns": 1
    },
    {
      "a": "14625570",
      "j": 2023,
      "n": "spreetal",
      "dn": "Spreetal",
      "bl": 14,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "14625580",
      "j": 2023,
      "n": "steina",
      "dn": "Steina",
      "bl": 14,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "14625590",
      "j": 2023,
      "n": "steinigtwolmsdorf",
      "dn": "Steinigtwolmsdorf",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14625600",
      "j": 2023,
      "n": "wachau",
      "dn": "Wachau",
      "bl": 14,
      "hs": 428.0,
      "mns": 1
    },
    {
      "a": "14625610",
      "j": 2023,
      "n": "weißenberg",
      "dn": "Weißenberg",
      "bl": 14,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "14625630",
      "j": 2023,
      "n": "wilthen",
      "dn": "Wilthen",
      "bl": 14,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "14625640",
      "j": 2023,
      "n": "wittichenau",
      "dn": "Wittichenau",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14626010",
      "j": 2023,
      "n": "bad muskau",
      "dn": "Bad Muskau",
      "bl": 14,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "14626020",
      "j": 2023,
      "n": "beiersdorf",
      "dn": "Beiersdorf",
      "bl": 14,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "14626030",
      "j": 2023,
      "n": "bernstadt a. d. eigen",
      "dn": "Bernstadt a. d. Eigen",
      "bl": 14,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "14626050",
      "j": 2023,
      "n": "bertsdorf-hörnitz",
      "dn": "Bertsdorf-Hörnitz",
      "bl": 14,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "14626060",
      "j": 2023,
      "n": "boxberg/o.l.",
      "dn": "Boxberg/O.L.",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14626070",
      "j": 2023,
      "n": "dürrhennersdorf",
      "dn": "Dürrhennersdorf",
      "bl": 14,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "14626085",
      "j": 2023,
      "n": "ebersbach-neugersdorf",
      "dn": "Ebersbach-Neugersdorf",
      "bl": 14,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "14626100",
      "j": 2023,
      "n": "gablenz",
      "dn": "Gablenz",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14626110",
      "j": 2023,
      "n": "görlitz",
      "dn": "Görlitz",
      "bl": 14,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "14626120",
      "j": 2023,
      "n": "groß düben",
      "dn": "Groß Düben",
      "bl": 14,
      "hs": 413.0,
      "mns": 1
    },
    {
      "a": "14626140",
      "j": 2023,
      "n": "großschönau",
      "dn": "Großschönau",
      "bl": 14,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "14626150",
      "j": 2023,
      "n": "großschweidnitz",
      "dn": "Großschweidnitz",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14626160",
      "j": 2023,
      "n": "hähnichen",
      "dn": "Hähnichen",
      "bl": 14,
      "hs": 428.0,
      "mns": 1
    },
    {
      "a": "14626170",
      "j": 2023,
      "n": "hainewalde",
      "dn": "Hainewalde",
      "bl": 14,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "14626180",
      "j": 2023,
      "n": "herrnhut",
      "dn": "Herrnhut",
      "bl": 14,
      "hs": 412.0,
      "mns": 1
    },
    {
      "a": "14626190",
      "j": 2023,
      "n": "hohendubrau",
      "dn": "Hohendubrau",
      "bl": 14,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "14626200",
      "j": 2023,
      "n": "horka",
      "dn": "Horka",
      "bl": 14,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "14626210",
      "j": 2023,
      "n": "jonsdorf",
      "dn": "Jonsdorf",
      "bl": 14,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "14626230",
      "j": 2023,
      "n": "kodersdorf",
      "dn": "Kodersdorf",
      "bl": 14,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "14626240",
      "j": 2023,
      "n": "königshain",
      "dn": "Königshain",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14626245",
      "j": 2023,
      "n": "kottmar",
      "dn": "Kottmar",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14626250",
      "j": 2023,
      "n": "krauschwitz",
      "dn": "Krauschwitz",
      "bl": 14,
      "hs": 488.0,
      "mns": 1
    },
    {
      "a": "14626260",
      "j": 2023,
      "n": "kreba-neudorf",
      "dn": "Kreba-Neudorf",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14626270",
      "j": 2023,
      "n": "lawalde",
      "dn": "Lawalde",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14626280",
      "j": 2023,
      "n": "leutersdorf",
      "dn": "Leutersdorf",
      "bl": 14,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "14626290",
      "j": 2023,
      "n": "löbau",
      "dn": "Löbau",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14626300",
      "j": 2023,
      "n": "markersdorf",
      "dn": "Markersdorf",
      "bl": 14,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "14626310",
      "j": 2023,
      "n": "mittelherwigsdorf",
      "dn": "Mittelherwigsdorf",
      "bl": 14,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "14626320",
      "j": 2023,
      "n": "mücka",
      "dn": "Mücka",
      "bl": 14,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "14626330",
      "j": 2023,
      "n": "neißeaue",
      "dn": "Neißeaue",
      "bl": 14,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "14626350",
      "j": 2023,
      "n": "neusalza-spremberg",
      "dn": "Neusalza-Spremberg",
      "bl": 14,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "14626370",
      "j": 2023,
      "n": "niesky",
      "dn": "Niesky",
      "bl": 14,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "14626390",
      "j": 2023,
      "n": "oderwitz",
      "dn": "Oderwitz",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14626400",
      "j": 2023,
      "n": "olbersdorf",
      "dn": "Olbersdorf",
      "bl": 14,
      "hs": 495.0,
      "mns": 1
    },
    {
      "a": "14626410",
      "j": 2023,
      "n": "oppach",
      "dn": "Oppach",
      "bl": 14,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "14626420",
      "j": 2023,
      "n": "ostritz",
      "dn": "Ostritz",
      "bl": 14,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "14626430",
      "j": 2023,
      "n": "oybin",
      "dn": "Oybin",
      "bl": 14,
      "hs": 488.0,
      "mns": 1
    },
    {
      "a": "14626440",
      "j": 2023,
      "n": "quitzdorf am see",
      "dn": "Quitzdorf am See",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14626450",
      "j": 2023,
      "n": "reichenbach/o.l.",
      "dn": "Reichenbach/O.L.",
      "bl": 14,
      "hs": 488.0,
      "mns": 1
    },
    {
      "a": "14626460",
      "j": 2023,
      "n": "rietschen",
      "dn": "Rietschen",
      "bl": 14,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "14626470",
      "j": 2023,
      "n": "rosenbach",
      "dn": "Rosenbach",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14626480",
      "j": 2023,
      "n": "rothenburg/o.l.",
      "dn": "Rothenburg/O.L.",
      "bl": 14,
      "hs": 428.0,
      "mns": 1
    },
    {
      "a": "14626490",
      "j": 2023,
      "n": "schleife",
      "dn": "Schleife",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14626500",
      "j": 2023,
      "n": "schönau-berzdorf auf dem eigen",
      "dn": "Schönau-Berzdorf auf dem Eigen",
      "bl": 14,
      "hs": 428.0,
      "mns": 1
    },
    {
      "a": "14626510",
      "j": 2023,
      "n": "schönbach",
      "dn": "Schönbach",
      "bl": 14,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "14626520",
      "j": 2023,
      "n": "schöpstal",
      "dn": "Schöpstal",
      "bl": 14,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "14626530",
      "j": 2023,
      "n": "seifhennersdorf",
      "dn": "Seifhennersdorf",
      "bl": 14,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "14626560",
      "j": 2023,
      "n": "trebendorf",
      "dn": "Trebendorf",
      "bl": 14,
      "hs": 428.0,
      "mns": 1
    },
    {
      "a": "14626570",
      "j": 2023,
      "n": "vierkirchen",
      "dn": "Vierkirchen",
      "bl": 14,
      "hs": 428.0,
      "mns": 1
    },
    {
      "a": "14626580",
      "j": 2023,
      "n": "waldhufen",
      "dn": "Waldhufen",
      "bl": 14,
      "hs": 428.0,
      "mns": 1
    },
    {
      "a": "14626590",
      "j": 2023,
      "n": "weißkeißel",
      "dn": "Weißkeißel",
      "bl": 14,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "14626600",
      "j": 2023,
      "n": "weißwasser/o.l.",
      "dn": "Weißwasser/O.L.",
      "bl": 14,
      "hs": 488.0,
      "mns": 2
    },
    {
      "a": "14626610",
      "j": 2023,
      "n": "zittau",
      "dn": "Zittau",
      "bl": 14,
      "hs": 520.0,
      "mns": 1
    },
    {
      "a": "14627010",
      "j": 2023,
      "n": "coswig",
      "dn": "Coswig",
      "bl": 14,
      "hs": 415.0,
      "mns": 3
    },
    {
      "a": "14627020",
      "j": 2023,
      "n": "diera-zehren",
      "dn": "Diera-Zehren",
      "bl": 14,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "14627030",
      "j": 2023,
      "n": "ebersbach",
      "dn": "Ebersbach",
      "bl": 14,
      "hs": 428.0,
      "mns": 1
    },
    {
      "a": "14627040",
      "j": 2023,
      "n": "glaubitz",
      "dn": "Glaubitz",
      "bl": 14,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "14627050",
      "j": 2023,
      "n": "gröditz",
      "dn": "Gröditz",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14627060",
      "j": 2023,
      "n": "großenhain",
      "dn": "Großenhain",
      "bl": 14,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "14627070",
      "j": 2023,
      "n": "hirschstein",
      "dn": "Hirschstein",
      "bl": 14,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "14627080",
      "j": 2023,
      "n": "käbschütztal",
      "dn": "Käbschütztal",
      "bl": 14,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "14627100",
      "j": 2023,
      "n": "klipphausen",
      "dn": "Klipphausen",
      "bl": 14,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "14627110",
      "j": 2023,
      "n": "lampertswalde",
      "dn": "Lampertswalde",
      "bl": 14,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "14627130",
      "j": 2023,
      "n": "lommatzsch",
      "dn": "Lommatzsch",
      "bl": 14,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "14627140",
      "j": 2023,
      "n": "meißen",
      "dn": "Meißen",
      "bl": 14,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "14627150",
      "j": 2023,
      "n": "moritzburg",
      "dn": "Moritzburg",
      "bl": 14,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "14627170",
      "j": 2023,
      "n": "niederau",
      "dn": "Niederau",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14627180",
      "j": 2023,
      "n": "nossen",
      "dn": "Nossen",
      "bl": 14,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "14627190",
      "j": 2023,
      "n": "nünchritz",
      "dn": "Nünchritz",
      "bl": 14,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "14627200",
      "j": 2023,
      "n": "priestewitz",
      "dn": "Priestewitz",
      "bl": 14,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "14627210",
      "j": 2023,
      "n": "radebeul",
      "dn": "Radebeul",
      "bl": 14,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "14627220",
      "j": 2023,
      "n": "radeburg",
      "dn": "Radeburg",
      "bl": 14,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "14627230",
      "j": 2023,
      "n": "riesa",
      "dn": "Riesa",
      "bl": 14,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "14627240",
      "j": 2023,
      "n": "röderaue",
      "dn": "Röderaue",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14627250",
      "j": 2023,
      "n": "schönfeld",
      "dn": "Schönfeld",
      "bl": 14,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "14627260",
      "j": 2023,
      "n": "stauchitz",
      "dn": "Stauchitz",
      "bl": 14,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "14627270",
      "j": 2023,
      "n": "strehla",
      "dn": "Strehla",
      "bl": 14,
      "hs": 428.0,
      "mns": 1
    },
    {
      "a": "14627290",
      "j": 2023,
      "n": "thiendorf",
      "dn": "Thiendorf",
      "bl": 14,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "14627310",
      "j": 2023,
      "n": "weinböhla",
      "dn": "Weinböhla",
      "bl": 14,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "14627340",
      "j": 2023,
      "n": "wülknitz",
      "dn": "Wülknitz",
      "bl": 14,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "14627360",
      "j": 2023,
      "n": "zeithain",
      "dn": "Zeithain",
      "bl": 14,
      "hs": 485.0,
      "mns": 1
    },
    {
      "a": "14628010",
      "j": 2023,
      "n": "altenberg",
      "dn": "Altenberg",
      "bl": 14,
      "hs": 495.0,
      "mns": 1
    },
    {
      "a": "14628020",
      "j": 2023,
      "n": "bad gottleuba-berggießhübel",
      "dn": "Bad Gottleuba-Berggießhübel",
      "bl": 14,
      "hs": 445.0,
      "mns": 1
    },
    {
      "a": "14628030",
      "j": 2023,
      "n": "bad schandau",
      "dn": "Bad Schandau",
      "bl": 14,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "14628040",
      "j": 2023,
      "n": "bahretal",
      "dn": "Bahretal",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14628050",
      "j": 2023,
      "n": "bannewitz",
      "dn": "Bannewitz",
      "bl": 14,
      "hs": 400.0,
      "mns": 3
    },
    {
      "a": "14628060",
      "j": 2023,
      "n": "dippoldiswalde",
      "dn": "Dippoldiswalde",
      "bl": 14,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "14628070",
      "j": 2025,
      "n": "dohma",
      "dn": "Dohma",
      "bl": 14,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "14628080",
      "j": 2025,
      "n": "dohna",
      "dn": "Dohna",
      "bl": 14,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "14628090",
      "j": 2023,
      "n": "dorfhain",
      "dn": "Dorfhain",
      "bl": 14,
      "hs": 490.0,
      "mns": 1
    },
    {
      "a": "14628100",
      "j": 2023,
      "n": "dürrröhrsdorf-dittersbach",
      "dn": "Dürrröhrsdorf-Dittersbach",
      "bl": 14,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "14628110",
      "j": 2023,
      "n": "freital",
      "dn": "Freital",
      "bl": 14,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "14628130",
      "j": 2023,
      "n": "glashütte",
      "dn": "Glashütte",
      "bl": 14,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "14628140",
      "j": 2023,
      "n": "gohrisch",
      "dn": "Gohrisch",
      "bl": 14,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "14628150",
      "j": 2023,
      "n": "hartmannsdorf-reichenau",
      "dn": "Hartmannsdorf-Reichenau",
      "bl": 14,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "14628160",
      "j": 2023,
      "n": "heidenau",
      "dn": "Heidenau",
      "bl": 14,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "14628170",
      "j": 2023,
      "n": "hermsdorf/erzgeb.",
      "dn": "Hermsdorf/Erzgeb.",
      "bl": 14,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "14628190",
      "j": 2023,
      "n": "hohnstein",
      "dn": "Hohnstein",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14628205",
      "j": 2023,
      "n": "klingenberg",
      "dn": "Klingenberg",
      "bl": 14,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "14628210",
      "j": 2023,
      "n": "königstein",
      "dn": "Königstein",
      "bl": 14,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "14628220",
      "j": 2023,
      "n": "kreischa",
      "dn": "Kreischa",
      "bl": 14,
      "hs": 423.0,
      "mns": 1
    },
    {
      "a": "14628230",
      "j": 2023,
      "n": "liebstadt",
      "dn": "Liebstadt",
      "bl": 14,
      "hs": 457.0,
      "mns": 1
    },
    {
      "a": "14628240",
      "j": 2023,
      "n": "lohmen",
      "dn": "Lohmen",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14628250",
      "j": 2023,
      "n": "müglitztal",
      "dn": "Müglitztal",
      "bl": 14,
      "hs": 600.0,
      "mns": 1
    },
    {
      "a": "14628260",
      "j": 2023,
      "n": "neustadt i. sa.",
      "dn": "Neustadt i. Sa.",
      "bl": 14,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "14628270",
      "j": 2025,
      "n": "pirna",
      "dn": "Pirna",
      "bl": 14,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "14628300",
      "j": 2023,
      "n": "rabenau",
      "dn": "Rabenau",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14628310",
      "j": 2023,
      "n": "rathen",
      "dn": "Rathen",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14628320",
      "j": 2023,
      "n": "rathmannsdorf",
      "dn": "Rathmannsdorf",
      "bl": 14,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "14628330",
      "j": 2023,
      "n": "reinhardtsdorf-schöna",
      "dn": "Reinhardtsdorf-Schöna",
      "bl": 14,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "14628340",
      "j": 2023,
      "n": "rosenthal-bielatal",
      "dn": "Rosenthal-Bielatal",
      "bl": 14,
      "hs": 510.0,
      "mns": 1
    },
    {
      "a": "14628360",
      "j": 2023,
      "n": "sebnitz",
      "dn": "Sebnitz",
      "bl": 14,
      "hs": 480.0,
      "mns": 1
    },
    {
      "a": "14628370",
      "j": 2023,
      "n": "stadt wehlen",
      "dn": "Stadt Wehlen",
      "bl": 14,
      "hs": 465.0,
      "mns": 1
    },
    {
      "a": "14628380",
      "j": 2023,
      "n": "stolpen",
      "dn": "Stolpen",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14628390",
      "j": 2023,
      "n": "struppen",
      "dn": "Struppen",
      "bl": 14,
      "hs": 455.0,
      "mns": 1
    },
    {
      "a": "14628400",
      "j": 2023,
      "n": "tharandt",
      "dn": "Tharandt",
      "bl": 14,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "14628410",
      "j": 2023,
      "n": "wilsdruff",
      "dn": "Wilsdruff",
      "bl": 14,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "14713000",
      "j": 2025,
      "n": "leipzig",
      "dn": "Leipzig",
      "bl": 14,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "14729010",
      "j": 2023,
      "n": "bad lausick",
      "dn": "Bad Lausick",
      "bl": 14,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "14729020",
      "j": 2023,
      "n": "belgershain",
      "dn": "Belgershain",
      "bl": 14,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "14729030",
      "j": 2023,
      "n": "bennewitz",
      "dn": "Bennewitz",
      "bl": 14,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "14729040",
      "j": 2023,
      "n": "böhlen",
      "dn": "Böhlen",
      "bl": 14,
      "hs": 433.0,
      "mns": 2
    },
    {
      "a": "14729050",
      "j": 2023,
      "n": "borna",
      "dn": "Borna",
      "bl": 14,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "14729060",
      "j": 2023,
      "n": "borsdorf",
      "dn": "Borsdorf",
      "bl": 14,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "14729070",
      "j": 2023,
      "n": "brandis",
      "dn": "Brandis",
      "bl": 14,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "14729080",
      "j": 2023,
      "n": "colditz",
      "dn": "Colditz",
      "bl": 14,
      "hs": 490.0,
      "mns": 2
    },
    {
      "a": "14729100",
      "j": 2023,
      "n": "elstertrebnitz",
      "dn": "Elstertrebnitz",
      "bl": 14,
      "hs": 413.0,
      "mns": 2
    },
    {
      "a": "14729140",
      "j": 2023,
      "n": "frohburg",
      "dn": "Frohburg",
      "bl": 14,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "14729150",
      "j": 2023,
      "n": "geithain",
      "dn": "Geithain",
      "bl": 14,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "14729160",
      "j": 2023,
      "n": "grimma",
      "dn": "Grimma",
      "bl": 14,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "14729170",
      "j": 2023,
      "n": "groitzsch",
      "dn": "Groitzsch",
      "bl": 14,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "14729190",
      "j": 2023,
      "n": "großpösna",
      "dn": "Großpösna",
      "bl": 14,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "14729220",
      "j": 2023,
      "n": "kitzscher",
      "dn": "Kitzscher",
      "bl": 14,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "14729245",
      "j": 2023,
      "n": "lossatal",
      "dn": "Lossatal",
      "bl": 14,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "14729250",
      "j": 2023,
      "n": "machern",
      "dn": "Machern",
      "bl": 14,
      "hs": 530.0,
      "mns": 2
    },
    {
      "a": "14729260",
      "j": 2023,
      "n": "markkleeberg",
      "dn": "Markkleeberg",
      "bl": 14,
      "hs": 420.0,
      "mns": 3
    },
    {
      "a": "14729270",
      "j": 2023,
      "n": "markranstädt",
      "dn": "Markranstädt",
      "bl": 14,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "14729300",
      "j": 2023,
      "n": "naunhof",
      "dn": "Naunhof",
      "bl": 14,
      "hs": 485.0,
      "mns": 2
    },
    {
      "a": "14729320",
      "j": 2023,
      "n": "neukieritzsch",
      "dn": "Neukieritzsch",
      "bl": 14,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "14729330",
      "j": 2023,
      "n": "otterwisch",
      "dn": "Otterwisch",
      "bl": 14,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "14729340",
      "j": 2023,
      "n": "parthenstein",
      "dn": "Parthenstein",
      "bl": 14,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "14729350",
      "j": 2023,
      "n": "pegau",
      "dn": "Pegau",
      "bl": 14,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "14729360",
      "j": 2023,
      "n": "regis-breitingen",
      "dn": "Regis-Breitingen",
      "bl": 14,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "14729370",
      "j": 2023,
      "n": "rötha",
      "dn": "Rötha",
      "bl": 14,
      "hs": 500.0,
      "mns": 2
    },
    {
      "a": "14729380",
      "j": 2025,
      "n": "thallwitz",
      "dn": "Thallwitz",
      "bl": 14,
      "hs": 325.0,
      "mns": 2
    },
    {
      "a": "14729400",
      "j": 2023,
      "n": "trebsen/mulde",
      "dn": "Trebsen/Mulde",
      "bl": 14,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "14729410",
      "j": 2023,
      "n": "wurzen",
      "dn": "Wurzen",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14729430",
      "j": 2023,
      "n": "zwenkau",
      "dn": "Zwenkau",
      "bl": 14,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "14730010",
      "j": 2023,
      "n": "gemeinde arzberg",
      "dn": "Gemeinde Arzberg",
      "bl": 14,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "14730020",
      "j": 2023,
      "n": "bad düben",
      "dn": "Bad Düben",
      "bl": 14,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "14730030",
      "j": 2023,
      "n": "beilrode",
      "dn": "Beilrode",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14730045",
      "j": 2023,
      "n": "belgern-schildau",
      "dn": "Belgern-Schildau",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14730050",
      "j": 2023,
      "n": "cavertitz",
      "dn": "Cavertitz",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14730060",
      "j": 2023,
      "n": "dahlen",
      "dn": "Dahlen",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14730070",
      "j": 2023,
      "n": "delitzsch",
      "dn": "Delitzsch",
      "bl": 14,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "14730080",
      "j": 2023,
      "n": "doberschütz",
      "dn": "Doberschütz",
      "bl": 14,
      "hs": 428.0,
      "mns": 1
    },
    {
      "a": "14730090",
      "j": 2023,
      "n": "dommitzsch",
      "dn": "Dommitzsch",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14730100",
      "j": 2023,
      "n": "dreiheide",
      "dn": "Dreiheide",
      "bl": 14,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "14730110",
      "j": 2023,
      "n": "eilenburg",
      "dn": "Eilenburg",
      "bl": 14,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "14730120",
      "j": 2023,
      "n": "elsnig",
      "dn": "Elsnig",
      "bl": 14,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "14730140",
      "j": 2023,
      "n": "jesewitz",
      "dn": "Jesewitz",
      "bl": 14,
      "hs": 435.0,
      "mns": 1
    },
    {
      "a": "14730150",
      "j": 2024,
      "n": "krostitz",
      "dn": "Krostitz",
      "bl": 14,
      "hs": 435.0,
      "mns": 1
    },
    {
      "a": "14730160",
      "j": 2023,
      "n": "laußig",
      "dn": "Laußig",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14730170",
      "j": 2023,
      "n": "liebschützberg",
      "dn": "Liebschützberg",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14730180",
      "j": 2023,
      "n": "löbnitz",
      "dn": "Löbnitz",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14730190",
      "j": 2023,
      "n": "mockrehna",
      "dn": "Mockrehna",
      "bl": 14,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "14730200",
      "j": 2023,
      "n": "mügeln",
      "dn": "Mügeln",
      "bl": 14,
      "hs": 427.0,
      "mns": 1
    },
    {
      "a": "14730210",
      "j": 2023,
      "n": "naundorf",
      "dn": "Naundorf",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "14730230",
      "j": 2023,
      "n": "oschatz",
      "dn": "Oschatz",
      "bl": 14,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "14730250",
      "j": 2023,
      "n": "rackwitz",
      "dn": "Rackwitz",
      "bl": 14,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "14730270",
      "j": 2023,
      "n": "schkeuditz",
      "dn": "Schkeuditz",
      "bl": 14,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "14730280",
      "j": 2023,
      "n": "schönwölkau",
      "dn": "Schönwölkau",
      "bl": 14,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "14730300",
      "j": 2023,
      "n": "taucha",
      "dn": "Taucha",
      "bl": 14,
      "hs": 480.0,
      "mns": 3
    },
    {
      "a": "14730310",
      "j": 2023,
      "n": "torgau",
      "dn": "Torgau",
      "bl": 14,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "14730320",
      "j": 2023,
      "n": "trossin",
      "dn": "Trossin",
      "bl": 14,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "14730330",
      "j": 2025,
      "n": "wermsdorf",
      "dn": "Wermsdorf",
      "bl": 14,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "14730340",
      "j": 2023,
      "n": "wiedemar",
      "dn": "Wiedemar",
      "bl": 14,
      "hs": 385.0,
      "mns": 1
    },
    {
      "a": "14730360",
      "j": 2023,
      "n": "zschepplin",
      "dn": "Zschepplin",
      "bl": 14,
      "hs": 420.0,
      "mns": 1
    }
  ],
  "15": [
    {
      "a": "15001000",
      "j": 2023,
      "n": "dessau-roßlau",
      "dn": "Dessau-Roßlau",
      "bl": 15,
      "hs": 495.0,
      "mns": 3
    },
    {
      "a": "15002000",
      "j": 2025,
      "n": "halle (saale)",
      "dn": "Halle (Saale)",
      "bl": 15,
      "hs": 500.0,
      "mns": 3
    },
    {
      "a": "15003000",
      "j": 2023,
      "n": "magdeburg",
      "dn": "Magdeburg",
      "bl": 15,
      "hs": 495.0,
      "mns": 3
    },
    {
      "a": "15081026",
      "j": 2023,
      "n": "apenburg-winterfeld",
      "dn": "Apenburg-Winterfeld",
      "bl": 15,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "15081030",
      "j": 2023,
      "n": "arendsee",
      "dn": "Arendsee",
      "bl": 15,
      "hs": 411.0,
      "mns": 1
    },
    {
      "a": "15081045",
      "j": 2023,
      "n": "beetzendorf",
      "dn": "Beetzendorf",
      "bl": 15,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "15081095",
      "j": 2023,
      "n": "dähre",
      "dn": "Dähre",
      "bl": 15,
      "hs": 340.0,
      "mns": 1
    },
    {
      "a": "15081105",
      "j": 2023,
      "n": "diesdorf",
      "dn": "Diesdorf",
      "bl": 15,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "15081135",
      "j": 2023,
      "n": "gardelegen",
      "dn": "Gardelegen",
      "bl": 15,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "15081225",
      "j": 2023,
      "n": "jübar",
      "dn": "Jübar",
      "bl": 15,
      "hs": 335.0,
      "mns": 1
    },
    {
      "a": "15081240",
      "j": 2023,
      "n": "kalbe (milde)",
      "dn": "Kalbe (Milde)",
      "bl": 15,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "15081280",
      "j": 2023,
      "n": "klötze",
      "dn": "Klötze",
      "bl": 15,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "15081290",
      "j": 2023,
      "n": "kuhfelde",
      "dn": "Kuhfelde",
      "bl": 15,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "15081440",
      "j": 2023,
      "n": "rohrberg",
      "dn": "Rohrberg",
      "bl": 15,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "15081455",
      "j": 2023,
      "n": "salzwedel",
      "dn": "Salzwedel",
      "bl": 15,
      "hs": 370.0,
      "mns": 2
    },
    {
      "a": "15081545",
      "j": 2023,
      "n": "wallstawe",
      "dn": "Wallstawe",
      "bl": 15,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "15082005",
      "j": 2023,
      "n": "aken (elbe)",
      "dn": "Aken (Elbe)",
      "bl": 15,
      "hs": 422.0,
      "mns": 2
    },
    {
      "a": "15082015",
      "j": 2023,
      "n": "bitterfeld-wolfen",
      "dn": "Bitterfeld-Wolfen",
      "bl": 15,
      "hs": 390.0,
      "mns": 3
    },
    {
      "a": "15082180",
      "j": 2023,
      "n": "köthen",
      "dn": "Köthen",
      "bl": 15,
      "hs": 480.0,
      "mns": 3
    },
    {
      "a": "15082241",
      "j": 2023,
      "n": "muldestausee",
      "dn": "Muldestausee",
      "bl": 15,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "15082256",
      "j": 2023,
      "n": "osternienburger land",
      "dn": "Osternienburger Land",
      "bl": 15,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "15082301",
      "j": 2023,
      "n": "raguhn-jeßnitz",
      "dn": "Raguhn-Jeßnitz",
      "bl": 15,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "15082340",
      "j": 2023,
      "n": "sandersdorf-brehna",
      "dn": "Sandersdorf-Brehna",
      "bl": 15,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "15082377",
      "j": 2023,
      "n": "südliches anhalt",
      "dn": "Südliches Anhalt",
      "bl": 15,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "15082430",
      "j": 2023,
      "n": "zerbst/anhalt",
      "dn": "Zerbst/Anhalt",
      "bl": 15,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "15082440",
      "j": 2023,
      "n": "zörbig",
      "dn": "Zörbig",
      "bl": 15,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "15083020",
      "j": 2023,
      "n": "altenhausen",
      "dn": "Altenhausen",
      "bl": 15,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "15083025",
      "j": 2023,
      "n": "am großen bruch",
      "dn": "Am Großen Bruch",
      "bl": 15,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "15083030",
      "j": 2023,
      "n": "angern",
      "dn": "Angern",
      "bl": 15,
      "hs": 336.0,
      "mns": 1
    },
    {
      "a": "15083035",
      "j": 2023,
      "n": "ausleben",
      "dn": "Ausleben",
      "bl": 15,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "15083040",
      "j": 2023,
      "n": "barleben",
      "dn": "Barleben",
      "bl": 15,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "15083060",
      "j": 2023,
      "n": "beendorf",
      "dn": "Beendorf",
      "bl": 15,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "15083115",
      "j": 2023,
      "n": "bülstringen",
      "dn": "Bülstringen",
      "bl": 15,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "15083120",
      "j": 2023,
      "n": "burgstall",
      "dn": "Burgstall",
      "bl": 15,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "15083125",
      "j": 2023,
      "n": "calvörde",
      "dn": "Calvörde",
      "bl": 15,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "15083130",
      "j": 2023,
      "n": "colbitz",
      "dn": "Colbitz",
      "bl": 15,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "15083190",
      "j": 2023,
      "n": "eilsleben",
      "dn": "Eilsleben",
      "bl": 15,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "15083205",
      "j": 2023,
      "n": "erxleben",
      "dn": "Erxleben",
      "bl": 15,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "15083230",
      "j": 2023,
      "n": "flechtingen",
      "dn": "Flechtingen",
      "bl": 15,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "15083245",
      "j": 2023,
      "n": "gröningen",
      "dn": "Gröningen",
      "bl": 15,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "15083270",
      "j": 2023,
      "n": "haldensleben",
      "dn": "Haldensleben",
      "bl": 15,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "15083275",
      "j": 2023,
      "n": "harbke",
      "dn": "Harbke",
      "bl": 15,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "15083298",
      "j": 2023,
      "n": "hohe börde",
      "dn": "Hohe Börde",
      "bl": 15,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "15083320",
      "j": 2023,
      "n": "hötensleben",
      "dn": "Hötensleben",
      "bl": 15,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "15083323",
      "j": 2023,
      "n": "ingersleben",
      "dn": "Ingersleben",
      "bl": 15,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "15083355",
      "j": 2023,
      "n": "kroppenstedt",
      "dn": "Kroppenstedt",
      "bl": 15,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "15083361",
      "j": 2023,
      "n": "loitsche-heinrichsberg",
      "dn": "Loitsche-Heinrichsberg",
      "bl": 15,
      "hs": 345.0,
      "mns": 1
    },
    {
      "a": "15083390",
      "j": 2023,
      "n": "niedere börde",
      "dn": "Niedere Börde",
      "bl": 15,
      "hs": 575.0,
      "mns": 1
    },
    {
      "a": "15083411",
      "j": 2023,
      "n": "oebisfelde-weferlingen",
      "dn": "Oebisfelde-Weferlingen",
      "bl": 15,
      "hs": 375.0,
      "mns": 2
    },
    {
      "a": "15083415",
      "j": 2023,
      "n": "oschersleben",
      "dn": "Oschersleben",
      "bl": 15,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "15083440",
      "j": 2023,
      "n": "rogätz",
      "dn": "Rogätz",
      "bl": 15,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "15083485",
      "j": 2023,
      "n": "sommersdorf",
      "dn": "Sommersdorf",
      "bl": 15,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "15083490",
      "j": 2023,
      "n": "sülzetal",
      "dn": "Sülzetal",
      "bl": 15,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "15083505",
      "j": 2023,
      "n": "ummendorf",
      "dn": "Ummendorf",
      "bl": 15,
      "hs": 355.0,
      "mns": 1
    },
    {
      "a": "15083515",
      "j": 2023,
      "n": "völpke",
      "dn": "Völpke",
      "bl": 15,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "15083531",
      "j": 2023,
      "n": "wanzleben-börde",
      "dn": "Wanzleben-Börde",
      "bl": 15,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "15083535",
      "j": 2023,
      "n": "wefensleben",
      "dn": "Wefensleben",
      "bl": 15,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "15083557",
      "j": 2023,
      "n": "westheide",
      "dn": "Westheide",
      "bl": 15,
      "hs": 354.0,
      "mns": 1
    },
    {
      "a": "15083565",
      "j": 2023,
      "n": "wolmirstedt",
      "dn": "Wolmirstedt",
      "bl": 15,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "15083580",
      "j": 2023,
      "n": "zielitz",
      "dn": "Zielitz",
      "bl": 15,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "15084012",
      "j": 2023,
      "n": "an der poststraße",
      "dn": "An der Poststraße",
      "bl": 15,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "15084013",
      "j": 2023,
      "n": "meineweh",
      "dn": "Meineweh",
      "bl": 15,
      "hs": 386.0,
      "mns": 1
    },
    {
      "a": "15084015",
      "j": 2023,
      "n": "bad bibra",
      "dn": "Bad Bibra",
      "bl": 15,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "15084025",
      "j": 2023,
      "n": "balgstädt",
      "dn": "Balgstädt",
      "bl": 15,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "15084115",
      "j": 2023,
      "n": "droyßig",
      "dn": "Droyßig",
      "bl": 15,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "15084125",
      "j": 2023,
      "n": "eckartsberga",
      "dn": "Eckartsberga",
      "bl": 15,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "15084130",
      "j": 2023,
      "n": "elsteraue",
      "dn": "Elsteraue",
      "bl": 15,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "15084132",
      "j": 2023,
      "n": "finne",
      "dn": "Finne",
      "bl": 15,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "15084133",
      "j": 2023,
      "n": "finneland",
      "dn": "Finneland",
      "bl": 15,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "15084135",
      "j": 2023,
      "n": "freyburg",
      "dn": "Freyburg",
      "bl": 15,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "15084150",
      "j": 2023,
      "n": "gleina, unstruttal",
      "dn": "Gleina, Unstruttal",
      "bl": 15,
      "hs": 325.0,
      "mns": 1
    },
    {
      "a": "15084170",
      "j": 2023,
      "n": "goseck",
      "dn": "Goseck",
      "bl": 15,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "15084207",
      "j": 2023,
      "n": "gutenborn",
      "dn": "Gutenborn",
      "bl": 15,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "15084235",
      "j": 2023,
      "n": "hohenmölsen",
      "dn": "Hohenmölsen",
      "bl": 15,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "15084246",
      "j": 2023,
      "n": "kaiserpfalz",
      "dn": "Kaiserpfalz",
      "bl": 15,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "15084250",
      "j": 2023,
      "n": "karsdorf",
      "dn": "Karsdorf",
      "bl": 15,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "15084275",
      "j": 2023,
      "n": "kretzschau",
      "dn": "Kretzschau",
      "bl": 15,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "15084282",
      "j": 2023,
      "n": "lanitz-hassel-tal",
      "dn": "Lanitz-Hassel-Tal",
      "bl": 15,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "15084285",
      "j": 2023,
      "n": "laucha an der unstrut",
      "dn": "Laucha an der Unstrut",
      "bl": 15,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "15084315",
      "j": 2023,
      "n": "lützen",
      "dn": "Lützen",
      "bl": 15,
      "hs": 330.0,
      "mns": 1
    },
    {
      "a": "15084335",
      "j": 2023,
      "n": "mertendorf",
      "dn": "Mertendorf",
      "bl": 15,
      "hs": 386.0,
      "mns": 1
    },
    {
      "a": "15084341",
      "j": 2023,
      "n": "molauer land",
      "dn": "Molauer Land",
      "bl": 15,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "15084355",
      "j": 2023,
      "n": "naumburg (saale)",
      "dn": "Naumburg (Saale)",
      "bl": 15,
      "hs": 450.0,
      "mns": 3
    },
    {
      "a": "15084360",
      "j": 2023,
      "n": "nebra",
      "dn": "Nebra",
      "bl": 15,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "15084375",
      "j": 2023,
      "n": "osterfeld",
      "dn": "Osterfeld",
      "bl": 15,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "15084442",
      "j": 2023,
      "n": "schnaudertal",
      "dn": "Schnaudertal",
      "bl": 15,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "15084445",
      "j": 2023,
      "n": "schönburg",
      "dn": "Schönburg",
      "bl": 15,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "15084470",
      "j": 2023,
      "n": "stößen",
      "dn": "Stößen",
      "bl": 15,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "15084490",
      "j": 2023,
      "n": "teuchern",
      "dn": "Teuchern",
      "bl": 15,
      "hs": 377.0,
      "mns": 1
    },
    {
      "a": "15084550",
      "j": 2023,
      "n": "weißenfels",
      "dn": "Weißenfels",
      "bl": 15,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "15084560",
      "j": 2023,
      "n": "wethau",
      "dn": "Wethau",
      "bl": 15,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "15084565",
      "j": 2023,
      "n": "wetterzeube",
      "dn": "Wetterzeube",
      "bl": 15,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "15084590",
      "j": 2023,
      "n": "zeitz",
      "dn": "Zeitz",
      "bl": 15,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "15085040",
      "j": 2023,
      "n": "ballenstedt",
      "dn": "Ballenstedt",
      "bl": 15,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "15085055",
      "j": 2023,
      "n": "blankenburg",
      "dn": "Blankenburg",
      "bl": 15,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "15085090",
      "j": 2023,
      "n": "ditfurt",
      "dn": "Ditfurt",
      "bl": 15,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "15085110",
      "j": 2023,
      "n": "falkenstein/harz",
      "dn": "Falkenstein/Harz",
      "bl": 15,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "15085125",
      "j": 2023,
      "n": "groß quenstedt",
      "dn": "Groß Quenstedt",
      "bl": 15,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "15085135",
      "j": 2023,
      "n": "halberstadt",
      "dn": "Halberstadt",
      "bl": 15,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "15085140",
      "j": 2023,
      "n": "harsleben",
      "dn": "Harsleben",
      "bl": 15,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "15085145",
      "j": 2023,
      "n": "harzgerode",
      "dn": "Harzgerode",
      "bl": 15,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "15085160",
      "j": 2023,
      "n": "hedersleben",
      "dn": "Hedersleben",
      "bl": 15,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "15085185",
      "j": 2023,
      "n": "huy",
      "dn": "Huy",
      "bl": 15,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "15085190",
      "j": 2023,
      "n": "ilsenburg",
      "dn": "Ilsenburg",
      "bl": 15,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "15085227",
      "j": 2023,
      "n": "nordharz",
      "dn": "Nordharz",
      "bl": 15,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "15085228",
      "j": 2023,
      "n": "oberharz am brocken",
      "dn": "Oberharz am Brocken",
      "bl": 15,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "15085230",
      "j": 2025,
      "n": "osterwieck",
      "dn": "Osterwieck",
      "bl": 15,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "15085235",
      "j": 2023,
      "n": "quedlinburg",
      "dn": "Quedlinburg",
      "bl": 15,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "15085285",
      "j": 2023,
      "n": "schwanebeck",
      "dn": "Schwanebeck",
      "bl": 15,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "15085287",
      "j": 2023,
      "n": "selke-aue",
      "dn": "Selke-Aue",
      "bl": 15,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "15085330",
      "j": 2023,
      "n": "thale",
      "dn": "Thale",
      "bl": 15,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "15085365",
      "j": 2023,
      "n": "wegeleben",
      "dn": "Wegeleben",
      "bl": 15,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "15085370",
      "j": 2025,
      "n": "wernigerode",
      "dn": "Wernigerode",
      "bl": 15,
      "hs": 438.0,
      "mns": 2
    },
    {
      "a": "15086005",
      "j": 2023,
      "n": "biederitz",
      "dn": "Biederitz",
      "bl": 15,
      "hs": 465.0,
      "mns": 2
    },
    {
      "a": "15086015",
      "j": 2023,
      "n": "burg",
      "dn": "Burg",
      "bl": 15,
      "hs": 424.0,
      "mns": 2
    },
    {
      "a": "15086035",
      "j": 2023,
      "n": "elbe-parey",
      "dn": "Elbe-Parey",
      "bl": 15,
      "hs": 375.0,
      "mns": 2
    },
    {
      "a": "15086040",
      "j": 2023,
      "n": "genthin",
      "dn": "Genthin",
      "bl": 15,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "15086055",
      "j": 2023,
      "n": "gommern",
      "dn": "Gommern",
      "bl": 15,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "15086080",
      "j": 2023,
      "n": "jerichow",
      "dn": "Jerichow",
      "bl": 15,
      "hs": 411.0,
      "mns": 2
    },
    {
      "a": "15086140",
      "j": 2023,
      "n": "möckern",
      "dn": "Möckern",
      "bl": 15,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "15086145",
      "j": 2023,
      "n": "möser",
      "dn": "Möser",
      "bl": 15,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "15087010",
      "j": 2023,
      "n": "ahlsdorf",
      "dn": "Ahlsdorf",
      "bl": 15,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "15087015",
      "j": 2023,
      "n": "allstedt",
      "dn": "Allstedt",
      "bl": 15,
      "hs": 411.0,
      "mns": 1
    },
    {
      "a": "15087031",
      "j": 2023,
      "n": "arnstein",
      "dn": "Arnstein",
      "bl": 15,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "15087045",
      "j": 2023,
      "n": "benndorf",
      "dn": "Benndorf",
      "bl": 15,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "15087055",
      "j": 2023,
      "n": "berga",
      "dn": "Berga",
      "bl": 15,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "15087070",
      "j": 2023,
      "n": "blankenheim",
      "dn": "Blankenheim",
      "bl": 15,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "15087075",
      "j": 2023,
      "n": "bornstedt",
      "dn": "Bornstedt",
      "bl": 15,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "15087101",
      "j": 2023,
      "n": "brücken-hackpfüffel",
      "dn": "Brücken-Hackpfüffel",
      "bl": 15,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "15087125",
      "j": 2023,
      "n": "edersleben",
      "dn": "Edersleben",
      "bl": 15,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "15087130",
      "j": 2023,
      "n": "lutherstadt eisleben",
      "dn": "Lutherstadt Eisleben",
      "bl": 15,
      "hs": 433.0,
      "mns": 2
    },
    {
      "a": "15087165",
      "j": 2023,
      "n": "gerbstedt",
      "dn": "Gerbstedt",
      "bl": 15,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "15087205",
      "j": 2023,
      "n": "helbra",
      "dn": "Helbra",
      "bl": 15,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "15087210",
      "j": 2023,
      "n": "hergisdorf",
      "dn": "Hergisdorf",
      "bl": 15,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "15087220",
      "j": 2023,
      "n": "hettstedt",
      "dn": "Hettstedt",
      "bl": 15,
      "hs": 416.0,
      "mns": 2
    },
    {
      "a": "15087250",
      "j": 2023,
      "n": "kelbra (kyffhäuser)",
      "dn": "Kelbra (Kyffhäuser)",
      "bl": 15,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "15087260",
      "j": 2023,
      "n": "klostermansfeld",
      "dn": "Klostermansfeld",
      "bl": 15,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "15087275",
      "j": 2023,
      "n": "mansfeld",
      "dn": "Mansfeld",
      "bl": 15,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "15087370",
      "j": 2023,
      "n": "sangerhausen",
      "dn": "Sangerhausen",
      "bl": 15,
      "hs": 433.0,
      "mns": 2
    },
    {
      "a": "15087386",
      "j": 2023,
      "n": "seegebiet mansfelder land",
      "dn": "Seegebiet Mansfelder Land",
      "bl": 15,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "15087412",
      "j": 2023,
      "n": "südharz",
      "dn": "Südharz",
      "bl": 15,
      "hs": 411.0,
      "mns": 1
    },
    {
      "a": "15087440",
      "j": 2023,
      "n": "wallhausen",
      "dn": "Wallhausen",
      "bl": 15,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "15087470",
      "j": 2023,
      "n": "wimmelburg",
      "dn": "Wimmelburg",
      "bl": 15,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "15088020",
      "j": 2023,
      "n": "bad dürrenberg",
      "dn": "Bad Dürrenberg",
      "bl": 15,
      "hs": 385.0,
      "mns": 2
    },
    {
      "a": "15088025",
      "j": 2023,
      "n": "bad lauchstädt",
      "dn": "Bad Lauchstädt",
      "bl": 15,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "15088030",
      "j": 2023,
      "n": "barnstädt",
      "dn": "Barnstädt",
      "bl": 15,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "15088065",
      "j": 2023,
      "n": "braunsbedra",
      "dn": "Braunsbedra",
      "bl": 15,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "15088100",
      "j": 2023,
      "n": "farnstädt",
      "dn": "Farnstädt",
      "bl": 15,
      "hs": 360.0,
      "mns": 2
    },
    {
      "a": "15088150",
      "j": 2023,
      "n": "kabelsketal",
      "dn": "Kabelsketal",
      "bl": 15,
      "hs": 330.0,
      "mns": 2
    },
    {
      "a": "15088195",
      "j": 2023,
      "n": "landsberg",
      "dn": "Landsberg",
      "bl": 15,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "15088205",
      "j": 2023,
      "n": "leuna",
      "dn": "Leuna",
      "bl": 15,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "15088216",
      "j": 2023,
      "n": "wettin-löbejün",
      "dn": "Wettin-Löbejün",
      "bl": 15,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "15088220",
      "j": 2023,
      "n": "merseburg",
      "dn": "Merseburg",
      "bl": 15,
      "hs": 495.0,
      "mns": 3
    },
    {
      "a": "15088235",
      "j": 2025,
      "n": "mücheln",
      "dn": "Mücheln",
      "bl": 15,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "15088250",
      "j": 2023,
      "n": "nemsdorf-göhrendorf",
      "dn": "Nemsdorf-Göhrendorf",
      "bl": 15,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "15088265",
      "j": 2023,
      "n": "obhausen",
      "dn": "Obhausen",
      "bl": 15,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "15088295",
      "j": 2023,
      "n": "petersberg",
      "dn": "Petersberg",
      "bl": 15,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "15088305",
      "j": 2023,
      "n": "querfurt",
      "dn": "Querfurt",
      "bl": 15,
      "hs": 416.0,
      "mns": 2
    },
    {
      "a": "15088319",
      "j": 2023,
      "n": "salzatal",
      "dn": "Salzatal",
      "bl": 15,
      "hs": 422.0,
      "mns": 2
    },
    {
      "a": "15088330",
      "j": 2023,
      "n": "schkopau",
      "dn": "Schkopau",
      "bl": 15,
      "hs": 380.0,
      "mns": 3
    },
    {
      "a": "15088340",
      "j": 2023,
      "n": "schraplau",
      "dn": "Schraplau",
      "bl": 15,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "15088355",
      "j": 2023,
      "n": "steigra",
      "dn": "Steigra",
      "bl": 15,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "15088365",
      "j": 2023,
      "n": "teutschenthal",
      "dn": "Teutschenthal",
      "bl": 15,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "15089005",
      "j": 2023,
      "n": "alsleben (saale)",
      "dn": "Alsleben (Saale)",
      "bl": 15,
      "hs": 480.0,
      "mns": 2
    },
    {
      "a": "15089015",
      "j": 2023,
      "n": "aschersleben",
      "dn": "Aschersleben",
      "bl": 15,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "15089026",
      "j": 2023,
      "n": "barby",
      "dn": "Barby",
      "bl": 15,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "15089030",
      "j": 2023,
      "n": "bernburg (saale)",
      "dn": "Bernburg (Saale)",
      "bl": 15,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "15089041",
      "j": 2023,
      "n": "bördeaue",
      "dn": "Bördeaue",
      "bl": 15,
      "hs": 402.0,
      "mns": 2
    },
    {
      "a": "15089042",
      "j": 2023,
      "n": "bördeland",
      "dn": "Bördeland",
      "bl": 15,
      "hs": 399.0,
      "mns": 2
    },
    {
      "a": "15089043",
      "j": 2023,
      "n": "börde-hakel",
      "dn": "Börde-Hakel",
      "bl": 15,
      "hs": 416.0,
      "mns": 2
    },
    {
      "a": "15089045",
      "j": 2023,
      "n": "borne",
      "dn": "Borne",
      "bl": 15,
      "hs": 402.0,
      "mns": 2
    },
    {
      "a": "15089055",
      "j": 2023,
      "n": "calbe (saale)",
      "dn": "Calbe (Saale)",
      "bl": 15,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "15089075",
      "j": 2023,
      "n": "egeln",
      "dn": "Egeln",
      "bl": 15,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "15089130",
      "j": 2023,
      "n": "giersleben",
      "dn": "Giersleben",
      "bl": 15,
      "hs": 338.0,
      "mns": 2
    },
    {
      "a": "15089165",
      "j": 2023,
      "n": "güsten",
      "dn": "Güsten",
      "bl": 15,
      "hs": 442.0,
      "mns": 2
    },
    {
      "a": "15089175",
      "j": 2023,
      "n": "hecklingen",
      "dn": "Hecklingen",
      "bl": 15,
      "hs": 411.0,
      "mns": 2
    },
    {
      "a": "15089185",
      "j": 2023,
      "n": "ilberstedt",
      "dn": "Ilberstedt",
      "bl": 15,
      "hs": 380.0,
      "mns": 2
    },
    {
      "a": "15089195",
      "j": 2023,
      "n": "könnern",
      "dn": "Könnern",
      "bl": 15,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "15089235",
      "j": 2023,
      "n": "nienburg (saale)",
      "dn": "Nienburg (Saale)",
      "bl": 15,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "15089245",
      "j": 2023,
      "n": "plötzkau",
      "dn": "Plötzkau",
      "bl": 15,
      "hs": 388.0,
      "mns": 2
    },
    {
      "a": "15089305",
      "j": 2023,
      "n": "schönebeck (elbe)",
      "dn": "Schönebeck (Elbe)",
      "bl": 15,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "15089307",
      "j": 2023,
      "n": "seeland",
      "dn": "Seeland",
      "bl": 15,
      "hs": 402.0,
      "mns": 2
    },
    {
      "a": "15089310",
      "j": 2023,
      "n": "staßfurt",
      "dn": "Staßfurt",
      "bl": 15,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "15089365",
      "j": 2023,
      "n": "wolmirsleben",
      "dn": "Wolmirsleben",
      "bl": 15,
      "hs": 402.0,
      "mns": 2
    },
    {
      "a": "15090003",
      "j": 2023,
      "n": "aland",
      "dn": "Aland",
      "bl": 15,
      "hs": 354.0,
      "mns": 1
    },
    {
      "a": "15090007",
      "j": 2023,
      "n": "altmärkische höhe",
      "dn": "Altmärkische Höhe",
      "bl": 15,
      "hs": 354.0,
      "mns": 1
    },
    {
      "a": "15090008",
      "j": 2023,
      "n": "altmärkische wische",
      "dn": "Altmärkische Wische",
      "bl": 15,
      "hs": 345.0,
      "mns": 1
    },
    {
      "a": "15090010",
      "j": 2023,
      "n": "arneburg",
      "dn": "Arneburg",
      "bl": 15,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "15090070",
      "j": 2023,
      "n": "bismark",
      "dn": "Bismark",
      "bl": 15,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "15090135",
      "j": 2023,
      "n": "eichstedt",
      "dn": "Eichstedt",
      "bl": 15,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "15090180",
      "j": 2023,
      "n": "goldbeck",
      "dn": "Goldbeck",
      "bl": 15,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "15090220",
      "j": 2023,
      "n": "hassel",
      "dn": "Hassel",
      "bl": 15,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "15090225",
      "j": 2023,
      "n": "havelberg",
      "dn": "Havelberg",
      "bl": 15,
      "hs": 411.0,
      "mns": 1
    },
    {
      "a": "15090245",
      "j": 2023,
      "n": "hohenberg-krusemark",
      "dn": "Hohenberg-Krusemark",
      "bl": 15,
      "hs": 320.0,
      "mns": 1
    },
    {
      "a": "15090270",
      "j": 2023,
      "n": "iden",
      "dn": "Iden",
      "bl": 15,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "15090285",
      "j": 2023,
      "n": "kamern",
      "dn": "Kamern",
      "bl": 15,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "15090310",
      "j": 2023,
      "n": "klietz",
      "dn": "Klietz",
      "bl": 15,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "15090415",
      "j": 2023,
      "n": "osterburg",
      "dn": "Osterburg",
      "bl": 15,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "15090435",
      "j": 2023,
      "n": "rochau",
      "dn": "Rochau",
      "bl": 15,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "15090445",
      "j": 2023,
      "n": "sandau",
      "dn": "Sandau",
      "bl": 15,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "15090485",
      "j": 2023,
      "n": "schollene",
      "dn": "Schollene",
      "bl": 15,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "15090500",
      "j": 2023,
      "n": "schönhausen (elbe)",
      "dn": "Schönhausen (Elbe)",
      "bl": 15,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "15090520",
      "j": 2023,
      "n": "seehausen (altmark)",
      "dn": "Seehausen (Altmark)",
      "bl": 15,
      "hs": 394.0,
      "mns": 1
    },
    {
      "a": "15090535",
      "j": 2023,
      "n": "stendal",
      "dn": "Stendal",
      "bl": 15,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "15090546",
      "j": 2023,
      "n": "tangerhütte",
      "dn": "Tangerhütte",
      "bl": 15,
      "hs": 350.0,
      "mns": 2
    },
    {
      "a": "15090550",
      "j": 2023,
      "n": "tangermünde",
      "dn": "Tangermünde",
      "bl": 15,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "15090610",
      "j": 2023,
      "n": "werben",
      "dn": "Werben",
      "bl": 15,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "15090631",
      "j": 2023,
      "n": "wust-fischbeck",
      "dn": "Wust-Fischbeck",
      "bl": 15,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "15090635",
      "j": 2023,
      "n": "zehrental",
      "dn": "Zehrental",
      "bl": 15,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "15091010",
      "j": 2023,
      "n": "annaburg",
      "dn": "Annaburg",
      "bl": 15,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "15091020",
      "j": 2023,
      "n": "bad schmiedeberg",
      "dn": "Bad Schmiedeberg",
      "bl": 15,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "15091060",
      "j": 2023,
      "n": "coswig",
      "dn": "Coswig",
      "bl": 15,
      "hs": 416.0,
      "mns": 1
    },
    {
      "a": "15091110",
      "j": 2023,
      "n": "gräfenhainichen",
      "dn": "Gräfenhainichen",
      "bl": 15,
      "hs": 373.0,
      "mns": 2
    },
    {
      "a": "15091145",
      "j": 2023,
      "n": "jessen (elster)",
      "dn": "Jessen (Elster)",
      "bl": 15,
      "hs": 385.0,
      "mns": 2
    },
    {
      "a": "15091160",
      "j": 2023,
      "n": "kemberg",
      "dn": "Kemberg",
      "bl": 15,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "15091241",
      "j": 2023,
      "n": "oranienbaum-wörlitz",
      "dn": "Oranienbaum-Wörlitz",
      "bl": 15,
      "hs": 415.0,
      "mns": 1
    },
    {
      "a": "15091375",
      "j": 2023,
      "n": "lutherstadt wittenberg",
      "dn": "Lutherstadt Wittenberg",
      "bl": 15,
      "hs": 395.0,
      "mns": 2
    },
    {
      "a": "15091391",
      "j": 2023,
      "n": "zahna-elster",
      "dn": "Zahna-Elster",
      "bl": 15,
      "hs": 370.0,
      "mns": 1
    }
  ],
  "16": [
    {
      "a": "16051000",
      "j": 2025,
      "n": "erfurt",
      "dn": "Erfurt",
      "bl": 16,
      "hs": 565.0,
      "mns": 3
    },
    {
      "a": "16052000",
      "j": 2023,
      "n": "gera",
      "dn": "Gera",
      "bl": 16,
      "hs": 600.0,
      "mns": 1
    },
    {
      "a": "16053000",
      "j": 2023,
      "n": "jena",
      "dn": "Jena",
      "bl": 16,
      "hs": 495.0,
      "mns": 4
    },
    {
      "a": "16054000",
      "j": 2023,
      "n": "suhl",
      "dn": "Suhl",
      "bl": 16,
      "hs": 460.0,
      "mns": 2
    },
    {
      "a": "16055000",
      "j": 2023,
      "n": "weimar",
      "dn": "Weimar",
      "bl": 16,
      "hs": 480.0,
      "mns": 3
    },
    {
      "a": "16061001",
      "j": 2023,
      "n": "arenshausen",
      "dn": "Arenshausen",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16061002",
      "j": 2023,
      "n": "asbach-sickenberg",
      "dn": "Asbach-Sickenberg",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16061003",
      "j": 2023,
      "n": "berlingerode",
      "dn": "Berlingerode",
      "bl": 16,
      "hs": 404.0,
      "mns": 1
    },
    {
      "a": "16061007",
      "j": 2023,
      "n": "birkenfelde",
      "dn": "Birkenfelde",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16061012",
      "j": 2023,
      "n": "bodenrode-westhausen",
      "dn": "Bodenrode-Westhausen",
      "bl": 16,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "16061014",
      "j": 2023,
      "n": "bornhagen",
      "dn": "Bornhagen",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16061015",
      "j": 2023,
      "n": "brehme",
      "dn": "Brehme",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16061017",
      "j": 2023,
      "n": "breitenworbis",
      "dn": "Breitenworbis",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16061018",
      "j": 2023,
      "n": "büttstedt",
      "dn": "Büttstedt",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16061019",
      "j": 2023,
      "n": "buhla",
      "dn": "Buhla",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16061021",
      "j": 2023,
      "n": "burgwalde",
      "dn": "Burgwalde",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16061023",
      "j": 2023,
      "n": "dieterode",
      "dn": "Dieterode",
      "bl": 16,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "16061024",
      "j": 2023,
      "n": "dietzenrode/vatterode",
      "dn": "Dietzenrode/Vatterode",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16061026",
      "j": 2023,
      "n": "ecklingerode",
      "dn": "Ecklingerode",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16061027",
      "j": 2023,
      "n": "effelder",
      "dn": "Effelder",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16061028",
      "j": 2023,
      "n": "eichstruth",
      "dn": "Eichstruth",
      "bl": 16,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "16061031",
      "j": 2023,
      "n": "ferna",
      "dn": "Ferna",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16061032",
      "j": 2023,
      "n": "freienhagen",
      "dn": "Freienhagen",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16061033",
      "j": 2023,
      "n": "fretterode",
      "dn": "Fretterode",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16061034",
      "j": 2023,
      "n": "geisleden",
      "dn": "Geisleden",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16061035",
      "j": 2023,
      "n": "geismar",
      "dn": "Geismar",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16061036",
      "j": 2023,
      "n": "gerbershausen",
      "dn": "Gerbershausen",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16061037",
      "j": 2023,
      "n": "gernrode",
      "dn": "Gernrode",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16061039",
      "j": 2023,
      "n": "glasehausen",
      "dn": "Glasehausen",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16061041",
      "j": 2023,
      "n": "großbartloff",
      "dn": "Großbartloff",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16061044",
      "j": 2023,
      "n": "haynrode",
      "dn": "Haynrode",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16061045",
      "j": 2023,
      "n": "heilbad heiligenstadt",
      "dn": "Heilbad Heiligenstadt",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16061047",
      "j": 2023,
      "n": "heuthen",
      "dn": "Heuthen",
      "bl": 16,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "16061048",
      "j": 2023,
      "n": "hohengandern",
      "dn": "Hohengandern",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16061049",
      "j": 2023,
      "n": "hohes kreuz",
      "dn": "Hohes Kreuz",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16061056",
      "j": 2023,
      "n": "kella",
      "dn": "Kella",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16061057",
      "j": 2023,
      "n": "kirchgandern",
      "dn": "Kirchgandern",
      "bl": 16,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "16061058",
      "j": 2023,
      "n": "kirchworbis",
      "dn": "Kirchworbis",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16061062",
      "j": 2023,
      "n": "krombach",
      "dn": "Krombach",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16061063",
      "j": 2023,
      "n": "küllstedt",
      "dn": "Küllstedt",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16061065",
      "j": 2023,
      "n": "lenterode",
      "dn": "Lenterode",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16061066",
      "j": 2023,
      "n": "lindewerra",
      "dn": "Lindewerra",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16061067",
      "j": 2023,
      "n": "lutter",
      "dn": "Lutter",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16061068",
      "j": 2023,
      "n": "mackenrode",
      "dn": "Mackenrode",
      "bl": 16,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "16061069",
      "j": 2023,
      "n": "marth",
      "dn": "Marth",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16061074",
      "j": 2023,
      "n": "niederorschel",
      "dn": "Niederorschel",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16061075",
      "j": 2023,
      "n": "pfaffschwende",
      "dn": "Pfaffschwende",
      "bl": 16,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "16061076",
      "j": 2023,
      "n": "reinholterode",
      "dn": "Reinholterode",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16061077",
      "j": 2023,
      "n": "röhrig",
      "dn": "Röhrig",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16061078",
      "j": 2023,
      "n": "rohrberg",
      "dn": "Rohrberg",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16061082",
      "j": 2023,
      "n": "rustenfelde",
      "dn": "Rustenfelde",
      "bl": 16,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "16061083",
      "j": 2023,
      "n": "schachtebich",
      "dn": "Schachtebich",
      "bl": 16,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "16061084",
      "j": 2023,
      "n": "schönhagen",
      "dn": "Schönhagen",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16061085",
      "j": 2023,
      "n": "schwobfeld",
      "dn": "Schwobfeld",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16061086",
      "j": 2023,
      "n": "sickerode",
      "dn": "Sickerode",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16061089",
      "j": 2023,
      "n": "steinbach",
      "dn": "Steinbach",
      "bl": 16,
      "hs": 379.0,
      "mns": 1
    },
    {
      "a": "16061091",
      "j": 2023,
      "n": "steinheuterode",
      "dn": "Steinheuterode",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16061094",
      "j": 2023,
      "n": "tastungen",
      "dn": "Tastungen",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16061096",
      "j": 2023,
      "n": "thalwenden",
      "dn": "Thalwenden",
      "bl": 16,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "16061097",
      "j": 2023,
      "n": "uder",
      "dn": "Uder",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16061098",
      "j": 2023,
      "n": "volkerode",
      "dn": "Volkerode",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16061101",
      "j": 2023,
      "n": "wachstedt",
      "dn": "Wachstedt",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16061102",
      "j": 2023,
      "n": "wahlhausen",
      "dn": "Wahlhausen",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16061103",
      "j": 2023,
      "n": "wehnde",
      "dn": "Wehnde",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16061105",
      "j": 2023,
      "n": "wiesenfeld",
      "dn": "Wiesenfeld",
      "bl": 16,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "16061107",
      "j": 2023,
      "n": "wingerode",
      "dn": "Wingerode",
      "bl": 16,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "16061111",
      "j": 2023,
      "n": "wüstheuterode",
      "dn": "Wüstheuterode",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16061113",
      "j": 2023,
      "n": "schimberg",
      "dn": "Schimberg",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16061114",
      "j": 2023,
      "n": "teistungen",
      "dn": "Teistungen",
      "bl": 16,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "16061115",
      "j": 2023,
      "n": "leinefelde-worbis",
      "dn": "Leinefelde-Worbis",
      "bl": 16,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "16061116",
      "j": 2023,
      "n": "am ohmberg",
      "dn": "Am Ohmberg",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16061117",
      "j": 2023,
      "n": "sonnenstein",
      "dn": "Sonnenstein",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16061118",
      "j": 2023,
      "n": "dingelstädt",
      "dn": "Dingelstädt",
      "bl": 16,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "16062005",
      "j": 2023,
      "n": "ellrich",
      "dn": "Ellrich",
      "bl": 16,
      "hs": 411.0,
      "mns": 1
    },
    {
      "a": "16062008",
      "j": 2023,
      "n": "görsbach",
      "dn": "Görsbach",
      "bl": 16,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "16062009",
      "j": 2023,
      "n": "großlohra",
      "dn": "Großlohra",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16062024",
      "j": 2023,
      "n": "kehmstedt",
      "dn": "Kehmstedt",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16062026",
      "j": 2023,
      "n": "kleinfurra",
      "dn": "Kleinfurra",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16062033",
      "j": 2023,
      "n": "lipprechterode",
      "dn": "Lipprechterode",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16062037",
      "j": 2023,
      "n": "niedergebra",
      "dn": "Niedergebra",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16062041",
      "j": 2023,
      "n": "nordhausen",
      "dn": "Nordhausen",
      "bl": 16,
      "hs": 460.0,
      "mns": 2
    },
    {
      "a": "16062049",
      "j": 2023,
      "n": "sollstedt",
      "dn": "Sollstedt",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16062054",
      "j": 2023,
      "n": "urbach",
      "dn": "Urbach",
      "bl": 16,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "16062062",
      "j": 2023,
      "n": "hohenstein",
      "dn": "Hohenstein",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16062063",
      "j": 2023,
      "n": "werther",
      "dn": "Werther",
      "bl": 16,
      "hs": 426.0,
      "mns": 1
    },
    {
      "a": "16062064",
      "j": 2023,
      "n": "heringen/helme",
      "dn": "Heringen/Helme",
      "bl": 16,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "16062065",
      "j": 2023,
      "n": "harztor",
      "dn": "Harztor",
      "bl": 16,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "16062066",
      "j": 2023,
      "n": "bleicherode",
      "dn": "Bleicherode",
      "bl": 16,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "16063003",
      "j": 2023,
      "n": "bad salzungen",
      "dn": "Bad Salzungen",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16063004",
      "j": 2023,
      "n": "barchfeld-immelborn",
      "dn": "Barchfeld-Immelborn",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16063006",
      "j": 2023,
      "n": "berka vor dem hainich",
      "dn": "Berka vor dem Hainich",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16063008",
      "j": 2023,
      "n": "bischofroda",
      "dn": "Bischofroda",
      "bl": 16,
      "hs": 391.0,
      "mns": 1
    },
    {
      "a": "16063011",
      "j": 2023,
      "n": "buttlar",
      "dn": "Buttlar",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16063015",
      "j": 2023,
      "n": "dermbach",
      "dn": "Dermbach",
      "bl": 16,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "16063023",
      "j": 2023,
      "n": "empfertshausen",
      "dn": "Empfertshausen",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16063028",
      "j": 2023,
      "n": "frankenroda",
      "dn": "Frankenroda",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16063032",
      "j": 2023,
      "n": "geisa",
      "dn": "Geisa",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16063033",
      "j": 2023,
      "n": "gerstengrund",
      "dn": "Gerstengrund",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16063037",
      "j": 2023,
      "n": "hallungen",
      "dn": "Hallungen",
      "bl": 16,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "16063046",
      "j": 2023,
      "n": "krauthausen",
      "dn": "Krauthausen",
      "bl": 16,
      "hs": 392.0,
      "mns": 1
    },
    {
      "a": "16063049",
      "j": 2023,
      "n": "lauterbach",
      "dn": "Lauterbach",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16063051",
      "j": 2023,
      "n": "leimbach",
      "dn": "Leimbach",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16063058",
      "j": 2023,
      "n": "nazza",
      "dn": "Nazza",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16063062",
      "j": 2023,
      "n": "oechsen",
      "dn": "Oechsen",
      "bl": 16,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "16063066",
      "j": 2023,
      "n": "ruhla",
      "dn": "Ruhla",
      "bl": 16,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "16063068",
      "j": 2023,
      "n": "schleid",
      "dn": "Schleid",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16063071",
      "j": 2023,
      "n": "seebach",
      "dn": "Seebach",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16063076",
      "j": 2023,
      "n": "treffurt",
      "dn": "Treffurt",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16063078",
      "j": 2023,
      "n": "unterbreizbach",
      "dn": "Unterbreizbach",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16063082",
      "j": 2023,
      "n": "vacha",
      "dn": "Vacha",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16063084",
      "j": 2023,
      "n": "weilar",
      "dn": "Weilar",
      "bl": 16,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "16063086",
      "j": 2023,
      "n": "wiesenthal",
      "dn": "Wiesenthal",
      "bl": 16,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "16063092",
      "j": 2025,
      "n": "wutha-farnroda",
      "dn": "Wutha-Farnroda",
      "bl": 16,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "16063097",
      "j": 2023,
      "n": "gerstungen",
      "dn": "Gerstungen",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16063098",
      "j": 2023,
      "n": "hörselberg-hainich",
      "dn": "Hörselberg-Hainich",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16063099",
      "j": 2023,
      "n": "bad liebenstein",
      "dn": "Bad Liebenstein",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16063101",
      "j": 2023,
      "n": "krayenberggemeinde",
      "dn": "Krayenberggemeinde",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16063103",
      "j": 2023,
      "n": "werra-suhl-tal",
      "dn": "Werra-Suhl-Tal",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16063104",
      "j": 2023,
      "n": "amt creuzburg",
      "dn": "Amt Creuzburg",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16063105",
      "j": 2023,
      "n": "eisenach",
      "dn": "Eisenach",
      "bl": 16,
      "hs": 472.0,
      "mns": 2
    },
    {
      "a": "16064003",
      "j": 2023,
      "n": "bad langensalza",
      "dn": "Bad Langensalza",
      "bl": 16,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "16064004",
      "j": 2023,
      "n": "bad tennstedt",
      "dn": "Bad Tennstedt",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16064005",
      "j": 2023,
      "n": "ballhausen",
      "dn": "Ballhausen",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16064007",
      "j": 2023,
      "n": "blankenburg",
      "dn": "Blankenburg",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16064009",
      "j": 2023,
      "n": "bruchstedt",
      "dn": "Bruchstedt",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16064014",
      "j": 2022,
      "n": "dünwald",
      "dn": "Dünwald",
      "bl": 16,
      "hs": 404.0,
      "mns": 1
    },
    {
      "a": "16064019",
      "j": 2023,
      "n": "großvargula",
      "dn": "Großvargula",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16064021",
      "j": 2023,
      "n": "haussömmern",
      "dn": "Haussömmern",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16064022",
      "j": 2023,
      "n": "herbsleben",
      "dn": "Herbsleben",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16064027",
      "j": 2023,
      "n": "hornsömmern",
      "dn": "Hornsömmern",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16064032",
      "j": 2023,
      "n": "kammerforst",
      "dn": "Kammerforst",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16064033",
      "j": 2023,
      "n": "kirchheilingen",
      "dn": "Kirchheilingen",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16064037",
      "j": 2023,
      "n": "körner",
      "dn": "Körner",
      "bl": 16,
      "hs": 404.0,
      "mns": 1
    },
    {
      "a": "16064038",
      "j": 2023,
      "n": "kutzleben",
      "dn": "Kutzleben",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16064043",
      "j": 2023,
      "n": "marolterode",
      "dn": "Marolterode",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16064045",
      "j": 2023,
      "n": "mittelsömmern",
      "dn": "Mittelsömmern",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16064046",
      "j": 2023,
      "n": "mühlhausen/thüringen",
      "dn": "Mühlhausen/Thüringen",
      "bl": 16,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "16064053",
      "j": 2023,
      "n": "oppershausen",
      "dn": "Oppershausen",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16064055",
      "j": 2023,
      "n": "rodeberg",
      "dn": "Rodeberg",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16064058",
      "j": 2023,
      "n": "schönstedt",
      "dn": "Schönstedt",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16064061",
      "j": 2023,
      "n": "sundhausen",
      "dn": "Sundhausen",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16064062",
      "j": 2023,
      "n": "tottleben",
      "dn": "Tottleben",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16064064",
      "j": 2023,
      "n": "urleben",
      "dn": "Urleben",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16064071",
      "j": 2023,
      "n": "unstruttal",
      "dn": "Unstruttal",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16064072",
      "j": 2022,
      "n": "menteroda",
      "dn": "Menteroda",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16064073",
      "j": 2022,
      "n": "anrode",
      "dn": "Anrode",
      "bl": 16,
      "hs": 436.0,
      "mns": 1
    },
    {
      "a": "16064074",
      "j": 2023,
      "n": "südeichsfeld",
      "dn": "Südeichsfeld",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16064075",
      "j": 2023,
      "n": "vogtei",
      "dn": "Vogtei",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16064076",
      "j": 2023,
      "n": "unstrut-hainich",
      "dn": "Unstrut-Hainich",
      "bl": 16,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "16064077",
      "j": 2023,
      "n": "nottertal-heilinger höhen",
      "dn": "Nottertal-Heilinger Höhen",
      "bl": 16,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "16065001",
      "j": 2023,
      "n": "abtsbessingen",
      "dn": "Abtsbessingen",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16065003",
      "j": 2023,
      "n": "bad frankenhausen/kyffhäuser",
      "dn": "Bad Frankenhausen/Kyffhäuser",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16065005",
      "j": 2023,
      "n": "bellstedt",
      "dn": "Bellstedt",
      "bl": 16,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "16065008",
      "j": 2023,
      "n": "borxleben",
      "dn": "Borxleben",
      "bl": 16,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "16065012",
      "j": 2023,
      "n": "clingen",
      "dn": "Clingen",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16065014",
      "j": 2023,
      "n": "ebeleben",
      "dn": "Ebeleben",
      "bl": 16,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "16065016",
      "j": 2023,
      "n": "etzleben",
      "dn": "Etzleben",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16065018",
      "j": 2023,
      "n": "freienbessingen",
      "dn": "Freienbessingen",
      "bl": 16,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "16065019",
      "j": 2023,
      "n": "gehofen",
      "dn": "Gehofen",
      "bl": 16,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "16065032",
      "j": 2023,
      "n": "helbedündorf",
      "dn": "Helbedündorf",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16065038",
      "j": 2023,
      "n": "holzsußra",
      "dn": "Holzsußra",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16065042",
      "j": 2023,
      "n": "kalbsrieth",
      "dn": "Kalbsrieth",
      "bl": 16,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "16065046",
      "j": 2023,
      "n": "mönchpfiffel-nikolausrieth",
      "dn": "Mönchpfiffel-Nikolausrieth",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16065048",
      "j": 2023,
      "n": "niederbösa",
      "dn": "Niederbösa",
      "bl": 16,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "16065051",
      "j": 2023,
      "n": "oberbösa",
      "dn": "Oberbösa",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16065052",
      "j": 2023,
      "n": "oberheldrungen",
      "dn": "Oberheldrungen",
      "bl": 16,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "16065056",
      "j": 2023,
      "n": "reinsdorf",
      "dn": "Reinsdorf",
      "bl": 16,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "16065058",
      "j": 2023,
      "n": "rockstedt",
      "dn": "Rockstedt",
      "bl": 16,
      "hs": 399.0,
      "mns": 1
    },
    {
      "a": "16065067",
      "j": 2023,
      "n": "sondershausen",
      "dn": "Sondershausen",
      "bl": 16,
      "hs": 402.0,
      "mns": 2
    },
    {
      "a": "16065074",
      "j": 2023,
      "n": "topfstedt",
      "dn": "Topfstedt",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16065075",
      "j": 2023,
      "n": "trebra",
      "dn": "Trebra",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16065077",
      "j": 2023,
      "n": "wasserthaleben",
      "dn": "Wasserthaleben",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16065079",
      "j": 2023,
      "n": "westgreußen",
      "dn": "Westgreußen",
      "bl": 16,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "16065085",
      "j": 2023,
      "n": "kyffhäuserland",
      "dn": "Kyffhäuserland",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16065086",
      "j": 2023,
      "n": "artern",
      "dn": "Artern",
      "bl": 16,
      "hs": 422.0,
      "mns": 1
    },
    {
      "a": "16065087",
      "j": 2023,
      "n": "roßleben-wiehe",
      "dn": "Roßleben-Wiehe",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16065088",
      "j": 2023,
      "n": "an der schmücke",
      "dn": "An der Schmücke",
      "bl": 16,
      "hs": 411.0,
      "mns": 1
    },
    {
      "a": "16065089",
      "j": 2023,
      "n": "greußen",
      "dn": "Greußen",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16066005",
      "j": 2023,
      "n": "belrieth",
      "dn": "Belrieth",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16066012",
      "j": 2023,
      "n": "birx",
      "dn": "Birx",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16066013",
      "j": 2023,
      "n": "breitungen/werra",
      "dn": "Breitungen/Werra",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16066015",
      "j": 2023,
      "n": "christes",
      "dn": "Christes",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16066016",
      "j": 2023,
      "n": "dillstädt",
      "dn": "Dillstädt",
      "bl": 16,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "16066017",
      "j": 2023,
      "n": "einhausen",
      "dn": "Einhausen",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16066018",
      "j": 2023,
      "n": "ellingshausen",
      "dn": "Ellingshausen",
      "bl": 16,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "16066019",
      "j": 2023,
      "n": "erbenhausen",
      "dn": "Erbenhausen",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16066022",
      "j": 2023,
      "n": "fambach",
      "dn": "Fambach",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16066023",
      "j": 2023,
      "n": "floh-seligenthal",
      "dn": "Floh-Seligenthal",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16066024",
      "j": 2023,
      "n": "frankenheim/rhön",
      "dn": "Frankenheim/Rhön",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16066025",
      "j": 2023,
      "n": "friedelshausen",
      "dn": "Friedelshausen",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16066038",
      "j": 2023,
      "n": "kühndorf",
      "dn": "Kühndorf",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16066039",
      "j": 2023,
      "n": "leutersdorf",
      "dn": "Leutersdorf",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16066041",
      "j": 2023,
      "n": "mehmels",
      "dn": "Mehmels",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16066042",
      "j": 2023,
      "n": "meiningen",
      "dn": "Meiningen",
      "bl": 16,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "16066045",
      "j": 2023,
      "n": "neubrunn",
      "dn": "Neubrunn",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16066047",
      "j": 2023,
      "n": "oberhof",
      "dn": "Oberhof",
      "bl": 16,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "16066049",
      "j": 2023,
      "n": "obermaßfeld-grimmenthal",
      "dn": "Obermaßfeld-Grimmenthal",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16066052",
      "j": 2023,
      "n": "oberweid",
      "dn": "Oberweid",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16066056",
      "j": 2023,
      "n": "rippershausen",
      "dn": "Rippershausen",
      "bl": 16,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "16066057",
      "j": 2023,
      "n": "ritschenhausen",
      "dn": "Ritschenhausen",
      "bl": 16,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "16066058",
      "j": 2023,
      "n": "rohr",
      "dn": "Rohr",
      "bl": 16,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "16066059",
      "j": 2023,
      "n": "rosa",
      "dn": "Rosa",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16066061",
      "j": 2023,
      "n": "roßdorf",
      "dn": "Roßdorf",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16066063",
      "j": 2023,
      "n": "schmalkalden",
      "dn": "Schmalkalden",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16066064",
      "j": 2023,
      "n": "schwallungen",
      "dn": "Schwallungen",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16066065",
      "j": 2023,
      "n": "schwarza",
      "dn": "Schwarza",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16066069",
      "j": 2023,
      "n": "steinbach-hallenberg",
      "dn": "Steinbach-Hallenberg",
      "bl": 16,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "16066073",
      "j": 2023,
      "n": "sülzfeld",
      "dn": "Sülzfeld",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16066074",
      "j": 2023,
      "n": "brotterode-trusetal",
      "dn": "Brotterode-Trusetal",
      "bl": 16,
      "hs": 421.0,
      "mns": 1
    },
    {
      "a": "16066076",
      "j": 2023,
      "n": "untermaßfeld",
      "dn": "Untermaßfeld",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16066079",
      "j": 2023,
      "n": "utendorf",
      "dn": "Utendorf",
      "bl": 16,
      "hs": 392.0,
      "mns": 1
    },
    {
      "a": "16066081",
      "j": 2023,
      "n": "vachdorf",
      "dn": "Vachdorf",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16066086",
      "j": 2023,
      "n": "wasungen",
      "dn": "Wasungen",
      "bl": 16,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "16066092",
      "j": 2025,
      "n": "zella-mehlis",
      "dn": "Zella-Mehlis",
      "bl": 16,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "16066093",
      "j": 2025,
      "n": "rhönblick",
      "dn": "Rhönblick",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16066094",
      "j": 2023,
      "n": "grabfeld",
      "dn": "Grabfeld",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16066095",
      "j": 2023,
      "n": "kaltennordheim",
      "dn": "Kaltennordheim",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16067004",
      "j": 2023,
      "n": "bienstädt",
      "dn": "Bienstädt",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16067009",
      "j": 2023,
      "n": "dachwig",
      "dn": "Dachwig",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16067011",
      "j": 2023,
      "n": "döllstädt",
      "dn": "Döllstädt",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16067013",
      "j": 2023,
      "n": "emleben",
      "dn": "Emleben",
      "bl": 16,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "16067016",
      "j": 2023,
      "n": "eschenbergen",
      "dn": "Eschenbergen",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16067019",
      "j": 2023,
      "n": "friedrichroda",
      "dn": "Friedrichroda",
      "bl": 16,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "16067022",
      "j": 2023,
      "n": "friemar",
      "dn": "Friemar",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16067026",
      "j": 2023,
      "n": "gierstädt",
      "dn": "Gierstädt",
      "bl": 16,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "16067029",
      "j": 2023,
      "n": "gotha",
      "dn": "Gotha",
      "bl": 16,
      "hs": 470.0,
      "mns": 2
    },
    {
      "a": "16067033",
      "j": 2023,
      "n": "großfahner",
      "dn": "Großfahner",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16067036",
      "j": 2023,
      "n": "herrenhof",
      "dn": "Herrenhof",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16067044",
      "j": 2023,
      "n": "luisenthal",
      "dn": "Luisenthal",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16067047",
      "j": 2023,
      "n": "molschleben",
      "dn": "Molschleben",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16067052",
      "j": 2023,
      "n": "nottleben",
      "dn": "Nottleben",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16067053",
      "j": 2023,
      "n": "ohrdruf",
      "dn": "Ohrdruf",
      "bl": 16,
      "hs": 435.0,
      "mns": 1
    },
    {
      "a": "16067055",
      "j": 2023,
      "n": "pferdingsleben",
      "dn": "Pferdingsleben",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16067059",
      "j": 2023,
      "n": "schwabhausen",
      "dn": "Schwabhausen",
      "bl": 16,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "16067063",
      "j": 2023,
      "n": "sonneborn",
      "dn": "Sonneborn",
      "bl": 16,
      "hs": 404.0,
      "mns": 1
    },
    {
      "a": "16067064",
      "j": 2023,
      "n": "bad tabarz",
      "dn": "Bad Tabarz",
      "bl": 16,
      "hs": 470.0,
      "mns": 1
    },
    {
      "a": "16067065",
      "j": 2023,
      "n": "tambach-dietharz",
      "dn": "Tambach-Dietharz",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16067067",
      "j": 2023,
      "n": "tonna",
      "dn": "Tonna",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16067068",
      "j": 2023,
      "n": "tröchtelborn",
      "dn": "Tröchtelborn",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16067071",
      "j": 2023,
      "n": "tüttleben",
      "dn": "Tüttleben",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16067072",
      "j": 2023,
      "n": "waltershausen",
      "dn": "Waltershausen",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16067082",
      "j": 2023,
      "n": "zimmernsupra",
      "dn": "Zimmernsupra",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16067087",
      "j": 2023,
      "n": "nesse-apfelstädt",
      "dn": "Nesse-Apfelstädt",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16067088",
      "j": 2023,
      "n": "hörsel",
      "dn": "Hörsel",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16067089",
      "j": 2023,
      "n": "drei gleichen",
      "dn": "Drei Gleichen",
      "bl": 16,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "16067091",
      "j": 2023,
      "n": "nessetal",
      "dn": "Nessetal",
      "bl": 16,
      "hs": 397.0,
      "mns": 1
    },
    {
      "a": "16067092",
      "j": 2023,
      "n": "georgenthal",
      "dn": "Georgenthal",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16068001",
      "j": 2023,
      "n": "alperstedt",
      "dn": "Alperstedt",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16068002",
      "j": 2023,
      "n": "andisleben",
      "dn": "Andisleben",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16068005",
      "j": 2023,
      "n": "büchel",
      "dn": "Büchel",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16068007",
      "j": 2023,
      "n": "eckstedt",
      "dn": "Eckstedt",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16068009",
      "j": 2023,
      "n": "elxleben",
      "dn": "Elxleben",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16068013",
      "j": 2023,
      "n": "gangloffsömmern",
      "dn": "Gangloffsömmern",
      "bl": 16,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "16068014",
      "j": 2023,
      "n": "gebesee",
      "dn": "Gebesee",
      "bl": 16,
      "hs": 404.0,
      "mns": 1
    },
    {
      "a": "16068015",
      "j": 2023,
      "n": "griefstedt",
      "dn": "Griefstedt",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16068017",
      "j": 2023,
      "n": "großmölsen",
      "dn": "Großmölsen",
      "bl": 16,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "16068019",
      "j": 2023,
      "n": "großneuhausen",
      "dn": "Großneuhausen",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16068021",
      "j": 2023,
      "n": "großrudestedt",
      "dn": "Großrudestedt",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16068022",
      "j": 2023,
      "n": "günstedt",
      "dn": "Günstedt",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16068025",
      "j": 2023,
      "n": "haßleben",
      "dn": "Haßleben",
      "bl": 16,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "16068032",
      "j": 2023,
      "n": "kleinmölsen",
      "dn": "Kleinmölsen",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16068033",
      "j": 2023,
      "n": "kleinneuhausen",
      "dn": "Kleinneuhausen",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16068034",
      "j": 2023,
      "n": "kölleda",
      "dn": "Kölleda",
      "bl": 16,
      "hs": 392.0,
      "mns": 1
    },
    {
      "a": "16068036",
      "j": 2023,
      "n": "markvippach",
      "dn": "Markvippach",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16068037",
      "j": 2023,
      "n": "nöda",
      "dn": "Nöda",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16068039",
      "j": 2023,
      "n": "ollendorf",
      "dn": "Ollendorf",
      "bl": 16,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "16068041",
      "j": 2023,
      "n": "ostramondra",
      "dn": "Ostramondra",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16068042",
      "j": 2023,
      "n": "rastenberg",
      "dn": "Rastenberg",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16068043",
      "j": 2022,
      "n": "riethgen",
      "dn": "Riethgen",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16068044",
      "j": 2023,
      "n": "riethnordhausen",
      "dn": "Riethnordhausen",
      "bl": 16,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "16068045",
      "j": 2023,
      "n": "ringleben",
      "dn": "Ringleben",
      "bl": 16,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "16068048",
      "j": 2023,
      "n": "schloßvippach",
      "dn": "Schloßvippach",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16068049",
      "j": 2023,
      "n": "schwerstedt",
      "dn": "Schwerstedt",
      "bl": 16,
      "hs": 398.0,
      "mns": 1
    },
    {
      "a": "16068051",
      "j": 2023,
      "n": "sömmerda",
      "dn": "Sömmerda",
      "bl": 16,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "16068052",
      "j": 2023,
      "n": "sprötau",
      "dn": "Sprötau",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16068053",
      "j": 2023,
      "n": "straußfurt",
      "dn": "Straußfurt",
      "bl": 16,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "16068055",
      "j": 2023,
      "n": "udestedt",
      "dn": "Udestedt",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16068056",
      "j": 2023,
      "n": "vogelsberg",
      "dn": "Vogelsberg",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16068057",
      "j": 2023,
      "n": "walschleben",
      "dn": "Walschleben",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16068058",
      "j": 2023,
      "n": "weißensee",
      "dn": "Weißensee",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16068059",
      "j": 2023,
      "n": "werningshausen",
      "dn": "Werningshausen",
      "bl": 16,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "16068061",
      "j": 2023,
      "n": "witterda",
      "dn": "Witterda",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16068062",
      "j": 2023,
      "n": "wundersleben",
      "dn": "Wundersleben",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16068063",
      "j": 2023,
      "n": "buttstädt",
      "dn": "Buttstädt",
      "bl": 16,
      "hs": 391.0,
      "mns": 1
    },
    {
      "a": "16068064",
      "j": 2023,
      "n": "kindelbrück",
      "dn": "Kindelbrück",
      "bl": 16,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "16069001",
      "j": 2023,
      "n": "ahlstädt",
      "dn": "Ahlstädt",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16069003",
      "j": 2023,
      "n": "beinerstadt",
      "dn": "Beinerstadt",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16069004",
      "j": 2023,
      "n": "bischofrod",
      "dn": "Bischofrod",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16069006",
      "j": 2023,
      "n": "brünn/thür.",
      "dn": "Brünn/Thür.",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16069008",
      "j": 2023,
      "n": "dingsleben",
      "dn": "Dingsleben",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16069009",
      "j": 2023,
      "n": "ehrenberg",
      "dn": "Ehrenberg",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16069011",
      "j": 2023,
      "n": "eichenberg",
      "dn": "Eichenberg",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16069012",
      "j": 2023,
      "n": "eisfeld",
      "dn": "Eisfeld",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16069016",
      "j": 2023,
      "n": "grimmelshausen",
      "dn": "Grimmelshausen",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16069017",
      "j": 2023,
      "n": "grub",
      "dn": "Grub",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16069021",
      "j": 2023,
      "n": "henfstädt",
      "dn": "Henfstädt",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16069024",
      "j": 2023,
      "n": "hildburghausen",
      "dn": "Hildburghausen",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16069025",
      "j": 2023,
      "n": "kloster veßra",
      "dn": "Kloster Veßra",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16069026",
      "j": 2023,
      "n": "lengfeld",
      "dn": "Lengfeld",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16069028",
      "j": 2023,
      "n": "marisfeld",
      "dn": "Marisfeld",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16069035",
      "j": 2023,
      "n": "oberstadt",
      "dn": "Oberstadt",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16069037",
      "j": 2023,
      "n": "reurieth",
      "dn": "Reurieth",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16069041",
      "j": 2023,
      "n": "schlechtsart",
      "dn": "Schlechtsart",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16069042",
      "j": 2023,
      "n": "schleusegrund",
      "dn": "Schleusegrund",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16069043",
      "j": 2023,
      "n": "schleusingen",
      "dn": "Schleusingen",
      "bl": 16,
      "hs": 370.0,
      "mns": 1
    },
    {
      "a": "16069044",
      "j": 2023,
      "n": "schmeheim",
      "dn": "Schmeheim",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16069046",
      "j": 2023,
      "n": "schweickershausen",
      "dn": "Schweickershausen",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16069047",
      "j": 2023,
      "n": "st. bernhard",
      "dn": "St. Bernhard",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16069049",
      "j": 2023,
      "n": "straufhain",
      "dn": "Straufhain",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16069051",
      "j": 2023,
      "n": "themar",
      "dn": "Themar",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16069052",
      "j": 2023,
      "n": "ummerstadt",
      "dn": "Ummerstadt",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16069053",
      "j": 2023,
      "n": "veilsdorf",
      "dn": "Veilsdorf",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16069056",
      "j": 2023,
      "n": "westhausen",
      "dn": "Westhausen",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16069058",
      "j": 2023,
      "n": "auengrund",
      "dn": "Auengrund",
      "bl": 16,
      "hs": 404.0,
      "mns": 1
    },
    {
      "a": "16069061",
      "j": 2023,
      "n": "masserberg",
      "dn": "Masserberg",
      "bl": 16,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "16069062",
      "j": 2023,
      "n": "römhild",
      "dn": "Römhild",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16069063",
      "j": 2023,
      "n": "heldburg",
      "dn": "Heldburg",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16070001",
      "j": 2023,
      "n": "alkersleben",
      "dn": "Alkersleben",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16070004",
      "j": 2023,
      "n": "arnstadt",
      "dn": "Arnstadt",
      "bl": 16,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "16070006",
      "j": 2023,
      "n": "bösleben-wüllersleben",
      "dn": "Bösleben-Wüllersleben",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16070008",
      "j": 2023,
      "n": "dornheim",
      "dn": "Dornheim",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16070011",
      "j": 2023,
      "n": "elgersburg",
      "dn": "Elgersburg",
      "bl": 16,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "16070012",
      "j": 2023,
      "n": "elleben",
      "dn": "Elleben",
      "bl": 16,
      "hs": 500.0,
      "mns": 1
    },
    {
      "a": "16070013",
      "j": 2023,
      "n": "elxleben",
      "dn": "Elxleben",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16070028",
      "j": 2023,
      "n": "amt wachsenburg",
      "dn": "Amt Wachsenburg",
      "bl": 16,
      "hs": 375.0,
      "mns": 1
    },
    {
      "a": "16070029",
      "j": 2023,
      "n": "ilmenau",
      "dn": "Ilmenau",
      "bl": 16,
      "hs": 440.0,
      "mns": 2
    },
    {
      "a": "16070034",
      "j": 2023,
      "n": "martinroda",
      "dn": "Martinroda",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16070041",
      "j": 2023,
      "n": "osthausen-wülfershausen",
      "dn": "Osthausen-Wülfershausen",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16070043",
      "j": 2023,
      "n": "plaue",
      "dn": "Plaue",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16070048",
      "j": 2023,
      "n": "stadtilm",
      "dn": "Stadtilm",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16070054",
      "j": 2023,
      "n": "witzleben",
      "dn": "Witzleben",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16070057",
      "j": 2023,
      "n": "geratal",
      "dn": "Geratal",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16070058",
      "j": 2023,
      "n": "großbreitenbach",
      "dn": "Großbreitenbach",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16071001",
      "j": 2023,
      "n": "apolda",
      "dn": "Apolda",
      "bl": 16,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "16071003",
      "j": 2023,
      "n": "bad berka",
      "dn": "Bad Berka",
      "bl": 16,
      "hs": 391.0,
      "mns": 1
    },
    {
      "a": "16071004",
      "j": 2023,
      "n": "bad sulza",
      "dn": "Bad Sulza",
      "bl": 16,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "16071005",
      "j": 2023,
      "n": "ballstedt",
      "dn": "Ballstedt",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16071008",
      "j": 2023,
      "n": "blankenhain",
      "dn": "Blankenhain",
      "bl": 16,
      "hs": 421.0,
      "mns": 1
    },
    {
      "a": "16071009",
      "j": 2023,
      "n": "buchfart",
      "dn": "Buchfart",
      "bl": 16,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "16071013",
      "j": 2023,
      "n": "döbritschen",
      "dn": "Döbritschen",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16071015",
      "j": 2023,
      "n": "eberstedt",
      "dn": "Eberstedt",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16071017",
      "j": 2023,
      "n": "ettersburg",
      "dn": "Ettersburg",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16071019",
      "j": 2023,
      "n": "frankendorf",
      "dn": "Frankendorf",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16071022",
      "j": 2023,
      "n": "großheringen",
      "dn": "Großheringen",
      "bl": 16,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "16071025",
      "j": 2023,
      "n": "großschwabhausen",
      "dn": "Großschwabhausen",
      "bl": 16,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "16071027",
      "j": 2023,
      "n": "hammerstedt",
      "dn": "Hammerstedt",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16071031",
      "j": 2023,
      "n": "hetschburg",
      "dn": "Hetschburg",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16071032",
      "j": 2023,
      "n": "hohenfelden",
      "dn": "Hohenfelden",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16071037",
      "j": 2023,
      "n": "kapellendorf",
      "dn": "Kapellendorf",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16071038",
      "j": 2023,
      "n": "kiliansroda",
      "dn": "Kiliansroda",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16071042",
      "j": 2023,
      "n": "kleinschwabhausen",
      "dn": "Kleinschwabhausen",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16071043",
      "j": 2023,
      "n": "klettbach",
      "dn": "Klettbach",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16071046",
      "j": 2023,
      "n": "kranichfeld",
      "dn": "Kranichfeld",
      "bl": 16,
      "hs": 412.0,
      "mns": 1
    },
    {
      "a": "16071049",
      "j": 2023,
      "n": "lehnstedt",
      "dn": "Lehnstedt",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16071053",
      "j": 2023,
      "n": "magdala",
      "dn": "Magdala",
      "bl": 16,
      "hs": 404.0,
      "mns": 1
    },
    {
      "a": "16071055",
      "j": 2023,
      "n": "mechelroda",
      "dn": "Mechelroda",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16071056",
      "j": 2023,
      "n": "mellingen",
      "dn": "Mellingen",
      "bl": 16,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "16071059",
      "j": 2023,
      "n": "nauendorf",
      "dn": "Nauendorf",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16071061",
      "j": 2023,
      "n": "neumark",
      "dn": "Neumark",
      "bl": 16,
      "hs": 411.0,
      "mns": 1
    },
    {
      "a": "16071064",
      "j": 2023,
      "n": "niedertrebra",
      "dn": "Niedertrebra",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16071069",
      "j": 2023,
      "n": "obertrebra",
      "dn": "Obertrebra",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16071071",
      "j": 2023,
      "n": "oettern",
      "dn": "Oettern",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16071077",
      "j": 2022,
      "n": "rannstedt",
      "dn": "Rannstedt",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16071079",
      "j": 2023,
      "n": "rittersdorf",
      "dn": "Rittersdorf",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16071083",
      "j": 2023,
      "n": "schmiedehausen",
      "dn": "Schmiedehausen",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16071087",
      "j": 2023,
      "n": "tonndorf",
      "dn": "Tonndorf",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16071089",
      "j": 2023,
      "n": "umpferstedt",
      "dn": "Umpferstedt",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16071093",
      "j": 2023,
      "n": "vollersroda",
      "dn": "Vollersroda",
      "bl": 16,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "16071095",
      "j": 2023,
      "n": "wiegendorf",
      "dn": "Wiegendorf",
      "bl": 16,
      "hs": 350.0,
      "mns": 1
    },
    {
      "a": "16071101",
      "j": 2023,
      "n": "ilmtal-weinstraße",
      "dn": "Ilmtal-Weinstraße",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16071102",
      "j": 2023,
      "n": "am ettersberg",
      "dn": "Am Ettersberg",
      "bl": 16,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "16071103",
      "j": 2023,
      "n": "grammetal",
      "dn": "Grammetal",
      "bl": 16,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "16072006",
      "j": 2023,
      "n": "goldisthal",
      "dn": "Goldisthal",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16072011",
      "j": 2023,
      "n": "lauscha",
      "dn": "Lauscha",
      "bl": 16,
      "hs": 426.0,
      "mns": 1
    },
    {
      "a": "16072013",
      "j": 2023,
      "n": "neuhaus am rennweg",
      "dn": "Neuhaus am Rennweg",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16072015",
      "j": 2023,
      "n": "schalkau",
      "dn": "Schalkau",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16072018",
      "j": 2023,
      "n": "sonneberg",
      "dn": "Sonneberg",
      "bl": 16,
      "hs": 445.0,
      "mns": 1
    },
    {
      "a": "16072019",
      "j": 2023,
      "n": "steinach",
      "dn": "Steinach",
      "bl": 16,
      "hs": 412.0,
      "mns": 1
    },
    {
      "a": "16072023",
      "j": 2023,
      "n": "frankenblick",
      "dn": "Frankenblick",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16072024",
      "j": 2023,
      "n": "föritztal",
      "dn": "Föritztal",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16073001",
      "j": 2023,
      "n": "allendorf",
      "dn": "Allendorf",
      "bl": 16,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "16073002",
      "j": 2023,
      "n": "altenbeuthen",
      "dn": "Altenbeuthen",
      "bl": 16,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "16073005",
      "j": 2023,
      "n": "bad blankenburg",
      "dn": "Bad Blankenburg",
      "bl": 16,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "16073006",
      "j": 2023,
      "n": "bechstedt",
      "dn": "Bechstedt",
      "bl": 16,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "16073013",
      "j": 2023,
      "n": "cursdorf",
      "dn": "Cursdorf",
      "bl": 16,
      "hs": 404.0,
      "mns": 2
    },
    {
      "a": "16073014",
      "j": 2023,
      "n": "deesbach",
      "dn": "Deesbach",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16073017",
      "j": 2023,
      "n": "döschnitz",
      "dn": "Döschnitz",
      "bl": 16,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "16073028",
      "j": 2023,
      "n": "gräfenthal",
      "dn": "Gräfenthal",
      "bl": 16,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "16073035",
      "j": 2023,
      "n": "hohenwarte",
      "dn": "Hohenwarte",
      "bl": 16,
      "hs": 250.0,
      "mns": 2
    },
    {
      "a": "16073037",
      "j": 2023,
      "n": "katzhütte",
      "dn": "Katzhütte",
      "bl": 16,
      "hs": 404.0,
      "mns": 2
    },
    {
      "a": "16073038",
      "j": 2023,
      "n": "kaulsdorf",
      "dn": "Kaulsdorf",
      "bl": 16,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "16073046",
      "j": 2023,
      "n": "lehesten",
      "dn": "Lehesten",
      "bl": 16,
      "hs": 420.0,
      "mns": 2
    },
    {
      "a": "16073055",
      "j": 2023,
      "n": "meura",
      "dn": "Meura",
      "bl": 16,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "16073067",
      "j": 2023,
      "n": "probstzella",
      "dn": "Probstzella",
      "bl": 16,
      "hs": 404.0,
      "mns": 2
    },
    {
      "a": "16073074",
      "j": 2023,
      "n": "rohrbach",
      "dn": "Rohrbach",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16073076",
      "j": 2023,
      "n": "rudolstadt",
      "dn": "Rudolstadt",
      "bl": 16,
      "hs": 402.0,
      "mns": 2
    },
    {
      "a": "16073077",
      "j": 2023,
      "n": "saalfeld/saale",
      "dn": "Saalfeld/Saale",
      "bl": 16,
      "hs": 402.0,
      "mns": 2
    },
    {
      "a": "16073082",
      "j": 2023,
      "n": "schwarzburg",
      "dn": "Schwarzburg",
      "bl": 16,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "16073084",
      "j": 2023,
      "n": "sitzendorf",
      "dn": "Sitzendorf",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16073094",
      "j": 2023,
      "n": "unterweißbach",
      "dn": "Unterweißbach",
      "bl": 16,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "16073106",
      "j": 2023,
      "n": "leutenberg",
      "dn": "Leutenberg",
      "bl": 16,
      "hs": 428.0,
      "mns": 2
    },
    {
      "a": "16073107",
      "j": 2023,
      "n": "drognitz",
      "dn": "Drognitz",
      "bl": 16,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "16073109",
      "j": 2023,
      "n": "uhlstädt-kirchhasel",
      "dn": "Uhlstädt-Kirchhasel",
      "bl": 16,
      "hs": 415.0,
      "mns": 2
    },
    {
      "a": "16073111",
      "j": 2023,
      "n": "unterwellenborn",
      "dn": "Unterwellenborn",
      "bl": 16,
      "hs": 340.0,
      "mns": 2
    },
    {
      "a": "16073112",
      "j": 2023,
      "n": "königsee",
      "dn": "Königsee",
      "bl": 16,
      "hs": 402.0,
      "mns": 2
    },
    {
      "a": "16073113",
      "j": 2023,
      "n": "schwarzatal",
      "dn": "Schwarzatal",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074001",
      "j": 2023,
      "n": "albersdorf",
      "dn": "Albersdorf",
      "bl": 16,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "16074002",
      "j": 2023,
      "n": "altenberga",
      "dn": "Altenberga",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074003",
      "j": 2023,
      "n": "bad klosterlausnitz",
      "dn": "Bad Klosterlausnitz",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074004",
      "j": 2023,
      "n": "bibra",
      "dn": "Bibra",
      "bl": 16,
      "hs": 402.0,
      "mns": 2
    },
    {
      "a": "16074005",
      "j": 2023,
      "n": "bobeck",
      "dn": "Bobeck",
      "bl": 16,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "16074007",
      "j": 2023,
      "n": "bremsnitz",
      "dn": "Bremsnitz",
      "bl": 16,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "16074008",
      "j": 2023,
      "n": "bucha",
      "dn": "Bucha",
      "bl": 16,
      "hs": 404.0,
      "mns": 2
    },
    {
      "a": "16074009",
      "j": 2023,
      "n": "bürgel",
      "dn": "Bürgel",
      "bl": 16,
      "hs": 426.0,
      "mns": 2
    },
    {
      "a": "16074011",
      "j": 2023,
      "n": "dornburg-camburg",
      "dn": "Dornburg-Camburg",
      "bl": 16,
      "hs": 402.0,
      "mns": 2
    },
    {
      "a": "16074012",
      "j": 2023,
      "n": "crossen an der elster",
      "dn": "Crossen an der Elster",
      "bl": 16,
      "hs": 404.0,
      "mns": 2
    },
    {
      "a": "16074016",
      "j": 2023,
      "n": "eichenberg",
      "dn": "Eichenberg",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074017",
      "j": 2023,
      "n": "eineborn",
      "dn": "Eineborn",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074018",
      "j": 2023,
      "n": "eisenberg",
      "dn": "Eisenberg",
      "bl": 16,
      "hs": 407.0,
      "mns": 1
    },
    {
      "a": "16074019",
      "j": 2023,
      "n": "frauenprießnitz",
      "dn": "Frauenprießnitz",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074021",
      "j": 2023,
      "n": "freienorla",
      "dn": "Freienorla",
      "bl": 16,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "16074022",
      "j": 2023,
      "n": "geisenhain",
      "dn": "Geisenhain",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074024",
      "j": 2023,
      "n": "gneus",
      "dn": "Gneus",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074025",
      "j": 2023,
      "n": "gösen",
      "dn": "Gösen",
      "bl": 16,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "16074026",
      "j": 2023,
      "n": "golmsdorf",
      "dn": "Golmsdorf",
      "bl": 16,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "16074028",
      "j": 2023,
      "n": "graitschen bei bürgel",
      "dn": "Graitschen bei Bürgel",
      "bl": 16,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "16074029",
      "j": 2023,
      "n": "großbockedra",
      "dn": "Großbockedra",
      "bl": 16,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "16074031",
      "j": 2023,
      "n": "großeutersdorf",
      "dn": "Großeutersdorf",
      "bl": 16,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "16074032",
      "j": 2023,
      "n": "großlöbichau",
      "dn": "Großlöbichau",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074033",
      "j": 2023,
      "n": "großpürschütz",
      "dn": "Großpürschütz",
      "bl": 16,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "16074034",
      "j": 2023,
      "n": "gumperda",
      "dn": "Gumperda",
      "bl": 16,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "16074036",
      "j": 2023,
      "n": "hainichen",
      "dn": "Hainichen",
      "bl": 16,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "16074037",
      "j": 2023,
      "n": "hainspitz",
      "dn": "Hainspitz",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074038",
      "j": 2023,
      "n": "hartmannsdorf",
      "dn": "Hartmannsdorf",
      "bl": 16,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "16074039",
      "j": 2023,
      "n": "heideland",
      "dn": "Heideland",
      "bl": 16,
      "hs": 415.0,
      "mns": 2
    },
    {
      "a": "16074041",
      "j": 2023,
      "n": "hermsdorf",
      "dn": "Hermsdorf",
      "bl": 16,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "16074042",
      "j": 2023,
      "n": "hummelshain",
      "dn": "Hummelshain",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074043",
      "j": 2023,
      "n": "jenalöbnitz",
      "dn": "Jenalöbnitz",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074044",
      "j": 2023,
      "n": "kahla",
      "dn": "Kahla",
      "bl": 16,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "16074045",
      "j": 2023,
      "n": "karlsdorf",
      "dn": "Karlsdorf",
      "bl": 16,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "16074046",
      "j": 2023,
      "n": "kleinbockedra",
      "dn": "Kleinbockedra",
      "bl": 16,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "16074047",
      "j": 2023,
      "n": "kleinebersdorf",
      "dn": "Kleinebersdorf",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074048",
      "j": 2023,
      "n": "kleineutersdorf",
      "dn": "Kleineutersdorf",
      "bl": 16,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "16074049",
      "j": 2023,
      "n": "laasdorf",
      "dn": "Laasdorf",
      "bl": 16,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "16074051",
      "j": 2023,
      "n": "lehesten",
      "dn": "Lehesten",
      "bl": 16,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "16074052",
      "j": 2023,
      "n": "lindig",
      "dn": "Lindig",
      "bl": 16,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "16074053",
      "j": 2023,
      "n": "lippersdorf-erdmannsdorf",
      "dn": "Lippersdorf-Erdmannsdorf",
      "bl": 16,
      "hs": 422.0,
      "mns": 2
    },
    {
      "a": "16074054",
      "j": 2023,
      "n": "löberschütz",
      "dn": "Löberschütz",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074055",
      "j": 2023,
      "n": "mertendorf",
      "dn": "Mertendorf",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074056",
      "j": 2023,
      "n": "meusebach",
      "dn": "Meusebach",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074057",
      "j": 2023,
      "n": "milda",
      "dn": "Milda",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074058",
      "j": 2023,
      "n": "möckern",
      "dn": "Möckern",
      "bl": 16,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "16074059",
      "j": 2023,
      "n": "mörsdorf",
      "dn": "Mörsdorf",
      "bl": 16,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "16074061",
      "j": 2023,
      "n": "nausnitz",
      "dn": "Nausnitz",
      "bl": 16,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "16074063",
      "j": 2023,
      "n": "neuengönna",
      "dn": "Neuengönna",
      "bl": 16,
      "hs": 395.0,
      "mns": 2
    },
    {
      "a": "16074064",
      "j": 2023,
      "n": "oberbodnitz",
      "dn": "Oberbodnitz",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074065",
      "j": 2023,
      "n": "orlamünde",
      "dn": "Orlamünde",
      "bl": 16,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "16074066",
      "j": 2023,
      "n": "ottendorf",
      "dn": "Ottendorf",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074067",
      "j": 2023,
      "n": "petersberg",
      "dn": "Petersberg",
      "bl": 16,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "16074068",
      "j": 2023,
      "n": "poxdorf",
      "dn": "Poxdorf",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074071",
      "j": 2023,
      "n": "rattelsdorf",
      "dn": "Rattelsdorf",
      "bl": 16,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "16074072",
      "j": 2023,
      "n": "rauda",
      "dn": "Rauda",
      "bl": 16,
      "hs": 425.0,
      "mns": 2
    },
    {
      "a": "16074073",
      "j": 2023,
      "n": "rauschwitz",
      "dn": "Rauschwitz",
      "bl": 16,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "16074074",
      "j": 2023,
      "n": "rausdorf",
      "dn": "Rausdorf",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074075",
      "j": 2025,
      "n": "reichenbach",
      "dn": "Reichenbach",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074076",
      "j": 2023,
      "n": "reinstädt",
      "dn": "Reinstädt",
      "bl": 16,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "16074077",
      "j": 2023,
      "n": "renthendorf",
      "dn": "Renthendorf",
      "bl": 16,
      "hs": 422.0,
      "mns": 2
    },
    {
      "a": "16074079",
      "j": 2023,
      "n": "rothenstein",
      "dn": "Rothenstein",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074081",
      "j": 2023,
      "n": "ruttersdorf-lotschen",
      "dn": "Ruttersdorf-Lotschen",
      "bl": 16,
      "hs": 375.0,
      "mns": 2
    },
    {
      "a": "16074082",
      "j": 2023,
      "n": "scheiditz",
      "dn": "Scheiditz",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074084",
      "j": 2023,
      "n": "schleifreisen",
      "dn": "Schleifreisen",
      "bl": 16,
      "hs": 390.0,
      "mns": 2
    },
    {
      "a": "16074085",
      "j": 2023,
      "n": "schlöben",
      "dn": "Schlöben",
      "bl": 16,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "16074086",
      "j": 2023,
      "n": "schöngleina",
      "dn": "Schöngleina",
      "bl": 16,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "16074087",
      "j": 2023,
      "n": "schöps",
      "dn": "Schöps",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074089",
      "j": 2023,
      "n": "seitenroda",
      "dn": "Seitenroda",
      "bl": 16,
      "hs": 402.0,
      "mns": 2
    },
    {
      "a": "16074091",
      "j": 2023,
      "n": "serba",
      "dn": "Serba",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074092",
      "j": 2023,
      "n": "silbitz",
      "dn": "Silbitz",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074093",
      "j": 2023,
      "n": "st. gangloff",
      "dn": "St. Gangloff",
      "bl": 16,
      "hs": 404.0,
      "mns": 2
    },
    {
      "a": "16074094",
      "j": 2023,
      "n": "stadtroda",
      "dn": "Stadtroda",
      "bl": 16,
      "hs": 411.0,
      "mns": 2
    },
    {
      "a": "16074095",
      "j": 2023,
      "n": "sulza",
      "dn": "Sulza",
      "bl": 16,
      "hs": 300.0,
      "mns": 2
    },
    {
      "a": "16074096",
      "j": 2023,
      "n": "tautenburg",
      "dn": "Tautenburg",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074097",
      "j": 2023,
      "n": "tautendorf",
      "dn": "Tautendorf",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074098",
      "j": 2023,
      "n": "tautenhain",
      "dn": "Tautenhain",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074099",
      "j": 2023,
      "n": "thierschneck",
      "dn": "Thierschneck",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074101",
      "j": 2023,
      "n": "tissa",
      "dn": "Tissa",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074102",
      "j": 2023,
      "n": "trockenborn-wolfersdorf",
      "dn": "Trockenborn-Wolfersdorf",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074103",
      "j": 2023,
      "n": "tröbnitz",
      "dn": "Tröbnitz",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074104",
      "j": 2023,
      "n": "unterbodnitz",
      "dn": "Unterbodnitz",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074105",
      "j": 2023,
      "n": "waldeck",
      "dn": "Waldeck",
      "bl": 16,
      "hs": 422.0,
      "mns": 2
    },
    {
      "a": "16074106",
      "j": 2023,
      "n": "walpernhain",
      "dn": "Walpernhain",
      "bl": 16,
      "hs": 450.0,
      "mns": 2
    },
    {
      "a": "16074107",
      "j": 2023,
      "n": "waltersdorf",
      "dn": "Waltersdorf",
      "bl": 16,
      "hs": 320.0,
      "mns": 2
    },
    {
      "a": "16074108",
      "j": 2023,
      "n": "weißbach",
      "dn": "Weißbach",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074109",
      "j": 2023,
      "n": "weißenborn",
      "dn": "Weißenborn",
      "bl": 16,
      "hs": 404.0,
      "mns": 2
    },
    {
      "a": "16074112",
      "j": 2023,
      "n": "wichmar",
      "dn": "Wichmar",
      "bl": 16,
      "hs": 405.0,
      "mns": 2
    },
    {
      "a": "16074113",
      "j": 2023,
      "n": "zimmern",
      "dn": "Zimmern",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074114",
      "j": 2023,
      "n": "zöllnitz",
      "dn": "Zöllnitz",
      "bl": 16,
      "hs": 389.0,
      "mns": 2
    },
    {
      "a": "16074116",
      "j": 2023,
      "n": "schkölen",
      "dn": "Schkölen",
      "bl": 16,
      "hs": 400.0,
      "mns": 2
    },
    {
      "a": "16075006",
      "j": 2023,
      "n": "bodelwitz",
      "dn": "Bodelwitz",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075014",
      "j": 2023,
      "n": "dittersdorf",
      "dn": "Dittersdorf",
      "bl": 16,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "16075016",
      "j": 2023,
      "n": "döbritz",
      "dn": "Döbritz",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16075019",
      "j": 2023,
      "n": "dreitzsch",
      "dn": "Dreitzsch",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075023",
      "j": 2023,
      "n": "eßbach",
      "dn": "Eßbach",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16075029",
      "j": 2023,
      "n": "geroda",
      "dn": "Geroda",
      "bl": 16,
      "hs": 422.0,
      "mns": 1
    },
    {
      "a": "16075031",
      "j": 2023,
      "n": "gertewitz",
      "dn": "Gertewitz",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075033",
      "j": 2023,
      "n": "görkwitz",
      "dn": "Görkwitz",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075034",
      "j": 2023,
      "n": "göschitz",
      "dn": "Göschitz",
      "bl": 16,
      "hs": 360.0,
      "mns": 1
    },
    {
      "a": "16075035",
      "j": 2023,
      "n": "gössitz",
      "dn": "Gössitz",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16075039",
      "j": 2023,
      "n": "grobengereuth",
      "dn": "Grobengereuth",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075046",
      "j": 2023,
      "n": "hirschberg",
      "dn": "Hirschberg",
      "bl": 16,
      "hs": 404.0,
      "mns": 1
    },
    {
      "a": "16075047",
      "j": 2023,
      "n": "keila",
      "dn": "Keila",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16075048",
      "j": 2023,
      "n": "kirschkau",
      "dn": "Kirschkau",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075051",
      "j": 2023,
      "n": "kospoda",
      "dn": "Kospoda",
      "bl": 16,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "16075054",
      "j": 2023,
      "n": "langenorla",
      "dn": "Langenorla",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075056",
      "j": 2023,
      "n": "lausnitz bei neustadt an der orla",
      "dn": "Lausnitz bei Neustadt an der Orla",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075057",
      "j": 2023,
      "n": "lemnitz",
      "dn": "Lemnitz",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075062",
      "j": 2023,
      "n": "bad lobenstein",
      "dn": "Bad Lobenstein",
      "bl": 16,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "16075063",
      "j": 2023,
      "n": "löhma",
      "dn": "Löhma",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075065",
      "j": 2023,
      "n": "miesitz",
      "dn": "Miesitz",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075066",
      "j": 2023,
      "n": "mittelpöllnitz",
      "dn": "Mittelpöllnitz",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075068",
      "j": 2023,
      "n": "moßbach",
      "dn": "Moßbach",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075069",
      "j": 2023,
      "n": "moxa",
      "dn": "Moxa",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075072",
      "j": 2023,
      "n": "neundorf",
      "dn": "Neundorf",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075073",
      "j": 2023,
      "n": "neustadt an der orla",
      "dn": "Neustadt an der Orla",
      "bl": 16,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "16075074",
      "j": 2023,
      "n": "nimritz",
      "dn": "Nimritz",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075075",
      "j": 2023,
      "n": "oberoppurg",
      "dn": "Oberoppurg",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075076",
      "j": 2023,
      "n": "oettersdorf",
      "dn": "Oettersdorf",
      "bl": 16,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "16075077",
      "j": 2023,
      "n": "oppurg",
      "dn": "Oppurg",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075079",
      "j": 2023,
      "n": "paska",
      "dn": "Paska",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075081",
      "j": 2023,
      "n": "peuschen",
      "dn": "Peuschen",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075083",
      "j": 2023,
      "n": "plothen",
      "dn": "Plothen",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16075084",
      "j": 2023,
      "n": "pörmitz",
      "dn": "Pörmitz",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075085",
      "j": 2023,
      "n": "pößneck",
      "dn": "Pößneck",
      "bl": 16,
      "hs": 410.0,
      "mns": 2
    },
    {
      "a": "16075087",
      "j": 2023,
      "n": "quaschwitz",
      "dn": "Quaschwitz",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075088",
      "j": 2023,
      "n": "ranis",
      "dn": "Ranis",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075093",
      "j": 2023,
      "n": "rosendorf",
      "dn": "Rosendorf",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075098",
      "j": 2023,
      "n": "schleiz",
      "dn": "Schleiz",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075099",
      "j": 2023,
      "n": "schmieritz",
      "dn": "Schmieritz",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075101",
      "j": 2023,
      "n": "schmorda",
      "dn": "Schmorda",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16075102",
      "j": 2023,
      "n": "schöndorf",
      "dn": "Schöndorf",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16075103",
      "j": 2023,
      "n": "seisla",
      "dn": "Seisla",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16075105",
      "j": 2023,
      "n": "solkwitz",
      "dn": "Solkwitz",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16075109",
      "j": 2023,
      "n": "tegau",
      "dn": "Tegau",
      "bl": 16,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "16075114",
      "j": 2023,
      "n": "tömmelsdorf",
      "dn": "Tömmelsdorf",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075116",
      "j": 2023,
      "n": "triptis",
      "dn": "Triptis",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075119",
      "j": 2023,
      "n": "volkmannsdorf",
      "dn": "Volkmannsdorf",
      "bl": 16,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "16075121",
      "j": 2023,
      "n": "weira",
      "dn": "Weira",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075124",
      "j": 2023,
      "n": "wernburg",
      "dn": "Wernburg",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16075125",
      "j": 2023,
      "n": "wilhelmsdorf",
      "dn": "Wilhelmsdorf",
      "bl": 16,
      "hs": 380.0,
      "mns": 1
    },
    {
      "a": "16075127",
      "j": 2023,
      "n": "ziegenrück",
      "dn": "Ziegenrück",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075129",
      "j": 2023,
      "n": "krölpa",
      "dn": "Krölpa",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16075131",
      "j": 2023,
      "n": "gefell",
      "dn": "Gefell",
      "bl": 16,
      "hs": 404.0,
      "mns": 1
    },
    {
      "a": "16075132",
      "j": 2023,
      "n": "tanna",
      "dn": "Tanna",
      "bl": 16,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "16075133",
      "j": 2023,
      "n": "wurzbach",
      "dn": "Wurzbach",
      "bl": 16,
      "hs": 426.0,
      "mns": 1
    },
    {
      "a": "16075134",
      "j": 2023,
      "n": "remptendorf",
      "dn": "Remptendorf",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16075135",
      "j": 2023,
      "n": "saalburg-ebersdorf",
      "dn": "Saalburg-Ebersdorf",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16075136",
      "j": 2023,
      "n": "rosenthal am rennsteig",
      "dn": "Rosenthal am Rennsteig",
      "bl": 16,
      "hs": 395.0,
      "mns": 1
    },
    {
      "a": "16076003",
      "j": 2023,
      "n": "bad köstritz",
      "dn": "Bad Köstritz",
      "bl": 16,
      "hs": 419.0,
      "mns": 1
    },
    {
      "a": "16076004",
      "j": 2023,
      "n": "berga/elster",
      "dn": "Berga/Elster",
      "bl": 16,
      "hs": 426.0,
      "mns": 1
    },
    {
      "a": "16076006",
      "j": 2023,
      "n": "bethenhausen",
      "dn": "Bethenhausen",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16076007",
      "j": 2023,
      "n": "bocka",
      "dn": "Bocka",
      "bl": 16,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "16076008",
      "j": 2023,
      "n": "brahmenau",
      "dn": "Brahmenau",
      "bl": 16,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "16076009",
      "j": 2023,
      "n": "braunichswalde",
      "dn": "Braunichswalde",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16076012",
      "j": 2023,
      "n": "caaschwitz",
      "dn": "Caaschwitz",
      "bl": 16,
      "hs": 430.0,
      "mns": 1
    },
    {
      "a": "16076014",
      "j": 2023,
      "n": "crimla",
      "dn": "Crimla",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16076017",
      "j": 2023,
      "n": "endschütz",
      "dn": "Endschütz",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16076019",
      "j": 2023,
      "n": "gauern",
      "dn": "Gauern",
      "bl": 16,
      "hs": 421.0,
      "mns": 1
    },
    {
      "a": "16076022",
      "j": 2023,
      "n": "greiz",
      "dn": "Greiz",
      "bl": 16,
      "hs": 439.0,
      "mns": 1
    },
    {
      "a": "16076023",
      "j": 2023,
      "n": "großenstein",
      "dn": "Großenstein",
      "bl": 16,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "16076026",
      "j": 2022,
      "n": "hartmannsdorf",
      "dn": "Hartmannsdorf",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16076027",
      "j": 2023,
      "n": "hilbersdorf",
      "dn": "Hilbersdorf",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16076028",
      "j": 2023,
      "n": "hirschfeld",
      "dn": "Hirschfeld",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16076029",
      "j": 2023,
      "n": "hohenleuben",
      "dn": "Hohenleuben",
      "bl": 16,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "16076033",
      "j": 2023,
      "n": "hundhaupten",
      "dn": "Hundhaupten",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16076034",
      "j": 2023,
      "n": "kauern",
      "dn": "Kauern",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16076036",
      "j": 2023,
      "n": "korbußen",
      "dn": "Korbußen",
      "bl": 16,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "16076038",
      "j": 2022,
      "n": "kühdorf",
      "dn": "Kühdorf",
      "bl": 16,
      "hs": 413.0,
      "mns": 1
    },
    {
      "a": "16076039",
      "j": 2023,
      "n": "langenwetzendorf",
      "dn": "Langenwetzendorf",
      "bl": 16,
      "hs": 425.0,
      "mns": 1
    },
    {
      "a": "16076041",
      "j": 2023,
      "n": "langenwolschendorf",
      "dn": "Langenwolschendorf",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16076042",
      "j": 2023,
      "n": "lederhose",
      "dn": "Lederhose",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16076043",
      "j": 2023,
      "n": "linda b. weida",
      "dn": "Linda b. Weida",
      "bl": 16,
      "hs": 421.0,
      "mns": 1
    },
    {
      "a": "16076044",
      "j": 2023,
      "n": "lindenkreuz",
      "dn": "Lindenkreuz",
      "bl": 16,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "16076049",
      "j": 2023,
      "n": "münchenbernsdorf",
      "dn": "Münchenbernsdorf",
      "bl": 16,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "16076055",
      "j": 2023,
      "n": "paitzdorf",
      "dn": "Paitzdorf",
      "bl": 16,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "16076058",
      "j": 2023,
      "n": "pölzig",
      "dn": "Pölzig",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16076059",
      "j": 2023,
      "n": "reichstädt",
      "dn": "Reichstädt",
      "bl": 16,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "16076061",
      "j": 2023,
      "n": "ronneburg",
      "dn": "Ronneburg",
      "bl": 16,
      "hs": 420.0,
      "mns": 1
    },
    {
      "a": "16076062",
      "j": 2023,
      "n": "rückersdorf",
      "dn": "Rückersdorf",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16076064",
      "j": 2023,
      "n": "saara",
      "dn": "Saara",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16076067",
      "j": 2023,
      "n": "schwaara",
      "dn": "Schwaara",
      "bl": 16,
      "hs": 300.0,
      "mns": 1
    },
    {
      "a": "16076068",
      "j": 2023,
      "n": "schwarzbach",
      "dn": "Schwarzbach",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16076069",
      "j": 2023,
      "n": "seelingstädt",
      "dn": "Seelingstädt",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16076074",
      "j": 2023,
      "n": "teichwitz",
      "dn": "Teichwitz",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16076079",
      "j": 2023,
      "n": "weida",
      "dn": "Weida",
      "bl": 16,
      "hs": 450.0,
      "mns": 1
    },
    {
      "a": "16076081",
      "j": 2023,
      "n": "weißendorf",
      "dn": "Weißendorf",
      "bl": 16,
      "hs": 411.0,
      "mns": 1
    },
    {
      "a": "16076084",
      "j": 2023,
      "n": "wünschendorf/elster",
      "dn": "Wünschendorf/Elster",
      "bl": 16,
      "hs": 410.0,
      "mns": 1
    },
    {
      "a": "16076086",
      "j": 2023,
      "n": "zedlitz",
      "dn": "Zedlitz",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16076087",
      "j": 2023,
      "n": "zeulenroda-triebes",
      "dn": "Zeulenroda-Triebes",
      "bl": 16,
      "hs": 411.0,
      "mns": 1
    },
    {
      "a": "16076088",
      "j": 2023,
      "n": "harth-pöllnitz",
      "dn": "Harth-Pöllnitz",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16076089",
      "j": 2023,
      "n": "kraftsdorf",
      "dn": "Kraftsdorf",
      "bl": 16,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "16076092",
      "j": 2023,
      "n": "auma-weidatal",
      "dn": "Auma-Weidatal",
      "bl": 16,
      "hs": 402.0,
      "mns": 1
    },
    {
      "a": "16076093",
      "j": 2023,
      "n": "mohlsdorf-teichwolframsdorf",
      "dn": "Mohlsdorf-Teichwolframsdorf",
      "bl": 16,
      "hs": 412.0,
      "mns": 1
    },
    {
      "a": "16077001",
      "j": 2023,
      "n": "altenburg",
      "dn": "Altenburg",
      "bl": 16,
      "hs": 445.0,
      "mns": 2
    },
    {
      "a": "16077003",
      "j": 2023,
      "n": "dobitschen",
      "dn": "Dobitschen",
      "bl": 16,
      "hs": 421.0,
      "mns": 1
    },
    {
      "a": "16077005",
      "j": 2023,
      "n": "fockendorf",
      "dn": "Fockendorf",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16077007",
      "j": 2023,
      "n": "gerstenberg",
      "dn": "Gerstenberg",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16077008",
      "j": 2023,
      "n": "göhren",
      "dn": "Göhren",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16077009",
      "j": 2023,
      "n": "göllnitz",
      "dn": "Göllnitz",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16077011",
      "j": 2023,
      "n": "göpfersdorf",
      "dn": "Göpfersdorf",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16077012",
      "j": 2023,
      "n": "gößnitz",
      "dn": "Gößnitz",
      "bl": 16,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "16077015",
      "j": 2023,
      "n": "haselbach",
      "dn": "Haselbach",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16077016",
      "j": 2023,
      "n": "heukewalde",
      "dn": "Heukewalde",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16077017",
      "j": 2023,
      "n": "heyersdorf",
      "dn": "Heyersdorf",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16077018",
      "j": 2023,
      "n": "jonaswalde",
      "dn": "Jonaswalde",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16077022",
      "j": 2023,
      "n": "kriebitzsch",
      "dn": "Kriebitzsch",
      "bl": 16,
      "hs": 404.0,
      "mns": 1
    },
    {
      "a": "16077023",
      "j": 2023,
      "n": "langenleuba-niederhain",
      "dn": "Langenleuba-Niederhain",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16077026",
      "j": 2023,
      "n": "löbichau",
      "dn": "Löbichau",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16077027",
      "j": 2023,
      "n": "lödla",
      "dn": "Lödla",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16077028",
      "j": 2023,
      "n": "lucka",
      "dn": "Lucka",
      "bl": 16,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "16077031",
      "j": 2023,
      "n": "mehna",
      "dn": "Mehna",
      "bl": 16,
      "hs": 400.0,
      "mns": 1
    },
    {
      "a": "16077032",
      "j": 2023,
      "n": "meuselwitz",
      "dn": "Meuselwitz",
      "bl": 16,
      "hs": 430.0,
      "mns": 2
    },
    {
      "a": "16077034",
      "j": 2023,
      "n": "monstab",
      "dn": "Monstab",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16077036",
      "j": 2023,
      "n": "nobitz",
      "dn": "Nobitz",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16077039",
      "j": 2023,
      "n": "ponitz",
      "dn": "Ponitz",
      "bl": 16,
      "hs": 460.0,
      "mns": 1
    },
    {
      "a": "16077041",
      "j": 2023,
      "n": "posterstein",
      "dn": "Posterstein",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16077042",
      "j": 2023,
      "n": "rositz",
      "dn": "Rositz",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16077043",
      "j": 2023,
      "n": "schmölln",
      "dn": "Schmölln",
      "bl": 16,
      "hs": 440.0,
      "mns": 1
    },
    {
      "a": "16077044",
      "j": 2023,
      "n": "starkenberg",
      "dn": "Starkenberg",
      "bl": 16,
      "hs": 390.0,
      "mns": 1
    },
    {
      "a": "16077047",
      "j": 2023,
      "n": "thonhausen",
      "dn": "Thonhausen",
      "bl": 16,
      "hs": 405.0,
      "mns": 1
    },
    {
      "a": "16077048",
      "j": 2023,
      "n": "treben",
      "dn": "Treben",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16077049",
      "j": 2023,
      "n": "vollmershain",
      "dn": "Vollmershain",
      "bl": 16,
      "hs": 389.0,
      "mns": 1
    },
    {
      "a": "16077052",
      "j": 2023,
      "n": "windischleuba",
      "dn": "Windischleuba",
      "bl": 16,
      "hs": 370.0,
      "mns": 1
    }
  ]
};
