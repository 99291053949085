<template>
  <div
    class="form-horizontal rechner-form"
    id="'in_group_' + name"
    :class="{'has-error': has_error, 'callout': has_error, 'callout-red': has_error, 'validation_error': has_error}"
  >
    <div class="form-group">
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <label :for="name" class="pull-left control-label rechner-form-label">
          {{ label }}
          <a v-if="info_text" class="rechner-form-info-button" @click="on_info_clicked"><span class="glyphicon glyphicon-info-sign"></span></a>
        </label>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <div :class="{'input-group': suffix}">
          <input
            type="text"
			inputmode="numeric"
            :id="name"
            :name="name"
            :placeholder="placeholder"
            v-model.trim="form_value"
            class="form-control text-right"
            :disabled="disabled"
			@focus="show_info_line=true"
			@blur="show_info_line=false"
          />
          <div class="input-group-btn" :class="{'no-round': extra_button || external_link_button}" v-if="suffix">
            <button type="button" class="btn btn-default" tabindex="-1" :disabled="disabled">{{ suffix }}</button>
          </div>
          <div v-if="external_link_button" class="input-group-btn">
            <a :href="this.external_link_button" target="_blank" class="btn btn-default">{{ external_link_button_text }}</a>
          </div>
          <div v-if="extra_button" class="input-group-btn">
            <a class="btn btn-default" @click="on_extra_clicked">{{ extra_button }}</a>
          </div>
          <div v-if="modal_html" class="input-group-btn">
            <a target="_blank" @click="$emit('toggleInfoText', modal_html_title, modal_html);" class="btn btn-default">{{ modal_button_text }}</a>
          </div>
          <div v-if="modal_layer" class="input-group-btn">
            <a target="_blank" @click="$emit('toggleLayer');" class="btn btn-default">{{ modal_button_text }}</a>
          </div>
        </div>
		<p v-if="show_info_line && info_line" class="info_line" v-html="info_line"></p>
        <div v-if="has_error && form_error_message">
          <p>{{ form_error_message }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get';
import { try_get_number_de } from '@steuerrechner/steuerrechner-shared';
import { RechnerInputWithError } from '../RechnerInputWithError';
import RechnerInputWithInfoText from '../RechnerInputWithInfoText';
import {FORM_UPDATE, FORM_RAW_SET} from '../../store/mutations';

export default {
  name: "RechnerInputNumber",
  mixins: [
    RechnerInputWithError,
    RechnerInputWithInfoText
  ],
  props: {
    // Name und ID des inputs
    name: String,

    // Platzhalter Text
    placeholder: String,

    // Bezeichnung für den Nutzer
    label: String,
    
    // Suffix zur Anzeige
    suffix: String,

    // Disable input fields
    disabled: Boolean,

    // Initialier Wert
    initial_value: String,

    // Allow to set this to null by not entering anything
    allow_empty: Boolean,

    // URL des extra Button rechts
    external_link_button: String || undefined,
    external_link_button_text: String || undefined,

    // Button für modales Feld
    modal_html: String || undefined,
	modal_html_title: String || undefined,
    modal_button_text: String || undefined,

    // Modalen Layer zeigen
    modal_layer: String || undefined,
    
	// Extra Button anzeigen
    extra_button: String,

	info_line: String,
  },
  data: function() {
	return {
		show_info_line: false,
	}
  },
  methods: {
    on_extra_clicked: function() {
      this.$emit('extra_button_click');
    }
  },
  computed: {
    // Eingabefeld-Wert
    form_value: {
      get() {
        return _get(this.$store.state.form_raw, this.name);
      },
      set(value) {
        // set empty to null, if allowed and value is null or just empty string
        if (this.allow_empty === true && (value === null || value === "")) {
          this.$store.dispatch({
            type: FORM_RAW_SET,
            name: this.name,
            value: null
          });
        }
        else {
          this.$store.dispatch({
            type: FORM_UPDATE,
            name: this.name,
            validation_func: try_get_number_de,
            error_message: this.error_message || "Bitte einen Wert angeben",
            value
          });
        }
      }
    }
  },
  mounted: function() {
    if (this.initial_value) {
      this.form_value = this.initial_value;
    }
  }
};
</script>

<style scoped lang="scss">
  .no-round button {
    border-radius: 0;
  }
</style>
